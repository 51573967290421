import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import safeRender from 'imports/ui/decorators/safeRender';
import PopularProducts from "imports/ui/shared/popular_products/PopularProductsContainer";

class TemplateDisplayActions extends Component {

  componentDidMount() {
    let i = 50;
    document.documentElement.style.setProperty('--atm__temp-page__text__font-size', i);
    if (this.props.smallText.length < 300) return;
    const h = document.querySelector('body').clientHeight;
    while (h < document.querySelector('#body').clientHeight && i > 0) {
      document.documentElement.style.setProperty('--atm__temp-page__text__font-size', i--);
    }
  }

  render() {
    const {
      smallText, bigText, smallBottomText, withSteps, srcImg, isSpinner, progressBar, additional, additionalSteps, onCancel,
      onBack, onPrint, onShow, onMenu, onMenuRight, onNext, onImageClick, onActions, onCancelCenter, onChangeAmount,
      onNo, onYes, leftButton, centerButton, rightButton, popularProducts,
    } = this.props;

    return (
      <table>
        <tbody>
        <tr>
          <td>
            <div className="atm-top-tr">
              {withSteps ?
                (<div>
                  {bigText && (
                    <div style={{lineHeight: '105px'}} className="atm__temp-page atm__display__text"
                         onMouseDown={() => onShow(true)}
                         onMouseUp={() => onShow(false)}>
                      <span>{bigText}</span>
                    </div>)}
                  {additionalSteps ?
                    <div style={{paddingBottom: '50px', fontSize: "110%"}} className="atm__temp-page__additional">
                      <span>[ {additionalSteps.left} ] </span>
                      <img style={{width: '20px'}} src="/resources/images/atm/ic_arrow_small.png"/>
                      <span> [ {additionalSteps.right} ]</span>
                    </div> :
                    ''}
                </div>) :
                (<div>
                  <div className="atm__temp-page atm__temp-page__text">
                    <span>{smallText}</span>
                  </div>
                  {bigText && (
                    <div className="atm__temp-page atm__display__text" onMouseDown={() => onShow(true)}
                         onMouseUp={() => onShow(false)}>
                      <span>{bigText}</span>
                    </div>)}
                  {smallBottomText && (
                    <div className="atm__temp-page atm__temp-page__text">
                      <span>{smallBottomText}</span>
                    </div>
                  )}
                </div>)}

              {popularProducts ? <PopularProducts/> :
                srcImg &&
                <div className="atm__temp-page atm__display__img" onMouseDown={() => onImageClick()}>
                  <img src={srcImg} alt=""/>
                </div>}

              {withSteps ?
                <div className="atm__temp-page atm__temp-page__text">
                  <span>{smallText}</span>
                </div> :
                null}

              {additional ?
                <div className="atm__temp-page__additional">
                  <span>[ {additional.left} ] </span>
                  <img src="/resources/images/atm/ic_arrow_small.png"/>
                  <span> [ {additional.right} ]</span>
                </div> :
                ''}
            </div>
          </td>
        </tr>
        {isSpinner && (
          <tr style={{height: 160}}>
            <td>

              <div className="atm-down-tr">
                <div className="spinner-dots">
                  <div className="spinner-dots__item spinner-dots__item-1"></div>
                  <div className="spinner-dots__item spinner-dots__item-2"></div>
                  <div className="spinner-dots__item spinner-dots__item-3"></div>
                  <div className="spinner-dots__item spinner-dots__item-4"></div>
                </div>
              </div>
            </td>
          </tr>)}
        {progressBar && (
          <tr style={{height: 160}}>
            <td>
              <div className="atm-down-tr">
                <div className="progress-bar">
                  <div className="progress-bar__progress"></div>
                </div>
              </div>
            </td>
          </tr>
        )}
        </tbody>
        <tfoot>
        <tr>
          <td style={{textAlign: 'center'}}>
            {onBack ?
              <Button id="back" className="left button" onMouseDown={onBack} text_={leftButton || 'Back'}/> :
              null}
            {onNo ?
              <Button id="back" className="left button" onMouseDown={onNo} text_={leftButton || 'No'}/> :
              null}
            {onActions ?
              <Button className="center button goto-index" text_={centerButton || 'Another transaction'}
                      onMouseDown={onActions}/> :
              null}
            {onMenu ?
              <Button className="center button goto-index" onMouseDown={onMenu} text_="Main Menu"/> :
              null}
            {onCancel ?
              <Button className="left button button-danger" onMouseDown={onCancel} text_={leftButton || 'Cancel'}/> :
              null}
            {onPrint ?
              <Button id="next" className="right button" onMouseDown={onPrint} text_={rightButton || 'Print'}/> :
              null}
            {onNext ?
              <Button id="next" className="right button" onMouseDown={onNext} text_={rightButton || 'Next'}/> :
              null}
            {onYes ?
              <Button id="next" className="right button" onMouseDown={onYes} text_={rightButton || 'Yes'}/> :
              null}
            {onCancelCenter ?
              <Button className="center button button-danger" text_="Cancel" onMouseDown={onCancelCenter}/> :
              null}
            {onChangeAmount ?
              <Button id="next" className="right button" onMouseDown={onChangeAmount}
                      text_={rightButton || 'Change Amount'}/> :
              null}
            {onMenuRight ?
              <Button className="right button goto-index" onMouseDown={onMenuRight}
                      text_={rightButton || 'Main Menu'}/> :
              null}
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

TemplateDisplayActions.propTypes = {
  smallText: React.PropTypes.string,
  bigText: React.PropTypes.string,
  withSteps: React.PropTypes.bool,
  srcImg: React.PropTypes.string,
  isSpinner: React.PropTypes.bool,
  additional: React.PropTypes.object,
  additionalSteps: React.PropTypes.object,
  onCancel: React.PropTypes.func,
  onCancelCenter: React.PropTypes.func,
  onPrint: React.PropTypes.func,
  onBack: React.PropTypes.func,
  onActions: React.PropTypes.func,
  onShow: React.PropTypes.func,
  onMenu: React.PropTypes.func,
  onMenuRight: React.PropTypes.func,
  onNext: React.PropTypes.func,
  onImageClick: React.PropTypes.func,
  onNo: React.PropTypes.func,
  onYes: React.PropTypes.func,
};

TemplateDisplayActions.defaultProps = {
  smallText: '',
  bigText: '',
  srcImg: '',
  isSpinner: true,
  additional: null,
  onShow: () => {
  },
  onImageClick: () => {
  },
};

export default TemplateDisplayActions;
