import React, {Component} from 'react';
import {FlowRouter} from 'core/Router';
import Components from 'imports/ui/apps/pages';
import safeRender from 'imports/ui/decorators/safeRender';

class App extends Component {
  render() {
    if (!this.props.appGuid) return null;
    $('#css').text(this.props.css);

    const CurrentStep = Components[this.props.step];

    return (
      <CurrentStep {...this.props}/>
    );
  }
}

App.propTypes = {
  css: React.PropTypes.string,
  step: React.PropTypes.string,
};

// Specifies the default values for props:
App.defaultProps = {
  css: '/* no apps open */'
};

export default safeRender(App);
