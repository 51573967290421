import {createContainer} from 'imports/lib/react_createContainer';
import ConfirmEnteredData from './ConfirmEnteredData';
import {MONEYTRANSFER_USER_AVAILABLE_FIELDS} from 'imports/lib/constants';
import IW from 'imports/iw_api';
import getPageManager from 'imports/lib/page_manager';
import {complete} from 'imports/lib/array_extension';

export default createContainer((params) => {
  const prefixType = params.isSender ? 'sender' : (params.isRecipient ? 'recipient' : (params.isBank ? 'bank' : ''));

  let prefixFields = params.prefixFields || prefixType;

  if (prefixType === 'sender' && IW.appGetVal('service_name') === 'viamericas') {
    prefixFields = 'senderViamericas';
  }

  const currentFields = MONEYTRANSFER_USER_AVAILABLE_FIELDS[prefixFields];

  function getDisplayedFields() {
    // Divide partials from other
    const partials = {}; // object with arrays of partials
    const independence = [];

    _.each(currentFields, (item) => {
      if (item.part_of) {
        partials[item.part_of] = partials[item.part_of] || [];
        partials[item.part_of].push(item);
      } else {
        independence.push(item);
      }
    });

    return independence.map((item) => {
      let data = '';

      if (partials[item.name]) {
        data = resolvePartials(partials[item.name], item.name, item.output_scheme, wrapGetVal(prefixType));
      } else {
        data = IW.appGetVal(`${prefixType}_${item.name}`);
      }
      return {...item, data};
    });
  }

  /**
   *
   * @param items
   * @param name
   * @param scheme
   * @param getVal
   * @return {*}
   */
  function resolvePartials(items, name, scheme, getVal) {
    if (scheme) {
      return resolvePartialsScheme(scheme, getVal);
    }

    return resolvePartialsSimple(items, name, getVal);
  }

  /**
   * Method return array, where all elements ready to display, as : 'MayaFS, Payment System, ... etc'
   * @param items
   * @param name
   * @param getVal
   */
  function resolvePartialsSimple(items, name, getVal) {
    const partials = items.filter((item) => item.part_of && item.part_of === name);
    const resultPartials = partials.map((item) => {
      if (item.name) return getVal(item.name);
      return '';
    }).filter((item) => !!item);
    return resultPartials.join(', ');
  }

  /**
   * Method return 2 levels array, where each row must displayed at new line
   * @param scheme
   * @param getVal
   * @return {[*]}
   */
  function resolvePartialsScheme(scheme, getVal) {
    const resultPartials = _.compact(_.map(scheme, (item) => {
      if (!_.isArray(item)) return getVal(item).trim();
      return _.compact(_.map(item, (item2) => {
        if (!_.isArray(item2)) return getVal(item2).trim();
        return _.compact(_.map(item2, (item3) => getVal(item3).trim())).join(' ');
      })).join(', ');
    }))
    return [...resultPartials];
  }

  console.log('IW, prefixType ++', IW, prefixType);
  function wrapGetVal(prefix) {
    return function (name) {
      return IW.appGetVal(`${prefix}_${name}`);
    }
  }

  return {
    userType: prefixType,
    items: getDisplayedFields().complete(6, {
      data: " ",
      describe_output: ""
    }),
    onNext: () => getPageManager().next(),
    onEdit: () => getPageManager().edit()
  };
}, ConfirmEnteredData);
