import {createContainer} from 'imports/lib/react_createContainer';
import BillScanningLoading from './BillScanningLoading';
import {Session} from 'core/session'
import {createLazyExecution} from 'imports/lib/helpers'

const setState1 = createLazyExecution(() => Session.set('bill_scanning_loading_state', 1), 4000)
const setState2 = createLazyExecution(() => Session.set('bill_scanning_loading_state', 2), 9000)

export default createContainer((params) => {
  const items = [
    {status: '', description: 'Scanning your bill'},
    {status: '', description: 'Submitting bill to processor'},
    {status: '', description: 'Waiting for processor response'},
  ];

  const state = Session.get('bill_scanning_loading_state');
  if (state < 3){
    items[state].status = 'loading';
  }
  for (let i = 0; i < state; i++) {
    items[i].status = 'done'
  }

  return {
    items: items,
    onMount: () => {
      Session.set('bill_scanning_loading_state', 0);
      setState1();
      setState2();
    }
  };

}, BillScanningLoading);