import { createContainer } from 'imports/lib/react_createContainer';
import {FlowRouter} from "core/Router";
import CONFIG from "imports/config";
import Index from "./Index";
import Languages from "imports/collections/languages";
import IW from 'imports/iw_api'
import {gaEvent} from "imports/lib/ga";

let timerId = -1;
export default createContainer(({params, gaCategory}) => {

  let languages = Languages.find({}, {
    sort: {order: 1}
  }).fetch();

  const items = [];

  if (languages.length) {
    languages = languages.complete(Math.ceil(languages.length / CONFIG.LANGUAGES_PER_PAGE) * CONFIG.LANGUAGES_PER_PAGE, {});

    languages = _.map(languages.chunk(4), (slide) => {
      items.push(slide.chunk(2))
    })
  }

  const locale = Session.get('locale') || CONFIG.DEFAULT_LANGUAGE;

  clearInterval(timerId);
  if (locale !== CONFIG.DEFAULT_LANGUAGE) {
    timerId = setTimeout(() => {
      Session.set('locale', CONFIG.DEFAULT_LANGUAGE);
    }, CONFIG.TIMEOUT_CHANGE_LANGUAGE * 1000);
  }

  const onSignIn = (lang) => {
    gaEvent(gaCategory, 'Sign In');
    IW.loginLaunch(lang)
  };
  const onGetApp = () => FlowRouter.go("GetApp");

  IW.setVoicePage('language');

  return {
    items,
    showFooter: CONFIG.MAIN_SCREEN_LOGIN || CONFIG.MAIN_SCREEN_GET_APP,
    showLogin: CONFIG.MAIN_SCREEN_LOGIN,
    showGetApp: CONFIG.MAIN_SCREEN_GET_APP,
    showATM: CONFIG.ATM_ENABLED && CONFIG.ATM_ENABLED_IN_LANGUAGE_PAGE,
    showLogout: IW.isClerkLogged(),
    onSignIn,
    onGetApp,
    onLogout: IW.logoutClerk,
    locale,
    timerId,

    gaCategory
  };

}, Index);
