import React, {Component} from 'react';
import IW from 'imports/iw_api';
import Button from 'imports/ui/shared/button/Button';
import getPageManager from 'imports/lib/page_manager';

export default class ProfileHeader extends Component {

  render() {
    const {backButton, isSearchBack, onSearchBack} = this.props;

    let renderBack = <Button id="back" className="button button-profile button-pink" onMouseDown={() => IW.gotoIndex()} text_="Exit"/>;

    if(backButton) {
      renderBack = <Button id="profile" className="button button-categories button-pink" onMouseDown={() => getPageManager().back()} text_="Profile"/>
    }
    //
    // if(isSearchBack){
    //   renderBack = <Button id="back" className="button button-categories button-pink" onMouseDown={onSearchBack} text_="Back"/>
    // }

    return (
      <tr className="main">
        <td>
          {renderBack}
        </td>
        <td>
          <div className="logo-container">
            <a className="partner logo"/>
          </div>
        </td>
        <td>
          {/*{CONFIG.ATM_ENABLED ?*/}
            {/*<Button id="next" className="button button-atm button-blue" text_="ATM" onMouseDown={() => IW.launchATM()}/> : <Time />*/}
          {/*}*/}
        </td>
      </tr>
    )
  }
}
