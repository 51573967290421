import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import ErrorMoneyTransfer from './ErrorMoneyTransfer'
import getPageManager from 'imports/lib/page_manager'
import IW from '../../../iw_api';

export default createContainer(() => {
  const supportNumber = IW.getUISettings().support_number;
  const errorMessages = _.compact(_.map(IW.appGetVal('transaction_error_messages'), (m) => m));

  return {
    onExit: () => getPageManager().cancel(),
    onBack: () => getPageManager().back(),
    supportNumber,
    errorMessages,
    errorTitle: 'Transaction not processed'
  };

}, ErrorMoneyTransfer);


