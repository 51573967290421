import React, {Component} from 'react';
import {FlowRouter} from 'core/Router';
import Button from 'imports/ui/shared/button/Button';
import Input from 'imports/ui/shared/input/Input';
import {usd} from 'imports/lib/helpers';

export default class PaymentPin extends Component {

  showStatus() {

    const {statusSmall, statusLarge, showButtons} = this.props;
    return (
      <div className="atm-top-tr">
        <div className="atm__temp-page atm__temp-page__text">
          <span>{statusSmall || ''}</span>
        </div>
        <div className="atm__temp-page atm__display__text">
          <span>{statusLarge || ''}</span>
        </div>
        <div className="atm__temp-page atm__display__img">
          <img src="/resources/images/atm/ic_take_card.png" alt=""/>
        </div>
        {!showButtons && (
          <div className="atm-down-tr">
            <div className="spinner-dots">
              <div className="spinner-dots__item spinner-dots__item-1"></div>
              <div className="spinner-dots__item spinner-dots__item-2"></div>
              <div className="spinner-dots__item spinner-dots__item-3"></div>
              <div className="spinner-dots__item spinner-dots__item-4"></div>
            </div>
          </div>
        )}
      </div>
    )
  }


  render() {

    const {pin, isPinOk, showAlert, showButtons, pinWrong, fee, onBack, onNext} = this.props;

    const res = (
      <table>
        <tbody>
        <tr>
          {
            showAlert ?
              <td>{this.showStatus()}</td> :
              <td>
                {fee ?
                  <div style={{"textAlign": "center"}}>
                    <span className="text-md fee__top__upper-input__text" _={[usd(fee)]}>
                      PIN DEBIT operation fee %s
                    </span>
                    <br/>
                  </div> : null}

                <div className="enter-pin__top">
                  <Input id="pin" className="enter-pin__top__input focus" value={pin} readOnly={true}/>
                  <div className="enter-pin__top__decorate-icon">
                    <img src="/resources/images/atm/ic_pin_small.png" alt=""/>
                  </div>
                  <br/>

                  {pinWrong ?
                    <span key={0} className="text-md enter-pin__top__under-input__text failed" _>
                      Your PIN is wrong. Please, try again
                    </span> :
                    <div>
                      <span className="text-md enter-pin__top__under-input__text" _>Enter your PIN through </span>
                      <span
                        className="text-md enter-pin__top__under-input__text enter-pin__top__under-input__text-accented"
                        _>
                        ATM PIN pad
                      </span>
                    </div>
                  }

                </div>
              </td>
          }
        </tr>
        {!showAlert && (
          <tr>
            <td>
              <div className="enter-pin__bottom">
                <img src="/resources/images/atm/ic_pin_pad.png" alt=""/>
              </div>
            </td>
          </tr>
        )}
        </tbody>
        {showButtons && (
          <tfoot className="atm-foot">
          <tr>
            <td colSpan="2">
              <Button id="back" className="left button" text_="Back" onMouseDown={onBack}/>
              {isPinOk && !showAlert &&
              <Button id="next" className="right button" onMouseDown={onNext} text_="Confirm"/>}
              {showAlert && <Button id="next" className="right button" onMouseDown={onNext} text_="Next"/>}
            </td>
          </tr>
          </tfoot>
        )}
      </table>
    );
    return res;
  }
}

PaymentPin.propTypes = {
  isPinOk: React.PropTypes.bool,
  pin: React.PropTypes.string,
  fee: React.PropTypes.number,
  transactionError: React.PropTypes.string,
  onNext: React.PropTypes.func,
  onBack: React.PropTypes.func,
  showAlert: React.PropTypes.bool,
  pinWrong: React.PropTypes.bool,
  statusSmall: React.PropTypes.string,
  statusLarge: React.PropTypes.string
};
