import createNumber from './common/number';
import createPaymentStatus from './common/payment_status';
import createNotice from './common/notice';
import createAmount from './common/amount';
import createChangeProduct from './common/change_product';
import app_common from './app_common';
import IW from 'imports/iw_api';
import {Session} from 'core/session';
import CONFIG from 'imports/config';


// ["list", "number_twice", "notice", "amount", "pay", "loading", "final", "email", "sent", "printed"]
export default _.extend({}, app_common, {
  index: () => 'change_product',
  change_product: createChangeProduct('number_twice', {simple: 'number_twice', amigo: 'number_twice', internet: 'number_twice'}),
  number_twice: createNumber('exit', 'notice', {
    allowLandline: true,
    withServerValidation: true,
  }),

  notice: createNotice('number_twice', 'inputs'),

  inputs: function({lastAction}){
    function back() {
      return 'number_twice';
    }
    function next(){
      return 'digit'
    }

    if (!IW.appGetVal('account_required')){
      if (lastAction === 'back') return back();
      return next()
    }

    if (IW.appGetVal('account_phone')){
      IW.appSetVal('account1', IW.appGetVal('number'));
      IW.appSetVal('account2', IW.appGetVal('number'));
    }

    startBarcodeScanning();
    return {back, next}
  },

  digit: function({lastAction}){
    function back() {
      return 'inputs';
    }
    function next(){
      if (IW.appGetVal('fixed_amount')) return 'amount';
      return 'bill_amount'
    }

    if (!IW.appGetVal('digits_required')){
      if (lastAction === 'back') return back();
      return next()
    }

    return {back, next}
  },

  bill_amount: createAmount('back', 'pay'),
  bill_amount_again: createAmount('exit', 'pay'),

  payment_status : createPaymentStatus(
    'number_twice',
    () => (IW.appGetVal('fixed_amount') ?
      'amount_again' :
      'bill_amount_again'),
    'pay'),
});

let lastTimeout = -1;
function startBarcodeScanning() {
  function routine() {
    if (IW.getPath() !== checkPath) {
      IW.callCamera('stop', sessionGuid);
      IW.callCamera('scan', sessionGuid);
      return;
    }
    clearTimeout(lastTimeout);
    lastTimeout = setTimeout(routine, 500);
  }

  let checkPath = '';
  let sessionGuid = '';

  clearTimeout(lastTimeout);
  lastTimeout = setTimeout(function () {
    try {
      IW.callCamera('stop');
      IW.callCamera('scan_barcode');
      checkPath = IW.getPath();
      sessionGuid = Session.get('session_guid');
      routine();
    } catch (err) {
      checkPath = ''
    }
  }, 100);
}