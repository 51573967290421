import {Route, Router, Switch} from "react-router-dom";
import {createBrowserHistory} from 'history';
import React from "react";
import _ from 'lodash';

export const history = createBrowserHistory();
const matchPaths = [];
window._history = history;

export const RootRouter = () => {
  return (
    <div id="router-container">
      <Router history={history}>
        <Switch>
          {
            matchPaths.map((item, i) => (
              <Route key={i} exact path={item.path} render={(params) => {
                const paramsCopy = _.extend({}, params.match.params);
                const Component = item.config.action(params.match.params)
                Session.set('_allParamsRouter', paramsCopy);
                return Component
              }}/>
            ))
          }
        </Switch>
      </Router>
    </div>
  )
}

export const FlowRouter = {
  notFound: null,
  group(obj) {
    this.basePrefix = this.basePrefix || '';
    this.prefix = _.get(obj, 'prefix', '');
    return {...this, basePrefix: this.basePrefix + this.prefix};
  },
  route(path, config) {
    path = this.basePrefix + path;
    const pathRegex = new RegExp(`^${path.replace(/\//g, '\\/').replace(/:\w+/g, '([^/]+)')}$`);
    const pathConfig = {
      config, path, pathRegex,
      pathParams: path.match(pathRegex).slice(1).map((p) => p.slice(1))
    };
    matchPaths.push(pathConfig);
    console.log("Route - ", pathConfig);
  },
  current() {
    return {
      path: history.location.path || history.location.pathname,
      params: this.getAllParams(),
    }
  },
  go(name, params) {
    console.log('FlowRouter: go:', name, params)
    const matchPath = _.find(matchPaths, (matchPath) => matchPath.config.name === name || matchPath.pathRegex.test(name));
    if (!matchPath) {
      debugger
      console.error('Bad path:', name)
      return;
    }

    if (matchPath.pathRegex.test(name)) {
      const values = name.match(matchPath.pathRegex).slice(1);
      params = {}
      _.each(matchPath.pathParams, (key, i) => {
        params[key] = values[i]
      })
    }

    const path = _.reduce(matchPath.pathParams,
      (path, key) => params[key] && path && path.replace(`:${key}`, params[key]) || '',
      matchPath.path);
    if (!path) return console.error('FlowRouter: params have error', params);
    history.push(path)
  },
  getParam(paramName) {
    return Session.get(`_allParamsRouter.${paramName}`);
  },
  getAllParams() {
    return Session.get('_allParamsRouter');
  },
  getRouteName() {
    const matchPath = _.find(matchPaths, (matchPath) => matchPath.pathRegex.test(window.location.pathname));
    return matchPath?.config?.name;
  },
  watchPathChange() {

  }
}

export const mount = (Container, args) => <Container {...args} />

window.FlowRouter = FlowRouter;
