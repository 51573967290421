import app_phonereload_base from './app_phonereload_base';

import createNumber from './common/number';
import IW from 'imports/iw_api';
import createAmount from './common/amount';

export default _.extend({}, app_phonereload_base, {
  number: function () {
    return 'sender_number'
  },

  sender_number: createNumber('exit', 'recipient_number', {
    onCreate: function () {
      IW.appSetVal('sender_address_country', 'USA');
      IW.appSetVal('sender_country_iso', 'US');
      IW.appSetLocalVal('number', IW.appGetVal('sender_number') || '');
    }
  }),

  recipient_number: createNumber(() => IW.appGetVal('logged') ?
      'phone_account_choice' :
      'sender_number',
    'amount', {
      onCreate: function () {
        IW.appSetLocalVal('number', IW.appGetVal('recipient_number') || '');
      },
      shouldCreateCustomer: false,
      withServerValidation: true
    }),

  amount: createAmount('recipient_number', 'pay'),
  // After Login
  on_login: function () {
    IW.appSetVal('current_account', {
      product_id: IW.appGetVal('product_id'),
      name: Session.get('title'),
      icon: Session.get('icon'),
      recipient_number: '',
      original_amount: 0,
      product: IW.appGetVal('product'),
      recipient_country_iso: IW.appGetVal('recipient_country_iso'),
    }, true);

    const phoneNumber = IW.appGetVal('phone_number');

    if (!phoneNumber) {
      const customerData = IW.loginGetVal('customer_data_result');

      IW.appSetVal('sender_number', customerData.mobile_number);
      IW.appSetVal('phone_number', customerData.mobile_number);
      IW.appSetVal('number', customerData.mobile_number);
      IW.appSetVal('sender_address_country', customerData.address_country)
    }

    return "phone_account_choice"
  },

  phone_account_choice: function*({lastAction}) {
    let account = IW.appGetVal('current_account') || {};
    IW.appSetObject(account);
    Session.set('icon', account.icon);
    Session.set('title', account.name);
    Session.set('accounts', null);
    if (IW.appGetVal('logged')) {
      IW.callWithUpdate('getAccounts', 'accounts');
      yield 'loading session accounts';
    }
    if (!Session.get('accounts') || !Session.get('accounts').length) {
      return lastAction === 'back' ?
        'exit' :
        'recipient_number';
    }

    return {
      back: function () {
        return 'exit';
      },

      next: function () {
        const accountId = +IW.appGetVal('account_id');
        account = _.find(Session.get('accounts'), (a) => a.account_id === accountId);
        if (!account) return 'exit';
        IW.appSetObject(account || {});

        Session.set('icon', account.icon);
        Session.set('title', account.name);

        return 'pay';
      },

      skip: function () {
        return 'recipient_number';
      }
    }
  }
});
