import React, {Component} from 'react';
import SlideItem from 'imports/ui/shared/slider/SlideItemContainer';
// import PaginationCommon from 'imports/ui/shared/pagination/PaginationContainer';
import Pagination4Dots from 'imports/ui/shared/pagination/Pagination4DotsContainer';
import {initSlider} from 'imports/lib/helpers';
import {MAX_DOTS_SLIDER, PAGINATOR_SLIDER_MODE, SLIDE_ITEM_TYPES} from 'imports/lib/constants';
import {gaEvent} from "imports/lib/ga";

export default class Slider extends Component {
  constructor(props) {
    super(props);

    this.id = 'Slider-' + Math.random().toFixed(5).slice(2);
    this.handleClickDot = this.handleClickDot.bind(this);
    this.linkToSliderObj = null;
  }

  componentDidMount() {
    const slider = initSlider('#' + this.id, {navSlideSelector: null}, PAGINATOR_SLIDER_MODE.FOUR_DOTS);
    this.linkToSliderObj = slider && slider[0] && $(slider[0]);
  }

  componentDidUpdate() {
    const slider = initSlider('#' + this.id, {navSlideSelector: null}, PAGINATOR_SLIDER_MODE.FOUR_DOTS);
    this.linkToSliderObj = slider && slider[0] && $(slider[0]);
  }

  shouldComponentUpdate(newProps) {
    return JSON.stringify(this.props.apps) !== JSON.stringify(newProps.apps);
  }

  handleClickDot(i) {
    const {apps, gaCategory} = this.props;
    let slideNum = i;

    if (apps.length > MAX_DOTS_SLIDER) {
      if (i === MAX_DOTS_SLIDER) {
        slideNum = apps.length;
      } else if (i === MAX_DOTS_SLIDER - 1) {
        slideNum = apps.length - 1;
      }
    }

    if (gaCategory) gaEvent(gaCategory, 'Slider dots click', String(i));
    this.linkToSliderObj.iosSlider('goToSlide', slideNum);
  }

  render() {
    const {apps, type, pages, gaCategory} = this.props;

    return (
      <div id={this.id} className="sliderContainer">
        <div className="iosSlider">
          <div className={apps.length > 1 ? "slider" : ""}>
            {_.map(apps, (slides, i) => {
              return <SlideItem key={i} type={type} slides={slides} pages={pages} gaCategory={gaCategory}/>
            })}
          </div>
        </div>
        <Pagination4Dots apps={apps} onClickDot={this.handleClickDot}/>
      </div>
    );
  }
}

Slider.propTypes = {
  apps: React.PropTypes.arrayOf(React.PropTypes.arrayOf(React.PropTypes.array)),
  type: React.PropTypes.string,
  pages: React.PropTypes.object,
  height: React.PropTypes.number,
  marginBottom: React.PropTypes.number,
};

Slider.defaultProps = {
  type: SLIDE_ITEM_TYPES.ICON
};
