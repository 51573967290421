import {Session} from 'core/session';
import IW from 'imports/iw_api';
import Products from 'imports/collections/products';
import CONFIG from 'imports/config';

export default function (pageNext, pages) {
  return function () {
    const productId = Session.get('product_id');
    const productsMap = {};

    // TODO: Products can be disabled
    _.each(CONFIG.BINDED_PRODUCTS, (p) => {
      if (!_.find(p, (v) => v === productId)) return;
      _.each(p, (v, k) => {
        const product = Products.findOne({product_id: v});
        if (!product || !product.enabled) return;
        productsMap[k] = v;
        if (v === productId) {
          productsMap.current = k;
        }
      })
    });

    if (_.keys(productsMap).length < 3 || !productsMap.current) return pageNext;

    IW.appSetLocalVal('change_product_map', productsMap);

    return {
      back: 'exit',
      next: function () {
        const selected = IW.appGetVal('change_product_selected');
        if (!pages[selected] || selected === productsMap.current) return pageNext;
        IW.launchProduct(productsMap[selected], pages[selected]);
        return 'loading';
      }
    }
  }
}
