import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import AppIcon from './AppIcon';
import IW from 'imports/iw_api';
import getPageManager, {goTo} from 'imports/lib/page_manager';
import appWorkflow from 'imports/apps_workflow'

export default createContainer((params) => {

  function onTapIcon(event) {
    const icon = params.icon;
    // Ignore mousedown for slider items
    if ((event.type === 'mousedown') && $('.iosSlider').data('iosslider')) return;
    if(_.has(icon, "account_id")) IW.appSetVal('account_id', icon.account_id);
    getPageManager().next(params);

    return event.preventDefault();
  }

  return {
    isSimpleItem: true,
    icon: params.icon,
    onTapIcon: onTapIcon
  };

}, AppIcon);
