import {Session} from 'core/session'

export default function (pageBack, pageNext) {
  return function() {

    Session.set('prev_current_active_field', 0);
    Session.set('current_active_field', 0);
    
    return {
      back: function () {
        return pageBack;
      },

      next: function () {
        return pageNext;
      }
    }
  }
}
