import createNumber from './common/number';
import createAmount from './common/amount';
import createPaymentStatus from './common/payment_status';
import app_common from './app_common';
import {FlowRouter} from 'core/Router';
import IW from '../iw_api';
import createPayDevice from './common/payDevice';
import createPayVirtual from './common/payVirtual'


// import Prods from 'imports/collections/products';

// ["gifts", "amount", "number", "emailInner", "pay", "loading", "final", "email", "sent"]

export default _.extend({}, app_common, {
  gifts: {},
  number: createNumber('exit', 'alder_recipient_data'),
  alder_recipient_data: {back: 'number', next: 'redirect'},

  amount: createAmount(() => IW.appGetVal('pay_again_selected') ?
    'exit' :
    'alder_recipient_data', 'pay'),

  range_amount: {
    back: () => IW.appGetVal('pay_again_selected') ?
      'exit' :
      'alder_recipient_data',
    next: 'pay'
  },

  redirect: ({lastStep}) => {  // redirect bridge
    if (lastStep !== 'pay' && lastStep !== 'alder_recipient_data') IW.appSetLocalVal('pay_again_selected', true);
    return IW.appGetVal('is_fixed_amount') ?
      'amount' :
      'range_amount'
  },

  pay_device: createPayDevice('back', 'final'),
  pay_virtual: createPayVirtual('back', 'final', null, null, 'amount_clerk'),

  payment_status: createPaymentStatus('number', 'redirect', 'pay'),

  /* After login */

  on_login:  function () {
    const customerData = IW.loginGetVal('customer_data_result');

    const sessionNumber = IW.appGetVal('phone_number') || IW.appGetVal('recipient_phone_number');
    if(!sessionNumber){
      IW.appSetVal('recipient_number', customerData.mobile_number);
      IW.appSetVal('phone_number', customerData.mobile_number);
      IW.appSetVal('number', customerData.mobile_number);
    }

    const sessionFirstName = IW.appGetVal('recipient_first_name');
    if(!sessionFirstName){
      IW.appSetVal('recipient_first_name', customerData.first_name);
    }

    const sessionLastName = IW.appGetVal('recipient_last_name');
    if(!sessionLastName){
      IW.appSetVal('recipient_last_name', customerData.last_name);
    }

    if(!(IW.appGetVal('recipient_first_name') && IW.appGetVal('recipient_last_name'))){
      return 'alder_recipient_data_after_login'
    }

    return 'redirect'
  },

  alder_recipient_data_after_login: {back: 'exit', next: 'redirect'},
});