import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import CardSimple from 'imports/ui/shared/card/CardContainer';
import safeRender from 'imports/ui/decorators/safeRender';

class TransferCash extends Component {
  render() {

    const { onBack, onCardClick, transferTypes } = this.props;

    const Card = CardSimple;

    return (
      <table>
        <tbody>
        <tr>
          <td >
            <div className="atm-accounts">
              {transferTypes.map((item, i) => {
                return <Card key={i} className="atm-transfer_card" iconUrl={item.iconUrl}
                             text_={item.text} additional={i+1}
                             direction="col" onCardClick={onCardClick.bind(this, item.from, item.to)}/>
              })}
            </div>
          </td>
        </tr>
        </tbody>
        <tfoot className="atm-foot">
        <tr>
          <td colSpan="2" >
            <Button id="back" className="left button" text_="Back" onMouseDown={ onBack }/>
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

TransferCash.propTypes = {
  onBack : React.PropTypes.func,
  onCardClick: React.PropTypes.func,
  transferTypes: React.PropTypes.array
};

TransferCash.defaultProps = {
};

export default TransferCash;
