import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';

export default class BillScanningError extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {onBack, onNext, showAccountNumber, imagePath} = this.props;

    return (
      <table>
        <tbody>
        <tr>
          <td colSpan="2" style={{padding: "40px", textAlign: "center", verticalAlign: "middle"}}>
            {imagePath && <img id="bill-photo" src={imagePath}/>}
            <h2 _>{'The image is not processed.'}</h2>
            {showAccountNumber ? <h2 _>{'Do you want to enter account number manually?'}</h2> : null}
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td>
            <Button id="back" className="left button goto-main" text_={'Try Again'} onMouseDown={onBack}/>
          </td>

          {showAccountNumber ? <td>
            <Button id="next" className="right button goto-main" text_={'Enter manually'} onMouseDown={onNext}/>
          </td> : null }
        </tr>
        </tfoot>
      </table>
    );
  }
}