import React from 'react';
import Time from '../shared/time/TimeContainer';
import Button from 'imports/ui/shared/button/Button';
import getPageManager, {goTo} from 'imports/lib/page_manager';
import IW from 'imports/iw_api';

export default class AppHeader extends React.Component {
  nextPage = () => {
    window.updateTranslationState && window.updateTranslationState(true);
    getPageManager().next();
  };

  prevPage = () => {
    window.updateTranslationState && window.updateTranslationState(true);
    getPageManager().back();

  };

  switchState = () => window.updateTranslationState && window.updateTranslationState();

  render() {
    const {pagesList, currentPage, allowStateSwitch} = this.props;

    return (
      <tr>
        <td>
          <table cellSpacing="0">
            <tbody>
            <tr className="appMain">
              <td>
                <Button id="menu" className="button button-header button-danger" onMouseDown={() => IW.mainMenu()}
                        text_="Menu"/>
              </td>
              <td className="translation-header-td">
                <div className="translation-header">
                  <select className="translation-select"
                          name="pages" id="pages" onChange={(e) => goTo(e.target.value)} value={currentPage}>
                    {_.map(pagesList, (page) => <option value={page} key={page}>{page}</option>)}
                  </select>
                  <Button id="prev_app" className="button button-blue"
                          onMouseDown={this.prevPage} text_="Prev"/>
                  <Button id="next_app" className="button button-blue" onMouseDown={this.nextPage} text_="Next"/>
                  {allowStateSwitch && (
                    <Button id="switch" className="button button-blue button-translation-state"
                            onMouseDown={this.switchState}
                            icon={<img className="translation-state-icon" src="/resources/images/login/ic_resend.png"/>}
                            icon_placement="after"
                            text="State"
                    />)}
                </div>
              </td>
              <td>
              </td>
            </tr>
            </tbody>
          </table>
          <div className="app">
            <Time/>
          </div>
        </td>
      </tr>
    )
      ;
  }
}

AppHeader.propTypes = {
  title: React.PropTypes.string,
  style: React.PropTypes.object,
  classLabel: React.PropTypes.string,
  onMenu: React.PropTypes.func,
  onPrevApp: React.PropTypes.func,
  onNextApp: React.PropTypes.func,
  menuEnabled: React.PropTypes.bool,
  pagesList: React.PropTypes.array,
};

