import IW from 'imports/iw_api'
import {throttle, debounce } from 'lodash';
import {Tracker} from 'core/tracker';
import {Session} from 'core/session';
import {FlowRouter} from 'core/Router';

let lastSessionGuid = '';
let count = 0;
const takeScreenShot = (sessionGuid) => {
  IW.takeScreenShot(sessionGuid || lastSessionGuid);
  if (!sessionGuid) count++;
  else count = 0;
  if (count === 1) lastSessionGuid = sessionGuid || lastSessionGuid;
  else lastSessionGuid = sessionGuid;
};

const takeScreen = throttle(takeScreenShot, 20, {trailing: false});
const takeScreenBack = debounce(takeScreen, 500, {maxWait: 1000});

const MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

const observer = new MutationObserver(function() {
  const sessionGuid = Session.get('session_guid');
  setTimeout(() => {
    takeScreen(sessionGuid);
    takeScreenBack(sessionGuid);
  }, 20)
});

observer.observe(window.document.querySelector('body'), {
  subtree: true,
  attributes: true,
  childList: true
});