import {Session} from 'core/session'
import IW from "../../iw_api";
import CONFIG from 'imports/config';

export default function (pageEmail) {
  return function* () {
    // Popular products fetch for advertisement
    if (CONFIG.SHOW_POPULAR_PRODUCTS_ADVERTISEMENT && !IW.appGetVal('popular_products_id')) {
      IW.call('getPopularProductsId');
      yield 'loading app popular_products_id';
    }

    Session.set('session_completed', true);

    return {
      email: pageEmail,
      next: () => {
        IW.launchSweepstakes({customer_id: IW.appGetVal('customer_id')});
        return 'loading';
      }
    };
  }
}
