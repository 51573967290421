import {createContainer} from 'imports/lib/react_createContainer';
import AnyBillIcon from './AnyBillIcon';
import {FlowRouter} from 'core/Router';
import IW from 'imports/iw_api';
import getPageManager, {goTo} from 'imports/lib/page_manager';
import appWorkflow from 'imports/apps_workflow'

export default createContainer((params) => {

  function onTapIcon(event, app) {

    const app_guid = app.app_guid;
    // Ignore mousedown for slider items
    if ((event.type == 'mousedown') && $('.iosSlider').data('iosslider')) return;

    const startPage = appWorkflow[app_guid] && appWorkflow[app_guid][app.start_page] ?
      app.start_page :
      null;

    // console.log("There's no app currently active, launching a new app session");
    const data = {
      icon: app.icon,
      app_icon: app.app_icon,
      title: app.title,
      product_id: app.product_id || 0, // product_id
      page: startPage,
      feed: app.feed,
      customer_id: app.customer_id,
      user: app.user
    };

    IW.appLaunch(app_guid, data);
    goTo(startPage || 'exit', app_guid, String(app.product_id || '0'));

    return event.preventDefault();
  }

  return {
    onTapIcon,
    app: params.item
  }

}, AnyBillIcon);
