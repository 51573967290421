import React, {Component} from 'react';
import Tabs from 'imports/ui/apps/tabbar/Tabs';

export default class Tabbar extends Component {

  componentWillMount() {
    //Set styles for current app
    $('#css').text(this.props.css);
  }

  componentDidMount() {

  }

  render() {
    const {pages, css, activeTab, components} = this.props;
    const CurrentWrapper = components[activeTab];

    return (
      <table className="homies">
        <tbody>
        <tr>
          <td colSpan="1" style={{height: "100px", padding: 0}}>
            <Tabs {...this.props} />
          </td>
        </tr>
        <tr>
          <td>
            <CurrentWrapper {...this.props}/>
          </td>
        </tr>
        </tbody>
      </table>
    )
  }
}

Tabbar.propTypes = {
  appGuid: React.PropTypes.string,
  pages: React.PropTypes.object,
  css: React.PropTypes.string,
  setActiveTab: React.PropTypes.func,
  components: React.PropTypes.object
};

// Specifies the default values for props:
Tabbar.defaultProps = {
  css: '/* no apps open */'
};
