import React, {Component} from 'react';
import Loading from 'imports/ui/shared/loading/Loading';


export default class OpenMaya extends Component {
  render() {
    const {startUrl, visible} = this.props;
    const style = visible ?
      {top: 0, bottom: 0} :
      {top: 'calc(0 - var(--main-height))', bottom: 'var(--main-height)'};
    return (
      <div>
        <Loading/>
        <iframe id="open-maya-frame" src="/themes/mayafs/open_maya.html"
                name={startUrl}
                scrolling="no"
                style={style}/>
        {/*this is huck to make iframe be rendered*/}
        <div style={{position: 'absolute'}}></div>
      </div>
    );
  }
}

OpenMaya.propTypes = {
  startUrl: React.PropTypes.string,
  visible: React.PropTypes.bool,
};
