import {Session} from 'core/session';
import IW from 'imports/iw_api';
import {FlowRouter} from 'core/Router';
import * as Timer from 'imports/api/timer';
import {N, S} from 'imports/lib/helpers';
import CONFIG from 'imports/config';
import {nextWhen} from '../../lib/page_manager';

export default function (pageBack, pageNext, pageLoading, pageError = 'payment_status') {
  return function* () {
    IW.appSetLocalVal('customer_update_discount_done', false);

    if (Session.get('app_guid') !== 'app_moneytransfer_sigue') {
      IW.appSetVal('customer_maya5_enabled', CONFIG.MAYA5_ENABLED);
      IW.appSetVal('customer_referrals_enabled', CONFIG.REFERRALS_ENABLED);
      IW.appSetVal('customer_first_payment_enabled', CONFIG.FIRST_PAYMENT_ENABLED);
      IW.appSetVal('customer_welcome_back_enabled', CONFIG.WELCOME_BACK_ENABLED);
      IW.appSetVal('customer_free_transaction_enabled', CONFIG.FREE_TRANSACTION_ENABLED);
    }

    IW.call('updateCustomerBalance');
    IW.updateCustomerDiscount(() => IW.appSetLocalVal('customer_update_discount_done', true));
    yield 'loading app customer_update_discount_done';

    IW.appSetVal('payment_guid', '');
    IW.call('initPayment');
    yield 'loading app payment_guid';

    IW.appSetLocalVal('paid', false);

    IW.appSetLocalVal('available_coins', null);
    IW.appSetLocalVal('max_payout', 0);
    IW.appSetLocalVal('last_try_pay_hash', '');
    IW.appSetLocalVal('start_amount', N(IW.appGetVal('amount')));

    //Pin DEBIT card
    IW.appSetLocalVal('terminal_init_status_done', false);
    if (_.contains(CONFIG.PAY_METHODS, 'card')) {
      IW.appSetLocalVal('withdrawal_success', false);
      IW.appSetLocalVal('card_insert', false);
      IW.appSetLocalVal('card_data_received', false);
      IW.appSetLocalVal('pin_wrong', false);
      IW.appSetLocalVal('card_removed', false);
      IW.appSetLocalVal('card_reader_status_success', false);
      IW.appSetLocalVal('epp_status_success', false);

      IW.appSetLocalVal('card_reader_status_done', false);
      IW.appSetLocalVal('epp_status_done', false);

      IW.callCardReader('status');
      IW.callEPP('status');

      yield 'loading app card_reader_status_done';
      yield 'loading app epp_status_done';

      if (IW.appGetVal('card_reader_status_success') && IW.appGetVal('epp_status_success')) {
        IW.call('initPinDebitTerminal');
        yield 'loading app terminal_init_status_done';
      }
    }

    if (!IW.appGetVal('congratulation_showed') && (
      IW.appGetVal('discount_type') === 'first_payment' ||
      IW.appGetVal('discount_type') === 'welcome_back' ||
      IW.appGetVal('discount_type') === 'maya5' ||
      IW.appGetVal('discount_type') === 'free_transaction') &&
      IW.appGetVal('discount_amount') < 0 &&
      IW.appGetVal('amount') <= 0) {
      IW.appSetLocalVal('congratulation_showed', true);
      yield 'sleep 4500 maya5_congratulation';
    }

    // TODO: No loading after this method
    if (IW.appGetVal('terminal_init_status_success')) {
      runCardReader();
    }
    runBillAcceptor();

    return {
      back: function () {
        IW.appSetVal('payment_guid', '');
        return pageBack;
      },

      tryPay: function* () {
        const totalAmountInserted = N(IW.appGetVal('amount'));
        const amountToLoad = N(IW.appGetVal('requested_amount'));
        if (IW.appGetVal('last_try_pay_hash') === getTryPayHash()) return 'none';
        IW.appSetLocalVal('last_try_pay_hash', getTryPayHash());

        if (IW.appGetVal('paid') || totalAmountInserted < amountToLoad || amountToLoad === 0) {

          if (IW.appGetVal('card_insert')) {
            IW.appSetLocalVal('payment_pin_payment_page', FlowRouter.getParam('step'))
            yield 'loading 500';
            return 'payment_pin';
          }

          IW.callBillAcceptor('status', (e, res) => {
            if (_.isEmpty(res)) return;
            IW.appSetLocalVal('available_coins', res.available_coins);
            IW.appSetLocalVal('max_payout', res.max_payout);
          });
          return 'none'
        }
        IW.appSetLocalVal('paid', true);
        IW.appSetLocalVal('add_cash_to_balance_timeout_result', 'none');

        if (IW.getUISettings().print_receipt_automaticaly) {
          IW.appSetVal('print_receipt_automatically', true);
          IW.appSetLocalVal('receipt_was_printed', true);
        }

        yield 'sleep 333';
        IW.appSetVal('session_stored', false);
        IW.paymentDo();

        if (pageLoading) return pageLoading;

        IW.appSetLocalVal('payment_status', '');

        if (CONFIG.SHOW_POPULAR_PRODUCTS_ADVERTISEMENT) {
          IW.appSetLocalVal('popular_products_id', null)
          IW.call('getPopularProductsId');
        }

        const startTime = Date.now();
        yield nextWhen(() => Date.now() - startTime > 5000);
        yield 'adv_loading';

        for (let i = 0; i < 10 && N(IW.appGetVal('amount')) > amountToLoad; i++) {
          yield 'loading 2000';
        }

        yield 'loading app payment_status';

        if (IW.appGetVal('payment_status') !== 'paid' && !IW.appGetVal('receipt_was_printed')) {
          IW.printReceipt();
          IW.appSetLocalVal('receipt_was_printed', true);
        }

        return IW.appGetVal('payment_status') === 'paid' ?
          pageNext :
          pageError
      },

      continueLater: function* () {
        yield 'loading 4000'; // wait for case when customer miss-click at the end of payment
        for (let i = 0; i < 20 * 2 * 500 && N(IW.appGetVal('actual_escrow_amount')) > 0; i++) {
          yield 'loading 500';
        }
        const totalAmountInserted = N(IW.appGetVal('amount'));
        const amountToLoad = N(IW.appGetVal('requested_amount'));

        if (IW.appGetVal('paid') || totalAmountInserted === 0 || totalAmountInserted >= amountToLoad) {
          return 'none' // redirect to current page for tryPay processing
        }

        IW.appSetLocalVal('paid', true);
        IW.appSetLocalVal('session_stored', false);
        IW.appSetVal('print_receipt_automatically', true);
        IW.appSetLocalVal('receipt_was_printed', true);
        IW.call('storeSession');

        yield 'loading app session_stored';
        return pageLoading || pageNext
      },

      referral: () => 'referral_code',
      maya5: () => 'maya5_description'
    }
  }
}

const routineTimers = {};

function runBillAcceptor() {
  function routine() {

    if (Timer.isTimeout() && isEnabled) {
      stop();
      isEnabled = false
    }

    if (!Timer.isTimeout() && !isEnabled) {
      start();
      isEnabled = true
    }

    if (hash === getLocationHash(DEVICE_ENABLED_PAGES.bill_acceptor)) {
      clearTimeout(routineTimers.billAcceptor);
      routineTimers.billAcceptor = setTimeout(routine, 100);
      return;
    }

    stop()
  }

  function start() {
    IW.callBillAcceptor('enable', sessionGuid);
  }

  function stop() {
    IW.callBillAcceptor('disable', sessionGuid);
  }

  let hash = '';
  let sessionGuid = '';
  let isEnabled = false;

  clearTimeout(routineTimers.billAcceptor);
  routineTimers.billAcceptor = setTimeout(function () {
    sessionGuid = Session.get('session_guid');
    hash = getLocationHash(DEVICE_ENABLED_PAGES.bill_acceptor);
    start();
    isEnabled = true;
    routine();
  }, 100)
}

function runCardReader() {
  function routine() {
    if (hash === getLocationHash(DEVICE_ENABLED_PAGES.card_reader)) {
      clearTimeout(routineTimers.cardReader);
      routineTimers.cardReader = setTimeout(routine, 100);
      return;
    }

    stop()
  }

  function start() {
    IW.callCardReader('receive_debit_card', sessionGuid);
  }

  function stop() {
    IW.appSetVal('card_insert', false);
    IW.callCardReader('close', sessionGuid);
    IW.callEPP('close', sessionGuid);
  }

  let hash = '';
  let sessionGuid = '';

  clearTimeout(routineTimers.cardReader);
  routineTimers.cardReader = setTimeout(function () {
    sessionGuid = Session.get('session_guid');
    hash = getLocationHash(DEVICE_ENABLED_PAGES.card_reader);
    start();
    routine();
  }, 100)
}

function getLocationHash(availablePages) {
  const params = FlowRouter.current().params;
  return Session.get('session_guid') + params.appName + params.language + params.productId +
    IW.appGetVal('paid') + IW.appGetVal('withdrawal_success') +
    (_.contains(availablePages, params.step) ?
      '_available_page_' :
      params.step);
}

function getTryPayHash() {
  return S(N(IW.appGetVal('amount'))) + S(N(IW.appGetVal('requested_amount'))) + S(IW.appGetVal('card_insert'))
}

const DEVICE_ENABLED_PAGES = {
  bill_acceptor: ['pay', 'pay_device', 'pay_again', 'pay_transfer', 'pay_transfer_again'],
  card_reader: ['payment_pin', 'pay', 'pay_device', 'loading', 'pay_again', 'pay_transfer', 'pay_transfer_again']
}