/**
 * Created by vitaliy on 09.06.17.
 */
import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import IW from 'imports/iw_api';
import CustomerItem from './CustomerItem';
import getPageManager from 'imports/lib/page_manager';
import { COUNTRY_FLAGS_BY_ISO2 } from 'imports/lib/constants';
import { capitalize } from 'imports/lib/helpers';

export default createContainer((params) => {

  const onClick = function (ev) {
    if ((ev.type === 'mousedown') && $('.iosSlider').data('iosslider')) return;
    IW.appSetLocalVal('customer_item_id', params.item.id);
    getPageManager().next();
  };

  return {
    subname: params.item.subname,
    name: params.item.name,
    productIcon : params.item.productIcon,
    icon: params.item.icon || '/resources/images/login/image_holder_transparent.png',
    subicon: params.item.subicon,
    defaultPayerIcon: params.item.defaultPayerIcon,
    onClick
  };

}, CustomerItem);
