import app_common from "./app_common";
import createNumber from "./common/number";
import IW from "../iw_api";
import {Session} from "core/session";

import createAmount from "./common/amount";

const includedFields = ['address1', 'address2', 'address_city',
  'address_country', 'address_state', 'address_zip', 'email', 'first_name', 'last_name',
  'middle_name', 'mobile_number'];
const requiredFields = [['mobile_number', 'email'], 'first_name', 'last_name'];

/**
 * Local session variables
 *
 * Customer edit flags:
 * customer_data_is_search_end
 * customer_data_is_editing_complete
 *
 * Routing helpers:
 * customer_data_is_not_enough_data
 */
export default _.extend({}, app_common, {
  index: () => 'number_login',

  number_login: createNumber('exit', 'redirect', {allowLandline: true}),

  // Switch between customer data flow
  redirect: function* ({lastAction}) {
    if (lastAction === 'back') return 'number_login';

    const customerId = IW.appGetVal('customer_id');
    const phoneNumber = IW.appGetVal('phone_number');
    IW.call('doCustomerSearch', {customerId, phoneNumber});
    yield 'loading app customer_data_is_customer_search_done';
    IW.appClearVal('customer_data_is_customer_search_done');

    if (!IW.appGetVal('customer_data_is_customer_exist_api') && !IW.appGetVal('customer_data_is_customer_exist_local')) {
      return 'on_login';
    }

    return 'on_launch_login';
  },

  on_login: function* () {
    IW.call('checkCustomerData');
    yield 'loading app customer_data_is_customer_check_done';
    IW.appClearVal('customer_data_is_customer_search_done');

    return IW.appGetVal('customer_data_is_not_enough_data') ?
      'pay_here_enter_user_data' :
      'pay_here_confirm_customer_data'
  },

  pay_here_confirm_customer_data: function () {
    const backPage = IW.appGetVal('customer_data_is_not_enough_data') ?
      'pay_here_email' :
      'number_login';

    return {
      back: backPage,
      edit: 'pay_here_enter_user_data',
      next: function* () {
        if (!checkRequiredField(requiredFields, 'customer_data')) return 'pay_here_enter_user_data';
        const customerData = getValuesFromSession(includedFields, 'customer_data');
        customerData.customer_id = IW.appGetVal('customer_id');
        IW.call('updateCustomer', customerData, (__, r) => {
          IW.appSetLocalVal('customer_data_customer_updated', true);
          IW.appSetLocalVal('customer_data_is_not_enough_data', false);
        });

        yield 'loading app customer_data_customer_updated';

        return 'inputs'
      }
    }
  },

  pay_here_enter_user_data: function () {
    Session.set('prev_current_active_field', 0);
    Session.set('current_active_field', 0);

    const backPage = IW.appGetVal('customer_data_is_not_enough_data') ?
      'number_login' :
      'on_login';

    return {
      back: backPage,
      next: 'pay_here_email'
    }
  },

  pay_here_email: function () {
    return {
      back: 'pay_here_enter_user_data',
      next: 'pay_here_confirm_customer_data'
    }
  },

  inputs: function () {
    IW.appSetVal('account1', '');
    IW.appSetVal('account2', '');
    return {
      back: 'pay_here_confirm_customer_data',
      next: 'bill_amount',
    }
  },

  bill_amount: createAmount('back', 'pay'),

});

/**
 * Get values from session
 *
 * @param {Array.<string[] | string>} valuesToGet - Keys to get from session. If value is array, then zero element key in object, first key in session.
 * @param {string} prefix - Prefix to keys in session.
 *
 * @return {{}} - Customer Data
 */
function getValuesFromSession(valuesToGet, prefix) {
  const result = {};

  _.each(valuesToGet, (val) => {
    const isArray = _.isArray(val);
    const kayInObject = isArray ? val[0] : val;
    const keyInSession = `${prefix}_${isArray ? val[1] : val}`;

    result[kayInObject] = IW.appGetVal(keyInSession)
  })

  return result;
}

/**
 * Check is values exist in session.
 *
 * @param {Array.<string[] | string>} fields - Fields to check in session. If array only one key must be true.
 * @param {string} prefix - Prefix to keys in session.
 *
 * @return {boolean} - Is all values exist.
 */
function checkRequiredField(fields, prefix) {
  function check(key) {
    const value = IW.appGetVal(key);
    if (_.isUndefined(value) || _.isEmpty(value)) return false;
    return true;
  }

  return _.every(fields, (field) => {
    const isArray = _.isArray(field);

    if (isArray) {
      return _.some(field, (f) => {
        const keyInSession = `${prefix}_${f}`;
        return check(keyInSession)
      })
    }

    const keyInSession = `${prefix}_${field}`;
    return check(keyInSession)
  })
}
