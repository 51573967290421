/**
 * Created by vitaliy on 20.03.17.
 */
import {createContainer} from 'imports/lib/react_createContainer';
import NameValueDataDisplay from '../namevalue_display/NameValueDataDisplay';
import IW from 'imports/iw_api'
import {_} from 'imports/lib/helpers'

export default createContainer((params) => {
  let method = '';
  let payerType = '';
  let payerName = '';

  if (IW.appGetVal('recipient_payment_mode_type') === 'cash_pickup') method = _('Cash Pick Up');
  if (IW.appGetVal('recipient_payment_mode_type') === 'delivery') method = _('Delivery');
  if (IW.appGetVal('recipient_payment_mode_type') === 'bank_deposit') method = _('Bank Deposit');

  if(IW.appGetVal('payer_bank_name')){
    payerType = 'Bank name: ';
    payerName = IW.appGetVal('payer_bank_name');
  }
  if(IW.appGetVal('payer_name')){
    payerType = 'Payer name: ';
    payerName = IW.appGetVal('payer_name');
  }

  const items = [
    { description : 'Method: ', data : method },
    { description : payerType, data : payerName },
    { description : 'Account: ', data : IW.appGetVal('bank_account') },
    { description : 'Routing: ', data : IW.appGetVal('bank_routing_number') },
    { description : ' ', data : ' ' }
  ];

  return {
    disclosure: params.disclosure,
    items,
    displayButtons: true,
    displayPrintButton: !IW.appGetVal('prev_amount') || (IW.appGetVal('original_amount') !== IW.appGetVal('prev_amount')
      && !IW.appGetVal('print_disclosure')) && !IW.isVirtual()
  }
}, NameValueDataDisplay);
