import IW from 'imports/iw_api';
import {S} from 'imports/lib/helpers'
import {VIAMERICA_INTERNAL_ERRORS} from '../../lib/constants';

export default function (pageBack, pageNext, onLoginNext, createSender = () => {}) {
  return function () {

    IW.appSetVal('confirm_sender_data', true);

    return {
      edit: pageBack,
      next: function* () {
        yield *createSender();

        if (IW.appGetVal('validation_error')) {
          if (VIAMERICA_INTERNAL_ERRORS[IW.appGetVal('validation_error')]) {
            return pageBack
          }

          IW.appSetLocalVal('transaction_error_messages', [IW.appGetVal('validation_error')]);
          return 'transaction_error_moneytransfer'
        }
        if (IW.appGetVal('logged')) return onLoginNext;

        return pageNext;
      }
    }
  }
}
