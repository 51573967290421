import {createContainer} from 'imports/lib/react_createContainer';
import PaymentPin from './PaymentPin';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';
import {_ as i18n, usd, S, N} from 'imports/lib/helpers';
import {sprintf} from 'imports/lib/external/sprintf';

const MIN_PIN_DIGITS = 4;

export default createContainer((params) => {
  const currentKey = IW.appGetVal('pad_key');
  IW.appSetLocalVal('pad_key', '');

  function updatePadInput(){
    if (!IW.appGetVal('epp_set_pin_mode_success')) return;

    if (currentKey === 'ENTER') {
      IW.appSetLocalVal('pad_entered', true);
      return getPageManager().next()
    }

    if (currentKey === 'CANCEL') {
      IW.appSetLocalVal('pad_canceled', true);
      return getPageManager().cancel()
    }

    if (currentKey === '#') {
      return getPageManager().back()
    }

    let padPin = IW.appGetVal('pad_pin') || '';

    if (currentKey === '*') {
      padPin += '*';
    }

    if (currentKey === 'CLEAR') {
      padPin = padPin.slice(0, -1);
    }

    if (currentKey === 'DELETE') {
      padPin = '';
    }

    IW.appSetLocalVal('pad_pin', padPin);
  }

  const amount = N(N(IW.appGetVal('requested_amount')) - N(IW.appGetVal('amount')));

  const alertsSmall = {
    withdrawal_success: [i18n('Operations Successfully Completed'), i18n('Thank you!')],
    withdrawal_not_enough: [i18n('Sorry, you do not have enough money to take'), usd(amount)],
    transaction_error: [i18n(`We can't process the transaction`), i18n(IW.appGetVal('transaction_status'))],
    card_insert_error: [i18n(`We can't read your card`), i18n('Please try again')],
    return_card_internal_error: [i18n(`There's a temporary error`), i18n('Please try again later')],
    take_your_card: ['', i18n('Take your card')],
  };

  updatePadInput();
  //Clear pin wrong message
  if(IW.appGetVal('pad_pin').length > 0) IW.appSetVal('pin_wrong', false);

  const currentStatus = alertsSmall[IW.appGetVal('withdrawal_alert')] || [];

  return {
    pin: IW.appGetVal('pad_pin'),
    isPinOk: IW.appGetVal('pad_pin').length >= MIN_PIN_DIGITS,
    pinWrong: !!(IW.appGetVal('pin_wrong')),
    showAlert: !!IW.appGetVal('withdrawal_alert'),
    showButtons: !IW.appGetVal('withdrawal_alert') ||
      (_.contains(_.keys(alertsSmall), IW.appGetVal('withdrawal_alert')) && IW.appGetVal('withdrawal_alert') !== 'take_your_card'),
    fee: IW.appGetVal('pin_debit_fee'),
    statusSmall: S(currentStatus[0]),
    statusLarge: S(currentStatus[1]),
    onBack: () => getPageManager().back(),
    onNext: () => getPageManager().next(),
  }
}, PaymentPin)
