/**
 * Created by vitaliy on 10.03.17.
 */
import {createContainer} from 'imports/lib/react_createContainer';
import TransferDescription from './TransferDescription';

export default createContainer((params) => {
  return {
    type : params.type || 'common'
  };

}, TransferDescription);
