import React from 'react';
import {FlowRouter} from 'core/Router';
import Button from 'imports/ui/shared/button/Button';
import IW from 'imports/iw_api'

export default class LanguageItem extends React.Component {


  onTapLanguage(event, language) {
    // Ignore mousedown for slider items
    if ((event.type === 'mousedown') && $('.iosSlider').data('iosslider')) return;

    IW.mainMenu(language.lang);

    return event.preventDefault();
  }

  render() {
    const {language} = this.props;
    if (!(language.lang_name && language.lang)) return null;

    return <Button className={`lang button ${language.lang}`} onMouseDown={(event) => this.onTapLanguage(event, language)}
                   onClick={(event) => this.onTapLanguage(event, language)}
                   text={language.lang_name} isWrapped={true}/>

  }
}

LanguageItem.propTypes = {
  language: React.PropTypes.object
};
