import React, {Component} from 'react';
import {FlowRouter} from 'core/Router';
import Button from 'imports/ui/shared/button/Button';

export default class QuizChallenge extends Component {
  render() {
    const {prompt, answers, onAnswer, onBack} = this.props;

    return (
      <table>
        <tbody className="quiz-challenge">
        <tr>
          <td className="quiz-challenge__prompt">
            <span>{prompt}</span>
          </td>
        </tr>
        <tr className="quiz-challenge__buttons">
          <td>
            <table className="buttons-table">
              <tbody>
              {_.map(answers, (a, k) => (
                <Button key={k} className="button button-primary" text={a} onMouseDown={() => onAnswer(a)}/>))}
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td>
            <Button id="back" className="left button" onMouseDown={onBack} text_="Back"/>
          </td>
        </tr>
        </tfoot>
      </table>
    );
  }
}

QuizChallenge.propTypes = {
  prompt: React.PropTypes.string,
  answers: React.PropTypes.arrayOf(React.PropTypes.string),
  onAnswer: React.PropTypes.func,
  onBack: React.PropTypes.func,
};
