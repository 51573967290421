import {createContainer} from 'imports/lib/react_createContainer';
import DropdownIOSliderList from './DropdownIOSliderList';

export default createContainer((params) => {

  function onTapItem(itemKey, event){
    if ((event.type == 'mousedown') && $('.iosVerticalSlider').data('iosSliderVertical')) return;
    params.onChecked(itemKey);

    return event.preventDefault();
  }

  return {
    id: params.id,
    className: params.className,
    style: params.style,
    items: params.items,
    optionsSlider : Object.assign({
      scrollbarBackground : 'black',
      scrollbarWidth: '16px'
    }, params.optionsSlider || {}),
    onTapItem
  };

}, DropdownIOSliderList);