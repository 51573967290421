import React from 'react';

const playAnimation = _.throttle((target) => {
  $(target).css({animation: "shake 0.83s"});
  setTimeout(() => $(target).css({animation: "none"}), 850);
}, 900, {trailing: false});


export default class Button extends React.Component {

  shakeButton(e) {
    const target = e.target;
    playAnimation(target);
  }

  onMouseDown(e) {
    const {onMouseDown} = this.props;

    if (!onMouseDown) {
      return this.shakeButton(e);
    }

    onMouseDown(e);
  }

  onTouchStart(e) {
    const {onTouchStart} = this.props;

    if (!onTouchStart) {
      return this.shakeButton(e);
    }

    onTouchStart(e);
  }

  render() {
    const {id, className, onClick, text, style, isWrapped, icon, icon_placement, ...others} = this.props;

    return (
      <a id={id} className={className} style={style} onMouseDown={(e) => this.onMouseDown(e)}
         onTouchStart={() => this.onTouchStart(e)} onClick={onClick} {...others}>
        {icon_placement === "before" ? icon : null}{isWrapped ?
        <span>{text}</span> : text}{icon_placement === "after" ? icon : null}
      </a>
    )
  }
}

Button.propTypes = {
  id: React.PropTypes.string,
  className: React.PropTypes.string,
  onMouseDown: React.PropTypes.func,
  onTouchStart: React.PropTypes.func,
  onClick: React.PropTypes.func,
  text: React.PropTypes.string,
  style: React.PropTypes.object,
  link: React.PropTypes.string,
  isWrapped: React.PropTypes.bool,
  icon: React.PropTypes.element,
  icon_placement: React.PropTypes.string
};

Button.defaultProps = {
  id: "",
  className: "button",
  onMouseDown: () => {
  },
  onTouchStart: () => {
  },
  onClick: () => {
  },
  text: "Button",
  style: {},
  link: "javascript:void(0)",
  isWrapped: false,
  icon: null,
  icon_placement: "before"
};