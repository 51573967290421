import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import StoredSessions from './StoredSessions';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';

export default createContainer((params) => {
  const items = _.sortBy(IW.appGetVal('customer_stored_sessions'), (i) => i.remaining_amount);
  return {
    items: items.slice(0, 3).complete(3, null),
    showNextButton: items.length < 3,
    onSelect: (item) => {
      IW.selectCustomerStoredSession(item.id, (_, result) => {
        if (result) return;
        console.log('Customer session was not restored');
        IW.mainMenu();
      });
      getPageManager().select()
    },
    onNext: () => getPageManager().next(),
    onBack: () => getPageManager().back(),
  };
}, StoredSessions);
