import {Tracker} from 'core/tracker';
import {Session} from 'core/session';
import {Meteor} from 'core/meteor';
import loadJson from 'core/loadJson';
import Products from '../collections/products';
import {getSocket} from 'imports/iw_api/sockets';
import CONFIG from 'imports/config';

const DEVICE = loadJson('/config/DEVICE.json');

let updateTimeout = 0;
let pingTimeout = 0;
let aliveLastTime = 0;

Tracker.autorun(function () {
  Session.get('settings_version'); // bind reactive update when settings changed
  const socket = getSocket();
  const DEVICE = getDevice();
  const deviceGuid = getDeviceGuid();

  if (Session.get('io.connected') && !DEVICE && deviceGuid && socket && CONFIG.VIRTUAL_ENABLED) {
    console.log('Init virtual device...', deviceGuid);
    socket.emit('/d/virtual/init', deviceGuid);
  }

  if (Session.get('io.connected') && DEVICE && socket && DEVICE.DEVICE_GUID) {
    console.log('EMITTING /data/apps/update with DEVICE_GUID ' + DEVICE.DEVICE_GUID);
    socket.emit('/d/clamshell', DEVICE.DEVICE_GUID);
    if (CONFIG.VIRTUAL_ENABLED) socket.emit('/data/settings', DEVICE.DEVICE_GUID);
    if (CONFIG.SIGUE_LOCATIONS) socket.emit('/u/sigue/get_locations', (err, ls) => Session.set('sigue_locations', ls) || err);
    updateDevice();
    socket.emit('/d/maintenance_message', DEVICE.DEVICE_GUID);
  } else {
    console.log('No DEVICE_GUID to emit yet...');
  }
});

Tracker.autorun(function _ping() {
  const socket = getSocket();

  if (Session.get('io.connected') && socket) {
    console.log('<= /d/ping');
    socket.emit('/d/ping', function () {
      console.log('-> /d/ping');
      Session.set('server.connected', true);
      aliveLastTime = Date.now();
    });
    if (Date.now() - aliveLastTime > 21000) {
      Session.set('server.connected', false);
    }
  }
  Meteor.clearTimeout(pingTimeout);
  pingTimeout = Meteor.setTimeout(_ping, 10 * 1000)
});

export function getDevice() {
  return CONFIG.VIRTUAL_ENABLED ?
    Session.get('DEVICE') :
    DEVICE
}

function getDeviceGuid() {
  return (DEVICE && DEVICE.DEVICE_GUID) || '';
}

function updateDevice() {
  const socket = getSocket();
  const DEVICE = getDevice();

  if (Session.get('io.connected') && socket && DEVICE && DEVICE.DEVICE_GUID) {
    if (CONFIG.VIRTUAL_ENABLED) {
      socket.emit('/d/virtual/alive', DEVICE.DEVICE_GUID, DEVICE.AUTH_TOKEN);
    } else {
      socket.emit('/d/alive', DEVICE.DEVICE_GUID);
    }
  }

  if (Products.find({hidden: false}).count() <= 0) {
    socket.emit('/data/apps/update', DEVICE.DEVICE_GUID); // apps, products + other stuff
  }

  Meteor.clearTimeout(updateTimeout);
  updateTimeout = Meteor.setTimeout(updateDevice, CONFIG.KEEP_ALIVE_TIMEOUT * 1000)
}

Meteor.setInterval(function () {
  const socket = getSocket();
  const DEVICE = getDevice();
  if (Session.get('maintenance_message')) {
    console.log('EMITTING /d/maintenance_message with DEVICE_GUID ' + DEVICE.DEVICE_GUID);
    socket.emit('/d/maintenance_message', DEVICE.DEVICE_GUID);
  }
}, 60000);