import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import {_, N} from 'imports/lib/helpers';
import Info from './Info';
import IW from 'imports/iw_api';
import getPageManager from 'imports/lib/page_manager';
import {getMoment} from 'imports/lib/external/moment-business-days'
import __ from 'lodash'

export default createContainer(() => {
  // return {
  //   paymentOptions: [],
  //   onNext,
  //   onBack
  // };
  //

  const moment = getMoment();
  const isAfter15pm = moment() > moment(moment().format('YYYY-MM-DD 15:00'));
  const isBusinessDay = moment().isBusinessDay();
  const f = (d) => d.format('YYYY-MM-DDT00:00');

  function getPriorityReleaseDate(option) {
    let result = moment().businessSubtract(1).businessAdd(1);

    if((isAfter15pm && isBusinessDay && Session.get('app_guid') !== 'app_billpay_cHckF') || (/next/i.test(option.description))){
      result = result.businessAdd(1)
    }

    return result
  }

  function getReleaseDaysAfterToday(releaseDate) {
    if(f(releaseDate) === f(moment())) return 'TODAY';

    return moment(f(releaseDate)).from(f(moment()));
  }

  function getStandardReleaseDate(option) {
    let result = moment().businessSubtract(1).businessAdd(1);
    let offset = getStandardOffset(option);

    if((isAfter15pm && isBusinessDay) || Session.get('app_guid') === 'app_billpay_cHckF'){
      result = result.businessAdd(offset)
    } else {
      result = result.businessAdd(offset - 1)
    }

    return result
  }

  function getStandardOffset(option){
    const standardFeeDescription = option.description;

    const commonDescriptions = {
      '1 to 2 Hours': 1,
      '5 to 7 bus. days': 7,
      '4 to 7 bus. days': 7,
      '2-3 bus. days': 3,
      'Standard': 3,
      'Same day': 1
    };

    return commonDescriptions[standardFeeDescription] || 4
  }

  function onNext(option) {
    IW.appSetVal('selected_payment_option', option.id);
    getPageManager().next()
  }

  function onBack() {
    getPageManager().back()
  }

  return {
    paymentOptions: __.sortBy(__.compact(__.map(IW.appGetVal('payment_options'), (option) => {
      if (!option) return;
      const releaseDateFunc = option.priority ?
        getPriorityReleaseDate(option) :
        getStandardReleaseDate(option);
      return {
        id: option.id,
        priority: option.priority,
        releaseDays: getReleaseDaysAfterToday(releaseDateFunc),
        releaseDate: releaseDateFunc.format('MM/DD/YYYY'),
        fee: N(option.fee),
        description: option.biller_description ?
          `${option.biller_description} - ${option.biller_type}` :
          ''
      }
    })), (o) => -N(o.priority)),
    showWarning: Session.get('app_guid') !== 'app_billpay_cHckF',
    onNext,
    onBack
  };

}, Info);