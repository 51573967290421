import React from 'react';
import Time from '../shared/time/TimeContainer';
import Button from 'imports/ui/shared/button/Button';

export default class AppHeader extends React.Component {
  render() {

    const {classLabel, style, title, appStep, onMenu, menuEnabled} = this.props;

    return (
      <tr>
        <td>
          <table cellSpacing="0">
            <tbody>
            <tr className="appMain">
              <td>
                {menuEnabled? <Button id="menu" className="button button-header button-danger" onMouseDown={onMenu} text_="Menu"/>: null}
              </td>
              <td className="app">
                <a className={"logo app " + classLabel} style={style}>{title}</a>
                <Time/>
              </td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
    )
  }
}

AppHeader.propTypes = {
  title: React.PropTypes.string,
  style: React.PropTypes.object,
  classLabel: React.PropTypes.string,
  onMenu: React.PropTypes.func,
  menuEnabled: React.PropTypes.bool,
};

