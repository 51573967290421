Array.prototype.chunk = function(chunkSize) {
  var array = this;

  if(array.map)
    return [].concat.apply([], array.map(function(elem, i) {
      return (i % chunkSize) ? [] : [array.slice(i, i + chunkSize)]
    }));

  return array
};

Array.prototype.complete = function(to, dummy) {
  var array = this;

  if(array.length < to) {
    for( var i = array.length; i < to; i++ ) {
      array.push( (typeof dummy === 'undefined') ? undefined : dummy );
    }
  }

  return array;
};

