import {FlowRouter} from 'core/Router';
import IW from 'imports/iw_api';
import getPageManager from 'imports/lib/page_manager';
import {hasVariant, N, S} from 'imports/lib/helpers';
import CONFIG from 'imports/config';
import {PAY_METHODS} from 'imports/lib/constants';

export default function (params) {

  function requestedAmount() {
    return N(IW.appGetVal('requested_amount'));
  }

  function customerBalance() {
    return N(IW.appGetVal('customer_balance'));
  }

  function customerCreditAmount() {
    return N(Math.min(requestedAmount() - receivedAmount(), customerBalance()));
  }

  function canBePayout(payout) {
    if (payout <= 0) return true;
    return payout <= N(IW.appGetVal('max_payout')) &&
      hasVariant(
        payout,
        _.map(IW.appGetVal('available_coins'), (c) => c.value),
        _.map(IW.appGetVal('available_coins'), (c) => c.count),
        _.map(IW.appGetVal('available_coins'), (c) => +(c.type === 'coin')))
  }

  function receivedAmount() {
    return Math.min(N(IW.appGetVal('amount')) + N(IW.appGetVal('actual_escrow_amount')), requestedAmount());
  }

  function startReceivedAmount() {
    return Math.min(N(IW.appGetVal('start_amount')), requestedAmount());
  }

  function getAccount() {
    const account = S(IW.appGetVal('account') || IW.appGetVal('account1') || IW.appGetVal('email') ||
      IW.appGetVal('number') || IW.appGetVal('sender_number'));
    return account;
  }

  function getNotes() {
    const notes = CONFIG.PAY_NOMINALS;
    const result = [[], []];

    if (N(receivedAmount()) >= N(requestedAmount())) return result;

    for (let i = 0; i < notes.length; i++) {
      const nominal = Math.pow(100, i);
      for (let e = 0; e < notes[i].length; e++) {
        const valueCoins = N((notes[i][e] / nominal) * 100);
        const payoutCoins = N((receivedAmount() * 100) + valueCoins - (requestedAmount() * 100));
        if (canBePayout(payoutCoins)) result[i].push(notes[i][e]);
      }
    }
    return result;
  }

  function checkDevicesStatus(devices) {
    if (!_.isArray(devices)) return false;
    return _.every(devices, (d) => Session.get('app')[d + '_status_success']);
  }

  function getDiscountLabel() {
    if (IW.appGetVal('discount_type') === 'first_payment') return 'First Payment';
    if (IW.appGetVal('discount_type') === 'welcome_back') return 'Welcome Back';
    if (IW.appGetVal('discount_type') === 'maya5') return 'Maya 5 Discount';
    if (IW.appGetVal('discount_type') === 'referral') return 'Referral Discount';
    if (IW.appGetVal('discount_type') === 'free_transaction') return 'Free Transaction';
    if (N(IW.appGetVal('discount_amount')) !== 0) return 'Discount';
  }

  if (IW.appGetVal('payment_guid') && !IW.appGetVal('paid')) getPageManager().tryPay();

  const payMethods = _.map(PAY_METHODS, (v, k) => _.extend({}, v, {
    enabled: _.contains(CONFIG.PAY_METHODS, k) && checkDevicesStatus(v.devices)
  }));

  const canAddBalance = customerCreditAmount() >= requestedAmount() - receivedAmount() &&
    receivedAmount() < requestedAmount() &&
    startReceivedAmount() === receivedAmount();

  return {
    requestedAmount: N(requestedAmount() - customerCreditAmount()),
    receivedAmount: N(receivedAmount() + customerCreditAmount()),
    customerBalance: N(customerBalance()),
    customerCreditAmount: N(customerCreditAmount()),
    receivedWaitingAmount: 0,
    remainingAmount: N(requestedAmount() - customerCreditAmount() - receivedAmount()),
    canGoBack: receivedAmount() === 0 && !IW.appGetVal('add_balance_pressed'),
    canSaveSession: !IW.isVirtual() && CONFIG.DISPLAY_CONTINUE_LATER &&
      receivedAmount() > 0 && receivedAmount() < (requestedAmount() - customerCreditAmount()) &&
      !canAddBalance && !IW.appGetVal('add_balance_pressed'),
    canAddBalance: canAddBalance && !IW.appGetVal('add_balance_pressed'),
    onSaveSession: () => getPageManager().continueLater(),
    onBack: () => getPageManager().back(),
    onReferralCode: () => getPageManager().referral(),
    onMaya5: () => getPageManager().maya5(),
    onTryPayerLoginAgain: () => getPageManager().generateQR(),
    onAddBalance: () => (IW.appSetLocalVal('add_balance_pressed', true), IW.callBillAcceptor('add_balance')),
    account: getAccount(),
    originalAmount: N(IW.appGetVal('original_amount')),
    creditAmount: N(IW.appGetVal('credit_amount')),
    feeAmount: N(IW.appGetVal('fee')),
    discount: N(IW.appGetVal('discount_amount')),
    discountLabel_: getDiscountLabel(),
    totalAmount: N(IW.appGetVal('requested_amount')),
    moreOptions: !!IW.appGetVal("more_payment_options"),
    isReferralCode: CONFIG.REFERRALS_ENABLED &&
      !!IW.appGetVal('customer_can_be_referral') && !IW.appGetVal('maya5_referral_hidden') &&
      !IW.appGetVal('session_resumed'),
    isMaya5: CONFIG.MAYA5_ENABLED && !IW.appGetVal('maya5_referral_hidden') && IW.appGetVal('customer_maya5_enabled'),
    notes: getNotes(),
    payMethods: payMethods,
    qrCodeUrl: IW.appGetVal('payment_qr_token_url'),
    virtualPayAccessDenied: IW.appGetVal('virtual_cash_access_denied'),
    isSigue: IW.appGetVal('service_name') === 'sigue'
  };
}