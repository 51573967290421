import React, {Component} from 'react';
import {FlowRouter} from 'core/Router';
import Button from 'imports/ui/shared/button/Button';
import {NUM_PAD} from 'imports/lib/constants';
import Numpad from 'imports/ui/shared/numpad/Numpad';
import ClearedInput from 'imports/ui/shared/cleared_input/ClearedInput';
import InputLabel from 'imports/ui/shared/input/InputLabel';

export default class PinNumber extends Component {
  componentDidMount() {
    Session.set('focus', 'pinNumber');

    var focus = Session.get('focus');
    Session.set('focus', '');
    Session.set('focus', focus);
  }

  render() {
    const {onBack, onNext, focus, pinNumber, pinNumberConfirm, pinNumberCurrent, showNextButton,
      onClearInput, onValueChange, headerScope, errorMessage} = this.props;

    const res = (
      <table className="pin-number">
        <tbody>
        <tr className="bg_dark_blue">
          <td colSpan="2" className="pin-number__header-container">
            {headerScope ?
              <span className="pin-number__description center" _={[
                <img className="pin_number_header-icon" src="/resources/images/login/ic_login_small.png"/>,
                <span className="green">Create</span>,
                <span className="green">{headerScope}</span>
              ]}>
                %s%s a new PIN code for your %s account
              </span>
              : <span className="pin-number__description center" _>
                <img className="pin_number_header-icon" src="/resources/images/login/ic_login_small.png"/>
                <span className="green">Create</span> a new PIN code for your account
              </span>}
          </td>
        </tr>
        <tr>
          <td className="pin-number__input-container">
            <div className="pin-number__input on-top">
              <br/>
              <br/>
              <InputLabel text={'Enter your new PIN code'} style={{padding: 0}}/>
              <ClearedInput id="pinNumber" className="focus" value={pinNumber.replace(/./g, '*')} readOnly={true}
                            focus={focus}
                            clearInput={onClearInput}/>
              <br/>
              <InputLabel text={'Re-enter your PIN code'} style={{padding: 0}}/>
              <ClearedInput id="pinNumberConfirm" className="focus" value={pinNumberConfirm.replace(/./g, '*')}
                            readOnly={true}
                            focus={focus}
                            clearInput={onClearInput}/>

              {errorMessage === 'server_error' ?
                <InputLabel text="Unexpected error" style={{padding: "10px 0 0 0", display: 'block', color: '#eb564a'}}/>
                : null}

            </div>
          </td>
          <td className="pin-number__numpad-container">
            <Numpad keys={NUM_PAD} value={pinNumberCurrent} onValueChange={onValueChange}/>
          </td>
        </tr>
        </tbody>
        <tfoot className="bg_dark_blue">

        <tr>
          <td>
            <Button id="back" className="left button" text_="Back" onMouseDown={onBack}/>
          </td>
          <td>
            {showNextButton ?
              <Button id="next" className="right button" text_="Next" onMouseDown={onNext}/> :
              null }
          </td>
        </tr>
        </tfoot>
      </table>
    );
    return res;
  }
}

PinNumber.propTypes = {
  onBack: React.PropTypes.func,
  onNext: React.PropTypes.func,
  focus: React.PropTypes.string,
  pinNumber: React.PropTypes.string,
  pinNumberConfirm: React.PropTypes.string,
  pinNumberCurrent: React.PropTypes.string,
  showNextButton: React.PropTypes.bool,
  onClearInput: React.PropTypes.func,
  onValueChange: React.PropTypes.func,
};
