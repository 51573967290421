import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import CategoryIcon from './CategoryIcon';
import IW from 'imports/iw_api';
import getPageManager, {goTo} from 'imports/lib/page_manager';
import appWorkflow from 'imports/apps_workflow'
import {gaEvent} from "imports/lib/ga";

export default createContainer((params) => {

  function onTapIcon(event, iconId) {
    if ((event.type === 'react-mousedown') && $('.iosSlider').data('iosslider')) {
      gaEvent(params.gaCategory, 'Slider swipe');
      return;
    }
    gaEvent(params.gaCategory, 'Category select', String(iconId));
    IW.openCat(iconId);
    return event.preventDefault();
  }

  return {
    icon: params.icon,
    onTapIcon: onTapIcon
  };

}, CategoryIcon);
