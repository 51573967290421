import React, {Component} from 'react';

export default class AnyBillIcon extends Component {

  render() {
    const {onTapIcon, app} = this.props;

    return (
      <td colSpan="2" rowSpan="2" style={{verticalAlign: 'top'}}>
        <div className="anyBillFrame pulse-button-pay-any-bill" onClick={(event) => onTapIcon(event, app)}>
          <div className="anyBillHeaderText">Can't find your bill?</div>
          <div className="anyBillIcon">
            <img src={app.icon}/>
          </div>
          <div className="anyBillFooterText">
            Press <span style={{color: '#0EB7D2'}}>here</span> to Scan and Pay your bill
          </div>
        </div>
      </td>
    )
  }
}
