import {createContainer} from 'imports/lib/react_createContainer';
import PrevalinsTransactionError from './PrevalinsTransactionError';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';
import {_ as i18n, usd, capitalizeSentence} from "../../../lib/helpers";

export default createContainer((params) => {

  const prevalinsResponseText = IW.appGetVal('prevalins_response_text');

  return {
    smallText: i18n('We can not process the transaction'),
    bigText: i18n(capitalizeSentence(prevalinsResponseText)),
    srcImg: '/resources/images/atm/ic_error.png',
    isSpinner: true
  };

}, PrevalinsTransactionError);