import IW from 'imports/iw_api';

import app_common from './app_common';
import createNumber from './common/number';
import {nextWhen} from 'imports/lib/page_manager';
import createSessionMethods from 'imports/lib/createSessionMethods';
import createStartScanningInsertId from './common/start_scanning_insert_id';

let lastTransactionHash = '';

export default _.extend({}, app_common, {
  index: () => 'dm_customer_number',
  // index: () => {
  //   IW.appSetVal('phone_number', '12014222656');
  //   return 'on_login';
  // },

  // index: () => 'scan_wallet_qr',

  dm_customer_number: createNumber('exit', 'on_login'),

  on_login: function* () {
    IW.appSetVal('phone_check_result', '');

    yield* doCall('checkPhone', null, 'phone_check_result');

    switch (IW.appGetVal('phone_check_result')) {
      case 'exist_user':
        return 'dm_pin_code';
      case 'new_user':
      case 'confirm_create_pin':
        return 'dm_confirm_code';
      case 'create_pin':
        return 'dm_new_pin_code';
      default:
        return getCustomError({
          small: 'Server Error',
          big: IW.appGetVal('phone_check_error') || 'Check your phone number or call support',
          allow_back: true,
        }, 'phone_check_error');
    }
  },

  dm_pin_code: function* () {
    const SM = createSM('dm_login');
    IW.appSetVal('reset_pin', '');
    SM.clearVal('pin_number');

    yield {
      back: 'index',
      change: () => {
        IW.call('resetPin');
        IW.appSetVal('reset_pin', true);
        return 'dm_confirm_code';
      },
    };
    while (true) {
      yield nextWhen(() => SM.getVal('pin_number').length === 4);
      yield 'none';

      IW.appSetVal('log_in_result', '');
      yield* doCall('logIn', {
        PinCode: SM.getVal('pin_number'),
      }, 'log_in_result');

      SM.clearVal('pin_number');

      if (IW.appGetVal('log_in_result') === 'success') return 'dm_product_select';
      if (IW.appGetVal('log_in_result') === 'invalid_pin') {
        callError(SM, 'invalid_pin');
        continue;
      }
      return getCustomError({
        small: 'Server Error',
        big: IW.appGetVal('log_in_error') || 'Log in error. Please contact support',
        allow_menu: true,
      }, 'log_in_error');
    }
  },

  dm_confirm_code: function* () {
    const SM = createSM('dm_login');
    SM.setVal('error_message_code', '');

    const confirmMethod = IW.appGetVal('reset_pin') ? 'confirmResetPin' : 'confirmPhone';

    SM.setVal('pin_number', '');
    yield {
      back: 'index',
      change: function () {
        if (IW.appGetVal('reset_pin')) {
          IW.call('resetPin');
        } else {
          IW.call('checkPhone');
        }
        SM.clearVal('pin_number');
        return 'none';
      },
    };
    while (true) {
      yield nextWhen(() => SM.getVal('pin_number').length === 6);
      yield 'none';

      IW.appSetVal('confirm_code_result', '');

      yield* doCall(confirmMethod, {
        ConfirmCode: SM.getVal('pin_number'),
      }, 'confirm_code_result');

      SM.clearVal('pin_number');
      if (IW.appGetVal('confirm_code_result') === 'success') {
        return 'dm_new_pin_code';
      }
      if (IW.appGetVal('confirm_code_result') === 'invalid_pin') {
        callError(SM, 'invalid_pin');
        continue;
      }
      return getCustomError({
        small: 'Server Error',
        big: IW.appGetVal('confirm_code_error') || 'Please contact support',
        allow_menu: true,
      }, 'confirm_code_error');
    }
  },

  dm_new_pin_code: function () {
    const SM = createSM('dm_login');
    SM.setVal('pin_code', '');
    SM.setVal('pin_number_1', '');
    SM.setVal('pin_number_2', '');

    return {
      back: 'index',
      next: function* () {
        if (IW.appGetVal('reset_pin')) {
          IW.appSetVal('new_pin_code_result', '');
          yield* doCall('resetPinSetNew', {
            PinCode: SM.getVal('pin_number_1'),
          }, 'new_pin_code_result');
          if (IW.appGetVal('new_pin_code_result') === 'success') return 'dm_product_select';
          if (IW.appGetVal('new_pin_code_result') === 'invalid_pin') return getCustomError({
            small: 'Server Error',
            big: 'Invalid pin code',
            allow_menu: true,
          });
          return getCustomError({
            small: 'Server Error',
            big: IW.appGetVal('new_pin_code_error') || 'Can\'t set pin code. Please contact support',
            allow_menu: true,
          }, 'new_pin_code_error');
        }
        // Case for exist user without PIN code
        if (IW.appGetVal('phone_check_result') === 'confirm_create_pin'
          || IW.appGetVal('phone_check_result') === 'create_pin') {
          yield* doCall('createPin', {
            PinCode: SM.getVal('pin_number_1'),
          }, 'create_pin_result');
          if (IW.appGetVal('create_pin_result') === 'success') return 'dm_product_select';
          if (IW.appGetVal('create_pin_result') === 'server_error') return getCustomError({
            small: 'Server Error',
            big: IW.appGetVal('create_pin_error'),
            allow_menu: true,
          }, 'create_pin_error');
        }

        IW.appSetVal('new_customer_pin_code', SM.getVal('pin_number_1'));

        return 'scan_id_barcode';
      },
    };
  },

  scan_id_barcode: function* () {
    IW.appSetVal('camera_qr', '');
    IW.appSetVal('enroll_customer_result', '');
    IW.appSetVal('enroll_customer_without_barcode', '');
    IW.appSetVal('allow_enroll_customer_without_barcode', '');

    setTimeout(() => IW.appSetVal('allow_enroll_customer_without_barcode', true), 5 * 1000);

    yield {
      back: 'dm_new_pin_code', next: () => {
        IW.appSetVal('enroll_customer_without_barcode', true);
        return 'cash_check_insert_id';
      },
    };
    yield nextWhen(() => IW.appGetVal('camera_qr'));
    yield 'sleep ' + (15 * 60 * 1000);
    IW.appSetVal('id_barcode_scan_raw_data', IW.appGetVal('camera_qr'));
    IW.appSetVal('account', '');

    IW.appSetVal('parse_id_scan_result', '');
    yield* doCall('parseIdScan', null, 'parse_id_scan_result');
    if (IW.appGetVal('parse_id_scan_result') === 'internal_error') return getCustomError({
      small: 'Internal Error',
      big: 'Can\'t read your ID data. Please try again or contact support',
      allow_back: true,
    });
    return 'dm_confirm_scan_data';
  },

  dm_confirm_scan_data: {
    back: 'scan_id_barcode',
    next: 'cash_check_insert_id',
  },

  ...createStartScanningInsertId('index', 'redirect1', 'scanning_error'),

  redirect1: function* () {
    yield 'loading app enroll_customer_result';
    if (IW.appGetVal('enroll_customer_result') === 'success' ||
      IW.appGetVal('enroll_customer_result') === 'verification_required') return 'dm_product_select';

    if (IW.appGetVal('enroll_customer_result') === 'internal_error') return getCustomError({
      small: 'Internal Error',
      big: 'Can\'t recognize your ID photo. Please try again or contact support',
      allow_back: true,
    });

    if (IW.appGetVal('enroll_customer_result') === 'server_error') return getCustomError({
      small: 'Server Error',
      big: IW.appGetVal('enroll_customer_error') || 'Can\'t create customer. Please contact support',
      allow_menu: true,
    }, 'enroll_customer_error');
  },

  dm_product_select: function* ({lastAction}) {
    IW.appSetVal('get_platforms_result', '');
    yield* doCall('getPlatforms', null, 'get_platforms_result');

    if (IW.appGetVal('get_platforms_result') === 'server_error') return getCustomError({
      small: 'Server Error',
      big: IW.appGetVal('get_platforms_error') || 'Can\'t get product list. Please contact support',
      allow_menu: true,
    }, 'get_platforms_error');

    if (IW.appGetVal('product_list').length === 1) {
      if (lastAction === 'back') return 'dm_customer_number';
      IW.appSetVal('crypto_currency_symbol', IW.appGetVal('product_list')[0]);
      IW.appSetVal('crypto_currency_title', IW.appGetVal('product_list')[0]);
      return 'dm_wallet_select';
    }

    return {
      next: function (event) {
        const currency = event.params[0].product_name;
        IW.appSetVal('crypto_currency_symbol', currency);
        IW.appSetVal('crypto_currency_title', currency);
        return 'dm_wallet_select';
      },
      back: 'dm_customer_number',
    };
  },

  dm_wallet_select: function () {
    IW.appSetVal('wallet_choice', '');
    IW.appSetVal('wallet_address', '');
    return {
      back: 'dm_product_select',
      next: function* () {
        if (IW.appGetVal('wallet_choice') === 'new_wallet') {
          return 'take_crypto_wallet_print';
        }
        if (IW.appGetVal('wallet_choice') === 'scan_wallet') {
          return 'scan_wallet_qr';
        }
      },
    };
  },

  take_crypto_wallet_print: function* () {
    IW.appSetVal('create_wallet_result', '');
    yield* doCall('createWallet', null, 'create_wallet_result');

    if (IW.appGetVal('create_wallet_result') === 'server_error') return getCustomError({
      small: 'Server Error',
      big: IW.appGetVal('create_wallet_error') || 'Can\'t create new wallet. Please contact support',
      allow_back: true,
    }, 'create_wallet_error');

    if (IW.appGetVal('create_wallet_result') === 'internal_error') return getCustomError({
      small: 'Internal Error',
      big: 'Can\'t print new wallet. Please contact support',
      allow_back: true,
    });

    return {
      next: 'enter_amount_crypto',
    };
  },

  scan_wallet_qr: function* () {
    IW.appSetVal('camera_qr', '');
    IW.appSetVal('wallet_address', '');
    yield {back: 'dm_wallet_select'};
    yield nextWhen(() => IW.appGetVal('camera_qr'));
    yield 'sleep ' + (15 * 60 * 1000);
    IW.appSetVal('wallet_address', IW.appGetVal('camera_qr'));
    IW.appSetVal('camera_qr', '');

    if (!IW.appGetVal('wallet_address')) return getCustomError({
      small: 'Internal Error',
      big: 'Can\'t read your wallet address. Please try again.',
      allow_back: true,
      image: 'ic_card_error',
    });
    return 'enter_amount_crypto';
  },

  enter_amount_crypto: function* () {
    // if (IW.appGetVal('enroll_customer_result') === 'verification_required') return getCustomError({
    //   big: 'Verification Required',
    //   small: 'When enrolling without an ID scan, the customer may NOT immediately transact until DigitialMint ' +
    //     'Customer Support staff have manually approved the customer account. Manual approval typically takes less ' +
    //     'than 5 minutes during business hours: Monday through Friday, 9 AM – 7 PM CST.',
    // });

    IW.appSetVal('get_price_quotes_result', '');

    yield* doCall('getPriceQuotes', null, 'get_price_quotes_result');

    if (IW.appGetVal('get_price_quotes_result') === 'server_error') return getCustomError({
      small: 'Server Error',
      big: IW.appGetVal('get_price_quotes_error') || 'Please contact support.',
      allow_menu: true,
    }, 'get_price_quotes_error');

    return {
      back: 'dm_wallet_select',
      next: function* () {
        let knownError = '';

        if (IW.appGetVal('transaction_hash')) {
          IW.appSetVal('cancel_transaction_result', '');
          yield* doCall('cancelTransaction', {
            CancelReason: 0,
          }, 'cancel_transaction_result');
        }

        // Init transaction
        IW.appSetVal('init_transaction_result', '');
        yield* doCall('initTransaction', null, 'init_transaction_result');

        knownError = getErrorFromCode('init_transaction_code');
        if (knownError) return getCustomError(knownError);

        if (IW.appGetVal('init_transaction_result') === 'server_error') return getCustomError({
          small: 'Server Error',
          big: IW.appGetVal('init_transaction_error') || 'Can\'t initialize transaction. Please contact support.',
          allow_menu: true,
        }, 'init_transaction_error');

        // Add wallet
        IW.appSetVal('add_wallet_address_result', '');
        yield* doCall('addWalletAddress', null, 'add_wallet_address_result');

        knownError = getErrorFromCode('add_wallet_address_code');
        if (knownError) return getCustomError(knownError);

        if (IW.appGetVal('add_wallet_address_result') === 'server_error') {
          IW.appSetVal('cancel_transaction_result', '');
          yield* doCall('cancelTransaction', {
            CancelReason: 0,
          }, 'cancel_transaction_result');
          return getCustomError({
            small: 'Server Error',
            big: IW.appGetVal('add_wallet_address_error') || 'Invalid wallet address. Please check your wallet address or contact support.',
            allow_back: true,
          }, 'add_wallet_address_error');
        }


        IW.appSetVal('add_cash_result', '');
        yield* doCall('addCash', null, 'add_cash_result');

        knownError = getErrorFromCode('add_cash_code');
        if (knownError) return getCustomError(knownError);

        if (IW.appGetVal('add_cash_result') === 'server_error') {
          IW.appSetVal('cancel_transaction_result', '');
          yield* doCall('cancelTransaction', {
            CancelReason: 11,
          }, 'cancel_transaction_result');

          return getCustomError({
            small: 'Server Error',
            big: IW.appGetVal('add_cash_error') || 'Invalid transaction amount. Please change amount or contact support.',
            allow_back: true,
          }, 'add_cash_error');
        }
        return 'pay';
      },
    };
  },

  dm_error: function (event) {
    let back = 'back';

    if (event.lastStep === 'on_login') {
      back = 'dm_customer_number';
    }
    if (event.lastStep === 'redirect1') {
      back = 'cash_check_insert_id';
    }
    if (event.lastStep === 'take_crypto_wallet_print') {
      back = 'dm_wallet_select';
    }
    if (event.lastStep === 'enter_amount_crypto') {
      if (IW.appGetVal('add_wallet_address_result') === 'server_error') {
        back = 'dm_wallet_select';
      }
    }

    return {
      back: function () {
        getCustomError({});
        return back;
      },
    };
  },
});


function createSM(scope) {
  const SM = createSessionMethods(scope);
  if (!Session.get(scope)) Session.set(scope, {});
  return SM;
}

function* doCall(method, requestData, wait) {
  IW.call(method, requestData);
  yield 'loading app ' + wait;
}

function callError(SM, key) {
  SM.setVal('error_message_code', key);
  return 'none';
}

function getCustomError(opt, appErrorToClear) {
  IW.appSetVal('custom_error_small', '');
  IW.appSetVal('custom_error_big', '');
  IW.appSetVal('custom_error_image', '');
  IW.appSetVal('custom_error_spinner', '');
  IW.appSetVal('custom_error_allow_back', '');
  IW.appSetVal('custom_error_allow_menu', '');

  if (opt.small) IW.appSetVal('custom_error_small', opt.small);
  if (opt.big) IW.appSetVal('custom_error_big', opt.big);
  if (opt.image) IW.appSetVal('custom_error_image', opt.image);
  if (opt.spinner) IW.appSetVal('custom_error_spinner', opt.spinner);
  if (opt.allow_back) IW.appSetVal('custom_error_allow_back', opt.allow_back);
  if (opt.allow_menu) IW.appSetVal('custom_error_allow_menu', opt.allow_menu);

  if (appErrorToClear) IW.appSetVal(appErrorToClear, '');
  return 'dm_error';
}

function getErrorFromCode(errorCodeProperty) {
  const errorCode = IW.appGetVal(errorCodeProperty);
  IW.appSetVal(errorCodeProperty, '');

  let errorMessage = null;
  switch (errorCode) {
    case 470:
      errorMessage = {
        small: 'Server Error',
        big: 'Transaction denied. Customer has reached their daily limit.',
        allow_back: false,
        allow_menu: true,
      };
      break;
    case 471:
      errorMessage = {
        small: 'Server Error',
        big: 'Transaction denied. Customer is not yet approved to transact.',
        allow_back: false,
        allow_menu: true,
      };
      break;
    case 480:
      errorMessage = {
        small: 'Server Error',
        big: 'Scan denied. Invalid wallet address.',
        allow_back: true,
        allow_menu: false,
      };
      break;
    case 530:
      errorMessage = {
        small: 'Server Error',
        big: 'Customer has exceeded risk tolerance.',
        allow_back: false,
        allow_menu: true,
      };
      break;
    case 531:
      errorMessage = {
        small: 'Server Error',
        big: 'Customer has reached their daily risk limit.',
        allow_back: false,
        allow_menu: true,
      };
      break;
    case 532:
      errorMessage = {
        small: 'Server Error',
        big: 'Customer has reached their individual transaction limit.',
        allow_back: false,
        allow_menu: true,
      };
      break;
    case 540:
      errorMessage = {
        small: 'Server Error',
        big: 'Transaction has already been canceled.',
        allow_back: false,
        allow_menu: true,
      };
      break;
    default:
      return null;
  }
  return errorMessage;
}