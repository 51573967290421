import React, {Component} from 'react';
import Loading from 'imports/ui/shared/loading/Loading';
import SliderContainer from 'imports/ui/shared/slider/SliderContainer';
import {SLIDE_ITEM_TYPES, KEYBOARD_TEXT} from 'imports/lib/constants';
import safeRender from 'imports/ui/decorators/safeRender';

class Categories extends Component {
  render() {
    const {categories, gaCategory} = this.props;

    console.log('Categories - ', categories);

    if (!categories) return <Loading />;
    return (
      <tr className="categoriesPage">
        <td>
          <SliderContainer gaCategory={gaCategory} type={SLIDE_ITEM_TYPES.CATEGORY} apps={categories}/>
        </td>
      </tr>
    )
  }
}

Categories.propTypes = {
  categories: React.PropTypes.arrayOf(React.PropTypes.array),
};

export default safeRender(Categories);
