import {createContainer} from 'imports/lib/react_createContainer';
import Links from 'imports/collections/links';
import Search from './Search';

export default createContainer(() => {
  let results = [];

  if (Session.get('search_string') && Session.get('search_string').trim().length >= 2) {
    const searchQuery = Session.get('search_string').replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    const re = new RegExp(searchQuery, 'i');
    const array = Links.find({
      keywords: {$not: undefined},
      // enabled: true,
      $or: [{name: re}, {keywords: {$in: re}}],
      hidden: false
    }, {
      sort: {priorityiosVerticalSlider: 1, name: 1}
    }).fetch();

    if (array.length)
      results = array.chunk(5);
  }

  return {
    results
  }
}, Search);
