import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import QuickLaunch from './QuickLaunch';
import QuickLaunchApps from 'imports/collections/quicklaunch_apps';

export default createContainer(() => {
  return {
    quickLaunchItems: QuickLaunchApps.find().fetch(),
    quickLaunchText_: 'Quick\nlaunch'
  };

}, QuickLaunch);
