import {createContainer} from 'imports/lib/react_createContainer';
import ConfirmCustomerData from './ConfirmCustomerData';
import IW from 'imports/iw_api';
import {S} from 'imports/lib/helpers';

import getPageManager from 'imports/lib/page_manager';

export default createContainer(() => {

  const customerData = IW.appGetVal('customer') || {};
  let customerViewData = [];

  function checkAddressData(data) {
    return !!data.address1 || !!data.address2 || !!data.address_city ||
      !!data.address_state || !!data.address_zip || !!data.address_country
  }

  if (!_.isEmpty(customerData)) {
    customerViewData = [
      {
        key: 'Mobile Number',
        value: customerData.mobile_number ?
          '+' + customerData.mobile_number :
          null
      },
      {
        key: 'Email Address',
        value: customerData.email
      },
      {
        key: 'First Name',
        value: customerData.first_name
      },
      {
        key: 'Middle Name',
        value: customerData.middle_name
      },
      {
        key: 'Last Name',
        value: customerData.last_name
      },
      {
        key: 'Date Of Birth',
        value: customerData.date_of_birth
      },
      {
        key: 'Address',
        value: checkAddressData(customerData) ?
          [
            [customerData.address1, customerData.address2],
            [customerData.address_city, [customerData.address_state, customerData.address_zip]],
            [customerData.address_country]
          ] :
          null
      }
    ];
  }

  return {
    title: 'Your profile:',
    titleItems: [],
    items: customerViewData,
    onEdit: () => getPageManager().edit(),
    onNext: () => getPageManager().next(),
  };
}, ConfirmCustomerData);
