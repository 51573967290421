import React, {Component} from 'react';
import AppIcon from 'imports/ui/shared/app_icon/AppIconContainer';
import SimpleIcon from 'imports/ui/shared/app_icon/SimpleIconContainer';
import CardItem from 'imports/ui/shared/card_item/CardItemContainer';
import CustomerItem from 'imports/ui/shared/customer_item/CustomerItemContainer';
import LanguageItem from 'imports/ui/shared/language_item/LanguageItem';
import CategoryIcon from 'imports/ui/shared/category_icon/CategoryIconContainer';
import BillerIcon from 'imports/ui/apps/biller_search/BillerIconContainer';
import AnyBillIcon from 'imports/ui/shared/any_bill_icon/AnyBillIconContainer'

import {SLIDE_ITEM_TYPES} from 'imports/lib/constants';
class SlideItem extends Component {

  render() {
    const {slides, type, gaCategory} = this.props;
    return (
      <table className="item">
        <tbody>
        {_.map(slides, (rows, j) => {
          if (rows.length > 0) {
            return (
              <tr key={j}>
                {_.map(rows, (item, k) => {
                  if (!item) {
                    return null;
                  }

                  const style = {
                    width: (100 / rows.length) + '%',
                    height: (100 / slides.length) + '%'
                  };

                  if (item.isBillPay) {
                    return (
                      <AnyBillIcon item={item}/>
                    )
                  }

                  return (
                    <td key={k} style={style}>
                      {type === SLIDE_ITEM_TYPES.ICON || item.type === 'application' ? <AppIcon icon={item} gaCategory={gaCategory}/> : null}
                      {type === SLIDE_ITEM_TYPES.CARD ? <CardItem item={item}/> : null}
                      {type === SLIDE_ITEM_TYPES.CUSTOMER ? <CustomerItem item={item}/> : null}
                      {type === SLIDE_ITEM_TYPES.SIMPLE ? <SimpleIcon icon={item}/> : null}
                      {type === SLIDE_ITEM_TYPES.LANGUAGE ? <LanguageItem language={item}/> : null}
                      {type === SLIDE_ITEM_TYPES.CATEGORY ? <CategoryIcon icon={item} gaCategory={gaCategory}/> : null}
                      {type === SLIDE_ITEM_TYPES.BILLER ? <BillerIcon icon={item}/> : null}
                    </td>
                  )
                })}
              </tr>
            )
          }
          return <tr key={j}/>
        })}
        </tbody>
      </table>
    )
  }
}

SlideItem.propTypes = {
  index: React.PropTypes.number,
  slides: React.PropTypes.arrayOf(React.PropTypes.array),
  type: React.PropTypes.string,
  itemHeight: React.PropTypes.number,
  onTapIcon: React.PropTypes.func
};

SlideItem.defaultProps = {};

export default SlideItem;
