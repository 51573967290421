import React, {Component} from 'react';
import {Session} from 'core/session';
import {_} from 'imports/lib/helpers';
import Keyboard from 'imports/ui/shared/keyboard/Keyboard';
import Button from 'imports/ui/shared/button/Button';
import Input from 'imports/ui/shared/input/Input';
import Clear from 'imports/ui/shared/clear/Clear';

export default class Email extends Component {

  componentDidMount() {
    Session.set('focus', '');
    Session.set('focus', 'email');
  }

  render() {
    const {isEmailValid, email, keyboardLayout, onTypeEmail, goToBackStep, goToNextStep, emailKeyboardLayout} = this.props;
    const nextButtonText = this.props.isSendEmail ? 'Send' : 'Next'

    return (
      <table>
        <tbody>
        <tr className="email-inputs">
          <td colSpan="2">
            <table className="email-inputs__container">
              <tbody>
                <tr>
                  <td className="email-inputs__container__description">
                    <span className="pre-wrap">{_("Email\naddress")}</span>
                  </td>
                  <td className="email-inputs__container__input">
                    <Input id="email" type="email" required={true} className="large input lower" value={email}/>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr className="email-keyboard">
          <td className="email-keyboard__text-keyboard">
            <Keyboard keys={keyboardLayout} value={email} maxLength={150} onValueChange={onTypeEmail}/>
            <Clear/>
          </td>
          <td className="email-keyboard__email-keyboard">
            <Keyboard className="email" keys={emailKeyboardLayout} value={email} maxLength={150} onValueChange={onTypeEmail}/>
            <Clear/>
          </td>
        </tr>
        </tbody>
        <tfoot>
          <tr className="finalTfoot">
            <td colSpan="2">
              <Button id="back" className="left button" onMouseDown={goToBackStep} text_="Back"/>
              {isEmailValid ? <Button id="next" className="right button" onMouseDown={goToNextStep} text_={nextButtonText}/> : null }
            </td>
          </tr>
        <tr>
        </tr>
        </tfoot>
      </table>
    );
  }
}

Email.propTypes = {
  isEmailValid: React.PropTypes.bool,
  email: React.PropTypes.string,
  keyboardLayout: React.PropTypes.arrayOf(React.PropTypes.arrayOf(React.PropTypes.string)),
  emailKeyboardLayout: React.PropTypes.arrayOf(React.PropTypes.arrayOf(React.PropTypes.string)),
  onTypeEmail: React.PropTypes.func,
  goToBackStep: React.PropTypes.func,
  goToNextStep: React.PropTypes.func,
  isSendEmail: React.PropTypes.bool
}
