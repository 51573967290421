import binder from 'imports/ui/decorators/binder';

import List from './list/ListContainer';
import Info from './info/InfoContainer';
import PhoneNumber from './number/PhoneNumberContainer';
import PhoneNumberTwice from './number_twice/PhoneNumberTwiceContainer';
import Error from './error/ErrorContainer';
import ErrorMoneyTransfer from './error_moneytransfer/ErrorMoneyTransferContainer';
import ConfirmPhoto from './confirm_photo/ConfirmPhotoContainer';
import BillScanningError from './bill_scanning_error/BillScanningErrorContainer';
import Notice from './notice/NoticeContainer';
import Amount from './amount/AmountContainer';
import BillAmount from './bill_amount/BillAmountContainer';
import BillScanning from './bill_scanning/BillScanningContainer';
import BillScanningLoading from './bill_scanning_loading/BillScanningLoadingContainer';
import EnterBillerData from './enter_user_data/EnterBillerDataContainer';
import BillerSearch from './biller_search/BillerSearchContainer';
import StartScanning from './start_scanning/StartScanningContainer';
import PhoneAccountChoice from './recipient_choice/PhoneAccountChoiceContainer';
import ScanInfo from './scan_info/ScanInfoContainer';
import Final from './final/FinalContainer';
import Email from './email/EmailContainer';
import EmailPayHere from './email/EmailPayHereContainer';
import Sent from './sent/SentContainer';
import Plus from './plus/PlusContainer';
import Digit from './plus/DigitContainer';
import Inputs from './inputs/InputsContainer';
import InputsBillpayment from './inputs/InputsBillpaymentContainer';
import Confirm from './confirm/ConfirmContainer';
import Tabbar from './tabbar/TabbarContainer';
import Loading from 'imports/ui/shared/loading/Loading';
import AdvLoading from 'imports/ui/shared/loading/AdvLoadingContainer';
import ShoppingCart from './shopping_cart/ShoppingCartContainer';
import ShoppingCartLoading from './shopping_cart_loading/ShoppingCartLoadingContainer';
import Gifts from './gifts/GiftsContainer';
import PaymentStatus from './payment_status/PaymentStatusContainer';
import ChangeProduct from './change_product/ChangeProductContainer';
import StoredSessions from './stored_sessions/StoredSessionsContainer';
import ReferralCode from './enter_user_data/ReferralCodeContainer';
import Maya5Congratulation from './maya5/Maya5CongratulationContainer';
import Maya5Description from './maya5/Maya5DescriptionContainer';
import Loans from './loans/LoansContainer';
import LoansPhoneNumber from './loans_phone_number/LoansPhoneNumberContainer';
import LoansFinal from './loans_final/LoansFinalContainer';

//ATM
import EnterPin from './atm/EnterPinContainer';
import TakeActions from './atm/TakeActionsContainer';
import Accounts from './atm/AccountsContainer';
import SelectAmount from './atm/SelectAmountContainer';
import EnterAmount from './atm/EnterAmountContainer';
import TemplateDisplayActions from './atm/TemplateDisplayActionsContainer';
import TransferCash from './atm/TransferCashContainer';
import SelectItem from './atm/SelectItemContainer';

//Card reload
import CardReloadActions from './card_reload/CardReloadActionsContainer';
import CardBalance from './card_reload/CardBalanceContainer';
import PrevalinsTransactionError from './card_reload/PrevalinsTransactionErrorContainer';

//Cash a Check
import EnterUserDataCashCheckSearch from './enter_user_data/EnterUserDataCashCheckSearchContainer';
import EnterUserDataCashCheckCustomer from './enter_user_data/EnterUserDataCashCheckCustomerContainer';
import ListSliderCashCheckId from './list_slider/ListSliderCashCheckIdContainer';
import EnterUserDataCashCheckCustomerId from './enter_user_data/EnterUserDataCashCheckCustomerIdContainer';
import StartTakePhoto from './start_scanning/StartTakePhotoContainer';
import ConfirmEnteredDataCashCheck from './confirm_entered_data/ConfirmEnteredDataCashCheckContainer';
import ExtractAmount from './bill_amount/ExtractAmountContainer';
import ConfirmCheckCash from './confirm_check_cash/ConfirmCheckCashContainer';
import QuizChallenge from './quiz_challenge/QuizChallengeContainer';

//Money transferring
import Payout from './payout/PayoutContainer';
import EnterUserData from './enter_user_data/EnterUserDataContainer';
import EnterUserDataTranspayment from './enter_user_data/EnterUserDataTranspaymentContainer';
import EnterUserAlderRecipient from './enter_user_data/EnterUserDataAlderRecipientContainer'; // set first_name and last_name
import EnterUserDataOFAC from './enter_user_data/EnterUserDataOFACContainer';
import ConfirmEnteredData from './confirm_entered_data/ConfirmEnteredDataContainer';
import ConfirmTransfer from './confirm_transfer/ConfirmTransferContainer';
import ConfirmBillScanning from './confirm_bill_scanning/ConfirmBillScanningContainer';
import BillProcessingType from './bill_processing_type/BillProcessingTypeContainer';
import ListSlider from './list_slider/ListSliderContainer';
import TransferEnterAmount from './enter_amount/EnterAmountContainer';
import RangeEnterAmount from './enter_amount/RangeAmountContainer';
import PaymentTransfer from './payment/PaymentTransferContainer';
import RecipientNotification from './recipient_notification/RecipientNotificationContainer';
import ListSliderOFAC from './list_slider/ListSliderOFACContainer';
import ListSliderPaymentLocations from './list_slider/ListSliderPaymentLocations';
import ListSliderProductContainer from './list_slider/ListSliderProductContainer';
import RecipientChoice from './recipient_choice/RecipientChoiceContainer';
import EnterUserDataSigueBankRequired from './enter_user_data/EnterUserDataSigueBankRequired';
import EnterUserDataCompliance from './enter_user_data/EnterUserDataCompliance';

//Login
import PinNumberSignIn from './pin_number_sign_in/PinNumberContainer';
import NewPinNumberSignIn from './pin_number_sign_in/NewPinNumberContainer';

import Profile from './profile/ProfileContainer';
import ProfileSelect from './profile/ProfileSelectContainer';

import ConfirmCustomerData from './confirm_customer_data/ConfirmCustomerDataContainer';
import ConfirmCustomerPayoutData from './confirm_customer_data/ConfirmCustomerPayoutDataContainer';
import ConfirmCustomerDataDigitalMint from './confirm_customer_data/ConfirmCustomerDataDigitalMint';
import EditCustomerData from './enter_user_data/EnterCustomerDataContainer';
import EditCustomerDataProfile from './enter_user_data/EditCustomerDataContainer';

import Payment from './payment/PaymentContainer';
import PaymentVirtual from './payment/PaymentVirtualContainer';
import PaymentPin from './payment_pin/PaymentPinContainer';

//Open Maya
import OpenMaya from './open_maya/OpenMayaContainer';

//Referrer Dispense
import BalanceDispense from './balance_dispense/BalanceDispenseContainer';

//Refund
import RefundInfo from './refund_info/RefundInfoContainer';

//Lottery
import Sweepstakes from './sweepstakes/SweepstakesContainer';
import SignInPhoneNumber from './sign_in_phone_number/SignInPhoneNumberContainer';
import SweepstakesCongratulation from './sweepstakes/SweepstakesCongratulationContainer';
import StartSweepstakes from './sweepstakes/StartSweepstakes';

import ClerkLoginError from './clerk_login_error/ClerkLoginErrorContainer';
import AmountClerk from './amount_clerk/AmountClerkContainer';

//Pay Here
import EnterUserDataPayHere from './enter_user_data/EnterUserDataPayHereContainer';
import ConfirmCustomerDataPayHere from './confirm_entered_data/ConfirmEnteredDataPayHereContainer';

//Check Free
import EnterUserDataCheckFreeContainer from "./enter_user_data/EnterUserDataCheckFreeContainer";
import PayoutCheckFree from './payout/PayoutCheckFreeContainer';
import CheckFreeDepositAmount from 'imports/ui/apps/bill_amount/CheckFreeDepositAmountContainer';
import CheckFreeBillAmount from 'imports/ui/apps/bill_amount/CheckFreeBillAmountContainer';

// Digital Mint
import WalletSelect from './payout/WalletSelect';
import EnterAmountCryptoContainer from './enter_amount/EnterAmountCryptoContainer';
import ConfirmTransferCryptoContainer from './confirm_transfer/ConfirmTransferCryptoContainer';

const pages = window.pageManager.pages = {
  list: List,
  info: Info,
  info2: Info,
  notice: Notice,
  no_coin_change_notice: binder(TemplateDisplayActions, {page: 'no_coin_change_notice'}),
  number: PhoneNumber,
  number_login: binder(PhoneNumber, {showLogin: true}),
  number_sign_in: binder(SignInPhoneNumber, {customSessionField: 'login'}),
  number_sign_in_clerk: binder(SignInPhoneNumber, {
    customSessionField: 'clerk',
    useUSMask: true,
    withoutBackButton: true,
  }),
  number_twice: PhoneNumberTwice,
  error: Error,
  amount: Amount,
  amount_again: Amount,
  amount_clerk: AmountClerk,
  bill_amount: BillAmount,
  bill_amount_again: BillAmount,
  bill_scanning: BillScanning,
  bill_scanning_loading: BillScanningLoading,
  bill_required_data: EnterUserDataTranspayment,
  bill_scanning_confirm: ConfirmBillScanning,
  bill_processing_type: BillProcessingType,
  bill_scanning_insert_bill: binder(TemplateDisplayActions, {page: 'bill_scanning_insert_bill'}),
  bill_scanning_confirm_bill: binder(ConfirmPhoto, {type: 'bill_scan'}),

  biller_search: BillerSearch,
  biller_search_by_name: binder(BillerSearch, {searchType: 'name', field: 'biller_name', noSkip: true}),
  biller_data: EnterBillerData,
  bill_required_data_phone: EnterUserDataTranspayment,
  scan_info: ScanInfo,
  scanning_error: binder(Error, {
    errorText: 'The image is not processed. Do you want to enter account manually?',
    buttonBackText: 'Try again',
    buttonNextText: 'Manually',
  }),
  scanning_error2: BillScanningError,
  confirm_photo: binder(ConfirmPhoto, {type: 'bill'}),
  phone_account_choice: PhoneAccountChoice,
  validate_account: Loading,
  final: Final,
  email_inner: binder(Email, {type: 'common'}),
  email: binder(Email, {type: 'email'}),
  send_disclosure_email: binder(Email, {type: 'email'}),
  sent: binder(Sent, {type: 'email'}),
  printed: binder(Sent, {type: 'common'}),
  payment_canceled: binder(Sent, {type: 'payment_canceled'}),
  plus: Plus,
  digit: Digit,
  pay: Loading,
  pay_device: Payment,
  pay_virtual: PaymentVirtual,
  payment_pin: PaymentPin,
  pay_again: Payment,
  inputs: Inputs,
  inputs_billpayment: InputsBillpayment,
  confirm: Confirm,
  tabbar: Tabbar,
  adv_loading: AdvLoading,
  loading: Loading,
  shopping_cart: ShoppingCart,
  shopping_cart_loading: ShoppingCartLoading,
  start_scanning: StartScanning,
  gifts: Gifts,
  change_product: ChangeProduct,
  stored_sessions: StoredSessions,
  customer_limit_reached: binder(TemplateDisplayActions, {page: 'customer_limit_reached'}),
  referral_code: ReferralCode,
  maya5_description: Maya5Description,
  maya5_congratulation: Maya5Congratulation,
  confirm_with_pin_number: binder(PinNumberSignIn, {
    headerType: 'confirming',
    sessionScope: 'app',
    hasOwnPincode: false,
  }),

  payment_status: PaymentStatus,
  payment_status_cancel: binder(TemplateDisplayActions, {page: 'payment_status_cancel'}),
  payment_status_cancel_error: binder(TemplateDisplayActions, {page: 'payment_status_cancel_error'}),
  payment_status_refund_error: binder(TemplateDisplayActions, {page: 'payment_status_refund_error'}),
  support_number: binder(PhoneNumber, {isSupportNumber: true, prefix: 'support'}),
  sent_support: binder(Sent, {type: 'support'}),

  //ATM
  insert_card: binder(TemplateDisplayActions, {page: 'insert_card'}), //InsertCard,
  insert_card_simple: binder(TemplateDisplayActions, {page: 'insert_card'}), //InsertCard,
  enter_pin: EnterPin,
  take_actions: TakeActions,
  accounts: Accounts,
  extract_select_amount: binder(SelectAmount, {extract: true}),
  transfer_select_amount: binder(SelectAmount, {extract: false}),
  enter_extract_amount: binder(EnterAmount, {extract: true}),
  enter_transfer_amount: binder(EnterAmount, {extract: false}),
  transfer_cash: TransferCash,

  //Card Reload
  insert_card_reload: binder(TemplateDisplayActions, {page: 'insert_card_reload'}), //InsertCard,
  insert_card_reload_simple: binder(TemplateDisplayActions, {page: 'insert_card_reload'}), //InsertCard,
  card_reload_actions: CardReloadActions,
  card_balance: CardBalance,
  prevalins_transaction: Loading,
  prevalins_transaction_error: PrevalinsTransactionError,

  transfer_final: binder(TemplateDisplayActions, {page: 'transfer_final'}),
  return_card_transfer: binder(TemplateDisplayActions, {page: 'transfer_status'}),
  return_card: binder(TemplateDisplayActions, {page: 'return_card'}), //ReturnCard,
  return_card_extraction: binder(TemplateDisplayActions, {page: 'return_card_extraction'}), //ReturnCard,
  return_card_balance: binder(TemplateDisplayActions, {page: 'return_card_balance'}), //ReturnCard,
  return_card_transaction_error: binder(TemplateDisplayActions, {page: 'return_card_transaction_error'}), //ReturnCard,
  return_card_internal_error: binder(TemplateDisplayActions, {page: 'return_card_internal_error'}), //ReturnCard,
  return_card_continue: binder(TemplateDisplayActions, {page: 'return_card_continue'}), //ReturnCard,
  internal_error: binder(TemplateDisplayActions, {page: 'internal_error'}), //Error,
  extract_money: binder(TemplateDisplayActions, {page: 'extract_money'}), //ExtractMoney,
  extract_money_success: binder(TemplateDisplayActions, {page: 'extract_money_success'}), //ExtractMoney,
  extract_money_not_enough: binder(TemplateDisplayActions, {page: 'extract_money_not_enough'}), //BadCardContainer
  transfer_money_not_enough: binder(TemplateDisplayActions, {page: 'transfer_money_not_enough'}), //BadCardContainer
  transaction_canceled: binder(TemplateDisplayActions, {page: 'transaction_canceled'}), //TransactionCanceled,
  bad_card: binder(TemplateDisplayActions, {page: 'bad_card'}), //BadCardContainer
  device_error: binder(TemplateDisplayActions, {page: 'device_error'}), //BadCardContainer
  terminal_locked_error: binder(TemplateDisplayActions, {page: 'terminal_locked_error'}), //BadCardContainer
  account_amount: binder(TemplateDisplayActions, {page: 'account_amount'}),
  coming_soon: binder(TemplateDisplayActions, {page: 'coming_soon'}),
  fee: binder(TemplateDisplayActions, {page: 'fee'}),
  emv_request: Loading,
  emv_cancel: Loading,
  emv_response: Loading,
  emv_enter_pin: Loading,
  emv_process: Loading,
  emv_select_application: SelectItem,
  emv_select_language: SelectItem,
  emv_confirm_application: binder(TemplateDisplayActions, {page: 'emv_confirm_application'}),
  emv_show_message: binder(TemplateDisplayActions, {page: 'emv_show_message'}),
  emv_select_transaction_type: TakeActions,
  emv_select_amount: SelectAmount,
  emv_enter_amount: binder(EnterAmount, {extract: true}),
  emv_select_account: Accounts,
  emv_account_amount: binder(TemplateDisplayActions, {page: 'emv_account_amount'}),
  emv_return_card_extraction: binder(TemplateDisplayActions, {page: 'return_card_extraction'}), //ReturnCard,
  emv_return_card_emv_error: binder(TemplateDisplayActions, {page: 'return_card_emv_error'}), //ReturnCard,

  // Digital Mint
  dm_customer_number: binder(PhoneNumber, {isSenderNumber: true, hideHeader: true, showLogin: true, prefix: 'sender'}),
  dm_pin_code: binder(PinNumberSignIn, {sessionScope: 'dm_login', headerType: 'logging', headerScope: 'DigitalMint', hasOwnPincode: true}),
  dm_confirm_code: binder(PinNumberSignIn, {sessionScope: 'dm_login', headerType: 'confirming', headerScope: 'DigitalMint', maxLength: 6}),
  dm_new_pin_code: binder(NewPinNumberSignIn, {sessionScope: 'dm_login', headerScope: 'DigitalMint'}),
  dm_wallet_select: WalletSelect,
  dm_product_select: ListSliderProductContainer,
  dm_confirm_scan_data: ConfirmCustomerDataDigitalMint,
  take_crypto_wallet_print: binder(TemplateDisplayActions, {page: 'take_crypto_wallet_print'}),
  dm_error: binder(TemplateDisplayActions, {page: 'custom_error'}),
  enter_amount_crypto: EnterAmountCryptoContainer,
  confirm_transfer_crypto: ConfirmTransferCryptoContainer,
  scan_id_barcode: binder(TemplateDisplayActions, {page: 'scan_id_barcode'}),
  scan_wallet_qr: binder(TemplateDisplayActions, {page: 'scan_wallet_qr'}),

  // Money transferring
  sender_number: binder(PhoneNumber, {isSenderNumber: true, prefix: 'sender', showLogin: true}),
  recipient_number: binder(PhoneNumber, {isRecipientNumber: true, prefix: 'recipient'}),
  recipient_email: binder(EnterUserData, {isSender: true, prefix: 'recipient', prefixFields: 'recipient_email'}),
  recipient_city: binder(EnterUserData, {isSender: true, prefix: 'recipient', prefixFields: 'recipient_city'}),
  recipient_city_sigue: binder(EnterUserData, {
    isSender: true,
    prefix: 'recipient',
    prefixFields: 'recipient_city_sigue',
  }),
  payment_city_sigue: binder(EnterUserData, {
    isSender: true,
    prefix: 'payment_agent',
    prefixFields: 'recipient_city_sigue',
  }),
  recipient_town: binder(EnterUserData, {isSender: true, prefix: 'recipient', prefixFields: 'recipient_town'}),
  payout: Payout,

  enter_amount_transfer: TransferEnterAmount,
  enter_amount_transfer_again: TransferEnterAmount,

  enter_sender_data: binder(EnterUserData, {prefix: 'sender', isSender: true}), // TODO : remove isSender (exist for compatibility)
  enter_sender_data_viamericas: binder(EnterUserData, {
    prefix: 'sender',
    prefixFields: 'senderViamericas',
    isSender: true,
  }), // TODO : remove isSender (exist for compatibility)
  validate_sender_data: binder(TemplateDisplayActions, {page: 'validate_sender_data'}),
  confirm_sender_data: binder(ConfirmEnteredData, {isSender: true}),

  enter_recipient_data: binder(EnterUserData, {prefix: 'recipient', isRecipient: true}), // TODO : remove isRecipient (exist for compatibility)

  list_slider: ListSlider,
  list_slider_ofac: ListSliderOFAC,
  enter_bank_data: binder(EnterUserData, {prefix: 'bank', isBank: true}), // TODO : remove isBank (exist for compatibility)
  confirm_transfer: ConfirmTransfer,
  ofac_required_data: EnterUserDataOFAC,
  pay_transfer: Payment,
  pay_transfer_again: Payment,

  recipient_choice: RecipientChoice,

  recipient_notification: RecipientNotification,
  confirm_recipient_data: binder(ConfirmEnteredData, {isRecipient: true}),
  transaction_error_moneytransfer: ErrorMoneyTransfer,
  redirect_transaction_validation: Loading,
  redirect_pincode_verification: Loading,

  sigue_error: binder(TemplateDisplayActions, {page: 'sigue_error'}),
  sigue_warning: binder(TemplateDisplayActions, {page: 'sigue_warning'}),
  sigue_warning_recipient: binder(TemplateDisplayActions, {page: 'sigue_warning'}),
  sigue_bank_required_fields: EnterUserDataSigueBankRequired,
  sigue_compliance: EnterUserDataCompliance,
  sigue_payment_locations_list_slider: ListSliderPaymentLocations,
  sigue_enter_sender_data: binder(EnterUserData, {prefix: 'sender', prefixFields: 'sender_sigue', isSender: true}),
  sigue_confirm_sender_data: binder(ConfirmEnteredData, {
    prefix: 'sender',
    prefixFields: 'sender_sigue',
    isSender: true,
  }),
  sigue_enter_recipient_data: binder(EnterUserData, {
    prefix: 'recipient',
    prefixFields: 'recipient_sigue',
    isRecipient: true,
  }), // TODO : remove isRecipient (exist for compatibility)

  //Login
  on_login: Loading,
  on_launch_login: Loading,
  on_login_pay_again: Loading,
  on_login_recipient_choice: Loading,

  logout: Loading,

  pin_redirect: Loading,
  pin_code_sing_in: binder(PinNumberSignIn, {headerType: 'logging', sessionScope: 'login', hasOwnPincode: false}),
  pin_number_sing_in: binder(PinNumberSignIn, {headerType: 'logging', sessionScope: 'login', hasOwnPincode: true}),
  new_pin_number_sing_in: binder(NewPinNumberSignIn, {sessionScope: 'login'}),

  clerk_login_error: ClerkLoginError,

  confirm_customer_data: ConfirmCustomerData,
  edit_customer_data: EditCustomerData,
  edit_customer_data_profile: EditCustomerDataProfile,
  confirm_payout: ConfirmCustomerPayoutData,

  profile: Profile,
  profile_select: ProfileSelect,

  //Open Maya
  open_maya: OpenMaya,

  balance_dispense: BalanceDispense,

  refund_payment_status: Loading,
  refund_canceled_transaction: Loading,
  refund_info: RefundInfo,
  refund_money: binder(TemplateDisplayActions, {page: 'refund_money'}), //ExtractMoney,
  refund_money_success: binder(TemplateDisplayActions, {page: 'refund_money_success'}), //ExtractMoney,

  //Alder App
  alder_recipient_data: EnterUserAlderRecipient, // first_name and last_name
  alder_recipient_data_after_login: EnterUserAlderRecipient,
  range_amount: RangeEnterAmount,

  //Lottery
  sweepstakes: Sweepstakes,
  sweepstakes_congratulation: SweepstakesCongratulation,
  sweepstakes_loose: binder(TemplateDisplayActions, {page: 'sweepstakes_loose'}), //ExtractMoney,
  sweepstakes_start_question: StartSweepstakes, //ExtractMoney,

  loans: Loans,
  loans_phone_number: LoansPhoneNumber,
  loans_final: binder(TemplateDisplayActions, {page: 'loans_final'}), //ExtractMoney,,

  //Cash a Check
  cash_check_search: EnterUserDataCashCheckSearch,
  cash_check_create_customer: EnterUserDataCashCheckCustomer,
  cash_check_create_customer_id: EnterUserDataCashCheckCustomerId,
  cash_check_insert_id: binder(TemplateDisplayActions, {page: 'insert_customer_id'}), //InsertCard,
  cash_check_return_id: binder(TemplateDisplayActions, {page: 'return_customer_id'}),
  cash_check_id_list: ListSliderCashCheckId,
  cash_check_confirm_data: ConfirmEnteredDataCashCheck,
  cash_check_insert_check: binder(TemplateDisplayActions, {page: 'insert_check'}), //InsertCard,
  cash_check_return_check: binder(TemplateDisplayActions, {page: 'return_customer_check'}),
  confirm_photo_customer: binder(ConfirmPhoto, {type: 'customer'}),
  confirm_photo_check_scan: binder(ConfirmPhoto, {type: 'check_scan'}),
  confirm_photo_id_scan: binder(ConfirmPhoto, {type: 'id_scan'}),
  start_take_photo: StartTakePhoto,
  cash_check_amount: ExtractAmount,
  cash_check_review: binder(TemplateDisplayActions, {page: 'cash_check_review'}),
  cash_check_amount_info: ConfirmCheckCash,
  cash_check_amount_info2: binder(TemplateDisplayActions, {page: 'cash_check_amount_info2'}),
  cash_check_customer_exists: binder(TemplateDisplayActions, {page: 'cash_check_customer_exists'}),
  cash_check_customer_create: binder(TemplateDisplayActions, {page: 'cash_check_customer_create'}),
  cash_check_continue: binder(TemplateDisplayActions, {page: 'cash_check_continue'}),
  cash_check_wait_review: binder(TemplateDisplayActions, {page: 'cash_check_wait_review'}),
  cash_check_customer_duplicated: binder(TemplateDisplayActions, {page: 'cash_check_customer_duplicated'}),
  cash_check_customer_verification_failed: binder(TemplateDisplayActions, {page: 'cash_check_customer_verification_failed'}),
  cash_check_success: binder(TemplateDisplayActions, {page: 'cash_check_success'}),
  cash_check_challenge: QuizChallenge,
  // cash_check_clerk_login: binder(PhoneNumber, {isClerkNumber: true}),
  // cash_check_clerk_wait_approve: binder(TemplateDisplayActions, {page: 'cash_check_clerk_wait_approve'}),

  // Pay here
  pay_here_enter_user_data: EnterUserDataPayHere,
  pay_here_confirm_customer_data: ConfirmCustomerDataPayHere,
  pay_here_email: binder(EmailPayHere, {type: 'email'}),

  // Check Free
  check_free_bill_required_data: EnterUserDataCheckFreeContainer,
  check_free_bill_required_data2: EnterUserDataCheckFreeContainer,
  check_free_bill_required_data_id_type: EnterUserDataCheckFreeContainer,
  check_free_error: binder(TemplateDisplayActions, {page: 'check_free_error'}),
  check_free_pop_authorized: binder(TemplateDisplayActions, {page: 'check_free_pop_authorized'}),
  check_free_required_fields_invalid: binder(TemplateDisplayActions, {page: 'check_free_required_fields_invalid'}),
  check_free_delivery: binder(TemplateDisplayActions, {page: 'check_free_delivery'}),
  check_free_consumer: binder(TemplateDisplayActions, {page: 'check_free_consumer'}),
  check_free_instruction: binder(TemplateDisplayActions, {page: 'check_free_instruction'}),
  check_free_instruction2: binder(TemplateDisplayActions, {page: 'check_free_instruction'}),
  check_free_payout: PayoutCheckFree,
  check_free_bill_amount: binder(BillAmount, {field: 'bill_original_amount'}),
  check_free_bill_amount_entergy: binder(BillAmount, {field: 'bill_original_amount'}),
  check_free_bill_amount_again: binder(BillAmount, {field: 'bill_original_amount'}),
  check_free_overpay: binder(TemplateDisplayActions, {page: 'check_free_overpay'}),
  check_free_without_customer: Loading,

  // General
  index: Loading,
  redirect: Loading,
  redirect1: Loading,
  redirect2: Loading,
  redirect3: Loading,
  redirect4: Loading,
  redirect_customer: Loading,

  _debug: binder(TemplateDisplayActions, {page: '_debug'}),
};

export default pages;
