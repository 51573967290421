import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import {Session} from 'core/session';
import IW from 'imports/iw_api';
import Prods from 'imports/collections/products';
import SimpExp from 'imports/lib/external/simpexp';
import BillAmount from './BillAmount';
import getPageManager from 'imports/lib/page_manager'
import {N, C} from 'imports/lib/helpers'
import CONFIG from 'imports/config'

const numpadLayout = [
  [1, 2, 3],
  [4, 5, 6],
  [7, 8, 9],
  ['.', 0, 'delete']
];

export default createContainer((params) => {
  const field = params.field || 'original_amount';
  const maxAmount = Math.min(N(IW.appGetVal('customer_limit_amount')), CONFIG.BILL_MAX_AMOUNT);

  function getPricePattern() {
    return '^\\d{1,4}(\\.\\d{1,2}|\\.)?$';
  }

  function getOriginalAmount() {
    return N(IW.appGetVal(field))
  }

  function isValid(v) {
    if (_.isFunction(params.isValid)) return params.isValid(v);
    return v > 0 && v <= maxAmount;
  }

  function onClickNext(e) {
    e.preventDefault();
    // Validation
    if (!isValid(getOriginalAmount())) return;
    getPageManager().next();
  }

  function onClickBack(e) {
    e.preventDefault();
    getPageManager().back();
  }

  function onChangeValue(v) {
    if (!isValid(N(v)) && v !== '') return;
    Session.set('amount', v);
    IW.appSetVal(field, N(v));
  }

  function clearInput() {
    Session.set('amount', '');
    IW.appSetVal(field, N(''));
  }

  function getValue() {
    return Session.get('amount');
  }

  return _.extend({
    field: field,
    title_: 'Amount to Pay',
    pricePattern: getPricePattern(),
    originalAmount: getOriginalAmount(),
    numPadLayout: numpadLayout,
    valid: isValid(getOriginalAmount()) && getOriginalAmount() >= CONFIG.BILL_MIN_AMOUNT,
    amount: getValue(),
    maxAmount: C(maxAmount, '$'),
    maxLength: 9,
    keyboardPattern: '^\\d{1,4}(\\.\\d{1,2}|\\.)?$',
    isFlexible: true,
    onClickNext: onClickNext,
    onClickBack: onClickBack,
    onChangeValue: onChangeValue,
    clearInput,
    focus : field,
    availableAmount: params.availableAmount
  }, params);

}, BillAmount);
