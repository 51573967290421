import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import AmountStatistic from 'imports/ui/shared/amount_statistic/AmountStatisticContainer';
import PaymentDetails from 'imports/ui/shared/payment_details/PaymentDetailsContainer';
import UserInfoCard from 'imports/ui/shared/user_info/UserInfoCard';
import {_} from 'imports/lib/helpers';

export default class EnterAmount extends Component {

  renderTextTitle(service_name, country) {
    if (service_name === 'viamericas') return _('*Viamericas may make money when it changes your US Dollars into ' +
      'a different currency.\nRecipient may receive less due to fees charged by recipient’s bank and applicable taxes.')
    if (service_name === 'transfast') return _('*Transfast makes money when it changes your U.S. dollars to another currency')
    if (service_name === 'sigue') return _('* Exchange rate is subject to change and is guaranteed at the time ' +
      'this disclosure is provided. Should you decide to conduct the transaction at a later time a new disclosure will be ' +
      'issued.\nIn the event you provide an incorrect account number or recipient institution identifier, you may lose the ' +
      'Transfer Amount despite our reasonable effort to retrieve misdirected funds.') + (country === 'COLOMBIA' ?
      '\n' + _('** Recipient may receive less due to taxes and additional non-covered third party fees charged within destination country.') :
      '')

    return '';
  }

  render() {
    const {onNext, onBack, userFrom, userTo, via, country, disclosureOnRight} = this.props;

    const poweredByStyles = {
      span: {
        display: 'none',
        position: 'absolute',
        width: 1280,
        textAlign: 'center',
        top: 150,
        fontWeight: 700
      },
      img: {
        width: 310,
        position: 'relative',
        top: 7,
        marginLeft: 10
      }
    }

    return (
      <table className="confirm-transfer-data">
        <thead>
          <tr>
            <th colSpan="3" className="confirm-transfer-data__title" _>Pre-Payment Disclosure</th>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td className="confirm-transfer-data__content">
            <UserInfoCard title_="From:" {...userFrom}/>
          </td>
          <td>
            <div className="right-arrow-img"></div>
          </td>
          <td className="confirm-transfer-data__content">
            <UserInfoCard title_="To:" {...userTo}/>
          </td>
        </tr>
        <tr>
          <td className="confirm-transfer-data__details">
            <AmountStatistic className="amount-statistic"/>
          </td>
          <td>

          </td>
          <td className="confirm-transfer-data__details" style={{padding: '0 50px 0 0'}}>
            <PaymentDetails className="payment-details" disclosure={disclosureOnRight && this.renderTextTitle(via, country)}/>
          </td>
        </tr>
        <tr>
          {!disclosureOnRight && (
            <td style={{fontSize: 22, lineHeight: 1.1, paddingLeft: 50}} colSpan="3" className="enter-amount__addition_text">
              {this.renderTextTitle(via, country)}
            </td>
          )}
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="3">
            <Button id="back" className="left button" text_="Back" onMouseDown={onBack}/>
            <Button id="next" className="right button" onMouseDown={onNext} text_="Confirm"/>
          </td>
        </tr>
        </tfoot>
        <span style={poweredByStyles.span} _>
          Transfers powered by
           <div className="db-css-confirm_transfer-logo"></div>
        </span>
      </table>
    )
  }
}

EnterAmount.propTypes = {
  onNext: React.PropTypes.func,
  onBack: React.PropTypes.func,
};
