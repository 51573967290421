import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import Inputs from './Inputs';
import IW from 'imports/iw_api';
import Prods from 'imports/collections/products';
import SimpExp from 'imports/lib/external/simpexp';
import getPageManager from 'imports/lib/page_manager';
import {Session} from 'core/session';
import {N, capitalize} from 'imports/lib/helpers';
import {KEYBOARD_NUMBER_3ROW, KEYBOARD_BILLER_ACCOUNT} from 'imports/lib/constants';

export default createContainer((params) => {

  const product = Prods.findOne(N(FlowRouter.getParam('productId')));
  //if (product) product.account_masks = ['\\d{2}[^A-Z0-9]?\\d{3}', '\\d{4}[^A-Z0-9]?\\d{3}']

  const inputValidation = {
    'maxLength': {
      'account1': 22,
      'account2': 22
    },
    'keyboardPattern': {
      'account1': '',
      'account2': ''
    },
    'flexible': {
      'account1': false,
      'account2': false
    }
  };

  const focusInput = Session.get('focus');

  function accnumInstructions() {
    if (product)
      return product.account_nr_instructions;
    return ''
  }

  function account1() {
    return IW.appGetVal('account1');
  }

  function account2() {
    return IW.appGetVal('account2')// || IW.beginningByPattern(new SimpExp(pattern).gen(ch), ch)
  }

  function placeholder() {
    return '';
  }

  function valid() {
    return !!( IW.appGetVal('account1') && getMatchMask(IW.appGetVal('account1')) &&
    IW.appGetVal('account1') === IW.appGetVal('account2'));
  }

  function showAlert() {
    const mask = getMatchMask(IW.appGetVal('account1'));
    if (!!(IW.appGetVal('account1') && IW.appGetVal('account2') &&
      mask &&
      mask.test(IW.appGetVal('account2')) &&
      IW.appGetVal('account1').length === IW.appGetVal('account2').length &&
      IW.appGetVal('account1') !== IW.appGetVal('account2'))){
      return 'The Account Numbers do not match';
    }
    return '';
  }

  function getMaxLength() {
    const masks = (product && product.account_masks) || [];
    return _.max(_.map(masks, function(m){
      const gen = new SimpExp(m).gen();
      return (gen && gen.length) || 0
    })) || 0;
  }

  function getValidAccount(v, startIndex = 0) {
    function replace(source, start, length, insertion){
      const copy = source.split('');
      copy.splice(start, length, insertion);
      return copy.join('')
    }
    const masks = (product && product.account_masks) || [];
    const values = []
    for (let i = startIndex; i < masks.length; i++) {
      let randexp = '';
      let randexpValue = '';
      let pattern = masks[i] || '\\d{16}';

      randexp = new SimpExp(pattern).gen().toUpperCase();

      let value1 = v;
      let value2 = []
      let v1 = 0;
      let v2 = 0;

      let invalidMask = randexp.replace(/[ -.]/g, '').length < v.replace(/[ -.]/g, '').length;
      for (v1 = 0; v1 < value1.length && !invalidMask; v1++) {
        if (((/[ -.]/).test(value1[v1]) && (/[ -.]/).test(randexp[v2])) ||
          ((/[A-Z0-9]/).test(value1[v1])) && new RegExp(pattern).test(replace(randexp, v2, 1, value1[v1]))) {
          value2.push(value1[v1]);
          v2++;
          continue;
        }
        if (/[ -.]/.test(randexp[v2])) {
          value2.push(randexp[v2]);
          v1--;
          v2++;
          continue;
        }
        if (/[ -.]/.test(value1[v1]) && getValidAccount(v, i + 1) === null) {
          continue;
        }
        invalidMask = true;
      }

      if (invalidMask) continue;

      value2 = value2.join('')
      randexpValue = value2 + randexp.slice(value2.length);
      pattern = '^' + pattern + '$';
      if (new RegExp(pattern).test(randexpValue)){
        values.push({v: value2, e: randexp.length - value2.length});
      }
    }
    values.sort((a, b) => a.e - b.e);
    return values.length === 0 ? null : values[0].v;
  }

  function getMatchMask(v) {
    const masks = (product && product.account_masks) || [];
    for (let i = 0; i < masks.length; i++) {
      const pattern = masks[i] || '\\d{16}';
      const regexp = new RegExp(`^${pattern}$`)
      if (regexp.test(v)) {
        return regexp;
      }
    }
    return null;
  }

  function onClickNext(e) {
    e.preventDefault();

    if (focusInput === 'account1') {
      Session.set('focus', 'account2');
      return;
    }

    // Validation
    if (!valid()) return;
    IW.appSetVal('account', IW.appGetVal('account1'));
    getPageManager().next();
  }

  function onClickBack(e) {
    e.preventDefault();

    if (focusInput === 'account2') {
      Session.set('focus', 'account1');
      return;
    }

    getPageManager().back();
  }

  function onChangeValue(v) {
    const validAccount = getValidAccount(v);
    if (validAccount === null) return;
    IW.appSetVal(focusInput, validAccount);
  }

  function clearInput() {
    IW.appSetVal(focusInput, '');
  }

  function getValue(name) {
    return IW.appGetVal(name);
  }

  function getValidationData(type, name) {
    return inputValidation[type][name];
  }

  return {
    accnumInstructions: accnumInstructions(),
    accnumPattern: '',
    pricePattern: '',
    accountFieldName: IW.appGetVal('account_field_name') ? capitalize(IW.appGetVal('account_field_name')) : 'Account #',
    account2FieldName: 'Re-enter account #',
    account1: account1(),
    account2: account2(),
    placeholder: placeholder(),
    keyboardLayout: KEYBOARD_BILLER_ACCOUNT,
    numPadLayout: KEYBOARD_NUMBER_3ROW,
    showNextButton: valid() || focusInput === 'account1',
    showAlert: showAlert(),
    value: getValue(focusInput),
    maxLength: getValidationData('maxLength', focusInput),
    keyboardPattern: getValidationData('keyboardPattern', focusInput),
    isFlexible: getValidationData('flexible', focusInput),
    isInputLong: getMaxLength() > 22,
    onClickNext: onClickNext,
    onClickBack: onClickBack,
    onChangeValue: onChangeValue,
    clearInput,
    focus: focusInput,
  }

}, Inputs);
