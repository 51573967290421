import {Session} from 'core/session'
import IW from 'imports/iw_api'

export default function(pageBack, pageNext, onStart){
  return function({lastAction, lastStep}){
    if (IW.appGetVal('amount') >= IW.appGetVal('requested_amount') &&
      IW.appGetVal('requested_amount') > 0) {
      return lastAction === 'next' ? pageNext : pageBack;
    }

    typeof onStart === 'function' && onStart(lastStep);
    return {
      back: pageBack,
      next: pageNext
    }
  }
}

