import React, {Component} from 'react';
import Loading from 'imports/ui/shared/loading/Loading';
import SliderContainer from 'imports/ui/shared/slider/SliderContainer';
import { SLIDE_ITEM_TYPES } from 'imports/lib/constants';

export default class MainMenu extends Component {
  render() {
    const {favorites} = this.props;
    let inner = null
    if (favorites && favorites.length) {
      inner = <SliderContainer type={SLIDE_ITEM_TYPES.ICON} apps={favorites} height={660} itemHeight={33}/>
    } else {
      inner = <div className="no-favorites" _>You haven't added any product to "Favorites"</div>
    }

    return (
      <table style={{"height": "calc(100% - 110px)"}}>
        <tbody>
          <tr>
            <td id="apps" className={(!favorites || !favorites.length) ? "v-middle" : ""}>
              {inner}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

MainMenu.propTypes = {
  favorites: React.PropTypes.arrayOf(React.PropTypes.array)
};