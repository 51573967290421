import React from 'react';

export default class Input extends React.Component {
  render() {
    const {id, className, onChange, placeholder, style, maxLength, type, pattern, value, readOnly, required, disabled} = this.props;

    return (
      <input id={id} className={className} style={style} onChange={onChange}
             maxLength={maxLength} readOnly={readOnly} required={required}
             type={type} pattern={pattern} value={value} placeholder={placeholder} disabled={disabled}/>
    )
  }
}


Input.propTypes = {
  id: React.PropTypes.string,
  className: React.PropTypes.string,
  onChange: React.PropTypes.func,
  placeholder: React.PropTypes.string,
  style: React.PropTypes.object,
  maxLength: React.PropTypes.number,
  type: React.PropTypes.string,
  pattern: React.PropTypes.string,
  value: React.PropTypes.string,
  readOnly: React.PropTypes.bool,
  required: React.PropTypes.bool
};

Input.defaultProps = {
  id: "",
  className: "",
  onChange: () => {
  },
  placeholder: "",
  style: {},
  maxLength: 1000,
  type: 'text',
  pattern: '',
  value: '',
  readOnly: false,
  required: false,
  disabled: false
};