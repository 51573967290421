import {createContainer} from 'imports/lib/react_createContainer';
import {Session} from 'core/session';
import PinNumber from './PinNumber';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api'
import {capitalize, createLazyExecution} from 'imports/lib/helpers';
import createSessionMethods from 'imports/lib/createSessionMethods'

const MAX_LENGTH = 4;

const showResend = createLazyExecution(() => {
  Session.set('pin_resend_hidden', false);
}, 15000);

export default createContainer((params) => {
  const sessionScope = params.sessionScope;
  const disableResetPin = params.disableResetPin;

  const SM = createSessionMethods(sessionScope);

  const pinNumber = SM.getVal('pin_number');

  function goBack() {
    cleanAlerts();
    getPageManager().back();
  }

  function onPrintPin(key) {
    if (key.length > (params.maxLength || MAX_LENGTH)) return;

    SM.setVal('pin_number', key);
    cleanAlerts();
  }

  function clearInput() {
    SM.setVal('pin_number', '');
    cleanAlerts();
  }

  function cleanAlerts() {
    SM.setVal('error_message_code', '');
  }

  function sendSms() {
    const phone = SM.getVal('phone_number');
    IW.callSSO('get_pin_code', {phone_number: phone}, sessionScope);
    showResend();
  }

  return {
    goBack: goBack,
    onPrintPin: onPrintPin,
    clearInput: clearInput,
    pinNumber: pinNumber,
    hasOwnPincode: params.hasOwnPincode,
    isResendShown: !Session.get('pin_resend_hidden'),
    headerType: params.headerType,
    headerScope: params.headerScope,
    onChangePin: () => {
      Session.set('pin_resend_hidden', true);
      showResend();
      getPageManager().change()
    },
    errorMessage: SM.getVal('error_message_code'),
    disableResetPin
  };

}, PinNumber);