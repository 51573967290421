import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import IW from 'imports/iw_api';
import QuizChallenge from './QuizChallenge';
import {Session} from 'core/session';
import getPageManager from 'imports/lib/page_manager';

let lastTimeout = 0;

export default createContainer((params) => {
  // return {
  //   prompt: 'Question asdfadsf dasfdasfdasf dasfdsafdasf adsfadsfdasf adsfadsf dasf asdfdasf asdfasdfasdf fdgdfgdfgdfg dfgdfgdfgdfg????',
  //   answers: [
  //     'Cool',
  //     'Long answer asdfadsf sdaf das fdas fd saf dasf dasfd asfs dafad sfd asfsdafadsf!!!!',
  //     'Many',
  //     'Valiants'
  //   ],
  //   onAnswer: (v) => getPageManager().next(v),
  //   onBack: () => getPageManager().back()
  // };

  return {
    prompt: IW.appGetVal('verify_customer_prompt'),
    answers: IW.appGetVal('verify_customer_answers'),
    onAnswer: (v) => getPageManager().next(v),
    onBack: () => getPageManager().back()
  };

}, QuizChallenge);
