/**
 * Created by vitaliy on 13.03.17.
 */
import {createContainer} from 'imports/lib/react_createContainer'
import EnterUserDataContainer from './EnterUserDataContainer'
import {KEYBOARDS, TRANSFER_INPUTS_RESULT} from 'imports/lib/constants'
import IW from 'imports/iw_api'
import {Session} from 'core/session'

export default createContainer((params) => {
  // const requiredFields = IW.appGetVal('ofac_fields')

  const fields = [
    {
      name: 'first_name',
      describe: 'First name',
      describe_output: 'First name',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      validation: {
        required: true,
        minLength: 2,
        maxLength: 28
      }
    },
    {
      name: 'last_name',
      describe: 'Last name',
      describe_output: 'Last name',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      validation: {
        required: true,
        minLength: 2,
        maxLength: 28
      }
    },
  ]


  return {
    fields,
    prefix: 'recipient',
  }
}, EnterUserDataContainer)