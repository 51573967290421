import IW from 'imports/iw_api';
import profileFlow from '../app_profile_common'
import _ from 'lodash'

export default function () {
  return function*() {

    const phone = IW.loginGetVal('phone_number');
    const accessToken = IW.loginGetVal('access_token');

    IW.loginSetVal('customer_data_result', null)
    IW.callSSOCustomerData({phone_number: phone, access_token: accessToken}, 'login');
    yield 'loading login customer_data_result';

    return {
      next: function(){
        const action = IW.loginGetVal('profile_action');
        if (!_.has(profileFlow, action)) return 'none';

        return action;
      }
    }
  };
}
