import React from 'react';
import KeyboardKey from '../keyboard/KeyboardKey';
import keyboardOnPress from '../../decorators/keyboardOnPress';

class NumpadDisabledKey extends React.Component {
  render() {
    const {keys, onPress, className, lockedKeys} = this.props;

    return (
      <table id="numpad" className={className}>
        <tbody>
        {keys.map((row, r) => (
          <tr key={r}>
            {row.map((key, k) => (
              <td key={k}>
                { lockedKeys.indexOf(key) > -1 ? <div className="disabled-key"><KeyboardKey key={k} keyName={key} /></div>: <KeyboardKey key={k} keyName={key} onPress={onPress}/> }
              </td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>
    )
  }
}

NumpadDisabledKey.propTypes = {
  keys: React.PropTypes.arrayOf(React.PropTypes.arrayOf(React.PropTypes.any)),
  lockedKeys : React.PropTypes.array,
  onPress: React.PropTypes.func,
  className : React.PropTypes.string
};

NumpadDisabledKey.defaultProps = {
  className: ""
};

export default keyboardOnPress(NumpadDisabledKey)
