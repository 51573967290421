import {FlowRouter} from 'core/Router';
import {triggerMouseEvent} from './helpers';

const TESTS = {}
const GENERATOR_STACK = []
const PATH_STACK = []

export const assert = {
  page(expected) {
    if (state().page !== expected) throw ('Page is not ' + expected)
  },

  exists(selector) {
    if ($(selector).length === 0) throw (`Element ${selector} does not exist`)
  },

  regexp(selector, regexp) {
    if (!regexp.test($(selector).text())) throw ('Page does not contain ' + regexp)
  },

  not: {
    page(expected) {
      if (state().page === expected) throw ('Page is ' + expected)
    },

    exists(selector) {
      if ($(selector).length !== 0) throw (`Element ${selector} exists`)
    },

    regexp(selector, regexp) {
      if (regexp.test($(selector).text())) throw ('Page contains ' + regexp)
    },
  }
}

export const actions = {
  go(path) {
    return {type: 'go', path}
  },

  mousedown(selector, errorMessage) {
    if ($(selector).length === 0) throw errorMessage;
    triggerMouseEvent($(selector)[0], 'mousedown');
    return {type: 'wait', till: () => true, timeout: 100}
  },

  click(selector, errorMessage) {
    if ($(selector).length === 0) throw errorMessage;
    triggerMouseEvent($(selector)[0], 'click');
    return {type: 'wait', till: () => true, timeout: 100}
  },

  loading(timeout) {
    return {
      type: 'wait',
      till: () => FlowRouter.getParam('step') === 'loading',
      timeout: timeout || 15000,
      message: 'Loading longer 15 sec'
    }
  },

  waitLoading(timeout) {
    let isBeforeLoading = true;
    const previousStep = FlowRouter.getParam('step');
    return {
      type: 'wait',
      till: () => {
        const step = FlowRouter.getParam('step');
        if (step === 'loading') {
          isBeforeLoading = false;
        }
        if (isBeforeLoading) return step !== 'loading' && step === previousStep;
        return step === 'loading';
      },
      timeout: timeout || 15000,
      message: 'Wait Loading longer 15 sec'
    }
  },

  sleep(time) {
    return {type: 'wait', till: () => true, timeout: time}
  },

  type(text) {
    window.__paste(text)
    return {type: 'wait', till: () => true, timeout: text.length * 110}
  },

  waitElement(selector, message, timeout = 10000) {
    return {type: 'wait', till: () => $(selector).length === 0, timeout, message}
  }
};

export const state = () => {
  const params = FlowRouter.current().params;
  return {
    page: params.step
  }
};

export const go = (path) => {
  log(path, 'GO')
  const func = TESTS[parsePath(path)];
  if (!func) return error(path, 'Test does not exists');

  PATH_STACK.push(path);
  let result = call(func);
  if (isGenerator(result)) {
    GENERATOR_STACK.push(result);
    result = call.call(result, result.next);
    if (result.type === 'error') return error(path, 'Fail', result.error);
    if (result.done) GENERATOR_STACK.pop();
    result = result.value;
  }
  if (_.isUndefined(result)) result = {type: 'done'}
  processResult(path, result)
}

export const clear = () => {
  PATH_STACK.splice(0, PATH_STACK.length);
  GENERATOR_STACK.splice(0, GENERATOR_STACK.length);
}

export const register = (tests, path = []) => {
  _.each(tests, (testsSecond, k) => {
    const localPath = [...path, k]
    if (_.isFunction(testsSecond)) {
      TESTS[localPath.join('.')] = testsSecond;
      return;
    }
    if (_.isObject(testsSecond)) {
      register(testsSecond, localPath)
    }
  })
};

function processResult(path, result) {
  if (!_.isObject(result)) return error(path, 'Test should use "actions only"');

  if (result.type === 'error') return error(path, 'Fail', result.error);

  if (result.type === 'go') return go(result.path);

  if (result.type === 'wait') return setTimeout(() => {
    const startTime = Date.now();
    const timeout = result.timeout || 999999;
    waitTill(() => result.till() && Date.now() < startTime + timeout, () => {
      if (Date.now() > startTime + timeout && result.message) return error(path, result.message);
      next(path)
    })
  }, 20);

  if (result.type === 'done') {
    PATH_STACK.pop();
    return next(PATH_STACK[PATH_STACK.length - 1]);
  }
}

function next(path) {
  const lastGenerator = GENERATOR_STACK[GENERATOR_STACK.length - 1];
  if (!lastGenerator) return log(path, 'EVERYTHING GOOD')
  const result = call.call(lastGenerator, lastGenerator.next);
  if (result.type === 'error') return error(path, 'Fail', result.error);
  if (result.done) {
    GENERATOR_STACK.pop();
    processResult(path, {type: 'done'});
  } else {
    processResult(path, result.value);
  }
}

function waitTill(predicate, callback) {
  if (!predicate()) {
    return callback();
  }
  setTimeout(() => waitTill(predicate, callback), 10);
}

function isGenerator(obj) {
  return typeof obj === 'object' && obj.next && !obj.hasOwnProperty('next')
}

function parsePath(path) {
  return _.compact(path.split(/[^\w\d]/)).join('.')
}

function call(func, ...args) {
  try {
    if (typeof func === 'function') {
      return func.apply(this, args);
    }
  } catch (err) {
    return {type: 'error', error: err}
  }
}

function error(message, ...args) {
  clear();
  console.error('TEST ERROR:', message, ...args);
}

function log(...args) {
  console.log('TESTING:', ...args);
}