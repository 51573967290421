import {createContainer} from 'imports/lib/react_createContainer';
import ListSlider from './ListSlider';
import getPageManager from 'imports/lib/page_manager';
import {capitalize, countInDomAmounts} from 'imports/lib/helpers';
import IW from '../../../iw_api';

export default createContainer(function () {
  const ROWS_COUNT = countInDomAmounts();
  const ITEM_COUNT = 3;

  const paymentLocations = Session.get('money_transferring_payment_locations');
  const paymentMode = IW.appGetVal('recipient_payment_mode_type');
  const country = IW.appGetVal('recipient_address_country');

  let paymentModeName = null;

  if (paymentMode === 'delivery') {
    paymentModeName = 'delivery';
  }

  if (paymentMode === 'cash_pickup') {
    paymentModeName = 'cash';
  }

  if (paymentMode === 'bank_deposit') {
    paymentModeName = 'deposit';
  }
  // agent_loc_agent_id: l.AGE_AGENT_ID,
  //   agent_loc_city_id: l.AGE_CITY_ID,
  //   agent_loc_phone: l.AGE_PHONE,
  //   agent_loc_address: l.AGE_ADDRESS,
  //   agent_loc_name: l.AGE_NAME

  const items = _.map(paymentLocations, function (item) {
    return {
      _id: item.agent_loc_agent_id,
      // required_sender_id_type: item.Id,
      product_name: capitalize(item.agent_loc_name),
      enabled: true,
      app_guid: 'app_moneytransfer_sigue',
      icon: '/resources/icons/payout/' +
        paymentModeName + '/' +
        country.toLowerCase().replace(/\s/g, '_') + '/' +
        (item.agent_loc_name || '').toLowerCase().replace(/\s/g, '') + '.png',
      iconDefault: '/resources/icons/payout/' + paymentModeName + '/default.png',
      name: capitalize(item.agent_loc_name),

      ...item
    }
  });

  const choices = items.chunk(ROWS_COUNT * ITEM_COUNT).map((item) => item.complete(ROWS_COUNT * ITEM_COUNT, {}).chunk(ITEM_COUNT));

  return {
    choices,
    title: 'Select payer location:',
    onBack: () => getPageManager().back(),
  }
}, ListSlider);