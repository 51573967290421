import app_common from './app_common';
import IW from 'imports/iw_api';

// ["list", "number", "notice", "amount", "pay", "loading", "final", "email", "sent"]
export default _.extend({}, app_common, {
  list: {},

  on_login: function () {
    const phoneNumber = IW.appGetVal('phone_number');

    if (!phoneNumber) {
      const customerData = IW.loginGetVal('customer_data_result');
      const customerPhoneNumber = customerData.mobile_number;

      IW.appSetVal('sender_phone_number', customerPhoneNumber);
      IW.appSetVal('phone_number', customerPhoneNumber);
      IW.appSetVal('number', customerPhoneNumber)
    }

    return "amount_again"
  }
});
