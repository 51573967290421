import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import BillerIcon from './BillerIcon';
import IW from 'imports/iw_api';
import getPageManager, {goTo} from 'imports/lib/page_manager';
import appWorkflow from 'imports/apps_workflow'

export default createContainer((params) => {

  function onTapIcon(event) {
    const icon = params.icon;

    // Ignore mousedown for slider items
    if ((event.type === 'mousedown') && $('.iosSlider').data('iosslider')) return;

    IW.appSetVal('biller_name', icon.name);
    Session.set('icon', icon.icon);
    Session.set('title', icon.title);
    IW.appSetVal('name', icon.name);
    IW.appSetVal('icon', icon.icon);

    getPageManager().next();

    return event.preventDefault();
  }

  return {
    icon: params.icon,
    onTapIcon
  };

}, BillerIcon);
