import React from 'react';
import {Session} from 'core/session';

export default class Alert extends React.Component {
  render() {
    let {headerText, messageText, buttonText, onClick, isAlertVisible} = this.props;

    if (!isAlertVisible) {
      return null
    }
    if (!messageText) {
      return null
    }

    return (
      <div id="alert_wrapper">
        <div id="alert">
          { headerText ? <h3>{headerText}</h3> : null }
          <p>{messageText}</p>
          <button className="upper" onMouseDown={onClick}>{buttonText}</button>
        </div>
      </div>
    );
  }
}

Alert.propTypes = {
  headerText: React.PropTypes.string,
  messageText: React.PropTypes.string,
  buttonText: React.PropTypes.string,
  isAlertVisible: React.PropTypes.bool,
  onClick: React.PropTypes.func
};

Alert.defaultProps = {
  headerText: '',
  messageText: '',
  buttonText: 'OK',
  isAlertVisible: false,
  onClick: () => {}
};
