import IW from 'imports/iw_api';

export default function (pageBack, pageNext, onConfirmPage, onLoginBackPage, getPaymentModes = () => {}) {
  return function*() {
    function back(){
      if (!IW.appGetVal('logged')) return pageBack;
      if (IW.appGetVal('new_recipient') || IW.appGetVal('is_payer_edit')) return onLoginBackPage;
      return onConfirmPage;
    }

    const isLogged = IW.appGetVal('logged') &&
      IW.appGetVal('recipient_city_id') &&
      IW.appGetVal('recipient_country_iso') &&
      !IW.appGetVal('new_recipient');

    IW.appSetVal('payment_modes', null);
    IW.appSetVal('payment_modes_init', false);
    Session.set('money_transferring_payers', null);

    //Get payment modes if user logged and selected recipient
    if (isLogged) {
      IW.call('getPaymentModes');
    } else {
      IW.call('getAvailablePaymentModes');
    }

    yield 'loading app payment_modes_init';

    if (!IW.appGetVal('payment_modes').length) {
      IW.appSetLocalVal('transaction_error_messages', [`Payout Methods not found for ${IW.appGetVal('recipient_address_country')}`]);
      return 'transaction_error_moneytransfer';
    }

    return {
      back,
      next: function*(){

        if(isLogged){
          Session.set('money_transferring_payers', null);
          IW.callWithUpdate('getPayers', 'money_transferring_payers');
          yield 'loading session money_transferring_payers';
          if (_.isEmpty(Session.get('money_transferring_payers'))) {
            const newPaymentModes = _.filter(IW.appGetVal('payment_modes'),
              (pm) => pm.id !== IW.appGetVal('recipient_payment_mode_id'));

            IW.appSetVal('payment_modes', newPaymentModes);
            if (_.isEmpty(newPaymentModes)) return back();
            return 'none';
          }
        }

        return pageNext
      }
    }
  }
}
