/**
 * Created by vitaliy on 14.03.17.
 */
import {createContainer} from 'imports/lib/react_createContainer';
import PickerIOSlider from './PickerIOSlider';
import React from 'react';

let lastUpdatedSlider = '';
let lastTimeout = 0

const PickerIOSliderContainer = createContainer((params) => {

  const onValueChange = params.onValueChange || (() => {})

  function activeSlide(jqObj) {
    $(jqObj).parent().find('.slide').removeClass('active');
    $(jqObj).addClass('active');
  }

  return {
    items : params.items || [], //data
    optionsSlider : {
      startAtSlide : params.selected + 1, // =
      infiniteSlider : true,
      snapToChildren : true,
      snapSlideCenter : true,
      elasticPullResistance : 1,
      onSliderLoaded : function (args) {
        activeSlide(args.currentSlideObject[0]);
      },
      onSlideChange : function (args) {
        lastUpdatedSlider = params.sliderId;
        clearTimeout(lastTimeout);
        lastTimeout = setTimeout(() => (lastUpdatedSlider = ''), 1000);

        activeSlide(args.currentSlideObject[0]);
        onValueChange({
          text: args.currentSlideObject.children('span').text(),
          value: args.currentSlideObject.attr('data-value')
        })
      },
      onSlideComplete : function (args) {
        // params.disabledSession ? '' : params.onCheckedValue(params.uniq, args.currentSlideNumber - 1);
      },
      navPrevSelector: `.${params.sliderId} .slide-button.slide-button__up`,
      navNextSelector: `.${params.sliderId} .slide-button.slide-button__down`
    },
    sliderId : params.sliderId, // type
    freezeUpdate: lastUpdatedSlider === params.sliderId
 //   handleSlide
  };

}, PickerIOSlider);

PickerIOSliderContainer.propTypes = {
  items : React.PropTypes.shape({
    text: React.PropTypes.string,
    value: React.PropTypes.number,
  }),
  selected : React.PropTypes.number,
  onValueChange: React.PropTypes.func,
  sliderId : React.PropTypes.string,
};

export default PickerIOSliderContainer;