import React, {Component} from 'react';
import CategoryItemContainer from 'imports/ui/shared/category_item/CategoryItemContainer';
import QuickLaunch from 'imports/ui/shared/quick_launch/QuickLaunchContainer';
import Loading from 'imports/ui/shared/loading/Loading';
import SliderContainer from 'imports/ui/shared/slider/SliderContainer';
import { SLIDE_ITEM_TYPES } from 'imports/lib/constants';
import safeRender from 'imports/ui/decorators/safeRender';

class MainMenu extends Component {
  render() {
    const {apps} = this.props;

    if (!apps) return <Loading/>;

    return (
      <tr className="apps">
        <td>
          <SliderContainer type={SLIDE_ITEM_TYPES.ICON} gaCategory="Category" apps={apps}/>
        </td>
      </tr>
    )
  }
}

MainMenu.propTypes = {
  apps: React.PropTypes.arrayOf(React.PropTypes.array)
};

export default safeRender(MainMenu);
