import {createContainer} from 'imports/lib/react_createContainer';
import IW from 'imports/iw_api';
import getPageManager from 'imports/lib/page_manager';
import Maya5 from './Maya5'


export default createContainer(() => {

  const maya5step  = IW.appGetVal('customer_maya5_step');

  return {
    maya5step
  }
}, Maya5)

