import {Session} from 'core/session';
import {FlowRouter} from 'core/Router';
import IW from '../iw_api/index';
import CONFIG from 'imports/config';
import {ATM_PAGES_OBJ} from 'imports/apps_workflow';
import {ATM_ACCOUNTS, INTERNAL_DATA_ERRORS, PAGINATOR_SLIDER_MODE} from 'imports/lib/constants';
import * as u from 'underscore';
import createSessionMethods from 'imports/lib/createSessionMethods';
import {sprintf} from 'imports/lib/external/sprintf';

import FEEDS from '../collections/feeds';
import moment from "./external/moment-with-langs";

window.ALL_TRANSLATIONS = {}; // FIXME
setInterval(function () {
  u.each(window.ALL_TRANSLATIONS, (o, k) => {
    if (Date.now() - o.time > 10 * 60 * 1000) {
      delete window.ALL_TRANSLATIONS[k];
    }
  })
}, 10000);

// {{_ 'Search'}}
export function _(string, app_guid, type) {
  if (typeof app_guid !== 'string')
    app_guid = '';
  const translation = IW._(string, app_guid, type);
  window.ALL_TRANSLATIONS[translation] = {translation, original: string, time: Date.now(), type, app_guid}; // FIXME
  return IW._(string, app_guid, type);
}

export function i18n(parts, ...args){
  if (u.isString(parts)) return _(parts);
  return sprintf(_(parts.join('%s')), ...args)
}

window.i18n = i18n
window.sprintf = sprintf

// {{#if eq theme 'paper'}}...{{/if}}
export function eq(a, b, c) {
  return (a == b) ? ((c) ? c : true) : false
}

// {{session theme}} // ??? {{#if eq session ''}}
export function sess(key) {
  return Session.get(key)
}

// {{}}
export function appVal(key) {
  return IW.appGetVal(key)
}

// {{session theme}} // ??? {{#if eq session ''}}
export function QR(string, size) {
  if (string == '') return ''

  size = size || 256

  return $.fn.qrcode({
    text: string,
    width: size,
    height: size
  })
}

// 5555555555 => +1 555 555 5555
export function format_phone_us(number, prefix) {
  return ("" + number).replace(/\D/g, '')
    .replace(/^1/, '') // strip the leading "1" just in case
    .replace(/(\d{3})?(\d{3})?(\d{4})?/, '+1 $1 $2 $3')
    .replace(/\s+/g, ' ') // this has to be done to avoid double/triple spaces
}

// USD price as $9.00 || $1,098.01 || $0.30
export function usd(val) {
  return currency(val, '$')
}

/*
 // Dimming keyboard keys
 UI.registerHelper('on', function(key){
 if( key == '' || key == ' ' ) return; // ignoring the gaps

 var focus = Session.get('focus');

 if( !focus || !$('input#'+focus).length ) return;

 var val = $('input#'+focus).val();

 switch( key ) {
 case 'space':
 if( val == '' || val.slice(-1) == ' ' ) return 'off'
 break;
 }
 })
 */
export function dump(...args) {
  // console.log(...args);
}

export function count(input) {
  if (!input) return 0; // undefined/null/""

  if (input.length) // array or a string
    return input.length;
  // it's an Object
  return Object.keys(input).length;
}

export function countMinus1(input) {
  if (!input) return 0; // undefined/null/""

  var counter = 0;

  if (input.length) // array or a string
    counter = input.length;
  else // it's an Object
    counter = Object.keys(input).length;

  if (counter > 0) --counter;

  return counter;
}

// {{x_data 'number'}}
export function x_data(key) {
  return (Session.get('app')) ? Session.get('app')[key] : '';
}

export function _feed(feed_name) {
  return (FEEDS.findOne(feed_name)) ? FEEDS.findOne(feed_name).ask : 0;
}

export function steps(appName, currentStep) {
  if (!CONFIG.CHAIN.hasOwnProperty(appName)) {
    return {
      prevStep: null,
      nextStep: null
    }
  }
  const chain = CONFIG.CHAIN[appName].steps;
  return {
    prevStep: chain.indexOf(currentStep) != 0 ? chain[chain.indexOf(currentStep) - 1] : null,
    nextStep: (chain.indexOf(currentStep) + 1) != chain.length ? chain[chain.indexOf(currentStep) + 1] : null
  };
}

// ===== Slider =====
// WARN! Slider must to know h and w container, in else - h or w can be 0px (if in CSS set % or auto)
export function initSlider(parentSelector, params, sliderType, sliderLoaded = () => {
}) {
  const $el = $('.iosSlider', parentSelector);
  const currentSettings = $el.data('iosslider');

  if (currentSettings && currentSettings.settings && !currentSettings.settings.infiniteSlider) {
    currentSettings.settings.infiniteSlider = true;
  }

  $('.slideSelectors .item', parentSelector).removeClass('selected');
  //$el.iosSlider("update");
  $el.iosSlider('destroy');

  // do not initiate iosSlider for single-page cats
  if ($('.slider', parentSelector).children().length < 2) {
    $el.iosSlider('lock');
    return;
  }

  const slideChange = sliderType && sliderType === PAGINATOR_SLIDER_MODE.FOUR_DOTS ?
    slideChange4Dots.bind(null, parentSelector) :
    slideChangeManyDots.bind(null, parentSelector);
  const settings = {
    snapToChildren: true,
    desktopClickDrag: true,
    infiniteSlider: true,
    navSlideSelector: parentSelector + ' .slideSelectors .item',
    keyboardControls: true,
    onSlideChange: slideChange,
    onSliderLoaded: sliderLoaded,
    autoSlideHoverPause: false,
    slideStartVelocityThreshold: 1,
    snapVelocityThreshold: 1,
    elasticPullResistance: 0.1,
    navPrevSelector: parentSelector + ' .slideSelectors .prev',
    navNextSelector: parentSelector + ' .slideSelectors .next',
    ...CONFIG.SLIDER
  };

  $('.slideSelectors .item:first', parentSelector).addClass('selected');
  $el.iosSlider('goToSlide', 1);

  return $el.iosSlider({...settings, ...params});
}

/* Example : have 4 dots - 4 pages, have 6 dots - 6 pages.
 * P.S. - dots may be clickable*/
function slideChangeManyDots(parentSelector, args) {
  let n = args.currentSlideNumber - 1;

  $('.slideSelectors .item', parentSelector).removeClass('selected');
  $('.slideSelectors .item:eq(' + n + ')', parentSelector).addClass('selected');

  return false;
}

/* Example : have 4 dots, 6 pages:
 * 1 dot - 1-st page,
 * 2 dot - 2-nd and 3-rd pages,
 * 3 dot - 4-th,5-th pages,
 * 4 dot - 6-th page.
 * P.S. - dots not(!) clickable*/
function slideChange4Dots(parentSelector, args) {
  const MAX_DOTS = 4;
  const numberOfSlides = args.data.numberOfSlides;
  const currentSlideNumber = args.currentSlideNumber;

  let n = args.currentSlideNumber - 1;

  if (numberOfSlides > MAX_DOTS && (n > 1)) {
    if (currentSlideNumber === numberOfSlides) {
      n = 3;
    } else if (currentSlideNumber <= numberOfSlides / 2) {
      n = 1;
    } else {
      n = 2;
    }
  }

  $('.slideSelectors .item', parentSelector).removeClass('selected');
  $('.slideSelectors .item:eq(' + n + ')', parentSelector).addClass('selected');

  return false;
}

// ===== Slider End =====

export function initVerticalSlider(props, classSelector, minEls) {
  const selector = (classSelector ? '.' + classSelector : '') + ' .iosVerticalSlider';
  const minSliders = minEls || 4;
  // console.log('helpers | initVerticalSlider | props : ', props);

  const $el = $(selector);

  $el.iosSliderVertical("destroy");

  // do not initiate iosSlider for single-page cats
  if ($(selector + ' .slider').children().length < minSliders) {
    $el.iosSliderVertical('lock');
    return;
  }

  const settings = Object.assign({
    snapToChildren: true,
    desktopClickDrag: true,
    keyboardControls: true,
    onSliderLoaded: function () {
    },
    onSliderUpdate: function () {
    },
    onSliderResize: function () {
    },
    onSlideStart: function () {
    },
    onSlideChange: function () {
    },
    onSlideComplete: function () {
    }
  }, CONFIG.SLIDER, props || {});

  $el.iosSliderVertical(settings);

  return $el
}

export function makeVerticalSlide(num, classSelector) {
  const selector = (classSelector ? '.' + classSelector : '') + ' .iosVerticalSlider';
  $(selector).iosSliderVertical('goToSlide', num);
}

export function getUser() {
  const loginSession = Session.get('login') || {};
  const customerData = loginSession.customer_data_result || u.extend({}, Session.get('user'));

  return u.extend({}, customerData);
}

export function setTime() {
  const locale = Session.get('locale');
  moment.lang(locale);

  Session.set("time", moment().format(CONFIG.CLOCK_FORMAT));
  Session.set("time_full", moment().format(CONFIG.CLOCK_FULL_FORMAT));
}

export const getPayout = u.memoize(function (payout, nominals, counts, limited) {
  function itemValue(item) {
    lastSum = 0;
    lastCount = 0;
    for (let i = 0; i < item.length; i++) {
      if (item[i] > counts[i]) {
        return 1;
      }
      lastSum += nominals[i] * item[i];
      lastCount += limited[i] * item[i];
      if (lastSum > payout) {
        return 1;
      }
    }
    return lastSum < payout ? -1 : 0;
  }

  function createItem(source) {
    const item = new Array(counts.length);
    for (let i = 0; i < item.length; i++) {
      item[i] = source[i] || 0;
    }
    return item;
  }

  let lastSum = 0;
  const stack = [createItem([])];
  let maxSum = 0;
  let maxItem = null;
  let lastCount = 0;


  for (let i = 0; i < nominals.length; i++) {
    lastSum += nominals[i] * counts[i]
  }
  if (lastSum <= payout) {
    return {payout: counts, sum: lastSum}
  }
  lastSum = 0;

  let maxIterations = 10000;

  // stack.length < 200 it is enough
  while (stack.length > 0 && maxIterations-- > 0) {
    const item = stack.pop();
    const value = itemValue(item);
    if (lastCount > 40) continue;
    if (value < 0) {
      for (let n = 0; n < nominals.length; n++) {
        const newItem = createItem(item);
        newItem[n]++;
        stack.push(newItem);
      }
      if (lastSum > maxSum) {
        maxSum = lastSum;
        maxItem = item;
      }
    }
    if (value === 0) return {payout: item, sum: lastSum};
  }
  return {payout: maxItem, sum: maxSum};
}, function (payout, nominals, counts) {
  return S(payout) + nominals.join('') + counts.join('')
});

export function predictNext(currentValue, maxValue, nominals, counts) {
  const possibleValues = [];
  for (let d = 0; d < 5; d++) {
    for (let i = 0; i <= 9; i++) {
      const possibleNext = i + (new Array(d + 1)).join('0');
      const possibleValue = currentValue + possibleNext;
      if (possibleValue > maxValue) break;
      if (possibleValue <= 0) continue;
      possibleValues.push({value: +possibleValue, key: possibleNext[0]});
    }
  }

  const result = [];
  const counters = u.sortBy(u.map(nominals, (n, i) => ({nominal: n, count: counts[i], limited: 0})), (o) => o.nominal);
  for (let k = 0; k < possibleValues.length; k++) {
    if (result.indexOf(possibleValues[k].key) > -1) continue;
    const calculation = getPayout(possibleValues[k].value,
      u.pluck(counters, 'nominal'),
      u.pluck(counters, 'count'),
      u.pluck(counters, 'limited'));
    if (calculation.sum === possibleValues[k].value) result.push(possibleValues[k].key);
  }

  return result;
}

export function hasVariant(value, nominals, counts, limited) {
  limited = limited || u.map(nominals, () => 0);
  const counters = u.sortBy(u.map(nominals, (n, i) => ({
    nominal: n,
    count: counts[i],
    limited: limited[i]
  })), (o) => o.nominal);
  const res = getPayout(value, u.pluck(counters, 'nominal'), u.pluck(counters, 'count'), u.pluck(counters, 'limited'));
  return res.sum === value;
}

export function validateText(text, rulesObj, prefix) {
  function getCountry() {
    return prefix ?
      (IW.appGetVal(`${prefix}_country_iso`) || IW.appGetVal(`${prefix}_address_country`)) :
      (IW.appGetVal(`country_iso`) || IW.appGetVal(`address_country`));
  }

  if (typeof rulesObj === 'function') {
    return rulesObj(text, prefix);
  }

  if (!rulesObj.required && !text) {
    return true;
  }

  if (rulesObj.required && !text) {
    return false;
  }

  if (rulesObj.minLength && text && text.length < rulesObj.minLength) {
    if (!rulesObj.required && !text.length) return true;
    return false;
  }

  if (rulesObj.maxLength && text && text.length > rulesObj.maxLength) {
    return false;
  }

  if (rulesObj.pattern && !rulesObj.pattern.test(text)) {
    return false;
  }

  if (rulesObj.isEmail) {
    return IW.validEmail(text);
  }

  if (rulesObj.exists) {
    switch (rulesObj.exists) {
      case 'cities':
        Session.set('city_exists', true);
        return Session.get('city_exists');
      case 'states':
        IW.callCacheable('checkState', {
          input: text,
          country_iso: getCountry()
        }, (data) => Session.set('state_exists', data));
        return Session.get('state_exists');
      case 'biller':
        const billerName = IW.appGetVal('biller_name');
        const billerId = IW.appGetVal('biller_id');
        return billerName === text && billerId;
      case 'bill_scanning_enabled':
        return CONFIG.BILL_SCANNING_ENABLED;
      case 'location':
        return Session.get(`${prefix}_location_selected`) === IW.appGetVal(`${prefix}_location`);
      case 'currency':
        return IW.appGetVal('recipient_currency') === IW.appGetVal('recipient_currency_selected');
      case 'payment_reason_select':
        return IW.appGetVal('bank_required_payment_reason_select') === IW.appGetVal('bank_payment_reason_selected');
      case 'occupation_type_select':
        return IW.appGetVal('compliance_occupation_type_select') === IW.appGetVal('occupation_type_selected');
      case 'relationship_to_beneficiary_select':
        return IW.appGetVal('compliance_relationship_to_beneficiary_select') === IW.appGetVal('relationship_to_beneficiary_selected');
      case 'sender_birth_country_select':
      case 'receiver_birth_country_select':
      case 'id_issuer_country':
        IW.callCacheable('getCountries', null, (data) => Session.set('countries_list', data));
        return !!u.find(Session.get('countries_list'), (c) => c.value === text);
      case 'sender_nationality_select':
        return IW.appGetVal('compliance_sender_nationality_select') === IW.appGetVal('sender_nationality_selected');
      case 'receiver_nationality_select':
        return IW.appGetVal('compliance_receiver_nationality_select') === IW.appGetVal('receiver_nationality_selected');
      case 'sender_money_origin_select':
        return IW.appGetVal('compliance_sender_money_origin_select') === IW.appGetVal('sender_money_origin_selected');
      case 'bank_search':
        return IW.appGetVal('bank_required_bank_search') === IW.appGetVal('bank_bank_search_selected');
      case 'bank_account_type':
        return IW.appGetVal('bank_required_bank_account_type') === IW.appGetVal('bank_account_type_selected');
      case 'id_type':
        IW.callCacheable('getIdTypes', {country_id: IW.appGetVal('id_issuer_country_id')},
          (data) => Session.set('id_type_list', data));
        return !!u.find(Session.get('id_type_list'), (c) => c.value === text);
      default:
        return false;
    }
  }

  return true;
}

export function capitalize(text) {
  return string(text).toLowerCase().replace(/\b./g, a => a.toUpperCase());
}

export function capitalizeSentence(text) {
  return string(text).toLowerCase().replace(/^.|\.\s+./gm, a => a.toUpperCase());
}

export function getName(text) {
  const names = u.compact(string(text).split(/[^A-Za-z]/g));
  if (names.length === 0) return '';
  if (names.length === 1 || (names.length > 1 && names[1].length === 0)) return capitalize(names[0]);
  return capitalize(names[1]) + ' ' + capitalize(names[0][0]) + '.';
}

function pluck(array, key) {
  return array.map(o => o[key]);
}

export function parseUrl(url) {
  const parser = document.createElement('a');
  parser.href = url;
  return {
    origin: parser.origin,
    pathname: parser.pathname,
  }
}

export function triggerMouseEvent(domNode, eventType) {
  if (!domNode || !eventType) return;
  const clickEvent = window.document.createEvent('MouseEvents');
  clickEvent.initEvent(eventType, true, true);
  domNode.dispatchEvent(clickEvent);
}

export function createLazyExecution(callback, timeout) {
  let timer = -1;
  return function (...params) {
    clearTimeout(timer);
    timer = setTimeout(function () {
      callback(...params)
    }, timeout);
  }
}

export function currency(val, symbol) {
  const v = number(val);
  const p = Math.abs(v).toFixed(2).split('.');
  return string(v < 0 && '-') + string(symbol) + p[0].split("").reverse().reduce(function (acc, num, i) {
    return num + (i && !(i % 3) ? "," : "") + acc;
  }, "") + "." + p[1];
}

export function number(val) {
  return Math.round((+val || 0) * 100000000) / 100000000;
}

export function string(val) {
  return String(val || '');
}

export const C = currency
export const S = string
export const N = number

export function convertObjectToArray(obj) {

  var keys = Object.keys(obj);
  if (keys.length === 0) return [];

  var array = [];
  for (var i = 0; i < keys.length; i++) {
    array.push({
      key: keys[i],
      value: obj[keys[i]]
    })
  }
  return array;
}

export function convertKeyToClearString(key) {
  key = key.replace(/_/g, " ");
  return capitalize(key);
}

export function getValueFromSession(field, inObject, sessionScope) {

  let get = IW.appGetVal;
  if (sessionScope) {
    const SM = createSessionMethods(sessionScope);
    get = SM.getVal;
  }

  if (inObject) {
    let obj = get(inObject);
    return obj[field] || "";
  }
  return get(field);
}

export function setValueToSession(field, value, inObject, sessionScope) {

  let set = IW.appSetVal;
  let setObject = IW.appSetObjectVal;

  if (sessionScope) {
    const SM = createSessionMethods(sessionScope);
    set = SM.setVal;
    setObject = SM.setObjectVal;
  }

  if (inObject) {
    let obj = {};
    obj[field] = value;
    setObject(inObject, obj);
  } else {
    set(field, value);
  }
}

export function getDecimals(input) {
  let result = [];
  if (Array.isArray(input)) {
    for (let i = 0; i < input.length; i++) {
      result.push(currency(input[i]).split(".")[1]);
    }
  }
  return result;
}

export function getATMAccount(id, field) {
  for (let i = 0; i < ATM_ACCOUNTS.length; i++) {
    if (ATM_ACCOUNTS[i].id === id) {
      return field ? ATM_ACCOUNTS[i][field] : ATM_ACCOUNTS[i];
    }
  }
}

export function createInternalDataError(e, data = []) {
  var errorMessage = S(INTERNAL_DATA_ERRORS[e]);

  u.each(data, (v, i) => {
    errorMessage = errorMessage.replace(`$${i + 1}`, v)
  });

  return errorMessage;
}

export function removeDot(obj) {
  const result = {};
  for (const prop in obj) {
    let processed = obj[prop];
    if (processed && typeof processed === 'object' && isNaN(processed.length)) {
      processed = removeDot(processed);
    }
    // Need to replace when property is used
    result[prop.replace(/\./g, '@')] = processed
  }
  return result;
}

export function getRandomString(length, regexp) {
  function getRandomSymbol() {
    let s = '';
    do {
      s = String.fromCharCode(Math.round(Math.random() * (122 - 48)) + 48);
    } while (!regexp.test(s));
    return s;
  }

  regexp = regexp || /\w/;
  var res = '';
  for (var i = 0; i < length; i++) {
    res += getRandomSymbol();
  }
  return res;
}

export function createQueue() {
  function run() {
    let runCalled = false;
    const callRun = () => {
      if (runCalled) return;
      runCalled = true;
      clearTimeout(timeout);
      run();
    };
    const pair = queue.shift();
    if (!pair) return (done = true);
    pair.args.push(callRun);
    const timeout = setTimeout(callRun, 20000);
    try {
      pair.executor(...pair.args);
    } catch (err) {
      console.error('Queueable function has error', err);
      setImmediate(callRun);
    }
  }

  const queue = [];
  let done = true;

  return function (func) {
    return function (...args) {
      queue.push({
        args: args,
        executor: func
      });
      if (done) {
        done = false;
        setImmediate(run);
      }
    };
  };
}

export function setImmediate(callback) {
  new Promise(r => r()).then(callback);
}

export function countInDom(offset, itemSize) {
  const scaleWidth = window.innerWidth / 1280;
  const scaleHeight = window.innerHeight / 1024;
  const globalScale = Math.min(scaleWidth, scaleHeight);
  const newHeight = N(window.innerHeight / globalScale);
  return Math.floor(N(newHeight - offset) / itemSize);
}

export function countInDomAmounts() {
  return countInDom(300, 330);
}

export function countInDomMenu() {
  return countInDom(300, 280);
}

export function countInDomBillSearch() {
  return countInDom(680, 310);
}

export function countInDomEditSlide() {
  return countInDom(673, 117);
}

export function countInDomSearch() {
  return countInDom(841, 60);
}

export function countInDomEditScrollListSlide() {
  return countInDom(145+100+60+153, 60);
}

export function countInDomBillScanningFavorites() {
  return countInDom(153+145+390, 240);
}

export function countInDomBillScanningFavoritesFull() {
  return countInDom(153+145, 240);
}

export function countInDomRecipientChoice() {
  return countInDom(470, 330); // .iosSlider calc(var(--main-height) - 470px);
}
