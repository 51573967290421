import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import ChangeProduct from './ChangeProduct';
import getPageManager from 'imports/lib/page_manager';
import Products from 'imports/collections/products';
import IW from 'imports/iw_api';
import {_ as i18n, capitalize} from 'imports/lib/helpers';
import {sprintf} from 'imports/lib/external/sprintf';

export default createContainer(() => {
  const map = IW.appGetVal('change_product_map');
  const productName = Session.get('title').split(' ')[0];

  function getMessages(type) {
    const product = Products.findOne({product_id: map[type]});
    if (!product) return null;

    if (type === 'plan') {
      return {
        type: type,
        message: i18n('If you get a monthly statement by mail or email, please select "Plan" payment option:'),
        button: sprintf(i18n('%s Plan'), productName),
        icon: product.icon,
      }
    }
    if (type === 'prepaid') {
      return {
        type: type,
        message: i18n('If you are on a Prepaid plan, receive no statements, please select "Prepaid" payment option:'),
        button: sprintf(i18n('%s Prepaid'), productName),
        icon: product.icon,
      }
    }

    const namesMap = {
      'Telcel': 'Amigo Telcel',
      'Telcel Amigo Internet': 'Internet Amigo',
      'Telcel Amigo Sin Limite': 'Amigo Sin Límite',
      'Playstation': 'PlayStation Plus',
      'Mexico Playstation': 'PlayStation Store',
    }

    const mappedName = namesMap[capitalize(product.name)] ?
      namesMap[capitalize(product.name)] :
      capitalize(product.name);

    if (type === 'simple') {
      return {
        type: type,
        message: sprintf(i18n('If you need to recharge %s, select this option:'), mappedName),
        button: capitalize(product.name),
        icon: product.icon,
      }
    }
    if (type === 'amigo') {
      return {
        type: type,
        message: sprintf(i18n('If you need to recharge one of %s packages, select this option:'), mappedName),
        button: capitalize(product.name),
        icon: product.icon,
      }
    }
    if (type === 'internet') {
      return {
        type: type,
        message: sprintf(i18n('If you need to pay for one of %s packages, select this option:'), mappedName),
        button: capitalize(product.name),
        icon: product.icon,
      }
    }
    if (type === 'online') {
      return {
        type: type,
        message: sprintf(i18n('If you need to buy online at the %s select this product:'), mappedName),
        button: mappedName,
        icon: product.icon,
      }
    }
    if (type === 'membership') {
      return {
        type: type,
        message: sprintf(i18n('If you need to buy a %s membership select this product:'), mappedName),
        button: mappedName,
        icon: product.icon,
      }
    }
  }

  const variants = _.compact(_.map(_.keys(map), (t) => getMessages(t))).slice(0, 3);

  return {
    variants: variants,
    onNext: (i) => {
      IW.appSetVal('change_product_selected', variants[i].type);
      getPageManager().next()
    },
    onBack: () => getPageManager().back()
  };
}, ChangeProduct);
