import React from 'react';

export default class Maya5 extends React.Component {

  render() {
    const {maya5step, onMaya5} = this.props;

    const steps = {
      "0": {title: "Every 5th transaction is FREE!", backgroundPositionY: 0},
      "1": {title: "Every 5th transaction is FREE!", backgroundPositionY: -79},
      "2": {title: "Every 5th transaction is FREE!", backgroundPositionY: -156},
      "3": {title: "Every 5th transaction is FREE!", backgroundPositionY: -233},
      "4": {title: "This transaction is FREE!", backgroundPositionY: -311}
    };

    const currentStep = steps[maya5step];

    return (
      <div id="maya5-container" onMouseDown={onMaya5}>
        <div style={{backgroundPositionY: currentStep.backgroundPositionY}} id="maya5-counter"/>
        <p _>{currentStep.title}</p>
      </div>
    )

  }
}

Maya5.propTypes = {
  maya5step: React.PropTypes.number
};

Maya5.defaultProps = {
  maya5step: 0
};
