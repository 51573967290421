import React, {Component} from 'react';
import Keyboard from 'imports/ui/shared/keyboard/Keyboard';
import Clear from 'imports/ui/shared/clear/Clear';
import InputRTLDirection from 'imports/ui/shared/input_rtl_direction/InputRTLDirection';
import SliderContainer from 'imports/ui/shared/slider/SliderContainer';
import {KEYBOARD_NUMBER_3ROW, KEYBOARD_TEXT, SLIDE_ITEM_TYPES} from 'imports/lib/constants';
import Button from 'imports/ui/shared/button/Button';
import Image from 'imports/ui/shared/image/Image';

export default class BillerSearch extends Component {
  renderInput() {
    const {value, label, onClearInput} = this.props;

    return <table className={`enter-user-data__input-container`}>
      <tbody>
      <tr>
        <td>
          <div className="enter-user-data__input-describe">
            <span _>{label + ' of biller'}</span>
          </div>
        </td>
        <td>
          <div className="bordered-input">
            <InputRTLDirection id="data" className="focus" value={value}
                               focus={"data"}
                               clearInput={onClearInput}/>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  }

  getTextNumberKeyboard() {
    const {value, doSearch} = this.props;

    return (<tr className="enter-user-data__keyboard-container">
      <td>
        <Keyboard keys={KEYBOARD_TEXT} value={value} maxLength={28} onValueChange={doSearch}/>
        <Clear/>
      </td>
      <td>
        <Keyboard keys={KEYBOARD_NUMBER_3ROW} value={value} maxLength={28} onValueChange={doSearch}/>
        <Clear/>
      </td>
    </tr>);
  }

  renderResult() {
    const {inSearching, skipMessage, supportNumber, searchResult} = this.props;

    if (inSearching) {
      return (
        <td className="biller_search-container" colSpan="2">
          <span _>{'Searching...'}</span>
        </td>
      )
    }

    if (searchResult.length) {
      return (
        <td className="biller_search-container" colSpan="2">
          <SliderContainer type={SLIDE_ITEM_TYPES.BILLER} apps={searchResult}/>
        </td>
      )
    }

    return (
      <td className="biller_search-container" colSpan="2">
        <h3 _>{'No results'}</h3>
        <span _={[supportNumber]}>{skipMessage}</span>
      </td>
    );
  }

  renderSkip() {
    const {onSkip, showSkipButton} = this.props;
    if (!showSkipButton) return;

    return (
      <div className="biller_search-skip">
        <span _>{`*Skip if biller is not found`}</span>
        <Button id="next" className="right button" text_="Skip*" onMouseDown={() => onSkip()}/>
      </div>
    )
  }

  render() {
    const {onBack, label, tipImage, startedSearching} = this.props;

    return (
      <table className="enter-user-data">
        <tbody>
        <tr>
          {!startedSearching ?
            <td className="biller_search-container" colSpan="2">
              <Image className="bill_search-pobox_image" src={tipImage}/>
              <span className="bill_search-pobox_title" _>{`Enter your biller ${label} to start searching`}</span>
            </td>
            : this.renderResult()
          }
        </tr>
        <tr>
          <td colSpan="2">
            {this.renderInput()}
          </td>
        </tr>
        {this.getTextNumberKeyboard()}
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2">
            <Button id="back" className="left button" text_="Back" onMouseDown={() => onBack()}/>
            {this.renderSkip()}
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }

}
