import IW from 'imports/iw_api';

export default function (pageBack, pageNext, functionBeforeNext = () => {}) {
  return function*() {

    Session.set('prev_current_active_field', 0);
    Session.set('current_active_field', 0);
    Session.set('money_transferring_places', '');

    //If customer is logged
    if (IW.appGetVal('logged') && !IW.appGetVal('new_recipient') && !IW.appGetVal('is_payer_edit')) {
      IW.call('checkData', 'recipient');

      IW.appSetVal('recipient_data_filled', undefined);
      yield 'loading app_exists recipient_data_filled';

      if (IW.appGetVal('recipient_data_filled')) return pageNext;
    }

    return {
      back: function () {
        return pageBack;
      },
      next: function* () {
        yield *functionBeforeNext();
        return pageNext
      }
    }
  }
}

