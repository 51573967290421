import React, {Component} from 'react';
import {_} from 'imports/lib/helpers';
import Button from 'imports/ui/shared/button/Button';

export default class Quick extends Component {
  render() {

    var res = null;
    const {a} = this.props;
    res = (
      <table>
        <tbody>
        <tr>
          <td style={{padding: "40px", textAlign: "center"}}>
            <h4 className="shadow">{_('Click a number to change, click X to remove')}</h4>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2" className="center">
            <Button id="back" className="left button capital help" onMouseDown={() => {}} style={{fontSize: "120%"}}
                    icon={<span className="circle">?</span>} text_="What's a Quick Dial?"/>
            <Button id="next" className="right button capital add" onMouseDown={() => {}} style={{fontSize: "110%"}}
                    icon={<span className="circle">+</span>} text_="New Quick Dial Number"/>
          </td>
        </tr>
        </tfoot>
      </table>
    );
    return res;
  }
}

Quick.propTypes = {};
