import {createContainer} from 'imports/lib/react_createContainer'
import EnterUserDataContainer from './EnterUserDataContainer'
import {KEYBOARDS, TRANSFER_INPUTS_RESULT} from 'imports/lib/constants'
import IW from 'imports/iw_api'
import {Session} from 'core/session'

import __ from 'lodash';

export default createContainer((params) => {
  const fields = [];
  const {ADDITIONAL_INFORMATION, MESSAGE_RULE_CHECK} = IW.appGetVal('aml_required_fields') || {
    ADDITIONAL_INFORMATION: {},
    MESSAGE_RULE_CHECK: {}
  };



  if (ADDITIONAL_INFORMATION.SENDER_BIRTH_COUNTRY) {
    fields.push({
      name: 'sender_birth_country_select',
      describe: 'Sender birth country',
      describe_output: 'Sender birth country',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SEARCH_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      validation: {
        required: true,
        exists: "sender_birth_country_select",
      }
    })
  }

  if (ADDITIONAL_INFORMATION.SENDER_PLACE_OF_BIRTH) {
    fields.push({
      name: 'sender_place_of_birth',
      describe: 'Sender place of birth',
      describe_output: 'Sender place of birth',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        required: true,
      }
    })
  }

  if (ADDITIONAL_INFORMATION.SENDER_BIRTH_DATE) {
    fields.push({
      name: 'sender_birth_date',
      describe: 'Sender birth date',
      describe_output: 'Sender birth date',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.DATEPICKER,
      validation: {
        required: true,
      }
    })
  }
  if (ADDITIONAL_INFORMATION.SENDER_NATIONALITY) {
    fields.push({
      name: 'sender_nationality_select',
      describe: 'Sender nationality',
      describe_output: 'Sender nationality',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SEARCH_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      validation: {
        required: true,
        exists: "occupation_type_select",
      }
    })
  }

  if (ADDITIONAL_INFORMATION.RECEIVER_BIRTH_COUNTRY) {
    fields.push({
      name: 'receiver_birth_country_select',
      describe: 'Receiver birth country',
      describe_output: 'Receiver birth country',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SEARCH_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      validation: {
        required: true,
        exists: "sender_birth_country_select",
      }
    })
  }

  if (ADDITIONAL_INFORMATION.RECEIVER_PLACE_OF_BIRTH) {
    fields.push({
      name: 'receiver_place_of_birth',
      describe: 'Receiver place of birth',
      describe_output: 'Receiver place of birth',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        required: true,
      }
    })
  }

  if (ADDITIONAL_INFORMATION.RECEIVER_BIRTH_DATE) {
    fields.push({
      name: 'receiver_birth_date',
      describe: 'Receiver birth date',
      describe_output: 'Receiver birth date',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.DATEPICKER,
      validation: {
        required: true,
      }
    })
  }

  if (ADDITIONAL_INFORMATION.RECEIVER_NATIONALITY) {
    fields.push({
      name: 'receiver_nationality_select',
      describe: 'Receiver nationality',
      describe_output: 'Receiver nationality',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SEARCH_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      validation: {
        required: true,
        exists: "occupation_type_select",
      }
    })
  }

  if (ADDITIONAL_INFORMATION.SENDER_OCCUPATION_TYPE) {
    fields.push({
      name: 'occupation_type_select',
      describe: 'Occupation type',
      describe_output: 'Occupation type',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SEARCH_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      validation: {
        required: true,
        exists: "occupation_type_select",
      }
    })
  }

  if (ADDITIONAL_INFORMATION.RECEIVER_RELATIONSHIP_TO_BENEFICIARY) {
    fields.push({
      name: 'relationship_to_beneficiary_select',
      describe: 'Relationship to beneficiary',
      describe_output: 'Relationship to beneficiary',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SEARCH_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      validation: {
        required: true,
        exists: "relationship_to_beneficiary_select",
      }
    })
  }

  if (ADDITIONAL_INFORMATION.SENDER_MONEY_ORIGIN) {
    fields.push({
      name: 'sender_money_origin_select',
      describe: 'Sender money origin',
      describe_output: 'Sender money origin',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SEARCH_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        required: true,
      }
    })
  }

  if (ADDITIONAL_INFORMATION.RECEIVER_PHONE) {
    fields.push({
      name: 'recipient_home_phone',
      describe: 'Receiver Phone',
      describe_output: 'Receiver Phone',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.NUMBER,
      validation: {
        required: true,
        minLength: 10,
        maxLength: 14,
        pattern: /^[0-9]+$/
      }
    })
  }

  if (ADDITIONAL_INFORMATION.RECEIVER_ADDRESS) {
    fields.push({
      name: 'receiver_address',
      describe: 'Receiver Address',
      describe_output: 'Receiver Address',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        maxLength: 100,
        required: true,
      }
    })
  }

  // Sender compliance

  // if (MESSAGE_RULE_CHECK.SENDER_PHONE) {
  //   fields.push({
  //     name: 'sender_number',
  //     describe: 'Sender phone',
  //     describe_output: MESSAGE_RULE_CHECK.SENDER_PHONE,
  //     type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
  //     type_keyboard: KEYBOARDS.NUMBER,
  //     validation: {
  //       required: true,
  //     }
  //   })
  // }
  //
  // if (MESSAGE_RULE_CHECK.SENDER_FIRST_NAME) {
  //   fields.push({
  //     name: 'sender_first_name',
  //     describe: 'Sender first name',
  //     describe_output: MESSAGE_RULE_CHECK.SENDER_FIRST_NAME,
  //     type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
  //     type_keyboard: KEYBOARDS.TEXT,
  //     validation: {
  //       required: true,
  //     }
  //   })
  // }
  //
  // if (MESSAGE_RULE_CHECK.SENDER_MIDDLE_NAME) {
  //   fields.push({
  //     name: 'sender_middle_name',
  //     describe: 'Sender middle name',
  //     describe_output: MESSAGE_RULE_CHECK.SENDER_MIDDLE_NAME,
  //     type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
  //     type_keyboard: KEYBOARDS.TEXT,
  //     validation: {
  //       required: true,
  //     }
  //   })
  // }
  //
  // if (MESSAGE_RULE_CHECK.SENDER_LAST_NAME_1) {
  //   fields.push({
  //     name: 'sender_last_name',
  //     describe: 'Sender last name',
  //     describe_output: MESSAGE_RULE_CHECK.SENDER_LAST_NAME_1,
  //     type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
  //     type_keyboard: KEYBOARDS.TEXT,
  //     validation: {
  //       required: true,
  //     }
  //   })
  // }
  //
  // if (MESSAGE_RULE_CHECK.SENDER_LAST_NAME_2) {
  //   fields.push({
  //     name: 'sender_last_name_2',
  //     describe: 'Sender second last name',
  //     describe_output: MESSAGE_RULE_CHECK.SENDER_LAST_NAME_2,
  //     type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
  //     type_keyboard: KEYBOARDS.TEXT,
  //     validation: {
  //       required: true,
  //     }
  //   })
  // }
  //
  // if (MESSAGE_RULE_CHECK.Sender_Address_Compliance) {
  //   fields.push({
  //     name: 'sender_address1',
  //     describe: 'Sender address',
  //     describe_output: MESSAGE_RULE_CHECK.Sender_Address_Compliance,
  //     type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
  //     type_keyboard: KEYBOARDS.TEXT_NUMBER,
  //     validation: {
  //       required: true,
  //     }
  //   })
  // }
  //
  // if (MESSAGE_RULE_CHECK.SENDER_CITY) {
  //   fields.push({
  //     name: 'sender_address_city',
  //     describe: 'Sender city',
  //     describe_output: MESSAGE_RULE_CHECK.SENDER_CITY,
  //     type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
  //     type_keyboard: KEYBOARDS.TEXT,
  //     validation: {
  //       required: true,
  //     }
  //   })
  // }
  //
  // // Receiver compliance
  //
  // if (MESSAGE_RULE_CHECK.RECEIVER_PHONE) {
  //   fields.push({
  //     name: 'receiver_number',
  //     describe: 'Receiver phone',
  //     describe_output: MESSAGE_RULE_CHECK.RECEIVER_PHONE,
  //     type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
  //     type_keyboard: KEYBOARDS.NUMBER,
  //     validation: {
  //       required: true,
  //     }
  //   })
  // }
  //
  // if (MESSAGE_RULE_CHECK.RECEIVER_FIRST_NAME) {
  //   fields.push({
  //     name: 'receiver_first_name',
  //     describe: 'Receiver first name',
  //     describe_output: MESSAGE_RULE_CHECK.RECEIVER_FIRST_NAME,
  //     type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
  //     type_keyboard: KEYBOARDS.TEXT,
  //     validation: {
  //       required: true,
  //     }
  //   })
  // }
  //
  // if (MESSAGE_RULE_CHECK.RECEIVER_MIDDLE_NAME) {
  //   fields.push({
  //     name: 'receiver_middle_name',
  //     describe: 'Receiver middle name',
  //     describe_output: MESSAGE_RULE_CHECK.RECEIVER_MIDDLE_NAME,
  //     type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
  //     type_keyboard: KEYBOARDS.TEXT,
  //     validation: {
  //       required: true,
  //     }
  //   })
  // }
  //
  // if (MESSAGE_RULE_CHECK.RECEIVER_LAST_NAME_1) {
  //   fields.push({
  //     name: 'receiver_last_name',
  //     describe: 'Receiver last name',
  //     describe_output: MESSAGE_RULE_CHECK.RECEIVER_LAST_NAME_1,
  //     type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
  //     type_keyboard: KEYBOARDS.TEXT,
  //     validation: {
  //       required: true,
  //     }
  //   })
  // }
  //
  // if (MESSAGE_RULE_CHECK.RECEIVER_LAST_NAME_2) {
  //   fields.push({
  //     name: 'receiver_last_name_2',
  //     describe: 'Receiver second last name',
  //     describe_output: MESSAGE_RULE_CHECK.RECEIVER_LAST_NAME_2,
  //     type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
  //     type_keyboard: KEYBOARDS.TEXT,
  //     validation: {
  //       required: true,
  //     }
  //   })
  // }
  //
  // if (MESSAGE_RULE_CHECK.RECEIVER_ADDRESS_COMPLIANCE) {
  //   fields.push({
  //     name: 'receiver_address',
  //     describe: 'Receiver address',
  //     describe_output: MESSAGE_RULE_CHECK.RECEIVER_ADDRESS_COMPLIANCE,
  //     type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
  //     type_keyboard: KEYBOARDS.TEXT_NUMBER,
  //     validation: {
  //       required: true,
  //     }
  //   })
  // }

  return {
    fields,
    prefix: 'compliance',
    ...params
  }
}, EnterUserDataContainer)