/**
 * Created by vitaliy on 13.03.17.
 */
import {createContainer} from 'imports/lib/react_createContainer';
import EnterUserDataContainer from './EnterUserDataContainer';
import {TRANSFER_INPUTS_RESULT, KEYBOARDS, TRNASPYMENT_DEFAULT_FIELDS as mustSetByDefault} from 'imports/lib/constants';
import IW from 'imports/iw_api';
import {capitalize, validateText} from 'imports/lib/helpers';
import {Session} from 'core/session';
import getPageManager from 'imports/lib/page_manager';

// function getCustomers(v) {
//   IW.callCacheable('doCustomerSearchBySsn', {query: v}, (res) => {
//     Session.set('cash_check_customers', _.map(res.s_customer_list, (c) => c));
//   })
// }

const getCustomers = _.debounce((v) => {
  function updateList(){
    Session.set('cash_check_customers',
      _.map(doCustomerSearchBySsnResult.concat(doCustomerSearchResult),
        (c) => ({key: c.id, value: capitalize(c.first_name) + ' ' + capitalize(c.last_name)})));
  }

  let doCustomerSearchResult = [];
  let doCustomerSearchBySsnResult = [];

  IW.appSetLocalVal('get_customers_loading', (IW.appGetVal('get_customers_loading') || 0) + 1);
  IW.call('doCustomerSearch', {query: v, offset: 0, limit: 3, all: true}, (___, res) => {
    IW.appSetLocalVal('get_customers_loading', (IW.appGetVal('get_customers_loading') || 0) - 1);
    doCustomerSearchResult = (res || {}).s_customer_list || [];
    updateList();
  });

  IW.appSetLocalVal('get_customers_loading', (IW.appGetVal('get_customers_loading') || 0) + 1);
  IW.call('doCustomerSearchBySsn', {query: v}, (___, res) => {
    IW.appSetLocalVal('get_customers_loading', (IW.appGetVal('get_customers_loading') || 0) - 1);
    doCustomerSearchBySsnResult = (res || {}).s_customer_list || [];
    updateList();
  });
}, 1000)

export default createContainer((params) => {

  const fields = [{
    name: 'query',
    describe: 'SSN/ID/Phone',
    describe_output: 'SSN/ID/Phone',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SEARCH_LIST,
    type_keyboard: KEYBOARDS.NUMBER,
    hideFromList: true,
    validation: {
      required: false,
      pattern: ''
    }
  }];

  function searchResult() {
    const results = (_.compact(Session.get('cash_check_customers') || [])).slice(0, 3);
    return results.complete(3, {key: '', value: ''});
  }

  return {
    fields,
    prefix: 'search',
    showLoading: IW.appGetVal('get_customers_loading') > 0,
    searchResult: searchResult(),
    onInput: function (value) {
      IW.appSetLocalVal('search_query', value);
      Session.set('cash_check_customers', []);
      getCustomers(value);
    },
    onSelectItem: function (v, key) {
      IW.appSetVal('s_customer_id', Session.get('cash_check_customers')[key].key);
      getPageManager().select();
    }
  }
}, EnterUserDataContainer);