import {createContainer} from 'imports/lib/react_createContainer';
import IW from 'imports/iw_api';
import getPageManager from 'imports/lib/page_manager';
import Maya5Description from './Maya5Description'

export default createContainer(() => {

  const onBack = () => getPageManager().back();

  return {
    onBack
  }
}, Maya5Description)
