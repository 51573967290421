import React from 'react';

export default class KeyboardKey extends React.Component {
  render() {
    const {keyName, onPress} = this.props;

    const [___, action, option] = (String(keyName) || '').match(/(.+)(#.+)/) || [0, keyName];

    const getKey = createKeyBuilder(action, onPress);
    const getTranslatedKey = createKeyBuilder(action, onPress, true);

    if (action === 'delete') return getKey('delete');
    if (action === 'prev_field') return getTranslatedKey('prev-field', 'Previous Field');
    if (action === 'next_field') return getTranslatedKey('next-field', 'Next Field');
    if (action === 'space') return getTranslatedKey('space upper', 'Space');
    if (action === 'clear') return getKey('clear', 'C');
    if (action === '') return <b/>;
    if (action === ' ') return <b> </b>;
    if (option === '#2') return getKey('two-lines', action);
    return getKey(null, action)
  }
}

KeyboardKey.propTypes = {
  keyName: React.PropTypes.any.isRequired,
  onPress: React.PropTypes.func
};

KeyboardKey.defaultProps = {
  onPress: () => {
  }
};

function createKeyBuilder(keyName, onPress, translate) {
  function onPressEvent(e) {
    return onPress(e, keyName)
  }

  return function (className, text) {
    return (
      <a className={className} onMouseDown={onPressEvent} _={translate}>
        {text}
      </a>
    )
  }
}
