import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import Family from './Family';


export default createContainer((params) => {

  return {};

}, Family);
