import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import {capitalize} from 'imports/lib/helpers';
import SliderContainer from 'imports/ui/shared/slider/SliderContainer';

export default class Choice extends Component {

  render() {
    const {title, skipButton, sliderItemType, onBack, onSkip, items} = this.props;
    
    return (<table className="recipient-choice-container">
      <tbody>
      <tr>
        <td colSpan="2">
          <div className="recipient-choice-container__title">
            <span>{title}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td colSpan="2">
          <SliderContainer type={sliderItemType} apps={items} />
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td>
          <Button id="back" className="left button" onMouseDown={ () => onBack() } text_="Back"/>
        </td>
        <td>
          <Button id="next" className="right button" onMouseDown={ () => onSkip() } text_={skipButton}/>
        </td>
      </tr>
      </tfoot>
    </table>);
  }
}

Choice.propTypes = {
  title: React.PropTypes.string,
  skipButton: React.PropTypes.string,
  sliderItemType: React.PropTypes.string,
  onBack: React.PropTypes.func,
  onSkip: React.PropTypes.func,
  items: React.PropTypes.array
};