import {createContainer} from 'imports/lib/react_createContainer';
import Sweepstakes2 from './Sweepstakes2';
import {N} from 'imports/lib/helpers';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';

export default createContainer((params) => {
  return {
    winningPrize: N(IW.appGetVal('selected_amount')),
    prizes: IW.appGetVal('prizes'),
    onMenu: () => IW.mainMenu(),
    onMoveComplete: () => getPageManager().next()
  };

}, Sweepstakes2);
