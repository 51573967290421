import {createContainer} from 'imports/lib/react_createContainer'
import EnterUserDataContainer from './EnterUserDataContainer'
import {KEYBOARDS, TRANSFER_INPUTS_RESULT} from 'imports/lib/constants'
import IW from 'imports/iw_api'
import {Session} from 'core/session'

import __ from 'lodash';

export default createContainer((params) => {
  const fields = [];

  if (!_.isEmpty(Session.get('bank_search_list')))
    fields.push({
      name: 'bank_search',
      describe: 'Bank',
      describe_output: 'Bank',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SEARCH_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        required: true,
        exists: "bank_search",
      }
    })

  if (__.get(IW.appGetVal('bank_required_fields'), 'ABF_IS_BANK_NAME_REQUIRED')) {
    fields.push({
      name: 'bank_name',
      describe: 'Bank Name',
      describe_output: 'Bank Name',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        required: true,
        minLength: 4,
        maxLength: 45
      }
    })
  }


  if (__.get(IW.appGetVal('bank_required_fields'), 'ABF_IS_LOCAL_BANK_CODE_REQUIRED')) {
    fields.push({
      name: 'local_bank_code',
      describe: 'Local Bank Code',
      describe_output: 'Local Bank Code',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        required: true,
        minLength: 3,
        maxLength: 15
      }
    })
  }

  if (__.get(IW.appGetVal('bank_required_fields'), 'ABF_IS_IBAN_REQUIRED')) {
    fields.push({
      name: 'bank_iban',
      describe: 'IBAN',
      describe_output: 'IBAN',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        required: true,
        minLength: 12,
        maxLength: 40
      }
    })
  }

  if (__.get(IW.appGetVal('bank_required_fields'), 'ABF_IS_ACCOUNT_TYPE_REQUIRED')) {
    fields.push({
      name: 'bank_account_type',
      describe: 'Account type',
      describe_output: 'Account type',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SEARCH_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      validation: {
        required: true,
        exists: "bank_account_type",
      }
    })
  }

  if (IW.appGetVal('recipient_payment_mode_id') === 'AD') {
    fields.push({
      name: 'account_number',
      describe: 'Bank Account',
      describe_output: 'Bank Account',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.NUMBER,
      validation: {
        required: true,
        minLength: 5,
        maxLength: 20
      },
      additionalUserInfo: IW.appGetVal('bank_label_' + Session.get('locale'))
    });
    const validation = (text) => text === IW.appGetVal('bank_required_account_number');
    validation.required = true;
    fields.push({
      name: 'account_number_confirm',
      describe: 'Confirm Bank Account',
      describe_output: 'Confirm Bank Account',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.NUMBER,
      validation: validation,
      additionalUserInfo: IW.appGetVal('bank_label_' + Session.get('locale'))
    });
  }

  if (__.get(IW.appGetVal('bank_required_fields'), 'ABF_IS_REASON_OF_PAYMENT_REQUIRED')) {
    fields.push({
      name: 'payment_reason_select',
      describe: 'Payment reason',
      describe_output: 'Payment reason',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SEARCH_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      validation: {
        required: true,
        exists: "payment_reason_select",
      }
    })
  }

  if (__.get(IW.appGetVal('bank_required_fields'), 'ABF_IS_RECIPIENT_ID_TYPE_REQUIRED') && !_.isEmpty(Session.get('id_type_list'))) {
    fields.push({
      name: 'id_type',
      describe: 'ID Type',
      describe_output: 'ID type',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SEARCH_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      validation: {
        required: true,
        exists: "id_type",
      }
    })
  }

  if (__.get(IW.appGetVal('bank_required_fields'), 'ABF_IS_RECIPIENT_ID_NUMBER_REQUIRED')) {
    fields.push({
      name: 'id_number',
      describe: 'ID Number',
      describe_output: 'ID Number',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        required: true,
        pattern: new RegExp(IW.appGetVal('bank_required_id_type_regexp')) || ''
      }
    })
  }

  if (__.get(IW.appGetVal('bank_required_fields'), 'ABF_IS_RECIPIENT_CPF_REQUIRED')) {
    fields.push({
      name: 'cpf',
      describe: 'CPF',
      describe_output: 'CPF',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        required: true,
      }
    })
  }

  if (__.get(IW.appGetVal('bank_required_fields'), 'ABF_IS_ANSWER_REQUIRED')) {
    fields.push({
      name: 'question',
      describe: 'Question secret',
      describe_output: 'Question secret',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        required: true,
        minLength: 5,
        maxLength: 50
      }
    })
  }

  if (__.get(IW.appGetVal('bank_required_fields'), 'ABF_IS_QUESTION_REQUIRED')) {
    fields.push({
      name: 'answer',
      describe: 'Answer',
      describe_output: 'Answer',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        required: true,
        maxLength: 50
      }
    })
  }


  return {
    fields,
    prefix: 'bank_required',
    ...params
  }
}, EnterUserDataContainer)