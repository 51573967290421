import IW from 'imports/iw_api'

export default function(pageBack = 'back'){
  return function*({}) {
    return {
      back: pageBack,
      next: 'redirect_customer',
      select: 'loading'
    }
  }
}
