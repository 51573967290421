import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import Input from 'imports/ui/shared/input/Input';


export default class Final extends Component {
  render() {
    const {
      canPrint, usdAmount, onPrintReceipt, fee, account, originalAmount, accountFieldName, onEmailPage, onMainMenu,
      deliveryOption, showSweepstakes, onSweepstakesStart
    } = this.props;
    // TODO: Add phone number
    // TODO: Replace _() with react _ attribute

    let leftButton = <Button className="left button" text_="Main menu" onMouseDown={onMainMenu}/>
    if (canPrint) {
      leftButton = <Button className="left button" text_="Print receipt" onMouseDown={onPrintReceipt}/>
    }

    const res = (
      <table className="final-page">
        <tbody>
        <tr>
          <td className="td-container">
            <table style={{height: "auto"}}>
              <tbody className="finalInputs">
              {account && accountFieldName ?
                <tr>
                  <td>
                    <span className="pre-wrap" _>{accountFieldName}</span>
                  </td>
                  <td>
                    <Input className="center" disabled="true" value={account}/>
                  </td>
                </tr> :
                null}

              <tr>
                <td>
                  <span className="pre-wrap" _>Your Bill Amount</span>
                </td>
                <td>
                  <Input className="center" disabled="true" value={originalAmount}/>
                </td>
              </tr>
              {fee ? <tr>
                <td>
                  <span className="pre-wrap" _>Fee</span>
                </td>
                <td>
                  <Input className="center" disabled="true" value={fee}/>
                </td>
              </tr> : null}
              <tr>
                <td>
                  <span style={{fontWeight: 600}} className="pre-wrap" _>Total paid today</span>
                </td>
                <td>
                  <Input style={{"fontWeight": "600"}} className="center" disabled="true" value={usdAmount}/>
                </td>
              </tr>
              {deliveryOption && <tr>
                <td colSpan={2}>
                  <div className="delivery-message">
                    <span className="pre-wrap" _>Delivery</span>
                    <span className="message">{deliveryOption}</span>
                  </div>
                </td>
              </tr>}
              </tbody>
            </table>
            <h2 style={{"margin": "12px 0px", "letterSpacing": "2px"}} className="yellow upper" _>
              Remember to take your receipt</h2>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr className="finalTfoot">
          <td colSpan="2">
            {leftButton}
            {showSweepstakes ?
              <Button className={`right button button-warning pulse-button-sweepstakes`} onMouseDown={onSweepstakesStart} text_="Sweepstakes"/> :
              <Button className={`right button`} onMouseDown={onEmailPage} text_="Email receipt"/>}
          </td>
        </tr>
        </tfoot>
      </table>
    );
    return res;
  }
}

Final.propTypes = {
  canPrint: React.PropTypes.bool,
  formatPhoneNumber: React.PropTypes.string,
  usdAmount: React.PropTypes.string,
  fee: React.PropTypes.string,
  account: React.PropTypes.string,
  accountFieldName: React.PropTypes.string,
  originalAmount: React.PropTypes.string,
  onEmailPage: React.PropTypes.func,
  onPrintReceipt: React.PropTypes.func,
  onMount: React.PropTypes.func,
  onMainMenu: React.PropTypes.func
};
