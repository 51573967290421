import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import SliderContainer from 'imports/ui/shared/slider/SliderContainer';
import {SLIDE_ITEM_TYPES} from 'imports/lib/constants';

export default class BillScanning extends Component {
  render() {
    const {
      accounts, hasAccount, billScanningEnabled, selectNewBiller, showFavorites, favoritesList, isLogged,
      goToScan, goToManual, goToSelectNew, onBack
    } = this.props;

    let renderAccounts = null;
    if (hasAccount) {
      renderAccounts = (
        <tr className="bill_scanning">
          <td colSpan="2" className="center">
            <div className="recent-header">
              <span _>Your</span> <strong _>recent accounts</strong>
            </div>
            <div className="bill_scanning__slider">
              <SliderContainer type={SLIDE_ITEM_TYPES.SIMPLE} apps={accounts}/>
            </div>
          </td>
        </tr>
      )
    }

    let renderButtons = null
    if (!showFavorites) {
      renderButtons = (
        <div className="bill-scanning-page__button-container">
          {billScanningEnabled ?
            <Button id="scan" className="button bill-scanning-page__scan-button" onMouseDown={goToScan}
                    text_="Scan a new bill"/> :
            null}
          {selectNewBiller ?
            <Button id="manual" className="button bill-scanning-page__enter-acc-button" onMouseDown={goToSelectNew}
                    text_="Search for new Biller"/>:
            <Button id="manual" className="button bill-scanning-page__enter-acc-button" onMouseDown={goToManual}
                    text_="Enter an account number"/>}
        </div>
      );
    }

    let renderFavorites = null
    if (showFavorites) {
      renderFavorites = (
        <div>
          <div className="recent-header center">
            <span _={[<strong _>a biller</strong>]}>Select %s below</span>
          </div>
          <div className="bill_scanning__slider" data-favorites data-no-accounts={!hasAccount}>
            <SliderContainer type={SLIDE_ITEM_TYPES.SIMPLE} apps={favoritesList}/>
          </div>
        </div>
      )
    }

    return (
      <table className="bill-scanning-page" id="center">
        <tbody>
        <tr>
          <td>
            {renderFavorites}
            {renderButtons}
          </td>
        </tr>
        {renderAccounts}
        </tbody>
        <tfoot>
        <tr>
          <td>
            <Button id="back" onMouseDown={onBack} className="left button" text_="Back"/>
            {showFavorites && selectNewBiller && (
              <Button id="next" className="right button" onMouseDown={goToSelectNew}
                      text_="Search for new Biller"/>
            )}
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

BillScanning.propTypes = {
  accounts: React.PropTypes.arrayOf(React.PropTypes.array),
  hasAccount: React.PropTypes.bool,
  billScanningEnabled: React.PropTypes.bool,
  selectNewBiller: React.PropTypes.bool,
  goToPay: React.PropTypes.func,
  goToScan: React.PropTypes.func,
  goToManual: React.PropTypes.func,
  onBack: React.PropTypes.func
};

BillScanning.defaultProps = {
  accounts: [],
}
