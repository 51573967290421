import {createContainer} from 'imports/lib/react_createContainer';
import {executeAtmStep} from 'imports/lib/helpers';
import EnterPin from './EnterPin';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';

const MIN_PIN_DIGITS = 4;
const MAX_PIN_DIGITS = 12;

export default createContainer((params) => {
  const currentKey = IW.appGetVal('pad_key');
  IW.appSetLocalVal('pad_key', '');

  function updatePadInput(){
    if (currentKey === 'ENTER') {
      IW.appSetLocalVal('pad_entered', true);
      return getPageManager().next()
    }

    if (currentKey === 'CANCEL') {
      IW.appSetLocalVal('pad_canceled', true);
      return getPageManager().cancel()
    }

    if (currentKey === '#') {
      return getPageManager().back()
    }

    let padPin = IW.appGetVal('pad_pin') || '';

    if (currentKey === '*') {
      padPin += '*';
    }

    if (currentKey === 'CLEAR') {
      padPin = padPin.slice(0, -1);
    }

    if (currentKey === 'DELETE') {
      padPin = '';
    }

    IW.appSetLocalVal('pad_pin', padPin);
  }

  updatePadInput();
  // show message only on start
  if (IW.appGetVal('pad_pin').length > 0 && IW.appGetVal('pin_wrong')) {
    IW.appSetVal('pin_wrong', false);
    IW.appSetVal('pin_not_valid', false);
  }

  IW.setVoicePage('atm_enter_pin', {wrong: !!IW.appGetVal('pin_wrong')});
  return {
    pin: IW.appGetVal('pad_pin'),
    isPinWrong: !!IW.appGetVal('pin_wrong'),
    isPinNotValid: !!IW.appGetVal('pin_not_valid'),
    isPinOk: IW.appGetVal('pad_pin').length >= MIN_PIN_DIGITS,
    onNext: () => getPageManager().next(),
    onCancel: () => getPageManager().cancel(),
    onBack: () => getPageManager().back()
  };

}, EnterPin);
