import IW from 'imports/iw_api'
import {Session} from 'core/session'
import _ from 'underscore'

export default function (pageBack, pageNext, billScanning) {
  return function() {

    Session.set('prev_current_active_field', 0);
    Session.set('current_active_field', 0);
    IW.appSetLocalVal('biller_name', '');

    return {
      back: function () {
        return pageBack
      },
      next: function* () {
        clearBillerFilledInfo();

        const biller = yield* getBiller();

        if (biller) {
          fillBiller(biller);
          return pageNext;
        }

        console.log('Biller not found');
        return 'none'
      },
      skip: function () {
        IW.appSetVal('biller_id', undefined);
        IW.appSetVal('biller_name', undefined);
        IW.appSetVal('address_sequence', undefined);
        IW.appSetVal('biller_unknown', true);
        return billScanning;
      }
    }
  }
}

function* getBiller() {
  const input = IW.appGetVal('biller_name');
  if (!input) return false;

  let biller = findBillerInSession(input);
  if (biller) return biller;

  getBillersForce(input);
  yield 'loading session founded_billers';

  biller = findBillerInSession(input);
  if (biller) return biller;

  return false;
}

function findBillerInSession(v) {
  const foundedBillers = Session.get("founded_billers") || {};
  const billerFromSession = _.find(foundedBillers, (fb) => fb.biller_name === v);
  if (!billerFromSession) {
    return false;
  }

  return billerFromSession;
}

function getBillersForce(v) {
  const previousBillers = Session.get('founded_billers');

  Session.set("founded_billers", undefined);

  IW.call('getBillers', {search_word: v}, (__, result) => {
    if (!(result && result.length)) {
      return Session.set('founded_billers', previousBillers);
    }
    return Session.set('founded_billers', result);
  });
}

function fillBiller(b) {
  IW.appSetVal('biller_id', b.biller_id || -1);
  IW.appSetVal('biller_name', b.biller_name || '');
  IW.appSetVal('biller_is_rent', b.biller_is_rent || false);
  IW.appSetVal('address_sequence', b.address_sequence || -1);
  IW.appSetVal('biller_city_state_zip', b.biller_city_state_zip || '');
  IW.appSetVal('payment_options', b.payment_options || null);
  IW.appSetVal('payment_methods', b.payment_methods || null);
  IW.appSetVal('biller_unknown', false);
}

function clearBillerFilledInfo() {
  IW.appSetDefaults(/required_/);
  IW.appSetVal('account_is_invalid', false);
  IW.appSetVal('biller_account', '');
  IW.appSetVal('account', '');
  IW.appSetVal('account1', '');
  IW.appSetVal('account2', '');
  IW.appSetVal('account_formatted', '');
}
