import {createContainer} from 'imports/lib/react_createContainer';
import ConfirmEnteredData from './ConfirmEnteredData';
import IW from 'imports/iw_api';
import getPageManager from 'imports/lib/page_manager';
import {complete} from 'imports/lib/array_extension';
import moment from 'imports/lib/external/moment-with-langs';


export default createContainer((params) => {
  const prefix = 'customer_data';
  const get = createGetterWithPrefix(prefix);

  const fullName = _.compact([get('first_name'), get('middle_name'), get('last_name')]).join(' ');
  const fullAddress = [_.compact([get('address1'), get('address2')]).join(' '),
    _.compact([get('address_city'), _.compact([get('address_state'), get('address_zip')]).join(' ')]).join(', ')
  ];

  return {
    userType: 'customer',
    items: [
      {describe_output: 'Name', data: fullName},
      {describe_output: 'Cell Phone', data: get('mobile_number')},
      {describe_output: 'Email', data: get('email')},
      {describe_output: 'Address', data: fullAddress},
    ],
    onBack: () => getPageManager().back(),
    onNext: () => getPageManager().next(),
    onEdit: () => getPageManager().edit()
  };
}, ConfirmEnteredData);

function createGetterWithPrefix(prefix) {
  return function (key) {
    return IW.appGetVal(`${prefix}_${key}`)
  }
}