import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import IFrame from 'imports/ui/shared/iframe/IFrame';
import Image from 'imports/ui/shared/image/Image'
import {_} from 'imports/lib/helpers'

export default class StartScanning extends Component {
  render() {
    const {previewUrl, onBack, isError, canScan, onScanBill, onSearch} = this.props;

    return (
      <table className="start-scan-page">
        <tbody>
        {!isError ?
          <tr>
            <td colSpan="3" style={{textAlign: 'center', fontSize: 50}}>
              Take a photo for your profile
            </td>
          </tr>: null}
        <tr>
          <td colSpan="3" className="start-scan-page__canvas-container">
            {isError ?
              <h3 className="warn start-scan-page__error-message" _>Cant establish a connection to the camera</h3> :
              <IFrame id="videoCanvas" startUrl={previewUrl} style={{height: 550}}/>}
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td>
            <Button onMouseDown={onBack} className="left button ready button-danger" text_="Back"/>
          </td>
          <td>
            {canScan ?
              <Button onMouseDown={onScanBill} className="right button ready" text_="Scan"/> :
              null }
          </td>
        </tr>
        </tfoot>
      </table>
    );
  }
}

StartScanning.propTypes = {
  onBack: React.PropTypes.func,
  onScanBill: React.PropTypes.func,
  isError: React.PropTypes.bool,
  canScan: React.PropTypes.bool,
};
