import {Session} from 'core/session';
import IW from 'imports/iw_api';
import app_common from './app_common';
import createNumber from "./common/number";
import createAmount from "./common/amount";
import createCardReloadActions from './common/createCardReloadActions'
import createPaymentStatus from './common/payment_status';

export default _.extend({}, app_common, {
  index: () => 'number',

  number: createNumber('exit', 'insert_card_reload', {allowLandline: true}),

  //insert_card_reload
  insert_card_reload: function* () {
    cleanCardReader();

    IW.callCardReader('status');
    yield 'loading app card_reader_status_done';
    if (!IW.appGetVal('card_reader_status_success')) return 'device_error';

    IW.appSetLocalVal('card_insert_error', false);
    IW.appSetLocalVal('card_removed', true);

    return 'insert_card_reload_simple';
  },

  return_card_continue: function*(){
    ejectCard();
    yield 'sleep app card_removed';
    Session.set('menu_disabled', false);
    IW.callCardReader('data');
    yield 'loading app card_data_received';
    if (!IW.appGetVal('card_processed')) {
      IW.appSetLocalVal('card_insert_error', true);
      return 'insert_card_reload_simple';
    }
    return 'card_reload_actions';
  },

  insert_card_reload_simple: function*(){
    IW.appSetVal('card_insert', false);
    IW.appSetVal('card_data_received', false);
    if (IW.appGetVal('card_removed')) {
      IW.callCardReader('receive_debit_card');
      Session.set('card_inserting', true);
    }
    IW.appSetVal('card_removed', false);
    yield {
      back: 'number',
      next: 'exit'
    };
    yield 'sleep app card_insert';
    return 'return_card_continue'
  },


  //insert_card_reload->card_reload_actions
  card_reload_actions: createCardReloadActions('number', 'card_balance', 'bill_amount'),

  //insert_card_reload->card_reload_actions->amount
  bill_amount: createAmount('card_reload_actions', 'prevalins_transaction'),
  bill_amount_again: createAmount('exit', 'prevalins_transaction'),

  prevalins_transaction: function* ({lastAction}) {
    if(lastAction === 'back') return 'back';

    cleanPrevalins();

    IW.call('doPrevalins');
    yield 'loading app prevalins_done';

    if (IW.appGetVal('prevalins_status')) {
      return 'pay'
    }

    return 'prevalins_transaction_error';
  },

  prevalins_transaction_error: function*() {
    Session.set('menu_disabled', true);
    yield 'sleep 5000';
    Session.set('menu_disabled', false);
    return 'card_reload_actions'
  },

  card_balance: function* () {
    IW.appSetVal('check_balance_done', false);
    IW.appSetVal('balance_amount', 0);

    IW.call('checkBalance');
    yield 'loading app check_balance_done';

    return {
      cancel: 'exit',
      back: 'card_reload_actions',
      print: () => {

      },
    }
  },

  check_balance_error: function () {
    return {
      cancel: 'exit',
      back: 'card_reload_actions'
    }
  },

  return_card_balance: function* () {
    ejectCard();
    yield 'sleep app card_removed';
    return 'extract_money_success';
  },

  return_card_transaction_error: function* () {
    ejectCard();
    yield 'sleep app card_removed';
    IW.printReceipt();
    return 'exit';
  },

  return_card_internal_error: function* () {
    ejectCard();
    yield 'sleep app card_removed';
    return 'exit';
  },

  transaction_canceled: {
    cancel: 'exit',
    next: 'exit',
  },
  bad_card: {
    cancel: 'exit',
    next: 'exit',
  },
  internal_error: {
    cancel: 'exit',
    next: 'exit',
  },
  device_error: () => {
    return {
      cancel: 'exit',
      next: 'exit',
    }
  },
  coming_soon: {},
  payment_status: createPaymentStatus('number', 'bill_amount_again', 'pay'),
});

function ejectCard() {
  IW.callCardReader('close');
  Session.set('menu_disabled', true);
  Session.set('card_inserting', false);
}

function cleanCardReader() {
  Session.set('card_inserting', false);
  IW.appSetVal('card_reader_status_done', undefined);
  IW.appSetVal('card_processed', undefined);
  IW.appSetVal('card_insert', undefined);
  IW.appSetVal('card_data_received', undefined);
  IW.appSetVal('card_removed', undefined);
}

function cleanPrevalins() {
  IW.appSetVal('prevalins_done', undefined);
  IW.appSetVal('prevalins_status', undefined);
  IW.appSetVal('prevalins_response_text', undefined);
}