import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';

export default class ClerkLoginError extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {onNext, message} = this.props;

    return (
      <table>
        <tbody>
        <tr>
          <td colSpan="2" style={{padding: "40px", textAlign: "center", verticalAlign: "middle"}}>
            <h2 _>{message}</h2>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td>
            <Button id="back" className="left button goto-main" text_={'Exit'} onMouseDown={onNext}/>
          </td>
        </tr>
        </tfoot>
      </table>
    );
  }
}