import {createContainer} from 'imports/lib/react_createContainer';
import Maya5Congratulation from '../maya5/Maya5Congratulation';
import {Session} from 'core/session';

export default createContainer(() => {

  let videoUrl = '/resources/videos/sweepstakes/win_animation_en.mp4';
  if (Session.get('locale') === 'es_MX'){
    videoUrl = '/resources/videos/sweepstakes/win_animation_sp.mp4';
  }

  return {
    videoUrl,
    styles: {background: '#1A172D'},
    onFinish: () => ({})
  }
}, Maya5Congratulation)
