import React from 'react';

/**
 * props:
 *  src {string} - link on video (mp4)
 *  onFinish {function} - trigger when video is finished
 *  onClick {function}
 */
export default class VideoOverlay extends React.Component {

  shouldComponentUpdate () {
    return false;
  }

  componentDidMount() {
    const { video, props } = this;

    function _loadedmetadata() {
      video.play();
      video.removeEventListener('loadedmetadata', _loadedmetadata);
    }
    video.addEventListener('loadedmetadata', _loadedmetadata);


    function _timeupdate() {
      if(video.currentTime === video.duration) {
        video.pause();
        if (props.onFinish) props.onFinish();
        video.removeEventListener('timeupdate', _timeupdate);
      }
    }
    video.addEventListener('timeupdate', _timeupdate);
  }

  render() {
    const { src, styles } = this.props;

    return (
      <div style={defaultStyles.Overlay} onClick={(e) => {
        if(this.props.onClick) this.props.onClick(e);
      }}>
        <video style={{...defaultStyles.Video, ...styles}} ref={(video) => this.video = video} >
          <source src={src} type="video/mp4" />
        </video>
      </div>
    )
  }

}


const defaultStyles = {
  Overlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },

  Video: {
    margin: 'auto',
    width: '100%',
    height: '100%',
  }
}