/**
 * Created by vitaliy on 17.03.17.
 */
import {createContainer} from 'imports/lib/react_createContainer';
import NameValueDataDisplay from '../namevalue_display/NameValueDataDisplay';
import IW from 'imports/iw_api';
import {C, N, S} from 'imports/lib/helpers';

export default createContainer((params) => {
  if(Session.get('app_guid') === 'app_cryptocoin_DgtMt') {
    const original = `${C(IW.appGetVal('original_amount'), '$')}`;
    const rateDescription = `${IW.appGetVal('crypto_currency_title')} rate: `;
    const exRate = `1${IW.appGetVal('crypto_currency_symbol')} = $${IW.appGetVal('rate')}`;
    const totalDescription = `Total ${IW.appGetVal('crypto_currency_title')} purchase: `;
    const totalPurchase = `${IW.appGetVal('total_purchase')}${IW.appGetVal('crypto_currency_symbol')}`;

    const items = _.compact([
      {description: 'Amount to pay: ', data: original},
      {delimiter: true},
      {description: totalDescription, data: totalPurchase},
      {description: rateDescription, data: exRate}
    ]);

    return {
      items
    }
  }

  const currency = (Session.get('app_guid') !== 'app_moneytransfer_sigue' && '$') || '';
  const extCurrencyFrom = 'USD';
  const extCurrency = IW.appGetVal("recipient_currency_iso") ||
    IW.appGetVal("recipient_currency_selected") ||
    IW.appGetVal("recipient_currency");
  const rate = IW.appGetVal('rate');
  const feeType = S(IW.appGetVal('feeType'));

  const original = `${C(IW.appGetVal('original_amount'), currency)}`;

  //Viamericas fee
  const customerFeeFix = IW.appGetVal('fee_fix_customer') !== '' ? `${C(IW.appGetVal('fee_fix_customer'), currency)}` : null;
  const customerFeePercentage = IW.appGetVal('fee_percentage_customer') !== '' ? `${C(IW.appGetVal('fee_percentage_customer'), currency)}` : null;

  const customerFee = customerFeeFix || customerFeePercentage;

  const transferTaxes = IW.appGetVal('transfer_taxes') !== '' ? `${C(IW.appGetVal('transfer_taxes'), currency)}` : null;
  const feeFunding = IW.appGetVal('fee_funding') ? `${C(IW.appGetVal('fee_funding'), currency)}` : null;

  const total = `${C(IW.appGetVal('requested_amount'), currency)}`;

  let fee = N(IW.appGetVal('fee'));
  if (feeType === "fee_fixed") fee = C(fee, currency);
  if (feeType === "fee_percentage") fee += "%";

  if (Session.get('app_guid') === 'app_moneytransfer_sigue') {
    const items = _.compact([
      {description: 'Transfer Amount: ', middle: extCurrencyFrom, data: original},
      feeType && customerFee && {description: 'Transfer Fees:', middle: extCurrencyFrom, data: C(IW.appGetVal('calculated_commission'))},
      feeType && customerFee && {
        description: 'Additional Transfer Fees:',
        middle: extCurrencyFrom,
        data: C(IW.appGetVal('calculated_additional_fee'))
      },
      feeType && customerFee && {
        description: 'Transfer Taxes:',
        middle: extCurrencyFrom,
        data: C(IW.appGetVal('calculated_tax'))
      },
      feeType && {description: 'Total Amount Received:', middle: extCurrencyFrom, data: C(IW.appGetVal('calculated_total'))},
      {delimiter: true},
      {description: 'Exchange Rate *:', middle: extCurrency, data: C(rate)},
      {description: 'Foreign Transfer Amount:', middle: extCurrency, data: C(IW.appGetVal('beneficiary_amount'))},
      feeType && {description: 'Foreign Fees:', middle: extCurrency, data: C(IW.appGetVal('calculated_foreign_fee'))},
      feeType && {description: 'Foreign Tax:', middle: extCurrency, data: C(IW.appGetVal('calculated_foreign_tax'))},
      feeType && {
        description: 'Total to Recipient **:',
        middle: extCurrency,
        data: C(IW.appGetVal('calculated_destination_amount'))
      },
    ]);

    return {
      items
    }
  }

  const beneficiary = `${C(IW.appGetVal('beneficiary_amount'))} ${extCurrency}`;
  const exRate = `${currency} 1 ${extCurrencyFrom} =  ${C(rate)} ${extCurrency}`;

  const items = _.compact([
    {description: 'Amount to send: ', data: original},
    feeType && customerFee && {description: 'Fee: ', data: customerFee},
    transferTaxes && {description: 'Transfer taxes: ', data: transferTaxes},
    feeFunding && {description: 'Funding fee: ', data: feeFunding},
    {delimiter: true},
    (feeType && {description: 'Total transfer fee: ', data: fee}),
    feeType && {description: 'Total due: ', data: total},
    {description: 'Recipient gets: ', data: beneficiary},
    {description: 'Exchange rate: ', data: exRate}
  ]);

  return {
    items
  }
}, NameValueDataDisplay);
