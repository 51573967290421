import {Tracker} from 'core/tracker';

Tracker.autorun(function(){
  var focus = Session.get('focus');

  $('input.focus, label.focus').removeClass('focus');

  // Exit if got nothing to focus to
  if( !focus ) return; /////////////

  $('input#'+focus).addClass('focus');
  $('label[for="'+focus+'"]').addClass('focus');
});
