import React from 'react';
import Button from 'imports/ui/shared/button/Button';
import IW from 'imports/iw_api';
import getPageManager from 'imports/lib/page_manager'
import {createContainer} from 'imports/lib/react_createContainer';

class Loading extends React.Component {
  render() {
    IW.setVoicePage('loading');
    return (
      <div className="loading" >
        <img id="spinner" src="/themes/mayafs/images/spinner.png"/>
        {this.props.message && <div className="loading-message">{this.props.message}</div>}
      </div>
    )
    // return (
    //   <table>
    //     <tbody>
    //     <tr>
    //       <td className="loading">
    //         <img id="spinner" src="/themes/interwallet/images/spinner.png"/>
    //       </td>
    //     </tr>
    //     </tbody>
    //     <tfoot>
    //     <tr>
    //       <td className="center">
    //         <Button id="back" className="left button" text_="Back" onMouseDown={() => getPageManager().back()}/>
    //       </td>
    //     </tr>
    //     </tfoot>
    //     {this.props.message && <div className="loading-message">{this.props.message}</div>}
    //   </table>
    // );
    /**
     * //TODO Cancel button is too dangerous on loading page
      <table>
        <tbody>
        <tr>
          <td className="loading">
            <img id="spinner" src="/themes/interwallet/images/spinner.png"/>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td className="center">
            <Button className="button button-danger" text_="Cancel" onMouseDown={() => IW.mainMenu()}/>
          </td>
        </tr>
        </tfoot>
      </table>
     * **/
  }
}

export default createContainer(() => {
  return {
    message: IW.appGetVal('loading_message')
  }
}, Loading)