import {createContainer} from 'imports/lib/react_createContainer';
import List from '../apps/list/List';
import Links from 'imports/collections/links';
import getPageManager from 'imports/lib/page_manager';

export default createContainer(({params}) => {

  const data = {
    query: {
      product_id: {$in: params.products},
    },
    sort: {
      sort_priority: -1,
      name: 1
    }
  };


  function getLinks() {
    const links = Links.find(data.query, {
      sort: data.sort,
      hidden: false
    }).fetch();

    return links.chunk(10).map((item) => item.complete(10, {}).chunk(5));
  }


  return {
    choices: getLinks(),
    onBack: () => getPageManager().back(),
  };

}, List);
