import IW from 'imports/iw_api';
import * as Helpers from 'imports/lib/helpers';
import getPageManager from 'imports/lib/page_manager'
import CONFIG from 'imports/config'

export default (params) => {
  return {
    ...IW.appGetAccount(),
    usdAmount: Helpers.usd(IW.appGetVal('amount') || 0),
    deliveryOption: IW.appGetVal('validate_transaction_delivery_message') || IW.appGetVal('biller_description'),
    fee: Helpers.usd((IW.appGetVal('fee') || 0) +
      (IW.appGetVal('transaction_fee') || 0) +
      (IW.appGetVal('discount_amount') || 0)),
    pages: params.pages,
    originalAmount: Helpers.usd(IW.appGetVal('paid_amount') || 0),
    canPrint: !IW.appGetVal('receipt_was_printed'),
    onPrintReceipt: () => {
      if (IW.appGetVal('receipt_was_printed')) return;
      IW.printReceipt();
      if (!IW.isVirtual()) {
        IW.appSetLocalVal('receipt_was_printed', true);
      }
    },
    isClerkLogged: IW.isClerkLogged(),
    showSweepstakes: !!CONFIG.SWEEPSTAKES_ENABLED,
    onEmailPage: () => getPageManager().email(),
    onMainMenu: () => getPageManager().cancel(),
    onClerkContinue: () => getPageManager().next(),
    onSweepstakesStart: () => getPageManager().next(),
  };

};
