import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import {Session} from 'core/session';
import {getUser, C} from 'imports/lib/helpers';
import Profile from './Profile';
import IW from 'imports/iw_api';
import {getDevice} from 'imports/api/device';
import getPageManager, {goTo} from 'imports/lib/page_manager';
import moment from 'imports/lib/external/moment-with-langs'
import CONFIG from '../../../config';

export default createContainer((params) => {

  const customer = IW.loginGetVal('customer_data_result') || {};
  const customerPayments = Session.get('customer_data_payments') || {};

  function launchMaintenance() {
    const device = getDevice();
    const socket = IW.getSocket();
    const user = Session.get('login');
    const session_locale = Session.get('locale');
    socket.emit('/d/profile_start_maintenance', {
      access_token: user.access_token,
      sso_id: user.customer_data_result.sso_id,
      session_locale: session_locale,
      device_guid: device.DEVICE_GUID,
    });

    IW.appSetVal('maintenance_qr_sent_show', true);
  }

  const onNext = (action) => {
    IW.loginSetVal('profile_action', action);

    if (action === 'withdraw_referral_cash') {
      Session.set('entry_point_path', FlowRouter.current().path);
      return IW.launchReferrerDispense();
    }
    if (action === 'withdraw_debit_cash') {
      Session.set('entry_point_path', FlowRouter.current().path);
      return IW.launchDebitDispense();
    }

    return getPageManager().next();
  };

  const last_payments = _.map(customerPayments || [], (p) => {
    return {
      payment_id: p.payment_id,
      product_id: p.product_id,
      product_name: p.product_name,
      product_title: p.product_title,
      product_icon: p.product_icon,
      session_guid: p.session_guid,
      x_data: p.session_x_data,
      app_guid: p.app_guid,
      actual_amount: `$${C(p.payment_actual_amount)}`,
      time_end: moment(p.payment_time_end || p.payment_time_start).format('M/D/YY | h:mm A'),
    };
  });

  return {
    first_name: customer.first_name || '',
    referral_amount: C(customer.referral_amount, '$'),
    referral_code: customer.referral_code,
    debit_balance: C(customer.debit_balance, '$'),
    last_payments: last_payments,
    time: Session.get('time'),
    canWithdrawReferral: CONFIG.REFERRALS_ENABLED && !!customer.referral_amount,
    canWithdrawDebit: !!customer.debit_balance,
    allowMaintenance: customer.role === 'technician' || customer.role === 's_technician',
    launchMaintenance,
    onNext,
  };
}, Profile);