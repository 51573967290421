import {createContainer} from 'imports/lib/react_createContainer';
import IW from 'imports/iw_api';
import {C, N} from 'imports/lib/helpers'
import CONFIG from 'imports/config'
import BillAmountContainer from './BillAmountContainer';

export default createContainer((params) => {
  return {
    field: 'required_field_deposit_amount',
    title: 'Deposit Amount',
  };

}, BillAmountContainer);
