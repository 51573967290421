import {createContainer} from 'imports/lib/react_createContainer';
import CategoryItem from './CategoryItem';
import {FlowRouter} from 'core/Router';
import {goTo} from 'imports/lib/page_manager'
import IW from 'imports/iw_api';
import CONFIG from 'imports/config';

export default createContainer(({id, name, accented}) => {
  return {
    id: id,
    name: name,
    accented: accented,
    currentCategory: ~~FlowRouter.getParam("categoryName"),
    onChangeCategory: (categoryId) => {
      if(accented){
        IW.launchATM();
      } else {
        IW.openCat(categoryId);
      }
    }
  };

}, CategoryItem);