import {Session} from 'core/session'
import IW from 'imports/iw_api'

export default function (pageBack, pageNext) {
  return function* ({lastAction}) {
    Session.set('prev_current_active_field', 0);
    Session.set('current_active_field', 0);
    Session.set('money_transferring_cities', "");

    if (IW.appGetVal('logged') &&
      IW.appGetVal('recipient_city_id') &&
      IW.appGetVal('recipient_city_name') &&
      !IW.appGetVal('new_recipient') &&
      !IW.appGetVal('internal_data_error') &&
      lastAction !== 'back') {
      return pageNext;
    }

    return {
      back: function () {
        return pageBack;
      },

      next: function () {
        if (IW.appGetVal('recipient_required_town')) {
          return 'recipient_town';
        }

        return pageNext;
      }
    }
  }
}
