import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import {Session} from 'core/session';
import {getUser} from 'imports/lib/helpers';
import Profile from './Profile';
import IW from 'imports/iw_api';
import getPageManager, {goTo} from 'imports/lib/page_manager';
import appWorkflow from 'imports/apps_workflow'

export default createContainer((params) => {
  // return {
  //   icon: {
  //     app_guid: "app_shoppingcart_dTres",
  //     icon: "icon",
  //     title: "Shopping Cart", // in the header
  //     name: "Shopping Cart", // in the list
  //     start_page: "shopping_cart",
  //     feed: 0,//Session.get('shopping_cart_length') || 0,
  //     customer_id: getUser().customer_id,
  //   },
  //   user: getUser(),
  // };

  const startPage = 'shopping_cart';
  const appGuid = 'app_shoppingcart_dTres';
  const data = {
    icon: 'icon',
    title: 'Shopping Cart',
    product_id: 0, // product_id
    page: startPage,
    feed: 0,
    customer_id: getUser().customer_id,
    user: getUser()
  };

  IW.appLaunch(appGuid, data);

  goTo(startPage, appGuid, '0');
  return null;

}, Profile);