import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import safeRender from 'imports/ui/decorators/safeRender'

class ChangeProduct extends Component {
  render() {
    const {variants, onNext, onBack} = this.props;

    const styleSpan = {
      fontSize: '35px',
      lineHeight: '50px'
    }

    const styleRow = {
      padding: '10px 25px 10px 60px',
    }

    const styleHeader = {
      margin: 0,
      padding: '10px 60px'
    }

    const rows = [];

    _.each(variants, (v, i) => {
      if (!v) {
        return rows.push(<tr key={i}><td></td></tr>);
      }
      rows.push(
        <tr key={i}>
          <td className="yellow" style={styleRow}>
            <span style={styleSpan}>
              {v.message}
            </span>
          </td>
          <td id="center">
            <Button id="next" className="right button" onMouseDown={() => onNext(i)} text={v.button}
                    icon={<img src={v.icon} style={{width: 100, margin: '-30px 20px -30px -20px'}}/>}
                    icon_placement="before" style={{fontSize: '40px', width: '100%'}}/>
          </td>
        </tr>
      );
    })

    return (
      <table>
        <tbody>
        <tr>
          <td colSpan={2}><h1 className="yellow upper" style={styleHeader} _>Please Note</h1> </td>
        </tr>
        {rows}
        <tr><td/></tr>
        <tr><td/></tr>
        </tbody>
        <tfoot>
        <tr>
          <td>
            <Button id="back" className="left button goto-main" onMouseDown={onBack} text_="Back"/>
          </td>
          <td/>
        </tr>
        </tfoot>
      </table>
    )
  }
}

ChangeProduct.propTypes = {
  variants: React.PropTypes.arrayOf(React.PropTypes.shape({
    message: React.PropTypes.string,
    button: React.PropTypes.string,
    icon: React.PropTypes.string,
  })),
  onNext: React.PropTypes.func,
  onOther: React.PropTypes.func,
  onBack: React.PropTypes.func
};

ChangeProduct.defaultProps = {
  type: 'plan',
  onNext: () => {
  },
  onOther: () => {
  },
  onBack: () => {
  }
};

export default ChangeProduct;
