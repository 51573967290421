import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import Email from './Email';
import IW from 'imports/iw_api';
import getPageManager from 'imports/lib/page_manager'
import {KEYBOARD_EMAIL, KEYBOARD_EMAIL_SUFFIX} from 'imports/lib/constants'

export default createContainer((params) => {
  function getEmail() {
    IW.appSetVal('email_valid', IW.validEmail(IW.appGetVal('customer_data_email')));
    return IW.appGetVal('customer_data_email') || '';
  }

  return {
    isEmailValid: !!IW.appGetVal('email_valid') || !IW.appGetVal('customer_data_email'),
    email: getEmail(),
    keyboardLayout: KEYBOARD_EMAIL,
    emailKeyboardLayout : KEYBOARD_EMAIL_SUFFIX,
    onTypeEmail: (v) => {
      const previousEmail = IW.appGetVal('email');
      if (v.toLowerCase().length - previousEmail.length >= 4) {
        v = v.toLowerCase().replace(/([^o]|.{2,})\1/, '$1');
      }
      IW.appSetVal('customer_data_email', v.toLowerCase());
    },
    goToBackStep: (ev) => getPageManager().back(),
    goToNextStep: (ev) => getPageManager().next(),
    pages: params.pages,
    isSendEmail: false
  };

}, Email);
