import React, {Component} from 'react';
import {FlowRouter} from 'core/Router';
import Button from 'imports/ui/shared/button/Button';
import {NUM_PAD} from 'imports/lib/constants';
import Numpad from 'imports/ui/shared/numpad/Numpad';
import ClearedInput from 'imports/ui/shared/cleared_input/ClearedInput';
import InputLabel from 'imports/ui/shared/input/InputLabel';


function renderHeader(headerType, headerScope) {
  let text = '';
  switch (headerType) {
    case 'logging':
      text = `%s%s into your${headerScope ? ' %s ' : ' '}profile`
      return (
        <span className="pin-number__description center" _={[
          <img key="0" className="pin_number_header-icon" src="/resources/images/login/ic_login_small.png"/>,
          <span key="1" className="green" _>Log</span>,
          <span key="2" className="green">{headerScope}</span>,
        ]}>
          {text}
        </span>
      );
    case 'confirming':
      text = `%s%s your${headerScope ? ' %s ' : ' '}phone number`
      return (
        <span className="pin-number__description center" _={[
          <img key="0" className="pin_number_header-icon" src="/resources/images/login/ic_login_small.png"/>,
          <span key="1" className="green" _>Confirming</span>,
          <span key="2" className="green">{headerScope}</span>,
        ]}>
          {text}
        </span>
      )
    default:
      return null;
  }
}

export default class PinNumber extends Component {

  render() {
    const {goBack, pinNumber, hasOwnPincode, isResendShown, headerType, headerScope,
      clearInput, onPrintPin, errorMessage, onChangePin, disableResetPin} = this.props;

    const labelMessage = hasOwnPincode ?
      'Enter your PIN code' :
      'Enter the PIN code we sent to your mobile phone';
    const visiblePinNumber = hasOwnPincode ?
      pinNumber.replace(/./g, '*') :
      pinNumber;

    const res = (
      <table className="pin-number">
        <tbody>
        <tr className="bg_dark_blue">
          <td colSpan="2" className="pin-number__header-container">
            {renderHeader(headerType, headerScope)}
          </td>
        </tr>
        <tr>
          <td className="pin-number__input-container">
            <div className="pin-number__input">
              <InputLabel text={labelMessage} style={{padding: "0 0 10px 0", display: 'block'}}/>
              <ClearedInput id="pinNumber" className="focus" value={visiblePinNumber} readOnly={true} focus="pinNumber"
                            clearInput={clearInput}/>
              <div style={{height: 50}}>
                {errorMessage === 'invalid_pin' ? <InputLabel text="Invalid PIN" style={{padding: "10px 0 0 0", display: 'block', color: '#eb564a'}}/> : null}
                {errorMessage === 'server_error' ? <InputLabel text="Server Error" style={{padding: "10px 0 0 0", display: 'block', color: '#eb564a'}}/> : null}
                {errorMessage === 'internal_error' ? <InputLabel text="Internal Error" style={{padding: "10px 0 0 0", display: 'block', color: '#eb564a'}}/> : null}
                {errorMessage === 'invalid_role' ? <InputLabel text="Access Denied" style={{padding: "10px 0 0 0", display: 'block', color: '#eb564a'}}/> : null}
              </div>
              <br/>
              {!hasOwnPincode ?
                <span className="pin-number__input under-input-txt note" _={[<b _>Resend</b>]}>
                  If you did not receive a code hit the "%s" button bellow
                </span> :
                null}
            </div>
          </td>
          <td className="pin-number__numpad-container">
            <Numpad keys={NUM_PAD} value={pinNumber} onValueChange={onPrintPin}/>
          </td>
        </tr>
        </tbody>
        <tfoot className="bg_dark_blue">

        <tr>
          <td>
            <Button id="back" className="left button" text_="Back" onMouseDown={goBack}/>
            {(isResendShown && !disableResetPin) ? <Button id="resend" className="right button" text_={hasOwnPincode ? 'Forgot' : 'Resend'}
                    onMouseDown={onChangePin}/> :
              null}
          </td>
          <td>
          </td>
        </tr>
        </tfoot>
      </table>
    );
    return res;
  }
}

PinNumber.propTypes = {
  goBack: React.PropTypes.func,
  onChangePin: React.PropTypes.func,
  onPrintPin: React.PropTypes.func,
  clearInput: React.PropTypes.func,
  pinNumber: React.PropTypes.string,
  hasOwnPincode: React.PropTypes.bool,
  isResendShown: React.PropTypes.bool,
  headerType: React.PropTypes.string,
  errorMessage: React.PropTypes.string,
};
