import React from 'react';
import logo from './logo.svg';
import './App.css';
import {RootRouter} from "core/Router";
import 'imports/startup/client/application';
import 'imports/startup/client';
import {Provider} from 'react-redux'
import {store} from 'core/store';

function App() {
  return (
    <Provider store={store}>
      <div data-reactroot>
          <RootRouter />
      </div>
    </Provider>
  );
}

export default App;
