import {createContainer} from 'imports/lib/react_createContainer';
import {Session} from 'core/session';
import {_} from 'imports/lib/helpers';
import {sprintf} from 'imports/lib/external/sprintf';
import Alert from './Alert';

export default createContainer(() => {
  
  function getAlertVisible() {
    //TODO: if socket not working set Session manually
    // if (typeof (Session.get('isAlertVisible')) == "undefined") {
    //   Session.set('isAlertVisible', true)
    // }
    return Session.get('isAlertVisible')
  }
  
  if (Session.get('qr')) {
    console.log("User was loggined. customer_id => ", Session.get('qr').customer_id );
    
    return {
      headerText_: 'User was logged',
      messageText: sprintf(_('Welcome %s %s'), Session.get('qr').first_name, Session.get('qr').last_name),
      buttonText_: 'OK',
      isAlertVisible: getAlertVisible(),
      onClick: () => {
        Session.set('isAlertVisible', false);
      }
    }
  }

  return {}
}, Alert)
