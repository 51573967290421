import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import {_ as i18n, C} from 'imports/lib/helpers';
import {sprintf} from 'imports/lib/external/sprintf';
import './style.scss';
import safeRender from 'imports/ui/decorators/safeRender'

class StoredSession extends Component {

  getPaymentItem(item, i, onSelect) {
    if (!item) return (
      <tr key={i}>
        <td/>
      </tr>
    )

    const text = sprintf(i18n('Pay off my %s payment\n%s remaining (out of %s)'),
      item.name,
      C(item.remaining_amount, '$'),
      C(item.requested_amount, '$'));

    const icon = (
      <img src={item.icon}/>
    )
    return (
      <tr key={i}>
        <td className="row">
          <Button className="button common" onMouseDown={() => onSelect(item)} text={text} icon={icon}
                  icon_placement="before"/>
        </td>
      </tr>
    )
  }

  render() {
    const {items, showNextButton, onSelect, onNext, onBack} = this.props;

    return (
      <table className="stored-session">
        <tbody>
        <tr>
          <td className="yellow row">
            <h1 className="upper" _>Please Note</h1>
          </td>
        </tr>
        <tr>
          <td className="yellow row">
            <span className="text" _>
              You have one or more incomplete payments pending. Please finish paying one of your payments below, first:
            </span>
          </td>
        </tr>
        {_.map(items, (item, i) => this.getPaymentItem(item, i, onSelect))}
        </tbody>
        <tfoot>
        <tr>
          <td>
            <Button id="back" className="left button goto-main" onMouseDown={onBack} text_="Back"/>
            {showNextButton ?
              <Button id="next" className="right button" onMouseDown={onNext} text_="Skip"/> :
              null}
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

export default StoredSession
