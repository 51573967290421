import {withTracker as oldCreateContainer} from 'core/withTracker';
import safeRender from 'imports/ui/decorators/safeRender';
import {Session} from 'core/session'
import IW from '../iw_api'

export const createContainer = function (handler, Component) {
  return oldCreateContainer((...args2) => {
    let result = null;
    try {
      result = handler(...args2)
    } catch (err) {
      debugger
      IW.handleError('Container: ' + err.message);
      console.error(err.stack);
    }
    return _.extend({}, result, {i18n: Session.get('i18n_version')})
  })(safeRender(Component))
};
