import React from 'react';
import IW from 'imports/iw_api';
import SimpExp from 'imports/lib/external/simpexp';

const NO_CHAR = 'ё';
const MIN_VALUE = Number.MIN_SAFE_INTEGER;
const MAX_VALUE = Number.MAX_SAFE_INTEGER;

window.__lastPaste = 0;
window.__paste = null

export default function (Component) {
  class KeyboardOnPress extends React.Component {
    constructor() {
      super();
      this.onPress = (e, key) => onPress(e, key, this.props)
    }

    render() {
      return <Component onPress={this.onPress} className={this.props.className} keys={this.props.keys}
                        lockedKeys={this.props.lockedKeys} style={this.props.style}/>
    }

    componentDidMount() {
      window.__paste = (text) => textToPresses(text, this);
      this.onKeyboardPressed = (e) => onKeyboardPressed(e, this.props);
      window.addEventListener('keydown', this.onKeyboardPressed);
    }

    componentDidUpdate(prevProps) {
      if (this.props.focus !== prevProps.focus) {
        this.props.onFocusChange(this.props.focus === 0 ? this.props.inputsCount - 1 : this.props.focus - 1);
      }
    }

    componentWillUnmount() {
      window.removeEventListener('keydown', this.onKeyboardPressed)
    }
  }

  KeyboardOnPress.propTypes = {
    focus: React.PropTypes.number,
    value: React.PropTypes.string,
    className: React.PropTypes.string,
    type: React.PropTypes.string,
    pattern: React.PropTypes.string,
    maxLength: React.PropTypes.number,
    min: React.PropTypes.number,
    max: React.PropTypes.number,
    isFlexible: React.PropTypes.bool,
    inputsCount: React.PropTypes.number,
    onFocusChange: React.PropTypes.func,
    onValueChange: React.PropTypes.func,
    style: React.PropTypes.object,
  };

  KeyboardOnPress.defaultProps = {
    focus: 0,
    className: "",
    value: '',
    type: 'text',
    pattern: '',
    maxLength: 1000,
    min: MIN_VALUE,
    max: MAX_VALUE,
    isFlexible: false,
    inputsCount: 1,
    onFocusChange: () => {
    },
    onValueChange: () => {
    },
  };

  return KeyboardOnPress
}

function onPress(e, key, props) {
  if (key === 'prev_field') {
    return props.onFocusChange(props.focus === 0 ? props.inputsCount - 1 : props.focus - 1);
  }

  if (key === 'next_field') {
    return props.onFocusChange(props.focus === props.inputsCount - 1 ? 0 : props.focus + 1);
  }

  const lastValue = props.value;
  const result = doAction(key, props)
  if (result != lastValue) {
    return props.onValueChange(result, props.focus);
  }
}

function doAction(key, props) {
  if (key === 'delete') {
    return doDelete(props);
  }

  if (key === 'clear') {
    return doClean(props);
  }

  if (key === 'space') {
    return doSpace(props);
  }

  return doKey(key, props);
}

function doDelete(props) {
  const val = props.value;
  let cut = 1; // amount of characters to chop off
  let keep = cut; // keep a static part (or the prefix)

  if (props.pattern) {
    const randexp = new SimpExp(props.pattern).gen(NO_CHAR);
    let flagManualInterrupt = false;

    // Check if the char we're erasing is a part of the pattern or not
    for (let i = val.length; i > 0; i--) {
      //console.log( val.slice(-cut), '??', randexp.slice(val.length-cut, val.length) )
      if (val.slice(-cut) == randexp.slice(val.length - cut, val.length) && !flagManualInterrupt) {
        cut++;
        keep++;

        //If current value not space, its means, that user start edit pattern
        //Flag canModifyPattern indicate, that user can edit pattern
        if (!!val.slice(-cut).trim() && props.canModifyPattern) {
          flagManualInterrupt = true;
        }
      } else { // it's ё
        keep--;
        break;
      }
    }
  }

  if (keep == cut && keep == val.length + 1) return val;
  return val.substring(0, val.length - cut)
}

function doClean(props) {
  console.log("doClean : ", props.pattern && !props.canModifyPattern ? IW.beginningByPattern(new SimpExp(props.pattern).gen(NO_CHAR), NO_CHAR) : '');
  return props.pattern && !props.canModifyPattern ? IW.beginningByPattern(new SimpExp(props.pattern).gen(NO_CHAR), NO_CHAR) : '';
}

function doSpace(props) {
  return props.value != '' &&
  props.value.slice(-1) != ' ' &&
  props.value.length < props.maxLength ? props.value + ' ' : props.value;
}

function doKey(key, props) {
  if (typeof props.value !== "string") return props.value;

  if (props.value.length >= props.maxLength) return props.value;

  let newValue = props.value + key;

  // min="10"
  if (props.min > MIN_VALUE && !isNaN(+newValue) && +newValue < props.min)
    return props.value;

  // max="5000"
  if (props.max < MAX_VALUE && !isNaN(+newValue) && +newValue > props.max)
    return props.value;

  //for field with dymanic value where length of value can be different (like amount)
  if (props.isFlexible) {
    if (!props.pattern) {
      return props.value;
    }

    const pattern = new RegExp(props.pattern);
    if (pattern.test(newValue)) {
      return newValue;
    }
  }

  if (!checkValidity(newValue, props)) {
    return props.value;
  }

  if (props.pattern) {
    const randexp = new SimpExp(props.pattern).gen(NO_CHAR);
    const randexpValue = newValue + randexp.slice(newValue.length);
    return randexpValue.split(NO_CHAR)[0]
  }

  return newValue
}


function checkValidity(newValue, props) {
  const $clone = $('<input>');

  if (props.pattern) {
    const randexp = new SimpExp(props.pattern).gen();
    const randexpValue = newValue + randexp.slice(newValue.length);
    if (props.value.length >= randexp.length)
      return false;

    $clone
      .attr('value', randexpValue)
      .attr('pattern', props.pattern);
    if (!$clone.get(0).checkValidity()) return false;
  }

  // type="email" and etc...
  if (props.type == 'email') {
    if (newValue.indexOf('@') > -1) { // '@' is in the field
      newValue += 'valid-domain.co'
    } else {
      newValue += '@valid-domain.co'
    }

    $clone
      .attr('value', newValue)
      .attr('type', 'email');

    if (!$clone.get(0).checkValidity()) return false;
  }

  return true;
}

function onKeyboardPressed(e, props) {
  e.preventDefault();
  let key = e.key
  if (!key) {
    if (e.keyIdentifier.match(/^U\+00[0-9A-F][0-9A-F]/)) {
      key = decodeURIComponent('%' + e.keyIdentifier.slice(-2));
    }

    if (e.keyIdentifier === 'U+0008') {
      key = 'Backspace'
    }

    if (e.keyIdentifier === 'U+007F') {
      key = 'Delete'
    }
  }
  if (!key) return;

  if (key === 'v' && e.ctrlKey && Date.now() - window.__lastPaste > 1000) {
    window.__paste(window.prompt('What type?'));
    window.__lastPaste = Date.now();
    return;
  }
  if (e.ctrlKey) return;

  if (key === 'Backspace') {
    return onPress(null, 'delete', props);
  }

  if (key === 'Delete') {
    return onPress(null, 'clear', props);
  }

  if (key === ' ') {
    return onPress(null, 'space', props);
  }

  if (key.toUpperCase().match(/^[0-9@A-Z+.-]$/)) {
    return onPress(null, key.toUpperCase(), props);
  }
}

function textToPresses(text, self) {
  function dummy() {
  }

  _.each(text, (ch, i) => {
    setTimeout(() => {
      onKeyboardPressed({
        key: ch,
        preventDefault: dummy
      }, self.props);
    }, 100 * (i + 1));
  })
}