import React from 'react';
import Button from "../button/Button";
import {usd} from 'imports/lib/helpers';

export default class Overlay extends React.Component {

  renderSimpleButton() {
    const {onClick, buttonText} = this.props;
    if (!(onClick && buttonText)) return;

    return <button className="upper" onMouseDown={onClick}>{buttonText}</button>
  }

  render() {
    const {messageText, onClick, onClick2, debitVariance, payoutAmount, isTimeout, show, time, type} = this.props;
    if (!show) {
      return null
    }

    if (type === 'overlay_timeout') {

      const exitButtonText = 'No, Exit to Main Menu';

      return (
        <div id="overlay">
          <div className="overlay-container">
            <div className="question" _>NEED MORE TIME?</div>
            <div className="timer-container">
              <div className="timer">{time}</div>
            </div>
            <div className="buttons-container">
              <Button className="button button-blue" style={{marginBottom: '30px'}} text_="Yes, I need more time"
                      onMouseDown={onClick}/>
              <Button className="button button-pink" text_={exitButtonText} onMouseDown={onClick2}/>
            </div>
          </div>
        </div>
      );
    }

    if (type === 'add_cash_to_balance_timeout') {
      return (
        <div id="overlay">
          <div className="overlay-container">
            {payoutAmount > 0 ? (
              <div className="question" _={[usd(payoutAmount), usd(debitVariance)]}>
                We will dispense %s in change and %s will be added to your account for future transactions
              </div>
            ):(
              <div className="question" _={[usd(debitVariance)]}>
                We will add %s to your account for future transactions
              </div>
            )}
            {isTimeout ?(
              <div className="buttons-container">
                <Button className="button button-pink" text_="No, I want to use another note" onMouseDown={onClick2}/>
              </div>
            ):(
              <div className="buttons-container">
                <Button className="button button-blue" style={{marginBottom: '30px'}} text_="OK, Finish Transaction"
                        onMouseDown={onClick}/>
                <Button className="button button-pink" text_="No, I want to use another note" onMouseDown={onClick2}/>
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <div id="overlay">
        <div className="message-text">{messageText}</div>
        {this.renderSimpleButton()}
      </div>
    )


  }
}

Overlay.propTypes = {
  messageText: React.PropTypes.string,
  type: React.PropTypes.string,
  onClick: React.PropTypes.func,
  onClick2: React.PropTypes.func,
  show: React.PropTypes.bool,
};

Overlay.defaultProps = {
  messageText: '',
  onClick: () => {
  },
  onClick2: () => {
  },
};
