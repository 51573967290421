/**
 * Created by vitaliy on 31.01.17.
 */
import {createContainer} from 'imports/lib/react_createContainer';
import EnterAmount from './EnterAmount';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';
import {N, S, createLazyExecution} from 'imports/lib/helpers'
import CONFIG from 'imports/config'

const MAX_AMOUNT = CONFIG.MONEYTRANSFER_MAX_AMOUNT;
const MIN_AMOUNT = CONFIG.MONEYTRANSFER_MIN_AMOUNT;

const updateAmounts = createLazyExecution(function () {
  IW.call('updateAmounts');
}, 100);

export default createContainer((params) => {
  // console.log('EnterAmountContainer | params ', params);
  const currencySymbol = '$';
  const maxAmount = Math.min(N(IW.appGetVal('original_max_amount')) || MAX_AMOUNT, N(IW.appGetVal('customer_limit_amount')));
  const amount = N(IW.appGetVal('original_amount')) || 0;

  const numpadLayout = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9],
    ['clear', 0, 'delete']
  ];

  function updateLocalAmounts() {
    const totalLimit = N(IW.appGetVal('original_max_amount'));
    const paid = Math.ceil(Math.min(totalLimit, N(IW.appGetVal('original_amount'))));
    const feeAmount = N(IW.appGetVal('fee'));
    const rate = N(IW.appGetVal('rate'));
    const requestedAmount = N(paid + feeAmount);
    const beneficiaryAmount = N(paid * rate);
    IW.appSetLocalVal('requested_amount', requestedAmount);
    IW.appSetLocalVal('beneficiary_amount', beneficiaryAmount);
  }

  function onPrintNumber(key) {
    if ( N(key) <= maxAmount) {
      IW.appSetVal('original_amount', N(key));
      updateLocalAmounts();
    }
  }

  function clearInput() {
    console.log('clear');
    IW.appSetVal('original_amount', 0);
    updateLocalAmounts();
  }

  return {
    currency: currencySymbol,
    amount: amount,
    numpadLayout,
    maxAmount: maxAmount,
    confirm : MIN_AMOUNT <= amount && amount <= maxAmount,
    onPrintNumber,
    clearInput,
    onNext: () => getPageManager().next(),
    onBack: () => getPageManager().back(),
  };

}, EnterAmount);
