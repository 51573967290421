import React, { Component } from 'react';

export default class InfoCard extends Component {
  render() {
    const { title, name, phone, email, birthDate } = this.props;

    return (<div className="info-card-container">
      <p className="info-card__title">{title}</p>
      <p>{name}</p>
      {phone? <p>{phone}</p>: null }
      {email ? <p className="info-card__email">{email.split('@')[0]}<span>{'@' + email.split('@')[1]}</span></p> : null}
      {birthDate? <p>{birthDate}</p>: null }
    </div>);
  }
}

InfoCard.propTypes = {
  title : React.PropTypes.string,
  name : React.PropTypes.string,
  phone : React.PropTypes.string,
  email : React.PropTypes.string,
  birthDate : React.PropTypes.string,
  emailBeforeDog : React.PropTypes.string,
  emailService : React.PropTypes.string
};

InfoCard.defaultProps = {
  title : '',
  name : '',
  phone : '',
  email : '',
  birthDate : '',
  emailBeforeDog : '',
  emailService : '',
}