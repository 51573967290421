import {createContainer} from 'imports/lib/react_createContainer';
import Layout from './Layout';
import {triggerMouseEvent} from 'imports/lib/helpers';
import Apps from 'imports/collections/apps';
import * as Timer from 'imports/api/timer';
import CONFIG from 'imports/config'

Session.set('ui_css', `#banner {
   background: url(/resources/img/partners/banners/MayaLogoWhite.png) no-repeat center;
}

#header .logo.partner {
  height: 144px !important;
    width: 100%;
    background: url(/resources/img/partners/banners/MayaLogoWhite.png) center no-repeat !important;
    background-size: 278px !important;
}`)

export default createContainer((props) => {
  const currAppGuid = Session.get('app_guid');
  const styles = {
    // Per-app CSS
    /*css: currAppGuid && Apps.findOne(currAppGuid) ? Apps.findOne(currAppGuid).style : '/!* no apps open *!/',*/
    css: '',
    // Per-kiosk CSS
    ui: Session.get('ui_css') ? Session.get('ui_css') : '/* null UI style */'
  };

  return {
    title: CONFIG.TITLE,
    testMode: Session.get('TEST_MODE'),
    params: props.params,
    content: props.content,
    header: props.header,
    styles: styles,
    i18n: Session.get('i18n_version'),
    onKeyboardPressed: function(e){
      e.preventDefault();

      let key = e.key
      if (!key) {
        if (e.keyIdentifier === 'Enter') {
          key = 'Enter'
        }

        if (e.keyIdentifier === 'Left') {
          key = 'ArrowLeft'
        }

        if (e.keyIdentifier === 'Right') {
          key = 'ArrowRight'
        }
      }

      if (key === 'Enter' || key === 'ArrowRight') {
        triggerMouseEvent($('#next')[0], 'mousedown');
      }

      if (key === 'Escape' || key === 'ArrowLeft') {
        triggerMouseEvent($('#back')[0] || $('#menu')[0], 'mousedown');
      }
    },
    sigue_locations: CONFIG.SIGUE_LOCATIONS && Session.get('TEST_MODE') &&
      FlowRouter.getRouteName() === 'Index' && Session.get('sigue_locations')
  };

}, Layout);
