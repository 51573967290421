import app_common from './app_common';
import IW from 'imports/iw_api';
import CONFIG from 'imports/config';

// ["shopping_cart", "pay", "shopping_cart_loading", "final", "email", "sent"]
export default _.extend({}, app_common, {
  index: function () {
    return 'balance_dispense'
  },

  balance_dispense: function*() {
    IW.callCashDispenser('status');
    IW.callBillAcceptor('status');
    yield 'loading app cash_dispenser_status_done';
    yield 'loading app coin_acceptor_status_done';

    if (!IW.appGetVal('load_dispense_done')){
      IW.call('loadDispense');
      yield 'loading app load_dispense_done';
    }

    IW.appSetLocalVal('can_dispense', !!IW.appGetVal('load_dispense_success'));

    yield 'loading app load_dispense_done';
    return {
      next: 'extract_money',
      cancel: 'exit'
    }
  },

  extract_money: function*() {
    Session.set('menu_disabled', true);
    IW.appSetLocalVal('extract_cash_done', false);
    IW.call('extractCash');
    yield 'loading app extract_cash_done';

    if (!IW.appGetVal('extract_cash_success')) {
      Session.set('menu_disabled', false);
      return 'internal_error';
    }

    IW.callBillAcceptor('dispense');
    IW.callCashDispenser('dispense');

    yield 'sleep app dispensing_done';

    Session.set('menu_disabled', false);
    return 'extract_money_success';
  },

  extract_money_success: function*() {
    // Popular products fetch for advertisement
    if (CONFIG.SHOW_POPULAR_PRODUCTS_ADVERTISEMENT) {
      IW.call('getPopularProductsId');
      yield 'loading app popular_products_id';
    }

    IW.printReceipt();
    return {
      cancel: 'exit',
      next: 'exit'
    }
  },

  internal_error: {
    cancel: 'exit',
    next: 'exit',
  },
});
