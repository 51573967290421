import React, {Component} from 'react';
import SliderContainer from 'imports/ui/shared/slider/SliderContainer';
import Loading from 'imports/ui/shared/loading/Loading';
import { SLIDE_ITEM_TYPES } from 'imports/lib/constants';

export default class Gifts extends Component {
  render() {
    const {links, filterItems, selectedId, onFilterChange} = this.props;

    return (
      <table className="giftcards">
        <tbody>
        <tr>
          <td>
            <div className="giftcards-filter">
              {_.map(filterItems, (item, k) => (
                <span key={k} data-selected={selectedId === k} onMouseDown={() => onFilterChange(k)}>
                  {item}
                </span>))}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            {links ? <SliderContainer type={SLIDE_ITEM_TYPES.ICON} apps={links} /> : <Loading />}
          </td>
        </tr>
        </tbody>
      </table>
    )
  }
}

Gifts.propTypes = {
  links: React.PropTypes.array,
  filterItems: React.PropTypes.array,
  selectedId: React.PropTypes.number,
  onFilterChange: React.PropTypes.func,
  onMount: React.PropTypes.func,
};

Gifts.defaultProps = {
  links: [[]],
  filterItems: [],
  selectedId: -1,
  onFilterChange: () => {}
};
