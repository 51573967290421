import {createContainer} from 'imports/lib/react_createContainer';
import EnterUserDataContainer from './EnterUserDataContainer';
import {KEYBOARDS, TRANSFER_INPUTS_RESULT} from 'imports/lib/constants';
import IW from 'imports/iw_api';
import {Session} from 'core/session';
import {convertObjectToArray, setValueToSession, createLazyExecution} from 'imports/lib/helpers';

var timeoutID = 0;

const getBillers = createLazyExecution(function (v) {
  if(!v) return;

  Session.set('in_data_searching', true);
  IW.appSetLocalVal('enter_user_data_loading', true);

  IW.call('getBillers', {biller_po_box: v}, function (__, result) {
    Session.set('in_data_searching', false);
    IW.appSetLocalVal('enter_user_data_loading', false);
    clearTimeout(timeoutID);
    Session.set('founded_billers', result || [])
  });

  timeoutID = setTimeout(() => {
    Session.set('in_data_searching', false)
    IW.appSetLocalVal('enter_user_data_loading', false);
  }, 15000)
}, 444);

export default createContainer((params) => {

  let fields = [
    {
      name: 'po_box',
      describe: 'P.O.Box of biller',
      describe_output: 'P.O.Box of biller',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SEARCH_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        required: false,
        minLength: 1,
        maxLength: 12
      }
    },
    {
      name: 'search_biller_input',
      describe: 'Biller Name',
      describe_output: 'Biller Name',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {}
    }
  ];

  return {
    fields,
    prefix: 'biller',
    onAfterInput(name, v){
      if(name === 'po_box'){
        getBillers(v);
      }
    }
  }
}, EnterUserDataContainer);