/**
 * Created by vitaliy on 13.03.17.
 */

export const USER_TRANSFER_TYPES = {
  SENDER: 'userTransferTypes/SENDER',
  RECEIVER: 'userTransferTypes/RECEIVER'
};

export const DIRECTS = {
  TOP: 'directs/TOP',
  DOWN: 'directs/DOWN',
  LEFT: 'directs/LEFT',
  RIGHT: 'directs/RIGHT'
};

export const KEYBOARDS = {
  TEXT: 'keyboards/TEXT',
  TEXT_NUMBER: 'keyboards/TEXT_NUMBER',
  EMAIL: 'keyboards/EMAIL',
  EMAIL_NUMBER: 'keyboards/EMAIL_NUMBER',
  NUMBER: 'keyboards/NUMBER',
  AMOUNT: 'keyboards/AMOUNT',
  DATEPICKER: 'keyboards/DATEPICKER',
  NONE: 'keyboards/NONE'
};

export const TRANSFER_INPUTS_RESULT = {
  SIMPLE_LIST: 'transferInputsResult/SIMPLE_LIST',
  SEARCH_LIST: 'transferInputsResult/SEARCH_LIST',
  MAP_SELECTOR: 'transferInputsResult/MAP_SELECTOR',
  SCROLL_LIST: 'transferInputsResult/SCROLL_LIST',
};

export const SLIDE_ITEM_TYPES = {
  ICON: 'slideItemTypes/ICON',
  CARD: 'slideItemTypes/CARD',
  CUSTOMER: 'sliderItemTypes/CUSTOMER',
  SIMPLE: 'sliderItemTypes/SIMPLE',
  LANGUAGE: 'slideItemTypes/LANGUAGE',
  CATEGORY: 'slideItemTypes/CATEGORY',
  BILLER: 'slideItemTypes/BILLER'
};

export const KEYBOARD_TEXT = [
  ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', 'delete'],
  [' ', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', ' '],
  [' ', ' ', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'space']
];

export const KEYBOARD_BILLER_ACCOUNT = [
  ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', 'delete'],
  [' ', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', '-'],
  [' ', ' ', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'space']
];

export const KEYBOARD_NUMBER = [
  ['1', '2', '3'],
  ['4', '5', '6'],
  ['7', '8', '9'],
  [' ', '0', ' ']
];

export const KEYBOARD_NUMBER_3ROW = [
  ['1', '2', '3', 'delete'],
  ['4', '5', '6'],
  ['7', '8', '9', '0#2']
];

export const KEYBOARD_AMOUNT_3ROW = [
  ['1', '2', '3', 'delete'],
  ['4', '5', '6', '.'],
  ['7', '8', '9', '0']
];

export const KEYBOARD_EMAIL = [
  ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
  ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', 'delete'],
  [' ', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', '-'],
  ['_', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', '.', '@', '+']
];

export const KEYBOARD_EMAIL_SUFFIX = [
  ['.com', '.net', '.org'],
  ['@yahoo.com'],
  ['@live.com'],
  ['@gmail.com']
];

export const KEYBOARD_EMAIL_SUFFIX_SECOND = [
  ['.com', '.net', '.org'],
  ['@gmail.com', '@live.com'],
  ['@yahoo.com'],
];

export const NUM_PAD = [
  [1, 2, 3],
  [4, 5, 6],
  [7, 8, 9],
  ['clear', 0, 'delete']
];

const commonSenderFields = [
  {
    name: 'first_name',
    describe: 'First name',
    describe_output: 'First name',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: true,
      minLength: 2,
      maxLength: 50,
      pattern: /^[a-zA-Z\s]+$/
    }
  },
  {
    name: 'middle_name',
    describe: 'Middle name(optional)',
    describe_output: 'Middle name(optional)',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: false,
      maxLength: 28,
      pattern: /^[a-zA-Z\s]+$/
    }
  },
  {
    name: 'last_name',
    describe: 'Last name',
    describe_output: 'Last name',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: true,
      minLength: 2,
      maxLength: 28,
      pattern: /^[a-zA-Z\s]+$/
    }
  },
  {
    name: 'address',
    describe: 'Street Address',
    describe_output: 'Address',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SEARCH_LIST,
    type_keyboard: KEYBOARDS.TEXT_NUMBER,
    hideFromList: true,
    output_scheme: [
      ['address1', 'address2'],
      ['address_city', ['address_state', 'address_zip']],
      ['address_country']
    ],
    validation: {
      required: true,
      pattern: ''
    }
  },
  {
    name: 'address1',
    describe: 'Street Address',
    describe_output: 'Address',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT_NUMBER,
    validation: {
      required: true,
      pattern: '',
      maxLength: 100
    },
    part_of: 'address'
  },
  {
    name: 'address2',
    describe: 'Unit or Suite #',
    describe_output: 'Unit or Suite #',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT_NUMBER,
    validation: {
      required: false,
      pattern: '',
      maxLength: 100
    },
    part_of: 'address'
  },
  {
    name: 'address_city',
    describe: 'City',
    describe_output: 'City',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: true,
      exists: "cities"
    },
    part_of: 'address'
  },
  {
    name: 'address_state',
    describe: 'State',
    describe_output: 'State',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: true,
      exists: "states"
    },
    part_of: 'address'
  },
  {
    name: 'address_zip',
    describe: 'ZIP',
    describe_output: 'ZIP',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT_NUMBER,
    validation: {
      required: false,
      minLength: 2,
      maxLength: 10
    },
    part_of: 'address'
  }

];

const sigueSenderFields = [
  {
    name: 'first_name',
    describe: 'First Name',
    describe_output: 'First Name',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: true,
      minLength: 2,
      maxLength: 50,
      pattern: /^[a-zA-Z\s]+$/
    }
  },
  {
    name: 'middle_name',
    describe: 'Middle Name',
    describe_output: 'Middle Name',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: false,
      maxLength: 28,
      pattern: /^[a-zA-Z\s]+$/
    }
  },
  {
    name: 'last_name',
    describe: 'First Last Name',
    describe_output: 'First Last Name',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: true,
      minLength: 2,
      maxLength: 28,
      pattern: /^[a-zA-Z\s]+$/
    }
  },
  {
    name: 'last_name2',
    describe: 'Second Last Name',
    describe_output: 'Second Last Name',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: false,
      minLength: 2,
      maxLength: 28,
      pattern: /^[a-zA-Z\s]+$/
    }
  },
  {
    name: 'home_phone',
    describe: 'Home Phone',
    describe_output: 'Home Phone',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.NUMBER,
    validation: {
      required: false,
      minLength: 10,
      maxLength: 11,
      pattern: /^[0-9]+$/
    }
  },
  {
    name: 'address',
    describe: 'Street Address',
    describe_output: 'Address',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SEARCH_LIST,
    type_keyboard: KEYBOARDS.TEXT_NUMBER,
    hideFromList: true,
    output_scheme: [
      ['address1', 'address2'],
      ['address_city', ['address_state', 'address_zip']],
      ['address_country']
    ],
    validation: {
      required: true,
      pattern: /^\d+\s/,
    }
  },
  {
    name: 'address1',
    describe: 'Street Address',
    describe_output: 'Address',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT_NUMBER,
    validation: {
      required: true,
      pattern: /^\d+\s/,
      maxLength: 60
    },
    part_of: 'address'
  },
  {
    name: 'address2',
    describe: 'Unit or Suite #',
    describe_output: 'Unit or Suite #',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT_NUMBER,
    validation: {
      required: false,
      pattern: '',
      maxLength: 100
    },
    part_of: 'address'
  },
  {
    name: 'address_city',
    describe: 'City',
    describe_output: 'City',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: true,
      minLength: 2,
      maxLength: 20
    },
    part_of: 'address'
  },
  {
    name: 'address_state',
    describe: 'State',
    describe_output: 'State',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: true,
      minLength: 2,
      maxLength: 20
    },
    part_of: 'address'
  },
  {
    name: 'address_zip',
    describe: 'ZIP',
    describe_output: 'ZIP',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT_NUMBER,
    validation: {
      required: true,
      minLength: 4,
      maxLength: 9
    },
    maxLength: 9,
    part_of: 'address'
  }
];

const commonSenderFieldsViamericas = [
  {
    name: 'first_name',
    describe: 'First name',
    describe_output: 'First name',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: true,
      minLength: 2,
      maxLength: 50,
      pattern: /^[a-zA-Z\s]+$/
    }
  },
  {
    name: 'middle_name',
    describe: 'Middle name(optional)',
    describe_output: 'Middle name(optional)',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: false,
      maxLength: 28,
      pattern: /^[a-zA-Z\s]+$/
    }
  },
  {
    name: 'last_name',
    describe: 'Last name',
    describe_output: 'Last name',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: true,
      minLength: 2,
      maxLength: 28,
      pattern: /^[a-zA-Z\s]+$/
    }
  },
  {
    name: 'second_last_name',
    describe: 'Second Last name(optional)',
    describe_output: 'Second Last name(optional)',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: false,
      minLength: 2,
      maxLength: 28,
      pattern: /^[a-zA-Z\s]+$/
    }
  },
  {
    name: 'address',
    describe: 'Street Address',
    describe_output: 'Address',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SEARCH_LIST,
    type_keyboard: KEYBOARDS.TEXT_NUMBER,
    hideFromList: true,
    output_scheme: [
      ['address1', 'address2'],
      ['address_city', ['address_state', 'address_zip']],
      ['address_country']
    ],
    validation: {
      required: true,
      pattern: ''
    }
  },
  {
    name: 'address1',
    describe: 'Street Address',
    describe_output: 'Address',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT_NUMBER,
    validation: {
      required: true,
      pattern: '',
      maxLength: 100
    },
    part_of: 'address'
  },
  {
    name: 'address2',
    describe: 'Unit or Suite #',
    describe_output: 'Unit or Suite #',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT_NUMBER,
    validation: {
      required: false,
      pattern: '',
      maxLength: 100
    },
    part_of: 'address'
  },
  {
    name: 'address_city',
    describe: 'City',
    describe_output: 'City',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: true,
      exists: "cities"
    },
    part_of: 'address'
  },
  {
    name: 'address_state',
    describe: 'State',
    describe_output: 'State',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: true,
      exists: "states"
    },
    part_of: 'address'
  },
  {
    name: 'address_zip',
    describe: 'ZIP',
    describe_output: 'ZIP',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT_NUMBER,
    upper_case: true,
    validation: {
      required: true,
      minLength: 2,
      maxLength: 10
    },
    part_of: 'address'
  }

];

const commonRecipientFields = [
  {
    name: 'first_name',
    describe: 'First name',
    describe_output: 'First name',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: true,
      minLength: 2,
      maxLength: 20,
      pattern: /^[a-zA-Z\s]+$/
    },
    maxLength: 20
  },
  {
    name: 'middle_name',
    describe: 'Middle name(optional)',
    describe_output: 'Middle name(optional)',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: false,
      maxLength: 20,
      pattern: /^[a-zA-Z\s]+$/
    },
    maxLength: 20
  },
  {
    name: 'last_name',
    describe: 'Last name',
    describe_output: 'Last name',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: true,
      minLength: 2,
      maxLength: 20,
      pattern: /^[a-zA-Z\s]+$/
    },
    maxLength: 20
  },
  {
    name: 'mother_name',
    describe: 'Mother\'s maiden name(optional)',
    describe_output: 'Mother\'s maiden name(optional)',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: false,
      maxLength: 20,
      pattern: /^[a-zA-Z\s]+$/
    },
    maxLength: 20
  }
];

const sigueRecipientFields = [
  {
    name: 'first_name',
    describe: 'First Name',
    describe_output: 'First Name',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: true,
      minLength: 2,
      maxLength: 20,
      pattern: /^[a-zA-Z\s]+$/
    },
    maxLength: 20
  },
  {
    name: 'middle_name',
    describe: 'Middle Name',
    describe_output: 'Middle Name',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: false,
      maxLength: 20,
      pattern: /^[a-zA-Z\s]+$/
    },
    maxLength: 20
  },
  {
    name: 'last_name',
    describe: 'First Last Name',
    describe_output: 'First Last Name',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: true,
      minLength: 2,
      maxLength: 20,
      pattern: /^[a-zA-Z\s]+$/
    },
    maxLength: 20
  },
  {
    name: 'mother_name',
    describe: 'Second Last Name',
    describe_output: 'Second Last Name',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: false,
      maxLength: 20,
      pattern: /^[a-zA-Z\s]+$/
    },
    maxLength: 20
  },
  {
    name: 'currency',
    describe: 'Currency',
    describe_output: 'Currency',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SCROLL_LIST,
    type_keyboard: KEYBOARDS.NONE,
    validation: {
      required: true,
      exists: "currency",
    }
  },

  {
    name: 'home_phone',
    describe: 'Home Phone (optional)',
    describe_output: 'Home Phone',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.NUMBER,
    validation: {
      required: false,
      minLength: 10,
      maxLength: 14,
      pattern: /^[0-9]+$/
    }
  },
  {
    name: 'address1',
    describe: 'Address (optional)',
    describe_output: 'Address',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT_NUMBER,
    validation: {
      maxLength: 100,
      required: false,
    }
  },
];

export const MONEYTRANSFER_USER_AVAILABLE_FIELDS = {
  sender: [...commonSenderFields],
  sender_sigue: [...sigueSenderFields],
  senderViamericas: [...commonSenderFieldsViamericas],
  recipient: [...commonRecipientFields],
  recipient_sigue: [...sigueRecipientFields],
  ofac: [
    {
      name: 'birth_day',
      describe: 'Date of birth',
      describe_output: 'Date of birth',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.DATEPICKER,
      disable_clear: true,
      validation: {
        required: true
      }
    },
  ],
  bank: [
    {
      name: 'account',
      describe: 'Bank account',
      describe_output: 'Bank account',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      upper_case: true,
      validation: {
        required: true,
        minLength: 4,
        maxLength: 35
      }
    },
    {
      name: 'routing_number',
      describe: 'Bank Routing Number',
      describe_output: 'Bank Routing Number',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      upper_case: true,
      validation: {
        required: true,
        minLength: 11,
        maxLength: 11
      }
    }
  ],
  recipient_city: [
    {
      name: 'address_city_name',
      describe: 'City',
      describe_output: 'City',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SEARCH_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        exists: "cities",
        required: true
      }
    }
  ],
  recipient_city_sigue: [
    {
      name: 'location',
      describe: 'City/State/Country',
      describe_output: 'City/State/Country',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SEARCH_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      hideFromList: true,
      output_scheme: [
        ['address_city', 'address_state', 'address_country']
      ],
      validation: {
        required: true,
        exists: 'location',
        pattern: ''
      }
    },
  ],
  recipient_town: [
    {
      name: 'address_town',
      describe: 'Town',
      describe_output: 'Town',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SEARCH_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        required: true
      }
    }
  ],
  recipient_email: [
    {
      name: 'email',
      describe: 'Email address',
      describe_output: 'Email address',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.EMAIL,
      validation: {
        required: true,
        minLength: 2,
        isEmail: true,
        maxLength: 64
      }
    }
  ]
};

export const DATEPICKER_DEFAULT = {
  startYear: 1930,
  year: 59,
  month: 0,
  day: 0
};

export const SENT_PAGE_TEXTS = {
  support: {
    thankYouText_: "Thank you!",
    message_: "We will call you as soon as possible.\nOur support number is:",
    type: 'support'
  },
  support_sigue: {
    thankYouText_: "Thank you!",
    message_: "We will call you as soon as possible.\nOur support number is:",
    message2_: 'For Sigue Customer Service contact:',
    value2: '+1 (800) 385-3316',
    type: 'support'
  },
  common: {
    thankYouText_: "Thank you!",
    message_: "Please take your receipt"
  },
  email: {
    thankYouText_: "Thank you!",
    message_: "Your receipt has been emailed to:",
    type: 'email'
  },
  payment_canceled: {
    thankYouText_: "Thank you!",
    message_: "Your payment has been canceled",
  }
};

export const MAX_DOTS_SLIDER = 4;

export const PAGINATOR_SLIDER_MODE = {
  COMMON: 'paginatorSliderMode/COMMON',
  FOUR_DOTS: 'paginatorSliderMode/FOUR_DOTS',
  LEFT_RIGHT_ARROWS: 'paginatorSliderMode/LEFT_RIGHT_ARROWS'
};

export const TRNASPYMENT_DEFAULT_FIELDS = [{
  keyFromRequired: 'PHONE',
  keyFromIW: 'phone_number',
  listenIW: true,
  formatterFromIW: (item, format) => {
    return item.slice((-1) * format.length);
  }
}, {
  keyFromRequired: 'CELL',
  keyFromIW: 'phone_number',
  listenIW: true,
  formatterFromIW: (item, format) => {
    return item.slice((-1) * format.length);
  }
}];

export const COUNTRY_FLAGS_BY_ISO2 = {
  MX: '/resources/images/flags/_maya/es_MX.png',
  GB: '/resources/images/flags/_maya/en_GB.png',
  US: '/resources/images/flags/_maya/en_US.png',
  DE: '/resources/images/flags/_maya/de_DE.png',
  MA: '/resources/images/flags/_maya/fr_MA.png',
  RU: '/resources/images/flags/_maya/ru_RU.png',
  CN: '/resources/images/flags/_maya/zh_CN.png',
  TW: '/resources/images/flags/_maya/zh_TW.png'
};

export const ATM_ACCOUNTS = [{
  id: 1,
  iconUrl: "/resources/images/atm/ic_savings_account.png",
  text: "Checking Account"
}, {
  id: 2,
  iconUrl: "/resources/images/atm/ic_checking_account.png",
  text: "Savings Account"
}, {
  id: 3,
  iconUrl: "/resources/images/atm/ic_custom_account.png",
  text: "Credit Account"
}, {
  id: 4,
  iconUrl: "/resources/images/atm/ic_custom_account.png",
  text: "Referrer Account"
}, {
  id: 5,
  iconUrl: "/resources/images/atm/ic_custom_account.png",
  text: "Prize"
}, {
  id: 6,
  iconUrl: "/resources/images/atm/ic_custom_account.png",
  text: "Payer Account"
}];

export const PAY_METHODS = {
  cash: {
    name: 'Insert Cash',
    alias: 'payment_cash',
    devices: []
  },
  card: {
    name: 'Swipe Card',
    alias: 'payment_card',
    devices: ['card_reader', 'epp', 'terminal_init']
  }
};

export const MONEY_TRANSFER_PAYMENT_MODES = {
  delivery: {
    name: "delivery",
    fullName: "DELIVERY",
    fieldName: "payer_name"
  },
  cash_pickup: {
    name: "cash",
    fullName: "CASH PICK UP",
    fieldName: "payer_name"
  },
  bank_deposit: {
    name: "deposit",
    fullName: "BANK DEPOSIT",
    fieldName: "bank_name"
  }
};

export const INTERNAL_DATA_ERRORS = {
  "payers_not_found": "%s is not available in %s, please select another city or another transfer method",
};

export const VIAMERICA_INTERNAL_ERRORS = {
  'ZIP not found': {field: 'sender_address_zip', index: 9, message: 'ZIP is not valid'},
  'The min length for ZIP is 5': {field: 'sender_address_zip', index: 9},
  'The ZIP must only contains numbers': {field: 'sender_address_zip', index: 9},
  'The max length for ZIP is 7': {field: 'sender_address_zip', index: 9},
  'City is not correct': {field: 'sender_address_city', index: 6}
};

export const LOGIN_FIELDS = [
  'first_name',
  'middle_name',
  'last_name',
  'address',
  'address1',
  'address2',
  'address_country',
  'address_city',
  'address_state',
  'address_zip'
];

export const SIGUE_CALCULATE_ERRORS = {
  0: {
    title: 'Undefined',
    description: 'There is not fee nor exchange rate',
  },
  1000: {
    title: 'Exchange_rate_error',
    description: 'There is not exchange rate',
  },
  1001: {
    title: 'Fee_error',
    description: 'There is not fee',
  },
  2: {
    title: 'Fee_particular',
    description: 'There is not a particular fee for this specific destination payment agent',
  },
  1002: {
    title: 'Promotion_code_error',
    description: 'Invalid promotion code',
  },
  1003: {
    title: 'Exchange_transaction',
    description: 'There is not an available exchange rate',
  },
  1004: {
    title: 'Error_len_amounts',
    description: 'Amounts are not allowed to be send',
  },
};