import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import {FlowRouter} from 'core/Router';
import IW from 'imports/iw_api';
import safeRender from 'imports/ui/decorators/safeRender';

class GetApp extends Component {
  render() {
    return (
      <div className="wrapper-index">
        <div className="body-container">
          <div className="get-app_wrapper">

            <img className="get-app_logo" src="/resources/img/logos/maya_app_logo.png" />

            <p className="get-app_title" _>{'Scan to download our app:'}</p>

            <div className="get-app_link_container">
              <img width={300} src="/resources/icons/getapp/maya_app_qr_code.png" />
              <p className="get-app_link">{'maya.tech/app'}</p>
            </div>

            <p className="get-app_title" _>{'We can also send a link to your phone'}</p>
          </div>
        </div>
        <div className="footer-container">
          <div className="footer-container_col index-footer_col_left">
            <Button id="back" className="button button-pink" onMouseDown={() => IW.gotoIndex()} text_="Exit"/>
          </div>
          <div className="footer-container_col index-footer_col_right ">
            <Button id="next" className="button button-blue" onMouseDown={() => FlowRouter.go('SendAppSms')} text_="Send me a link"/>
          </div>
        </div>
      </div>
    )
  }
}

GetApp.propTypes = {};

GetApp.defaultProps = {};

export default safeRender(GetApp);
