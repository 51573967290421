import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import {_, capitalize} from 'imports/lib/helpers';
import VerticalSlider from 'imports/ui/shared/vertical_slider/VerticalSliderContainer';
import * as u from 'underscore';
import Image from 'imports/ui/shared/image/Image';

export default class SendMoney extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recipientMode: true
    };

    this.lastPoint = {x: 0, y: 0, id: null};
    this.onTapIcon = this.onTapIcon.bind(this);
  }

  renderProduct(p, key) {
    const {product_name, icon} = p;
    const {launchApplication} = this.props;
    const action = () => launchApplication(p);

    if(u.isEmpty(p)){
      return <div className="payment-item_container"> </div>
    }

    return (
      <div key={key} onMouseUp={(event) => this.onTapIcon(event, action, key)}
           onMouseDown={(event) => this.onTapIcon(event, action, key)} className="payment-item_container">
        <div className="payment-item_product-full">
          <img className="payment-item_product-image_image" src={icon}/>
          <div className="payment-item_product-desc">
            <span className="payment-item_product-product_name">{product_name}</span>
          </div>
        </div>
      </div>
    )
  }

  renderSender(sender, key) {
    const senderFullName = `${sender.first_name} ${sender.last_name}`;

    if (u.isEmpty(sender)) {
      return <div className="payment-item_container-send-money"></div>
    }

    return (
      <div className="payment-item_container-send-money" key={key}>
        <div className="sender-name">
          <div>{senderFullName}</div>
          <div className="payment-item_product-desc_time">{sender.from}</div>
        </div>
      </div>
    )
  }

  renderRecipients(recipient, key) {

    if (u.isEmpty(recipient)) {
      return <div className="payment-item_container-send-money"></div>
    }

    const {launchApplicationFromRecipient} = this.props;
    const sendTo = `${_('Send money to')} ${recipient.recipient_first_name} ${recipient.recipient_middle_name} ${recipient.recipient_last_name}`;
    const from = `from ${capitalize(recipient.recipient_address_city_name || recipient.recipient_city_name)}`;
    const action = () => launchApplicationFromRecipient(recipient);
    let paymentModeName = '';

    if (recipient.recipient_payment_mode_type === 'cash_pickup') {
      paymentModeName = 'cash';
    } else if (recipient.recipient_payment_mode_type === 'bank_deposit') {
      paymentModeName = 'deposit';
    } else if (recipient.recipient_payment_mode_type === 'delivery') {
      paymentModeName = 'delivery';
    }

    const payerIcon = '/resources/icons/payout/' + [paymentModeName,
        recipient.recipient_address_country.toLowerCase(), (recipient.payer_name.trim()).toLowerCase()].join('/') +
      '.png';
    const defaultIcon = '/resources/icons/payout/' + paymentModeName + '/default.png';

    return (
      <div className="payment-item_container-send-money" key={key}>
        <div className="recipient-block">
          <div onMouseUp={(event) => this.onTapIcon(event, action, key)}
               onMouseDown={(event) => this.onTapIcon(event, action, key)} key={key}
               className="payment-item_container">
            <div className="payment-item_product-full">
              <img className="payment-item_product-image_image" src={recipient.icon}/>
              <div className="payment-item_product-desc">
                <span className="payment-item_product-product_name">{sendTo}</span>
                <span className="payment-item_product-desc_time">{from}</span>
              </div>
              <Image className="payment-item_product-image_image" src={payerIcon} default={defaultIcon}/>
            </div>
          </div>
        </div>
      </div>
    )
  }

  switchMode() {
    const {recipientMode} = this.state;

    this.setState({recipientMode: !recipientMode})
  }

  onTapIcon(event, next, key) {
    // Ignore mousedown for slider items
    if ((event.type === 'mousedown') && $('.iosVerticalSlider').data('iosSliderVertical')) {
      this.lastPoint.id = key;
      this.lastPoint.x = event.pageX;
      this.lastPoint.y = event.pageY;
      return;
    }

    if (this.lastPoint.id !== key ||
      Math.max(Math.abs(this.lastPoint.x - event.pageX), Math.abs(this.lastPoint.y - event.pageY)) > 20) return;

    next();
  }

  renderProductItems() {
    const {products} = this.props;

    return products.map((item, index) => {
      return this.renderProduct(item, index)
    })
  }

  renderRecipientsItems() {
    const {recipientsBySender} = this.props;

    return recipientsBySender.map((item, index) => {
      if (item.isSender) {
        return this.renderSender(item, index);
      }

      return this.renderRecipients(item, index);
    })
  }

  render() {
    const {products, recipientsBySender, totalCountRecipients} = this.props;
    const {recipientMode} = this.state;

    let renderList = (
      <div className="payment-item_container">
        <span className="payment-item_product-not" _>No products yet</span>
      </div>
    );

    if (products.length) {
      renderList = <VerticalSlider withArrows={true} items={this.renderProductItems()}/>
    }

    if (recipientsBySender.length && recipientMode) {
      renderList = <VerticalSlider showScrollBar={recipientsBySender.length >= 5} withArrows={true} items={this.renderRecipientsItems()}/>;
    }

    return (
      <div className="profile_payments_container">
        <div className="profile_list-header_container">
          <img className="profile_list-header_icon" src="/themes/mayafs/images/icons/ic_money.png"/>
          <span className="profile_list-header_title" _>Send money</span>
        </div>
        <div id="payment-list" className="profile_payments-list_container">
          {renderList}
        </div>

        {recipientsBySender.length ?
          <Button id="next" className="menu-button" onMouseDown={ () => this.switchMode() }
                  text_={recipientMode ? 'Create new recipient' : 'Select recipient'}/> : null}
      </div>
    );
  }
}
