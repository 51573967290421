import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import {Session} from 'core/session';
import IW from 'imports/iw_api';
import RangeAmount from './RangeAmount';
import getPageManager from 'imports/lib/page_manager'
import {N} from 'imports/lib/helpers'

export default createContainer((params) => {

  const minAmount = IW.appGetVal('min_amount') || 0;
  const maxAmount = Math.min(N(IW.appGetVal('customer_limit_amount'), IW.appGetVal('max_amount') || 1000));

  function getPricePattern() {
    return '^\\d{1,4}(\\.\\d{1,2}|\\.)?$';
  }

  function getOriginalAmount() {
    return N(IW.appGetVal('original_amount'))
  }
  
  const numpadLayout = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9],
    ['.', 0, 'delete']
  ];

  function isValid(v) {
    return v > 0 && v <= maxAmount;
  }

  function onClickNext(e) {
    e.preventDefault();
    // Validation
    if (!isValid(getOriginalAmount())) return;
    getPageManager().next();
  }

  function onClickBack(e) {
    e.preventDefault();
    getPageManager().back();
  }

  function onChangeValue(v) {
    if (!isValid(N(v)) && v !== '') return;
    Session.set('amount', v);
    IW.appSetVal('original_amount', N(v));
  }

  function clearInput() {
    Session.set('amount', '');
    IW.appSetVal('original_amount', N(''));
  }

  function getValue() {
    return Session.get('amount');
  }

  return {
    pricePattern: getPricePattern(),
    originalAmount: getOriginalAmount(),
    numPadLayout: numpadLayout,
    valid: getOriginalAmount() >= minAmount && isValid(getOriginalAmount()),
    value: getValue(),
    maxLength: 9,
    keyboardPattern: '^\\d{1,4}(\\.\\d{1,2}|\\.)?$',
    isFlexible: true,
    onClickNext: onClickNext,
    onClickBack: onClickBack,
    onChangeValue: onChangeValue,
    clearInput,
    focus : 'original_amount',
    minAmount,
    maxAmount,
  };

}, RangeAmount);
