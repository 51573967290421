import IW from 'imports/iw_api';
import {Session} from "core/session";
import Apps from "imports/collections/apps";
import CONFIG from "imports/config";
import {_ as translate, N} from "./helpers";
import {getDevice} from "imports/api/device";
import appWorkflow from "imports/apps_workflow";
import {goTo as goToPage, goTo as goToPageManager} from "./page_manager";
import {FlowRouter} from 'core/Router';
import Products from "../collections/products";

export default function () {

  function initSession(f){
    return function (...args) {
      if (typeof f === 'function') {
        try {
          Session.set('session_active', true);
          f.apply(this, args);
        } catch (err) {
          Session.set('session_active', false);
          console.error('Func', f.name, 'throws error:', err);
        }
      }
    };
  }

  return {
    application: initSession((appGuid, options, socketEmit) => {
      if(!appWorkflow[appGuid]) return console.error(`Application ${appGuid} not found`);

      console.log('Launched app ', appGuid, options);
      const DEVICE = getDevice();

      //Session.set('layout', 'app');
      if (options && options.page)
        IW.appSetLocalVal('page', 'loading'); // show this before we get the real page from the server

      // Clear the focus field #id just in case
      Session.set('focus', '');

      const record = Apps.findOne(appGuid);

      Session.set('title', ((record && record.title) ? record.title : options.title));

      Session.set('app_guid', appGuid);
      Session.set('app', {});

      const sess_data = {
        app_guid: appGuid,
        locale: FlowRouter.getParam('language') || CONFIG.DEFAULT_LANGUAGE,
        device_guid: DEVICE.DEVICE_GUID,
        auth_token: DEVICE.AUTH_TOKEN,
      };

      const sess_opt = {
        version: CONFIG.VERSION,
        nonce: +new Date(),
        utc_offset: -new Date().getTimezoneOffset(),
      };

      if (options) {
        if (options.title) Session.set('title', options.title); // page header title
        if (options.icon) Session.set('icon', options.icon);   // page header icon
        if (options.app_icon) Session.set('app_icon', options.app_icon);

        IW.appSetVal('feed', options.feed || null, '_private');

        if (options.product_id) {
          const products_id = N(options.product_id);

          sess_opt.product_id = products_id;
          Session.set('product_id', products_id);

          /* Initializing product in clamshell */
          IW.initProduct(products_id)
        }

        if (options.page) sess_opt.page = options.page;
        if (options.customer_id) sess_opt.customer_id = options.customer_id;
        if (options.session_source_guid) sess_opt.session_source_guid = options.session_source_guid;
        if (options.refund_payment_guid) sess_opt.refund_payment_guid = options.refund_payment_guid;
        if (options.session_resumed) sess_opt.session_resumed = options.session_resumed;
        if (options.logged) sess_opt.logged = options.logged;
      }

      Session.set('session_create_nonce', sess_opt.nonce);
      Session.set('session_guid', '');

      console.time('CREATE SESSION');
      socketEmit('/s/create', sess_data, sess_opt);
    }),
    login: initSession((lang) => {
      console.log('Launched login');
      Session.set('entry_point_path', FlowRouter.current().path);

      let appGuid = 'app_profile_common';
      let startPage = appWorkflow[appGuid] && appWorkflow[appGuid].index ?
        'index' :
        null;
      let language = lang || FlowRouter.getParam('language') || CONFIG.DEFAULT_LANGUAGE;

      Session.set('title', translate('Login', appGuid));
      Session.set('login', {});

      goToPageManager(startPage, appGuid, '0', language);

      Session.set('session_guid', 'login_stub_guid');
    }),
    login_in_application: initSession(() => {
      console.log('Launched login in application');

      Session.set('login', {});

      const phoneNumber = IW.appGetVal('phone_number') || "";
      IW.loginSetVal('phone_number', phoneNumber);
    }),
    resume_session: initSession((data) => {
      const new_session_guid = data.session_guid;
      const previous_session_data = data.session_data;
      const session_locale = data.session_locale;
      const session_app_guid = data.session_app_guid;

      if (!(new_session_guid && previous_session_data && session_locale && session_app_guid)) return;
      console.log("Resume session with - >", {
        new_session_guid: new_session_guid,
        previous_session_data: previous_session_data,
        session_locale: session_locale,
        session_app_guid: session_app_guid
      });

      const product = Products.findOne({product_id: previous_session_data.product_id});

      Session.set('title', previous_session_data.name);
      Session.set('icon', previous_session_data.icon);
      if (product) {
        Session.set('title', Session.get('title') || product.title);
        Session.set('icon', Session.get('icon') || product.icon);
      }

      if (!_.isEmpty(previous_session_data.shopping_cart)) {
        Session.set('title', 'Shopping Cart');
      }

      Session.set('session_guid', new_session_guid);
      Session.set('app_guid', session_app_guid);
      Session.set('app', previous_session_data);
      Session.set('last_amount', N(previous_session_data.amount));

      IW.appSetLocalVal('session_resumed', true);
      goToPage('payment_status', session_app_guid, String(previous_session_data.product_id), session_locale);
    })
  };
}
