import {createContainer} from 'imports/lib/react_createContainer';
import TemplateDisplayActions from './TemplateDisplayActions';
import {C, getATMAccount, i18n, N, usd, capitalizeSentence} from 'imports/lib/helpers';
import {sprintf} from 'imports/lib/external/sprintf';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';
import CONFIG from 'imports/config';

const c = (v) => C(v, '$');

export default createContainer((params) => {
  const currentKey = IW.appGetVal('pad_key');
  IW.appSetLocalVal('pad_key', '');

  const images = _.extend({}, imagesCommon, CONFIG.LIVE_IMAGES_GENMEGA ? imagesGenmega : {});

  function handlePadKey() {
    const keyAction = {
      '#': [onBack, onNo],
      ENTER: [onNext, onPrint, onChangeAmount, onActions, onImageClick, onMenu, onYes],
    };

    if (currentKey === 'CANCEL' && !currentPage.cancelDisabled && !currentPage.isSpinner) {
      return getPageManager().cancel();
    }
    const action = _.find(keyAction[currentKey], (h) => _.contains(currentPage, h));
    if (!action) return;
    action();
  }

  function getExtractionMessage() {
    return IW.appGetVal('dispensed_amount') < IW.appGetVal('dispensable_amount') ?
      i18n('Sorry, we could not dispense full amount.') + ' ' + i18n('Check receipt for details or call support') :
      '';
  }

  const onBack = () => getPageManager().back();
  const onActions = () => getPageManager().next();
  const onCancel = () => getPageManager().cancel();
  const onCancelCenter = () => getPageManager().cancel();
  const onNo = () => getPageManager().cancel();
  const onPrint = () => getPageManager().print();
  const onMenu = () => getPageManager().next();
  const onMenuRight = () => getPageManager().next();
  const onImageClick = () => getPageManager().next();
  const onNext = () => getPageManager().next();
  const onYes = () => getPageManager().next();
  const onChangeAmount = () => getPageManager().next();
  const onLogin = () => getPageManager().login();

  function getPage(page) {
    if (page === 'insert_card') return {
      smallText: i18n('Welcome to ' + CONFIG.PARTNER_NAME + ' ATM'),
      bigText: IW.appGetVal('card_insert_error') ?
        i18n('We can\'t read your card') :
        i18n('Insert your card'),
      srcImg: images.ic_insert_card,
      isSpinner: false,
      onCancelCenter,
    };

    if (page === 'insert_card_reload') return {
      smallText: i18n('Welcome to ' + CONFIG.PARTNER_NAME),
      bigText: IW.appGetVal('card_insert_error') ?
        i18n('We can\'t read your card') :
        i18n('Insert your card'),
      srcImg: images.ic_insert_card,
      isSpinner: false,
      onCancelCenter
    };

    if (page === 'coming_soon') return {
      smallText: '',
      bigText: i18n('Coming soon'),
      srcImg: images.maya_logo,
      isSpinner: false,
      cancelDisabled: true,
    };

    if (page === 'transfer_final') return {
      smallText: '',
      bigText: i18n('Money will be transferred'),
      srcImg: images.ic_transfered,
      isSpinner: true,
    };

    if (page === 'transfer_status') return {
      smallText: i18n('Please take your card'),
      bigText: i18n`Transferred ${c(IW.appGetVal('transferred_amount'))}`,
      srcImg: images.ic_transfered,
      additional: {
        left: i18n(getATMAccount(IW.appGetVal('selected_account'), 'text')),
        right: i18n(getATMAccount(IW.appGetVal('destination_account'), 'text'))
      },
      isSpinner: true
    };

    if (page === 'return_card') return {
      smallText: '',
      bigText: i18n('Take your card'),
      srcImg: images.ic_take_card,
      isSpinner: true,
    };

    if (page === 'return_card_continue') return {
      smallText: '',
      bigText: i18n('Take your card to continue'),
      srcImg: images.ic_take_card,
      isSpinner: true,
    };

    if (page === 'return_card_extraction') return {
      smallText: i18n('Take your card'),
      bigText: i18n('Cash will be dispensed'),
      srcImg: images.ic_take_card,
      isSpinner: true,
    };

    if (page === 'return_card_balance') return {
      smallText: i18n('Take your card'),
      bigText: i18n('Balance will be printed'),
      srcImg: images.ic_take_card,
      isSpinner: true,
    };

    if (page === 'return_card_transaction_error') return {
      smallText: i18n('We can not process the transaction'),
      bigText: i18n(IW.appGetVal('transaction_status')),
      srcImg: images.ic_card_error,
      isSpinner: false,
      onCancelCenter,
    };

    if (page === 'return_card_internal_error') return {
      smallText: i18n('There\'s a temporary error'),
      bigText: i18n('Please try again later'),
      srcImg: images.ic_problem,
      isSpinner: false,
      onCancelCenter,
    };

    if (page === 'custom_error') {
      const opt = {
        smallText: i18n(capitalizeSentence(IW.appGetVal('custom_error_small'))),
        bigText: i18n(capitalizeSentence(IW.appGetVal('custom_error_big'))),
        srcImg: images[IW.appGetVal('custom_error_image') || 'ic_problem'],
        isSpinner: IW.appGetVal('custom_error_spinner'),
      }
      if (IW.appGetVal('custom_error_allow_back')) opt.onBack = onBack;
      if (IW.appGetVal('custom_error_allow_menu')) opt.onMenu = onMenu;
      return opt;
    }

    if (page === 'return_card_emv_error') {
      let smallText = '';
      if (IW.appGetVal('emv_error_status') === 'blocked_aid') {
        smallText = 'Application ID is blocked';
      }
      if (IW.appGetVal('emv_error_status') === 'blocked_card') {
        smallText = i18n('Your card is blocked');
      }
      if (IW.appGetVal('emv_error_status') === 'not_found_aid') {
        smallText = 'Application ID is not found';
      }
      if (IW.appGetVal('emv_error_status') === 'chip_error') {
        smallText = 'Chip error';
      }
      if (IW.appGetVal('emv_error_status') === 'card_expired') {
        smallText = i18n('Your card is expired');
      }
      if (IW.appGetVal('emv_error_status') === 'unknown') {
        smallText = i18n('Your card was not processed');
      }
      return {
        smallText: smallText,
        bigText: i18n('Take your card'),
        srcImg: images.ic_take_card,
        isSpinner: true,
      };
    }

    if (page === 'extract_money') return {
      smallText: i18n`Dispensing ${usd(IW.appGetVal('extraction_amount'))}`,
      bigText: i18n('Please take your cash'),
      srcImg: images.ic_cash,
      isSpinner: true,
      additional: {
        left: i18n(getATMAccount(IW.appGetVal('selected_account'), 'text')),
        right: usd(IW.appGetVal('extraction_amount'))
      }
    };

    if (page === 'extract_money_success') return {
      smallText: getExtractionMessage(),
      bigText: i18n('Thank You!'),
      srcImg: images.ic_success,
      isSpinner: false,
      popularProducts: CONFIG.SHOW_POPULAR_PRODUCTS_ADVERTISEMENT,
      onMenu, onImageClick
    };

    if (page === 'extract_money_not_enough') return {
      smallText: i18n`Sorry, you do not have enough money to dispense ${usd(IW.appGetVal('selected_amount'))}`,
      bigText: i18n('Please change amount'),
      srcImg: '/resources/images/atm/ic_cash.png',
      isSpinner: false,
      onCancel: IW.appGetVal('card_emv') ?
        null :
        onCancel,
      onChangeAmount: IW.appGetVal('card_emv') ?
        null :
        onChangeAmount,
      onCancelCenter: IW.appGetVal('card_emv') ?
        onCancelCenter :
        null,
    };

    if (page === 'transfer_money_not_enough') return {
      smallText: i18n`Sorry, you do not have enough money to transfer ${usd(IW.appGetVal('selected_amount'))}`,
      bigText: i18n('Please change amount'),
      srcImg: '/resources/images/atm/ic_cash.png',
      isSpinner: false,
      onCancel: IW.appGetVal('card_emv') ?
        null :
        onCancel,
      onChangeAmount: IW.appGetVal('card_emv') ?
        null :
        onChangeAmount,
      onCancelCenter: IW.appGetVal('card_emv') ?
        onCancelCenter :
        null,
    };

    if (page === 'bad_card') return {
      smallText: i18n('Sorry, we were not able to read your card'),
      bigText: i18n('Please take your card'),
      srcImg: images.ic_card_error,
      isSpinner: true,
      onCancel
    };

    if (page === 'internal_error') return {
      smallText: i18n('There\'s a temporary error'),
      bigText: i18n('Please try again later'),
      srcImg: images.ic_problem,
      isSpinner: false,
      onMenu, onImageClick
    };

    if (page === 'device_error') return {
      smallText: i18n`${(!IW.appGetVal('card_reader_status_success') && i18n('Card reader')) ||
      (!IW.appGetVal('cash_dispenser_status_success') && i18n('Dispenser')) ||
      (!IW.appGetVal('epp_status_success') && i18n('Pin pad'))} does not work now`,
      bigText: i18n('Please try again later'),
      srcImg: images.ic_problem,
      isSpinner: false,
      onMenu, onImageClick
    };

    if (page === 'terminal_locked_error') return {
      smallText: i18n('Terminal is temporary out of service'),
      bigText: i18n('Please try again later'),
      srcImg: images.ic_problem,
      isSpinner: false,
      onMenu, onImageClick
    };

    if (page === 'account_amount') return {
      smallText: i18n('Amount on your account is'),
      bigText: IW.appGetVal('balance_amount_unavailable') ?
        i18n('Unavailable') :
        usd(IW.appGetVal('selected_amount')),
      srcImg: '/resources/images/atm/ic_cash.png',
      isSpinner: false,
      onBack, onPrint
    };

    if (page === 'transaction_canceled') return {
      smallText: i18n('Your transaction has been cancelled'),
      bigText: i18n('Please take your card'),
      srcImg: images.ic_take_card,
      isSpinner: true,
    };

    if (page === 'fee') return {
      smallText: i18n('Do you want to continue?'),
      bigText: i18n`Transaction fee ${c(IW.appGetVal('terminal_fee'))}`,
      srcImg: images.ic_question,
      isSpinner: false,
      onBack, onNext
    };

    if (page === 'emv_confirm_application') return {
      smallText: i18n('Do you want to use this application?'),
      bigText: IW.appGetVal('application_name'),
      srcImg: images.ic_question,
      isSpinner: false,
      onCancel, onNext
    };

    if (page === 'emv_show_message') return {
      smallText: IW.appGetVal('terminal_message').length > 30 ?
        IW.appGetVal('terminal_message') :
        '',
      bigText: IW.appGetVal('terminal_message').length <= 30 ?
        IW.appGetVal('terminal_message') :
        '',
      srcImg: images.ic_problem,
      isSpinner: false,
      onCancel, onNext
    };

    if (page === 'emv_account_amount') return {
      smallText: i18n('Amount on your account is'),
      bigText: IW.appGetVal('balance_amount_unavailable') ?
        i18n('Unavailable') :
        usd(IW.appGetVal('selected_amount')),
      srcImg: '/resources/images/atm/ic_cash.png',
      isSpinner: false,
      onCancel, onPrint
    };

    if (page === 'refund_money') return {
      smallText: IW.appGetVal('dispenser_coin_amount') > 0 ?
        i18n`Dispensing ${usd(IW.appGetVal('dispenser_cash_amount'))} cash and ${usd(IW.appGetVal('dispenser_coin_amount'))} coins` :
        i18n`Dispensing ${usd(IW.appGetVal('dispenser_cash_amount'))}`,
      bigText: i18n('Please take your cash'),
      srcImg: images.ic_cash,
      isSpinner: true,
      additional: {
        left: i18n('Payment'),
        right: usd(IW.appGetVal('refund_amount'))
      }
    };

    if (page === 'refund_money_success') return {
      smallText: getExtractionMessage(),
      bigText: i18n('Thank You!'),
      srcImg: images.ic_success,
      isSpinner: false,
      popularProducts: CONFIG.SHOW_POPULAR_PRODUCTS_ADVERTISEMENT,
      onMenu, onImageClick
    };

    if (page === 'sweepstakes_loose') return {
      smallText: i18n('Sorry, you did not win'),
      bigText: i18n('Better luck next time!'),
      srcImg: '/resources/images/atm/ic_cash.png', //TODO
      isSpinner: false,
      onMenu, onImageClick
    };

    if (page === 'sweepstakes_start_question') return {
      smallText: i18n('Do you want to enter'),
      bigText: i18n('The Sweepstakes?'),
      srcImg: '/resources/images/atm/ic_cash.png',
      isSpinner: false,
      onNo, onYes
    };

    if (page === 'customer_limit_reached') {
      let limitName = i18n('daily');
      if (IW.appGetVal('customer_limit_type') === 'monthly') limitName = i18n('monthly');
      if (IW.appGetVal('customer_limit_type') === 'six_month') limitName = i18n('6th month');
      return {
        smallText: i18n('You cannot continue!'),
        bigText: i18n`You reached the ${limitName} limit!`,
        srcImg: images.ic_problem,
        isSpinner: false,
        onBack, onMenuRight
      };
    }

    if (page === 'insert_check') return {
      smallText: i18n('Please sign on the back of the check!'),
      bigText: i18n('Insert your check'),
      srcImg: images.ic_insert_check,
      isSpinner: false,
      onBack,
    };

    if (page === 'insert_customer_id') return {
      smallText: i18n('We need additional information'),
      bigText: i18n('Insert your ID document'),
      srcImg: images.ic_insert_id,
      isSpinner: false,
      onBack,
    };

    if (page === 'return_customer_id') return {
      smallText: i18n('Data was scanned'),
      bigText: i18n('Take your ID document'),
      srcImg: images.ic_take_id,
      isSpinner: true
    };

    if (page === 'scan_id_barcode') return {
      // smallText: i18n('We need additional information'),
      bigText: i18n('Scan your ID document barcode'),
      srcImg: images.ic_insert_id,
      isSpinner: true,
      onBack,
      onNext: IW.appGetVal('allow_enroll_customer_without_barcode') ? onNext : undefined,
      rightButton: 'Continue without barcode scan'
    };

    if (page === 'scan_wallet_qr') return {
      // smallText: i18n('We need additional information'),
      bigText: i18n('Scan your wallet QR'),
      srcImg: images.ic_insert_id,
      isSpinner: true,
      onBack,
    };


    if (page === 'return_customer_check') return {
      smallText: _.isArray(IW.appGetVal('return_check_message')) ?
        i18n(...IW.appGetVal('return_check_message')) :
        i18n(IW.appGetVal('return_check_message')),
      bigText: i18n('Take your check'),
      srcImg: images.ic_take_check,
      isSpinner: !IW.appGetVal('check_removed'),
      onNext: IW.appGetVal('check_removed') && onNext,
    };

    if (page === 'cash_check_amount') return {
      smallText: _.compact([
        i18n`We will dispense you ${usd(IW.appGetVal('selected_amount'))}.`,
        i18n`Fee is ${usd(IW.appGetVal('fee'))}.`,
        IW.appGetVal('debit_amount') > 0 ?
          i18n`${usd(IW.appGetVal('debit_amount'))} will be added on your account.` :
          '',
        IW.appGetVal('debit_amount') < 0 ?
          i18n`${usd(-IW.appGetVal('debit_amount'))} will be taken from your account.` :
          '']).join(' '),
      bigText: i18n('Are you agree?'),
      srcImg: images.ic_question,
      isSpinner: false,
      onNo, onYes
    };

    if (page === 'cash_check_review') return {
      smallText: i18n('Your check has been accepted for review, but you can try to scan again'),
      bigText: i18n('Proceed with review?'),
      srcImg: images.ic_question,
      isSpinner: false,
      leftButton: 'Scan Again',
      rightButton: 'Proceed',
      onNo, onYes
    };

    if (page === 'cash_check_wait_review') {
      if (IW.appGetVal('check_review_too_long')) return {
        smallText: i18n('We are still reviewing the check. Please wait.\nOr you can take your check and be notified by SMS'),
        bigText: i18n('Continue to wait or press continue later'),
        srcImg: images.ic_wait,
        isSpinner: false,
        rightButton: 'Continue Later',
        onYes
      };
      return {
        smallText: IW.appGetVal('transaction_review_status') === 'inprogress' ?
          i18n('We are reviewing the check now. Please wait') :
          i18n('Please wait while we review the check'),
        bigText: i18n('This will take a few minutes'),
        srcImg: images.ic_wait,
        isSpinner: false,
        progressBar: true
      };
    }

    if (page === 'cash_check_customer_exists') return {
      smallText: i18n('You already have the account!'),
      bigText: i18n('Please login to continue'),
      srcImg: images.ic_login,
      isSpinner: false,
      rightButton: 'Login',
      onBack, onNext
    };

    if (page === 'cash_check_customer_create') return {
      smallText: '',
      bigText: i18n('In order to cash checks we need to confirm your identity'),
      srcImg: images.ic_profile,
      isSpinner: false,
      onBack, onNext
    };

    if (page === 'cash_check_continue') {
      const baseMessage = i18n`Your check ${[
        IW.appGetVal('review_check_number'),
        IW.appGetVal('review_check_maker_aba_number'),
        IW.appGetVal('review_check_maker_account_number'),
      ].join(' ')} of ${c(IW.appGetVal('review_check_amount'))} is approved!`;

      return {
        smallText: baseMessage,
        bigText: i18n('Let\'s finish it?'),
        srcImg: images.ic_question,
        isSpinner: false,
        onNo, onYes
      };
    }

    if (page === 'cash_check_customer_duplicated') return {
      smallText: _.compact([
        IW.appGetVal('s_customer_ssn_duplicated') && !IW.appGetVal('s_customer_id_duplicated') && i18n('Your SSN already exists in our system'),
        !IW.appGetVal('s_customer_ssn_duplicated') && IW.appGetVal('s_customer_id_duplicated') && i18n('Your ID already exists in our system'),
        IW.appGetVal('s_customer_ssn_duplicated') && IW.appGetVal('s_customer_id_duplicated') && i18n('Your SSN and ID already exist in our system')
      ]).join(' '),
      bigText: i18n('Please review your profile'),
      srcImg: images.ic_profile,
      isSpinner: false,
      onBack
    };

    if (page === 'cash_check_customer_verification_failed') return {
      smallText: i18n('Your ID did not pass verification process'),
      bigText: i18n('We can review your ID instead'),
      srcImg: images.ic_problem,
      isSpinner: false,
      rightButton: 'Review my ID',
      onBack, onNext
    };

    if (page === 'cash_check_amount_info2') {
      const checkAmount = N(IW.appGetVal('check_amount'));
      const fee = N(IW.appGetVal('fee'));
      const requestedAmount = N(checkAmount - fee);
      const dispensableAmount = N(IW.appGetVal('selected_amount'));
      const debitAmount = N(IW.appGetVal('debit_amount'));
      const customerBalance = N(IW.appGetVal('customer_balance_amount'));

      if (dispensableAmount > 0 && customerBalance + debitAmount < 2) return {
        smallText: (debitAmount > 0 && i18n`We will dispense you ${c(dispensableAmount)} and send to your balance ${c(debitAmount)} (fee is ${c(fee)})`) ||
          (debitAmount < 0 && i18n`We will apply ${c(-debitAmount)} from your credit balance and dispense ${c(dispensableAmount)} (fee is ${c(fee)})`) ||
          i18n`We will dispense you ${c(dispensableAmount)} (fee is ${c(fee)})`,
        bigText: i18n('Continue?'),
        srcImg: images.ic_question,
        isSpinner: false,
        leftButton: 'No',
        onBack,
        onYes
      };

      if (dispensableAmount > 0) return {
        smallText: i18n`Now we can dispense only ${c(dispensableAmount)} (fee is ${c(fee)})`,
        bigText: i18n('Please try again later'),
        srcImg: images.ic_problem,
        isSpinner: false,
        onBack,
        onMenuRight: () => getPageManager().cancel()
      };

      return {
        smallText: i18n`Now we cannot dispense ${c(requestedAmount)} (fee is ${c(fee)})`,
        bigText: i18n('Please try again later'),
        srcImg: images.ic_problem,
        isSpinner: false,
        onBack,
        onMenuRight: () => getPageManager().cancel()
      };
    }

    // if (page === 'cash_check_clerk_wait_approve') {
    //   if (IW.appGetVal('notify_clerk_success')) {
    //     if (IW.appGetVal('clerk_qr_scanned') && !IW.appGetVal('clerk_qr_correct')) {
    //       return {
    //         smallText: i18n('The QR is not for this specific review'),
    //         bigText: i18n('Please scan another QR'),
    //         srcImg: images.ic_wait,
    //         isSpinner: false,
    //         popularProducts: false,
    //         onBack
    //       };
    //     }
    //
    //     return {
    //       smallText: i18n('We send QR code to the clerk phone'),
    //       bigText: i18n('Please scan the code to approve the check'),
    //       srcImg: images.ic_wait,
    //       isSpinner: false,
    //       popularProducts: false,
    //       onBack
    //     };
    //   }
    //
    //   return {
    //     smallText: i18n('There\'s a temporary error'),
    //     bigText: i18n('Please try again later'),
    //     srcImg: images.ic_problem,
    //     isSpinner: false,
    //     popularProducts: false,
    //     onBack
    //   };
    // }

    if (page === 'cash_check_success') return {
      smallText: getExtractionMessage(),
      bigText: i18n('Please take your Receipt!'),
      srcImg: images.ic_take_receipt,
      isSpinner: false,
      popularProducts: CONFIG.SHOW_POPULAR_PRODUCTS_ADVERTISEMENT,
      onMenu, onImageClick
    };

    if (page === 'bill_scanning_insert_bill') return {
      smallText: i18n('We scan your bill and return it'),
      bigText: i18n('Insert your bill'),
      srcImg: images.ic_insert_check,
      isSpinner: false,
      onBack,
    };

    if (page === 'bill_scanning_return_bill') return {
      smallText: i18n('Data was scanned'),
      bigText: i18n('Take your bill'),
      srcImg: images.ic_take_check,
      isSpinner: true
    };

    if (page === 'no_coin_change_notice') return {
      smallText: i18n(CONFIG.SHOW_NO_COINS_NOTICE_HARD ?
        'This machine does not give out coin change.\nWe will store your balance for future transactions' :
        'When the machine is out of coins, we will save your change to your account for future transactions'),
      bigText: i18n('Continue?'),
      srcImg: images.ic_question,
      isSpinner: false,
      onYes, onNo
    };

    if (page === 'sigue_error') {
      const e = IW.appGetVal('sigue_calculate_error_code');
      let additionalMessage = '';
      if (e !== 1) {
        additionalMessage = i18n`There is unexpected error ${IW.appGetVal('sigue_calculate_error_code')}`;
      }
      if (e === 0) additionalMessage = '';
      if (e === 1000 || e === 2 || e === 1003 || e === 1004) {
        additionalMessage = i18n('There is no fee nor exchange rate');
      }
      if (e === 1001) additionalMessage = i18n('There is no fee, but there is an exchange rate');
      if (e === 1002) additionalMessage = i18n('Invalid Promotion Code');

      return {
        smallText: _.compact([
          IW.appGetVal('sigue_calculate_error_small'),
          additionalMessage
        ]).join('. ') || i18n('We cannot process your transaction'),
        bigText: IW.appGetVal('sigue_calculate_error_big') || i18n('Please try again later'),
        srcImg: IW.appGetVal('sigue_calculate_error_small').length + IW.appGetVal('sigue_calculate_error_big').length < 300 ?
          images.ic_problem :
          '',
        isSpinner: false,
        onBack,
      }
    }

    if (page === 'sigue_warning') {
      return {
        smallText: IW.appGetVal('sigue_calculate_warning_small'),
        bigText: IW.appGetVal('sigue_calculate_warning_big'),
        srcImg: IW.appGetVal('sigue_calculate_warning_small').length + IW.appGetVal('sigue_calculate_warning_big').length < 300 ?
          images.ic_question :
          '',
        isSpinner: false,
        onBack, onNext
      }
    }

    if (page === 'validate_sender_data') return {
      smallText: i18n`We cannot find city ${IW.appGetVal('sender_address_city')} in ${IW.appGetVal('sender_address_state')}.`,
      bigText: i18n('Please change the address'),
      srcImg: images.ic_profile,
      isSpinner: false,
      onBack
    };

    if (page === 'check_free_error') {
      return {
        smallText: i18n('We cannot process your transaction'),
        bigText: i18n(IW.appGetVal('validate_transaction_message')) || i18n('Please try again later'),
        srcImg: images.ic_profile,
        isSpinner: false,
        onBack,
      }
    }

    if (page === 'check_free_required_fields_invalid') {
      const fields = _.compact(_.map(IW.appGetVal('required_fields'),
        (f) => _.contains(IW.appGetVal('required_fields_invalid'), f.name) && f.description));
      return {
        smallText: fields.length === 1 ?
          i18n`${fields[0]} is not correct` :
          i18n`${fields.join(', ') || i18n('Some fields')} are not correct`,
        bigText: i18n('Please check your input'),
        srcImg: images.ic_profile,
        isSpinner: false,
        onBack,
      }
    }

    if (page === 'check_free_pop_authorized') return {
      smallText: i18n(IW.appGetVal('validate_transaction_pop_message')),
      bigText: i18n('Confirm?'),
      srcImg: images.ic_profile,
      isSpinner: false,
      onNo, onYes
    };

    if (page === 'check_free_delivery') return {
      smallText: '',
      bigText: i18n(IW.appGetVal('validate_transaction_delivery_message')),
      srcImg: images.ic_profile,
      isSpinner: false,
      rightButton: 'OK',
      onBack, onNext
    };

    if (page === 'check_free_instruction') return {
      smallText: (IW.appGetVal('biller_instruction').length > 60 && IW.appGetVal('biller_instruction')) || '',
      bigText: (IW.appGetVal('biller_instruction').length <= 60 && IW.appGetVal('biller_instruction')) || '',
      srcImg: images.ic_profile,
      isSpinner: false,
      rightButton: 'I see',
      onBack, onNext
    };

    if (page === 'check_free_consumer') return IW.appGetVal('validate_transaction_success') ? ({
      smallText: IW.appGetVal('validate_transaction_consumer_message'),
      bigText: '',
      srcImg: '/resources/images/atm/new/ic_profile.svg',
      isSpinner: false,
      rightButton: 'IT IS CORRECT',
      onBack, onNext
    }) : ({
      smallText: IW.appGetVal('validate_transaction_consumer_message'),
      bigText: '',
      srcImg: '/resources/images/atm/new/ic_problem.svg',
      isSpinner: false,
      onBack
    });

    if (page === 'check_free_overpay') return IW.appGetVal('biller_is_rent') ? {
      smallText: i18n('Overpayments are not allowed and this machine cannot dispense change in cents. ' +
        'We are unable to proceed with this Rent payment transaction.'),
      // bigText: sprintf(i18n('Please, change amount to %s or to %s'),
      //   C(IW.appGetVal('bill_original_amount_new') - 1, '$'),
      //   C(IW.appGetVal('bill_original_amount_new'), '$')),
      srcImg: '/resources/images/atm/new/ic_problem.svg',
      isSpinner: false,
      rightButton: 'I AGREE',
      onBack
    } : {
      // bigText: sprintf(i18n('We changed the amount to %s (%s overpay)?'),
      //   C(IW.appGetVal('bill_original_amount'), '$'),
      //   C(IW.appGetVal('bill_original_amount_overpay'), '$')),
      smallText: i18n('This kiosk DOES NOT MAKE CHANGE and will round the transaction amount up to the next whole dollar amount. ' +
        'Prior to conducting this transaction you should contact your biller and confirm that a payment for an amount other ' +
        'than the EXACT AMOUNT DUE is acceptable by your biller. ' +
        'CheckFreePay is not responsible for payments that are rejected by your biller.'),
      srcImg: '',
      isSpinner: false,
      rightButton: 'I UNDERSTAND',
      onBack, onNext
    };

    if (page === 'payment_status_cancel') return {
      smallText: i18n('Your transaction will be CANCELLED!'),
      bigText: ((!IW.canRefund() && i18n('But we cannot refund your money.') + ' ') || '') + i18n('Are you sure?'),
      srcImg: images.ic_question,
      isSpinner: false,
      onNo, onYes
    };

    if (page === 'payment_status_cancel_error') return {
      smallText: i18n('We cannot cancel the transaction'),
      bigText: IW.appGetVal('cancel_payment_message') || i18n('Press Get Support for help'),
      srcImg: images.ic_problem,
      isSpinner: false,
      rightButton: 'Get Support',
      onBack, onNext
    };

    if (page === 'payment_status_refund_error') return {
      smallText: i18n('Your transaction is cancelled, but we cannot refund your money now'),
      bigText: i18n('Press Get Support for help'),
      srcImg: images.ic_problem,
      isSpinner: false,
      rightButton: 'Get Support',
      onBack, onNext
    };

    if (page === 'loans_final') return {
      smallText: i18n('We sent you SMS with the link'),
      bigText: i18n('Thank You!'),
      srcImg: '/resources/images/atm/new/ic_success.svg',
      isSpinner: false,
      popularProducts: CONFIG.SHOW_POPULAR_PRODUCTS_ADVERTISEMENT,
      onMenu, onImageClick
    };

    if (page === '_debug') return {
      smallText: '',
      bigText: '',
      srcImg: images.ic_card_error,
      isSpinner: false,
      onBack, onNext: () => IW.call('notifyAboutReview')
    };

    if (page === 'take_crypto_wallet_print') return {
      smallText: i18n('New wallet created.'),
      bigText: i18n('Please take your Wallet Print!'),
      srcImg: images.ic_take_receipt,
      isSpinner: false,
      // popularProducts: CONFIG.SHOW_POPULAR_PRODUCTS_ADVERTISEMENT,
      onNext
    };
  }


  const currentPage = getPage(params.page);
  handlePadKey();
  IW.setVoicePage('atm_message', {
    page: params.page,
    smallText: currentPage.smallText,
    bigText: currentPage.bigText,
    canCancel: !!currentPage.onCancel || !!currentPage.onCancelCenter,
    canMenu: !!currentPage.onMenu || !!currentPage.onImageClick || !!currentPage.onMenuRight,
    canChangeAmount: !!currentPage.onChangeAmount,
    canPrint: !!currentPage.onPrint,
    canBack: !!currentPage.onBack,
    canApprove: !!currentPage.onNext || !!currentPage.onActions,
  });
  return currentPage;

}, TemplateDisplayActions);

const imagesCommon = {
  maya_logo: '/resources/images/atm/maya_logo.png',
  ic_transfered: '/resources/images/atm/new/ic_transfered.svg',
  ic_card_error: '/resources/images/atm/new/ic_card_error.svg',
  ic_problem: '/resources/images/atm/new/ic_problem.svg',
  ic_cash: '/resources/images/atm/new/ic_cash.svg',
  ic_success: '/resources/images/atm/new/ic_success.svg',
  ic_question: '/resources/images/atm/new/ic_question.svg',
  ic_wait: '/resources/images/atm/new/ic_wait.svg',
  ic_login: '/resources/images/atm/new/ic_login.svg',
  ic_profile: '/resources/images/atm/new/ic_profile.svg',

  ic_insert_id: '/resources/images/atm/new/ic_insert_id.svg',
  ic_insert_check: '/resources/images/atm/new/ic_insert_check.svg',
  ic_insert_card: '/resources/images/atm/new/ic_insert_card.svg',

  ic_take_id: '/resources/images/atm/new/ic_take_id.svg',
  ic_take_card: '/resources/images/atm/new/ic_take_card.svg',
  ic_take_check: '/resources/images/atm/new/ic_take_check.svg',
  ic_take_receipt: '/resources/images/atm/new/ic_take_receipt.svg',
};

const imagesGenmega = {
  ic_cash: '/resources/images/atm/genmega/ic_cash.jpg',

  ic_insert_id: '/resources/images/atm/genmega/ic_insert_id.jpg',
  ic_insert_check: '/resources/images/atm/genmega/ic_insert_check.jpg',
  ic_insert_card: '/resources/images/atm/genmega/ic_insert_card.jpg',

  ic_take_id: '/resources/images/atm/genmega/ic_take_id.jpg',
  ic_take_card: '/resources/images/atm/genmega/ic_take_card.jpg',
  ic_take_check: '/resources/images/atm/genmega/ic_take_check.jpg',
  ic_take_receipt: '/resources/images/atm/genmega/ic_take_receipt.jpg',
};