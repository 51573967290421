import IW from 'imports/iw_api';
import {Session} from 'core/session';
import {VIAMERICA_INTERNAL_ERRORS} from '../../lib/constants';
import {FlowRouter} from 'core/Router';

export default function (pageBack, pageNext, loginBack) {
  return function*() {
    Session.set('prev_current_active_field', 0);
    Session.set('current_active_field', 0);
    Session.set('money_transferring_places', '');
    IW.appSetDefaults(/^compliance_sender/);

    if (IW.appGetVal('validation_error')) {
      const error = VIAMERICA_INTERNAL_ERRORS[IW.appGetVal('validation_error')];
      if (error) {
        IW.appSetVal(error.field, '');
        Session.set('current_active_field', error.index);
      }
    }

    if(IW.appGetVal('sender_location_is_invalid')) {
      IW.appSetVal('sender_address_city', '');
      IW.appSetVal('sender_address_state', '');
      IW.appSetVal('sender_address', '');
      IW.appSetVal('sender_address1', '');
      IW.appSetVal('sender_address2', '');
      Session.set('current_active_field', 5);
    }

    //If customer is logged
    if (IW.appGetVal('logged') && !IW.appGetVal('confirm_sender_data')) {
      IW.appSetVal('sender_data_filled', undefined);
      IW.call('checkData', 'sender');

      yield 'loading app_exists sender_data_filled';

      if (IW.appGetVal('sender_data_filled')) {
        return pageNext;
      }
    }

    const realBack = (IW.appGetVal('logged') && IW.appGetVal('sender_data_filled')) ? loginBack : pageBack;

    return {
      back: () => {
        if (IW.appGetVal('logged') && realBack === pageBack) {
          const entryPointPath = Session.get('entry_point_path');
          if (entryPointPath && !IW.appGetVal('confirm_sender_data')) { // through profile back button should return to profile not sender_number
            return FlowRouter.go(entryPointPath, {language: FlowRouter.getParam('language')});
          }

          IW.call('logout');
          return pageBack;
        }

        return realBack
      },
      next: function () {
        IW.appSetLocalVal('validate_sender_data_next_page', pageNext);
        IW.appSetLocalVal('validate_sender_data_back_page', FlowRouter.getParam('step'));
        return 'validate_sender_data'
      }
    }
  }
}


