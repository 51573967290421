import {createContainer} from 'imports/lib/react_createContainer';
import SearchLayout from './SearchLayout'
import {Session} from "core/session";
import CONFIG from "../../../config";
import {sprintf} from 'imports/lib/external/sprintf';
import * as Helpers from "../../../lib/helpers";
import {gaEvent} from "imports/lib/ga";
import IW from '../../../iw_api';
import Links from '../../../collections/links';

export default createContainer(({gaCategory}) => {

  const onOpenSearch = () => {
    Session.set('search_panel', true);
    gaEvent(gaCategory, 'Open search')
  };

  const locale = Session.get('locale'); //Didn`t remove this line need for reactive update language on header

  const errorText = () => {
    if (Session.get('search_string') && Session.get('search_string').trim().length >= CONFIG.SEARCH_MINLEN)
      return sprintf(Helpers._('Nothing found for "%s"'), Session.get('search_string')); // no results found
    else if (Session.get('search_string').trim().length < CONFIG.SEARCH_MINLEN)
      return Helpers._('Please enter your search query'); // one letter
    return Helpers._('No results found');
  };

  const onQuery = (v) => {
    Session.set('search_string', v);
    gaEvent(gaCategory, 'Do query', v);
  };

  IW.updateMenuVoicePage()
  return {
    onOpenSearch,
    isSearchOpened: Session.get('search_panel'),
    errorText: errorText(),
    onQuery,
    onClear: () => {
      gaEvent(gaCategory, 'Clear query');
      Session.set('search_string', '')
    },
    searchString: Session.get('search_string'),

    gaCategory,
  }
}, SearchLayout)
