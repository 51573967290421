import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import IW from 'imports/iw_api';
import Prods from 'imports/collections/products';
import CardItem from './CardItem';
import getPageManager from 'imports/lib/page_manager';
import {N, C, _ as i18n} from 'imports/lib/helpers';

export default createContainer((params) => {

  const product = Prods.findOne({"product_id": N(FlowRouter.getParam("productId"))})
  if (!product) return null;

  const onCardClick = function (ev) {
    // Ignore mousedowns for slider items
    if ((ev.type === 'mousedown') && $('.iosSlider').data('iosslider')) return;
    IW.appSetVal('original_amount', +params.item.amount);
    // Replace escaped dot
    IW.appSetVal('product', params.item.prod.replace(/@/g, '.'));
    if(_.has(params.item, "account_id")) IW.appSetVal('account_id', params.item.account_id);
    getPageManager().next()
  };

  let name = params.item.name;
  if (product.app_guid === 'app_giftcards_ddGtr'){
    name = i18n('buy for') + ' $' + N(params.item.amount);
  }

  return {
    amount: params.item.amount,
    icon: params.item.icon || product.icon,
    name: name,
    iconCountry: params.item.iconCountry,
    iconPerson: params.item.iconPerson,
    onCardClick: onCardClick
  };

}, CardItem);
