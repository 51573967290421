import {createContainer} from 'imports/lib/react_createContainer';
import SendMoney from './SendMoney';
import IW from 'imports/iw_api';
import Apps from 'imports/collections/apps';
import Links from 'imports/collections/links';
import {capitalize} from 'imports/lib/helpers';

export default createContainer((params) => {

  const payments = params.items;

  const getRecipientsBySender = () => {
    const sendMoneyApps = _.filter(Apps.find({}).fetch(), (a) => /moneytransfer/.test(a.app_guid));
    const sendMoneyPayments = _.filter(payments, (p) => _.find(sendMoneyApps, (a) => a.app_guid === p.app_guid));

    const recipients = {};
    const senders = {};
    let result = [];
    _.each(sendMoneyPayments, (payment) => {
      const paymentData = payment.x_data;

      if (!paymentData.sender_first_name || !paymentData.sender_last_name || !paymentData.sender_address_city) return;
      const senderKey = paymentData.sender_first_name + paymentData.sender_last_name + paymentData.sender_address;

      if (!senders[senderKey]) {
        senders[senderKey] = {
          first_name: paymentData.sender_first_name,
          last_name: paymentData.sender_last_name,
          city: paymentData.sender_address_city,
          from: `from ${capitalize(paymentData.sender_address1)}, ${capitalize(paymentData.sender_address_city)}`,
          recipients: []
        }
      }

      if (!paymentData.recipient_first_name || !paymentData.recipient_last_name || !paymentData.recipient_city_name) return;
      const recipientKey = paymentData.recipient_first_name + paymentData.recipient_last_name + paymentData.recipient_city_name;

      if (!recipients[recipientKey]) {
        const recipient = {};
        recipient.recipient_id = payment.payment_id;
        recipient.icon = payment.product_icon;
        recipient.product_id = payment.product_id;
        recipient.product_name = payment.product_name;
        recipient.app_guid = payment.app_guid;
        recipient.session_guid = payment.session_guid;

        _.each(_.keys(paymentData), (key) => {
          if (/^recipient_|^payer_|^bank_account$/.test(key)) {
            recipient[key] = paymentData[key];
          }
        });

        if (recipient.recipient_payment_mode_type) {
          recipients[recipientKey] = recipient;
          senders[senderKey].recipients.push(recipient);
        }
      }
    });

    Object.keys(senders).forEach((key) => {
      if (senders[key].recipients.length) {
        senders[key].recipients = _.sortBy(senders[key].recipients, 'recipient_id').reverse();
        result.push({..._.omit(senders[key], 'recipients'), ...{isSender: true}});
        _.each(senders[key].recipients, (recipient) => result.push(recipient));
      }
    });

    if (result.length) {
      result = result.complete(6, {});
    }

    return result;
  };

  const onNext = () => {
  };

  const launchApplication = (p) => {
    const customerData = IW.loginGetVal('customer_data_result') || {};

    const options = {
      app_guid: p.app_guid,
      icon: p.icon,
      title: p.product_name,
      product_id: p.product_id,
      customer_id: customerData.customer_id
    };

    return IW.launchAppAfterLogin(options);
  };

  const launchApplicationFromRecipient = (r) => {
    const customerData = IW.loginGetVal('customer_data_result') || {};

    const options = {
      app_guid: r.app_guid,
      icon: r.icon,
      title: r.product_name,
      product_id: r.product_id,
      customer_id: customerData.customer_id,
      session_guid: r.session_guid
    };

    return IW.launchAppAfterLogin(options, 'on_login_recipient_choice');
  };

  const products = _.filter(Links.find({hidden: false}, {sort: {priority: -1}}).fetch(),
    (a) => /moneytransfer/.test(a.app_guid));

  return {
    products: products.complete(5, {}),
    recipientsBySender: getRecipientsBySender(),
    onNext,
    launchApplication,
    launchApplicationFromRecipient
  }
}, SendMoney);
