import React from 'react';
import { initVerticalSlider } from 'imports/lib/helpers';
import { DIRECTS } from 'imports/lib/constants';

export default class DropdownIOSliderList extends React.Component {
  componentDidMount() {
    const { optionsSlider } = this.props;
    initVerticalSlider(Object.assign(optionsSlider));
  }

  componentDidUpdate() {
    const { optionsSlider } = this.props;
    initVerticalSlider(Object.assign(optionsSlider));
  }

  renderItems() {
    let {items, onTapItem} = this.props;

    return items.map(function (item, index) {
      return <a key={index} className="slide slide-vertical-item"
                onMouseDown={(e) => onTapItem(item.key, e)}
                onClick={(e) => onTapItem(item.key, e)}
      >
        <span><b>{item.left_text}</b> {item.main_text}</span></a>
    });
  }

  render() {
    const {id, className, style, controls} = this.props;

    return (
      <div id={id} className={className} style={ style }>
        { controls ? <div className="slide-button slide-button__up" onMouseDown={() => this.handleSlideControls(DIRECTS.TOP)}/> : null}
        <div className="iosVerticalSlider">
          <div className="slider">
            {this.renderItems()}
          </div>
        </div>
        { controls ? <div className="slide-button slide-button__down" onMouseDown={() => this.handleSlideControls(DIRECTS.DOWN)}/> : null}
      </div>
    )
  }
}

DropdownIOSliderList.propTypes = {
  id: React.PropTypes.string,
  className: React.PropTypes.string,
  onChecked: React.PropTypes.func,
  style: React.PropTypes.object,
  items: React.PropTypes.array,
  step: React.PropTypes.number,
  onTapItem: React.PropTypes.func,
  optionsSlider: React.PropTypes.object,
  handleSlide : React.PropTypes.func,
  controls : React.PropTypes.bool
};