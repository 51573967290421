import {createContainer} from 'imports/lib/react_createContainer';
import getPageManager from 'imports/lib/page_manager';
import Maya5Congratulation from './Maya5Congratulation';
import {Session} from 'core/session';

export default createContainer(() => {

  let videoUrl = '/resources/videos/maya5/free_transaction_animation_en.mp4';
  if (Session.get('locale') === 'es_MX') {
    videoUrl = '/resources/videos/maya5/free_transaction_animation_sp.mp4';
  }

  return {
    videoUrl,
    styles: {background: '#181a2f'},
    onFinish: () => ({})
  }
}, Maya5Congratulation)
