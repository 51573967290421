import React from 'react';
import Input from 'imports/ui/shared/input/Input';
import Button from 'imports/ui/shared/button/Button';

export default class SearchHeader extends React.Component {
  render() {
    return (
      <table cellSpacing={0}>
        <tbody>
        <tr>
          <td className="sq">
            <Button id="menu" className="button button-header button-danger" onMouseDown={this.props.onMainSelected} text_="Menu"/>
          </td>
          <td className="search">
            <div id="search-bar">
              {/*<span id="q-label" _>Search</span>*/}
              <span id="q-wrap">
                  <Input id="q" className="upper" value={this.props.query} readOnly={true} placeholder_="Product Name"/>
                {this.props.query ? <span id="q-x" onMouseDown={this.props.onClear}></span> : null}
                </span>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    )
  }
}

SearchHeader.propTypes = {
  query: React.PropTypes.string,
  onMainSelected: React.PropTypes.func,
  onClear: React.PropTypes.func
};

SearchHeader.defaultProps = {
  query: '',
  onMainSelected: () => {
  },
  onClear: () => {
  },
};
