import app_common from './app_common';
import IW from 'imports/iw_api';
import CONFIG from 'imports/config';

// ["shopping_cart", "pay", "shopping_cart_loading", "final", "email", "sent"]
export default _.extend({}, app_common, {
  index: function () {
    IW.appSetVal('prize_table', _.extend({}, CONFIG.SWEEPSTAKES_PRIZES));
    IW.appSetVal('prizes', _.keys(IW.appGetVal('prize_table')).concat([0, 0]));
    return 'sweepstakes'
  },

  sweepstakes: function* () {
    if (!CONFIG.SWEEPSTAKES_ENABLED) return 'exit';
    IW.callCashDispenser('status');
    IW.callBillAcceptor('status');
    disabledCamera();
    yield 'loading app cash_dispenser_status_done';
    yield 'loading app coin_acceptor_status_done';

    if (!IW.appGetVal('load_dispense_done')) {
      IW.call('loadDispense');
      yield 'loading app load_dispense_done';
    }

    IW.appSetLocalVal('wheel_prizes', null);
    IW.appSetLocalVal('rolling', false);

    IW.call('doSwipe');
    yield 'loading app swipe_done';
    return {
      next: function* () {
        IW.takeScreenShot();
        IW.callSystem('screen_capture_take');
        yield 'sleep app system_screen_capture_take_done';
        if (IW.appGetVal('swipe_success')) {
          yield 'sleep 4000 sweepstakes_congratulation';
          return 'extract_money'
        }
        return 'sweepstakes_loose'
      }
    };
  },

  extract_money: function* () {
    Session.set('menu_disabled', true);
    IW.appSetLocalVal('extract_cash_done', false);
    IW.call('extractCash');
    yield 'loading app extract_cash_done';

    if (!IW.appGetVal('extract_cash_success')) {
      Session.set('menu_disabled', false);
      return 'internal_error';
    }

    IW.callBillAcceptor('dispense');
    IW.callCashDispenser('dispense');

    yield 'sleep app dispensing_done';

    Session.set('menu_disabled', false);
    return 'extract_money_success';
  },

  extract_money_success: function*() {
    if (CONFIG.SHOW_POPULAR_PRODUCTS_ADVERTISEMENT) {
      IW.call('getPopularProductsId');
      yield 'loading app popular_products_id';
    }

    IW.printReceipt();
    return {
      cancel: 'exit',
      next: 'exit'
    }
  },

  internal_error: {
    cancel: 'exit',
    next: 'exit',
  },

  sweepstakes_loose: function () {
    return {
      cancel: 'exit',
      next: 'exit'
    }
  },

  sweepstakes_congratulation: {}
});

function disabledCamera() {
  function getPath() {
    return IW.getPath()
      .replace(/sweepstakes/g, 'valid')
      .replace(/loading/g, 'valid')
      .replace(/sweepstakes_congratulation/g, 'valid')
  }

  function routine() {
    const path = getPath() + Session.get('session_guid');
    if (path !== checkPath) {
      IW.callCamera('scan', sessionGuid);
      return;
    }
    setTimeout(routine, 500);
  }

  let checkPath = '';
  const sessionGuid = Session.get('session_guid');

  IW.callCamera('stop', sessionGuid);
  setTimeout(function () {
    if (sessionGuid !== Session.get('session_guid')) throw Error('Session is finished');
    checkPath = getPath() + sessionGuid;
    routine();
  }, 100);
}
