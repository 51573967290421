import {Session} from 'core/session';
import {Meteor} from 'core/meteor';
import CONFIG from 'imports/config';
import {FlowRouter} from 'core/Router';
import {isIndex} from './layout';
import IW from 'imports/iw_api';

let timer = -1;
let lastTimeout = 0;
let lastState = '';

export function init() {
  lastTimeout = Number.MAX_SAFE_INTEGER;
  Session.set('timer_current_time', +new Date() / 1000);
  reset();
  routine();
}

export function tryReset() {
  if (!isTimeout()) reset();
}

export function reset() {
  Session.set('timer_last_time', Session.get('timer_current_time'));
}

export function isTimeout() {
  return getTimeElapsed() >= lastTimeout;
}

export function getCountdown() {
  return (Math.max(lastTimeout + CONFIG.INTERNAL_TIMEOUT - getTimeElapsed(), 0)).toFixed(0);
}

function routine() {
  Meteor.clearInterval(timer);
  timer = Meteor.setInterval(function () {
    Session.set('timer_current_time', Math.round(+new Date() / 1000));

    if (isIndex()) {
      lastTimeout = CONFIG.TIMEOUT_INDEX;
      return;
    }

    if (lastState !== getState() || shouldReset()) {
      lastState = getState();
      reset();
    }

    if (getCountdown() == 0) {
      reset();
      return IW.gotoIndex();
    }

    if (FlowRouter.getParam('step')) {
      if (/^pay_|^pay$/.test(FlowRouter.getParam('step'))) {
        lastTimeout = CONFIG.TIMEOUT_PAYMENT;
        return;
      }

      if (/^extract_money$|^refund_money$/.test(FlowRouter.getParam('step'))) {
        lastTimeout = CONFIG.TIMEOUT_PAYMENT;
        return;
      }

      if (/_wait_/.test(FlowRouter.getParam('step'))) {
        lastTimeout = CONFIG.TIMEOUT_WAITING;
        return;
      }

      if (FlowRouter.getParam('step') === 'shopping_cart_loading' || FlowRouter.getParam('step') === 'loading') {
        lastTimeout = CONFIG.TIMEOUT_LOADING;
        return;
      }

      if (FlowRouter.getParam('step') === 'extract_money_success' || FlowRouter.getParam('step') === 'final') {
        lastTimeout = CONFIG.TIMEOUT_SUCCESS_PAYMENT;
        return;
      }

      lastTimeout = CONFIG.TIMEOUT_APP;
      return;
    }

    lastTimeout = CONFIG.TIMEOUT;
  }, 490);
}

function getTimeElapsed() {
  const time = Session.get('timer_last_time')
  if (time == 0) return 0;
  return (Session.get('timer_current_time') - time) || 0;
}

function getState() {
  return [
    Meteor.status().connected,
    Session.get('online'),
    Session.get('io.connected'),
    Session.get('overlay_state'),
    FlowRouter.current().path,
    IW.appGetVal('amount'),
  ].join();
}

function shouldReset() {
  return !Meteor.status().connected ||
    !Session.get('online') ||
    !Session.get('io.connected') ||
    Session.get('overlay_state');
}