import React, {Component} from 'react';
import Loading from 'imports/ui/shared/loading/Loading';
import Button from 'imports/ui/shared/button/Button';
import {_, usd, C} from 'imports/lib/helpers';
import {sprintf} from 'imports/lib/external/sprintf';
import __ from 'lodash'

export default class Info extends Component {

  getTitle(option){
    if (option.releaseDays !== 'TODAY'){
      return sprintf(_('Your Payment will be made on %s'), option.releaseDate);
    }

    return _('Your Payment will be made TODAY');
  }

  getText(option){
    const fee = option.fee > 0 ?
      `${C(option.fee, '$')}` :
      _('FREE');

    if (option.description) return sprintf('%s (%s)', option.description, fee);
    return sprintf(_('PAY %s (%s)'), option.releaseDays, fee)
  }

  render() {

    const {paymentOptions, showWarning, onNext, onBack} = this.props;

    const totalTitleLength = __.reduce(paymentOptions, (l, option) => l + this.getTitle(option).length, 0);

    return (
      <table className="info-page">
        <tbody>
        <tr>
          <td className="info-page__info">
            <div className="info-page-container">
              <table className="info-page__info-table" cellSpacing="5">
                <tbody className="infoTable">
                {__.map(paymentOptions, (option, i) => totalTitleLength > 140 ? (
                  <tr key={i}>
                    <td className="info-page__delivery-payment">
                      <span className="b">{this.getTitle(option)}</span>
                      <Button className="right button" data-priority={!!option.priority} onMouseDown={() => onNext(option, i)}
                              text={this.getText(option)}/>
                    </td>
                  </tr>
                ) : (
                  <tr key={i}>
                    <td className="pre-wrap "><span className="b">{this.getTitle(option)}</span></td>
                    <td className="info-page__delivery-payment">
                      <Button className="right button" data-priority={!!option.priority} onMouseDown={() => onNext(option, i)}
                              text={this.getText(option)}/>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
              {showWarning && (
                <div className="info-page-description">
                  <span _>Warning: If your service is disconnected, we do not reconnect services. Please contact your service provider</span>
                </div>
              )}
            </div>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td className="info-page__controls">
            <Button id="back" className="left button" text_="Back" onMouseDown={onBack}/>
          </td>
        </tr>
        </tfoot>
      </table>
    );
  }
}

Info.propTypes = {
  paymentOptions: React.PropTypes.arrayOf(React.PropTypes.shape({
    priority: React.PropTypes.bool,
    releaseDays: React.PropTypes.string,
    releaseDate: React.PropTypes.string,
    fee: React.PropTypes.number
  })),
  showWarning: React.PropTypes.bool,
  onNext: React.PropTypes.func,
  onBack: React.PropTypes.func,
};