/**
 * Created by vitaliy on 09.03.17.
 */
import React, {Component} from 'react';
import {FlowRouter} from 'core/Router';
import Button from 'imports/ui/shared/button/Button';
import Loading from 'imports/ui/shared/loading/Loading';

export default class Payout extends Component {


  render() {
    const {onNext, onBack, paymentModes} = this.props;

    if (!paymentModes.length) return <Loading/>;

    const res = (
      <table className="payout-page">
        <tbody>
        <tr className="payout__status">
          <td>
            <span _>Select payment method</span>
          </td>
        </tr>
        <tr className="payout__buttons">
          <td>
            <table className="buttons-table">
              <tbody>
              {paymentModes.map((row, i) => (
                <tr key={i}>
                  {row.map((mode, j) => (
                    <td key={j}>
                      <Button className="button button-primary" text_={mode.name}
                              onMouseDown={() => onNext(mode.id, mode.type)}
                              style={{width: 460}}
                              icon={<img className="button-icon"
                                         src={`/resources/images/money_transferring/ic_${mode.type}.png`}/>}/>
                    </td>
                  ))}
                </tr>
              ))}
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2">
            <Button id="back" onMouseDown={onBack} className="left button" text_="Back"/>
          </td>
        </tr>
        </tfoot>
      </table>
    );
    return res;
  }
}

Payout.propTypes = {
  paymentModes: React.PropTypes.array,
  onNext: React.PropTypes.func,
  onBack: React.PropTypes.func,
};
