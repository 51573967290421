import React from 'react';
import Time from '../shared/time/TimeContainer';
import {FlowRouter} from "core/Router";
import {createContainer} from 'imports/lib/react_createContainer';
import CONFIG from 'imports/config';

class IndexHeader extends React.Component {
  render() {
    return (
      <tr>
        <td id="topbar">
            <span id="tip" _={[this.props.support]}>
              For support call %s
            </span>
          {this.props.withDisclaimer && CONFIG.SWEEPSTAKES_ENABLED ?
            <span className="index_header-disclaimer" onMouseDown={() => FlowRouter.go('Disclaimer', {
              language: Session.get('locale') || 'en_US'
            })} _>
              Sweepstakes disclaimer
            </span>
            : null}
          <Time/>
        </td>
      </tr>
    )
  }
}

IndexHeader.propTypes = {
  support: React.PropTypes.string.isRequired
};

export default createContainer((props) => (Session.get('i18n_version'), Session.get('locale'), {...props}), IndexHeader)
