import {createContainer} from 'imports/lib/react_createContainer';
import CardBalance from './CardBalance';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';
import {_ as i18n, usd, capitalizeSentence} from "../../../lib/helpers";

export default createContainer((params) => {

  const checkBalanceStatus = IW.appGetVal('check_balance_status');
  const checkBalanceResponseText = IW.appGetVal('check_balance_response_text');

  const onBack = () => getPageManager().back();
  const onPrint = () => getPageManager().print();

  if (checkBalanceStatus){
    return {
      smallText: i18n('Amount on your account is'),
      bigText: usd(IW.appGetVal('balance_amount')),
      srcImg: '/resources/images/atm/ic_cash.png',
      isSpinner: false,
      onBack, /*onPrint*/
    };
  }

  return {
    smallText: i18n('We can not process the transaction'),
    bigText: i18n(capitalizeSentence(checkBalanceResponseText)),
    srcImg: '/resources/images/atm/ic_error.png',
    isSpinner: false,
    onBack
  };


}, CardBalance);