import {createContainer} from 'imports/lib/react_createContainer';
import EnterUserDataContainer from './EnterUserDataContainer';
import {KEYBOARDS, TRANSFER_INPUTS_RESULT} from 'imports/lib/constants';
import IW from 'imports/iw_api';

export default createContainer((params) => {
  let fields = [
    {
      name: 'code_editable',
      describe: 'Referral code',
      describe_output: 'Referral code',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        required: true,
        minLength: 2,
        maxLength: 28
      }
    },
  ];

  return {
    fields,
    prefix: 'referral',
    onInput: (value) => {
      IW.appSetLocalVal('referral_code_editable', value.toUpperCase());
      IW.checkCustomerReferralCode(value.toUpperCase(), (_, res) => IW.appSetLocalVal('referral_code_valid', !!res));
    },
    onClearInput: () => {
      IW.appSetLocalVal('referral_code_editable', '');
      IW.appSetLocalVal('referral_code_valid', false);
    },
    showNext: IW.appGetVal('customer_can_be_referral') && !!IW.appGetVal('referral_code_valid')
  }
}, EnterUserDataContainer);