import React from 'react';

const InputLabelCustomer = () => {
  return (
    <div className="login-label-message">
      <span className="text-lg upper login-green bold" _>Autofill saves time!</span>
      <span className="login-label-message__tip" _>Login below for quick access</span>
      <img src="/resources/images/login/img_arrow_green.png"/>
    </div>

  );
};

export default InputLabelCustomer;