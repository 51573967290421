import React, {Component} from 'react';
import {FlowRouter} from 'core/Router';
import Button from 'imports/ui/shared/button/Button';
import safeRender from 'imports/ui/decorators/safeRender';

class RefundInfo extends Component {

  render() {
    const {message, refundAmount, canNotRefundReason, onRefund, onMenu, onSupport} = this.props;

    return (
      <table>
        <tbody>
        <tr>
          <td>
            <div className="atm-top-tr">
              <div>
                {canNotRefundReason ?
                  <div className="atm__temp-page atm__display__text" >
                    <span>{canNotRefundReason}</span>
                  </div> :
                  <div className="atm__temp-page atm__temp-page__text">
                    <span>{message}</span>
                  </div>
                }
              </div>

              <div className="atm__temp-page atm__display__img">
                <img src="/resources/images/atm/ic_cash.png" alt=""/>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td style={{textAlign: 'center'}}>
            <Button className="left button" onMouseDown={onSupport} text_="Support"/>
            {canNotRefundReason ?
              <Button id="next" className="right button" onMouseDown={onMenu} text_="Main Menu"/> :
              <Button id="next" className="right button" onMouseDown={onRefund} text_="Refund"/>}
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

export default RefundInfo;