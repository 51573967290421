import {createContainer} from 'imports/lib/react_createContainer';
import TakeActions from './TakeActions';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';
import CONFIG from 'imports/config';
import {getName} from 'imports/lib/helpers';

export default createContainer((params) => {
  const currentKey = IW.appGetVal('pad_key');
  IW.appSetLocalVal('pad_key', '');
  const canExtract = CONFIG.ATM_IGNORE_COUNTERS || !!_.find(IW.appGetVal('dispenser_cash'), (c) => c > 0);
  const canTransfer = !CONFIG.ATM_DISABLE_TRANSFER;

  function handlePadKey() {
    if (currentKey === '1') return handleClickNext('balance');
    if (currentKey === '2' && canExtract) return handleClickNext('withdraw');
    if (currentKey === '3') return handleClickNext('transfer');
    if (currentKey === 'CANCEL') return getPageManager().cancel();
  }

  function handleClickNext(type) {
    IW.appSetVal('selected_action', type);
    getPageManager().next();
  }

  handlePadKey();
  IW.setVoicePage('atm_take_action', {canExtract});
  return {
    fullName: getName(IW.appGetVal('card_name')),
    canExtract,
    canTransfer,
    onNext: handleClickNext,
    onCancel: () => getPageManager().cancel(),
    isEMV: IW.appGetVal('card_emv'),
  };

}, TakeActions);