import { createContainer } from 'imports/lib/react_createContainer';
import { Session } from 'core/session';
import AccountsComponent from './Accounts';
import getPageManager from 'imports/lib/page_manager'
import IW from 'imports/iw_api'

export default createContainer((params) => {
  const currentKey = IW.appGetVal('pad_key');
  IW.appSetLocalVal('pad_key', '');

  function handlePadKey(){
    if (currentKey === '1' || currentKey === '2' || currentKey === '3') return handleClickCard(+currentKey);
    if (currentKey === '#') return getPageManager().back();
    if (currentKey === 'CANCEL') return getPageManager().cancel();
  }

  function handleClickCard(id) {
    IW.appSetVal('selected_account', id);
    getPageManager().next()
  }

  handlePadKey();
  IW.setVoicePage('atm_select_account');
  return {
    accounts: IW.appGetVal('accounts'),
    onBack: () => getPageManager().back(),
    onPrint: () => getPageManager().print(),
    onCardClick: handleClickCard,
  };

}, AccountsComponent);