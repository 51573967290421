import {createContainer} from 'imports/lib/react_createContainer';
import IW from "../../../iw_api";
import Links from "../../../collections/links";
import Apps from "../../../collections/apps";
import PopularProducts from "./PopularProducts";

export default createContainer((params) => {
  const popularProductsId = IW.appGetVal('popular_products_id');
  const popularProducts = []

  if (_.isArray(popularProductsId)) {
    Links.find({product_id: {$in: popularProductsId}, enabled: true, hidden: false}).forEach((product, i) => {
      const currentApp = Apps.findOne({app_guid: product.app_guid});
      popularProducts.push({
        ...currentApp,
        app_guid: product.app_guid,
        start_page: product.start_page,
        product_id: product.product_id,
        index: i,
        selection_priority: 0,
        type: 'application',
        icon: product.icon,
        nm: product.name,
        name: product.name,
        title: product.title,
        force: true,
      })
    });
  }

  return {
    apps: popularProducts.slice(0, 4).chunk(4).map((p) => p.chunk(4)),
  }
}, PopularProducts)










