import {Session} from 'core/session';
import IW from 'imports/iw_api';

export default function (pageBack, pageNext, options, pageLogin = 'on_launch_login') {
  return function () {

    if (_.isFunction(options)) {
      options = options()
    }

    options = _.extend({}, {
      withValidation: true,
      withServerValidation: false,
      shouldCreateCustomer: true,
      allowLandline: false,
      onCreate: () => null,
      onNext: () => pageNext
    }, options);

    const onCreate = options.onCreate;

    const redirect = onCreate();
    if (redirect) return redirect;

    Session.set('phone_is_not_valid', false);

    return {
      next: function* () {
        const pageNext2 = options.onNext && options.onNext() || pageNext;
        return yield* goNext(options, pageNext2)
      },
      login: function* () {

        Session.set("customer_exists", false);
        IW.appSetLocalVal('login_just_verify_and_next', '');
        IW.appSetLocalVal('check_customer_done', false);
        IW.checkCustomer(IW.appGetVal('phone_number').replace(/ /g, '').replace(/\+/, ''), function (_, result) {
          Session.set("customer_exists", result);
          IW.appSetLocalVal('check_customer_done', true);
        });

        yield 'loading app check_customer_done';



        const pageNext2 = options.onNext && options.onNext() || pageNext;
        if (!Session.get('customer_exists')) {
          IW.appSetLocalVal('login_just_verify_and_next', pageNext2);
        }

        return yield* goNext(options, pageLogin, pageNext2)
      },
      back: pageBack,
    }
  }
}

function* goNext(options, pageNext, pageNext2) {
  if (!(yield* isPhoneNumberValid(options))) {
    return 'none';
  }

  if (options.shouldCreateCustomer) {
    return yield* initCustomer(pageNext);
  }

  return IW.appGetVal('is_mobile') ?
    pageNext :
    (pageNext2 || pageNext)
}

function isWPValid(verificationResult, options) {
  //If number is valid
  if (verificationResult.is_valid) {
    const {line_type} = verificationResult;

    if (line_type === 'Mobile') {
      IW.appSetVal('is_mobile', true);
      return true;
    }

    if (options.allowLandline && line_type === 'Landline') {
      IW.appSetVal('is_mobile', false);
      return true;
    }

    return false
  }

  //If number is not valid
  if (!verificationResult.is_valid && verificationResult.error) {
    IW.appSetVal('is_mobile', false);

    return true;
  }
  //Clear values after verification
  Session.set('wp_result', null);
  return false;
}

function* initCustomer(page) {
  IW.appSetLocalVal('customer_id', 0);
  IW.initCustomer();
  yield 'loading app customer_id';
  // Fixme: This handler depends on stored_sessions page
  IW.appSetLocalVal('customer_next_page', page);
  return 'redirect_customer'
}

function* isPhoneNumberValid(options) {
  IW.appSetVal('phone_number_validation', null);

  if (options.withServerValidation) {
    IW.call('validatePhoneNumber');
  } else {
    IW.appSetVal('phone_number_validation', {error: 0});
  }

  yield 'loading app phone_number_validation';
  if (IW.appGetVal('phone_number_validation').error) {
    Session.set('phone_is_not_valid', true);
    return false;
  }

  // ** WHITE PAGES PRO HANDLER ** //

  //*  Landline     : Traditional wired phone lines
  //*  FixedVOIP    : VOIP based fixed line phones
  //*  Mobile       : Wireless phone line
  //*  Voicemail    : Voicemail-only service
  //*  TollFree     : Callee pays for call
  //*  Premium      : Caller pays a premium for the call–e.g. 976 area code
  //*  NonFixedVOIP : Skype, for example
  //*  Other        : Anything that does not match the previous categories
  if (options.withValidation) {
    IW.verifyNumber();
    yield 'loading session wp_result';
    const verificationResult = Session.get('wp_result');
    if (!isWPValid(verificationResult, options)) {

      Session.set('phone_is_not_valid', true);
      return false;
    }
  }

  return true;
}
