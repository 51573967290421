import {Session} from 'core/session'
import IW from 'imports/iw_api'

export default function(pageBack){
  return function(){

    IW.appClearVal('clerk_received_amount');
    IW.appClearVal('clerk_received_display_amount');

    return {
      back: pageBack,
      next: pageBack
    }
  }
}

