import {actions, assert, register, state} from '../../lib/app_flow_testing'
import IW from 'imports/iw_api';
import Products from '../../collections/products';

register({
  app_moneytransfer_sigue: {
    sigue_confirm_sender_data: {
      should_able_confirm: function* () {
        assert.page('sigue_confirm_sender_data')
        yield actions.go('common.simple.should_able_next');
      },
    },

    recipient_choice: {
      should_able_select: function* () {
        assert.page('recipient_choice')
        actions.click('.customer-item__img-recipient', 'No recipients');
        yield actions.loading();
      },
      should_able_skip: function* () {
        assert.page('recipient_choice')
        yield actions.go('common.simple.should_able_next');
      },
    },

    sigue_warning_recipient: {
      should_able_next: function* () {
        if (state().page !== 'sigue_warning_recipient') return;
        yield actions.go('common.simple.should_able_next');
      },
    },

    payout: {
      should_able_cash_pick_up: function* () {
        assert.page('payout');
        actions.mousedown('a[data-i18n_text="Cash Pick Up"]', 'No Cash Pick Up');
        yield actions.loading();
      },
    },

    payment_city_sigue: {
      should_able_find_tijuanana: function* () {
        assert.page('payment_city_sigue');
        actions.mousedown('.input-rtl-direction__clear-button', 'No clear button');
        yield actions.type('tiju')
        yield actions.waitElement('.ellipsis-container', 'Cannot find TIJU');
        actions.mousedown($('.ellipsis-container').eq(0), 'No Cash Pick Up');
        yield actions.waitElement('#next', 'No next', 1000);
        actions.mousedown('#next', 'No next');
        yield actions.loading(60000);
      },
    },

    list_slider: {
      should_able_select: function* () {
        assert.page('list_slider');
        actions.click($('.app_icon').eq(0), 'No Payers');
        yield actions.loading();
      },
    },

    sigue_payment_locations_list_slider: {
      should_able_select_or_skip: function* () {
        if (state().page !== 'sigue_payment_locations_list_slider') return;
        actions.click($('.app_icon').eq(0), 'No Locations');
        yield actions.loading();
      },
    },

    recipient_notification: {
      should_able_skip: function* () {
        assert.page('recipient_notification');
        actions.mousedown($('.button-primary').eq(2), 'No Skip Button');
        yield actions.loading();
      },
    },

    sigue_enter_recipient_data: {
      should_able_fill_data: function* () {
        assert.page('sigue_enter_recipient_data');
        if ($('.left-side').length !== 7) throw Error('Unexpected count of lines')
        yield actions.sleep(100)
        // first name
        yield actions.mousedown('.input-rtl-direction__clear-button', 'No clear button');
        yield actions.type('test');
        yield actions.mousedown('#next', 'No Next');

        // middle name
        yield actions.mousedown('#next', 'No Next');

        // last name
        yield actions.mousedown('.input-rtl-direction__clear-button', 'No clear button');
        yield actions.type('test');
        yield actions.mousedown('#next', 'No Next');

        // second last name
        yield actions.mousedown('#next', 'No Next');

        // currency
        yield actions.mousedown('#next', 'No Next');
        // phone
        yield actions.mousedown('#next', 'No Next');
        // address
        if ($('.left-side').eq(6).text() !== $('.enter-user-data__input-describe').text()) throw Error('Unexpected position')
        yield actions.go('common.simple.should_able_next');
      },
    },

    enter_amount_transfer: {
      should_able_enter_20: function* () {
        assert.page('enter_amount_transfer');
        yield actions.sleep(100)
        yield actions.mousedown('.cleared-input__clear-button', 'No clear button');
        yield actions.mousedown($('#numpad td a').eq(1), 'No 2')
        yield actions.mousedown($('#numpad td a').eq(10), 'No 0')
        yield actions.go('common.simple.should_able_next');
      }
    },

    sigue_warning: {
      should_able_skip: function* () {
        if (state().page !== 'sigue_warning') return;
        yield actions.go('common.simple.should_able_next');
      }
    },

    confirm_transfer: {
      should_able_confirm: function* () {
        assert.page('confirm_transfer');
        yield actions.go('common.simple.should_able_next');
      }
    },
  }
})

register({
  simple_cases: {
    app_moneytransfer_sigue: {
      launch_login_flow: function* () {
        IW.launchProduct(Products.findOne({app_guid: 'app_moneytransfer_sigue'})._id);
        yield actions.waitLoading();
        yield actions.go('common            | flow                  | should_continue_phone_9292297774');
        // yield actions.go('common            | simple                | should_able_next');
        yield actions.go('common            | stored_sessions       | should_skip');
        yield actions.go('common            | pin_number_sing_in    | should_allow_pin_1111');
      },

      general_flow: function* () {
        yield actions.go('simple_cases          | app_moneytransfer_sigue | launch_login_flow');

        yield actions.go('app_moneytransfer_sigue | sigue_confirm_sender_data           | should_able_confirm');
        yield actions.go('app_moneytransfer_sigue | recipient_choice                    | should_able_select');
        yield actions.go('app_moneytransfer_sigue | sigue_warning_recipient             | should_able_next');
        if (state().page === 'confirm_payout') {
          yield actions.go('common.simple.should_able_next');
        } else {
          yield actions.go('app_moneytransfer_sigue | payout                              | should_able_cash_pick_up');
          yield actions.go('app_moneytransfer_sigue | payment_city_sigue                  | should_able_find_tijuanana');
          yield actions.go('app_moneytransfer_sigue | list_slider                         | should_able_select');
          yield actions.go('app_moneytransfer_sigue | sigue_payment_locations_list_slider | should_able_select_or_skip');
          yield actions.go('app_moneytransfer_sigue | recipient_notification              | should_able_skip');
          yield actions.go('app_moneytransfer_sigue | sigue_enter_recipient_data          | should_able_fill_data');
        }
        yield actions.go('app_moneytransfer_sigue | enter_amount_transfer               | should_able_enter_20');
        yield actions.go('app_moneytransfer_sigue | sigue_warning                       | should_able_skip');
        yield actions.go('app_moneytransfer_sigue | confirm_transfer                    | should_able_confirm');
        assert.page('pay_transfer');
      },

      new_recipient_flow: function* () {
        yield actions.go('simple_cases          | app_moneytransfer_sigue | launch_login_flow');

        yield actions.go('app_moneytransfer_sigue | sigue_confirm_sender_data           | should_able_confirm');
        yield actions.go('app_moneytransfer_sigue | recipient_choice                    | should_able_skip');
        yield actions.go('app_moneytransfer_sigue | payout                              | should_able_cash_pick_up');
        yield actions.go('app_moneytransfer_sigue | payment_city_sigue                  | should_able_find_tijuanana');
        yield actions.go('app_moneytransfer_sigue | list_slider                         | should_able_select');
        yield actions.go('app_moneytransfer_sigue | sigue_payment_locations_list_slider | should_able_select_or_skip');
        assert.page('recipient_notification');
      },
    }
  }
})
