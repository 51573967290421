import React from 'react';
import KeyboardKey from './KeyboardKey';
import keyboardOnPress from '../../decorators/keyboardOnPress';

class Keyboard extends React.Component {
  render() {
    const {keys, onPress, className, style} = this.props;

    return (
      <div id="keyboard" className={className} style={style}>
        {keys.map((row, r) => (
          <div key={r} className="keyboard-row">
            {row.map((key, k) => (
              <KeyboardKey key={k} keyName={key} onPress={onPress}/>
            ))}
          </div>
        ))}
      </div>
    )
  }
}

Keyboard.propTypes = {
  keys: React.PropTypes.arrayOf(React.PropTypes.arrayOf(React.PropTypes.string)),
  onPress: React.PropTypes.func,
  className : React.PropTypes.string,
  style: React.PropTypes.object,
};

Keyboard.defaultProps = {
  className: "",
  style: {}
};

export default keyboardOnPress(Keyboard)
