import React, {Component} from 'react';
import SliderPageTmp from 'imports/ui/shared/slider_page_tmp/SliderPageTmpl';
import { SLIDE_ITEM_TYPES } from 'imports/lib/constants';
import SearchLayout from '../search/SearchLayoutContainer';

export default class NewList extends Component {
  render() {
    const {choices, onBack} = this.props;
    const pages = {
      prev: 'menu',
      next: 'menu'
    };

    return (
      <SearchLayout>
        <SliderPageTmp className="list__container" type={SLIDE_ITEM_TYPES.ICON} choices={choices} pages={pages} />
      </SearchLayout>
    )
  }
}

NewList.propTypes = {
  choices: React.PropTypes.array,
  onBack: React.PropTypes.func,
};
