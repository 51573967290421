import {Session} from 'core/session';
import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import CONFIG from 'imports/config';
import {countInDomAmounts, _ as i18n} from 'imports/lib/helpers';
import Search from './Search';
import Links from 'imports/collections/links';
import {sprintf} from 'imports/lib/external/sprintf';
import IW from 'imports/iw_api'
import {KEYBOARD_TEXT} from 'imports/lib/constants'

export default createContainer(({params}) => {
  const ROWS_COUNT = countInDomAmounts();
  const ITEM_COUNT = 5;

  var results = [];
  if (Session.get('search_string') && Session.get('search_string').trim().length >= 2) {
    var searchQuery = Session.get('search_string').replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    var re = new RegExp(searchQuery, "i");

    var array = Links.find({
      keywords: {$not: undefined},
      // enabled: true,
      $or: [{name: re}, {keywords: {$in: [re]}}],
      hidden: false
    }, {
      limit: ROWS_COUNT*ITEM_COUNT,
      sort: {priority: 1, name: 1}
    }).fetch();

    if (array.length)
      results = array.complete(ROWS_COUNT*ITEM_COUNT, {}).chunk(ITEM_COUNT);
  }

  return {
    searchString: Session.get('search_string'),
    onClear: () => Session.set('search_string', ''),
    results: results,
    keyboardLayout: KEYBOARD_TEXT,
    links: {
      category: FlowRouter.path("Category", {
        language: FlowRouter.getParam("language"),
        categoryName: Session.get('last_category') || IW.getPriorityCategory()
      })
    },
    errorText: function () {
      if (Session.get('search_string') && Session.get('search_string').trim().length >= CONFIG.SEARCH_MINLEN)
        return sprintf(i18n('Nothing found for "%s"'), Session.get('search_string')); // no results found
      else if (Session.get('search_string').trim().length < CONFIG.SEARCH_MINLEN)
        return i18n('Please enter your search query'); // one letter
      return i18n('No results found');
    },
    onQuery: (v) => {
      Session.set('search_string', v)
    }
  };

}, Search);
