import React from 'react';
import Button from 'imports/ui/shared/button/Button';

export default class PaginationArrows extends React.Component {

  render() {

    let {items} = this.props;

    if(!(items.length && items.length > 1)) return null;

    return (
      <div className="slideSelectors" style={{top: "35%", zIndex: 0}}>
        <Button className="left-arrow left-arrow-pagination button upper prev button-slider" text="&lt;"/>
        <Button className="right-arrow right-arrow-pagination button upper next button-slider" text="&gt;"/>
      </div>
    );

  }
}

PaginationArrows.propTypes = {
  items: React.PropTypes.array
};