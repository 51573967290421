import {Session} from 'core/session';
import IW from 'imports/iw_api';
import {FlowRouter} from 'core/Router';

export default function (pageBack, pageNext, pageError) {
  return function*() {
    IW.appSetVal('image_name', '');

    IW.appSetLocalVal('camera_preview_done', false);
    IW.callCamera('preview');
    yield 'loading app camera_preview_done';
    showPreview(Session.get('session_guid'));

    return {
      back: pageBack,
      scan: function*() {
        scanImage();
        Session.set('bill_scanning_loading_state', 0);
        yield 'bill_scanning_loading';
        if (!IW.appGetVal('image_name')) return pageError;
        Session.set('bill_scanning_loading_state', 3);
        yield 'sleep 500';
        return pageNext;
      }
    }
  }
}

// not_initialized, connecting, preview, sending, error,


function showPreview(sessionGuid) {
  function routine() {
    const path = IW.getPath() + Session.get('session_guid');
    if (path !== checkPath) {
      IW.callCamera('stop', sessionGuid);
      IW.callCamera('scan', sessionGuid);
      return;
    }
    setTimeout(routine, 500);
  }

  let checkPath = '';

  setTimeout(function () {
    if (sessionGuid !== Session.get('session_guid')) throw Error('Session is finished');
    checkPath = IW.getPath() + sessionGuid;
    routine();
  }, 100);
}

function scanImage() {
  IW.callCamera('stop');
  IW.callCamera('image');
}
