import AdvLoading from './AdvLoading';
import {i18n} from 'imports/lib/helpers';
import {createContainer} from 'imports/lib/react_createContainer';
import Products from 'imports/collections/products';

let randomValue = 0;
setInterval(() => (randomValue = Math.random()), 10000); // Fixme

export default createContainer(() => {
  // {
  //   bigImage: string | {src: string, alt: string},
  //   bigText: string,
  //   middleText: string,
  //   captionText: string,
  //   images: [string | {src: string, alt: string}],
  //   bottomText: string,
  // }

  const allAppGuids = _.uniq(_.map(Products.find({}).fetch(), (p) => p.app_guid));
  const variants = [{
    middleText: i18n('Thank you for using Maya!'),
    captionText: i18n('You can also reload international phones here!'),
    bottomText: i18n('and more'),
    images: [
      {src: '/resources/images/advertise/moviestar.svg', alt: 'Movistar'},
      {src: '/resources/images/advertise/claro.svg', alt: 'Claro'},
      {src: '/resources/images/advertise/telcel.svg', alt: 'Telcel'},
      {src: '/resources/images/advertise/digicel.svg', alt: 'Digicel'},
      {src: '/resources/images/advertise/tigo.svg', alt: 'Tigo'},
    ],
    showFor: [/^app_phonereload_rrzQh/],
    showIn: [
      /^app_atm_dGer/,
      /^app_cardreload_/,
      /^app_cashcheck_/,
      /^app_giftcards_/,
      /^app_moneytransfer_/,
      /^app_phonereloadus_/,
      /^app_pinproducts_/,
      /^app_referrerdispense_/,
      /^app_sweepstakes_/,
      /^app_billpay_/,
      /^app_moneytransfer_/
    ]
  }, {
    middleText: i18n('Thank you for using Maya!'),
    captionText: i18n(`Don’t just reload their phone, send money as well!`),
    bottomText: i18n('and more'),
    images: [
      {src: '/resources/images/advertise/Mexico.svg', alt: 'Mexico'},
      {src: '/resources/images/advertise/Jamaica.svg', alt: 'Jamaica'},
      {src: '/resources/images/advertise/El_Salvador.svg', alt: 'El Salvador'},
      {src: '/resources/images/advertise/Honduras.svg', alt: 'Honduras'},
      {src: '/resources/images/advertise/Guatemala.svg', alt: 'Guatemala'},
      {src: '/resources/images/advertise/Dominican_Republic.svg', alt: 'Dominican_Republic'},
    ],
    showFor: [/^app_moneytransfer_/],
    showIn: [/^app_phonereload_rrzQh/]
  }, {
    middleText: i18n('Thank you for using Maya!'),
    captionText: i18n(`Don’t just pay your utility bill, pay your phone bill here as well!`),
    bottomText: i18n('and more'),
    images: [
      {src: '/resources/images/advertise/t_mobile.svg', alt: 'T-Mobile'},
      {src: '/resources/images/advertise/at_and_t.svg', alt: 'at&t'},
      {src: '/resources/images/advertise/boost.svg', alt: 'Boost'},
      {src: '/resources/images/advertise/sprint.svg', alt: 'Sprint'},
      {src: '/resources/images/advertise/metro.svg', alt: 'metroPCS'},
      {src: '/resources/images/advertise/verizon.svg', alt: 'Verizon'},
    ],
    showFor: [/^app_phonereloadus_/],
    showIn: [/^app_billpay_/]
  }, {
    middleText: i18n('Thank you for using Maya!'),
    captionText: i18n(`Reload your phone and pay your bills all in one place!`),
    bottomText: i18n('and more'),
    images: [
      {src: '/resources/images/advertise/socalgas.svg', alt: 'SoCalGas'},
      {src: '/resources/images/advertise/directv.svg', alt: 'Directv'},
      {src: '/resources/images/advertise/la.svg', alt: 'LA DWP'},
      {src: '/resources/images/advertise/edison.svg', alt: 'Edison International'},
      {src: '/resources/images/advertise/spectrum.svg', alt: 'Spectrum'},
    ],
    showFor: [
      /^app_phonereloadus_/,
      /^app_billpay_/
    ],
    showIn: [
      /^app_atm_dGer/,
      /^app_cardreload_/,
      /^app_cashcheck_/,
      /^app_giftcards_/,
      /^app_moneytransfer_/,
      /^app_phonereload_/,
      /^app_pinproducts_/,
      /^app_referrerdispense_/,
      /^app_sweepstakes_/
    ]
  }, {
    middleText: i18n('Thank you for using Maya!'),
    bigImage: '/resources/images/advertise/cash_a_check.svg',
    bigText: i18n(`Cash your checks here!`),
    showFor: [/^app_cashcheck_/],
    showIn: [
      /^app_atm_dGer/,
      /^app_cardreload_/,
      /^app_cashcheck_/,
      /^app_giftcards_/,
      /^app_moneytransfer_/,
      /^app_phonereload_/,
      /^app_phonereloadus_/,
      /^app_pinproducts_/,
      /^app_referrerdispense_/,
      /^app_sweepstakes_/,
      /^app_billpay_/,
      /^app_moneytransfer_/
    ]
  }];

  const possibleVariants = _.filter(variants, (v) => {
    if (!_.find(v.showIn, (reg) => reg.test(Session.get('app_guid')))) return false;
    return !!_.find(v.showFor, (reg) => !!_.find(allAppGuids, (a) => reg.test(a)));
  });
  if (_.isEmpty(possibleVariants)) return {default: true};

  return possibleVariants[Math.floor(randomValue * possibleVariants.length)];
}, AdvLoading);