import IW from 'imports/iw_api';

export default function (pageBack, pageNext, pageBankPage, onLoginBackPage, preNextGenerator) {
  return function*() {

    Session.set('money_transferring_payers', null);
    IW.callWithUpdate('getPayers', 'money_transferring_payers');
    yield 'loading session money_transferring_payers';

    if (_.isEmpty(Session.get('money_transferring_payers'))) {
      return pageBack
    }

    return {
      back: function () {
        if(IW.appGetVal('logged') && !IW.appGetVal('new_recipient') && !IW.appGetVal('is_payer_edit')) {
          return onLoginBackPage;
        }
        return pageBack;
      },
      next: function*(){
        yield 'loading app network_id';
        const payer = _.find(Session.get('money_transferring_payers'), (p) => p.network_id === IW.appGetVal('network_id'));
        if (!payer) return 'exit';

        IW.appSetVal('payer_id', payer.payer_id);
        IW.appSetVal('payer_rate', payer.rate);
        IW.appSetVal('payer_branch_id', payer.branch_id);
        IW.appSetVal('payer_fax', payer.payer_fax);

        IW.appSetVal('agent_age_agent_id', payer.agent_age_agent_id);
        IW.appSetVal('agent_age_code', payer.agent_age_code);
        IW.appSetVal('agent_age_city_id', payer.agent_age_city_id);
        IW.appSetVal('agent_age_fx', payer.agent_age_fx);
        IW.appSetVal('agent_dep_agent_id', payer.agent_dep_agent_id);
        IW.appSetVal('agent_loc_agent_id', payer.agent_loc_agent_id);
        IW.appSetVal('agent_loc_internal_code', payer.agent_loc_internal_code);
        IW.appSetVal('agent_loc_phone', payer.agent_loc_phone);
        IW.appSetVal('agent_loc_address', payer.agent_loc_address);
        IW.appSetVal('agent_payment_service_id', payer.agent_payment_service_id);

        IW.appSetVal('payer_sender_limit', payer.max_send);
        IW.appSetVal('original_max_amount', 0);
        IW.call('getPayerLimit');
        yield 'loading app payer_sender_limit';
        IW.call('updateAmounts');
        yield 'loading app original_max_amount';

        if (IW.appGetVal('recipient_payment_mode_type') === 'bank_deposit' && payer.bank_id) {
          IW.appSetVal('payer_bank_id', payer.bank_id);
          IW.appSetVal('payer_bank_name', payer.bank_name);
          IW.appSetVal('payer_name', '');
          IW.call('getRecipientBankInfo');
          yield 'loading app bank_inited';
          return pageBankPage;
        }

        IW.appSetVal('payer_bank_id', '');
        IW.appSetVal('payer_bank_name', '');
        IW.appSetVal('payer_name', payer.payer_name);

        if(preNextGenerator) yield *preNextGenerator();
        return pageNext;
      }
    }
  }
}
