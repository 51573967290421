import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import SignInPhoneNumber from './SignInPhoneNumber';
import IW from 'imports/iw_api';
import Prods from 'imports/collections/products';
import SimpExp from 'imports/lib/external/simpexp';
import getPageManager from 'imports/lib/page_manager';
import Countries from 'imports/collections/countries';
import {Session} from 'core/session';
import CONFIG from 'imports/config';
import {N, S} from 'imports/lib/helpers';

const DEFAULT_MASK = '\\d{12}';//'\\+1 [2-9|0]\\d{2} \\d{3} \\d{4}';
const US_MASK = '\\+1 [2-9|0]\\d{2} \\d{3} \\d{4}';

export default createContainer((params) => {
  const NO_CHAR = 'ё';
  const NO_CHAR_REG = /ё/;

  const product = Prods.findOne(N(FlowRouter.getParam("productId")));

  const isSenderNumber = params.isSenderNumber;
  const isRecipientNumber = params.isRecipientNumber;
  const isSupportNumber = params.isSupportNumber;
  const showLogin = params.showLogin;
  const prefix = params.prefix ? params.prefix + '_' : '';
  const sessionField =  params.customSessionField || 'app';
  const useDefaultMask = params.useDefaultMask && DEFAULT_MASK;
  const useUSMask = params.useUSMask && US_MASK;
  const withoutBackButton = params.withoutBackButton;

  function sessionSet(field, value) {
    if(sessionField === 'app'){
      return IW.appSetVal(field, value)
    }

    return IW.sessionSetVal(sessionField, field, value);
  }

  function sessionGet(field) {
    if(sessionField === 'app'){
      return IW.appGetVal(field);
    }

    return IW.sessionGetVal(sessionField, field);
  }

  function getNumber() {
    return (
      getAdditionalNumber(prefix) ||
      IW.beginningByPattern(new SimpExp(getPattern()).gen(NO_CHAR), NO_CHAR)
    )
  }

  // Logic if user to input some wrong numbers
  function partialCountryMaskCheck(number) {
    if (!number) {
      return;
    }

    const {PARTIAL_MATCHING, BAD_PARTIAL_MATCHING} = CONFIG.PARTIALS_REGEX;

    number = number.replace(/ /g, '').replace(/\+/, '');
    if (!(product && product.mask)) {
      const countryCodesForList = getNumberCountries(number);
      // console.log('PhoneNumberContainer | partialCountryCheck | number, countryCodesForList : ', number, countryCodesForList);
      // Check on 'bad' matching. In case +1 1.. ... .... - this is unknown country, use alternative pattern
      const badMatching = BAD_PARTIAL_MATCHING[number[0]];
      if (badMatching && badMatching.number.test(number)) {
        console.log('PhoneNumberContainer | partialCountryCheck |badMatching ', badMatching);
        setPattern(badMatching.code, badMatching.pattern);
        return;
      }

      countryCodesForList.forEach(function (item) {
        let hasPartialRegex = PARTIAL_MATCHING[item.iso3];
        if (hasPartialRegex) {
          if (hasPartialRegex.control_length <= number.length) {
            console.log('PhoneNumberContainer | partialCountryCheck | hasPartialRegex, number  : ', hasPartialRegex, number);
            console.log('PhoneNumberContainer | partialCountryCheck | is match : ', hasPartialRegex.number.test(number));
            if (hasPartialRegex.number.test(number)) {
              console.log('PhoneNumberContainer | partialCountryCheck | country must set : ', item.iso3);
              setPatternByCountryId(item._id);
            }
          }
        }
      });
    }
  }

  function getInternationalNumber() {
    const pattern = getPattern();
    let internationalPattern = '';
    const number = getNumber().replace(/[^0-9]/g, '');
    if (/\[.*\+[^\]]*]|\\\+/g.test(pattern)) {
      internationalPattern = pattern;
    } else if(product){
      const country = Countries.findOne({iso3: product.country_iso3});
      if(!country) internationalPattern = DEFAULT_MASK;
      else internationalPattern = country.pattern
    } else {
      internationalPattern = DEFAULT_MASK
    }

    const code = new SimpExp(internationalPattern).gen('0').replace(/[^0-9]/g, '')
    const mask = new SimpExp(pattern || internationalPattern).gen('0').replace(/[^0-9]/g, '')
    const masked = number + mask.slice(number.length)
    return code.slice(0, -masked.length) + masked
  }

  function getPattern() {
    return US_MASK;
  }

  //Set pattern by checked country from DropdownList
  function setPatternByCountryId(id) {
    const country = Countries.findOne({_id: id});

    if (country) {
      const {code, pattern} = country;
      setPattern(code, pattern);
    } else {
      //Session.set('checked_mask', null); // TODO: investigate why it is needed
    }
  }

  function setPattern(code, pattern) {
    Session.set('checked_mask', pattern);
    const number = formatNumberByPattern(getNumber(), code, pattern);
    IW.beginningByPattern(new SimpExp(pattern).gen(NO_CHAR), NO_CHAR);
    console.log('PhoneNumberContainer | setPattern | number, pattern : ', number, pattern);
    setAdditionalNumber(prefix, number);
  }

  function getNumberCountries(number) {
    number = number.replace(/[^0-9]/g, '');
    if (!number) return [];

    let countryCodesForList = Countries.find().fetch().filter(function (item) {
      const code = item.code.replace(/[^0-9]/g, '');
      const minLenght = Math.min(number.length, code.length);
      return number.substr(0, minLenght) === code.substr(0, minLenght);
    });

    let maxLength = 0;
    countryCodesForList.forEach((item) => {
      const code = item.code.replace(/[^0-9]/g, '');

      if (code === number.substr(0, code.length) && maxLength < code.length)
        maxLength = code.length;
    });
    countryCodesForList = countryCodesForList.filter(c => {
      return c.code.replace(/ /g, '').replace(/\+/, '').length >= maxLength;
    });
    return countryCodesForList;
  }

  function setNumber(number) {
    //From this moment user deleted number from mask in input
    //We restore 'checked_mask' and phone number displayed without '+' and spaces
    let checkedMask = getPattern();
    if (!(product && product.mask) && checkedMask && !(isRecipientNumber || isSenderNumber || isSupportNumber)) {
      if (number && number.length < (new SimpExp(checkedMask).gen(' ')).trim().length) {
        number = number.replace(/ /g, '').replace(/\+/, '');
        Session.set('checked_mask', null);
        checkedMask = null;
      } else if (!number) {
        Session.set('checked_mask', null);
        checkedMask = null;
      }
    }

    //User are inputting number for product, that haven't mask, we show him available country for to check pattern
    if (!(product && product.mask) && !checkedMask && !!number) {
      //Request to base
      const countryCodesForList = getNumberCountries(number);

      //Last code in list - we must check this automatically
      if (countryCodesForList.length === 1) {
        setAdditionalNumber(prefix, number);
        setPatternByCountryId(countryCodesForList[0]._id);
        return;
      }
    }

    setAdditionalNumber(prefix, number);

    partialCountryMaskCheck(number);
  }

  //use when user set input '12345' and check pattern from Dropdownlist '+1 23'
  //the result will '+1 23 45 ...' or else, such as checked pattern
  function formatNumberByPattern(number, code, pattern) {
    const numberWithoutCode = number
      .replace(/[^0-9]/g, '')
      .slice(code.replace(/[^0-9]/g, '').length);
    let realNumber = new SimpExp(pattern).gen(NO_CHAR);

    for (let i = 0; i < numberWithoutCode.length; i++) {
      realNumber = realNumber.replace(NO_CHAR_REG, numberWithoutCode[i])
    }

    return realNumber.split(NO_CHAR)[0];
  }

  function goNext() {
    sessionSet('phone_number', getInternationalNumber());
    // setAdditionalNumber(prefix, getNumber());
    getPageManager().next();
  }

  function goBack() {
    removeAlerts();
    // TODO: On support page back button should not clear it
    // sessionSet('number', '');
    getPageManager().back();
  }

  function goLogin() {
    sessionSet('phone_number', getInternationalNumber());
    getPageManager().login();
  }

  function prepareCountryCodesToDropdownList(array) {
    if (!_.isArray(array) || getPattern()) {
      return [];
    }

    array.sort((a, b) => {
      return +a.dialing_prefix > +b.dialing_prefix ? 1 : -1;
    });

    return array.map(function (item) {
      return _.extend({}, {
        key: item._id,
        left_text: item.code,
        main_text: item.country
      });
    });
  }

  function removeAlerts() {
    sessionSet('phone_is_not_valid', false);
    Session.set('user_not_found', false);
  }

  function clearInput() {
    removeAlerts();
    setNumber('');
  }

  function getValue(name) {
    return sessionGet(name);
  }

  function getAboveText() {
    if (isSenderNumber) return 'Enter your mobile phone number:';
    if (isRecipientNumber) return 'Enter mobile phone number of recipient:';
    if (isSupportNumber) return 'Enter your phone number for support call:';

    return 'Enter your phone number:';
  }
  function getUnderText() {
    if (sessionGet('phone_is_not_valid')){
      return 'You can only access your profile after making a transaction. Please go to the main menu to make a payment.';
    }

    return 'Please make sure your phone number is correct';
  }

  function setAdditionalNumber(p, number) {
    sessionSet((p || '') + 'number', number);
  }

  function getAdditionalNumber(p) {
    return sessionGet((p || '') + 'number');
  }

  function tryGetCustomerByNumber(number) {
    if(isNumberValid && showLogin){
      IW.checkCustomer(number.replace(/ /g, '').replace(/\+/, ''), function (_, result) {
        Session.set("customer_exists", result);
      });
    } else {
      Session.set("customer_exists", false);
    }
  }

  function checkCountryPattern(number) {
    const country = Countries.findOne({pattern: CONFIG.NUMBER_PATTERN});
    const countryCode = (country && country.code.replace(/[^0-9]/g, '')) || DEFAULT_MASK;
    const startWithCountryCode = S(number).indexOf(countryCode) === 0;
    const checkLength = S(number).length === countryCode.length + 1;
    const checkByPattern = new RegExp(CONFIG.NUMBER_PATTERN).test(new SimpExp(CONFIG.NUMBER_PATTERN).gen(S(number).slice(countryCode.length)));
    const isDefaultMask = Session.get('checked_mask') === DEFAULT_MASK;

    if (startWithCountryCode && checkLength && checkByPattern && isDefaultMask) {
      Session.set('checked_mask', null);
      setNumber(formatNumberByPattern(number, countryCode, getPattern()));
    }
  }

  const numpad_layout = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9],
    ['clear', 0, 'delete']
  ];

  const isNumberValid = !!(getAdditionalNumber(prefix) &&
    (new RegExp(getPattern() || DEFAULT_MASK).test(getAdditionalNumber(prefix))));

  const inputNumber = getNumber().replace(/[^0-9]/g, '');

  if (sessionField !== 'app' && Session.get(sessionField).number === '' && !Session.get('checked_mask')) {
    Session.set('checked_mask', DEFAULT_MASK);
  } else {
    checkCountryPattern(inputNumber);
  }


  // TODO: Replace it with product props
  if (isSenderNumber || isRecipientNumber){
    let iso2 = 'US';
    if (isRecipientNumber) iso2 = sessionGet("recipient_country_iso");
    const country = Countries.findOne({iso2});
    if (country) setPatternByCountryId(country.country_id);
  }

  const currentNumber = getNumber();

  tryGetCustomerByNumber(currentNumber);

  const isCustomer = Session.get("customer_exists") && CONFIG.LOGIN_ENABLED && !sessionGet('login_disabled');

  const re = {
    appGuid: params.appGuid,
    pages: params.pages,
    numpadLayout: numpad_layout,
    numberPattern: getPattern() || DEFAULT_MASK, //'\\+1 [2-9|0]\\d{2} \\d{3} \\d{4}',
    number: currentNumber,
    onPrintNumber: (v) => {
      removeAlerts();
      setNumber(v);
    },
    goNext: goNext,
    goBack: goBack,
    goLogin: goLogin,
    inputWidth: (new SimpExp(getPattern() || DEFAULT_MASK).gen('0').length) * 30 + 10,
    countryCodes: prepareCountryCodesToDropdownList(getNumberCountries(currentNumber) || []),
    setPatternByCountryId: setPatternByCountryId,
    canModifyPattern: !(product && product.mask),
    clearInput,
    focus: getValue('focus'),
    abovePhoneText: getAboveText(),
    underPhoneText: getUnderText(),
    isNumberOk: isNumberValid,
    isUnderPhoneTextError: !!sessionGet('phone_is_not_valid'),
    isHeaderShowed: isSenderNumber || isRecipientNumber,
    isCustomer: isCustomer,
    sessionSet,
    sessionGet,
    withoutBackButton,
    userNotFound: !!Session.get('user_not_found')
  }

  console.log('result ====>', re);

  return re;

}, SignInPhoneNumber);

