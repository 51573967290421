/**
 * Created by vitaliy on 17.03.17.
 */
import {createContainer} from 'imports/lib/react_createContainer';
import ConfirmTransfer from './ConfirmTransfer';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api'
import moment from 'imports/lib/external/moment-with-langs'
import {capitalize} from 'imports/lib/helpers'

export default createContainer((params) => {
  moment.lang(Session.get('locale'))
  const userFrom = {
    name : _.compact([
      IW.appGetVal('sender_first_name'),
      IW.appGetVal('sender_middle_name'),
      IW.appGetVal('sender_last_name')
    ]).join(' ').replace(/\s+/g, ' '),
    phone : IW.appGetVal('sender_number'),
    email : IW.appGetVal('sender_email'),
  };

  const userFromBirthDate = IW.appGetVal('sender_birth_day') || IW.appGetVal('required_sender_birth_day')
  if (userFromBirthDate){
    userFrom.birthDate = capitalize(moment(+userFromBirthDate).format('MMMM D, YYYY'))
  }

  const userTo = {
    name : _.compact([
      IW.appGetVal('recipient_first_name'),
      IW.appGetVal('recipient_middle_name'),
      IW.appGetVal('recipient_last_name'),
      IW.appGetVal('recipient_mother_name')
    ]).join(' ').replace(/\s+/g, ' '),
    phone : IW.appGetVal('recipient_number'),
    email : IW.appGetVal('recipient_email'),
  };

  const userToBirthDate = IW.appGetVal('recipient_birth_day') || IW.appGetVal('required_recipient_birth_day')
  if (userToBirthDate){
    userTo.birthDate = capitalize(moment(+userToBirthDate).format('MMMM D, YYYY'))
  }

  moment.lang('en_US');

  const via = IW.appGetVal('service_name');

  if (IW.appGetVal('disclosure_email')) {
    getPageManager().email();
  }

  return {
    userFrom,
    userTo,
    via,
    country: IW.appGetVal('recipient_address_country'),
    onBack: () => getPageManager().back(),
    onNext: () => getPageManager().next(),
    disclosureOnRight: IW.appGetVal('service_name') === 'sigue'
  }
}, ConfirmTransfer);
