import {Tracker} from 'core/tracker'
import {Session} from 'core/session'
import IW from '../../iw_api'
import CONFIG from '../../config'
import * as Timer from 'imports/api/timer'
import moment from 'imports/lib/external/moment-with-langs'


Tracker.autorun(function () {
  var scope = '';
  var locale = Session.get('locale');
  if (typeof IW != 'undefined') {
    scope = Session.get('app_guid');
  }

  // Fixme: deprecated
  // Meteor.subscribe('i18n', locale, scope);
  // if (typeof IW != 'undefined') {
  //   IW.getLocale(locale, scope);
  // }
});
// Meteor.subscribe('i18n'); // I18n

Session.setDefault('locale', CONFIG.DEFAULT_LANGUAGE);
Session.setDefault('layout', '');          // Page layout type, '' by default for index

Session.setDefault('app', {}); // Session data of the currently running application

// Search string and timeout
Session.setDefault('search_string', '');

Meteor.startup(function () {
  // Live clock
  function time() {
    const locale = Session.get('locale');
    moment.lang(locale);

    Session.set("time", moment().format(CONFIG.CLOCK_FORMAT));
    Session.set("time_full", moment().format(CONFIG.CLOCK_FULL_FORMAT));
  }

  time();
  Meteor.setInterval(time, 5000);

  Timer.init();

  Tracker.autorun(function () {
    console.log('APP_GUID changed to', Session.get('app_guid'));
  });

  Tracker.autorun(function () {
    time();
  });

  console.log('Clamshell version', CONFIG.VERSION);
});
