import {createContainer} from 'imports/lib/react_createContainer';
import CONFIG from 'imports/config';
import {hasVariant, number as N, predictNext, S, triggerMouseEvent, i18n} from 'imports/lib/helpers';
import EnterAmount from './EnterAmount';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';

export default createContainer((params) => {
  console.log('EnterAmountContainer | params ', params);
  const currentKey = IW.appGetVal('pad_key');
  IW.appSetLocalVal('pad_key', '');

  const account = _.find(IW.appGetVal('accounts'), (a) => a.id === IW.appGetVal('selected_account'));
  if (!account) throw Error('Account not found');
  const currency = '$';
  const amount = IW.appGetVal('selected_amount_string');
  const numpadLayout = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9],
    ['clear', 0, 'delete']
  ];
  const maxAmount = params.extract ?
    CONFIG.ATM_MAX_DISPENSE :
    CONFIG.ATM_MAX_TRANSFER;
  const minAmount = params.extract ?
    CONFIG.ATM_MIN_DISPENSE :
    CONFIG.ATM_MIN_TRANSFER;
  const customerMaxAmount = Math.min(N(account.amount || maxAmount), maxAmount);

  const cashMovement = CONFIG.ATM_IGNORE_COUNTERS ?
    {1: maxAmount} :
    (params.extract ?
      IW.appGetVal('dispenser_cash') :
      {1: customerMaxAmount});

  const lockedKeys = params.extract ?
    getUnAvailableNums(amount).map((item) => +item) :
    getSimpleUnAvailableNums(amount).map((item) => +item);

  const showConfirm = (!params.extract || hasVariant(N(amount),
      _.keys(cashMovement),
      _.values(cashMovement))) &&
    N(amount) <= N(customerMaxAmount) && N(amount) >= N(minAmount);

  function onValueChange(key) {
    IW.appSetLocalVal('selected_amount_string', inputToNumberStr(key));
    IW.appSetVal('selected_amount', N(inputToNumberStr(key)));
  }

  function getAvailableNums(value, maxValue) {
    return predictNext(S(value), maxValue,
      _.keys(cashMovement),
      _.values(cashMovement));
  }

  function getUnAvailableNums(value) {
    const arr = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    const avalNumber = getAvailableNums(value, customerMaxAmount);
    return arr.filter((item) => {
      return avalNumber.indexOf(item) < 0;
    });
  }

  function getSimpleUnAvailableNums(value) {
    return _.filter(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"], (v) => {
      const newValue = N(value + v);
      return !(N(newValue) <= N(customerMaxAmount) && N(newValue) >= N(minAmount))
    })
  }

  function inputToNumberStr(current) {
    let result = '';
    result = current.match(/^(0)0/);
    if (result) return result[1];
    result = current.match(/^(\d+\.)[^\d]/);
    if (result) return result[1];
    result = current.match(/^(\d+\.\d{1,2})/);
    if (result) return result[1];
    result = current.match(/^(\d+\.)/);
    if (result) return result[1];
    result = current.match(/^(\d+\.)/);
    if (result) return result[1];
    result = current.match(/^(\d+)/);
    if (result) return result[1];
    return '';
  }

  function handlePadKey() {
    function clickOn($elem, time) {
      setTimeout(() => triggerMouseEvent($($elem)[0], 'mousedown'), time || 1);
    }

    const buttons = $('#numpad a');
    if (currentKey >= 1 && currentKey <= 9) return clickOn(buttons[currentKey - 1]);
    if (currentKey === 'CLEAR') return clickOn(buttons[11]);
    if (currentKey === 'DELETE') return clickOn(buttons[9]);
    if (currentKey === '0') return clickOn(buttons[10]);
    if (currentKey === '00') {
      clickOn(buttons[10])
      return clickOn(buttons[10], 50);
    }
    if (currentKey === '#') {
      return getPageManager().back();
    }
    if (currentKey === 'ENTER' && showConfirm) {
      IW.appSetVal('selected_amount', N(amount));
      getPageManager().next();
    }
    if (currentKey === 'CANCEL') {
      return getPageManager().cancel();
    }
  }

  handlePadKey();
  IW.setVoicePage('atm_enter_amount', {amount: N(amount), canConfirm: !!showConfirm});

  const availableBanknotes = _.min(_.map(_.keys(cashMovement), N));
  const amountEnterTip = (_.isFinite(availableBanknotes) && availableBanknotes > 1 &&
    i18n`(Only multiples of ${'$' + N(availableBanknotes)})`) || '';

  return {
    currency,
    amount,
    numpadLayout,
    lockedKeys,
    showConfirm,
    amountEnterTip,
    onValueChange,
    onNext: () => {
      if (CONFIG.ATM_ALLOW_INPUT_ANY_AMOUNT || showConfirm) {
        IW.appSetVal('selected_amount', N(amount));
        getPageManager().next()
      }
    },
    onBack: () => getPageManager().back()
  };

}, EnterAmount);