import React, {Component} from 'react';

export default class CardItem extends Component {
  render() {
    const {name, amount, icon, iconCountry, iconPerson, onCardClick} = this.props;

    if (!amount) return null;
    return (
      <div className="card-item" style={{height: 330}}>
        <a className="button button-buy" onMouseDown={onCardClick} onClick={onCardClick}>
          <table className="card-item__card">
            <tbody>
            <tr>
              <td rowSpan="2">
                <div className="card-item__card__img" style={{ backgroundImage : 'url('+icon+')' }}/>
                {iconCountry? <div className="card-item__card__country" style={{ backgroundImage : 'url('+iconCountry+')' }}/> : null}
                {iconPerson? <div className="card-item__card__person" style={{ backgroundImage : 'url('+iconPerson+')' }}/> : null}
              </td>
              <td className="card-item__card__amount">
                ${amount}
              </td>
            </tr>
            </tbody>
          </table>
          {name ?
            (<div className="card-item__card__name">
              <div style={{height: '100%', display: 'inline-block', verticalAlign: 'middle'}} />
              <span style={{display: 'inline-block'}}>{name}</span>
            </div>) :
            (<div style={{paddingTop: 6}} _>
              buy
            </div>)
          }

        </a>

      </div>
    )
  }
}

CardItem.propTypes = {
  amount: React.PropTypes.number,
  name: React.PropTypes.string,
  icon: React.PropTypes.string,
  iconCountry: React.PropTypes.string,
  iconPerson: React.PropTypes.string,
  onCardClick: React.PropTypes.func
};