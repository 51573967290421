import React, {Component} from 'react';
import {Session} from 'core/session';
import IW from 'imports/iw_api';
import ClearedInput from 'imports/ui/shared/cleared_input/ClearedInput';
import Numpad from 'imports/ui/shared/numpad/Numpad';
import Button from 'imports/ui/shared/button/Button';
import {currency, N, S} from 'imports/lib/helpers';

export default class RangeAmount extends Component {

  componentDidMount() {
    Session.set('focus', 'original_amount');

    const field_to_focus = Session.get('focus');
    Session.set('focus', '');
    Session.set('focus', field_to_focus);
    Session.set('amount', S(N(IW.appGetVal('original_amount')) || ''));
  }

  render() {
    const {
      pricePattern, value, keyboardMaxLength, keyboardPattern, numPadLayout, valid, onClickNext,
      onClickBack, isFlexible, onChangeValue, clearInput, focus, minAmount, maxAmount
    } = this.props;

    return (
      <table className="bill-amount-page">
        <tbody>
        <tr>
          <td className="bill-amount-page__input-container">
            <div className="bill-amount-page__describe-text">
              <span className="text-md padding-l-md" _>Amount to Pay</span>
            </div>
            <div className="bill-amount-page__describe-example">
              <span className="text-md padding-l-md" _={[currency(minAmount, '$')]}>MIN value: %s</span>
            </div>
            <div className="bill-amount-page__describe-example">
              <span className="text-md padding-l-md" _={[currency(maxAmount, '$')]}>MAX value: %s</span>
            </div>
            <ClearedInput type="text" id="original_amount" required={true} pattern={pricePattern} readOnly={true}
                          value={value} maxLength={9} placeholder="$0.00" clearInput={clearInput} focus={focus}/>
          </td>
          <td className="bill-amount-page__keyboard-container">
            <Numpad keys={numPadLayout} pattern={keyboardPattern} value={value}
                    onValueChange={onChangeValue} isFlexible={isFlexible} maxLength={keyboardMaxLength}/>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2">
            {valid ?
              <Button id="next" onMouseDown={(event) => onClickNext(event)} className="button right" text_="Next"/> :
              null}
            <Button id="back" onMouseDown={(event) => onClickBack(event)} className="left button" text_="Back"/>
          </td>
        </tr>
        </tfoot>
      </table>
    );
  }
}

RangeAmount.propTypes = {
  pricePattern: React.PropTypes.string,
  originalAmount: React.PropTypes.number,
  value: React.PropTypes.string,
  numPadLayout: React.PropTypes.arrayOf(React.PropTypes.arrayOf(React.PropTypes.any)),
  valid: React.PropTypes.bool,
  keyboardMaxLength: React.PropTypes.number,
  onClickNext: React.PropTypes.func,
  onClickBack: React.PropTypes.func,
  onChangeValue: React.PropTypes.func,
  keyboardPattern: React.PropTypes.string,
  isFlexible: React.PropTypes.bool,
  clearInput: React.PropTypes.func,
  focus: React.PropTypes.string,
  minAmount: React.PropTypes.number,
  maxAmount: React.PropTypes.number,
};
