import {createContainer} from 'imports/lib/react_createContainer';
import ConfirmCheckCash from './ConfirmCheckCash'
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';
import {C, N} from 'imports/lib/helpers'
import CONFIG from 'imports/config';

export default createContainer((params) => {

  const customerBalance = N(IW.appGetVal('customer_balance_amount'));
  const checkAmount = N(IW.appGetVal('check_amount'));
  const fee = N(IW.appGetVal('fee'));
  const requestedAmount = N(customerBalance + checkAmount - fee);
  const dispensableAmount = N(IW.appGetVal('selected_amount'));
  const debitAmount = N(IW.appGetVal('debit_amount'));

  const items = [];

  items.push({key: 'Check Amount', value: C(checkAmount, '$')});
  items.push({key: CONFIG.PARTNER_NAME + ' Fee', value: C(fee, '$')});

  if (customerBalance !== 0) {
    items.push({key: 'Your Current Balance', value: C(customerBalance, '$')});
    if (requestedAmount > 0) {
      items.push({
        key: 'Requested Amount', value: `${
          C(requestedAmount, '$')} (${
          C(checkAmount, '$')} - ${
          C(fee, '$')} + ${
          C(customerBalance, '$')})`
      });
    }
  } else {
    items.push({
      key: 'Requested Amount', value: `${
        C(requestedAmount, '$')} (${
        C(checkAmount, '$')} - ${
        C(fee, '$')})`
    });
  }

  items.push({key: 'Kiosk can dispense', value: C(dispensableAmount, '$')});

  if (debitAmount > 0) {
    if (dispensableAmount > 0 && customerBalance > 0) {
      items.push({
        key: 'Sent to the Balance', value: `${
          C(debitAmount, '$')} (${
          C(requestedAmount, '$')} - ${
          C(dispensableAmount, '$')})`
      });
    } else {
      items.push({key: 'Sent to the Balance', value: C(debitAmount, '$')});
    }
  }

  if (debitAmount < 0) {
    items.push({key: 'Taken from the Balance', value: C(-debitAmount, '$')});
  }

  if (customerBalance + debitAmount !== customerBalance && customerBalance !== 0) {
    items.push({
      key: 'Your New Balance', value: `${
        C(debitAmount + customerBalance, '$')} (${
        C(customerBalance, '$')} ${debitAmount > 0 ?
        '+' :
        '-'} ${
        C(Math.abs(debitAmount), '$')})`
    })
  }

  return {
    items: items,
    canAccept: dispensableAmount > 0 || debitAmount > 0,
    canDispense: dispensableAmount > 0,
    dispensableAmount: C(dispensableAmount, '$'),
    requestedAmount: C(requestedAmount, '$'),
    onCancel: () => getPageManager().cancel(),
    onNext: () => getPageManager().next(),
  };
}, ConfirmCheckCash)