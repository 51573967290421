import {Session} from 'core/session';
import IW from 'imports/iw_api';
import CONFIG from 'imports/config';
import {ATM_ACCOUNTS} from 'imports/lib/constants';
import {nextWhen} from '../lib/page_manager';

export default {
  //insert_card
  insert_card: function* () {
    // IW.appSetLocalVal('selection_items', ['asdfasdfsadfsfd afsf sadfadsf ', 'sdaf sdf sadf sadfdsf ', 'sdafsadf'],);
    // return 'emv_select_application';

    if (CONFIG.ATM_COMING_SOON) return 'coming_soon';
    IW.call('loadTerminal');
    yield 'loading app terminal_id';
    if (IW.appGetVal('terminal_locked')) {
      closeCardReader();
      return 'terminal_locked_error';
    }
    IW.callCardReader('status');
    IW.callEPP('status');
    IW.callCashDispenser('status');
    yield 'loading app card_reader_status_done';
    yield 'loading app cash_dispenser_status_done';
    yield 'loading app epp_status_done';

    if (!IW.appGetVal('card_reader_status_success') ||
      !IW.appGetVal('cash_dispenser_status_success') ||
      !IW.appGetVal('epp_status_success')) {
      closeCardReader();
      return 'device_error';
    }

    IW.appSetLocalVal('accounts', ATM_ACCOUNTS.slice(0, 3), true);
    IW.callEPP('set_text_mode');

    IW.appSetLocalVal('card_insert_error', false);
    IW.appSetLocalVal('card_insert_first_time', true);

    return 'insert_card_simple';
  },

  return_card_continue: function* () {
    ejectCard();

    yield 'sleep app card_removed';
    Session.set('menu_disabled', false);
    yield 'loading app card_read';
    IW.callCardReader('data');
    yield 'loading app card_data_received';

    if (!IW.appGetVal('card_processed')) {
      IW.appSetLocalVal('card_insert_error', true);
      IW.appSetVal('card_read', false);
      IW.appSetVal('card_insert', false);
      IW.appSetVal('card_data_received', false);
      return 'insert_card_simple';
    }

    return 'take_actions';
  },

  insert_card_simple: function* () {
    if (!IW.appGetVal('card_insert') && CONFIG.CARD_READER_ROUTINE && IW.appGetVal('card_insert_first_time')) {
      closeCardReader();
      IW.appSetLocalVal('card_insert_first_time', false);
      yield 'loading 2000' // wait possible card insert
    }

    if (!IW.appGetVal('card_insert')) {
      IW.appSetVal('card_insert', false);
      IW.appSetVal('card_data_received', false);

      Session.set('card_inserting', true);
      IW.appSetLocalVal('card_reader_receive_card_done', false);
      IW.callCardReader('receive_card');
      yield 'loading app card_reader_receive_card_done';
      IW.appSetVal('card_insert', false);
      Session.set('card_reader_already_active', true);

      IW.appSetVal('card_removed', false);
      yield 'sleep app card_insert';
    }

    if (IW.appGetVal('card_emv')) {
      yield 'loading app card_read';
      IW.callCardReader('data');
      yield 'loading app card_data_received';
      return 'emv_request';
    }

    return 'return_card_continue'
  },

  enter_pin: function* () {
    IW.appSetLocalVal('epp_set_pin_mode_done', false);
    Session.set('card_inserting', true);
    IW.callEPP('set_pin_mode');
    IW.callCardReader('read');
    IW.appSetVal('pad_pin', '');
    IW.appSetVal('pad_entered', false);
    IW.appSetVal('pad_canceled', false);
    if (IW.appGetVal('pin_valid') === false) IW.appSetLocalVal('pin_not_valid', true);
    IW.appSetLocalVal('pin_valid', undefined);
    yield 'loading app epp_set_pin_mode_done';
    if (!IW.appGetVal('epp_set_pin_mode_success')) return 'return_card_internal_error';

    return {
      back: function () {
        IW.callEPP('set_text_mode');
        Session.set('card_inserting', false);
        IW.appSetVal('pin_wrong', false);
        IW.appSetVal('pin_not_valid', false);
        return IW.appGetVal('pin_request_page_back');
      },

      cancel: function () {
        IW.appSetVal('pad_canceled', false);
        return 'return_card'
      },

      next: function* () {
        if (!IW.appGetVal('pad_entered')) {
          IW.callEPP('get_pin');
        }
        IW.appSetVal('pad_entered', false);
        yield 'loading app_exists pin_valid';
        if (IW.appGetVal('pin_valid')) {
          IW.callEPP('set_text_mode');
          Session.set('card_inserting', false);
          IW.appSetVal('pin_wrong', false);
          IW.appSetVal('pin_not_valid', false);
          return IW.appGetVal('pin_request_page');
        }
        return 'enter_pin';
      },
    }
  },
  //insert_card->take_actions
  take_actions: () => {
    return {
      cancel: 'return_card',
      next: () => {
        let type = IW.appGetVal('selected_action');
        if (type === 'balance' || type === 'withdraw') return 'accounts';
        if (type === 'transfer') return "transfer_cash";
        return 'exit';
      }
    }
  },

  //insert_card->take_actions->accounts
  accounts: () => {
    return {
      cancel: 'return_card',
      back: 'take_actions',
      print: 'none',
      next: function () {
        if (IW.appGetVal('selected_action') === 'balance') {
          return emvActionSwitch('account_amount')()
        }
        if (IW.appGetVal('selected_action') === 'withdraw') {
          return 'extract_select_amount'
        }
      },
    }
  },

  //insert_card->take_actions->accounts->extract_select_amount
  extract_select_amount: () => {
    return {
      cancel: 'return_card',
      back: 'accounts',
      other: 'enter_extract_amount',
      next: emvActionSwitch('return_card_extraction')
    }
  },

  //insert_card->take_actions->accounts->extract_select_amount->enter_extract_amount
  enter_extract_amount: function () {
    IW.appSetVal('selected_amount', 0);
    return {
      cancel: 'return_card',
      back: 'back',
      next: emvActionSwitch('return_card_extraction'),
    };
  },

  //insert_card->take_actions->transfer_cash
  transfer_cash: () => {
    return {
      cancel: 'return_card',
      back: 'take_actions',
      next: 'transfer_select_amount'
    }
  },

  //insert_card->take_actions->transfer_cash->transfer_select_amount
  transfer_select_amount: () => {
    return {
      cancel: 'return_card',
      back: 'transfer_cash',
      other: 'enter_transfer_amount',
      next: emvActionSwitch('transfer_final'),
    }
  },

  //insert_card->take_actions->transfer_cash->transfer_select_amount->enter_transfer_amount
  enter_transfer_amount: function () {
    IW.appSetVal('selected_amount', 0);
    return {
      cancel: 'return_card',
      back: 'back',
      next: emvActionSwitch('transfer_final'),
    };
  },

  //insert_card->take_actions->accounts->account_amount
  account_amount: function* () {
    const checkPage = tryEnterPin('account_amount', 'accounts');
    if (checkPage) {
      IW.appSetVal('check_balance_done', false);
      return checkPage;
    }
    if (!IW.appGetVal('check_balance_done')) {
      const feePage = yield* tryShowFee('account_amount', 'take_actions');
      if (feePage) return feePage;

      IW.call('checkBalance');
      yield 'loading app check_balance_done';
      if (IW.appGetVal('pin_wrong')) {
        IW.appSetVal('check_balance_done', false);
        return 'enter_pin';
      }
      if (IW.appGetVal('transaction_error')) return 'return_card_transaction_error';
      if (!IW.appGetVal('check_balance_success')) return 'return_card_internal_error';

      IW.appSetVal('fee_agree', false);
    }
    const accounts = IW.appGetVal('accounts');
    const account = _.find(accounts, (a) => a.id === IW.appGetVal('selected_account'));
    if (!account) throw Error('Account not found');
    account.amount = IW.appGetVal('selected_amount');
    IW.appSetLocalVal('accounts', accounts);
    return {
      cancel: 'return_card',
      back: 'accounts',
      print: 'return_card_balance',
    }
  },

  //insert_card->take_actions->accounts->extract_select_amount->return_card_extraction
  //insert_card->take_actions->accounts->extract_select_amount->enter_extract_amount->return_card_extraction
  return_card_extraction: function* () {
    const checkPage = tryEnterPin('return_card_extraction', 'extract_select_amount');
    if (checkPage) return checkPage;

    const feePage = yield* tryShowFee('return_card_extraction', 'take_actions');
    if (feePage) return feePage;

    IW.call('extractCash');
    IW.appSetLocalVal('extract_cash_done', false);
    yield 'loading app extract_cash_done';

    if (IW.appGetVal('pin_wrong')) return 'enter_pin';
    if (IW.appGetVal('extract_cash_not_enough')) return 'extract_money_not_enough';
    if (IW.appGetVal('transaction_error')) return 'return_card_transaction_error';
    if (!IW.appGetVal('extract_cash_success')) return 'return_card_internal_error';

    ejectCard();
    yield 'sleep app card_removed';

    if (IW.appGetVal('card_swallowed')) {
      IW.call('reverseCash');
      return 'exit';
    }
    return 'extract_money';
  },

  extract_money: function* () {
    IW.callCashDispenser('dispense');
    yield 'sleep app cash_dispenser_dispensed_done';
    Session.set('menu_disabled', false);
    // if (!IW.appGetVal('cash_dispenser_dispensed_success')) return 'return_card_internal_error';
    return 'extract_money_success';
  },


  //insert_card->take_actions->transfer_cash->transfer_select_amount->transfer_final
  //insert_card->take_actions->transfer_cash->transfer_select_amount->enter_transfer_amount->transfer_final
  transfer_final: function* () {
    const checkPage = tryEnterPin('transfer_final', 'transfer_select_amount');
    if (checkPage) return checkPage;

    const feePage = yield* tryShowFee('transfer_final', 'take_actions');
    if (feePage) return feePage;

    IW.call('transferCash');
    IW.appSetLocalVal('transfer_cash_done', false);

    yield 'loading app transfer_cash_done';

    if (IW.appGetVal('pin_wrong')) return 'enter_pin';
    if (IW.appGetVal('transfer_cash_not_enough')) return 'transfer_money_not_enough';
    if (IW.appGetVal('transaction_error')) return 'return_card_transaction_error';
    if (!IW.appGetVal('transfer_cash_success')) return 'return_card_internal_error';

    IW.appSetVal('fee_agree', false);
    return 'return_card_transfer';
  },

  return_card_transfer: function* () {
    ejectCard();
    yield 'sleep app card_removed';
    return 'extract_money_success';
  },


  extract_money_not_enough: function () {
    IW.appSetVal('pin_request_page', '');
    return {
      next: 'extract_select_amount',
      cancel: 'return_card',
    }
  },

  transfer_money_not_enough: function () {
    IW.appSetVal('pin_request_page', '');
    return {
      next: 'transfer_select_amount',
      cancel: 'return_card',
    }
  },

  extract_money_success: function* () {
    IW.printReceipt();
    // Popular products fetch for advertisement
    if (CONFIG.SHOW_POPULAR_PRODUCTS_ADVERTISEMENT) {
      IW.call('getPopularProductsId');
    }

    const startTime = Date.now();
    yield nextWhen(() => Date.now() - startTime > 5000);
    yield 'adv_loading';

    if (CONFIG.SHOW_POPULAR_PRODUCTS_ADVERTISEMENT) {
      yield 'loading app popular_products_id';
    }

    return {
      cancel: 'exit',
      next: 'exit'
    }
  },

  return_card: function* () {
    ejectCard();
    yield 'sleep app card_removed';
    return 'exit';
  },

  return_card_balance: function* () {
    ejectCard();
    yield 'sleep app card_removed';
    return 'extract_money_success';
  },

  return_card_transaction_error: {
    cancel: 'return_card',
    next: 'return_card',
  },

  return_card_internal_error: {
    cancel: 'return_card',
    next: 'return_card',
  },

  transaction_canceled: {
    cancel: 'exit',
    next: 'exit',
  },
  bad_card: {
    cancel: 'exit',
    next: 'exit',
  },
  internal_error: {
    cancel: 'exit',
    next: 'exit',
  },
  device_error: () => {
    return {
      cancel: 'exit',
      next: 'exit',
    }
  },
  terminal_locked_error: {
    cancel: 'exit',
    next: 'exit',
  },
  coming_soon: {},
  fee: function () {
    IW.appSetVal('fee_agree', false);
    return {
      cancel: 'return_card',
      next: function () {
        IW.appSetVal('fee_agree', true);
        return IW.appGetVal('fee_request_page')
      },
      back: IW.appGetVal('fee_request_page_back')
    }
  },

  emv_request: function* () {
    yield 'loading app emv_action';
    const action = IW.appGetVal('emv_action');
    const data = IW.appGetVal('emv_data');
    IW.appSetLocalVal('emv_action', '');

    if (action === 'select_application') {
      IW.appSetLocalVal('selection_items', data.applications);
      return 'emv_select_application';
    }

    if (action === 'select_language') {
      IW.appSetLocalVal('selection_items', data.languages);
      return 'emv_select_language';
    }

    if (action === 'select_transaction_type') {
      return 'take_actions';
    }

    if (action === 'show_message') {
      if (isAACDeclined(data.message)) {
        IW.appSetVal('transaction_status', 'Transaction declined');
        return 'return_card_transaction_error';
      }

      IW.appSetLocalVal('terminal_message', data.message);
      return 'emv_show_message';
    }

    if (action === 'confirm_application') {
      IW.appSetVal('application_name', data.application_name)
      return 'emv_confirm_application';
    }

    if (action === 'enter_pin') {
      return 'emv_enter_pin'
    }

    if (action === 'process') {
      IW.appSetVal('emv_ud', data.ud);
      IW.appSetVal('emv_uh', data.uh);
      IW.appSetVal('emv_application_id', data.selected_application_id);
      IW.appSetVal('emv_application_name', data.selected_application_name);
      return 'emv_process';
    }

    if (action === 'reverse') {
      IW.call('reverseCash');
      IW.callCardReader('emv_response', Session.get('session_guid'), null);
      return 'emv_request';
    }

    if (action === 'transaction_approved') {
      return emvAction(() => null)();
    }

    if (action === 'transaction_declined') {
      if (IW.appGetVal('pin_wrong')) {
        IW.appSetLocalVal('terminal_message', 'Transaction declined' + (IW.appGetVal('pin_wrong') ?
          '. Your pin is wrong' :
          ''));
        return 'emv_show_message'
      }
      return emvAction(() => null)();
    }

    if (action === 'done') {
      if (IW.appGetVal('selected_action') === 'balance') {
        if (IW.appGetVal('transaction_error')) return 'return_card_transaction_error';
        if (IW.appGetVal('transaction_declined')) return 'return_card_transaction_error';
        if (!IW.appGetVal('check_balance_success')) return 'return_card_internal_error';
        return 'emv_account_amount';
      }

      if (IW.appGetVal('selected_action') === 'withdraw') {
        if (IW.appGetVal('pin_wrong')) {
          IW.callCardReader('emv_reset', Session.get('session_guid'));
          return 'emv_request';
        }
        if (IW.appGetVal('extract_cash_not_enough')) return 'extract_money_not_enough';
        if (IW.appGetVal('transaction_error')) return 'return_card_transaction_error';
        if (IW.appGetVal('transaction_declined')) return 'return_card_transaction_error';
        if (!IW.appGetVal('extract_cash_success')) return 'return_card_internal_error';
        return 'emv_return_card_extraction';
      }

      if (IW.appGetVal('selected_action') === 'transfer') {
        if (IW.appGetVal('transfer_cash_not_enough')) return 'transfer_money_not_enough';
        if (IW.appGetVal('transaction_error')) return 'return_card_transaction_error';
        if (IW.appGetVal('transaction_declined')) return 'return_card_transaction_error';
        if (!IW.appGetVal('transfer_cash_success')) return 'return_card_internal_error';
        return 'return_card_transfer';
      }

      return 'return_card';
    }

    if (action === 'error') {
      if (data.status === 'chip_error' && IW.appGetVal('card_magnetic')) {
        IW.appSetLocalVal('terminal_message', 'Chip error');
        IW.appSetLocalVal('emv_fallback', true);
        return 'emv_show_message';
      }

      IW.appSetVal('emv_error_status', data.status);
      return 'emv_return_card_emv_error';
    }

    console.error('emv_request >>> action =', action);
    return 'exit';
  },

  emv_response: function () {
    const response = {
      transaction_type: 'NotSet',
      account_type: 'Default',
      amount: IW.appGetVal('selected_amount'),
    };
    const type = IW.appGetVal('selected_action');
    const account = IW.appGetVal('selected_account');

    if (type === 'balance') {
      response.transaction_type = 'AvailableFundsInquiry';
      response.amount = 0;
    }
    if (type === 'withdraw') response.transaction_type = 'Cash';
    if (type === 'transfer') response.transaction_type = 'NotSet'

    if (account === 1) response.account_type = 'ChequeOrDebit';
    if (account === 2) response.account_type = 'Savings';
    if (account === 3) response.account_type = 'Credit';

    IW.callCardReader('emv_response', Session.get('session_guid'), response);
    return 'emv_request'
  },

  emv_cancel: function () {
    if (IW.appGetVal('extract_cash_success')) {
      IW.call('reverseCash');
    }
    return 'return_card'
  },

  emv_select_application: function () {
    return {
      back: 'emv_cancel',
      next: emvAction(() => ({
        application_id: IW.appGetVal('selected_item_id')
      }))
    }
  },

  // emv_select_language: function () {
  //   return {
  //     back: 'emv_cancel',
  //     next: emvAction(() => ({
  //       language: IW.appGetVal('selected_item_id')
  //     }))
  //   }
  // },

  emv_show_message: function () {
    return {
      cancel: 'emv_cancel',
      back: 'emv_cancel',
      next: function () {
        if (IW.appGetVal('emv_fallback')) {
          return 'return_card_continue';
        }
        emvAction(() => ({confirmed: true}))();
      }
    }
  },

  emv_confirm_application: function () {
    return {
      cancel: emvAction(() => ({confirmed: false})),
      back: emvAction(() => ({confirmed: false})),
      next: emvAction(() => ({confirmed: true}))
    }
  },

  // emv_select_transaction_type: function () {
  //   return {
  //     cancel: 'emv_cancel',
  //     back: 'emv_cancel',
  //     next: emvAction(() => {
  //       const type = IW.appGetVal('selected_action');
  //       if (type === 'balance') return {typeId: IW.appGetVal('emv_data').types.indexOf('AvailableFundsInquiry')};
  //       if (type === 'withdraw') return {typeId: IW.appGetVal('emv_data').types.indexOf('Cash')};
  //       if (type === 'transfer') return {typeId: IW.appGetVal('emv_data').types.indexOf('NotSet')};
  //       return {typeId: _.findIndex(IW.appGetVal('emv_data').types, (t) => t === 'NotSet')};
  //     })
  //   }
  // },

  emv_enter_pin: function* () {
    const feePage = yield* tryShowFee('emv_enter_pin', 'return_card');
    if (feePage) return feePage;

    IW.appSetLocalVal('pin_valid', undefined);
    const checkPage = tryEnterPin('emv_request', 'return_card');
    if (checkPage) return checkPage;
    return 'emv_request';
  },

  // emv_select_account: function () {
  //   return {
  //     cancel: 'emv_cancel',
  //     back: 'emv_cancel',
  //     print: 'none',
  //     next: emvAction(() => {
  //       const account = IW.appGetVal('selected_account');
  //       if (account === 1) return {typeId: IW.appGetVal('emv_data').types.indexOf('ChequeOrDebit')};
  //       if (account === 2) return {typeId: IW.appGetVal('emv_data').types.indexOf('Savings')};
  //       if (account === 3) return {typeId: IW.appGetVal('emv_data').types.indexOf('Credit')};
  //       return {typeId: _.findIndex(IW.appGetVal('emv_data').types, (t) => t === 'NotSet')};
  //     }),
  //   }
  // },
  //
  // emv_select_amount: function () {
  //   if (IW.appGetVal('selected_action') === 'balance'){
  //     return emvAction(() => ({amount: 0}))();
  //   }
  //   return {
  //     cancel: 'emv_cancel',
  //     back: 'emv_cancel',
  //     next: emvAction(() => ({amount: IW.appGetVal('selected_amount')})),
  //     other: 'emv_enter_amount',
  //   }
  // },
  //
  // emv_enter_amount: function () {
  //   IW.appSetVal('selected_amount', 0);
  //   return {
  //     cancel: 'emv_cancel',
  //     back: 'emv_cancel',
  //     next: emvAction(() => ({amount: IW.appGetVal('selected_amount')})),
  //   };
  // },

  emv_process: function* () {
    const response = {}
    if (IW.appGetVal('selected_action') === 'balance') {
      IW.appSetLocalVal('check_balance_done', false);
      IW.call('checkBalance');
      yield 'loading app check_balance_done';
      response.has_error = !IW.appGetVal('check_balance_success') && !IW.appGetVal('transaction_error');
    }

    if (IW.appGetVal('selected_action') === 'withdraw') {
      IW.appSetLocalVal('extract_cash_done', false);
      IW.call('extractCash');
      yield 'loading app extract_cash_done';
      response.has_error = !IW.appGetVal('extract_cash_success') && !IW.appGetVal('transaction_error') &&
        !IW.appGetVal('extract_cash_not_enough');
    }

    if (IW.appGetVal('selected_action') === 'transfer') {
      IW.appSetLocalVal('transfer_cash_done', false);
      IW.call('transferCash');
      yield 'loading app transfer_cash_done';
      response.has_error = !IW.appGetVal('transfer_cash_success') && !IW.appGetVal('transaction_error') &&
        !IW.appGetVal('transfer_cash_not_enough');
    }

    if (IW.appGetVal('pin_wrong')) response.pin_error = true;

    response.ud = IW.appGetVal('emv_processor_ud');
    response.uh = IW.appGetVal('emv_processor_uh');

    IW.callCardReader('emv_response', Session.get('session_guid'), response);
    return 'emv_request'
  },

  emv_account_amount: {
    cancel: 'return_card',
    back: 'return_card',
    print: 'return_card_balance',
  },

  emv_return_card_extraction: function* () {
    ejectCard();
    yield 'sleep app card_removed';

    if (IW.appGetVal('card_swallowed')) {
      IW.call('reverseCash');
      return 'exit';
    }
    return 'extract_money';
  },

  emv_return_card_emv_error: function* () {
    ejectCard();
    yield 'sleep app card_removed';
    return 'exit';
  },
};

function ejectCard() {
  closeCardReader();
  IW.callEPP('close');
  Session.set('card_inserting', false);
  Session.set('menu_disabled', true);
}

function tryEnterPin(page, pageBack) {
  const account = _.find(IW.appGetVal('accounts'), (a) => a.id === IW.appGetVal('selected_account'));
  if (!account) throw Error('Account not found');

  if (!IW.appGetVal('pin_valid') || IW.appGetVal('pin_request_page') !== page || IW.appGetVal('pin_request_account') !== account.id) {
    IW.appSetVal('pin_request_page', page);
    IW.appSetVal('pin_request_page_back', pageBack);
    IW.appSetVal('pin_request_account', account.id);
    return 'enter_pin';
  }
}

function* tryShowFee(page, pageBack) {
  IW.appSetLocalVal('terminal_fee', undefined);
  IW.call('getTerminalFee');
  yield 'loading app_exists terminal_fee';
  if (IW.appGetVal('terminal_fee') && !IW.appGetVal('fee_agree')) {
    IW.appSetVal('fee_request_page', page);
    IW.appSetVal('fee_request_page_back', pageBack);
    return 'fee';
  }
}

function emvAction(handler) {
  return function () {
    IW.callCardReader('emv_response', Session.get('session_guid'), handler());
    return 'emv_request'
  }
}

function emvActionSwitch(nextPage) {
  return function () {
    if (IW.appGetVal('card_emv')) {
      return 'emv_response';
    }
    return nextPage;
  }
}

function isAACDeclined(message) {
  return /AAC/.test(message) && /Transaction declined/.test(message);
}

function closeCardReader() {
  IW.callCardReader('close');
  Session.set('card_reader_already_active', false);
}