import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import IW from '../../../iw_api';

export default class NameValueDataDisplay extends Component {
  renderRow(item, i) {
    if (item.description && item.data) {
      return (<tr key={i}>
        <th><span _>{item.description}</span></th>
        {(item.middle && <td><span>{item.middle}</span></td>) || <td/>}
        <td><span className="accented">{item.data}</span></td>
      </tr>);
    }

    if (item.delimiter) {
      return (
        <tr className="delimiter-amount" key={i}>
          <td className="delimiter-amount-padding" colSpan={3}/>
        </tr>
      )
    }

    return (
      <tr key={i}>
        <td colSpan="3"><span className="accented">{item.data}</span></td>
      </tr>
    );
  }

  printButtonHandle() {
    IW.appSetLocalVal('prev_amount', IW.appGetVal('original_amount'));
    IW.call('printDisclosure', (__, receipt_url) => {
      console.log('Gotten disclosure receipt url =>', receipt_url);
      if (IW.isVirtual()) IW.openReceipt(receipt_url)
    });
  }

  renderDisclosureButtons() {
    const {displayButtons, displayPrintButton} = this.props;
    if (!displayButtons) return;

    let printButton = null;

    if (displayPrintButton) {
      printButton = <Button id="print" className="left button print_button" text_="Print This Disclosure"
                            onMouseDown={() => this.printButtonHandle()}/>;
    }

    return (
      <tr>
        <td colSpan="3">
          {printButton}
          <Button id="print" className="left button print_button" text_="Email This Disclosure"
                  onMouseDown={() => IW.appSetLocalVal('disclosure_email', true)}/>
        </td>
      </tr>
    )
  }

  renderDisclosure() {
    const {disclosure} = this.props;
    if (!disclosure) return;

    const style = {
      fontSize: 20,
      lineHeight: 1.1,
      paddingTop: 8,
      textAlign: 'justify',
      whiteSpace: 'pre-line'
    };

    return (
      <tr>
        <td colSpan="3" style={style}>
          {disclosure}
        </td>
      </tr>
    )
  }

  render() {
    const {items, className, id} = this.props;

    return (
      <table id={id} className={className}>
        <tbody>
        {items.map((item, i) => {
          return this.renderRow(item, i);
        })}
        {this.renderDisclosure()}
        {this.renderDisclosureButtons()}
        </tbody>
      </table>
    );
  }
}

NameValueDataDisplay.propTypes = {
  className: React.PropTypes.string,
  id: React.PropTypes.string,
  items: React.PropTypes.array
};

NameValueDataDisplay.defaultProps = {
  displayButtons: false,
  displayPrintButton: true
};
