import React, {Component} from 'react';
import IW from 'imports/iw_api';
import Button from 'imports/ui/shared/button/Button';

export default class ScanInfo extends Component {
  render() {

    var res = null;
    const {goNext, goBack} = this.props;
    res = (
      <table className="scan-info-page">
        <tbody>
        <tr>
          <td className="scan-info-page__detach">
            <div>
              <span className="badge">1</span>
              <span className="badge-text" _>Detach Stub</span>
            </div>
            <img src="/themes/mayafs/images/img_bill_example.png" />
          </td>
          <td className="scan-info-page__scan">
            <div>
              <span className="badge">2</span>
              <span className="badge-text" _>Scan your Stub</span>
            </div>
            <img src="/themes/mayafs/images/img_bill_scan.png" />
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2">
            <Button id="back" className="left button" onMouseDown={goBack} text_="Back"/>
            <Button id="ready" className="right button" onMouseDown={goNext} text_="Ready"/>
          </td>
        </tr>
        </tfoot>
      </table>
    );
    return res;
  }
}

ScanInfo.propTypes = {
  goNext: React.PropTypes.func,
  goBack: React.PropTypes.func
};
