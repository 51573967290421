import app_common from './app_common';
import createNumber from './common/number';
import createStartScanning from './common/start_scanning';
import createStartScanningInsertBill from './common/start_scanning_insert_bill';
import createAmount from './common/amount';
import createBillerSearching from './common/createBillerSearching'
import confirmPhotoBiller from './common/confirmPhotoBiller'
import IW from 'imports/iw_api';
import CONFIG from "../config";
import {Session} from "core/session";

const startScanningPage = CONFIG.BILL_SCANNING_GENMEGA ?
  'bill_scanning_insert_bill' :
  'start_scanning';

export default _.extend({}, app_common, {
  index: () => {
    if (IW.appGetVal('transpayment_does_not_work')) return 'return_card_internal_error';
    return 'number'
  },

  number: createNumber('exit', 'biller_search', {allowLandline: true}),

  biller_search: createBillerSearching('number', 'info', startScanningPage),

  info: function* () {
    yield* doCall('getBillerPaymentOptions', (d) => IW.appSetObject(d))
    if (IW.appGetVal('transpayment_does_not_work')) return 'return_card_internal_error';

    return {
      back: function () {
        clearBiller();
        return 'biller_search'
      },
      next: function* () {
        yield* doCall('setPaymentOption', (d) => IW.appSetObject(d))
        if (IW.appGetVal('transpayment_does_not_work')) return 'return_card_internal_error';
        return startScanningPage
      },
    }
  },

  bill_amount: createAmount('back', 'pay'),

  // Bill Scanning

  ...createStartScanningInsertBill(() => {
    Session.set('in_data_searching', false);
    IW.appSetLocalVal('enter_user_data_loading', false);
    return 'back'
  }, 'bill_amount', 'scanning_error2'),

  start_scanning: createStartScanning(() => {
    Session.set('in_data_searching', false);
    IW.appSetLocalVal('enter_user_data_loading', false);
    return 'back'
  }, 'confirm_photo', 'scanning_error2'),

  confirm_photo: confirmPhotoBiller(startScanningPage, 'bill_amount'),

  scanning_error2: {
    back: startScanningPage,
    next: 'inputs_billpayment'
  },

  inputs_billpayment: {
    back: 'scanning_error2',
    next: 'bill_required_data',
  },

  bill_required_data: function ({lastAction}) {
    Session.set('current_active_field', 0);
    Session.set('prev_current_active_field', 0);

    if (!IW.appGetVal('required_fields')) {
      if (lastAction !== 'back') return 'bill_amount';
      return 'inputs_billpayment'
    }

    return {
      back: 'inputs_billpayment',
      next: 'bill_amount',
    }
  },
})

function clearBiller() {
  IW.appSetVal('biller_name', '');
  IW.appSetVal('biller_id', '');
  IW.appSetVal('address_sequence', '');
  IW.appSetVal('biller_city_state_zip', '');
  IW.appSetVal('payment_options', null);
}

function* doCall(method, onData) {
  IW.appSetLocalVal(method + '_done', false);
  IW.call(method, null, (__, data) => {
    _.isFunction(onData) && onData(data);
    IW.appSetLocalVal(method + '_done', true);
  })
  yield 'loading app ' + method + '_done';
}
