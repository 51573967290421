import {createContainer} from 'imports/lib/react_createContainer';
import ConfirmCustomerData from './ConfirmCustomerData';
import IW from 'imports/iw_api';
import {S} from 'imports/lib/helpers';

import getPageManager from 'imports/lib/page_manager';

export default createContainer(() => {
  const customerViewData = [
    {
      key: 'Document Serial',
      value: IW.appGetVal('customer_document_serial'),
    },
    {
      key: 'First Name',
      value: IW.appGetVal('customer_first_name'),
    },
    {
      key: 'Last Name',
      value: IW.appGetVal('customer_last_name'),
    },
    {
      key: 'Gender',
      value: IW.appGetVal('customer_gender'),
    },
    {
      key: 'Date of Birth',
      value: IW.appGetVal('customer_dob'),
    },
    {
      key: 'Document Expiry',
      value: IW.appGetVal('customer_document_expiry'),
    },
    {
      key: 'Document Issue',
      value: IW.appGetVal('customer_document_issue'),
    },
    {
      key: 'Street',
      value: IW.appGetVal('customer_street'),
    },
    {
      key: 'City',
      value: IW.appGetVal('customer_city'),
    },
    {
      key: 'ZIP',
      value: IW.appGetVal('customer_zip'),
    },
    {
      key: 'State Code',
      value: IW.appGetVal('customer_state_code'),
    },
    {
      key: 'Country Code',
      value: IW.appGetVal('customer_country_code'),
    },
  ];


  return {
    title: 'ID scan result: ',
    items: customerViewData,
    onBack: () => getPageManager().back(),
    onNext: () => getPageManager().next(),
  };
}, ConfirmCustomerData);
