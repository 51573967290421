import React, {Component, Fragment} from 'react';
import SliderContainer from 'imports/ui/shared/slider/SliderContainer';
import {SLIDE_ITEM_TYPES} from 'imports/lib/constants';

class PopularProducts extends Component {
  render() {
    const {apps} = this.props;

    return !_.isEmpty(apps) ? (
      <div>
        <h2 style={{}} className="upper center final-page__adv-header" _>
          Try our other services here!
        </h2>
        <div className="final-page__adv-icons">
          <SliderContainer type={SLIDE_ITEM_TYPES.ICON} apps={apps}/>
        </div>
      </div>

    ) : null;
  }
}

PopularProducts.propTypes = {
  apps: React.PropTypes.array
};

export default PopularProducts