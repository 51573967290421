import React, {Component} from 'react';
import SliderContainer from 'imports/ui/shared/slider/SliderContainer';
import Loading from 'imports/ui/shared/loading/Loading';
import Button from 'imports/ui/shared/button/Button';

export default (props) => {
  const {type, pages, choices, onBack, className} = props;

  let backButton = null;

  if (onBack) {
    backButton =
      <div style={{
        position: 'absolute',
        bottom: '10px',
        left:'20px'
      }}>
        <Button id="back" style={{
          'backgroundColor': '#040a1f',
          'fontWeight': 500,
          'padding': '40px 50px 40px 75px'
        }} className="button button-danger" onMouseDown={onBack} text_="Back"/>
      </div>
  }

  return <table className={className}>
    <tbody>
    <tr>
      <td id="choices" style={{position: 'relative'}} colSpan="2">
        {choices ? <SliderContainer type={type} apps={choices} pages={pages}/> : <Loading />}
        {backButton}
      </td>
    </tr>
    </tbody>
  </table>
}