/**
 * Created by vitaliy on 22.03.17.
 */
import React from 'react';
import {_} from 'imports/lib/helpers';

const InputStateDescribe = (params) => {
  const { isError, text, className} = params;

  return (<b className={"note pre-wrap text-md padding-l-md " + (className ? className : '')} data-error={!!isError} _>{text}</b>);
};

export default InputStateDescribe;
