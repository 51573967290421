import {createContainer} from 'imports/lib/react_createContainer';
import ListSlider from './ListSlider';
import {Session} from 'core/session';
import getPageManager from 'imports/lib/page_manager';
import {capitalize, countInDomAmounts} from 'imports/lib/helpers';
import _ from 'lodash';

export default createContainer(function () {
  const ROWS_COUNT = countInDomAmounts();
  const ITEM_COUNT = 3;

  const senderIdTypes = Session.get('money_transferring_id_types');
  const items = _.map(_.filter(senderIdTypes, (item) => item.Id !== 'NN' /*None*/), function (item) {
    return {
      _id: item.Id,
      required_sender_id_type: item.Id,
      product_name: capitalize(item.Name),
      enabled: true,
      app_guid: 'app_moneytransfer_erTSg',
      icon: '/resources/icons/mt/id_types/' + item.Name.replace(/[\s/]/g, '_').toLowerCase() + '.png',
      iconDefault: '/resources/icons/payout/identification/default.png',
      name: capitalize(item.Name),
    }
  });

  const choices = items.chunk(ROWS_COUNT * ITEM_COUNT).map((item) => item.complete(ROWS_COUNT * ITEM_COUNT, {}).chunk(ITEM_COUNT));

  return {
    choices,
    title: 'Select your identification document:',
    onBack: () => getPageManager().back(),
  }
}, ListSlider);