import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import ReferrerDispense from './BalanceDispense';
import IW from 'imports/iw_api';
import getPageManager from 'imports/lib/page_manager';
import {_ as i18n, currency as C} from 'imports/lib/helpers';
import {sprintf} from 'imports/lib/external/sprintf';

export default createContainer((params) => {
  let message = '';
  const denominations = IW.appGetVal('cash_denominations');

  if (IW.appGetVal('balance_amount') > 0 && !_.isEmpty(denominations)) {
    message = sprintf(i18n('This machine only dispenses %s. Max withdrawal: %s'),
      _.map(denominations, (d) => C(d, '$')).join(', '), C(IW.appGetVal('selected_amount'), '$'));
  }

  if (IW.appGetVal('selected_amount') > 0 &&
    IW.appGetVal('selected_amount') === IW.appGetVal('balance_amount') &&
    IW.appGetVal('can_dispense')) {
    message = i18n('Press dispense to withdraw your cash');
  }

  if (_.isEmpty(denominations) || !IW.appGetVal('can_dispense')) {
    message = i18n('Machine needs to be reloaded. Notify Owner');
  }

  if (IW.appGetVal('balance_amount') === 0) {
    message = i18n('Ask your friend to use the referral code');
  }

  return {
    balanceAmount: IW.appGetVal('balance_amount'),
    message: message,
    canDispense: IW.appGetVal('can_dispense') && IW.appGetVal('selected_amount') > 0,
    onDispense: () => getPageManager().next(),
    onCancel: () => getPageManager().cancel()
  };

}, ReferrerDispense);