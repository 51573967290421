import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import SliderContainer from 'imports/ui/shared/slider/SliderContainer';
import { SLIDE_ITEM_TYPES } from 'imports/lib/constants';

export default class BillProcessingType extends Component {
  render() {
    const {billScanningEnabled,
      goToScan, goToSearch, onBack} = this.props;

    return (
      <table className="bill-scanning-page" id="center">
        <tbody>
          <tr>
            <td>
              <div className="bill-scanning-page__button-container">
                {billScanningEnabled ?
                  <Button id="scan" className="button bill-scanning-page__scan-button" onMouseDown={goToScan}
                          text_="Scan a new bill"/> :
                  null}

                <Button id="manual" className="button bill-scanning-page__enter-acc-button" onMouseDown={goToSearch} text_="Biller Search"/>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
        <tr>
          <td>
            <Button id="back" onMouseDown={onBack} className="left button" text_="Back"/>
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

BillProcessingType.propTypes = {
  billScanningEnabled: React.PropTypes.bool,
  goToScan: React.PropTypes.func,
  goToSearch: React.PropTypes.func,
  onBack: React.PropTypes.func
};