import {Session} from 'core/session';
import IW from 'imports/iw_api';

export default function (pageBack, pageNext) {
  return function* ({lastAction}) {
    Session.set('prev_current_active_field', 0);
    Session.set('current_active_field', 0);
    Session.set('money_transferring_towns', '');

    if (IW.appGetVal('logged') &&
      IW.appGetVal('recipient_town_id') &&
      IW.appGetVal('recipient_town_name') &&
      !IW.appGetVal('new_recipient') &&
      !IW.appGetVal('internal_data_error') &&
      lastAction !== 'back') {
      return pageNext;
    }

    return {
      back: function () {
        return pageBack;
      },

      next: function () {
        return pageNext;
      }
    }
  }
}
