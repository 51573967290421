import React from 'react'
import {connect} from 'react-redux';
import _ from 'lodash'
import {Session} from "./session";


const withTracker = callback => WrappedComponent => {
  return connect((state, props) => ({session: state.session, props}))(class extends React.Component {
    constructor(props) {
      super(props);
      this.callbackProps = null;
      this.sessionKeys = {};
      this.sessionValues = {};
      this.updateCallbackProps(props.props);
    }

    updateCallbackProps(props){
      Session.__getKeys = [];
      this.callbackProps = {...props, ...callback.call(this, props)};
      if (!Session.__getKeys) debugger
      _.each(Session.__getKeys, (key) => (this.sessionKeys[key] = true));
      Session.__getKeys = null;
      _.each(this.sessionKeys, (__, key) => (this.sessionValues[key] = Session._get(key), true));
    }

    shouldComponentUpdate(nextProps){
      const lastProps = this.props.props;
      const props = nextProps.props;
      let sessionKeys = this.sessionKeys;
      let sessionValues = this.sessionValues;

      if (_.isEqual(lastProps, props)) {
        if (this.props.session === nextProps.session) return false;
        const keyChanged = _.find(sessionKeys, (__, key) => sessionValues[key] !== Session._get(key));
        if (!keyChanged) return false;
      }

      this.updateCallbackProps(props);
      return true;
    }

    render(){
      return <WrappedComponent {...this.callbackProps}/>
    }
  })
}

export {withTracker};
