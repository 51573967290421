import React, {Component} from 'react';
import {usd} from 'imports/lib/helpers';
import {FlowRouter} from 'core/Router';
import Button from 'imports/ui/shared/button/Button';
import Maya5 from "imports/ui/shared/maya5/Maya5Container";
import CONFIG from '../../../config';

class Payment extends Component {


  payingHeader() {
    const {account, creditAmount, feeAmount, requestedAmount, originalAmount} = this.props;

    return (
      <tr className="paying-header">
        <td colSpan="3">
          {account ? <h2 _={[<span>{account}</span>]}>Paying for profile: %s</h2> : null}
          {creditAmount ? <h4 _={[usd(creditAmount)]}>
            A credit of %s will be posted to your account</h4> : null}
          {feeAmount && creditAmount ?
            <h4 _={[usd(originalAmount), usd(creditAmount), usd(feeAmount), usd(requestedAmount)]}>
              Amount due %s + %s credit + %s fee = %s total amount
            </h4> : null}
        </td>
      </tr>
    )
  }

  buildPayMethods(methods) {
    return _.isArray(methods) && methods.map((method, i) => {
      const imageLink = `/resources/icons/pay${(CONFIG.LIVE_IMAGES_GENMEGA && '/genmega') || ''
        }/img_${method.alias}_${(method.enabled && 'enabled') || 'disabled'}.png`;

      return (
        <div key={i} className="pay-container-body-left_pay-methods-item">
          <h3 _>{method.name}</h3>
          <img src={imageLink}/>
        </div>
      )
    });
  }

  buildNotes(notes) {
    return notes[0].map((note, i) => <span className="cash" key={i}>${note}</span>)
  }

  buildCoinNotes(notes) {
    return (
      <div className="cash-container coins">
        {notes[1].map((note, i) => note >= 100 ?
          <span className="coin" key={i}>${note / 100}</span> :
          <span className="coin" key={i}>{note}&cent;</span>)
        }
      </div>
    )
  }

  buildTipNotes() {
    const {notes} = this.props;

    return (
      <tbody>
      <tr>
        <td style={{width: '1%', whiteSpace: 'nowrap'}}>
          <h4 _ className="lock-title">Cash</h4>
        </td>
        <td>
          <div className="cash-container cash">
            {this.buildNotes(notes)}
          </div>
        </td>
      </tr>
      <tr>
        <td style={{width: '1%', whiteSpace: 'nowrap'}}>
          <h4 _ className="lock-title">Coins</h4>
        </td>
        <td>
          {this.buildCoinNotes(notes)}
        </td>
      </tr>
      </tbody>
    )
  }

  render() {

    let res = null;

    const {
      account, canGoBack, onBack, canSaveSession, onSaveSession, canAddBalance, onAddBalance, notes, discount, discountLabel, totalAmount,
      customerBalance, customerCreditAmount, originalAmount, feeAmount, moreOptions, remainingAmount, receivedAmount, receivedWaitingAmount, payMethods,
      isReferralCode, isMaya5, onReferralCode, onMaya5, isSigue
    } = this.props;

    // for test max nominal
    //notes[0] = [10, 20, 50, 100, 200, 500, 1000];

    const labels = isSigue ? {
      customerBalance: 'Account Credit',
      originalAmount: 'Transfer Amount',
      feeAmount: 'Transfer Fees',
      discount: discountLabel,
      totalAmount: 'Total Amount Receive',
      remainingAmount: 'Amount to be Inserted',
      receivedAmount: 'Received',
      applyAccountCredit: 'Next'
    }: {
      customerBalance: 'Account Credit',
      originalAmount: 'Amount Due',
      feeAmount: 'Transaction Fee',
      discount: discountLabel,
      totalAmount: 'Total due',
      remainingAmount: 'Insert',
      receivedAmount: 'Received',
      applyAccountCredit: 'Apply Account Credit'
    };

    res = (
      <table className="pay-container" style={account ? {margin: "0 auto"} : {}}>
        <tbody>
        {this.payingHeader()}
        <tr>
          <td>
            <table className="pay-container-body" data-is-sigue={!!isSigue}>
              <tbody>
              <tr>
                <td className="pay-container-body-left">

                  <div className="pay-container-body-left_pay-methods">
                    {this.buildPayMethods(payMethods)}
                  </div>

                </td>

                <td className="pay-container-body-right">

                  <div className="table-amount">
                    {customerBalance ? <div className="table-amount_item">
                      <div _>
                        {labels.customerBalance}:
                      </div>
                      <div>
                        {usd(customerBalance)}
                      </div>
                    </div> : null}

                    {originalAmount ? <div className="table-amount_item">
                      <div _>
                        {labels.originalAmount}:
                      </div>
                      <div>
                        {usd(originalAmount)}
                      </div>
                    </div> : null}

                    {feeAmount ? <div className="table-amount_item">
                      <div _>
                        {labels.feeAmount}:
                      </div>
                      <div>
                        {usd(feeAmount)}
                      </div>
                    </div> : null}

                    {discount ? <div className="table-amount_item">
                      <div>
                        {labels.discount}:
                      </div>
                      <div>
                        {usd(discount)}
                      </div>
                    </div> : null}

                    {totalAmount ? <div className="table-amount_item top-border"></div> : null}
                    {totalAmount ? <div className="table-amount_item">
                      <div _>
                        {labels.totalAmount}:
                      </div>
                      <div style={{"color": "white"}}>
                        {usd(totalAmount)}
                      </div>
                    </div> : null}

                  </div>

                  {isReferralCode ? <Button id="optional" className="center" text_="Referral code"
                                            onMouseDown={onReferralCode}/> : null}
                  {moreOptions ? <Button id="optional" className="center" text_="More payment options"/> : null}

                </td>
              </tr>
              <tr>
                <td className="pay-container-body-left">
                  <div className="pay-container-body-left_notes-container">
                    <table className="pay-container-body-left_notes-container-table">
                      {this.buildTipNotes()}
                    </table>

                  </div>
                </td>
                <td className="pay-container-body-right">
                  <div className="container-amount">
                    <div className="container-amount-item">
                      <span _>
                        {labels.remainingAmount}:
                      </span>
                      <span>
                        {usd(remainingAmount)}
                      </span>
                    </div>
                    <div className="container-amount-item">
                       <span _>
                        {labels.receivedAmount}:
                      </span>
                      <span>
                        {receivedWaitingAmount ?
                          '(' + usd(receivedWaitingAmount) + ') ' :
                          ''}
                        {usd(receivedAmount)}
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="3" style={{"display": "block", "height": "132px", "position": "relative"}}>
            {canGoBack ?
              <Button id="back" onMouseDown={onBack} className="left button" text_="Back"/> :
              null}
            {canSaveSession ?
              <Button id="next" onMouseDown={onSaveSession} className="right button button-warning"
                      text_="Continue later"/> :
              null}
            {canAddBalance ?
              <Button id="next" onMouseDown={onAddBalance} className="right button button-warning"
                      text_={labels.applyAccountCredit}/> :
              null}
            {isMaya5 && canGoBack && !canSaveSession && !canAddBalance ?
              <Maya5 onMaya5={onMaya5}/> :
              null}
          </td>
        </tr>
        </tfoot>
      </table>
    );
    return res;
  }
}

Payment.propTypes = {
  account: React.PropTypes.oneOfType([
    React.PropTypes.string,
    React.PropTypes.object
  ]),
  creditAmount: React.PropTypes.number,
  feeAmount: React.PropTypes.number,
  requestedAmount: React.PropTypes.number,
  originalAmount: React.PropTypes.number,
  customerBalance: React.PropTypes.number,
  customerCreditAmount: React.PropTypes.number,
  discount: React.PropTypes.number,
  discountLabel: React.PropTypes.string,
  totalAmount: React.PropTypes.number,
  canGoBack: React.PropTypes.bool,
  canSaveSession: React.PropTypes.bool,
  canAddBalance: React.PropTypes.bool,
  moreOptions: React.PropTypes.bool,
  isReferralCode: React.PropTypes.bool,
  isMaya5: React.PropTypes.bool,
  remainingAmount: React.PropTypes.number,
  receivedAmount: React.PropTypes.number,
  notes: React.PropTypes.array,
  payMethods: React.PropTypes.arrayOf(React.PropTypes.object),
  onBack: React.PropTypes.func,
  onReferralCode: React.PropTypes.func,
  onSaveSession: React.PropTypes.func,
  onMaya5: React.PropTypes.func,
  onAddBalance: React.PropTypes.func,
  checkDevicesStatus: React.PropTypes.func
};

Payment.defaultProps = {
  account: '1111',
  creditAmount: 0,
  feeAmount: 1,
  requestedAmount: 20,
  originalAmount: 20,
  discount: 0,
  totalAmount: 20,
  canGoBack: false,
  canSaveSession: false,
  moreOptions: false,
  isReferralCode: false,
  isMaya5: true,
  remainingAmount: 10,
  receivedAmount: 15.20,
  notes: [[1, 2, 5, 10, 20, 50], [0.01, 0.05, 0.10, 0.25]]
};

export default Payment;
