import React from 'react';

export default class CategoryItem extends React.Component {
  render() {
    const {id, name, onChangeCategory, currentCategory, accented} = this.props;
    const className = "cat_icon " + (currentCategory === id ? " active" : "") + ( accented ? " accented" : "");
    return (<a className={className} onMouseDown={() => onChangeCategory(id)} _>
      { accented ? (<div>
        <div className="accented__img-div accented__img-div__left"/>
        <span>{name}</span>
        <div className="accented__img-div accented__img-div__right"/>
      </div>) : name }
    </a>);
  }
}

CategoryItem.propTypes = {
  id: React.PropTypes.number,
  name: React.PropTypes.string,
  onChangeCategory: React.PropTypes.func.isRequired,
  currentCategory: React.PropTypes.number,
  accented: React.PropTypes.bool
};

/*
 *       <div className="accented__img-div accented__img-div__left"/>
 <span>{name}</span>
 <div className="accented__img-div accented__img-div__right"/>*/
