import {FlowRouter} from 'core/Router';
import IW from 'imports/iw_api';
import {Session} from 'core/session';
import apps from 'imports/apps_workflow';
import createSessionMethods from 'imports/lib/createSessionMethods';

const flowParams = {
  version: 0,
  lastTimer: -1,
  lastHandlers: null,
  lastGenerator: null,
  lastGeneratorStep: '',
  lastRouteHash: '',
  lastStep: '',
  lastPreviousStep: {},
  lastAction: '',
  isActive: false,
  lastRoute: {}
}

export default function () {
  const step = FlowRouter.getParam('step');

  // TODO: Should be replaced (proxy problem)
  const allActions = {
    next: 0,
    back: 0,
    skip: 0,
    logout: 0,
    login: 0,
    profile: 0,
    print: 0,
    email: 0,
    error: 0,
    cancel: 0,
    tryPay: 0,
    continue: 0,
    continueLater: 0,
    scan: 0,
    manual: 0,
    edit: 0,
    support: 0,
    payAgain: 0,
    payLast: 0,
    other: 0,
    select: 0,
    referral: 0,
    maya5: 0,
    refund: 0,
    sweepstakes: 0,
    reset: 0,
    search: 0,
    generateQR: 0,
    clerkAmount: 0,
    change: 0,
  };

  return new Proxy(allActions, {
    get(t, action) {
      if (!flowParams.lastHandlers || !flowParams.lastHandlers[action]) {
        console.log(`Application action "${action}" does not exist in context`, flowParams);
        return () => goTo('exit');
      }
      return createHandler(flowParams.lastHandlers, step, action);
    }
  });
}

/**
 *
 * @param step
 * @param appGuid
 * @param productId
 * @param language
 */
export function goTo(step, appGuid, productId, language) {
  function goToStep(s) {
    const currentHash = language +
      appGuid +
      String(productId) +
      s;

    if (flowParams.lastRouteHash === currentHash) return;

    console.log('Page Manager: go to:', step, appGuid, productId, language);

    goFlowRouter("Application", {
      language: language,
      appName: appGuid,
      productId: String(productId),
      step: s
    });
    flowParams.lastRouteHash = currentHash;
  }

  function waitTill(predicate, callback, showLoading = true) {
    if (!flowParams.isActive) return;
    if (!predicate()) {
      return callback();
    }
    if (showLoading) goToStep('loading');
    flowParams.lastTimer = setTimeout(() => waitTill(predicate, callback, showLoading), 10);
  }

  const pages = window.pageManager.pages;
  appGuid = flowParams.lastRoute.appGuid = appGuid || FlowRouter.getParam('appName') || flowParams.lastRoute.appGuid;
  productId = flowParams.lastRoute.productId = productId || FlowRouter.getParam('productId') || flowParams.lastRoute.productId;
  language = flowParams.lastRoute.language = language || FlowRouter.getParam('language') || flowParams.lastRoute.language;

  console.log('Page Manager:', JSON.stringify(step), IW.__debug_createStackTraceObj());

  // Parse the step as a command
  if (step === 'exit') {
    console.log('Application exit');
    IW.mainMenu();
    clear();
    return;
  }

  if (typeof step === 'function') {
    goTo(step(), appGuid, productId, language);
    return;
  }

  if (typeof step === 'object' && step.__nextWhen) {
    waitTill(() => !step.condition(), () => next(null, appGuid, productId, language), false);
    return next(null, appGuid, productId, language);
  }

  if (typeof step === 'object') {
    flowParams.lastHandlers = step;
    next(null, appGuid, productId, language);
    return;
  }

  if (typeof step !== 'string') {
    throw Error(`The step ${JSON.stringify(step)} is not allowed`);
  }

  flowParams.isActive = true;
  waitTill(() => !Session.get('session_guid'), () => {
    const app = apps[appGuid];
    if (!app) throw Error(`Application ${appGuid} does not exist`);

    if (typeof app[step] === 'object') {
      if (!pages[step]) throw Error(`The step ${step} does not have a Component`);
      flowParams.lastHandlers = app[step];
      if (typeof step !== 'string') throw Error(`Invalid next step ${step}`);

      saveLastStep(step);
      goToStep(step);
      return;
    }

    if (typeof app[step] === 'function') {
      if (!pages[step]) throw Error(`The step ${step} does not have a Component`);
      const actionResult = call(app[step], {...flowParams});
      saveLastStep(step);
      if (isGenerator(actionResult)) {
        if (flowParams.lastGenerator) throw Error('There is unprocessed generator');
        flowParams.lastGenerator = actionResult;
        flowParams.lastGeneratorStep = step;
        next(null, appGuid, productId, language);
        return;
      }
      if (typeof actionResult === 'object') {
        flowParams.lastHandlers = actionResult;
        if (typeof step !== 'string') throw Error(`Invalid next step ${step}`);
        goToStep(step);
        return;
      }

      if (typeof actionResult === 'string') {
        goTo(actionResult, appGuid, productId, language);
        return;
      }

      if (typeof actionResult === 'function') {
        goTo(actionResult(), appGuid, productId, language);
        return;
      }
    }

    if (/loading$/.test(step)) {
      console.log('Page Manager: loading till next()');
      goToStep(step);
      return;
    }

    if (step === 'none') {
      goToStep(flowParams.lastStep);
      return;
    }

    if (step === 'back') {
      goTo(getPreviousStep(flowParams.lastStep) || 'exit',
        appGuid, productId, language);
      return;
    }

    let reg = /^loading\s+(\d+)$/
    let value = step.match(reg) && step.match(reg)[1]
    if (value && !isNaN(value)) {
      goToStep('loading');
      flowParams.lastTimer = setTimeout(() => next(null, appGuid, productId, language), +value);
      return;
    }

    reg = /^sleep\s+(\d+)$/
    value = step.match(reg) && step.match(reg)[1]
    if (value && !isNaN(value)) {
      goToStep(flowParams.lastStep);
      flowParams.lastTimer = setTimeout(() => next(null, appGuid, productId, language), +value);
      return;
    }

    reg = /^sleep\s+(\d+)\s+([A-Za-z_0-9]+)$/
    value = step.match(reg) && step.match(reg)[1];
    const shownPage = step.match(reg) && step.match(reg)[2];
    if (value && !isNaN(value) && shownPage) {
      goToStep(shownPage);
      flowParams.lastTimer = setTimeout(() => next(null, appGuid, productId, language), +value);
      return;
    }

    reg = /^(loading|sleep)\s+([A-Za-z_0-9]+)\s+([A-Za-z_0-9]+)$/
    const showLoading = step.match(reg) && step.match(reg)[1] !== 'sleep';
    const type = step.match(reg) && step.match(reg)[2];
    value = step.match(reg) && step.match(reg)[3];
    if (type && value) {
      if (!showLoading) goToStep(flowParams.lastStep);
      switch (type) {
        case 'session':
          return waitTill(() => !Session.get(value), () => next(null, appGuid, productId, language), showLoading);
        case 'session_exists':
          return waitTill(() => typeof Session.get(value) === "undefined", () => next(null, appGuid, productId, language), showLoading);
        case 'app':
          return waitTill(() => !IW.appGetVal(value), () => next(null, appGuid, productId, language), showLoading);
        case 'app_exists':
          return waitTill(() => typeof Session.get("app")[value] === "undefined", () => next(null, appGuid, productId, language), showLoading); //fixme: dirty hack
        case 'login':
          return waitTill(() => !IW.loginGetVal(value), () => next(null, appGuid, productId, language), showLoading);
        case 'login_exists':
          return waitTill(() => typeof Session.get("login")[value] === "undefined", () => next(null, appGuid, productId, language), showLoading);  //fixme: dirty hack
        case 'eval':
          return waitTill(() => !evalString(value), () => next(null, appGuid, productId, language), showLoading);
        default: {
          if ((/_exists/).test(type)) {
            return waitTill(() => typeof Session.get(type.replace('_exists', ''))[value] === "undefined", () => next(null, appGuid, productId, language), showLoading);  //fixme: dirty hack
          }
          const SM = createSessionMethods(type);
          return waitTill(() => !SM.getVal(value), () => next(null, appGuid, productId, language), showLoading);
        }
      }
    }

    throw Error(`Application step "${step}" does not exist`);
  });
}

/**
 *
 * @param params
 * @param appGuid
 * @param productId
 * @param language
 */
export function next(params, appGuid, productId, language) {
  const pages = window.pageManager.pages;
  if (!flowParams.lastGenerator) throw Error('There is no generators waiting for');

  appGuid = flowParams.lastRoute.appGuid = flowParams.lastRoute.appGuid || appGuid || FlowRouter.getParam('appName');
  productId = flowParams.lastRoute.productId = flowParams.lastRoute.productId || productId || FlowRouter.getParam('productId');
  language = flowParams.lastRoute.language = flowParams.lastRoute.language || language || FlowRouter.getParam('language');

  const nextValue = call.call(flowParams.lastGenerator, flowParams.lastGenerator.next, {
    step: flowParams.lastStep,
    params
  });
  if (nextValue.done) flowParams.lastGenerator = null;

  if (nextValue.done && typeof nextValue.value === 'object') {
    if (flowParams.lastGenerator && flowParams.lastGenerator.isUserActionGenerator) throw Error('Current generator should only return next step');
    if (typeof flowParams.lastGeneratorStep !== 'string') throw Error('Current step is not set');
    if (!pages[flowParams.lastGeneratorStep]) throw Error(`The step ${flowParams.lastGeneratorStep} does not have a Component`);

    flowParams.lastHandlers = nextValue.value;
    saveLastStep(flowParams.lastGeneratorStep);
    goFlowRouter("Application", {
      language: language,
      appName: appGuid,
      productId: String(productId),
      step: flowParams.lastGeneratorStep
    });
    flowParams.lastRouteHash = '';
  } else {
    goTo(nextValue.value, appGuid, productId, language);
  }
}

export function nextWhen(condition) {
  return {__nextWhen: true, condition}
}

export function clear() {
  flowParams.lastHandlers = null;
  flowParams.lastGenerator = null;
  flowParams.lastGeneratorStep = '';
  flowParams.lastRouteHash = '';
  flowParams.lastStep = '';
  flowParams.lastPreviousStep = {};
  flowParams.isActive = false;
  flowParams.lastAction = '';
  clearTimeout(flowParams.lastTimer);
}

function createHandler(page, step, actionName) {
  function clearGenerator() {
    console.error('There is unprocessed generator', flowParams.lastStep, flowParams.lastGenerator.actionName,
      'Canceling and start new one', step, actionName);
    clearTimeout(flowParams.lastTimer);
    flowParams.lastGenerator = null;
  }

  function handler(...params) {
    const action = page[actionName];
    flowParams.lastAction = actionName;
    let nextStep = 'exit';
    console.log('Page Manager: action:', step, actionName);

    let doClearGenerator = false;

    if (flowParams.lastGenerator) {
      if (flowParams.lastGenerator.step === step &&
        flowParams.lastGenerator.actionName === actionName) {
        console.log('There is unprocessed generator. Ignore new same action', step, actionName);
        return;
      }

      doClearGenerator = true;
    }

    if (typeof action === 'function') {
      const actionResult = call(action, {...flowParams, params});
      if (isGenerator(actionResult)) {
        if (doClearGenerator) clearGenerator();
        doClearGenerator = false;
        flowParams.lastGenerator = actionResult;
        flowParams.lastGenerator.isUserActionGenerator = true;
        flowParams.lastGenerator.step = step;
        flowParams.lastGenerator.actionName = actionName;

        let nextValue = call.call(flowParams.lastGenerator, flowParams.lastGenerator.next, {step, params});
        if (nextValue.done) flowParams.lastGenerator = null;

        nextStep = nextValue.value;
      }

      if (typeof actionResult === 'string') {
        nextStep = actionResult;
      }

      if (typeof actionResult === 'function') {
        nextStep = actionResult();
      }
    }

    if (typeof action === 'string') {
      nextStep = action;
    }

    if (nextStep === 'none') return;
    if (doClearGenerator) clearGenerator();

    if (typeof nextStep === 'object' && nextStep.__nextWhen) return goTo(nextStep);

    if (typeof nextStep !== 'string') throw Error('Invalid next page');
    goTo(nextStep);
  }

  return handler;
}

function evalString(str) {
  const func = new window.Function('IW', 'Session', str);
  return func(IW, Session);
}

function saveLastStep(step) {
  function push(currentStep, lastStep) {
    const arr = flowParams.lastPreviousStep[currentStep] || (flowParams.lastPreviousStep[currentStep] = ['exit']);
    arr.push(lastStep);
  }

  function pop(currentStep) {
    return flowParams.lastPreviousStep[currentStep] && flowParams.lastPreviousStep[currentStep].pop()
  }

  if (step !== flowParams.lastStep && step !== getPreviousStep(flowParams.lastStep)) {
    push(step, flowParams.lastStep || 'exit')
  }

  if (step !== flowParams.lastStep && step === getPreviousStep(flowParams.lastStep)) {
    pop(flowParams.lastStep)
  }

  flowParams.lastStep = step
}

function getPreviousStep(step) {
  const arr = flowParams.lastPreviousStep[step];
  return arr && arr[arr.length - 1];
}

function goFlowRouter(key, data) {
  const step = data.step.split('_').filter(i => i).map((item) => item[0].toUpperCase() + item.slice(1)).join(' ');
  IW.sessSetStatus(step);
  try {
    FlowRouter.go(key, {
      language: data.language,
      appName: data.appName,
      productId: String(data.productId),
      step: data.step
    });
  } catch (err) {
    IW.handleError(['The bug in FlowRouter reproduced:',
      err.message,
      JSON.stringify(flowParams),
      JSON.stringify(key),
      JSON.stringify(data)
    ].join(' '));
  }
}

function call(func, ...args) {
  try {
    if (typeof func === 'function') {
      return func.apply(this, args);
    }
  } catch (err) {
    clear();
    IW.handleError('Page Manager: ' + err.message + ' ' + JSON.stringify(flowParams));
    throw err;
  }
}

function isGenerator(obj) {
  return typeof obj === 'object' && obj.next && !obj.hasOwnProperty('next')
}


if (process.env.NODE_ENV !== 'production') {
  setInterval(function () {
    if (flowParams.version === 0 && Session.get('flow_params')) {
      _.extend(flowParams, JSON.parse(Session.get('flow_params')));
      flowParams.lastGenerator = null;
      goTo(flowParams.lastStep);
    }
    flowParams.version++;
    Session.set('flow_params', JSON.stringify(flowParams));
  }, 500);
}

window.pageManager = {};
window.pageManager.goTo = goTo;
window.pageManager.next = next;
window.pageManager.flowParams = flowParams;
