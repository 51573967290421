/**
 * Created by vitaliy on 09.03.17.
 */
import {createContainer} from 'imports/lib/react_createContainer';
import Payout from './Payout';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';

export default createContainer(() => {

  const DEFINED_PAYMENT_MODES = {
    cash_pickup: {
      name: 'Cash Pick Up',
      status: true,
      required_bank: false,
      required_account: false,
      displayIndex: 0
    },
    bank_deposit: {
      name: 'Bank Deposit',
      status: true,
      required_bank: true,
      required_account: true,
      displayIndex: 1
    },
    delivery: {
      name: 'Delivery',
      status: true,
      required_bank: false,
      required_account: false,
      displayIndex: 2
    }
  };

  const paymentModes = _.map(IW.appGetVal('payment_modes'), (paymentMode) => _.extend({}, DEFINED_PAYMENT_MODES[paymentMode.type], paymentMode)) || [];

  return {
    paymentModes: _.sortBy(paymentModes, 'displayIndex'),
    onBack: () => getPageManager().back(),
    onNext: (id, type) => {
      IW.appSetVal('recipient_payment_mode_id', id);
      IW.appSetVal('recipient_payment_mode_type', type);
      getPageManager().next()
    },
    selectLabel_: "Select recipient's payout method"
  };

}, Payout);
