import React, {Component} from 'react';
import Numpad from 'imports/ui/shared/numpad/Numpad';
import IW from 'imports/iw_api';
import {Session} from 'core/session';
import Button from 'imports/ui/shared/button/Button';
import Input from 'imports/ui/shared/input/Input';

export default class PhoneNumber extends Component {

  componentDidMount() {
    Session.set('focus', 'code');

    var field_to_focus = Session.get('focus');
    Session.set('focus', '');
    Session.set('focus', field_to_focus);

    // Forcing reactivity of number_ok
    var code = IW.appGetVal('code');
    IW.appSetVal('code', '');
    IW.appSetVal('code', code);
  }

  render() {
    const {code, pattern, numpadLayout, onTypeCode, hideResendButton, isPincodeOk, onResendCode, onExit, onNext} = this.props;

    return (
      <table>
        <tbody>
        <tr>
          <td style={{padding: "40px", textAlign: "center"}}>
            <h3 className="shadow" _>pin code</h3>
            <Input id="code" pattern={pattern} value={code} className="center" readOnly={true}/>
            <br /><br />
            <b className="note pre-wrap" _>If you did not receive the code,\nhit the "Resend" button below.</b>
          </td>
          <td className="center">
            <Numpad keys={numpadLayout} pattern={pattern} value={code} onValueChange={onTypeCode}/>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2" className="center">
            <Button id="back" className="left button upper goto-main" text_="Exit" onMouseDown={onExit}/>

            { !hideResendButton ?
              <Button id="btn-resend" className="button upper" onMouseDown={onResendCode} text_="Resend"/> : null }

            { isPincodeOk ? <Button id="next" className="right button upper" onMouseDown={onNext}
                                    text_="Next"/> :
              <Button id="next" className="right button upper" style={{opacity: 0}} text_="Next"/> }
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

PhoneNumber.propTypes = {
  appGuid: React.PropTypes.string,
  pages: React.PropTypes.object,
  code: React.PropTypes.string,
  pattern: React.PropTypes.string,
  hideResendButton: React.PropTypes.bool,
  isPincodeOk: React.PropTypes.bool,
  numpadLayout: React.PropTypes.arrayOf(React.PropTypes.array),
  onTypeCode: React.PropTypes.func,
  onResendCode: React.PropTypes.func,
  onExit: React.PropTypes.func,
  onNext: React.PropTypes.func
};
