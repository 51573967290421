import React, {Component} from 'react';
import {FlowRouter} from 'core/Router';
import {C} from 'imports/lib/helpers';
import Button from 'imports/ui/shared/button/Button';
import safeRender from 'imports/ui/decorators/safeRender';

class BalanceDispense extends Component {

  render() {
    const {balanceAmount, message, canDispense, onDispense, onCancel} = this.props;

    return (
      <table>
        <tbody>
        <tr>
          <td>
            <div className="atm-top-tr">
              <div>
                <div className="atm__temp-page atm__temp-page__text">
                  <span _={[C(balanceAmount, '$')]}>Your amount is %s</span>
                </div>
                <div className="atm__temp-page atm__display__text" onMouseDown={() => canDispense ?
                  onDispense() :
                  onCancel()}>
                  <span>{message}</span>
                </div>
              </div>

              <div className="atm__temp-page atm__display__img">
                <img src="/resources/images/atm/ic_cash.png" alt=""/>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td style={{textAlign: 'center'}}>
            <Button className="left button button-danger" onMouseDown={onCancel} text_="Cancel"/>
            {canDispense ?
              <Button id="next" className="right button" onMouseDown={onDispense} text_="Dispense"/> :
              null}
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

export default BalanceDispense;