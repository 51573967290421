import container from './container';
import {createContainer} from 'imports/lib/react_createContainer';
import CONFIG from 'imports/config';

import Final from './Final';
import FinalAdvertisement from './FinalAdvertisement';

export default createContainer(container, CONFIG.SHOW_POPULAR_PRODUCTS_ADVERTISEMENT ?
  FinalAdvertisement :
  Final);
