import {actions, register, state, assert} from '../../lib/app_flow_testing'
import IW from 'imports/iw_api';

register({
  common: {
    simple: {
      should_able_back: function* () {
        actions.mousedown('#back', 'Back is not avaliable')
        yield actions.loading()
      },


      should_able_next: function* () {
        actions.mousedown('#next', 'Next is not avaliable')
        yield actions.loading()
      },

      should_able_menu: function* () {
        actions.mousedown('#menu', 'Menu is not avaliable')
        yield actions.loading()
      },

      should_able_clear: function() {
        actions.mousedown('.cleared-input__clear-button', 'Clear does not exists');
      },
    },

    flow: {
      should_continue_phone_2042121111: createShouldContinuePhone('2042121111'),
      should_continue_phone_9292297777: createShouldContinuePhone('9292297777'),
      should_continue_phone_9292297771: createShouldContinuePhone('9292297771'),
      should_continue_phone_9292297772: createShouldContinuePhone('9292297772'),
      should_continue_phone_9292297773: createShouldContinuePhone('9292297773'),
      should_continue_phone_9292297774: createShouldContinuePhone('9292297774'),
      should_continue_phone_2014222656: createShouldContinuePhone('2014222656'),

      should_pay_and_finish: function* () {
        yield actions.go('common            | pay                    | should_able_pay');
        yield actions.go('common            | final                  | should_able_finish');
      },

      should_menu_on_pay: function* () {
        if (!(/pay_/.test(state().page))) throw ('Page is not payment page');
        yield actions.go('common            | simple                  | should_able_menu');
      },

      new_customer_should_able_login: function* () {
        yield actions.go('common            | flow                    | should_continue_phone_9292297771');
        yield actions.go('common            | pin_number_sing_in      | should_allow_pin_1111');
      },

      new_customer_should_able_set_new_pin: function* () {
        yield actions.go('common            | flow                    | should_continue_phone_9292297772');
        yield actions.type('1111');
        yield actions.loading();
        assert.page('new_pin_number_sing_in')
        yield actions.type('1111');
        yield actions.go('common            | simple      | should_able_next');
        assert.not.exists('#next');
        yield actions.type('1112');
        assert.not.exists('#next');
        yield actions.go('common            | simple      | should_able_clear');
        yield actions.type('1111');
        yield actions.go('common            | simple      | should_able_next');
      }
    },


    stored_sessions: {
      should_skip: function* () {
        if (state().page !== 'stored_sessions') return;
        yield actions.go('common.simple.should_able_next')
      }
    },


    pin_number_sing_in: {
      should_allow_pin_1111: function* () {
        assert.page('pin_number_sing_in')
        yield actions.type('1111');
        yield actions.waitLoading();
        if (state().page === 'pin_number_sing_in') throw ('Pin does not work')
      },

      should_allow_pin_2222: function* () {
        assert.page('pin_number_sing_in')
        yield actions.type('2222');
        yield actions.waitLoading();
        if (state().page === 'pin_number_sing_in') throw ('Pin does not work')
      },

      should_allow_pin_7777: function* () {
        assert.page('pin_number_sing_in')
        yield actions.type('7777');
        yield actions.waitLoading();
        if (state().page === 'pin_number_sing_in') throw ('Pin does not work')
      },

      should_allow_pin_1234: function* () {
        assert.page('pin_number_sing_in')
        yield actions.type('1234');
        yield actions.waitLoading();
        if (state().page === 'pin_number_sing_in') throw ('Pin does not work')
      }
    },

    pay: {
      should_able_pay: function* () {
        assert.regexp('#center', /Apply Account Credit/);
        actions.mousedown('#center', 'No credit to pay');
        yield actions.waitLoading();
      },
    },

    final: {
      should_able_finish: function* () {
        if (state().page === 'sweepstakes_start_question' || state().page === 'final') {
          yield actions.go('common . simple . should_able_menu');
          return
        }
        throw ('Payment failed')
      }
    }
  }
})


function createShouldContinuePhone(phone){
  return function* () {
    yield actions.go('common.simple.should_able_clear');
    const page = state()
    yield actions.type(phone);
    yield actions.waitElement('#next');
    actions.mousedown('#next', 'Number valid but next do not appear');
    yield actions.loading()
    if (state().page === page) throw ('Valid phone but no continue')
  }
}
