import {createContainer} from 'imports/lib/react_createContainer';
import DatePickerIOSlider from './DatePickerIOSlider';
import moment from 'imports/lib/external/moment-with-langs';
import {Session} from 'core/session';
import {capitalize} from 'imports/lib/helpers';
import _ from 'lodash';

let lastTimeout = -1;
let lastDate = 0;

const DatePickerIOSliderContainer = createContainer(function (params) {
  const startYear = params.startYear;
  const currentDate = lastDate = _.find([+new Date(+params.date), +new Date(params.date), Date.now()], (v) => v);
  const onDateChange = params.onDateChange || (() => {
  });

  function daysInMonth(m, y) {
    // m : 1...12
    return new Date(startYear + y, m + 1, 0).getDate();
  }

  function getDays(m, y) {
    // month 0...11
    return (new Array(daysInMonth(m, y))).fill(1).map((item, index) => {
      return {text: (index + 1).toString(), value: index + 1}
    });
  }

  function getMonths() {
    moment.lang(Session.get('locale'));
    const months = moment.months();
    moment.lang('en_US');
    return months.map((item, index) => {
      return {text: capitalize(item.toString()), value: index}
    });
  }

  function getYears() {
    return new Array(2030 - startYear).fill(1).map((item, index) => {
      return {text: (startYear + index).toString(), value: startYear + index}
    });
  }

  function handleValueChange(item, slider) {
    Meteor.clearTimeout(lastTimeout);
    lastTimeout = Meteor.setTimeout(function () {
      const date = new Date(lastDate || +params.date);

      const y = slider.type === 'slider-year' ? +item.value : date.getFullYear()
      const m = slider.type === 'slider-month' ? +item.value : date.getMonth()
      const d = slider.type === 'slider-day' ? +item.value : date.getDate()

      const maxDays = new Date(y, m + 1, 0).getDate()

      const newDate = new Date(y, m, Math.min(d, maxDays))

      lastDate = +newDate
      onDateChange(+newDate)
    }, 200)
  }

  const months = getMonths()
  const years = getYears()
  const days = getDays(new Date(currentDate).getMonth(), new Date(currentDate).getFullYear())

  return {
    items: [
      {
        items: months,
        type: 'slider-month',
        selected: new Date(currentDate).getMonth(),
      },
      {
        items: days,
        type: 'slider-day',
        selected: days.length < new Date(currentDate).getDate() ? 0 : new Date(currentDate).getDate() - 1,
      },
      {
        items: years,
        type: 'slider-year',
        selected: new Date(currentDate).getFullYear() - startYear,
      }
    ],
    onValueChange: handleValueChange,
  }

}, DatePickerIOSlider);


export default DatePickerIOSliderContainer;


function getDefaultStartDate() {
  const date = new Date();
  return +new Date(date.getFullYear() - 40, date.getMonth(), date.getDate());
}