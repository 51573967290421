import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import './sweepstakes2.scss';
import {N} from '../../../lib/helpers';
import _ from 'lodash';
import $ from 'jquery';

class Sweepstakes extends Component {
  constructor(p) {
    super(p);
    const {prizes, winningPrize} = this.props;
    let values = _.times(12, () => _.shuffle(prizes)[0]);
    values = _.shuffle(values.concat(_.times(6, () => 0)));
    values[values.length - 4] = 0;
    values[2] = winningPrize;
    values = _.map(values, (v) => '$' + N(v));
    this.state = {show: true, values: values, offset: 0}
  }

  componentDidMount() {
    this.$feGaussianBlur = $('feGaussianBlur');
  }

  shouldComponentUpdate(newProps, newState) {
    return newState.goToEnd !== this.state.goToEnd
  }

  rolling() {
    const {onMoveComplete} = this.props;
    if (this.state.goToEnd) return;
    this.setState({goToEnd: true, offset: Math.random()*260});
    const startTime = Date.now();
    const interval = setInterval(() => {
      const progress = (Date.now() - startTime) / 4000;
      const f = (x) => {
        x *= 9.44;
        x -= 6;
        return (-0.01 * x * x * x * x + 0.2 * x * x - 0.5 * x + 0.3) / 3;
      };
      let roundBlur = Math.min(Math.max(0, Math.round(40 * 50 * f(progress))) / 50, 100);
      if (roundBlur < 0.02) roundBlur = 0;
      this.$feGaussianBlur[0].setAttribute('stdDeviation', '0,' + roundBlur);
    }, 30);

    setTimeout(() => {
      clearInterval(interval);
      this.$feGaussianBlur[0].setAttribute('stdDeviation', '0,0');
      onMoveComplete();
    }, 4000);
  }

  render() {
    const {onMenu} = this.props;
    const {values, goToEnd, offset} = this.state;
    const renderResult = _.map(values, (v, k) => <div key={k} className="slide" data-type={(k % 3) + 1}>{v}</div>);

    return (
      <table className="sweepstakes" onMouseDown={this.rolling.bind(this)}>
        <div className="loading" data-hidden={!!this.state.show}>
          <img id="spinner" src="/themes/interwallet/images/spinner.png"/>
        </div>
        <tbody data-hidden={!this.state.show}>
        <tr>
          <td></td>
          <td className="container">
            <div className="sweeptakes-slider-container">
              <div className="slider" data-end={goToEnd} style={{paddingTop: offset}}>
                {renderResult}
              </div>
            </div>
            <div className="prevent-twise" data-rolling={goToEnd}/>
          </td>
          <td>
            <div className="arrow"/>
            <div className="swipe-message" data-pressed={goToEnd} data-rolling={goToEnd} _>
              PRESS & HOLD TO SPIN
            </div>
            <Button id="menu" className="button button-header button-danger swipe-menu-button" onMouseDown={onMenu}
                    text_="Menu"/>
          </td>
        </tr>
        </tbody>
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{display: 'none'}}>
          <defs>
            <filter id="blur">
              <feGaussianBlur in="SourceGraphic" stdDeviation="0,0"/>
            </filter>
          </defs>
        </svg>
      </table>
    )
  }
}

Sweepstakes.propTypes = {
  prizes: React.PropTypes.any,
  winningPrize: React.PropTypes.number,
  onMenu: React.PropTypes.func,
  onMoveComplete: React.PropTypes.func
};

Sweepstakes.defaultProps = {
  rolling: false,
  onMenu: () => {
  },
  onMoveComplete: () => {
  },
};

export default Sweepstakes;
