import {Session} from 'core/session'
import IW from 'imports/iw_api'
import CONFIG from 'imports/config'

export default function (pageBack = 'back') {
  return function* ({lastAction, lastStep}) {

    if (lastAction === 'back') {
      if (Session.get('entry_point_path')){
        return 'exit'
      }
      return pageBack;
    }

    if (!_.contains(['no_coin_change_notice', 'stored_sessions'], lastStep)) {
      Session.set('transaction_limit', null);
      IW.callWithUpdate('getTransactionLimit', 'transaction_limit');
      yield 'loading session transaction_limit';
      IW.appSetObject(Session.get('transaction_limit'));
      if (IW.appGetVal('customer_limit_amount') < 20) return 'customer_limit_reached';

      if (CONFIG.SHOW_NO_COINS_NOTICE && !IW.appGetVal('no_coin_change_notice_shown')) {
        IW.appSetLocalVal('no_coin_change_notice_shown', true)
        return 'no_coin_change_notice';
      }

      lastStep = 'no_coin_change_notice';
    }

    if (lastStep === 'no_coin_change_notice'){
      IW.appSetLocalVal('customer_stored_sessions', null);
      IW.getCustomerStoredSessions((_, res) => IW.appSetLocalVal('customer_stored_sessions', res));
      yield 'loading app customer_stored_sessions';

      if (!_.isEmpty(IW.appGetVal('customer_stored_sessions'))) {
        return 'stored_sessions'
      }
    }

    return IW.appGetVal('customer_next_page');
  }
}