import {createContainer} from 'imports/lib/react_createContainer';
import UserTransferDetails from './UserTransferDetails';
import IW from 'imports/iw_api';
import {S, _ as i18n, capitalize} from 'imports/lib/helpers'
import {MONEY_TRANSFER_PAYMENT_MODES, INTERNAL_DATA_ERRORS, VIAMERICA_INTERNAL_ERRORS} from 'imports/lib/constants';
import {sprintf} from 'imports/lib/external/sprintf';

export default createContainer((params) => {

  if (!params.userType) return {};

  let userType = '';
  let user = '';

  if (/bank/.test(params.userType) && IW.appGetVal("payer_bank_name")) {
    userType = 'recipient';
    user = IW.appGetVal("payer_bank_name");
  }

  if (!userType && (/bank/.test(params.userType))) {
    userType = 'recipient';
    user = i18n('Bank required');
  }

  if (!userType) {
    userType = params.userType;
    user = i18n(capitalize(params.userType.replace(/_/g, ' ').replace(/^[a-z]{0,2}[^a-z]|[^a-z ]/g, '')));
  }

  const payment_mode = MONEY_TRANSFER_PAYMENT_MODES[IW.appGetVal(userType + '_payment_mode_type')] || {};
  const city = S(IW.appGetVal(userType + '_city_name'));

  const errorMessage = sprintf(i18n(INTERNAL_DATA_ERRORS[IW.appGetVal('internal_data_error')]), capitalize(S(payment_mode.fullName)), city);

  let validationError = null;

  if (IW.appGetVal('validation_error') && VIAMERICA_INTERNAL_ERRORS[IW.appGetVal('validation_error')]) {
    validationError = VIAMERICA_INTERNAL_ERRORS[IW.appGetVal('validation_error')].message || IW.appGetVal('validation_error');
  }

  return {
    number: IW.appGetVal(userType + '_number'),
    country : IW.appGetVal(userType + '_address_country'),
    user,
    email: IW.appGetVal(userType + '_email'),
    error_message: errorMessage || validationError
  };

}, UserTransferDetails);
