import React, {Component} from 'react';
import {_} from 'imports/lib/helpers';

export default class Tabs extends Component {

  componentDidMount() {

  }

  render() {
    const {pages, activeTab, setActiveTab} = this.props;

    return (
      <table id="tabbar">
        <tbody>
        <tr>
          <td>
            <a id="tab_account" className={activeTab == 'tab_account' ? 'active shadow' : ''}
               onMouseDown={() => setActiveTab('tab_account')}>{_("Account Balance\nAnd Details")}</a>
          </td>
          <td>
            <a id="tab_speed" className={activeTab == 'tab_speed' ? 'active shadow' : ''}
               onMouseDown={() => setActiveTab('tab_speed')}>{_("Speed Dial\nNumbers")}</a>
          </td>
          <td>
            <a id="tab_quick" className={activeTab == 'tab_quick' ? 'active shadow' : ''}
               onMouseDown={() => setActiveTab('tab_quick')}>{_("Quick Dial\nNumbers")}</a>
          </td>
          <td>
            <a id="tab_family" className={activeTab == 'tab_family' ? 'active shadow' : ''}
               onMouseDown={() => setActiveTab('tab_family')}>{_("Family\nNumbers")}</a>
          </td>
        </tr>
        </tbody>
      </table>
    )
  }
}

Tabs.propTypes = {
  activeTab: React.PropTypes.string,
  setActiveTab: React.PropTypes.func,
  components: React.PropTypes.object
};
