import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import SendApplicationSMS from './SendApplicationSMS';
import IW from 'imports/iw_api';
import Prods from 'imports/collections/products';
import SimpExp from 'imports/lib/external/simpexp';
import Countries from 'imports/collections/countries';
import {Session} from 'core/session';
import CONFIG from 'imports/config';
import {N} from 'imports/lib/helpers';

const DEFAULT_MASK = '\\+1 [2-9|0]\\d{2} \\d{3} \\d{4}'; //'\\d{12}';

export default createContainer((params) => {
  const NO_CHAR = 'ё';
  const NO_CHAR_REG = /ё/;

  const product = Prods.findOne(N(FlowRouter.getParam("productId")));

  const isSenderNumber = params.isSenderNumber;
  const isRecipientNumber = params.isRecipientNumber;
  const isSupportNumber = params.isSupportNumber;
  const prefix = params.prefix ? params.prefix + '_' : '';

  function getNumber() {
    return (
      getAdditionalNumber(prefix) ||
      IW.beginningByPattern(new SimpExp(getPattern()).gen(NO_CHAR), NO_CHAR)
    )
  }

  // Logic if user to input some wrong numbers
  function partialCountryMaskCheck(number) {
    if (!number) {
      return;
    }

    const {PARTIAL_MATCHING, BAD_PARTIAL_MATCHING} = CONFIG.PARTIALS_REGEX;

    number = number.replace(/ /g, '').replace(/\+/, '');
    if (!(product && product.mask)) {
      const countryCodesForList = getNumberCountries(number);
      // console.log('PhoneNumberContainer | partialCountryCheck | number, countryCodesForList : ', number, countryCodesForList);
      // Check on 'bad' matching. In case +1 1.. ... .... - this is unknown country, use alternative pattern
      const badMatching = BAD_PARTIAL_MATCHING[number[0]];
      if (badMatching && badMatching.number.test(number)) {
        console.log('PhoneNumberContainer | partialCountryCheck |badMatching ', badMatching);
        setPattern(badMatching.code, badMatching.pattern);
        return;
      }

      countryCodesForList.forEach(function (item) {
        let hasPartialRegex = PARTIAL_MATCHING[item.iso3];
        if (hasPartialRegex) {
          if (hasPartialRegex.control_length <= number.length) {
            console.log('PhoneNumberContainer | partialCountryCheck | hasPartialRegex, number  : ', hasPartialRegex, number);
            console.log('PhoneNumberContainer | partialCountryCheck | is match : ', hasPartialRegex.number.test(number));
            if (hasPartialRegex.number.test(number)) {
              console.log('PhoneNumberContainer | partialCountryCheck | country must set : ', item.iso3);
              setPatternByCountryId(item._id);
            }
          }
        }
      });
    }
  }

  function getPattern() {
    let pattern = Session.get('checked_mask') || DEFAULT_MASK;

    if (product && product.mask){
      pattern = product.mask;
    }

    if (isSenderNumber || isRecipientNumber || isSupportNumber){
      pattern = Session.get('checked_mask');
    }

    return pattern;
  }

  //Set pattern by checked country from DropdownList
  function setPatternByCountryId(id) {
    const country = Countries.findOne({_id: id});

    if (country) {
      const {code, pattern} = country;
      setPattern(code, pattern);
    } else {
      //Session.set('checked_mask', null); // TODO: investigate why it is needed
    }
  }

  function setPattern(code, pattern) {
    Session.set('checked_mask', pattern);
    const number = formatNumberByPattern(getNumber(), code, pattern);
    IW.beginningByPattern(new SimpExp(pattern).gen(NO_CHAR), NO_CHAR);
    console.log('PhoneNumberContainer | setPattern | number, pattern : ', number, pattern);
    setAdditionalNumber(prefix, number);
  }

  function getNumberCountries(number) {
    number = number.replace(/[^0-9]/g, '');
    if (!number) return [];

    let countryCodesForList = Countries.find().fetch().filter(function (item) {
      const code = item.code.replace(/[^0-9]/g, '');
      const minLenght = Math.min(number.length, code.length);
      return number.substr(0, minLenght) === code.substr(0, minLenght);
    });

    let maxLength = 0;
    countryCodesForList.forEach((item) => {
      const code = item.code.replace(/[^0-9]/g, '');

      if (code === number.substr(0, code.length) && maxLength < code.length)
        maxLength = code.length;
    });
    countryCodesForList = countryCodesForList.filter(c => {
      return c.code.replace(/ /g, '').replace(/\+/, '').length >= maxLength;
    });
    return countryCodesForList;
  }

  function setNumber(number) {
    //From this moment user deleted number from mask in input
    //We restore 'checked_mask' and phone number displayed without '+' and spaces
    let checkedMask = getPattern();
    if (!(product && product.mask) && checkedMask && !(isRecipientNumber || isSenderNumber || isSupportNumber)) {
      if (number && number.length < (new SimpExp(checkedMask).gen(' ')).trim().length) {
        number = number.replace(/ /g, '').replace(/\+/, '');
        Session.set('checked_mask', null);
        checkedMask = null;
      } else if (!number) {
        Session.set('checked_mask', null);
        checkedMask = null;
      }
    }

    //User are inputting number for product, that haven't mask, we show him available country for to check pattern
    if (!(product && product.mask) && !checkedMask && !!number) {
      //Request to base
      const countryCodesForList = getNumberCountries(number);

      //Last code in list - we must check this automatically
      if (countryCodesForList.length === 1) {
        setAdditionalNumber(prefix, number);
        setPatternByCountryId(countryCodesForList[0]._id);
        return;
      }
    }

    setAdditionalNumber(prefix, number);

    partialCountryMaskCheck(number);
  }

  //use when user set input '12345' and check pattern from Dropdownlist '+1 23'
  //the result will '+1 23 45 ...' or else, such as checked pattern
  function formatNumberByPattern(number, code, pattern) {
    const numberWithoutCode = number
      .replace(/[^0-9]/g, '')
      .slice(code.replace(/[^0-9]/g, '').length);
    let realNumber = new SimpExp(pattern).gen(NO_CHAR);

    for (let i = 0; i < numberWithoutCode.length; i++) {
      realNumber = realNumber.replace(NO_CHAR_REG, numberWithoutCode[i])
    }

    return realNumber.split(NO_CHAR)[0];
  }

  async function goNext() {
    const number = Session.get('number');
    if(!number) return;

    let verificationResult = await IW.verifyNumberSync(number);
    if(!(verificationResult && verificationResult.is_valid && verificationResult.line_type === 'Mobile')){
      return Session.set('phone_is_not_valid', true);
    }

    const result = await IW.sendMobileAppLinkSms(number);
    if(result){
      Session.set('loading', false);
      Session.set('app_link_sent', true);
      setNumber('');
    }
  }

  function goBack() {
    removeAlerts();
    Session.set('number', '');
    return FlowRouter.go('GetApp')
  }

  function goExit() {
    removeAlerts();
    Session.set('number', '');
    return IW.gotoIndex()
  }

  function prepareCountryCodesToDropdownList(array) {
    if (!_.isArray(array) || getPattern()) {
      return [];
    }

    array.sort((a, b) => {
      return +a.dialing_prefix > +b.dialing_prefix ? 1 : -1;
    });

    return array.map(function (item) {
      return _.extend({}, {
        key: item._id,
        left_text: item.code,
        main_text: item.country
      });
    });
  }

  function removeAlerts() {
    Session.set("phone_is_not_valid", false);
  }

  function clearInput() {
    removeAlerts();
    setNumber('');
  }

  function getValue(name) {
    return Session.get(name);
  }

  function getAboveText() {
    if (isSenderNumber) return 'Enter your mobile phone number:';
    if (isRecipientNumber) return 'Enter mobile phone number of recipient:';
    if (isSupportNumber) return 'Enter your phone number for support call:';

    return 'Enter your phone number:';
  }

  function getUnderText() {
    if (Session.get("phone_is_not_valid")){
      return 'Phone number is not valid. Please check your number.';
    }
    return 'a link to the app store will be sent to your phone';
  }

  function setAdditionalNumber(p, number) {
    Session.set((p || '') + 'number', number);
  }

  function getAdditionalNumber(p) {
    return Session.get((p || '') + 'number');
  }

  const numpad_layout = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9],
    ['clear', 0, 'delete']
  ];

  const isNumberValid = !!(getAdditionalNumber(prefix) &&
  (new RegExp(getPattern() || DEFAULT_MASK).test(getAdditionalNumber(prefix))));

  // TODO: Replace it with product props
  if (isSenderNumber || isRecipientNumber || isSupportNumber){
    let iso2 = 'US';
    if (isRecipientNumber) iso2 = Session.get("recipient_country_iso");
    const country = Countries.findOne({iso2});
    if (country) setPatternByCountryId(country.country_id);
  }

  const currentNumber = getNumber();

  return {
    appGuid: params.appGuid,
    pages: params.pages,
    numpadLayout: numpad_layout,
    numberPattern: getPattern() || DEFAULT_MASK, //'\\+1 [2-9|0]\\d{2} \\d{3} \\d{4}',
    number: currentNumber,
    onPrintNumber: (v) => {
      removeAlerts();
      setNumber(v);
    },
    goNext: goNext,
    goBack: goBack,
    goExit: goExit,
    inputWidth: (new SimpExp(getPattern() || DEFAULT_MASK).gen('0').length) * 30 + 10,
    countryCodes: prepareCountryCodesToDropdownList(getNumberCountries(currentNumber) || []),
    setPatternByCountryId: setPatternByCountryId,
    canModifyPattern: !(product && product.mask),
    clearInput,
    focus: getValue('focus'),
    abovePhoneText: getAboveText(),
    underPhoneText: getUnderText(),
    isNumberOk: isNumberValid,
    isUnderPhoneTextError: !!Session.get("phone_is_not_valid"),
    isAppLinkWasSend: !!Session.get('app_link_sent'),
    isLoading: !!Session.get('loading'),
  };

}, SendApplicationSMS);

