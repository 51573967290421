import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import {Session} from 'core/session';
import PlusContainer from './PlusContainer';
import IW from 'imports/iw_api';
import getPageManager from 'imports/lib/page_manager';
import {capitalize} from 'imports/lib/helpers';

const FIELD = {name: 'digit', minLength: 1, maxLength: 1, description: 'Check digit'}

export default createContainer((params) => {
  const field = _.extend({}, FIELD);

  if (IW.appGetVal('digits_field_name')){
    field.description = capitalize(IW.appGetVal('digits_field_name'));
  }

  return {
    ...params,
    fields: [field],
  };

}, PlusContainer);
