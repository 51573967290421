import {actions, assert, register, state} from '../../lib/app_flow_testing'
import IW from 'imports/iw_api';
import Products from '../../collections/products';

register({
  app_cryptocoin_DgtMt: {
    dm_pin_code: {
      should_allow_pin_2222: function* () {
        assert.page('dm_pin_code')
        yield actions.type('2222');
        yield actions.waitLoading();
        if (state().page === 'pin_number_sing_in') throw ('Pin does not work')
      },
    },


    dm_wallet_select: {
      should_allow_i_have_wallet: function* () {
        assert.page('dm_wallet_select')
        actions.mousedown('table.buttons-table tr:nth-child(2) > td > a', 'No button "I have wallet"')
        yield actions.loading()
      },
    },

    scan_wallet_qr: {
      should_pass_with_fake_wallet_scan: function* () {
        assert.page('scan_wallet_qr')
        if (IW.appGetVal('camera_qr')) throw ('Wallet exists');
        IW.appSetVal('camera_qr', 'mpaeox7uVT8sRKzTHEEpXRNWR8GQGNz2Pn')
        yield actions.loading()
      },
    },

    enter_amount_crypto: {
      should_able_enter_20: function* () {
        assert.page('enter_amount_crypto');
        yield actions.sleep(100)
        yield actions.mousedown('.cleared-input__clear-button', 'No clear button');
        yield actions.mousedown($('#numpad td a').eq(1), 'No 2')
        yield actions.mousedown($('#numpad td a').eq(10), 'No 0')
        yield actions.go('common.simple.should_able_next');
      }
    },
  }
})

register({
  simple_cases: {
    app_cryptocoin_DgtMt: {
      launch_login_flow: function* () {
        IW.launchProduct(Products.findOne({app_guid: 'app_cryptocoin_DgtMt'})._id);
        yield actions.waitLoading();
        yield actions.go('common            | flow                  | should_continue_phone_2014222656');
        yield actions.go('common            | stored_sessions       | should_skip');
        yield actions.go('common            | pin_number_sing_in    | should_allow_pin_2222');
      },

      general_flow: function* () {
        yield actions.go('simple_cases          | app_cryptocoin_DgtMt | launch_login_flow');

        yield actions.go('app_cryptocoin_DgtMt | dm_pin_code           | should_allow_pin_2222');
        yield actions.go('app_cryptocoin_DgtMt | dm_wallet_select      | should_allow_i_have_wallet');
        yield actions.go('app_cryptocoin_DgtMt | scan_wallet_qr        | should_pass_with_fake_wallet_scan');
        yield actions.go('app_cryptocoin_DgtMt | enter_amount_crypto   | should_able_enter_20');
        assert.page('pay_device');
      },
    }
  }
})
