import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import PhoneNumberTwice from './PhoneNumberTwice';
import IW from 'imports/iw_api';
import Prods from 'imports/collections/products';
import SimpExp from 'imports/lib/external/simpexp';
import Countries from 'imports/collections/countries';
import {Session} from 'core/session';
import getPageManager from 'imports/lib/page_manager';
import {N} from 'imports/lib/helpers';

const DEFAULT_MASK = '\\d{12}';//'\\+1 [2-9|0]\\d{2} \\d{3} \\d{4}';
const NO_CHAR = 'ё';
const NO_CHAR_REG = /ё/

export default createContainer((params) => {

  const product = Prods.findOne(N(FlowRouter.getParam("productId")));
  const isExistProductMask = !!(product && product.mask);

  function getNumber() {
    var pattern = isExistProductMask ? product.mask : (Session.get('checked_mask') || DEFAULT_MASK);//'\\+1 [2-9|0]\\d{2} \\d{3} \\d{4}';

    return (
      IW.appGetVal('number') ||
      IW.beginningByPattern(new SimpExp(pattern).gen(NO_CHAR), NO_CHAR)
    )
  }

  function getInternationalNumber() {
    const pattern = getPattern();
    let internationalPattern = '';
    const number = getNumber().replace(/[^0-9]/g, '');
    if (/\[.*\+[^\]]*]|\\\+/g.test(pattern)) {
      internationalPattern = pattern;
    } else {
      const country = Countries.findOne({iso3: product.country_iso3});
      if (country) {
        internationalPattern = country.pattern
      } else {
        internationalPattern = DEFAULT_MASK
      }
    }

    const code = new SimpExp(internationalPattern).gen('0').replace(/[^0-9]/g, '')
    const mask = new SimpExp(pattern || internationalPattern).gen('0').replace(/[^0-9]/g, '')
    const masked = number + mask.slice(number.length)
    return code.slice(0, -masked.length) + masked
  }

  function getPattern() {

    if (isExistProductMask) {
      return product.mask;
    }

    return Session.get('checked_mask');
  }

  //Set pattern by checked country from DropdownList
  function setPattern(id) {
    const country = Countries.findOne({_id: id});

    if (country) {
      const {code, pattern} = country;
      Session.set('checked_mask', pattern);
      const number = formatNumberByPattern(getNumber(), code, pattern);
      IW.beginningByPattern(new SimpExp(pattern).gen(NO_CHAR), NO_CHAR);
      IW.appSetVal('number', number);
      IW.appSetVal('number_confirm', number);
    } else {
      Session.set('checked_mask', null);
    }
  }

  function getNumberCountries(number) {
    number = number.replace(/[^0-9]/g, '')
    if (!number) return [];

    let countryCodesForList = Countries.find().fetch().filter(function (item) {
      const code = item.code.replace(/[^0-9]/g, '');
      const minLenght = Math.min(number.length, code.length);
      return number.substr(0, minLenght) === code.substr(0, minLenght);
    });

    let maxLength = 0;
    countryCodesForList.forEach(item => {
      const code = item.code.replace(/[^0-9]/g, '');
      if (code === number.substr(0, code.length) && maxLength < code.length)
        maxLength = code.length;
    });
    countryCodesForList = countryCodesForList.filter(c => {
      return c.code.replace(/ /g, '').replace(/\+/, '').length >= maxLength;
    });
    return countryCodesForList;
  }

  function setNumber(number) {
    //From this moment user deleted number from mask in input
    //We restore 'checked_mask' and phone number displayed without '+' and spaces
    let checkedMask = getPattern();
    if (!isExistProductMask && checkedMask) {
      if (number && number.length < (new SimpExp(checkedMask).gen(' ')).trim().length) {
        number = number.replace(/ /g, '').replace(/\+/, '');
        Session.set('checked_mask', null);
        checkedMask = null
      } else if (!number) {
        Session.set('checked_mask', null);
        checkedMask = null
      }
    }

    //User are inputting number for product, that haven't mask, we show him available country for to check pattern
    if (!isExistProductMask && !checkedMask && !!number) {
      //Request to base
      const countryCodesForList = getNumberCountries(number);

      //Last code in list - we must check this automatically
      if (countryCodesForList.length === 1) {
        IW.appSetVal('number', number);
        IW.appSetVal('number_confirm', number);
        setPattern(countryCodesForList[0]._id);
        return;
      }
    }

    IW.appSetVal('number', number);
    IW.appSetVal('phone_number', getInternationalNumber())
  }

  function setConfirmNumber(number) {
    //From this moment user deleted number from mask in input
    //We restore 'checked_mask' and phone number displayed without '+' and spaces
    let checkedMask = getPattern();
    if (!isExistProductMask && checkedMask) {
      if (number && number.length < (new SimpExp(checkedMask).gen(' ')).trim().length) {
        number = number.replace(/ /g, '').replace(/\+/, '');
        Session.set('checked_mask', null);
        checkedMask = null
      } else if (!number) {
        Session.set('checked_mask', null);
        checkedMask = null
      }
    }

    //User are inputting number for product, that haven't mask, we show him available country for to check pattern
    if (!isExistProductMask && !checkedMask && !!number) {
      //Request to base
      const countryCodesForList = getNumberCountries(number);

      //Last code in list - we must check this automatically
      if (countryCodesForList.length === 1) {
        IW.appSetVal('number_confirm', number);
        setPattern(countryCodesForList[0]._id);
        return;
      }
    }

    IW.appSetVal('number_confirm', number);
    // IW.appSetVal('phone_number', getInternationalNumber())
  }

  //use when user set input '12345' and check pattern from Dropdownlist '+1 23'
  //the result will '+1 23 45 ...' or else, such as checked pattern
  function formatNumberByPattern(number, code, pattern) {
    const numberWithoutCode = number
      .replace(/[^0-9]/g, '')
      .slice(code.replace(/[^0-9]/g, '').length);
    let realNumber = new SimpExp(pattern).gen(NO_CHAR);

    for (let i = 0; i < numberWithoutCode.length; i++) {
      realNumber = realNumber.replace(NO_CHAR_REG, numberWithoutCode[i])
    }

    return realNumber.split(NO_CHAR)[0];
  }

  const numpad_layout = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9],
    ['clear', 0, 'delete']
  ];

  const isNumberValid = !!(IW.appGetVal('number') &&
  //$('#number').length &&
  //$('#number').get(0).checkValidity() &&
  (new RegExp(getPattern() || DEFAULT_MASK).test(IW.appGetVal('number'))));

  function valueValid(value){
    return value && new RegExp(getPattern() || DEFAULT_MASK).test(value)
  }

  function valid() {
    // return !!( isNumberValid && IW.appGetVal('number') && IW.appGetVal('number') === IW.appGetVal('number_confirm') );
    return valueValid(getValue(getValue("focus"))) && (
      getValue("focus") !== 'number_confirm' || IW.appGetVal('number') === IW.appGetVal('number_confirm'));
  }

  function focus(value){
    Session.set('focus', value)
    IW.appSetVal('focus', value)
  }

  var goNext = function () {
    if (!valueValid(IW.appGetVal('number'))) {
      return focus('number');
    }

    if (!valueValid(IW.appGetVal('number_confirm')) ||
      IW.appGetVal('number') !== IW.appGetVal('number_confirm') ||
      getValue("focus") === 'number') {
      return focus('number_confirm');
    }

    getPageManager().next();
  };

  var goBack = function () {
    removeAlerts();
    if (IW.appGetVal('focus') === 'number_confirm') {
      return focus('number');
    }

    // TODO: On support page back button should not clear it
    // IW.appSetVal('number', '');
    // IW.appSetVal('number_confirm', '');
    getPageManager().back();
  };

  function prepareCountryCodesToDropdownList(array) {
    if (!_.isArray(array) || getPattern()) {
      return [];
    }

    array.sort((a, b) => {
      return +a.dialing_prefix > +b.dialing_prefix ? 1 : -1;
    });

    return array.map(function (item) {
      return _.extend({}, {
        key: item._id,
        left_text: item.code,
        main_text: item.country
      });
    });
  }

  function removeAlerts() {
    Session.set("phone_is_not_valid", false);
  }

  function getValue(name) {
    const value = IW.appGetVal(name);
    return value;
  }

  function getConfirmNumber() {
    var pattern = isExistProductMask ? product.mask : (Session.get('checked_mask') || DEFAULT_MASK);//'\\+1 [2-9|0]\\d{2} \\d{3} \\d{4}';

    return (
      IW.appGetVal('number_confirm') ||
      IW.beginningByPattern(new SimpExp(pattern).gen(NO_CHAR), NO_CHAR)
    )
  }

  const currentNumber = getNumber();
  const currentConfirmNumber = isNumberValid ? getConfirmNumber() : IW.appSetVal('number_confirm', '');

  return {
    appGuid: params.appGuid,
    pages: params.pages,
    numpadLayout: numpad_layout,
    isNumberOk: isNumberValid,
    valid: valid(),
    numberPattern: getPattern() || DEFAULT_MASK, //'\\+1 [2-9|0]\\d{2} \\d{3} \\d{4}',
    number: currentNumber,
    numberConfirm: currentConfirmNumber,
    value: getValue(getValue('focus')),
    onPrintNumber: (v) => {
      removeAlerts();
      getValue('focus') === 'number' ? setNumber(v) : setConfirmNumber(v);
    },
    goNext: goNext,
    goBack: goBack,
    inputWidth: (new SimpExp(getPattern() || DEFAULT_MASK).gen('0').length) * 30 + 10,
    countryCodes: getValue('focus') === 'number' ? prepareCountryCodesToDropdownList(getNumberCountries(currentNumber) || []) : [],
    setPattern: setPattern,
    canModifyPattern: !isExistProductMask,
    wpValidation: Session.get("phone_is_not_valid"),
    clearInput: () => setNumber(''),
    clearConfirmInput: () => setConfirmNumber(''),
    focus: getValue('focus')
  };

}, PhoneNumberTwice);

