import {createContainer} from 'imports/lib/react_createContainer';
import PaymentHistory from './PaymentHistory'
import IW from 'imports/iw_api';
import {checkVerticalSliderTap} from 'imports/lib/helpers';

const lastPoint = {x: 0, y: 0, id: null};

export default createContainer((params) => {

  const items = params.items || [];

  const onPayAgain = (event, p) => {

    function next() {
      const customerData = IW.loginGetVal('customer_data_result') || {};

      const options = {
        app_guid: p.app_guid,
        icon: p.product_icon,
        title: p.product_title,
        product_id: p.product_id,
        session_guid: p.session_guid,
        customer_id: customerData.customer_id
      };

      return IW.launchAppAfterLogin(options, 'on_login_pay_again');
    }

    // Ignore mousedown for slider items
    if ((event.type === 'mousedown') && $('.iosVerticalSlider').data('iosSliderVertical')) {
      lastPoint.x = event.pageX;
      lastPoint.y = event.pageY;
      lastPoint.id = p.payment_id;
      return;
    }

    if (lastPoint.id !== p.payment_id ||
      Math.max(Math.abs(lastPoint.x - event.pageX), Math.abs(lastPoint.y - event.pageY)) > 20) return;

    return next();
  };

  return {
    items: items.complete(6, {}),
    onPayAgain
  }
}, PaymentHistory);
