import {createContainer} from 'imports/lib/react_createContainer';
import Payout from './Payout';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';
import {sprintf} from 'imports/lib/external/sprintf';
import {_ as i18n} from 'imports/lib/helpers';

export default createContainer(() => {
  const walletChoices = [
    {
      name: 'I don\'t have Wallet',
      type: 'new_wallet',
      disableIcon: true,
    },
    {
      name: 'I have Wallet',
      type: 'scan_wallet',
      disableIcon: true,
    },
  ];

  return {
    paymentModes: walletChoices,
    onBack: () => getPageManager().back(),
    onNext: (id, type) => {
      IW.appSetVal('wallet_choice', type);
      getPageManager().next();
    },
    selectLabelHeader: sprintf(i18n('You are going to purchase %s.'), IW.appGetVal('crypto_currency_title')),
    selectLabel_: 'Do you have a wallet?',
    disableTransferDescription: true,
  };

}, Payout);
