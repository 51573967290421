import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import IW from 'imports/iw_api';
import ShoppingCart from './ShoppingCart';
import getPageManager from 'imports/lib/page_manager';
import {getName, getUser, N, S, _ as i18n} from 'imports/lib/helpers';
import {sprintf} from 'imports/lib/external/sprintf';

const lastMousePosition = {}

export default createContainer((params) => {
  function getShoppingCartItems() {
    const cart = _.map(IW.appGetVal('shopping_cart') || {}, (item, key) => {
      return _.extend({}, item, {id: key});
    });
    //Session.set('shopping_cart_length', cart.length);
    return cart;
  }

  function getShoppingCartItemsWithLimits() {
    const cart = getShoppingCartItems();
    const limits = IW.appGetVal('shopping_cart_limits');
    const sums = {};
    _.each(cart, (item) => {
      const limit = limits[item.transaction_limit_id];
      if (!_.isObject(limit)) return;
      sums[limit.id] = N(sums[limit.id]);
      sums[limit.parent] = N(sums[limit.parent]);
      if (item.selected && item.enabled) {
        sums[limit.id] += item.original_amount;
        sums[limit.parent] += item.original_amount;
        sums[limit.id] = N(sums[limit.id]);
        sums[limit.parent] = N(sums[limit.parent]);
      }
    });
    return _.map(cart, (item) => {
      const limit = limits[item.transaction_limit_id];
      const parent = limits[limit.parent];
      if (!_.isObject(limit)) return item;
      if (N(sums[limit.id]) > N(limit.daily) || (_.isObject(parent) && N(sums[parent.id]) > N(parent.daily))) {
        return _.extend({}, item, {
          limit_reached: true,
          limit_type: 'daily'
        })
      }
      if (N(sums[limit.id]) > N(limit.monthly) || (_.isObject(parent) && N(sums[parent.id]) > N(parent.monthly))) {
        return _.extend({}, item, {
          limit_reached: true,
          limit_type: 'monthly'
        })
      }
      if (N(sums[limit.id]) > N(limit.six_month) || (_.isObject(parent) && N(sums[parent.id]) > N(parent.six_month))) {
        return _.extend({}, item, {
          limit_reached: true,
          limit_type: 'six_month'
        })
      }
      return item;
    })
  }

  function getRequestedAmount() {
    return _.reduce(IW.appGetVal('shopping_cart'), (sum, item, key) => {
      return N(sum + (item.selected && item.enabled ?
          item.requested_amount :
          0));
    }, 0);
  }

  function getMessageText(cart) {
    if (_.isEmpty(cart)) return i18n('Your shopping cart is empty');
    const problematicItem = _.find(currentCart, (i) => i.limit_reached);
    if (problematicItem) {
      let limitName = i18n('daily');
      if (problematicItem.limit_type === 'monthly') limitName = i18n('monthly');
      if (problematicItem.limit_type === 'six_month') limitName = i18n('6th month');
      return sprintf(i18n('You reached the %s limit!'), limitName);
    }
    return i18n('You have an active shopping cart');
  }

  function onTapItem(id, enabled, event) {
    console.log(event);

    if (event.type == 'mousedown') {
      lastMousePosition.y = event.pageY;
      return;
    }

    if (Math.abs(event.pageY - lastMousePosition.y) > 10) return;
    onChange(id, enabled, event);

    return event.preventDefault();
  }

  const onChange = function (id, selected) {
    //event.preventDefault();
    const shoppingCart = IW.appGetVal('shopping_cart');
    if (!shoppingCart[id].enabled) return;
    shoppingCart[id].selected = !selected;
    IW.appSetVal('shopping_cart', shoppingCart);
  };

  const currentCart = getShoppingCartItemsWithLimits();

  return {
    userName: getName(S(getUser().last_name) + ' ' + S(getUser().first_name)),
    shoppingCart: currentCart,
    requestedAmount: getRequestedAmount(),
    canGoNext: getRequestedAmount() > 0 && !_.find(currentCart, (i) => i.limit_reached),
    messageText: getMessageText(currentCart),
    goNext: () => getPageManager().next(),
    goProfile: () => getPageManager().cancel(),
    onTapItem,
    optionsSlider: {
      scrollbarBackground: '#ff972b',
      scrollbarWidth: '8px',
      scrollbarMargin: '0px',
      scrollbarOpacity: '1',
      scrollbarBorderRadius: '0px'
    },
  };

}, ShoppingCart);