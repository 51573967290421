import React from 'react';
import IW from "../../iw_api";

export default class extends React.Component {
  /** TODO remove it unused
   *
    * @returns {*}
   */
  renderATM() {

    return (
      <tr>
        <td>
          <div className="bannerContainer">
            <div style={{width: '50%', marginRight: 90}} id="banner"></div>
            <div className="atm-main-screen-button" onClick={() => IW.launchATM()}>
              <img className="icon" src={'/resources/icons/other/AtmRed.png'}/>
            </div>
          </div>
        </td>
      </tr>
    )
  }

  render() {
    const {atmEnabled} = this.props;

    if (atmEnabled) {
      return this.renderATM();
    }

    return (
      <tr>
        <td id="banner"></td>
      </tr>
    )
  }
}
