import React from 'react';

export default class SmallLoading extends React.Component {
  render() {
    return (
      <div className="circularGWrapper" style={_.extend({ marginLeft: "12px" }, this.props.style)}>
        <div className="circularG circularG_1"></div>
        <div className="circularG circularG_2"></div>
        <div className="circularG circularG_3"></div>
        <div className="circularG circularG_4"></div>
        <div className="circularG circularG_5"></div>
        <div className="circularG circularG_6"></div>
        <div className="circularG circularG_7"></div>
        <div className="circularG circularG_8"></div>
      </div>
    )
  }
}
