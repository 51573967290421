import React, {Component} from 'react';
import Image from 'imports/ui/shared/image/Image';

export default class CategoryIcon extends Component {

  renderProductIcons() {
    const {icon} = this.props;
    if (!(icon.apps && icon.apps.length)) return;

    const moreThen9 = icon.apps.length > 9;

    let apps = icon.apps.slice(0, moreThen9 ? 8 : 9);

    apps = apps.map((app, key) => {
      return (
        <div key={key} className="category-icon_small">
          {app.icon || app.iconDefault ? <Image src={app.icon} default={app.iconDefault}/> : null}
        </div>
      )
    });

    if (moreThen9) {
      apps.push(
        <div key={10} className="category-icon_small">
          <Image src="/resources/icons/categories/ic_dots.png"/>
        </div>
      )
    }

    return apps;
  }

  renderCategoryIcon(icon) {
    return (
      <div className="category-icon_single">
        <Image src={icon}/>
      </div>
    )
  }

  render() {
    const {icon, onTapIcon} = this.props;

    return (
      icon.type === 'category' ? (
        <a className={`app_icon ${icon.id}`} onMouseDown={() => onTapIcon(event, icon.id)}
           onClick={() => onTapIcon(event, icon.id)}>
          {icon.icon ? (
            <div>
              <img src={icon.icon}/>
            </div>
          ) : (
            <div className="category-icon_container_outer">
              <div className="category-icon_container_inner">
                {this.renderProductIcons()}
              </div>
            </div>
          )}
          {icon.hasOwnProperty('nm') ? <span _>{icon.nm}</span> : null}
        </a>) : null
    )
  }
}

CategoryIcon.propTypes = {
  icon: React.PropTypes.shape({
    id: React.PropTypes.number,
    nm: React.PropTypes.string,
    index: React.PropTypes.number,
    selection_priority: React.PropTypes.number
  }),
  onTapIcon: React.PropTypes.func
};
