import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import IW from 'imports/iw_api';
import {C, N} from 'imports/lib/helpers'
import CONFIG from 'imports/config'
import BillAmountContainer from './BillAmountContainer';

export default createContainer((params) => {
  const maxAmount = Math.min(N(IW.appGetVal('customer_limit_amount')), CONFIG.BILL_MAX_AMOUNT);

  function isRentValid() {
    if (!IW.appGetVal('biller_is_rent')) return true;
    return N(IW.appGetVal('bill_original_amount')) === Math.round(N(IW.appGetVal('bill_original_amount')));
  }

  function isValid(v) {
    return N(v) > 0 && N(v) <= maxAmount
  }

  function getWarningMessage() {
    if (IW.appGetVal('biller_is_rent') && !isRentValid()) return `
    You should enter round amount! Overpayments are not allowed and this machine cannot dispense change in cents.
      We are unable to proceed with this Rent payment transaction.`
  }

  return {
    field: 'bill_original_amount',
    isValid: isValid,
    valid: isValid(IW.appGetVal('bill_original_amount')) && N(IW.appGetVal('bill_original_amount')) > 0 && isRentValid(),
    warningMessage: getWarningMessage()
  };

}, BillAmountContainer);
