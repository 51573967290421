import React, {Component} from 'react';
import CONFIG from 'imports/config'
import {N} from 'imports/lib/helpers'
import _ from 'lodash'

const Disclaimer = ({locale}) => {
  // constructor(props) {
  //   super(props);
  //   let locale = Session.get('locale');
  //   debugger;
  // }

  function renderDisclaimer() {
    // const locale = Session.get('locale');

    if (/es_/.test(locale)) {
      return renderSpanish()
    }

    return renderDefault();
  }

  function getMaxPrize() {
    const prizes = CONFIG.SWEEPSTAKES_PRIZES || {20: 0};
    let result = 0;

    _.each(prizes, (v, k) => {
      if (N(result) <= N(k)) result = N(k);
    });

    return result;
  }

  function getWinningOdds() {
    // const locale = Session.get('locale');
    const prizes = CONFIG.SWEEPSTAKES_PRIZES;
    let odds = '';

    const countDecimals = function (value) {
      if (Math.floor(value) === value) return 0;
      return value.toString().split(".")[1].length || 0;
    };

    _.each(prizes, (v, k) => {
      const dec = countDecimals(v);
      let val = Math.floor(Math.pow(10, dec) / (v * Math.pow(10, dec)));

      if (/es_/.test(locale)) {
        odds += ` La probabilidad de ganar $${k} es 1 en ${val}.`
      } else {
        odds += ` The odds of winning $${k} is 1 in ${val}.`
      }

    });

    return odds;
  }

  function renderSpanish() {
    return (
      <div className="sweepstakes-text">
        <span _>
          <b>Reglas oficiales del sorteo de Maya Payment Machine: NO SE REQUIERE COMPRA PARA PARTICIPAR.</b> Las compras no aumentan la probabilidad de ganar.
          <br/>
          <b style={{color: 'var(--maya-pink-color)'}}>El Premio: Hasta ${getMaxPrize()} al contado o en premios por transacción.</b> No se permite substituir el dinero al contado u otro premio salvo a discreción del Patrocinador. El premio no es transferible. Cualquier y todos los gastos relacionados con el premio, incluyendo sin limitación cualquier y todos los impuestos federales, estatales, y/o locales serán la exclusiva responsabilidad del ganador. Cómo participar: Puede participar en el Sorteo a través de Maya Payment Machine cuando realice una transacción en una tienda cercana a usted. Formas alternativas de participar: Mande por correo una tarjeta 4” x 6” con la siguiente información: Su nombre completo; Su dirección completa; Dirección de email válida (si no tiene email, escriba “no email”); Número de teléfono válido; Locación donde irá para activar su entrada, y las palabras Sorteo Maya. Envíe la entrada por correo a: Maya Labs, Inc. 5160 Van Nuys Bl. #350, Sherman Oaks, CA 91403. Solo una tarjeta de entrada por persona será aceptada. Todas las entradas se convierten en propiedad exclusiva del Patrocinador y no se acusará recibo ni serán devueltas.
          <br/>
          <ul style={{listStyleType: 'decimal', paddingLeft: 50}}>
            <b>Términos y Condiciones:</b>
            <li>Al enviar una entrada, usted está de acuerdo y acepta total e incondicionalmente las Reglas Oficiales.</li>
            <li>Las entradas al sorteo están limitadas a los Estados Unidos y al Distrito de Colombia y es NULO DONDE LO PROHIBE LA LEY. Se aplican todas las leyes y regulaciones federales, estatales y locales. Sujeto a cualquier aprobación gubernamental que pueda ser requerida, el Patrocinador se reserva el derecho de, sin previo aviso y en cualquier momento, terminar el Sorteo, en su totalidad o en parte, o modificar el Sorteo en cualquier forma, si cualquier factor interfiere con su correcto desarrollo como se contempla por estas Reglas Oficiales. El Sorteo comenzará el 6 de julio de 2018 y continuará por cada transacción. El Patrocinador se reserva el derecho a su exclusivo criterio, a cancelar el sorteo en su totalidad si este se corrompe técnicamente o por alguna intervención humana no autorizada.</li>
            <li>Elegibilidad. Debe tener por lo menos dieciocho (18) años para participar. Empleados, oficiales, o directores del Patrocinador, sus compañías matrices, afiliadas, subsidiarias, y sus hijos, padres, esposos y miembros de sus familias no son elegibles para participar. No se requiere compra o entrada en línea. Si usted no realiza una transacción en una Maya Payment Machine, puede entrar en el sorteo como se indica en Formas alternativas de participar. El Sorteo está abierto solo a residentes legales de los Estados Unidos. Una compra no mejora su probabilidad de ganar.</li>
            <li>Fecha efectiva de las entradas. Las entradas enviadas por correo serán efectivas en la fecha que el Patrocinador las reciba y las procese. Todas las entradas se convierten en la propiedad exclusiva de Maya Labs, Inc. y no se acusará recibo ni serán devueltas. Usted es responsable por los costos de participar, incluyendo los gastos de envío.</li>
            <li>Selección de ganadores. Los ganadores se determinarán por un sorteo aleatorio de todas las entradas elegibles recibidas. Los sorteos se llevarán a cabo por transacción por usuario. Los ganadores deben acceder al uso de sus nombres, voces, y/o semejanzas para el propósito de publicidad, comercio o promoción sin más compensación, a menos que lo prohíba la ley.</li>
            <li>Notificación a los ganadores. Los ganadores del Sorteo serán notificados instantáneamente en la Maya Payment Machine. Todas las decisiones son definitivas y obligatorias. Los ganadores deben recoger su premio al final de su transacción. El Patrocinador no es responsable por intentos tardíos, perdidos, mal dirigidos o fallidos para reclamar un premio por los ganadores.</li>
            <li>Probabilidades de ganar. Las probabilidades de ganar cualquier sorteo serán determinadas en la siguiente manera.{getWinningOdds()}</li>
            <li>En caso de una disputa con relación a quien envió una entrada por correo, la entrada será considerada que fue enviada por el titular de la dirección autorizada y el número de teléfono en la tarjeta postal.</li>
            <li>Otras condiciones. El Patrocinador, sus agentes y representantes, sus casa matrices, afiliadas, subsidiarias, agencias de publicidad, promociones y ejecución y asesores legales no son responsables por (I) entradas perdidas, dañadas, mal dirigidas, incompletas, ilegibles o con sello postal insuficiente; (II), fallos telefónicos, electrónicos, de hardware o software, de red, Internet o mal funcionamiento de la computadora, fallas o dificultades de cualquier clase; (III) transmisiones de computadora fallidas, incompletas, codificadas o atrasadas; (IV) cualquier condición causada por eventos fuera del control del Patrocinador que pueda causar que el Sorteo sea interrumpido o corrompido; (V) cualquier perjuicio, pérdidas o daños de cualquier clase que surjan en conexión con o como resultado del Sorteo, o de la participación en el Sorteo; o (VI) cualquier error de imprenta o tipográfico en cualquier material asociado con el Sorteo.</li>
            <li>Indemnización. Usted está de acuerdo en liberar y dejar libre de culpa al Patrocinador, sus empleados, oficiales, directores, accionistas, agentes, representantes, casas matrices, afiliadas, subsidiarias, agencias de publicidad, promociones y ejecución y asesores legales de cualquiera y todas las pérdidas, daños, derechos, reclamos y acciones de cualquier clase en conexión con el Sorteo, incluyendo sin limitación, agravios personales, muerte y daño a la propiedad, y reclamos basados en derechos de publicidad, difamación o invasión de la privacidad. Usted entiende que usted provee su información a Maya Labs, Inc. como el patrocinador.</li>
            <li>Selección de derecho aplicable. Todos los asuntos y preguntas concernientes a la creación, validez, interpretación y aplicación de la ley de estas Reglas Oficiales, o de los derechos y obligaciones de usted y el Patrocinador en relación con el Sorteo serán regidas por, e interpretadas conforme a las leyes sustanciales de Delaware.</li>
          </ul>
        </span>
      </div>
    )
  }

  function renderDefault() {
    return (
      <div className="sweepstakes-text">
        <span _>
          <b>Maya Payment Machine Sweepstakes Official Rules:  NO PURCHASE NECESSARY TO ENTER.</b> Purchase does not increase chance of winning.
          <br/>
          <b style={{color: 'var(--maya-pink-color)'}}>The prize: Up to ${getMaxPrize()} in cash or prizes per transaction.</b> No cash or other prize substitution permitted except at Sponsor's discretion. The prize is non-transferable. Any and all prize related expenses, including without limitation any and all federal, state, and/or local taxes shall be the sole responsibility of the winner. <b>How to enter:</b> You may enter the Sweepstakes through Maya Payment Machine when doing a transaction at a retail location near you. <b>Alternate means of entry:</b> Mail a 4" x 6" card with the following information: Your full name; Your complete address; Valid email address (if you do not have an email address, print "no email address"); Valid telephone number; Location you will go to activate your entry, and, The words Maya Sweepstakes. Mail entry to:  Maya Labs, Inc. 5160 Van Nuys Bl. #350, Sherman Oaks, CA 91403. Only one entry card will be accepted per person. All entries become the exclusive property of Sponsor and will not be acknowledged or returned.
          <br/>
          <b>Terms and Conditions:</b>
          <ul style={{listStyleType: 'decimal', paddingLeft: 50}}>
            <b>Terms and Conditions:</b>
            <li>By submitting an entry, you fully and unconditionally agree to and accept these Official Rules.</li>
            <li>Sweepstakes entries are limited to the United States and District of Columbia and is <b>VOID WHERE PROHIBITED. All federal, state and local laws and regulations apply.</b> Subject to any governmental approval that may be required, Sponsor reserves the right to, without prior notice and at any time, terminate the Sweepstakes, in whole or in part, or modify Sweepstakes in any way, should any factor interfere with its proper conduct as contemplated by these Official Rules.Sweepstakes will begin on July 6, 2018 and will continue on per transaction basis. Sponsor reserves the right in its sole discretion, to cancel the sweepstakes in its entirety if it becomes technically corrupted or because of non-authorized human intervention.</li>
            <li><b>Eligibility.</b> Must be at least eighteen (18) years old to enter. Employees, officers, or directors of Sponsor, its parent companies, affiliates, subsidiaries, and their children, parents, spouse, and members of their household are ineligible to participate. No purchase or online entry necessary. If you do not perform a transaction at a Maya Payment Machine, you can enter sweepstakes as stated in <b>Alternate means of entry</b>. Sweepstakes is <b>open only to legal residents of the United States</b>. A purchase does not enhance your chance of winning.</li>
            <li><b>Effective date of entries</b>. Entries submitted by mail will be effective on the date Sponsor receives and processes the entry. All entries become the exclusive property of Maya Labs, Inc. and will not be acknowledged or returned.  You are responsible for your costs of entering, including postage.</li>
            <li><b>Selection of winners</b>. Winners will be determined by random drawing from all eligible entries received. Drawings will be held on a per transaction per user basis. Winners must agree to the use of their names, voices, and/or likeness for the purpose of advertising, trade, or promotion without further compensation, unless prohibited by law.</li>
            <li><b>Notification of winners</b>. Sweepstakes winner will be notified instantly at the Maya Payment Machine. All decisions are final and binding. Winners must collect their prize at the end of their transaction. Sponsor is not responsible for and shall not be liable for late, lost, misdirected, or unsuccessful efforts to claim prize by the winners.</li>
            <li><b>Odds of winning</b>.{getWinningOdds()}</li>
            <li><b>In the event of a dispute</b> regarding who submitted mail-in entry, the entry will be deemed submitted by the authorized address and telephone number holder on the mail-in postcard.</li>
            <li><b>Other conditions</b>. Sponsor, its agents and representatives, its parent companies, affiliates, subsidiaries, advertising, promotion and fulfillment agencies and legal advisors are not responsible for and will not be liable for (I) late, lost, damaged, misdirected, incomplete, unintelligible or postage due entries; (II) telephone, electronic, hardware or software program, network, Internet or computer malfunctions, failures or difficulties of any kind; (III) failed, incomplete, garbled or delayed computer transmissions; (IV) any condition caused by events beyond the control of Sponsor that may cause Sweepstakes to be disrupted or corrupted; (V) any injuries losses or damages of any kind arising in connection with or as a result of the Sweepstakes, or from participation in the Sweepstakes; or (VI) any printing or typographical error in any material associated with the Sweepstakes.</li>
            <li><b>Indemnification</b>. You agree to release and hold Sponsor, its employees, officers, directors, shareholders, agents, representatives, parent companies, affiliates, subsidiaries, advertising, promotion and fulfillment agencies, and legal advisors, harmless from any and all losses, damages, rights, claims and actions of any kind in connection with the Sweepstakes, including without limitation, personal injury, death and property damage, and claims based on publicity rights, defamation or invasion of privacy. You understand that you are providing your information to Maya Labs, Inc. as the sponsor.</li>
            <li><b>Choice of law</b>. All issues and questions concerning the construction, validity, interpretation and enforceability of these Official Rules, or the rights and obligations of you and Sponsor in connection with the Sweepstakes, will be governed by, and construed in accordance with, the substantive laws of the Delaware.</li>
          </ul>
        </span>
      </div>
    )
  }

  return renderDisclaimer()
}

export default Disclaimer
