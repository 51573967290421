// Internationalization

// TODO: Investigate how to use Meteor.Collection for translations without time consuming
//export default new Meteor.Collection(null);

const cache = {}

export default {
  upsert(id, obj){
    cache[getHash(obj)] = obj
  },

  findOne(obj){
    return cache[getHash(obj)];
  },

  find(){
    return {
      count: () => Object.keys(cache).length,
      fetch: () => Object.keys(cache).map((k) => cache[k])
    }
  }
}

function getHash(obj){
  return obj.lang + obj.original + obj.type
}
