/**
 * Created by vitaliy on 10.03.17.
 */
import {createContainer} from 'imports/lib/react_createContainer';
import TransferDescription from './TransferDescriptionContainer';
import IW from 'imports/iw_api';
import Countries from 'imports/collections/countries';

export default createContainer((params) => {
  let via = '';
  if (IW.appGetVal('transfer_type') === 'deposit') via = 'Bank Deposit';
  if (IW.appGetVal('transfer_type') === 'pickup') via = 'Pick up';

  return {
    sendingFromNumber : IW.appGetVal('sender_number'),
    sendingFromCountry : IW.appGetVal('sender_address_country'),
    sendingToNumber : IW.appGetVal('recipient_number'),
    sendingToEmail: IW.appGetVal('recipient_email'),
    sendingToCountry : IW.appGetVal('recipient_address_country'),
    sendingToWallet: IW.appGetVal('wallet_address'),
    amount: IW.appGetVal('original_amount'),
    currency: '$', // TODO: Get from Countries
    cryptoCurrency: IW.appGetVal('crypto_currency_title'),
    transferType: IW.appGetVal('transfer_type'),
    via, // deposit/pickup
  };

}, TransferDescription);