/**
 * Created by vitaliy on 22.03.17.
 */
import React, {Component} from 'react';
import Numpad from 'imports/ui/shared/numpad/Numpad';
import IW from 'imports/iw_api';
import {Session} from 'core/session';
import Button from 'imports/ui/shared/button/Button';
import ClearedInput from 'imports/ui/shared/cleared_input/ClearedInput';
import DropdownIOSliderList from 'imports/ui/shared/dropdown/DropdownIOSliderListContainer';
import InputStateDescribe from 'imports/ui/shared/input/InputStateDescribe';
import TransferDescription from 'imports/ui/shared/transfer_description/TransferDescriptionSessionContainer';
import InputLabel from 'imports/ui/shared/input/InputLabel';
import InputLabelCustomer from 'imports/ui/shared/input/InputLabelCustomer';
import Loading from 'imports/ui/shared/loading/Loading';
import safeRender from 'imports/ui/decorators/safeRender';

class SendApplicationSMS extends Component {

  componentDidMount() {
    Session.set('number', Session.get('number') ? Session.get('number') : "");

    Session.set('focus', 'number');

    const field_to_focus = Session.get('focus');
    Session.set('focus', '');
    Session.set('focus', field_to_focus);
    Session.set('app_link_sent', false);
  }

  render() {
    const {
      isNumberOk, number, numberPattern, numpadLayout, onPrintNumber, goNext, goBack, goExit, canModifyPattern,
      clearInput, abovePhoneText, isUnderPhoneTextError, underPhoneText, isAppLinkWasSend, isLoading
    } = this.props;

    if(isLoading) return <Loading/>;

    if(isAppLinkWasSend) return (
      <table className={"phone-number app"}>
        <tbody>
        <tr>
          <div className="flex-box_middle">
            <img src="/resources/images/atm/ic_check.png"/>
            <span className="status-message_large" _>{'SMS with link was sent'}</span>
          </div>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2">
            <table>
              <tr>
                <td style={{padding: 0}}>
                  <Button id="back" className="left button" text_="Exit" onMouseDown={goExit}/></td>
              </tr>
            </table>
          </td>
        </tr>
        </tfoot>
      </table>
    );

    return (
      <table className={"phone-number app"}>
        <tbody>
        <tr>
          <td className="phone-number__input-container">
            <InputLabel text={abovePhoneText}/>
            <br /><br />
            <ClearedInput id="number" pattern={numberPattern} className="focus" value={number} readOnly={true}
                          clearInput={clearInput} focus="number"/>

            <br />
            <InputStateDescribe
              className="phone-number__under-input"
              isError={isUnderPhoneTextError}
              text={underPhoneText}/>

          </td>
          <td className="phone-number__numpad-container">
            <Numpad keys={numpadLayout} pattern={numberPattern} value={number} onValueChange={onPrintNumber}
                    canModifyPattern={canModifyPattern} style={{margin: 0}}/>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2">
            <table>
              <tr>
                <td style={{padding: 0}}>
                  <Button id="back" className="left button" text_="Back" onMouseDown={goBack}/></td>
                <td style={{padding: 0}}>
                  {isNumberOk ?
                    <Button id="next" className="right button" onMouseDown={async() => {
                      Session.set('loading', true);
                      await goNext();
                      Session.set('loading', false);
                    }} text_="Send"/> :
                    null }
                </td>
              </tr>
            </table>
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

export default safeRender(SendApplicationSMS);
