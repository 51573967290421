import React, {Component} from 'react';
import Image from 'imports/ui/shared/image/Image';

export default class CustomerItem extends Component {
  render() {
    const {icon, subicon, defaultPayerIcon, subname, name, productIcon, onClick, } = this.props;

    if (!name) return null;

    return (
      <div className="customer-item">
        <a onMouseDown={onClick} onClick={onClick}>
          <div className="customer-item__img">
            <div className="customer-item__img-recipient">
              <img src={icon} alt=""/>
            </div>
            <div className="customer-item__img-payout">
              <Image src={subicon} default={defaultPayerIcon}/>
            </div>
            <div className="customer-item__img-flag">
              <img src={productIcon} alt=""/>
            </div>
          </div>
          <div className="customer-item__description-txt-container">
            <span>
              <b>{name}</b>{subname ? ', ' + subname : ''}
            </span>
          </div>
        </a>
      </div>);
  }
}

CustomerItem.propTypes = {
  icon: React.PropTypes.string,
  defaultPayerIcon: React.PropTypes.string,
  subicon: React.PropTypes.string,
  productIcon: React.PropTypes.string,
  subname: React.PropTypes.string,
  name: React.PropTypes.string,
  onClick: React.PropTypes.func
};
