import {Session} from 'core/session'
import {createContainer} from 'imports/lib/react_createContainer';
import AppHeader from './AppHeader';
import IW from 'imports/iw_api';
import {FlowRouter} from 'core/Router';
import Prods from 'imports/collections/products';
import {_} from 'imports/lib/helpers'

export default createContainer((props) => {
  const product = Prods.findOne({app_guid: FlowRouter.getParam('appName')});

  function style() {
    let styleString = {};
    if (Session.get('icon'))
      styleString.backgroundImage = 'url(' + Session.get('icon') + ')';
    return styleString;
  }

  function appStep() {
    var html = '';
    var step = IW.appGetStep(IW.appGetVal('page') || 'index');

    if (step)
      for (var i = 1; i <= IW.appGetStep(false, true); i++)
        html += (step == i) ? '<b>' + i + '</b>' : '<a>' + i + '</a>';

    return html;
  }

  return {
    classLabel: FlowRouter.getParam("appName"),
    style: style(),
    title: _(Session.get('title'), Session.get('app_guid'), 'product'),
    appStep: appStep(),
    menuEnabled: !Session.get('menu_disabled'),
    onMenu: () => IW.mainMenu()
  }
}, AppHeader)
