import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import Inputs from './Inputs';
import IW from 'imports/iw_api';
import Prods from 'imports/collections/products';
import SimpExp from 'imports/lib/external/simpexp';
import getPageManager from 'imports/lib/page_manager';
import {Session} from 'core/session';
import {capitalize, N} from 'imports/lib/helpers';
import {KEYBOARD_NUMBER_3ROW, KEYBOARD_BILLER_ACCOUNT} from 'imports/lib/constants';

const MAX_LENGTH = 25;
const MIN_LENGTH = 5;

export default createContainer((params) => {

  const product = Prods.findOne(N(FlowRouter.getParam('productId')));
  //if (product) product.account_masks = ['\\d{2}[^A-Z0-9]?\\d{3}', '\\d{4}[^A-Z0-9]?\\d{3}']

  const inputValidation = {
    'maxLength': {
      'account1': 22,
      'account2': 22
    },
    'keyboardPattern': {
      'account1': '',
      'account2': ''
    },
    'flexible': {
      'account1': false,
      'account2': false
    }
  };

  const focusInput = Session.get('focus');

  function accnumInstructions() {
    if (product)
      return product.account_nr_instructions;
    return ''
  }

  function account1() {
    return IW.appGetVal('account1').replace(/--/g, '-').replace(/[^a-zA-Z0-9-]/g, '');
  }

  function account2() {
    return IW.appGetVal('account2').replace(/--/g, '-').replace(/[^a-zA-Z0-9-]/g, '')
  }

  function placeholder() {
    return '';
  }

  function valid() {
    return !!( IW.appGetVal('account1') &&
    IW.appGetVal('account1').length >= MIN_LENGTH && IW.appGetVal('account1').length <= MAX_LENGTH &&
    IW.appGetVal('account1') === IW.appGetVal('account2')) &&
    IW.appGetVal('account_is_validated') && !IW.appGetVal('account_is_invalid');
  }

  function showAlert() {
    if (IW.appGetVal('account_is_invalid')){
      return 'The Account Numbers is invalid';
    }

    if (!!(IW.appGetVal('account1') && IW.appGetVal('account2') &&
      IW.appGetVal('account1').length === IW.appGetVal('account2').length &&
      IW.appGetVal('account1') !== IW.appGetVal('account2'))) {
      return 'The Account Numbers do not match';
    }

    return '';
  }

  function onClickNext(e) {
    e.preventDefault();

    if (focusInput === 'account1') {
      IW.call('checkAccountNumber', null, (_, res) => {
        IW.appSetLocalVal('account_is_validated', true);
        IW.appSetLocalVal('account_is_invalid', !res.check_account_success);
        IW.appSetLocalVal('account_formatted', res.check_account_result);
      });
      Session.set('focus', 'account2');
      return;
    }

    // Validation
    if (!valid()) return;
    IW.appSetVal('account', IW.appGetVal('account_formatted'));
    // IW.appSetVal('account1', IW.appGetVal('account_formatted'));
    // IW.appSetVal('account2', IW.appGetVal('account_formatted'));
    getPageManager().next();
  }

  function onClickBack(e) {
    e.preventDefault();

    if (focusInput === 'account2') {
      Session.set('focus', 'account1');
      return;
    }

    getPageManager().back();
  }

  function onChangeValue(v) {
    if (v.length > 25) return;
    v = v.replace(/--/g, '-').replace(/^-/g, '');
    if (focusInput === 'account1') {
      IW.appSetLocalVal('account_is_validated', false);
      IW.appSetLocalVal('account_is_invalid', false);
    }
    IW.appSetVal(focusInput, v);
  }

  function clearInput() {
    if (focusInput === 'account1') {
      IW.appSetLocalVal('account_is_validated', false);
      IW.appSetLocalVal('account_is_invalid', false);
    }
    IW.appSetVal(focusInput, '');
  }

  function getValue(name) {
    return IW.appGetVal(name);
  }

  function getValidationData(type, name) {
    return inputValidation[type][name];
  }

  function onFocusChange(){
    if (focusInput === 'account2' && account1().length > 0) {
      //IW.appSetLocalVal('account_formatted', IW.appGetVal('account1'));

      IW.call('checkAccountNumber', null, (_, res) => {
        IW.appSetLocalVal('account_is_validated', true);
        IW.appSetLocalVal('account_is_invalid', !res.check_account_success);
        IW.appSetLocalVal('account_formatted', res.check_account_result);
      });
    }
  }

  return {
    accnumInstructions: accnumInstructions(),
    accnumPattern: '',
    pricePattern: '',
    accountFieldName: IW.appGetVal('account_field_name') ?
      capitalize(IW.appGetVal('account_field_name')) :
      'Account #',
    account2FieldName: 'Re-enter account #',
    account1: account1(),
    account2: account2(),
    placeholder: placeholder(),
    keyboardLayout: KEYBOARD_BILLER_ACCOUNT,
    numPadLayout: KEYBOARD_NUMBER_3ROW,
    showNextButton: valid() || focusInput === 'account1',
    showAlert: showAlert(),
    value: getValue(focusInput),
    maxLength: getValidationData('maxLength', focusInput),
    keyboardPattern: getValidationData('keyboardPattern', focusInput),
    isFlexible: getValidationData('flexible', focusInput),
    isInputLong: true,
    onClickNext: onClickNext,
    onClickBack: onClickBack,
    onChangeValue: onChangeValue,
    clearInput,
    focus: focusInput,
    onFocusChange: onFocusChange,
  }

}, Inputs);
