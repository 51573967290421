import pages from 'imports/ui/apps/pages';
import createSessionMethods from 'imports/lib/createSessionMethods';
import IW from 'imports/iw_api';
import __ from 'lodash';
import {ATM_ACCOUNTS} from 'imports/lib/constants';

const applicationWorkflow = _.chain(pages)
  .omit('index',
    'error',
    'tabbar',
    'amount_clerk',
    'pay_virtual',
    'confirm',
    'maya5_congratulation',
    'list',
    'info2',
    'number_login',
    'number_sign_in_clerk',
    'amount', 'amount_again', 'bill_amount_again',
    'biller_data',
    'bill_required_data_phone',
    'phone_account_choice',
    'validate_account',
    'email', 'send_disclosure_email',
    'gifts',
    'insert_card_simple',
    'confirm_with_pin_number',
    'emv_select_language',
    'emv_select_transaction_type',
    'emv_select_amount',
    'emv_enter_amount',
    'emv_select_account',
    'dm_customer_number',
    'emv_show_message',

    // Todo:
    'extract_select_amount',
    'transfer_select_amount',
    'enter_extract_amount',
    'enter_transfer_amount',
  )
  .keys()
  .filter((page) => pages.loading !== pages[page])
  .reduce((workflow, page, i, pages) => {
    const pageConfig = {};
    if (pages[i - 1]) pageConfig.back = pages[i - 1];
    if (pages[i + 1]) pageConfig.next = pages[i + 1];
    workflow[page] = (...args) => {
      if (!_.isEmpty(args)) {
        window.updateTranslationState = undefined;
        Session.set('translation_pages_list', _.keys(applicationWorkflow));
        Session.set('translation_state_length', 0);
      }
      ;
      return pageConfig;
    };
    return workflow;
  }, {}).value();

applicationWorkflow.index = () => _.first(_.keys(applicationWorkflow));

applicationWorkflow.info = createStateSwitch(applicationWorkflow.info(),
  {app: {payment_options: ''}, session: {app_guid: ''}},
  {
    app: {
      payment_options: [
        {
          id: 'id',
          priority: 'priority',
          fee: 'fee',
          biller_description: 'biller_description',
          biller_type: 'biller_type',
        }, {
          id: 'id',
          priority: 'priority',
          fee: 'fee',
          biller_description: 'biller_description',
          biller_type: 'biller_type',
        }, {
          id: 'id',
          priority: 'priority',
          fee: 'fee',
          biller_description: 'biller_description',
          biller_type: 'biller_type',
        },
      ],
    },
  },
  {
    app: {
      payment_options: [
        {
          id: 'id',
          priority: 'priority',
          fee: 'fee',
          biller_description: 'biller_description',
          biller_type: 'biller_type',
        }, {
          id: 'id',
          priority: 'priority',
          fee: 'fee',
          biller_description: 'biller_description',
          biller_type: 'biller_type',
        }, {
          id: 'id',
          priority: 'priority',
          fee: 'fee',
          biller_description: 'biller_description',
          biller_type: 'biller_type',
        },
      ],
    },
    session: {app_guid: 'app_billpay_cHckF'},
  },
);


applicationWorkflow.number = createStateSwitch(applicationWorkflow.number(),
  {session: {phone_is_not_valid: ''}, app: {service_name: ''}}, {},
  {
    session: {
      phone_is_not_valid: true,
    },
  },
  {
    app: {
      service_name: 'sigue',
    },
  },
);

applicationWorkflow.number_sign_in = createStateSwitch(applicationWorkflow.number_sign_in(),
  {
    session: {user_not_found: '', customer_exists: ''},
    login: {login_disabled: '', phone_is_not_valid: ''},
  }, {},
  {session: {user_not_found: true}},
  {
    session: {customer_exists: true},
    login: {login_disabled: false, phone_is_not_valid: true},
  },
);

applicationWorkflow.number_twice = createStateSwitch(applicationWorkflow.number_twice(),
  {session: {phone_is_not_valid: ''}}, {},
  {session: {phone_is_not_valid: true}},
);

applicationWorkflow.bill_scanning = createStateSwitch(applicationWorkflow.bill_scanning(),
  {
    app: {
      current_favorites: '',
      show_favorites: '',
      biller_predefined: '',
    },
    session: {
      accounts: '',
      app_guid: '',
    },
  }, {},
  {
    app: {
      biller_predefined: false,
    },
    session: {
      app_guid: 'app_billpay_cHckF',
    },
  },
  {
    app: {
      current_favorites: [
        {
          'favorite_id': 16,
          'location_id': 259,
          'name': 'Amerigas',
          'score': 14,
        },
        {
          'favorite_id': 3,
          'location_id': 259,
          'name': 'CenturyLink',
          'score': 6,
        },
        {
          'favorite_id': 8,
          'location_id': 259,
          'name': 'MasterCard',
          'score': 6,
        },
        {
          'favorite_id': 11,
          'location_id': 259,
          'name': 'Southern California Edison SCE',
          'score': 4,
        },
        {
          'favorite_id': 5,
          'location_id': 259,
          'name': 'Entergy',
          'score': 4,
        },
        {
          'favorite_id': 13,
          'location_id': 259,
          'name': 'ADT Security Services, Inc.',
          'score': 3,
        },
      ],
      show_favorites: true,
    },
  },
  {
    session: {
      accounts: [
        {
          account_id: 'p.payment_id;',
          account: 'accountNumber;',
          account1: 'accountNumber;',
          account2: 'data.account2;',
          required_fields: 'null;',
          payment_options: 'null;',
          fee_id: '1',
          name: 'p.cfp_name || data.name || p.name;',
          icon: 'data.icon || p.icon;',
          biller_name: 'account.name;',
          biller_icon: 'account.icon;',
        },
        {
          account_id: 'p.payment_id;',
          account: 'accountNumber;',
          account1: 'accountNumber;',
          account2: 'data.account2;',
          required_fields: 'null;',
          payment_options: 'null;',
          fee_id: '2',
          name: 'p.cfp_name || data.name || p.name;',
          icon: 'data.icon || p.icon;',
          biller_name: 'account.name;',
          biller_icon: 'account.icon;',
        },
        {
          account_id: 'p.payment_id;',
          account: 'accountNumber;',
          account1: 'accountNumber;',
          account2: 'data.account2;',
          required_fields: 'null;',
          payment_options: 'null;',
          fee_id: '3',
          name: 'p.cfp_name || data.name || p.name;',
          icon: 'data.icon || p.icon;',
          biller_name: 'account.name;',
          biller_icon: 'account.icon;',
        },
        {
          account_id: 'p.payment_id;',
          account: 'accountNumber;',
          account1: 'accountNumber;',
          account2: 'data.account2;',
          required_fields: 'null;',
          payment_options: 'null;',
          fee_id: '4',
          name: 'p.cfp_name || data.name || p.name;',
          icon: 'data.icon || p.icon;',
          biller_name: 'account.name;',
          biller_icon: 'account.icon;',
        },
      ],
    },
  },
);

applicationWorkflow.bill_scanning_confirm = createStateSwitch(applicationWorkflow.bill_scanning_confirm(),
  {app: {biller_name: ''}}, {app: {biller_name: 'John Smith'}},
);

applicationWorkflow.bill_required_data = createStateSwitch(applicationWorkflow.bill_required_data(),
  {app: {required_fields: ''}, session: {current_active_field: 0}}, {
    app: {
      required_fields: [
        {
          'name': 'CELL',
          'description': 'CELL NO. ON ACCOUNT',
          'format': {
            'length': 10,
            'charSet': 'N',
          },
        },
        {
          'name': 'PHONE',
          'description': 'PHONE NO. ON ACCOUNT',
          'format': {
            'length': 10,
            'charSet': 'N',
          },
        },
        {
          'name': 'SSN4',
          'description': 'LAST 4 OF SSN',
          'format': {
            'length': 4,
            'charSet': 'N',
          },
        },
        {
          'description': 'PIN Code',
          'format': {
            'length': 4,
            'charSet': 'N',
          },
          'name': 'PIN',
        },
        {
          'name': 'ZIP',
          'format': {
            'length': 5,
            'charSet': 'N',
          },
          'description': 'CUSTOMER ZIP CODE',
        },
        {
          'name': 'NAME',
          'description': 'CUSTOMER NAME',
          'format': {
            'length': 30,
            'charSet': 'A',
          },
        },
        {
          'name': 'NAME1',
          'description': 'CUSTOMER NAME',
          'format': {
            'length': 30,
            'charSet': 'A',
          },
        },
        {
          'name': 'NAME2',
          'description': 'CUSTOMER NAME',
          'format': {
            'length': 30,
            'charSet': 'A',
          },
        },
      ],

    },
  },
);

applicationWorkflow.biller_search = createStateSwitch(applicationWorkflow.biller_search(),
  {
    app: {
      initial_search: '',
      in_searching: '',
      started_searching: '',
    },
  }, {},
  {
    app: {
      started_searching: true,
      initial_search: true,
      in_searching: false,
    },
  },
);

// TODO: applicationWorkflow.bill_required_data_phone

// TODO: applicationWorkflow.final 3x state account email phone

applicationWorkflow.scanning_error2 = createStateSwitch(applicationWorkflow.scanning_error2(),
  {app: {biller_id: ''}}, {app: {biller_id: 22}},
);

// TODO: applicationWorkflow.phone_account_choice = createStateSwitch(applicationWorkflow.phone_account_choice(),
//   {
//     session: {
//       accounts: '',
//     },
//     app: {customer_limit_amount: 0},
//   },  {
//     app: {customer_limit_amount: 1000},
//     session: {
//       accounts: [
//         {
//           account_id: 1,
//           product_id: 1,
//           original_amount: 100,
//           product: 'product 1',
//           recipient_country_iso: 'MX',
//           recipient_number: '2142342315',
//           name: 'Name 1',
//           icon: 'icon',
//         },
//         {
//           account_id:2,
//           product_id: 2,
//           original_amount: 500,
//           product: 'product 1',
//           recipient_country_iso: 'MX',
//           recipient_number: '2142342315',
//           name: 'Name 1',
//           icon: 'icon',
//         },
//       ],
//     },
//   },
// );

applicationWorkflow.email_inner = createStateSwitch(applicationWorkflow.email_inner(),
  {app: {email_valid: '', email: ''}}, {},
  {app: {email_valid: true, email: 'some@some.com'}},
);

applicationWorkflow.sent = createStateSwitch(applicationWorkflow.sent(),
  {app: {email: ''}},
  {app: {email: 'some@some.com'}},
);

// applicationWorkflow.pay_device = createStateSwitch(applicationWorkflow.pay_device(),
//   {
//     app: {
//       discount_type: '',
//       service_name: '',
//
//       amount: '',
//       actual_escrow_amount: '',
//       requested_amount: '',
//     }
//   },
//   {
//     app: {
//       amount: 10,
//       actual_escrow_amount: 12,
//       requested_amount: 20,
//     }
//   },
//   )

// if (IW.appGetVal('discount_type') === 'first_payment') return 'First Payment';
// if (IW.appGetVal('discount_type') === 'welcome_back') return 'Welcome Back';
// if (IW.appGetVal('discount_type') === 'maya5') return 'Maya 5 Discount';
// if (IW.appGetVal('discount_type') === 'referral') return 'Referral Discount';
// if (IW.appGetVal('discount_type') === 'free_transaction') return 'Free Transaction';
// if (N(IW.appGetVal('discount_amount')) !== 0) return 'Discount';


// IW.appGetVal('service_name') === 'sigue'

// return Math.min(N(IW.appGetVal('amount')) + N(IW.appGetVal('actual_escrow_amount')), requestedAmount());
// IW.appGetVal('requested_amount')

applicationWorkflow.payment_pin = createStateSwitch(applicationWorkflow.payment_pin(),
  {
    app: {
      pad_pin: '',
      pin_wrong: '',
      withdrawal_alert: '',
      pin_debit_fee: '',
    },
  }, {
    app: {
      pad_pin: '1234',
      pin_debit_fee: '20',
    },
  },
  {app: {pin_wrong: true}},
  {app: {withdrawal_alert: 'withdrawal_success'}},
  {app: {withdrawal_alert: 'withdrawal_not_enough'}},
  {app: {withdrawal_alert: 'transaction_error'}},
  {app: {withdrawal_alert: 'card_insert_error'}},
  {app: {withdrawal_alert: 'return_card_internal_error'}},
  {app: {withdrawal_alert: 'take_your_card'}},
);

applicationWorkflow.inputs = createStateSwitch(applicationWorkflow.inputs(),
  {app: {account1: '', account2: ''}},
  {app: {account1: '1234', account2: '4321'}},
);

applicationWorkflow.inputs_billpayment = createStateSwitch(applicationWorkflow.inputs_billpayment(),
  {app: {account1: '', account2: '', account_is_invalid: ''}},
  {app: {account1: '1234', account2: '4321'}},
  {app: {account1: '1234', account2: '4321', account_is_invalid: true}},
);

// applicationWorkflow.confirm = createStateSwitch(applicationWorkflow.confirm(),
//   {app: {pin: '', code: '', hide_resend_button: ''}},
//   {app: {pin: '1234', code: '1235', hide_resend_button: false}},
// );

applicationWorkflow.adv_loading = createStateSwitch(applicationWorkflow.adv_loading(),
  {session: {app_guid: ''}},
  {session: {app_guid: 'app_atm_dGer'}},
  {session: {app_guid: 'app_phonereload_rrzQh'}},
  {session: {app_guid: 'app_billpay_cHckF'}},
);

// TODO:
applicationWorkflow.shopping_cart = createStateSwitch(applicationWorkflow.shopping_cart(),
  {app: {shopping_cart: '', shopping_cart_limits: ''}, session: {user: ''}}, {},
  {
    app: {
      shopping_cart: [{selected: true, enabled: true, requested_amount: 1, transaction_limit_id: 0}],
      shopping_cart_limits: [{}],
    },
    session: {user: {first_name: 'John', last_name: 'Smith'}},
  },
);
applicationWorkflow.shopping_cart_loading = createStateSwitch(applicationWorkflow.shopping_cart_loading());

applicationWorkflow.start_scanning = createStateSwitch(applicationWorkflow.start_scanning(),
  {app: {camera_preview_success: ''}},
  {app: {camera_preview_success: true}},
  {app: {camera_preview_success: false}},
);

// TODO:
applicationWorkflow.change_product = createStateSwitch(applicationWorkflow.change_product());
applicationWorkflow.stored_sessions = createStateSwitch(applicationWorkflow.stored_sessions(),
  {app: {customer_stored_sessions: []}},
  {
    app: {
      customer_stored_sessions: [
        {
          name: 'Some Stored Session',
          remaining_amount: 20,
          requested_amount: 30,
          icon: '/resources/icons/utilities/ADT.png',
        },
      ],
    },
  },
);

applicationWorkflow.customer_limit_reached = createStateSwitch(applicationWorkflow.customer_limit_reached(),
  {app: {customer_limit_type: ''}},
  {},
  {app: {customer_limit_type: 'monthly'}},
  {app: {customer_limit_type: 'six_month'}},
);

applicationWorkflow.referral_code = createStateSwitch(applicationWorkflow.referral_code(),
  {app: {customer_can_be_referral: '', referral_code_valid: ''}},
  {app: {customer_can_be_referral: true, referral_code_valid: true}},
);

applicationWorkflow.payment_status = createStateSwitch(applicationWorkflow.payment_status(),
  {
    app: {
      cancel_allowed: '',
      service_name: '',

      payment_status: '',
      payment_cancel_expiration: '',
      payment_readonly: '',
      payment_edit_disabled: '',
      payment_refunded: '',

      account1: '',
      account: '',
      email: '',
      number: '',
      sender_number: '',
    },
  }, {},
  {
    app: {
      payment_status: 'received',
      payment_cancel_expiration: new Date(Date.now() + 999999),
    },
  },
  {
    app: {
      payment_status: '',
      payment_readonly: false,
      payment_edit_disabled: false,
      payment_refunded: false,
      payment_cancel_expiration: new Date(Date.now() + 999999),
    },
  },

  {app: {payment_status: 'paid'}},
  {app: {cancel_allowed: false}},
  {app: {service_name: 'sigue'}},

  {app: {account1: '1234'}},
  {app: {account: '1234'}},
  {app: {email: '1234@1234.123'}},
  {app: {number: '+123412341234'}},
);


applicationWorkflow.sent_support = createStateSwitch(applicationWorkflow.sent_support(),
  {app: {service_name: ''}},
  {},
  {app: {service_name: 'sigue'}},
);

applicationWorkflow.payment_status_cancel = createStateSwitch(applicationWorkflow.payment_status_cancel(),
  {app: {payment_refunded: ''}}, {},
  {app: {payment_refunded: true}},
);

applicationWorkflow.insert_card = createStateSwitch(applicationWorkflow.insert_card(),
  {app: {card_insert_error: ''}}, {},
  {app: {card_insert_error: true}},
);

applicationWorkflow.enter_pin = createStateSwitch(applicationWorkflow.enter_pin(),
  {app: {pad_pin: '', pin_wrong: '', pin_not_valid: ''}},
  {app: {pad_pin: '1234567'}},
  {app: {pin_wrong: true}},
  {app: {pin_not_valid: true}},
);

applicationWorkflow.take_actions = createStateSwitch(applicationWorkflow.take_actions(),
  {app: {card_name: '', dispenser_cash: []}}, {app: {card_name: 'Username', dispenser_cash: [10]}},
  {app: {card_name: '', dispenser_cash: []}}, {app: {dispenser_cash: [10]}},
);

applicationWorkflow.accounts = createStateSwitch(applicationWorkflow.accounts(),
  {app: {accounts: []}},
  {
    app: {
      accounts: ATM_ACCOUNTS.slice(0, 3),
    },
  },
);

applicationWorkflow.insert_card_reload = createStateSwitch(applicationWorkflow.insert_card_reload(),
  {app: {card_insert_error: ''}}, {}, {app: {card_insert_error: true}},
);

applicationWorkflow.insert_card_reload_simple = createStateSwitch(applicationWorkflow.insert_card_reload_simple(),
  {app: {card_insert_error: ''}}, {}, {app: {card_insert_error: true}},
);

applicationWorkflow.card_reload_actions = createStateSwitch(applicationWorkflow.card_reload_actions(),
  {app: {card_name: ''}}, {app: {card_name: 'Username'}},
);

applicationWorkflow.card_balance = createStateSwitch(applicationWorkflow.card_balance(),
  {
    app: {
      check_balance_status: '',
      check_balance_response_text: '',
      balance_amount: '',
    },
  },
  {
    app: {
      check_balance_status: true,
      balance_amount: 20,
    },
  },
  {
    app: {
      check_balance_status: '',
      check_balance_response_text: 'Temporary error. Please, try again later.',
      balance_amount: '',
    },
  },
);

applicationWorkflow.return_card_transfer = createStateSwitch(applicationWorkflow.return_card_transfer(),
  {app: {transferred_amount: '', selected_account: '', destination_account: ''}},
  {app: {transferred_amount: 50, selected_account: 1, destination_account: 2}},
  {app: {transferred_amount: 50, selected_account: 3, destination_account: 4}},
  {app: {transferred_amount: 50, selected_account: 5, destination_account: 6}},
);

applicationWorkflow.return_card_transaction_error = createStateSwitch(applicationWorkflow.return_card_transaction_error(),
  {app: {transaction_status: ''}},
  {app: {transaction_status: 'Transaction declined'}},
);


applicationWorkflow.extract_money = createStateSwitch(applicationWorkflow.extract_money(),
  {app: {extraction_amount: '', selected_account: ''}},
  {app: {extraction_amount: 20, selected_account: 1}},
);

applicationWorkflow.extract_money_success = createStateSwitch(applicationWorkflow.extract_money_success(),
  {app: {dispensed_amount: '', dispensable_amount: ''}},
  {},
  {app: {dispensed_amount: 50, dispensable_amount: 100}},
);

applicationWorkflow.extract_money_not_enough = createStateSwitch(applicationWorkflow.extract_money_not_enough(),
  {app: {selected_amount: ''}},
  {app: {selected_amount: '100'}},
);

applicationWorkflow.transfer_money_not_enough = createStateSwitch(applicationWorkflow.transfer_money_not_enough(),
  {app: {selected_amount: ''}},
  {app: {selected_amount: '100'}},
);

applicationWorkflow.device_error = createStateSwitch(applicationWorkflow.device_error(),
  {app: {card_reader_status_success: '', cash_dispenser_status_success: '', epp_status_success: ''}},
  {},
  {app: {card_reader_status_success: true, cash_dispenser_status_success: '', epp_status_success: ''}},
  {app: {card_reader_status_success: true, cash_dispenser_status_success: true, epp_status_success: ''}},
);


applicationWorkflow.account_amount = createStateSwitch(applicationWorkflow.account_amount(),
  {app: {balance_amount_unavailable: ''}}, {},
  {app: {balance_amount_unavailable: true}},
);


applicationWorkflow.emv_select_application = createStateSwitch(applicationWorkflow.emv_select_application(),
  {app: {selection_items: []}},
  {
    app: {
      selection_items: [
        '"PLUS"',
        '"INTERLINK"',
      ],
    },
  },
);

applicationWorkflow.emv_account_amount = createStateSwitch(applicationWorkflow.emv_account_amount(),
  {app: {balance_amount_unavailable: ''}}, {},
  {app: {balance_amount_unavailable: true}},
);

applicationWorkflow.emv_return_card_emv_error = createStateSwitch(applicationWorkflow.emv_return_card_emv_error(),
  {app: {emv_error_status: ''}},
  {app: {emv_error_status: 'blocked_aid'}},
  {app: {emv_error_status: 'blocked_card'}},
  {app: {emv_error_status: 'not_found_aid'}},
  {app: {emv_error_status: 'chip_error'}},
  {app: {emv_error_status: 'card_expired'}},
  {app: {emv_error_status: 'unknown'}},
);


applicationWorkflow.dm_pin_code = createStateSwitch(applicationWorkflow.dm_pin_code(),
  {dm_login: {}},
);

applicationWorkflow.dm_confirm_code = createStateSwitch(applicationWorkflow.dm_confirm_code(),
  {dm_login: {}},
);

applicationWorkflow.dm_new_pin_code = createStateSwitch(applicationWorkflow.dm_new_pin_code(),
  {dm_login: {}},
);

// dm_product_select
// product_list

applicationWorkflow.dm_product_select = createStateSwitch(applicationWorkflow.dm_product_select(),
  {app: {product_list: []}},
  {app: {product_list: ['Bitcoin', 'Ethereum', 'Tether']}},
);


applicationWorkflow.dm_confirm_scan_data = createStateSwitch(applicationWorkflow.dm_confirm_scan_data(),
  {
    app: {
      customer_document_serial: '',
      customer_first_name: '',
      customer_last_name: '',
      customer_gender: '',
      customer_dob: '',
      customer_document_expiry: '',
      customer_document_issue: '',
      customer_street: '',
      customer_city: '',
      customer_zip: '',
      customer_state_code: '',
      customer_country_code: '',
    },
  },
  {
    app: {
      customer_document_serial: '-',
      customer_first_name: '-',
      customer_last_name: '-',
      customer_gender: '-',
      customer_dob: '-',
      customer_document_expiry: '-',
      customer_document_issue: '-',
      customer_street: '-',
      customer_city: '-',
      customer_zip: '-',
      customer_state_code: '-',
      customer_country_code: '-',
    },
  },
);

applicationWorkflow.dm_error = createStateSwitch(applicationWorkflow.dm_error(),
  {
    app: {
      custom_error_small: '',
      custom_error_big: '',
      custom_error_image: '',
      custom_error_spinner: '',
      custom_error_allow_back: '',
      custom_error_allow_menu: '',
    },
  },
  {
    app: {
      custom_error_big: 'Log in error. Please contact support',
      custom_error_image: '',
      custom_error_small: 'Server Error',
      custom_error_spinner: '',
      custom_error_allow_back: '',
      custom_error_allow_menu: true,
    },
  },
  {
    app: {
      custom_error_big: 'invalid PIN',
      custom_error_image: '',
      custom_error_small: 'Server Error',
      custom_error_spinner: '',
      custom_error_allow_back: '',
      custom_error_allow_menu: true,
    },
  },
  {
    app: {
      custom_error_big: 'Please try again',
      custom_error_image: '',
      custom_error_small: 'Server Error',
      custom_error_spinner: '',
      custom_error_allow_back: '',
      custom_error_allow_menu: true,
    },
  },
  {
    app: {
      custom_error_big: 'Can\'t initialize transaction. Please contact support.',
      custom_error_image: '',
      custom_error_small: 'Server Error',
      custom_error_spinner: '',
      custom_error_allow_back: '',
      custom_error_allow_menu: true,
    },
  },
  {
    app: {
      custom_error_big: 'Please contact support',
      custom_error_image: '',
      custom_error_small: 'Server Error',
      custom_error_spinner: '',
      custom_error_allow_back: '',
      custom_error_allow_menu: true,
    },
  },
  {
    app: {
      custom_error_big: 'Could not find pending phone number.',
      custom_error_image: '',
      custom_error_small: 'Server Error',
      custom_error_spinner: '',
      custom_error_allow_back: '',
      custom_error_allow_menu: true,
    },
  },
  {
    app: {
      custom_error_big: 'could not verify confirmation code, please contact customer service',
      custom_error_image: '',
      custom_error_small: 'Server Error',
      custom_error_spinner: '',
      custom_error_allow_back: '',
      custom_error_allow_menu: true,
    },
  },
  {
    app: {
      custom_error_big: 'Invalid pin code',
      custom_error_image: '',
      custom_error_small: 'Server Error',
      custom_error_spinner: '',
      custom_error_allow_back: '',
      custom_error_allow_menu: true,
    },
  },
  {
    app: {
      custom_error_big: 'Can\'t set pin code. Please contact support',
      custom_error_image: '',
      custom_error_small: 'Server Error',
      custom_error_spinner: '',
      custom_error_allow_back: '',
      custom_error_allow_menu: true,
    },
  },
  {
    app: {
      custom_error_big: 'an error has occurred',
      custom_error_image: '',
      custom_error_small: 'Server Error',
      custom_error_spinner: '',
      custom_error_allow_back: '',
      custom_error_allow_menu: true,
    },
  },
  {
    app: {
      custom_error_big: 'When enrolling without an ID scan, the customer may NOT immediately transact until DigitialMint Customer Support staff have manually approved the customer account. Manual approval typically takes less than 5 minutes during business hours: Monday through Friday, 9 AM – 7 PM CST.',
      custom_error_image: '',
      custom_error_small: 'Verification Required',
      custom_error_spinner: '',
      custom_error_allow_back: '',
      custom_error_allow_menu: '',
    },
  },
  // {app: {}},
);

// TODO:
// dm_wallet_select

export default applicationWorkflow;

function createStateSwitch(config, defaultSnapshot, ...stateSnapshots) {
  const updateState = (isSetDefault = false) => {
    if (isSetDefault) return applySnapshot(_.extend({}, defaultSnapshot));

    const requestedIndex = Number(Session.get('translation_state_index')) + 1;
    const newIndex = requestedIndex >= stateSnapshots.length
      ? 0
      : requestedIndex < 0
        ? stateSnapshots.length - 1
        : requestedIndex;
    Session.set('translation_state_index', newIndex);

    const stateSnapshot = stateSnapshots[newIndex];

    applySnapshot(__.merge({}, defaultSnapshot, stateSnapshot));
  };

  return () => {
    Session.set('translation_state_index', -1);
    Session.set('translation_pages_list', _.keys(applicationWorkflow));
    Session.set('translation_state_length', stateSnapshots.length);
    window.updateTranslationState = updateState;
    updateState();
    return config;
  };
}

function createSM(scope) {
  const SM = createSessionMethods(scope);
  if (!Session.get(scope)) Session.set(scope, {});
  return SM;
}

function applySnapshot(snapshot) {
  _.forEach(snapshot, (properties, scope) => {
    let setMethod = null;
    if (scope === 'app') setMethod = IW.appSetLocalVal;
    if (scope === 'session') setMethod = Session.set.bind(Session);
    else setMethod = createSM(scope).setVal;
    _.forEach(properties, (val, key) => setMethod(key, val));
  });
}