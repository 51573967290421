import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import BillScanning from './BillScanning';
import IW from 'imports/iw_api';
import getPageManager from 'imports/lib/page_manager';
import {countInDomBillScanningFavorites, countInDomBillScanningFavoritesFull} from 'imports/lib/helpers';
import CONFIG from 'imports/config';
import {Session} from "core/session";
import {getIcon} from 'imports/lib/getIcon';

export default createContainer((params) => {
  function convertToAccount(account, i) {
    return {
      account_id: account.account_id,
      name: account.name + '\n' + account.account,
      icon: getIcon(account.name) || account.icon,
      iconDefault: '/resources/icons/other/ic_common_billpay.png',
      type: 'account',
    }
  }

  function convertToFavorite(biller, i) {
    return {
      name: biller.name,
      icon: getIcon(biller.name) || biller.icon || '/resources/icons/other/ic_common_billpay.png',
      iconDefault: '/resources/icons/other/ic_common_billpay.png',
      type: 'favorite'
    }
  }

  const accounts = Session.get('accounts') || [];
  const favorites = IW.appGetVal('current_favorites') || [];
  const LINES = _.isEmpty(accounts) ?
    countInDomBillScanningFavoritesFull() :
    countInDomBillScanningFavorites();
  const COLUMNS = 5;
  //accounts= [accounts[0], accounts[0], accounts[0], accounts[0], accounts[0], accounts[0], accounts[0], accounts[0]];

  return {
    accounts: _.map(accounts, convertToAccount).chunk(COLUMNS).map((item) => item.complete(COLUMNS, {}).chunk(COLUMNS)),
    showFavorites: !_.isEmpty(favorites) && IW.appGetVal('show_favorites'),
    favoritesList: _.map(favorites, convertToFavorite).chunk(LINES * COLUMNS).map((item) => item.complete(LINES * COLUMNS, {}).chunk(COLUMNS)),
    hasAccount: accounts.length > 0,
    isLogged: IW.appGetVal('logged'),
    billScanningEnabled: CONFIG.BILL_SCANNING_ENABLED && !IW.appGetVal('bill_scanning_disabled'),
    selectNewBiller: Session.get('app_guid') === 'app_billpay_cHckF' && !IW.appGetVal('biller_predefined'),
    goToPay: () => {
      if (accounts.length === 0) return;
      IW.appSetVal('account_id', accounts[0].account_id);
      getPageManager().next()
    },
    goToScan: () => getPageManager().scan(),
    goToManual: () => getPageManager().manual(),
    goToSelectNew: () => getPageManager().select(),
    onBack: () => getPageManager().back()
  };

}, BillScanning);