import React, {Component} from 'react';
import VerticalSlider from 'imports/ui/shared/vertical_slider/VerticalSliderContainer';
import * as u from 'underscore'
import {_} from 'imports/lib/helpers'

export default class PaymentHistory extends Component {

  renderPaymentItem(payment, key, handleClick) {
    const {product_name, product_icon, actual_amount, time_end} = payment;

    if(u.isEmpty(payment)){
      return <div className="payment-item_container"> </div>
    }

    return (
      <div key={key} onMouseUp={(event) => handleClick(event, payment)}
           onMouseDown={(event) => handleClick(event, payment)} className="payment-item_container">
        <div className="payment-item_product">
          <img className="payment-item_product-image_image" src={product_icon}/>
          <div className="payment-item_product-desc">
            <span className="payment-item_product-desc_name">{product_name}</span>
            <span className="payment-item_product-desc_time">{time_end}</span>
          </div>
        </div>
        <div className="payment-item_amount">
          <span className="payment-item_product-desc_amount">{actual_amount}</span>
        </div>
        <div className="payment-item_pay-again">
          <img className="payment-item_pay-again_image" src="/themes/mayafs/images/icons/ic_pay_again_blue.png"/>
          <span className="payment-item_pay-again_desc" _>{`Pay\nagain`}</span>
        </div>
      </div>
    )
  }

  renderSliderItems() {
    const {items, onPayAgain} = this.props;

    return items.map((item, index) => {
      return this.renderPaymentItem(item, index, onPayAgain)
    })
  }

  render() {
    const {items, onNext} = this.props;

    const renderList = (
      <div className="payment-item_container">
        <span className="payment-item_product-not" _>No payments yet</span>
      </div>
    );

    return (
      <div className="profile_payments_container">
        <div className="profile_list-header_container">
          <div className="profile_list-header_container_left">
            <img className="profile_list-header_icon" src="/themes/mayafs/images/icons/ic_bill.png"/>
            <span className="profile_list-header_title" _>Payment history</span>
          </div>
          <div className="profile_list-header_container_right">
            <span className="profile_list-header_link" onMouseDown={() => onNext('pay_new_bill')}>
              {`+ ${_('Pay a new bill')}`}
            </span>
          </div>
        </div>
        <div className="payment_slider">
          {items.length ? <VerticalSlider withArrows={true} items={this.renderSliderItems()}/> : renderList}
        </div>
      </div>

    );
  }
}
