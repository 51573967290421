import React, {Component} from 'react';
import Image from 'imports/ui/shared/image/Image';
import {_, _feed} from 'imports/lib/helpers';

export default class AppIcon extends Component {
  render() {
    const {icon, onTapIcon, isProfileItem, isSimpleItem} = this.props;
    if (!icon) {
      // console.log("No icon specified for ", icon.app_guid)
      return null;
    }

    if (isSimpleItem && icon.icon && icon.name){
      return (
        <a className="app_icon" onMouseDown={onTapIcon} onClick={onTapIcon}>
          <div><Image src={icon.icon} default={icon.iconDefault}/></div>
          {icon.hasOwnProperty('name') ? <span>{icon.name}</span> : null}
        </a>
      )
    }

    if (!icon.app_guid) {
      // console.log("Empty app_guid, page: " + icon.page + ", icon: " + icon.icon + ", title: " + icon.title);
      return null;
    }


    if (isProfileItem) {
      return (
        <a className={"relative " + icon.app_guid} onMouseDown={onTapIcon} onClick={onTapIcon}>
          {_(icon.name, icon.app_guid, 'product')} <span className={icon.feed ? "badge" : "badge hidden"}>{icon.feed}</span>
        </a>
      );
    }

    return (
      <a className={"app_icon " + icon.app_guid} onMouseDown={onTapIcon} onClick={onTapIcon}>
        {icon.additional ? <div className="icon-info"><span>{icon.additional}</span></div> : ''}
        <div><Image src={icon.icon} default={icon.iconDefault}/></div>
        {icon.hasOwnProperty('name') ? <span>{_(icon.name, icon.app_guid, 'product')}</span> : null}
        {(icon.hasOwnProperty('feed') && _feed(icon.feed)) ? <p className="glow">${_feed(icon.feed)}</p> : null}
      </a>
    );
  }
}

AppIcon.propTypes = {
  icon: React.PropTypes.shape({
    app_guid: React.PropTypes.string,
    name: React.PropTypes.string,
    feed: React.PropTypes.number
  }),
  onTapIcon: React.PropTypes.func,
  isProfileItem: React.PropTypes.bool,
  isSimpleItem: React.PropTypes.bool,
};
