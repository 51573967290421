import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';

export default class LoansFinal extends Component{
  render() {
    const {onBack} = this.props;
    console.log(onBack);

    return (
      <div className="wrapper-index">
        <div className="body-container">
          <div className="get-app_wrapper" style={{alignItems: 'center'}}>
            <h1>SMS was sent</h1>
          </div>
        </div>
        <div className="footer-container">
          <div className="footer-container_col index-footer_col_left" style={{justifyContent: 'center'}}>
            <Button id="back" className="button button-pink" onMouseDown={onBack} text_="Exit"/>
          </div>
        </div>
      </div>
    )
  }
}

LoansFinal.propTypes = {
   onBack: React.PropTypes.func,
}
