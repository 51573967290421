import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import Input from 'imports/ui/shared/input/Input';
import safeRender from 'imports/ui/decorators/safeRender';
import CONFIG from '../../../config';

class EnterPin extends Component {
  render() {
    const {isPinOk, isPinNotValid, isPinWrong, pin, onNext, onCancel, onBack} = this.props;

    const labels = [];
    const pinPadImage = CONFIG.LIVE_IMAGES_GENMEGA ?
      '/resources/images/atm/genmega/ic_pin_pad.jpg' :
      '/resources/images/atm/ic_pin_pad.png'

    if (isPinWrong) {
      labels.push(
        <span key={0} className="text-md enter-pin__top__under-input__text failed" _>
          Your PIN is wrong. Please, try again
        </span>)
    }

    if (labels.length <= 0 && isPinNotValid) {
      labels.push(
        <span key={0} className="text-md enter-pin__top__under-input__text failed" _>
          Your PIN is not acceptable. Please, try again
        </span>)
    }

    if (labels.length <= 0) {
      labels.push(<span key={0} className="text-md enter-pin__top__under-input__text" _>Enter your PIN through</span>);
      labels.push(' ');
      labels.push(
        <span key={2} className="text-md enter-pin__top__under-input__text enter-pin__top__under-input__text-accented"
              _>
          ATM PIN pad
        </span>);
    }

    return (
      <table>
        <tbody>
        <tr>
          <td>
            <div className="enter-pin__top">
              <Input id="pin" className="enter-pin__top__input focus" value={pin} readOnly={true}/>
              <div className="enter-pin__top__decorate-icon">
                <img src="/resources/images/atm/ic_pin_small.png" alt=""/>
              </div>
              <br/>

              {labels}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className="enter-pin__bottom" data-genmega={!!CONFIG.LIVE_IMAGES_GENMEGA}>
              <img src={pinPadImage} alt=""/>
            </div>
          </td>
        </tr>
        </tbody>
        <tfoot className="atm-foot">
        <tr>
          <td colSpan="2">
            <Button id="back" className="left button" text_="Back" onMouseDown={onBack}/>
            {isPinOk ? <Button id="next" className="right button" onMouseDown={onNext} text_="Confirm"/> : null}
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

EnterPin.propTypes = {
  isPinOk: React.PropTypes.bool,
  pin: React.PropTypes.string,
  onNext: React.PropTypes.func,
  onBack: React.PropTypes.func,
  onCancel: React.PropTypes.func
};

export default EnterPin;
