import './common'
import './app_billpay_cHckF'
import './app_pinproducts_EX5LS'
import './app_moneytransfer_sigue'
import './app_cryptocoin_DgtMt'
import * as app_flow_testing from 'imports/lib/app_flow_testing'

window.__flow_testing = app_flow_testing;

export default {
}