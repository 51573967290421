import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import safeRender from 'imports/ui/decorators/safeRender';
import Image from 'imports/ui/shared/image/Image'
import getPageManager from 'imports/lib/page_manager';
import Disclaimer from './Disclaimer'
import VerticalSlider from 'imports/ui/shared/vertical_slider/VerticalSliderContainer';

class StartSweepstakes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDisclaimer: false,
      showDisclaimerButton: false
    }
  }

  renderDisclaimer() {
    return (
      <td colSpan={3}>
        <div className="sweepstakes-slider">
          <VerticalSlider sliderOptions={{
            slideStartVelocityThreshold: 0,
            snapVelocityThreshold: 0
          }} minEls={1} showScrollBar={true} items={<Disclaimer />}/>
        </div>
      </td>
    )
  }

  render() {
    const {showDisclaimer, showDisclaimerButton} = this.state;

    return (
      <table>
        <tbody>
        <tr>
          {showDisclaimer ? this.renderDisclaimer() :
            <td colSpan={3}>
              <div>
                <div className="atm__temp-page atm__temp-page__text">
                  <span _>{'Limited time cash prize!'}</span>
                </div>
                <div className="atm__temp-page atm__display__text">
                  <span _>{'Spin and Win!'}</span>
                </div>
              </div>
              <div className="atm__temp-page atm__display__img">
                <Image src="/resources/images/atm/ic_cash.png"/>
              </div>
            </td>}
        </tr>

        </tbody>
        <tfoot>
        <tr>
          <td style={{textAlign: 'center'}}>
            <Button id="back" className="left button" text_={showDisclaimer ? 'Decline' : 'No'} onMouseDown={() => getPageManager().cancel()}/>
          </td>

          {!showDisclaimer && showDisclaimerButton ?
            <td style={{textAlign: 'center', paddingRight: '100px'}}>
              <Button className="right button-danger button" text_="Disclaimer" onMouseDown={() => this.setState({
                showDisclaimer: true
              })}/>
            </td>
            : null}

          <td style={{textAlign: 'center'}}>
            <Button id="next" className="right button" text_={showDisclaimer ? 'Accept' : 'Yes'} onMouseDown={() => getPageManager().next()}/>
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

export default StartSweepstakes;
