import React, {Component} from 'react';
import IW from 'imports/iw_api';
import Button from 'imports/ui/shared/button/Button';
import {_ as i18n} from 'imports/lib/helpers';

export default class RecipientNotification extends Component {
  render() {

    var res = null;
    const {goNext, goBack, sendingFromNumber, sendingFromCountry, isLogged} = this.props;

    const sendingFromArgs = [
      <span className="transfer-description__number">{sendingFromNumber}</span>,
      <span className="transfer-description__country">({i18n(sendingFromCountry)})</span>
    ];

    res = (
      <table>
        <tbody>

        <tr className="thin">
          <td>
            <div className="transfer-description__container">
              <span _={sendingFromArgs}>Sending from : %s %s</span>
              <span style={{display: 'block', fontSize: 60, marginTop: 30}} _>
                Notify recipient?
              </span>
            </div>
          </td>
        </tr>

        <tr className="notification_type__buttons">
          <td>
            <table className="buttons-table">
              <tbody>
              <tr>
                <td>
                  <Button className="button button-primary" text_="Notify by SMS"
                          onMouseDown={() => goNext('phone') }
                          icon={<img className="button-icon"
                                     src={`/resources/images/money_transferring/ic_phone.png`}/>}/>
                </td>
              </tr>
              <tr>
                <td>
                  <Button className="button button-primary" text_="Notify by email"
                          onMouseDown={() => goNext('email') }
                          icon={<img className="button-icon"
                                     src={`/resources/images/money_transferring/ic_email.png`}/>}/>
                </td>
              </tr>
              <tr>
                <td>
                  <Button className="button button-primary" text_="I will notify myself "
                          onMouseDown={() => goNext() }
                          icon={<img className="button-icon"
                                     src={`/resources/images/money_transferring/ic_user.png`}/>}/>
                </td>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>

        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2">
            <Button id="back" className="left button" text_="Back" onMouseDown={goBack}/>
            {/*<Button id="next" className="right button" text_="Next" onMouseDown={goNext}/>*/}
          </td>
        </tr>
        </tfoot>
      </table>
    );
    return res;
  }
}

RecipientNotification.propTypes = {
  sendingFromNumber: React.PropTypes.string,
  sendingFromCountry: React.PropTypes.string,
  goNext: React.PropTypes.func,
  goBack: React.PropTypes.func
};
