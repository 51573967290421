import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import Speed from './Speed';


export default createContainer((params) => {

  return {};

}, Speed);
