import IW from 'imports/iw_api';
import createNumber from './common/number';
import createNotice from './common/notice';
import createPay from './common/pay';
import createPayDevice from './common/payDevice';
import createPayVirtual from './common/payVirtual';
import createFinal from './common/final';
import createPaymentStatus from './common/payment_status';
import createAmount from './common/amount';
import createAmountClerk from './common/amount_clerk';
import createSent from './common/sent';
import createConfirmCustomerData from './common/confirm_customer_data';
import createEditCustomerData from './common/edit_customer_data';
import createChangeProduct from './common/change_product';
import createStoredSessions from './common/stored_sessions';
import redirectCustomer from './common/redirectCustomer';
import createPaymentPin from './common/createPaymentPin';
import loginPages from './app_login_common';

export default {
  list: {},
  index: () => 'change_product',

  change_product: createChangeProduct('number', {plan: 'redirect1', prepaid: 'number'}),

  number: createNumber('exit', 'notice'),
  stored_sessions: createStoredSessions(),
  notice: createNotice('number', 'amount'),
  no_coin_change_notice: {
    cancel: 'exit',
    next: 'redirect_customer'
  },
  amount: createAmount('notice', 'pay'),
  amount_again: createAmount('exit', 'pay'),

  pay: createPay('pay_device', 'pay_virtual'),

  pay_device: createPayDevice('back', 'final', null, 'payment_status'),

  pay_virtual: createPayVirtual('back', 'final', null, 'payment_status', 'amount_clerk'),
  amount_clerk: createAmountClerk('back'),

  referral_code: function () {
    Session.set('current_active_field', 0);
    Session.set('prev_current_active_field', 0);

    return {
      back: 'back',
      next: function* () {
        const code = IW.appGetVal('referral_code_editable');
        IW.applyCustomerReferralCode(code);
        yield 'loading app customer_referrer_id';
        IW.appSetLocalVal('update_payment_done', false);
        IW.call('updatePayment');
        yield 'loading app update_payment_done';
        return 'back'
      }
    }
  },
  final: createFinal('email'),
  email: {
    back: 'final',
    next: function () {
      IW.call('sendEmail');
      return 'sent'
    }
  },
  // printed: createPrint(),
  sent: createSent(),
  payment_status: createPaymentStatus('number', 'amount_again', 'pay'),
  support_number: () => {
    IW.appSetVal('support_number', IW.appGetVal('support_number') ||
      IW.appGetVal('sender_number') || IW.appGetVal('number'));

    return {
      back: 'payment_status',
      next: function () {
        IW.call('doGetSupport');
        return 'sent_support'
      }
    }
  },
  payment_status_cancel: {
    cancel: 'payment_status',
    next: function* () {
      IW.appSetLocalVal('cancel_payment_done', false);
      IW.call('cancelPayment');
      yield 'loading app cancel_payment_done';
      if (!IW.appGetVal('cancel_payment_success')) return 'payment_status_cancel_error';
      if (!IW.canRefund()) return 'payment_status_refund_error';
      IW.launchRefund({
        refund_payment_guid: IW.appGetVal('payment_guid'),
        customer_id: IW.appGetVal('customer_id'),
        start_page: 'refund_canceled_transaction',
      });
      return 'loading';
    }
  },
  payment_status_cancel_error: {
    back: 'payment_status',
    next: 'support_number'
  },
  payment_status_refund_error: {
    back: 'payment_status',
    next: 'support_number'
  },

  sent_support: createSent(),
  payment_canceled: createSent(),

  ...loginPages,

  confirm_customer_data: createConfirmCustomerData('on_login'),
  edit_customer_data: createEditCustomerData('confirm_customer_data'),

  on_login: () => 'exit',
  on_login_recipient_choice: () => 'exit',

  maya5_description: () => {
    return {
      back: 'back'
    }
  },
  payment_pin: createPaymentPin(),
  maya5_congratulation: {},
  sweepstakes_start_question: {
    cancel: () => IW.appGetVal('customer_next_page'),
    next: () => {
      IW.launchSweepstakes({customer_id: IW.appGetVal('customer_id')});
      return 'loading';
    }
  },

  redirect_customer: redirectCustomer(),

  customer_limit_reached: function* () {
    return {
      back: 'back',
      next: 'exit',
    }
  },

  loading: {
    back: 'back'
  },

  adv_loading: {},

  return_card_internal_error: {},
  internal_error: {},
};
