import IW from 'imports/iw_api'
import {Session} from 'core/session'
import {FlowRouter} from 'core/Router';
import * as Timer from 'imports/api/timer';

export default class Watcher {
  constructor(onStart, onStop, pages){
    this.onStart = onStart;
    this.onStop = onStop;
    this.started = false;
    this.enabled = false;
    this.timeout = -1;
    this.pages = pages || [];
    this.options = null;
    this.sessionGuid = '';
  }
  
  start(options) {
    function routine() {
      const path = getLocationHash(self.pages);
      let newEnabled = self.enabled;

      // if (Timer.isTimeout() && self.enabled) {
      //   newEnabled = false
      // }
      //
      // if (!Timer.isTimeout() && !self.enabled) {
      //   newEnabled = true
      // }

      if (path !== checkPath) {
        newEnabled = false;
      }

      if (self.enabled && !newEnabled){
        call(self.onStop, sessionGuid, self.options);
        self.enabled = false;
      }

      if (path !== checkPath) {
        self.started = false;
        return;
      }

      if (!self.enabled && newEnabled){
        call(self.onStart, sessionGuid, self.options);
        self.enabled = true;
      }

      self.timeout = setTimeout(routine, 500);
    }

    const self = this;
    
    const sessionGuid = Session.get('session_guid');
    self.sessionGuid = sessionGuid;
    self.options = _.extend({}, options);

    call(self.onStart, sessionGuid, self.options);
    let checkPath = '';

    self.enabled = true;
    if (self.started) return;
    self.started = true;

    self.timeout = setTimeout(function () {
      if (sessionGuid !== Session.get('session_guid')) throw Error('Session is finished');
      checkPath = getLocationHash(self.pages);
      routine();
    }, 100);
  }
  
  stop(options){
    clearTimeout(this.timeout);
    this.started = false;
    this.enabled = false;
    call(this.onStop, this.sessionGuid, _.extend({}, this.options, options));
  }
}

function call(func, sessionGuid, options) {
  try {
    func(sessionGuid, options);
  }catch (e) {
    console.error('Watcher:', e)
  }
}

function getLocationHash(availablePages) {
  const params = FlowRouter.current().params;
  return Session.get('session_guid') + params.appName + params.language + params.productId +
    IW.appGetVal('paid') + IW.appGetVal('withdrawal_success') +
    (_.find(availablePages, (p) => (params.step || '').match(p)) ?
      '_available_page_' :
      params.step);
}
