import React, {Component} from "react";
import {Session} from "core/session";
import Button from 'imports/ui/shared/button/Button';
import safeRender from 'imports/ui/decorators/safeRender';
import IW from '../../iw_api';
import SliderLanguage from '../shared/slider/SliderLanguage';
import CONFIG from 'imports/config';
import {gaEvent} from "imports/lib/ga";

//css

class Index extends Component {
  constructor(props) {
    super(props);

    this.changeLocale = this.changeLocale.bind(this);
  }

  componentDidMount() {
    Session.set('lang_page', 1); // Back to the beginning of the language choices
  }

  componentWillUnmount() {
    const {timerId} = this.props;

    clearInterval(timerId)
  }

  gotoMainManu(locale) {
    return IW.mainMenu(locale);
  }

  gotoATM(locale) {
    return IW.launchATM(locale);
  }

  renderATMPage(locale) {
    return (
      <div className="applicationContainer">
        <div className="appSelectHelper" _>Press one to get started</div>
        <div className="main-wrapper">
          <div className="wrapper">
            <div className="app pulse-button-pay-any-bill">
              <div className="appIcon" onClick={() => {
                gaEvent(this.props.gaCategory, 'Select', 'ATM');
                this.gotoATM(locale)
              }}>
                <div className="ATM"></div>
              </div>
            </div>
            <div className="appHeader" _>ATM</div>
          </div>
          <div className="wrapper">
            <div className="app pulse-button-pay-any-bill">
              <div className="appIcon" onClick={() => {
                gaEvent(this.props.gaCategory, 'Select', 'Categories');
                this.gotoMainManu(locale)
              }}>
                <div className="APPS"></div>
              </div>
            </div>
            <div className="appHeader" _>{CONFIG.INDEX_PAGE_BUTTON_NAME}</div>
          </div>
        </div>
      </div>
    )
  }

  renderLanguagePage(items) {
    return (
      <div className="languages">
        <SliderLanguage items={items}/>
      </div>
    )
  }

  changeLocale() {
    const {locale} = this.props;
    const newLocale = locale === 'en_US' ? 'es_MX' : 'en_US';
    gaEvent(this.props.gaCategory, 'Change Locale', newLocale);
    Session.set('locale', newLocale);
  }


  renderLeftButton() {
    const {showLogout, onLogout, locale, showATM, showGetApp, onGetApp} = this.props;

    const languageText = locale === 'en_US' ? 'Español' : 'English';

    if (showLogout) {
      return (
        <div className="index-footer_col">
          <Button className="button button-pink" onMouseDown={onLogout} text_="Logout"/>
        </div>
      )
    }

    if (showGetApp && !showATM) {
      return (
        <div className="index-footer_col">
          <Button icon={
            <img className="footer-index_button-icon footer-index_button-icon_left"
                 src="/resources/icons/index/ic_get_our_apps.svg"/>
          } icon_placement="before" className="button button-blue" onMouseDown={onGetApp} text_="Get our App!"/>
        </div>
      )
    }

    return (
      <div className="index-footer_col">
        <Button id="back" className="button button-blue" onMouseDown={this.changeLocale} text_={languageText}/>
      </div>
    )
  }

  renderLoginButtonWithATM() {
    const {locale, onSignIn} = this.props;

    return (
      <div className="index-footer_col">
        <Button icon={
          <img className="footer-index_button-icon footer-index_button-icon_left"
               src="/resources/icons/index/ic_sign_in.svg"/>
        } icon_placement="after" className="button button-pink" id="next"
                onMouseDown={() => onSignIn(locale)} text_="Sign In"/>

      </div>
    )
  }

  renderLoginButtonWithoutATM() {
    const {onSignIn} = this.props;

    return (
      <div className="index-footer_col">
        <Button icon={
          <img className="footer-index_button-icon footer-index_button-icon_left"
               src="/resources/icons/index/ic_sign_in.svg"/>
        } icon_placement="after" className="button button-pink button-half-left" id="next"
                style={{paddingRight: '40px'}} onMouseDown={() => onSignIn('en_US')} text="Sign In"/>

        <Button className="button button-pink button-half-right" id="next" onMouseDown={() => onSignIn('es_MX')}
                text="Iniciar sesión"/>
      </div>
    );
  }

  renderFooter() {
    const {showLogin, showATM} = this.props;

    return (
      <div className="footer-index">

        {this.renderLeftButton()}

        {showLogin && showATM ? this.renderLoginButtonWithATM() : this.renderLoginButtonWithoutATM()}

      </div>
    )
  }

  render() {
    const {showFooter, locale, showATM, items} = this.props;

    if (!showATM && !items) return;

    return (
      <div className="wrapper-index">
        <div className="main-container">
          <div className="bannerContainer">
            <div id="banner"></div>
          </div>
          {showATM ? this.renderATMPage(locale) : this.renderLanguagePage(items)}
        </div>
        {showFooter ? this.renderFooter() : null}
      </div>
    )
  }
};

Index.propTypes = {
  items: React.PropTypes.arrayOf((React.PropTypes.arrayOf(React.PropTypes.arrayOf(React.PropTypes.object)))),
  showFooter: React.PropTypes.bool,
  showLogin: React.PropTypes.bool,
  showLogout: React.PropTypes.bool,
  showATM: React.PropTypes.bool,
  onGetApp: React.PropTypes.func,
  onSignIn: React.PropTypes.func,
  locale: React.PropTypes.string
};

export default safeRender(Index);
