/**
 * Created by vitaliy on 12.04.17.
 */
import React from 'react';

export default class InputRTLDirection extends React.Component {
  constructor(props) {
    super(props);
    this.timeout = -1;
    this.state = {
      blinking: true,
    };
  }

  componentWillReceiveProps(newProps, newState) {
    if (this.props.value !== newProps.value) {
      this.setState({blinking: false});
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => this.setState({blinking: true}), 300);
    }
  }

  render() {
    const {id, className, style, value, focus, clearInput} = this.props;
    return (
      <div id={id} className={'input-rtl-direction ' + className} style={style}>
        <div style={{width: '100%', height: '100%', overflow: 'hidden'}}>
          <div style={{textAlign: 'center', float: 'right', minWidth: '100%'}}>
            {value}<span id="cursor"
                         className={this.state.blinking ?
                           'blinking' :
                           ''}/>
          </div>
        </div>
        {id === focus ? <div className="input-rtl-direction__clear-button" onMouseDown={ clearInput }></div> : ''}
      </div>

    )
  }
}


InputRTLDirection.propTypes = {
  id: React.PropTypes.string,
  className: React.PropTypes.string,
  style: React.PropTypes.object,
  value: React.PropTypes.string,
  focus: React.PropTypes.string,
  clearInput: React.PropTypes.func
};

InputRTLDirection.defaultProps = {
  id: "",
  className: "",
  style: {}
};