import {createContainer} from 'imports/lib/react_createContainer';
import Card from './Card';
import {FlowRouter} from 'core/Router';

export default createContainer((params) => {

  return {
    className: params.className,
    iconUrl: params.iconUrl,
    direction: params.direction,
    text: params.text,
    additional: params.additional || null,
    onCardClick : params.onCardClick || (() => {}),
    style: params.style
  };

}, Card);