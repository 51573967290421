import React, {Component} from 'react';
import {_} from 'imports/lib/helpers';
import Numpad from 'imports/ui/shared/numpad/Numpad';
import IW from 'imports/iw_api';
import {Session} from 'core/session';
import Button from 'imports/ui/shared/button/Button';
import ClearedInput from 'imports/ui/shared/cleared_input/ClearedInput';
import DropdownIOSliderList from 'imports/ui/shared/dropdown/DropdownIOSliderListContainer';
import Loading from 'imports/ui/shared/loading/Loading';

export default class PhoneNumberTwice extends Component {

  componentDidMount() {
    Session.set('number', Session.get('number') ? Session.get('number') : "");

    Session.set('focus', 'number');

    var field_to_focus = Session.get('focus');
    Session.set('focus', '');
    Session.set('focus', field_to_focus);

    IW.appSetVal('focus', field_to_focus);
  }

  componentWillUpdate() {
    var field_to_focus = Session.get('focus');
    Session.set('focus', '');
    Session.set('focus', field_to_focus);

    IW.appSetVal('focus', field_to_focus);
  }

  render() {
    const {isNumberOk, valid, number, numberConfirm, value, numberFocused, numberPattern, numpadLayout, onPrintNumber, goNext, goBack, countryCodes,
        setPattern, canModifyPattern, wpValidation, doLoading, clearInput, clearConfirmInput, focus } = this.props;

    return (
      <table className="phone-number twice">
        <tbody>
        <tr>
          <td className="phone-number__input-container">
            <table>
              <tbody>
              <tr>
                <td>
                  <span className="text-md padding-l-md" _>Enter your phone number:</span>
                  <br /><br />
                  <ClearedInput id="number" pattern={numberPattern} className={"mask " + (numberFocused ? "focus" : "")}
                    value={number} readOnly={true} clearInput={clearInput} focus={focus}/>
                  <br /><br />
                  { countryCodes.length > 0 ? <DropdownIOSliderList id="dropdown" onChecked={ setPattern } className="drop-down" items={ countryCodes }/> : '' }
                </td>
              </tr>
              <tr>
                <td>
                  <span className={ "text-md padding-l-md " + (isNumberOk ? "" : "locked") } _>Repeat your phone number:</span>
                  <br /><br />
                  <ClearedInput id="number_confirm" pattern={numberPattern}
                    value={numberConfirm} className={"repeat " + (isNumberOk ? "" : "locked ")} readOnly={true}
                      clearInput={clearConfirmInput} focus={focus}
                  />
                </td>
              </tr>
              <tr>
                <td className="input-message-container">
                  <b style={{display : "block"}} className="note pre-wrap text-md padding-l-md">{
                    !wpValidation ? _("Please make sure your\nphone number is correct") :
                      <span style={{"color" : "#eb564a"}} _>Phone number is not valid. Please check your number.</span>}</b>
                </td>
              </tr>
              </tbody>
            </table>
          </td>
          <td className="phone-number__numpad-container">
            <Numpad keys={numpadLayout} pattern={numberPattern} value={value} onValueChange={onPrintNumber} canModifyPattern={canModifyPattern} />
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2">
            <Button id="back" className="left button" text_="Back" onMouseDown={goBack}/>
            { valid ? <Button id="next" className="right button" onMouseDown={goNext} text_="Next"/> : null }
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

PhoneNumberTwice.propTypes = {
  appGuid: React.PropTypes.string,
  pages: React.PropTypes.object,
  numpadLayout: React.PropTypes.arrayOf(React.PropTypes.array),
  isNumberOk: React.PropTypes.bool,
  valid : React.PropTypes.bool,
  numberPattern: React.PropTypes.string,
  number: React.PropTypes.string,
  numberConfirm : React.PropTypes.string,
  value : React.PropTypes.string,
  onPrintNumber: React.PropTypes.func,
  goNext: React.PropTypes.func,
  goBack: React.PropTypes.func,
  inputWidth: React.PropTypes.number,
  countryCodes : React.PropTypes.array,
  setPattern : React.PropTypes.func,
  canModifyPattern : React.PropTypes.bool,
  wpValidation : React.PropTypes.bool,
  doLoading : React.PropTypes.bool,
  numberFocused : React.PropTypes.bool,
  clearInput: React.PropTypes.func,
  clearConfirmInput: React.PropTypes.func,
  focus : React.PropTypes.string
};
