import React, {Component} from 'react';
import {FlowRouter} from 'core/Router';
import TransferDescription from 'imports/ui/shared/transfer_description/TransferDescriptionSessionContainer';
import Payment from './Payment'

export default class PayTransfer extends Payment {
  payingHeader() {
    return (
      <tr className="paying-header">
        <td>
          <TransferDescription type="simple" />
        </td>
      </tr>);
  }
}
