import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import Pagination4Dots from './Pagination4Dots';

const MAX_DOTS = 4;

export default createContainer((params) => {

  let count = params.apps.length;

  if(count > MAX_DOTS) {
    count = MAX_DOTS;
  } else if ( count === 1) {
    count = 0; // disable pagination
  }

  return {
    apps: count
  };

}, Pagination4Dots);