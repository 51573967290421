import {FlowRouter} from 'core/Router';
import {Session} from 'core/session';
import {Tracker} from 'core/tracker';
import React from 'react';
import {mount} from 'core/Mounter';
import CONFIG from 'imports/config';
import IW from 'imports/iw_api';
import createSessionLaunch from 'imports/lib/createSessionLaunch'
import appWorkflow from 'imports/apps_workflow'
import {getUser} from 'imports/lib/helpers';
import LayoutContainer from 'imports/ui/layouts/LayoutContainer';
import IndexContainer from 'imports/ui/pages/IndexContainer';
import SearchContainer from 'imports/ui/pages/SearchContainer';
import MainContainer from 'imports/ui/main_menu/MainMenuContainer';
import ListContainer from 'imports/ui/main_menu/ListContainer';
import AppContainer from 'imports/ui/pages/AppContainer';
import ProfileContainer from 'imports/ui/pages/profile/ProfileContainer';
import IndexHeader from 'imports/ui/headers/IndexHeaderContainer';
import IndexBanner from 'imports/ui/headers/IndexBanner';
import MainHeader from 'imports/ui/headers/MainHeader';
import CategoriesHeader from 'imports/ui/headers/CategoriesHeaderContainer';
import SearchHeaderContainer from 'imports/ui/headers/SearchHeaderContainer';
import AppHeaderContainer from 'imports/ui/headers/AppHeaderContainer';
import TranslationHeader from 'imports/ui/headers/TranslationHeader';
import ProfileHeaderContainer from 'imports/ui/headers/ProfileHeaderContainer';
import GetApp from 'imports/ui/pages/get_app/GetApp';
import SendApplicationSMS from 'imports/ui/pages/get_app/SendApplicationSMSContainer';
import Categories from 'imports/ui/main_menu/CategoriesContainer';
import Disclaimer from 'imports/ui/pages/disclaimer/Disclaimer';
import SearchLayout from 'imports/ui/apps/search/SearchLayoutContainer';
import TemplateDisplayActionsContainer from "imports/ui/apps/atm/TemplateDisplayActionsContainer";

//containers

//pages

//headers

// export variable reactive to changing the route,
//  because FlowRouter.watchPathChange() is buggy
const pathChangedDep = new Tracker.Dependency();
export default pathChangedDep

FlowRouter.notFound = {
  action: function () {
    IW.gotoIndex();
  },
};

function checkClerkLogin(redirect) {
}

const globalGroup = FlowRouter.group({
  name: 'Global',
  triggersEnter: [function (context, redirect) {
    pathChangedDep.changed();
    checkClerkLogin(redirect);
  }]
});

globalGroup.route('/', {
  name: 'Index',
  action: function (params) {
    params = extendParams(params, "index", "_index", null);
    return mount(LayoutContainer, {
      content: () => (<IndexContainer params={params}/>),
      header: () => [<IndexHeader key={0} withDisclaimer={true} support={IW.getUISettings().support_number}/>],
      params: params
    });
  }
});

globalGroup.route('/error', {
  name: 'Error',
  action: function (params) {
    params = extendParams(params, "error", "_error", null);
    return mount(LayoutContainer, {
      content: () => (<TemplateDisplayActionsContainer page="internal_error" />),
      header: () => [<IndexHeader key={0} withDisclaimer={false} support={IW.getUISettings().support_number}/>],
      params: params
    });
  }
});
/* Get mobile application */

globalGroup.route('/getApp', {
  name: 'GetApp',
  action: function (params) {
    params = extendParams(params, "getApp", "_getApp", null);
    return mount(LayoutContainer, {
      content: () => (<GetApp params={params}/>),
      header: () => [<IndexHeader key={0} support={IW.getUISettings().support_number}/>],
      params: params
    });
  }
})

/* Sweepstakes disclaimer */

globalGroup.route('/:language/disclaimer', {
  name: 'Disclaimer',
  action: function (params) {
    params = extendParams(params, "disclaimer", "_disclaimer", null);
    return mount(LayoutContainer, {
      content: () => (<Disclaimer params={params}/>),
      header: () => [<IndexHeader key={0} withDisclaimer={false} support={IW.getUISettings().support_number}/>],
      params: params
    });
  },
  triggersEnter: [function (router) {
    Session.set('locale', router.params.language);
  }]
});

globalGroup.route('/getApp/sendSms', {
  name: 'SendAppSms',
  action: function (params) {
    params = extendParams(params, "sendAppSms", "_sendAppSms", null);
    return mount(LayoutContainer, {
      content: () => (<SendApplicationSMS params={params}/>),
      header: () => [<IndexHeader key={0} support={IW.getUISettings().support_number}/>],
      params: params
    });
  },
  triggersExit: [function (router) {
    Session.set('number', '');
  }]
});

const languageSection = globalGroup.group({
  prefix: "/:language",
  name: "Language",
  triggersEnter: [function (context, redirect) {
    Session.set('locale', context.params.language);
    checkClerkLogin(redirect);
  }]
});

languageSection.route('/category', {
  name: 'Categories',
  action: function (params) {
    params = extendParams(params, "categories", "_categories", null);
    return mount(LayoutContainer, {
      content: () => (
        <SearchLayout onSearchSelected={() => FlowRouter.go('Search', params)} params={params}>
        <Categories/>
      </SearchLayout>),
      header: () => <CategoriesHeader backButton={false} params={params}/>,
      params: params
    });
  },
});

languageSection.route('/category/:categoryName', {
  name: 'Category',
  action: function (params) {
    params = extendParams(params, "category", "_category", null);
    return mount(LayoutContainer, {
      content: () => ((
        <SearchLayout onSearchSelected={() => FlowRouter.go('Search', params)} params={params}>
          <MainContainer/>
        </SearchLayout>)),
      header: () => <CategoriesHeader backButton={true} params={params}/>,
      params: params
    });
  },
  triggersEnter: [function () {
    const user = getUser();
    if (user && typeof user.customer_id !== 'undefined') {
      IW.gotoProfile();
    }
  }]
});

function createSubCategory(level) {
  function getCategory(params) {
    let category = Session.get('categories_tree');
    if (!category) return null;
    category = _.find(category.categories, (c) => c.id === +params.categoryName);
    for (let i = 1; i <= level && category; i++) {
      category = _.find(category.categories, (c) => c.id === +params["subCategory" + i]);
    }
    return category;
  }

  languageSection.route('/category/:categoryName/' + _.map(_.range(1, level + 1), (i) => ':subCategory' + i).join('/'), {
    name: 'SubCategory' + level,
    action: function (params) {
      params = extendParams(params, "subcategory", "_subcategory", null);
      params.products = getCategory(params).products

      return mount(LayoutContainer, {
        content: () => (<ListContainer params={params}/>),
        header: () => <CategoriesHeader backButton={true} params={params}/>,
        params: params
      });
    },
    triggersEnter: [function (context, redirect, stop) {
      if (!getCategory(context.params)) {
        redirect('Category', _.extend({}, context.params));
      }
    }]
  });
}

createSubCategory(1);
createSubCategory(2);

languageSection.route('/search', {
  name: 'Search',
  action: function (params) {
    params = extendParams(params, "search", "_search", null);
    return mount(LayoutContainer, {
      content: () => (<SearchContainer params={params}/>),
      header: () => <CategoriesHeader backButton={true} params={params}/>,
      params: params
    });
  }
});

languageSection.route('/app/:appName/:productId/:step', {
  name: 'Application',
  action: function (params) {
    params = extendParams(params,
      // FIXME: This should be replaced with another logic
      ((FlowRouter.getParam("step") === "shopping_cart" || FlowRouter.getParam("step") === "shopping_cart_loading")
        ? "app profile"
        : "app"),
      FlowRouter.getParam("appName") + "_" + FlowRouter.getParam("step"),
      FlowRouter.getParam("appName"));
    return mount(LayoutContainer, {
      content: () => (<AppContainer params={params}/>),
      header: () => {

        let options = {
          onIndexSelected: () => {
            FlowRouter.go('Category', {
              language: FlowRouter.getParam("language"),
              categoryName: Session.get('last_category') || IW.getPriorityCategory()
            });
          }
        };

        if (FlowRouter.getParam('appName') === 'app_translation_trans') {
          return <TranslationHeader {...options}
                                    pagesList={Session.get('translation_pages_list')}
                                    currentPage={FlowRouter.getParam('step')}
                                    allowStateSwitch={Session.get('translation_state_length') > 1}
          />
        }

        if (FlowRouter.getParam('appName') === 'app_profile_common') {
          options = _.extend(options, {
            onIndexSelected: () => FlowRouter.go('Category', _.extend(params, {categoryName: Session.get('last_category') || IW.getPriorityCategory()})),
            onSearchSelected: () => FlowRouter.go('Search', params),
            onExitSelected: () => IW.gotoIndex()
          });

          return <ProfileHeaderContainer {...options} />
        }

        return <AppHeaderContainer {...options} />
      },

      params: params
    });
  },
  triggersEnter: [function (context, redirect) {
    if (Session.get('session_active')) return;
    IW.appClearVals();
    redirect('/');
  }]
});

// languageSection.route('/atm/:step', {
//   name: 'ATM',
//   action: function (params) {
//     console.log('ATM router params ', params);
//     params = extendParams(params, "atm", "_atm", null);
//     console.log('ATM router params after', params);
//     mount(LayoutContainer, {
//       content: () => (<ATMContainer params={params}/>),
//       header: () => (<ATMHeaderContainer onIndexSelected={() => {
//         FlowRouter.go('Category', {language: FlowRouter.getParam("language"), categoryName: CONFIG.DEFAULT_CAT});
//       }}/>),
//
//       params: params
//     });
//   }
// });

var profileSection = languageSection.group({
  prefix: "/profile",
  name: "Profile Group",
  triggersEnter: [function (context, redirect) {
    checkClerkLogin(redirect);
  }]
});

profileSection.route('/', {
  name: 'Profile',
  action: function (params) {
    params = extendParams(params, "profile", "_profile", null);
    return mount(LayoutContainer, {
      content: () => (<ProfileContainer params={params}/>),
      header: () => (
        <ProfileHeaderContainer
          onIndexSelected={() => FlowRouter.go('Category', _.extend(params, {categoryName: Session.get('last_category') || IW.getPriorityCategory()}))}
          onSearchSelected={() => FlowRouter.go('Search', params)}
          onExitSelected={() => IW.gotoIndex()}/>
      ),
      params: params
    });
  },
  triggersEnter: [function () {
    var user = getUser();
    if (!user || typeof user.customer_id === 'undefined') {
      IW.gotoIndex();
    }
  }]
});

//extend Params
function extendParams(params, layoutName, appPageName, appName) {
  return _.extend(params, {
    layoutName: layoutName,
    appPageName: appPageName,
    appName: appName,
    _formattedNames: (!_.isNull(layoutName) ? (layoutName + " ") : "") +
    (!_.isNull(appPageName) ? (appPageName + " ") : "") +
    (!_.isNull(appName) ? appName : "")
  });
}

Tracker.autorun(function () {
  var routeName = FlowRouter.getRouteName();
  Session.set('search_string', '');
  //Locked session restoration when customer in pay page
  // const step = FlowRouter.getParam('step')
  // Session.set('restoration_locked',
  //   step === 'pay' ||
  //   step === 'pay_again' ||
  //   step === 'pay_transfer' ||
  //   step === 'pay_transfer_again' ||
  //   step === 'loading');
  const appGuid = FlowRouter.getParam('appName')
  Session.set('restoration_locked', appGuid);
});
