import React from 'react';
import IW from 'imports/iw_api';
import Loading from 'imports/ui/shared/loading/Loading';

class AdvLoading extends React.Component {

  render() {
    IW.setVoicePage('loading');
    if (this.props.default) return <Loading />;

    const {
      bigImage,
      bigText,
      middleText,
      captionText,
      images,
      bottomText,
    } = this.props;

    // eslint-disable-next-line multiline-ternary
    const bigImageProps = _.isObject(bigImage) ? bigImage : {src: bigImage};
    // eslint-disable-next-line multiline-ternary
    const imagesProps = _.isEmpty(images) ? null : images.map((img) => _.isObject(img) ? img : {src: img});

    return (
      <div className="promo-loading">
        {bigImage && <img className="promo-loading-big-image" {...bigImageProps}/>}
        {bigText && <div className="promo-loading-big-text">{bigText}</div>}
        {middleText && <div className="promo-loading-middle-text">{middleText}</div>}
        {captionText && <div className="promo-loading-caption-text">{captionText}</div>}
        {imagesProps && (
          <div className="promo-loading-images">
            {imagesProps.map((img, i) => <img key={i} {...img}/>)}
          </div>
        )}
        {bottomText && <div className="promo-loading-bottom-text">{bottomText}</div>}
      </div>
    );
  }
}

export default AdvLoading;