import app_common from "./app_common";
import app_moneytransfer_common from "./app_moneytransfer_common";
import createNumber from "./common/number";
import IW from "../iw_api";
import createEnterSenderData from "./common/enterSenderData";
import {Session} from "core/session";
import createPayout from "./common/payout";
import createEnterRecipientData from "./common/enterRecipientData";
import createEmail from "./common/createEmail";
import createConfirmPayout from './common/confirmPayout';
import createRecipientChoise from './common/recipientChoise';
import __ from 'lodash';

export default _.extend({}, app_common, app_moneytransfer_common, {
  sender_number: createNumber('exit', 'confirm_sender_data', {
    onCreate: function () {
      if (!IW.appGetVal('origin_agent_id')) {
        if (IW.appGetVal('sigue_calculate_error_code') === 0) return 'exit';
        IW.appSetLocalVal('sigue_calculate_error_code', 0);
        IW.appSetLocalVal('sigue_calculate_error_small', 'SIGUE Money Transfer Services will be back shortly');
        return 'sigue_error';
      }

      IW.appSetVal('number', IW.appGetVal('sender_number') || '', true);

      // TODO: Only for debugging
      // IW.appSetVal('recipient_address_country_id', 43);
      // IW.appSetVal('bank_required_fields', {
      //   ABF_IS_BANK_NAME_REQUIRED: true,
      //   ABF_IS_REASON_OF_PAYMENT_REQUIRED: true,
      //   ABF_IS_ANSWER_REQUIRED: true,
      //   ABF_IS_QUESTION_REQUIRED: true,
      //   ABF_IS_RECIPIENT_ID_TYPE_REQUIRED: true,
      //   ABF_IS_RECIPIENT_ID_NUMBER_REQUIRED: true,
      //   ABF_IS_RECIPIENT_CPF_REQUIRED: true,
      // })
      // IW.appSetVal('recipient_address_country', 'PHILIPPINES')

      // IW.call('setRecipientCountry');
      // return 'redirect3'

      // IW.call('debug_copySession', 's_PWGLy', function () {
      //   IW.appSetLocalVal('debug_session_copy_done', true);
      // });
      // yield 'loading app debug_session_copy_done';
      // return 'confirm_sender_data'
    }
  }),

  redirect3: function* () {
    yield* doCall('debug_copySession', 's_mqW6N')
    return 'enter_amount_transfer'
  },

  on_login_pay_again: () => {
    IW.appSetLocalVal('enter_amount_transfer_back', 'exit')
    if (!IW.appGetVal('logged')) IW.appSetVal('enter_amount_transfer_back', 'back')
    return 'enter_amount_transfer'
  },

  on_login_recipient_choice: function* ({lastAction}) {
    if (lastAction === 'back') return 'exit';
    yield* doCall('createSender');
    yield* doCall('createBeneficiary');
    Session.set('recipients', null);
    IW.callWithUpdate('getRecipients', 'recipients');
    yield 'loading session recipients';

    const recipientIndex = __.findIndex(Session.get('recipients'), (r) => +r.recipient_sender_code === +IW.appGetVal('recipient_sender_code'));
    if (recipientIndex === -1) {
      IW.appSetLocalVal('sigue_calculate_error_small', 'Recipient cannot be restored');
      return 'sigue_error';
    }

    IW.appSetVal('customer_item_id', recipientIndex);
    IW.appSetLocalVal('from_profile', true);
    IW.appSetLocalVal('new_recipient', false);
    IW.appSetLocalVal('payer_details_confirm', null);
    IW.appSetVal('original_amount', 0);
    IW.appSetVal('validation_error', '');
    const errorPage = yield* setRecipient(Session.get('recipients')[recipientIndex]);
    if (errorPage) return errorPage;
    return 'confirm_payout'
  },

  enter_sender_data: () => 'sigue_enter_sender_data',
  sigue_enter_sender_data: createEnterSenderData('sender_number', 'confirm_sender_data', () => {
    IW.appSetLocalVal('sigue_enter_sender_data_back', true);
    return 'confirm_sender_data';
  }),

  confirm_sender_data: () => 'sigue_confirm_sender_data',
  sigue_confirm_sender_data: function () {
    IW.appSetVal('sender_number', IW.appGetVal('sender_number').replace(/[^\d]/g, ''));

    if (IW.appGetVal('sigue_enter_sender_data_back')) {
      IW.appSetObject(IW.appGetVal('previous_sender'))
      IW.appSetLocalVal('previous_sender', null);
      IW.appSetLocalVal('sigue_enter_sender_data_back', false);
    }

    if (!IW.appGetVal('logged') &&
      !IW.appGetVal('confirm_sender_data') &&
      !IW.appGetVal('sender_new_data_entered')) return 'sigue_enter_sender_data';

    return {
      edit: function () {
        IW.appSetVal('confirm_sender_data', true);
        const senderObject = {
          sender_first_name: IW.appGetVal('sender_first_name'),
          sender_middle_name: IW.appGetVal('sender_middle_name'),
          sender_last_name: IW.appGetVal('sender_last_name'),
          sender_last_name2: IW.appGetVal('sender_last_name2'),
          sender_home_phone: IW.appGetVal('sender_home_phone'),
          sender_address: IW.appGetVal('sender_address'),
          sender_address1: IW.appGetVal('sender_address1'),
          sender_address2: IW.appGetVal('sender_address2'),
          sender_address_city: IW.appGetVal('sender_address_city'),
          sender_address_state: IW.appGetVal('sender_address_state'),
          sender_address_zip: IW.appGetVal('sender_address_zip'),
          sender_birth_country_select: IW.appGetVal('sender_birth_country_select'),
          sender_birth_date: IW.appGetVal('sender_birth_date')
        }
        IW.appSetLocalVal('previous_sender', senderObject);
        return 'sigue_enter_sender_data';
      },
      next: function* () {
        IW.appSetVal('validation_error', '');
        IW.appSetVal('confirm_sender_data', true);

        IW.appSetLocalVal('sender_location_is_invalid', false);
        IW.appSetVal('sender_address_city_id', 0);
        IW.appSetVal('sender_address_state_id', 0);

        yield* doCall('getLocationIdsByZipCode', 'sender', (data) => IW.appSetObject(data)); // TODO: Sometimes getLocationIdsByZipCode does not set data into session

        if (
          !IW.appGetVal('sender_address_city_id') ||
          !IW.appGetVal('sender_address_state_id') ||
          !IW.appGetVal('sender_address_country_id')
        ) {
          IW.appSetLocalVal('sender_location_is_invalid', true);
          IW.appSetVal('validation_error', 'Sender location is not correct!');
          return 'sigue_enter_sender_data'
        }

        // yield* doCall('validateCustomerData', 'sender', IW.appSetVal.bind(null, 'reg_ex_sender_error'));
        // if(!_.isEmpty(IW.appGetVal('reg_ex_sender_error'))) {
        //   IW.appSetVal('sigue_calculate_error_small', IW.appGetVal('reg_ex_sender_error').join('\n').replace(FIND_FIRST_300_REG, '$1...'));
        //   return 'sigue_error'
        // }

        yield* doCall('createSender');
        if (IW.appGetVal('create_sender_error') === 'ERROR') {
          IW.appSetVal('sigue_calculate_error_big', 'Cannot create sender.');
          IW.appClearVal('create_sender_error');
          return 'sigue_error';
        }

        if (IW.appGetVal('logged')) return 'recipient_choice';
        return 'payout'
      }
    }
  },

  recipient_choice: createRecipientChoise('confirm_sender_data', 'confirm_payout', null, function* (recipient) {
    return yield* setRecipient(recipient)
  }),

  confirm_payout: createConfirmPayout(
    () => (IW.appGetVal('from_profile') && 'exit') || 'recipient_choice',
    'payout', 'enter_amount_transfer', confirmPayoutData),

  payout: createPayout('confirm_sender_data', 'payment_city_sigue', 'confirm_payout',
    () => (IW.appGetVal('from_profile') && 'exit') || 'recipient_choice'),

  list_slider: function* ({lastAction}) {
    const pageBack = 'payment_city_sigue';
    const pageBankPage = 'enter_bank_data';
    const onLoginBackPage = 'payout';

    if (lastAction === 'next') {
      Session.set('money_transferring_payers', null);
      IW.callWithUpdate('getPayers', 'money_transferring_payers');
      yield 'loading session money_transferring_payers';
    }

    if (_.isEmpty(Session.get('money_transferring_payers'))) {
      return pageBack
    }

    return {
      back: function () {
        IW.appSetLocalVal('list_slider_error', false);
        if (IW.appGetVal('logged') && !IW.appGetVal('new_recipient') && !IW.appGetVal('is_payer_edit')) {
          return onLoginBackPage;
        }
        return pageBack;
      },
      next: function* () {
        yield 'loading app network_id';
        const payer = _.find(Session.get('money_transferring_payers'), (p) => p.network_id === IW.appGetVal('network_id'));
        if (!payer) {
          Session.set('money_transferring_payers', null);
          return pageBack;
        }
        IW.appSetDefaults(/^bank_/);

        yield* doCall('setPayer', payer);

        Session.set('money_transferring_payment_locations', payer.paymentLocations);

        if (IW.appGetVal('recipient_payment_mode_type') === 'bank_deposit' && payer.bank_id) {
          IW.appSetVal('payer_bank_id', payer.bank_id);
          IW.appSetVal('payer_bank_name', payer.bank_name);
          IW.appSetVal('payer_name', '');
          return pageBankPage;
        }

        IW.appSetVal('payer_bank_id', '');
        IW.appSetVal('payer_bank_name', '');
        IW.appSetVal('payer_name', payer.payer_name);

        confirmPayoutData();

        if (payer.paymentLocations.length < 2) {
          const paymentLocation = payer.paymentLocations[0];
          IW.appSetObject(paymentLocation);
          return 'recipient_notification';
        }

        return 'sigue_payment_locations_list_slider';
      }
    }
  },

  sigue_payment_locations_list_slider: function () {
    return {
      back: 'list_slider',
      next: 'recipient_notification'
    }
  },

  payment_city_sigue: function ({lastAction}) {
    Session.set('prev_current_active_field', 0);
    Session.set('current_active_field', 0);
    Session.set('money_transferring_cities', "");

    IW.appSetVal('payment_agent_address_city_id', 0);
    IW.appSetVal('payment_agent_address_state_id', 0);

    return {
      back: function () {
        return 'payout';
      },

      next: function* () {
        IW.appSetLocalVal('payment_agent_location_ids_done', false);
        IW.callWithAppLocalUpdate('setLocationIDs', 'payment_agent_location_ids_done', 'payment_agent', true);
        yield 'loading app payment_agent_location_ids_done';

        if (
          !IW.appGetVal('payment_agent_address_city_id') ||
          !IW.appGetVal('payment_agent_address_state_id') ||
          !IW.appGetVal('payment_agent_address_country_id')
        ) {
          IW.appSetVal('validation_error', 'There are no available payers for this location.');
          return 'none'
        }

        IW.appSetVal('recipient_address_city', IW.appGetVal('payment_agent_address_city'));
        IW.appSetVal('recipient_city_name', IW.appGetVal('payment_agent_address_city'));

        return 'list_slider';
      }
    }
  },

  recipient_notification: function () {
    IW.appSetVal('recipient_email', '');
    IW.appSetVal('recipient_number', '');
    IW.appSetVal('recipient_notification_type', '');

    return {
      back: function () {
        return 'list_slider';
      },

      next: function () {
        switch (IW.appGetVal('recipient_notification_type')) {
          case 'email':
            return 'recipient_email';
          case 'phone':
            return 'recipient_number';
          default:
            return 'sigue_enter_recipient_data'
        }
      }

    }
  },

  recipient_number: createNumber('recipient_notification', 'sigue_enter_recipient_data', {
    onCreate: function () {
      IW.appSetVal('number', IW.appGetVal('recipient_number') || '', true);
    },
    shouldCreateCustomer: false
  }),

  recipient_email: createEmail('recipient_notification', 'sigue_enter_recipient_data'),

  // recipient_city_sigue: function ({lastAction}) {
  //   Session.set('prev_current_active_field', 0);
  //   Session.set('current_active_field', 0);
  //   Session.set('money_transferring_cities', "");
  //
  //   IW.appSetVal('recipient_address_city_id', 0);
  //   IW.appSetVal('recipient_address_state_id', 0);
  //
  //
  //   return {
  //     back: 'recipient_notification',
  //
  //     next: function* () {
  //       IW.appSetLocalVal('recipient_location_ids_done', false);
  //       IW.callWithAppLocalUpdate('setLocationIDs', 'recipient_location_ids_done', 'recipient', true);
  //       yield 'loading app recipient_location_ids_done';
  //
  //       if (
  //         !IW.appGetVal('recipient_address_city_id') ||
  //         !IW.appGetVal('recipient_address_state_id') ||
  //         !IW.appGetVal('recipient_address_country_id')
  //       ) {
  //         IW.appSetVal('validation_error', 'Something went wrong with location. Please try again later.');
  //         return 'none'
  //       }
  //
  //       return 'enter_recipient_data_sigue';
  //     }
  //   }
  // },

  enter_recipient_data: () => 'sigue_enter_recipient_data',
  sigue_enter_recipient_data: createEnterRecipientData('recipient_notification', () => {
    IW.appSetDefaults(/^compliance_receiver/);

    // if(!_.isEmpty(IW.appGetVal('reg_ex_ben_error'))) {
    //   IW.appSetVal('sigue_calculate_error_small', IW.appGetVal('reg_ex_ben_error').join('\n').replace(FIND_FIRST_300_REG, '$1...'));
    //   return 'sigue_error'
    // }

    if (IW.appGetVal('create_beneficiary_error')) {
      IW.appSetVal('sigue_calculate_error_big', 'Cannot create beneficiary.');
      if (IW.appGetVal('create_beneficiary_error') === 'PHONE') {
        IW.appSetVal('sigue_calculate_error_big', 'Beneficiary phone is not correct.');
      }
      IW.appClearVal('create_beneficiary_error');
      return 'sigue_error';
    }

    return 'enter_amount_transfer';
  }, function* () {
    // yield* doCall('validateCustomerData', 'ben', IW.appSetVal.bind(null, 'reg_ex_ben_error'));

    IW.appSetLocalVal('create_beneficiary_done', false);
    IW.call('createBeneficiary');
    yield 'loading app create_beneficiary_done';
  }),

  enter_amount_transfer: function* () {
    IW.appSetLocalVal('feeType', '');
    IW.appSetLocalVal('rate', 0);
    IW.appSetVal('calculated_total', 0);
    IW.appSetVal('calculated_destination_amount', 0);
    IW.call('updateAmounts');
    yield 'loading app rate';

    return {
      back: function () {
        if (IW.appGetVal('enter_amount_transfer_again')) {
          if (IW.appGetVal('logged')) return 'exit';
          return 'payment_status';
        }
        if (IW.appGetVal('enter_amount_transfer_back')) return IW.appGetVal('enter_amount_transfer_back');
        if (IW.appGetVal('logged') && !IW.appGetVal('new_recipient') && !IW.appGetVal('is_payer_edit')) {
          return 'confirm_payout';
        }
        return 'sigue_enter_recipient_data'
      },
      next: function () {
        if (IW.appGetVal('recipient_payment_mode_id') === 'AD') {
          return 'sigue_bank_required_fields'
        }
        return 'redirect2'
      }
    }
  },

  enter_amount_transfer_again: function () {
    IW.appSetLocalVal('enter_amount_transfer_again', true);
    return 'enter_amount_transfer'
  },

  sigue_bank_required_fields: function* () {
    Session.set('prev_current_active_field', 0);
    Session.set('current_active_field', 0);

    yield* doCall('getBankRequiredFields', null, IW.appSetVal.bind(null, 'bank_required_fields'));

    const requiredFields = IW.appGetVal('bank_required_fields');

    Session.set('bank_search_list', []);
    if (
      requiredFields.ABF_IS_BANK_NAME_REQUIRED ||
      requiredFields.ABF_IS_LOCAL_BANK_CODE_REQUIRED ||
      requiredFields.ABF_IS_IBAN_REQUIRED
    ) {
      yield* doCall('getBankList', {
        countryName: IW.appGetVal('recipient_address_country')
      }, Session.set.bind(Session, 'bank_search_list'));
    }

    Session.set('bank_account_type_list', []);
    yield* doCall('getBankAccountTypes', null, Session.set.bind(Session, 'bank_account_type_list'));

    if (requiredFields.ABF_IS_REASON_OF_PAYMENT_REQUIRED) {
      Session.set('bank_payment_reason_list', []);
      yield* doCall('getPaymentReason', null, Session.set.bind(Session, 'bank_payment_reason_list'));
    }

    if (requiredFields.ABF_IS_RECIPIENT_ID_TYPE_REQUIRED) {
      Session.set('id_type_list', []);
      yield* doCall('getIdTypes', null, Session.set.bind(Session, 'id_type_list'));
    }


    return {
      back: 'sigue_enter_recipient_data',
      next: 'redirect2'
    }
  },

  redirect2: function* ({lastAction}) {
    function generalFail() {
      IW.appSetVal('sigue_calculate_error_small', 'There\'s a temporary error');
      IW.appSetVal('sigue_calculate_error_big', 'Please try again later');
      return 'sigue_error';
    }

    if (lastAction === 'back') {
      if (IW.appGetVal('recipient_payment_mode_id') === 'AD') return 'sigue_bank_required_fields';
      return 'enter_amount_transfer';
    }

    IW.appSetLocalVal('calculated_total', 0);
    IW.appSetLocalVal('calculated_done', false);
    IW.call('doCalculate');
    yield 'loading app calculated_done';
    if (!IW.appGetVal('calculated_status_success')) return generalFail();

    IW.appSetLocalVal('rate', 0);
    IW.call('updateAmounts');
    yield 'loading app rate';

    IW.appSetDefaults(/^compliance_/)
    IW.appSetLocalVal('sigue_compliance_after', false);
    return 'redirect1';
  },

  redirect1: function* ({lastAction}) {
    if (lastAction === 'back') {
      if (IW.appGetVal('recipient_payment_mode_id') === 'AD') return 'sigue_bank_required_fields';
      return 'enter_amount_transfer';
    }

    yield* doCall('getAmlRules', null, IW.appSetVal.bind(null, 'aml_required_fields'));
    const rules = IW.appGetVal('aml_required_fields');

    if (!rules) {
      IW.appSetVal('sigue_calculate_error_small', 'There\'s a temporary error');
      IW.appSetVal('sigue_calculate_error_big', 'Please try again later');
      return 'sigue_error';
    }

    const {ADDITIONAL_INFORMATION, COMPLIANCE_HIT, MESSAGE_RULE_CHECK, ISSUES, WARNINGS} = (rules || {
      MESSAGE_RULE_CHECK: {},
      ADDITIONAL_INFORMATION: {},
      ISSUES: [],
      WARNINGS: [],
    });

    if (COMPLIANCE_HIT) {
      IW.appSetVal('sigue_calculate_error_code', 0);
      IW.appSetVal('sigue_calculate_error_small', 'Your Money Transfer authorization has been declined at this time');
      IW.appSetVal('sigue_calculate_error_big', 'Go to the nearest Sigue location to complete your transaction');
      return 'sigue_error'
    }

    if (IW.appGetVal('recipient_home_phone')) delete ADDITIONAL_INFORMATION.RECEIVER_PHONE;
    if (IW.appGetVal('recipient_address1')) delete ADDITIONAL_INFORMATION.RECEIVER_ADDRESS;

    if (!IW.appGetVal('sigue_compliance_after') && !_.isEmpty(ADDITIONAL_INFORMATION)) {
      return 'sigue_compliance'
    }

    if (!_.isEmpty(ISSUES)) {
      IW.appSetVal('sigue_calculate_error_small', ISSUES.join('\n'));
      return 'sigue_error'
    }

    if (!_.isEmpty(MESSAGE_RULE_CHECK)) {
      IW.appSetVal('sigue_calculate_error_small', _.map(MESSAGE_RULE_CHECK, v => v.replace(/(.)\1{3,}/g, '$1')).join('\n'));

      return 'sigue_error'
    }

    if (IW.appGetVal('compliance_recipient_home_phone')) IW.appSetVal('recipient_home_phone', IW.appGetVal('compliance_recipient_home_phone'));
    if (IW.appGetVal('compliance_receiver_address')) IW.appSetVal('recipient_address1', IW.appGetVal('compliance_receiver_address'));

    if (!_.isEmpty(WARNINGS)) {
      IW.appSetVal('sigue_calculate_warning_big', '');
      IW.appSetVal('sigue_calculate_warning_small', WARNINGS.join('\n'));
      return 'sigue_warning';
    }

    return 'confirm_transfer'
  },

  sigue_compliance: function ({lastAction}) {
    if (lastAction === 'back') return 'enter_amount_transfer';
    Session.set('prev_current_active_field', 0);
    Session.set('current_active_field', 0);
    const {ADDITIONAL_INFORMATION} = IW.appGetVal('aml_required_fields');

    if (ADDITIONAL_INFORMATION.RECEIVER_PHONE) IW.appSetVal('compliance_recipient_home_phone', IW.appGetVal('recipient_home_phone'));
    if (ADDITIONAL_INFORMATION.RECEIVER_ADDRESS) IW.appSetVal('compliance_receiver_address', IW.appGetVal('recipient_address1'));

    return {
      back: 'enter_amount_transfer',
      next: function () {
        IW.appSetLocalVal('sigue_compliance_after', true);
        return 'redirect1'
      }
    }
  },

  // __sigue_compliance: function* ({lastAction}) {
  //   Session.set('prev_current_active_field', 0);
  //   Session.set('current_active_field', 0);
  //
  //   const {ADDITIONAL_INFORMATION, MESSAGE_RULE_CHECK} = IW.appGetVal('aml_required_fields') || {
  //     MESSAGE_RULE_CHECK: {},
  //     ADDITIONAL_INFORMATION: {}
  //   };
  //
  //   if (lastAction === 'back' && _.isEmpty(ADDITIONAL_INFORMATION)) return 'enter_amount_transfer';
  //   if (_.isEmpty(ADDITIONAL_INFORMATION)) return 'confirm_transfer';
  //
  //   if (ADDITIONAL_INFORMATION.SENDER_OCCUPATION_TYPE) {
  //     Session.set('occupation_type_list', []);
  //     yield* doCall('getOccupationType', null, Session.set.bind(Session, 'occupation_type_list'));
  //   }
  //
  //   if (ADDITIONAL_INFORMATION.RECEIVER_RELATIONSHIP_TO_BENEFICIARY) {
  //     Session.set('relationship_to_beneficiary_list', []);
  //     yield* doCall('getRelationshipToBeneficiary', null, Session.set.bind(Session, 'relationship_to_beneficiary_list'));
  //   }
  //
  //   if (ADDITIONAL_INFORMATION.RECEIVER_BIRTH_COUNTRY || ADDITIONAL_INFORMATION.SENDER_BIRTH_COUNTRY
  //     || ADDITIONAL_INFORMATION.SENDER_NATIONALITY || ADDITIONAL_INFORMATION.RECEIVER_NATIONALITY) {
  //     Session.set('countries_list', []);
  //     yield* doCall('getCountries', null, Session.set.bind(Session, 'countries_list'));
  //   }
  //
  //   if (ADDITIONAL_INFORMATION.SENDER_MONEY_ORIGIN) {
  //     Session.set('money_origin_list', []);
  //     yield* doCall('getMoneyOrigin', null, Session.set.bind(Session, 'money_origin_list'));
  //   }
  //
  //   return {
  //     back: 'enter_amount_transfer',
  //     next: function* () {
  //       yield* doCall('getAmlRules', null, IW.appSetVal.bind(null, 'aml_required_fields'))
  //       const {ADDITIONAL_INFORMATION, MESSAGE_RULE_CHECK, ISSUES, WARNINGS} = (IW.appGetVal('aml_required_fields') || {
  //         MESSAGE_RULE_CHECK: {},
  //         ADDITIONAL_INFORMATION: {},
  //         ISSUES: [],
  //         WARNINGS: [],
  //       });
  //       if(!_.isEmpty(ISSUES)) {
  //         IW.appSetVal('sigue_calculate_error_small', ISSUES.join('\n').replace(FIND_FIRST_300_REG, '$1...'));
  //         return 'sigue_error'
  //       }
  //       if (!((!ADDITIONAL_INFORMATION.SENDER_BIRTH_COUNTRY || (ADDITIONAL_INFORMATION.SENDER_BIRTH_COUNTRY && IW.appGetVal('compliance_sender_birth_country_id'))) &&
  //         (!ADDITIONAL_INFORMATION.SENDER_PLACE_OF_BIRTH || (ADDITIONAL_INFORMATION.SENDER_PLACE_OF_BIRTH && IW.appGetVal('compliance_sender_place_of_birth'))) &&
  //         (!ADDITIONAL_INFORMATION.SENDER_BIRTH_DATE || (ADDITIONAL_INFORMATION.SENDER_BIRTH_DATE && IW.appGetVal('compliance_sender_birth_date'))) &&
  //         (!ADDITIONAL_INFORMATION.SENDER_NATIONALITY || (ADDITIONAL_INFORMATION.SENDER_NATIONALITY && IW.appGetVal('compliance_sender_nationality_id'))) &&
  //         (!ADDITIONAL_INFORMATION.RECEIVER_BIRTH_COUNTRY || (ADDITIONAL_INFORMATION.RECEIVER_BIRTH_COUNTRY && IW.appGetVal('compliance_receiver_birth_country_id'))) &&
  //         (!ADDITIONAL_INFORMATION.RECEIVER_PLACE_OF_BIRTH || (ADDITIONAL_INFORMATION.RECEIVER_PLACE_OF_BIRTH && IW.appGetVal('compliance_receiver_place_of_birth'))) &&
  //         (!ADDITIONAL_INFORMATION.RECEIVER_BIRTH_DATE || (ADDITIONAL_INFORMATION.RECEIVER_BIRTH_DATE && IW.appGetVal('compliance_receiver_birth_date'))) &&
  //         (!ADDITIONAL_INFORMATION.RECEIVER_NATIONALITY || (ADDITIONAL_INFORMATION.RECEIVER_NATIONALITY && IW.appGetVal('compliance_receiver_nationality_id'))) &&
  //         (!ADDITIONAL_INFORMATION.SENDER_OCCUPATION_TYPE || (ADDITIONAL_INFORMATION.SENDER_OCCUPATION_TYPE && IW.appGetVal('compliance_occupation_type_id'))) &&
  //         (!ADDITIONAL_INFORMATION.RECEIVER_RELATIONSHIP_TO_BENEFICIARY || (ADDITIONAL_INFORMATION.RECEIVER_RELATIONSHIP_TO_BENEFICIARY && IW.appGetVal('compliance_relationship_to_beneficiary_id'))) &&
  //         (!ADDITIONAL_INFORMATION.SENDER_MONEY_ORIGIN || (ADDITIONAL_INFORMATION.SENDER_MONEY_ORIGIN && IW.appGetVal('compliance_sender_money_origin_id'))))) return 'none';
  //
  //       if (!_.isEmpty(MESSAGE_RULE_CHECK)) {
  //         IW.appSetVal('sigue_calculate_error_small', _.map(MESSAGE_RULE_CHECK, v => v.replace(/(.)\1{3,}/g, '$1')).join('\n').replace(FIND_FIRST_300_REG, '$1...'));
  //
  //         return 'sigue_error'
  //       }
  //
  //       if(!_.isEmpty(WARNINGS)) {
  //         IW.appSetVal('sigue_calculate_warning_small', WARNINGS.join('\n').replace(FIND_FIRST_300_REG, '$1...'));
  //         return 'sigue_warning';
  //       }
  //
  //       return 'confirm_transfer'
  //     }
  //   }
  // },


  sigue_error: function () {
    return {
      back: function () {
        IW.appSetDefaults(/sigue_calculate_error_/);
        return 'back';
      },
      cancel: 'exit',
      next: 'exit',
    }
  },

  sigue_warning: function () {
    return {
      back: function () {
        IW.appSetDefaults(/sigue_calculate_warning_/);
        return 'back';
      },
      cancel: 'back',
      next: 'confirm_transfer',
    }
  },

  sigue_warning_recipient: function () {
    return {
      back: function () {
        IW.appSetDefaults(/sigue_calculate_warning_/);
        return 'back';
      },
      next: () => {
        IW.appSetDefaults(/^payment_agent_/);
        IW.appSetDefaults(/^recipient_payment_mode_/);
        IW.appSetLocalVal('new_recipient', true);
        return 'confirm_payout'
      },
    }
  },
})

function* doCall(method, requestData, onData) {
  IW.appSetLocalVal(method + '_done', false);
  IW.call(method, requestData, (__, data) => {
    _.isFunction(onData) && onData(data);
    IW.appSetLocalVal(method + '_done', true);
  })
  yield 'loading app ' + method + '_done';
}

function* setRecipient(recipient) {
  IW.appSetDefaults(/^bank_/);
  IW.appSetLocalVal('sigue_calculate_warning_code', 0);
  IW.appSetLocalVal('sigue_calculate_warning_small', '');
  IW.appSetLocalVal('sigue_calculate_warning_big', '');
  IW.appSetVal('set_recipient_error', '');
  yield* doCall('setRecipient', recipient);
  if (IW.appGetVal('set_recipient_error')) {
    const e = IW.appGetVal('set_recipient_error');
    if (e === 'no_address') IW.appSetLocalVal('sigue_calculate_warning_small', 'City or country is not found for the recipient');
    if (e === 'no_payer') IW.appSetLocalVal('sigue_calculate_warning_small', 'Payment agent is not found for the recipient');
    if (e === 'no_location') IW.appSetLocalVal('sigue_calculate_warning_small', 'Payment location is not found for the recipient');
    if (e === 'no_currency') IW.appSetLocalVal('sigue_calculate_warning_small', 'Currency is not found for the recipient');
    if (e === 'calculation_failed') IW.appSetLocalVal('sigue_calculate_warning_small', 'Payment agent has internal issue');
    IW.appSetLocalVal('sigue_calculate_warning_big', 'Please enter new data');
    return 'sigue_warning_recipient';
  }
}

// TODO: Copy from app_moneytransfer_common
function confirmPayoutData() {
  IW.appSetVal('payer_details_confirm', {
    payer_bank_id: IW.appGetVal('payer_bank_id'),
    payer_bank_name: IW.appGetVal('payer_bank_name'),
    payer_name: IW.appGetVal('payer_name'),
    payer_id: IW.appGetVal('payer_id'),
    payer_rate: IW.appGetVal('payer_rate'),
    payer_branch_id: IW.appGetVal('payer_branch_id'),
    payer_sender_limit: IW.appGetVal('payer_sender_limit'),
    original_max_amount: IW.appGetVal('original_max_amount'),
    recipient_payment_mode_id: IW.appGetVal('recipient_payment_mode_id'),
  }, true)
}
