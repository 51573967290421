import React, {Component} from 'react';
import {initVerticalSlider, makeVerticalSlide} from 'imports/lib/helpers';
import __ from 'lodash';
import {gaEvent} from "imports/lib/ga";

export default class VerticalSlider extends Component {
  constructor(props) {
    super(props);

    this.sliderOptions = {
      scrollbarMargin: 0,
      scrollbarBackground: 'rgba(255, 255, 255, 0.12)',
      scrollbarWidth: '20px',
      scrollbarBorderRadius: '10px',
      scrollbarShadow: '0 10px 40px rgba(3, 19, 19, 0.15)',
      scrollbarContainer: '.scrollBar',
      keyboardControls: true,
      onSlideChange: props.onSlideChange || function () {
      },
      onSlideComplete: props.onSlideComplete || function () {
      },
      ...props.sliderOptions
    }
  }

  componentDidMount() {
    const {classSelector, minEls} = this.props;

    if (this.refs.slider) {
      this.slider = initVerticalSlider(this.sliderOptions, classSelector, minEls);
      makeVerticalSlide(~~this.props.slideNumber, classSelector);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {classSelector, minEls, items, slideNumber} = this.props;

    if (this.refs.slider && __.map(prevProps.items, 'key').join() !== __.map(items, 'key').join()) {
      this.slider = initVerticalSlider(this.sliderOptions, classSelector, minEls);

    }

    if (this.refs.slider && slideNumber !== prevProps.slideNumber && items.length > minEls) {
      makeVerticalSlide(~~slideNumber, classSelector);
    }
  }

  renderScrollBar() {
    const {showScrollBar, items, withArrows, scrollBarStyle = {}, minEls, gaCategory} = this.props;

    if (showScrollBar || items.length > minEls) {

      if (withArrows) {
        return (
          <div style={scrollBarStyle} className="scrollBar-container">
            <div className="vertical-slider-top" onClick={() => {
              if(gaCategory) gaEvent(gaCategory, 'Slide', 'Up');
              $('.iosVerticalSlider').iosSliderVertical('prevPage')
            }}/>
            <div className="vertical-slider-bottom" onClick={() => {
              if(gaCategory) gaEvent(gaCategory, 'Slide', 'Down');
              $('.iosVerticalSlider').iosSliderVertical('nextPage')
            }}/>
            <div className="scrollBar scrollBarWithArrows"/>
          </div>
        )
      }

      return (
        <div style={scrollBarStyle} className="scrollBar-container">
          <div className="scrollBar"/>
        </div>
      );
    }
    return null
  }

  render() {
    const {items} = this.props;

    return (
      <div ref="slider" className="iosVerticalSlider">
        <div className="slider">
          {items}
        </div>
        {this.renderScrollBar()}
      </div>
    )
  }
}
