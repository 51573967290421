import {actions, assert, register, state} from '../../lib/app_flow_testing'
import IW from 'imports/iw_api';

register({
  app_billpay_cHckF: {
    number_login: {
      should_deny_phone: function* () {
        assert.page('number_login')
        const page = state()
        yield actions.type('2222222222');
        actions.mousedown('#next', 'Number valid but next do not appear')
        yield actions.loading()
        if (state().page !== page) throw ('Invalid phone not denied')
      },


      should_continue_phone_2042121111: function* () {
        assert.page('number_login')
        yield actions.go('common.flow.should_continue_phone_2042121111');
      },
    },


    bill_scanning: {
      should_allow_new_biller: function* () {
        assert.page('bill_scanning')
        actions.mousedown('#next', 'New biller is not allowed')
        yield actions.loading()
      },

      should_allow_recent_account: function* () {
        assert.page('bill_scanning')
        yield actions.waitElement('.bill_scanning .app_icon');
        actions.click($('.bill_scanning .app_icon').eq(0), 'No accounts found')
        yield actions.loading()
      }
    },


    biller_search_by_name: {
      should_find_biller_amerigas: createShouldFindBiller('amerigas'),
      should_find_biller_oceanic: createShouldFindBiller('oceanic'),
    },

    info: {
      should_able_select_1: function* () {
        if (!(state().page === 'info' || state().page === 'info2')) throw ('It is not info page')
        actions.mousedown('td.info-page__delivery-payment a', 'No options')
        yield actions.loading()
      }
    },

    check_free_instruction: {
      should_able_agree: function* () {
        assert.page('check_free_instruction')
        yield actions.go('common.simple.should_able_next')
      }
    },

    inputs_billpayment: {
      should_able_continue_with_1345267891: createShouldAbleContinueWithAccount('1345267891'),
      should_able_continue_with_8448200015946949: createShouldAbleContinueWithAccount('8448200015946949'),
    },

    check_free_bill_amount: {
      should_able_enter_1_01: function* () {
        assert.page('check_free_bill_amount')
        yield actions.type('1.01');
        yield actions.go('common.simple.should_able_next')
      },
      should_able_enter_1_75: function* () {
        assert.page('check_free_bill_amount')
        yield actions.type('1.75');
        yield actions.go('common.simple.should_able_next')
      }
    },

    check_free_overpay: {
      should_see_message: function* () {
        assert.page('check_free_overpay')
        assert.regexp('.atm__temp-page__text', /DOES NOT MAKE CHANGE/);
        yield actions.go('common  simple  should_able_next')
      },
    },

    check_free_bill_required_data2: {
      should_able_enter_123451234: function* () {
        assert.page('check_free_bill_required_data2')
        yield actions.type('123451234');
        yield actions.go('common  simple  should_able_next')
      },
    },

    check_free_payout: {
      should_able_select_cash: function () {
        throw ('Deprecated')
        // assert.page('check_free_payout')
        // assert.regexp('.button-primary', /Cash/);
        // actions.mousedown('.button-primary', 'No cash after regexp');
        // yield actions.loading()
      },
    },

    check_free_delivery: {
      should_able_OK: function* () {
        if (state().page !== 'check_free_delivery') return;
        yield actions.go('common.simple.should_able_next')
      },
    }
  }
})

register({
  simple_cases: {
    app_billpay_cHckF: {
      launch_login_flow: function* () {
        IW.launchProduct(2275);
        yield actions.waitLoading();
        yield actions.go('app_billpay_cHckF | number_login           | should_continue_phone_2042121111');
        yield actions.go('common            | stored_sessions        | should_skip');
        yield actions.go('common            | pin_number_sing_in     | should_allow_pin_7777');
      },

      general_flow: function* () {
        yield actions.go('simple_cases      | app_billpay_cHckF      | launch_login_flow');

        yield actions.go('app_billpay_cHckF | bill_scanning          | should_allow_new_biller');
        yield actions.go('app_billpay_cHckF | biller_search_by_name  | should_find_biller_amerigas');
        yield actions.go('app_billpay_cHckF | info                   | should_able_select_1');
        yield actions.go('app_billpay_cHckF | check_free_instruction | should_able_agree');
        yield actions.go('app_billpay_cHckF | inputs_billpayment     | should_able_continue_with_1345267891');
        yield actions.go('app_billpay_cHckF | check_free_bill_amount | should_able_enter_1_01');
        yield actions.go('common            | simple                 | should_able_back');
        yield actions.go('common            | simple                 | should_able_clear');
        yield actions.go('app_billpay_cHckF | check_free_bill_amount | should_able_enter_1_01');
        yield actions.go('app_billpay_cHckF | check_free_overpay     | should_see_message');
        yield actions.go('common            | simple                 | should_able_back');
        yield actions.go('common            | simple                 | should_able_clear');
        yield actions.go('app_billpay_cHckF | check_free_bill_amount | should_able_enter_1_75');

        yield actions.go('app_billpay_cHckF | check_free_delivery    | should_able_OK');
        yield actions.go('common            | flow                   | should_menu_on_pay');

      },

      biller_zip_flow: function* () {
        yield actions.go('simple_cases      | app_billpay_cHckF              | launch_login_flow');

        yield actions.go('app_billpay_cHckF | bill_scanning                  | should_allow_new_biller');
        yield actions.go('app_billpay_cHckF | biller_search_by_name          | should_find_biller_oceanic');
        yield actions.go('app_billpay_cHckF | info                           | should_able_select_1');
        yield actions.go('app_billpay_cHckF | inputs_billpayment             | should_able_continue_with_8448200015946949');
        yield actions.go('app_billpay_cHckF | check_free_bill_amount         | should_able_enter_1_01');
        yield actions.go('app_billpay_cHckF | check_free_overpay             | should_see_message');
        yield actions.go('app_billpay_cHckF | check_free_bill_required_data2 | should_able_enter_123451234');

        yield actions.go('app_billpay_cHckF | check_free_delivery            | should_able_OK');
      },

      short_flow: function* () {
        yield actions.go('simple_cases      | app_billpay_cHckF              | launch_login_flow');

        yield actions.go('app_billpay_cHckF | bill_scanning                  | should_allow_recent_account');
        yield actions.go('app_billpay_cHckF | check_free_bill_amount         | should_able_enter_1_01');
        yield actions.go('app_billpay_cHckF | info                           | should_able_select_1');
        yield actions.go('app_billpay_cHckF | check_free_overpay             | should_see_message');

        yield actions.go('app_billpay_cHckF | check_free_delivery            | should_able_OK');
      }
    }
  }
})

function createShouldFindBiller(biller) {
  return function* () {
    assert.page('biller_search_by_name')
    yield actions.type(biller);
    yield actions.waitElement('a.biller_icon');
    actions.mousedown('a.biller_icon', 'Billers not found')
    yield actions.loading()
  }
}

function createShouldAbleContinueWithAccount(account) {
  return function* () {
    assert.page('inputs_billpayment')
    yield actions.type(account);
    actions.mousedown('#next', 'Account valid but Next not appear');
    yield actions.type(account);
    actions.mousedown('#next', 'Account valid but Next not appear');
  }
}