import {createContainer} from 'imports/lib/react_createContainer';
import getPageManager from 'imports/lib/page_manager';
import StartScanning from './StartScanning';
import IW from 'imports/iw_api';
import CONFIG from 'imports/config'

export default createContainer((params) => {
  return {
    previewUrl: CONFIG.PREVIEW_SERVER + '/' + IW.appGetVal('preview_key'),
    onBack: () => getPageManager().back(),
    onScanBill: () => getPageManager().scan(),
    isError: !IW.appGetVal('camera_preview_success'),
    canScan: IW.appGetVal('camera_preview_success')

    // isError: false,
    // canScan: true
  };

}, StartScanning);
