import app_common from './app_common';
import IW from 'imports/iw_api';
import CONFIG from 'imports/config';
import {nextWhen} from '../lib/page_manager';

// ["shopping_cart", "pay", "shopping_cart_loading", "final", "email", "sent"]
export default _.extend({}, app_common, {
  index: function () {
    return 'refund_info'
  },

  refund_payment_status: function () {
    if (IW.appGetVal('session_resumed')) return 'refund_info';
    IW.appSetLocalVal('without_pincode', true);
    IW.appSetVal('refund_allowed', true);
    IW.appSetVal('transaction_reverse_allowed', true);
    return 'refund_info';
  },

  refund_canceled_transaction: function () {
    IW.appSetLocalVal('without_pincode', true);
    IW.appSetVal('refund_allowed', true);
    IW.appSetVal('transaction_reverse_allowed', true);
    return 'refund_info';
  },

  refund_info: function* () {
    if (!CONFIG.REFUND_ENABLED) return 'exit';
    IW.callCashDispenser('status');
    IW.callBillAcceptor('status');
    yield 'loading app cash_dispenser_status_done';
    yield 'loading app coin_acceptor_status_done';

    if (!IW.appGetVal('load_dispense_done')) {
      IW.call('loadDispense');
      yield 'loading app load_dispense_done';
    }
    return {
      support: 'support_number',
      next: () => {
        if (!IW.appGetVal('load_dispense_success')) return 'exit';
        if (IW.appGetVal('without_pincode')) return 'refund_money';
        return 'confirm_with_pin_number'
      },
      cancel: 'exit'
    }
  },

  support_number: {
    back: 'back',
    next: function () {
      IW.call('doGetSupport');
      return 'sent_support'
    },
  },

  confirm_with_pin_number: function* () {
    IW.appSetLocalVal('error_message_code', '');
    IW.appSetLocalVal('pin_number', '');

    IW.sendCustomerPin();

    yield {
      back: 'refund_info',
      change: () => {
        IW.sendCustomerPin();
        return 'none';
      }
    };

    while (true) {
      yield nextWhen(() => {
        return IW.appGetVal('pin_code') && IW.appGetVal('pin_code').length === IW.appGetVal('pin_number').length
      });
      yield 'none';

      const pinNumber = IW.appGetVal('pin_number');
      IW.appSetLocalVal('pin_number', '');

      if (pinNumber === IW.appGetVal('pin_code')) {
        return 'refund_money';
      }

      IW.appSetLocalVal('error_message_code', 'invalid_pin');
    }
  },

  refund_money: function* () {
    Session.set('menu_disabled', true);
    IW.appSetLocalVal('extract_cash_done', false);
    IW.call('extractCash');
    yield 'loading app extract_cash_done';

    if (!IW.appGetVal('extract_cash_success')) {
      Session.set('menu_disabled', false);
      return 'internal_error';
    }

    IW.callBillAcceptor('dispense');
    IW.callCashDispenser('dispense');

    yield 'sleep app dispensing_done';
    Session.set('menu_disabled', false);
    return 'refund_money_success';
  },

  refund_money_success: function* () {
    // Popular products fetch for advertisement
    if (CONFIG.SHOW_POPULAR_PRODUCTS_ADVERTISEMENT) {
      IW.call('getPopularProductsId');
      yield 'loading app popular_products_id';
    }

    IW.printReceipt();
    return {
      cancel: 'exit',
      next: 'exit'
    }
  },

  internal_error: {
    cancel: 'exit',
    next: 'exit',
  },
});
