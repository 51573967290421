import Apps from '../collections/apps';
import I18n from '../collections/i18n';
import Links from '../collections/links';
import {getSocket} from './sockets';
import CONFIG from 'imports/config';
import {Session} from 'core/session';
import {getDevice} from '../api/device';
import {_ as i18n, createLazyExecution, createQueue, getUser, N, removeDot, S} from 'imports/lib/helpers';
import {FlowRouter} from 'core/Router';
import {clear as clearPageManager, goTo as goToPageManager} from 'imports/lib/page_manager';
import appWorkflow from 'imports/apps_workflow';
import Prods from 'imports/collections/products';
import createSessionMethods from 'imports/lib/createSessionMethods';
import createSessionLaunch from 'imports/lib/createSessionLaunch';
import * as u from 'underscore';
import iwbrowserApi from 'imports/lib/iwbrowserApi';

var retrievingReceiptTimer = 0;

// API Object with shared functions
const IW = {
  // I18n
  _: function (string, app_guid, type) {
    const socket = getSocket();
    const lo_CL = getLocale();

    if (!Session.get('i18n_version') || !I18n.find().count() || !string) {
      return string;
    }

    type = type || 'ui'

    if (lo_CL === 'en_US') {
      handleString('es_MX');
      const term = I18n.findOne({lang: 'en_US', original: string, type: type});
      if (term) {
        return term.translation || string;
      }
      return string;
    }

    return handleString(lo_CL);

    function handleString(locale) {
      // Prioritize the application value first
      const term = I18n.findOne({lang: locale, original: string, type: type});

      if (term) {
        return term.translation || string;
      }

      if (typeof socket !== 'undefined') {
        if (type !== 'product') {
          socketEmit('/i18n/missing', locale, string, '', type);
        }
        I18n.upsert(null, {lang: locale, original: string, type: type}) // prevent duplicates
        console.log('/i18n/missing', locale, string, type);
      }

      // If nothing matched in I18n, return the string as-is
      return string;
    }
  },

  setTranslation: function (original, translation, type) {
    const lo_CL = getLocale();

    if (!Session.get('i18n_version') || !I18n.find().count() || !original) {
      return original;
    }

    type = type || 'ui'

    const emit = (token) => {
      socketEmit('/i18n/update', lo_CL, original, type, translation, token, (err, res) => {
        if (err || res !== 'success') return window.localStorage.setItem('i18n_update_token', '');
        window.localStorage.setItem('i18n_update_token', token);
      });
    };

    let token = window.localStorage.getItem('i18n_update_token');
    if (!token) {
      socketEmit('/i18n/update/token');
      setTimeout(() => {
        token = window.prompt('Enter i18n token (you can find it in alerts)');
        emit(token)
      }, 200)
      return;
    }
    emit(token)
  },
  // Pull localization object from the server...
  getLocale: function (lo_CL, scope) { // deprecated...
    // if( typeof socket !== 'undefined' ) {
    //   socketEmit('/i18n/get', lo_CL, scope);
    //   console.log('/i18n/get', lo_CL, scope);
    // }
  },
  // Change the interface language
  setLocale: function (lo_CL) {
    Session.set('locale', lo_CL == CONFIG.DEFAULT_LANGUAGE ? '' : lo_CL);
  },

  // Front-end application installation
  appInstall: function (data) {
    Apps.upsert(data._id, data);
  },
  appLink: function (data) {
    Links.upsert(data._id, data);
  },
  appUninstall: function (id) {
    Apps.remove(id);
  },
  appLinkRemove: function (id) {
    Links.remove(id);
  },

  sessionSetVal: function (sessionKey, field, value) {
    console.log('==', sessionKey, field, value, IW.__debug_createStackTraceObj());
    if (typeof sessionKey !== 'string') console.error(Error('Invalid variable ' + sessionKey + ': use only string'));
    if (typeof value === 'undefined' && arguments.length < 3) {
      console.error(Error('Invalid variable ' + field + ': use appGetVal instead'));
    }

    const sessionData = Session.get(sessionKey);

    if (_.has(sessionData, field) && typeof sessionData[field] !== typeof value) {
      console.error(Error('Invalid variable ' + field + ': input ' + (typeof value) + ', expected ' + typeof sessionData[field]));
    }

    sessionData[field] = value;

    Session.set(sessionKey, sessionData);
  }, // Set field to session
  sessionSetObject: function (sessionKey, inputObject) {
    _.each(inputObject, (v, k) => {
      IW.sessionSetVal(sessionKey, k, v);
    });
  }, // Set object to session
  sessionGetVal: function (sessionKey, field) {
    if (arguments.length > 2) console.error(Error('Invalid variable: use sessionSetVal instead'));

    // Output the whole thing
    if (typeof field === 'undefined')
      return Session.get(sessionKey);

    const sessionValue = Session.get(`${sessionKey}.${field}`);

    if (typeof sessionValue === 'number')
      return sessionValue || 0;

    if (typeof sessionValue === 'object')
      return sessionValue || null;

    if (typeof sessionValue === 'boolean')
      return sessionValue || false;

    return sessionValue || '';
  }, // Get field value from session
  sessionClearVal: function (sessionKey, field) {
    let iterator = null;
    if (typeof field === 'function') {
      iterator = field;
    }
    if (typeof field === 'string') {
      iterator = (k) => k === field;
    }
    if (field instanceof RegExp) {
      iterator = (k) => field.test(k);
    }
    if (!iterator) return;
    const current_app_session_data = Session.get(sessionKey);

    const removeObj = {};
    _.each(_.filter(_.keys(current_app_session_data), iterator), (k) => (removeObj[k] = undefined));

    return removeObj;
  }, // Remove field from session

  // Application session methods
  appSetVal: function (field, value, isPrivate) {
    IW.sessionSetVal('app', field, value);

    // Emit the delta to the server
    if (isPrivate) return;

    const obj = {};
    obj[field] = value;

    return IW.setServerSession(obj);
  },
  appSetLocalVal(field, value) {
    return IW.sessionSetVal('app', field, value);
  },
  appSetObject(inputObject) {
    IW.sessionSetObject('app', inputObject);

    // Emit the delta to the server
    return IW.setServerSession(inputObject);
  },
  appSetObjectVal(objectField, inputObject) {
    IW.appSetVal(objectField, _.extend(IW.appGetVal(objectField), inputObject));
    IW.appSetObject(inputObject);
  },
  appGetVal: function (field) {
    return IW.sessionGetVal('app', field);
  },
  appClearVal: function (field) {
    const removeObj = IW.sessionClearVal('app', field);
    IW.appSetObject(removeObj);
  },
  appGetAndClearVal: function (field) {
    const val = IW.appGetVal(field);
    IW.appClearVal(field);
    return val;
  },

  appClearVals: function (backToProfile) {
    console.log('IW:appClearVals() called!');

    IW.trySessComplete();

    Session.set('session_guid', '');
    Session.set('app_guid', '');
    Session.set('app_icon', '');

    Session.set('app', {}); // reset the data from the current app session

    if (!backToProfile) {
      Session.set('session_active', false);
      Session.set('login', {});
    }

    Session.set('payer', {}); // reset the data from the payer login

    Session.set('icon', '');
    Session.set('title', '');
    Session.set('qr', '');
    Session.set('isAlertVisible', false);
    Session.set('phone_is_not_valid', false);
    Session.set('wp_result', null);
    Session.set('accounts', null);

    Session.set('user', null);

    Session.set('checked_mask', '');
    Session.set('last_amount', 0);
    Session.set('gifts_selected_id', 0);
    Session.set('pagination_width', '');
    Session.set('pagination_item_width', '');
    Session.set('session_completed', false);
    Session.set('overlay_state', '');
    Session.set('frame_loaded', false);
    Session.set('founded_billers', undefined);
    Session.set('retrieving_receipt', false);
    Session.set('retrieving_receipt_timeout', false);

    Session.set('camera_state', '');
    Session.set('payment_status_app', null);
    //ATM
    Session.set('menu_disabled', false);

    //Money transferring
    Session.set('payment_modes', null);
    Session.set('current_active_field', 0);
    Session.set('prev_current_active_field', 0);
    Session.set('money_transferring_payers', null);
    Session.set('recipients', '');
    Session.set("city_exists", false);
    Session.set("state_exists", false);

    //Cash a check
    Session.set('cash_check_customers', null);
    Session.set('cash_check_reviewing_sms', false);

    //Hardware
    Session.set('card_reader_status_done', '');
    Session.set('epp_status_done', '');
    Session.set('search_panel', false);
    $('#css').text('');

    //Login
    Session.set('entry_point_path', '');
    Session.set('customer_data_payments', null);
    clearPageManager();
  },

  appSetDefaults: function (field) {
    let iterator = null;
    if (typeof field === 'function') {
      iterator = field;
    }
    if (typeof field === 'string') {
      iterator = (k) => k === field;
    }
    if (field instanceof RegExp) {
      iterator = (k) => field.test(k);
    }
    if (!iterator) return;
    _.each(Session.get('app'), (v, k) => {
      if (iterator(k)) {
        switch (typeof v) {
          case 'number':
            return IW.appSetVal(k, 0);
          case 'string':
            return IW.appSetVal(k, '');
          case 'object':
            return IW.appSetVal(k, null);
          default:
            return IW.appSetVal(k, undefined);
        }
      }
    });
  },

  appGetStep: function (appPage) {
    /* deprecated */
  },

  appGetAccount: function () {
    if (IW.appGetVal('account1')) {
      return {
        account: IW.appGetVal('account1'),
        accountFieldName: 'Account Number'
      }
    }

    if (IW.appGetVal('account')) {
      return {
        account: IW.appGetVal('account'),
        accountFieldName: 'Account Number'
      }
    }

    if (IW.appGetVal('email')) {
      return {
        account: IW.appGetVal('email'),
        accountFieldName: 'Email'
      }
    }

    if (IW.appGetVal('number')) {
      return {
        account: IW.appGetVal('number'),
        accountFieldName: 'Phone Number'
      }
    }

    if (IW.appGetVal('sender_number')) {
      return {
        account: IW.appGetVal('sender_number'),
        accountFieldName: 'Phone Number'
      }
    }

    if (getUser() && getUser().phone) {
      return {
        account: getUser().phone,
        accountFieldName: 'Phone Number'
      }
    }

    if (getUser() && getUser().mobile_number) {
      return {
        account: getUser().mobile_number,
        accountFieldName: 'Phone Number'
      }
    }

    return {
      account: '',
      accountFieldName: ''
    }
  },

  //Login session methods
  loginSetVal: function (field, value) {
    const SM = IW.createSessionMethods('login');
    SM.setVal(field, value);
  },
  loginSetObject: function (inputObject) {
    const SM = IW.createSessionMethods('login');
    SM.setObject(inputObject);
  },
  loginSetObjectVal: function (objectField, inputObject) {
    const SM = IW.createSessionMethods('login');
    SM.setObjectVal(objectField, inputObject);
  },
  loginGetVal: function (field) {
    const SM = IW.createSessionMethods('login');
    return SM.getVal(field);
  },
  loginClearVal: function (field) {
    const SM = IW.createSessionMethods('login');
    SM.clearVal(field);
  },

  appLaunch: function (appGuid, options) {
    createSessionLaunch().application(appGuid, options, socketEmit);
  },

  loginLaunch: function (lang) {
    createSessionLaunch().login(lang);
  },

  loginLaunchInApplication() {
    createSessionLaunch().login_in_application();
  },

  profileLaunch: function () {
    if (!IW.loginGetVal('access_token')) {
      console.log('Access token not found');
      return IW.gotoIndex();
    }

    Session.set('session_guid', 'profile_stub_guid');

    let appGuid = 'app_profile_common';
    let startPage = 'on_login';

    let language = getLocale();

    goToPageManager(startPage, appGuid, '0', language)
  },

  launchTranslation: function () {
    let appGuid = 'app_translation_trans';
    let startPage = 'index';

    Session.set('session_guid', 'translation_stub_guid');
    Session.set('app_guid', appGuid)
    Session.set('app', {})
    Session.set('session_active', true)

    let language = Session.get('locale') || FlowRouter.getParam('language') || CONFIG.DEFAULT_LANGUAGE;

    goToPageManager(startPage, appGuid, '0', language)
  },


  call: function (method_name, data, callback) {
    const socket = getSocket();
    if (!method_name || !Session.get('session_guid')) {
      return console.error('method_name and session_guid are both required to make an API call to the server');
    }
    if (_.isFunction(data)) {
      callback = data;
      data = null;
    }
    console.log('IW.call:', method_name, data, IW.__debug_createStackTraceObj());
    if (socket) {
      IW.__debug_call_args = [method_name, data, callback];
      socketEmit('/s/call', Session.get('session_guid'), method_name, data, callback);
    }
  },

  __debug_call_args: null,
  __debug_recall() {
    socketEmit('/s/call', Session.get('session_guid'), IW.__debug_call_args[0], IW.__debug_call_args[1], IW.__debug_call_args[2]);
  },

  __debug_createStackTraceObj() {
    const stackTrace = Error()
    const stackTraceObj = {}
    Object.defineProperty(stackTraceObj, 'stackTrace', {
      get: function () {
        console.log(stackTrace)
      }
    });
    return stackTraceObj
  },

  callCacheable(method_name, data, callback) {
    cacheableCallState[method_name] = cacheableCallState[method_name] || {
      inQueue: createQueue(),
      store: {}
    };
    const hash = JSON.stringify(data);
    const {inQueue, store} = cacheableCallState[method_name];

    inQueue((done) => {
      if (store[hash]) {
        done();
        return callback(store[hash].data);
      }
      IW.call(method_name, data, (__, res) => {
        done();
        store[hash] = store[hash] || {};
        store[hash].data = res;
        clearTimeout(store[hash].timeout);
        store[hash].timeout = setTimeout(() => {
          delete store[hash];
        }, 120000);
        done();
        return callback(store[hash].data);
      });
    })();
  },

  callWithUpdate: function (method_name, field, data, defaultValue) {
    IW.call(method_name, data, (__, result) => {
      Session.set(field, result || defaultValue)
    });
  },

  callWithAppUpdate: function (method_name, field, data, defaultValue) {
    IW.call(method_name, data, (__, result) => {
      IW.appSetVal(field, result || defaultValue)
    });
  },

  callWithAppLocalUpdate: function (method_name, field, data, defaultValue) {
    IW.call(method_name, data, (__, result) => {
      IW.appSetLocalVal(field, result || defaultValue)
    });
  },

  callDevice: function (device, action, sessionGuid, data, callback) {
    if (!_.contains(['camera', 'bill_acceptor', 'card_reader', 'epp', 'cash_dispenser', 'system', 'check_reader'], device)) {
      throw Error('Unknown device ' + device);
    }
    if (typeof sessionGuid === 'function') {
      callback = sessionGuid;
      sessionGuid = null;
      data = null;
    }
    if (typeof data === 'function') {
      callback = data;
      data = sessionGuid;
      sessionGuid = null;
    }
    sessionGuid = sessionGuid || Session.get('session_guid');
    const socket = getSocket();
    if (action && sessionGuid) {
      console.log('IW.callDevice', device, ':', action, IW.__debug_createStackTraceObj());
      if (socket) {
        socketEmit('/s/' + device, sessionGuid, action, data, callback);
      }
    }
  },

  callDeviceForce: function (device, action, data, callback) {
    if (!_.contains(['card_reader'], device)) {
      throw Error('Unknown device ' + device);
    }

    const DEVICE = getDevice();
    if (!(DEVICE && DEVICE.DEVICE_GUID)) return;

    if (action && DEVICE.DEVICE_GUID) {
      console.log('IW.callDeviceForce', device, ':', action, IW.__debug_createStackTraceObj());
      socketEmit('/s/' + device + '/force', DEVICE.DEVICE_GUID, action, data, callback);
    }
  },

  callCamera: (...args) => IW.callDevice('camera', ...args),
  callBillAcceptor: (...args) => IW.callDevice('bill_acceptor', ...args),
  callCardReader: (...args) => IW.callDevice('card_reader', ...args),
  callCheckReader: (...args) => IW.callDevice('check_reader', ...args),
  callEPP: (...args) => IW.callDevice('epp', ...args),
  callCashDispenser: (...args) => IW.callDevice('cash_dispenser', ...args),
  callSystem: (...args) => IW.callDevice('system', ...args),

  callCardReaderForce: (...args) => IW.callDeviceForce('card_reader', ...args),

  // Request a PIN-code
  pinReq: function (number, length) {
    const socket = getSocket()
    console.log('IW.pinReq:', number, length)

    IW.appSetVal('code', '');

    var data = {
      session_guid: Session.get('session_guid'),
      number: number || IW.appGetVal('number'),
      digits: length || 5
    };

    socketEmit('/s/pin', data);
  },

  // Update session status in the database
  sessSetStatus: function (value) {
    var sessionGUID = Session.get('session_guid');
    if (!sessionGUID) {
      return;
    }
    const socket = getSocket()
    var data = {
      session_guid: sessionGUID,
      value: value
    };

    socketEmit('/s/update', data);
  },
  sessComplete: function (sessionGuid, appGuid) {
    const socket = getSocket()
    socketEmit('/s/complete', {
      app_guid: appGuid || Session.get('app_guid'),
      session_guid: sessionGuid || Session.get('session_guid')
    });
  },

  trySessComplete: function () {
    const sessionGuid = Session.get('session_guid');
    if (!sessionGuid) return;

    const appGuid = Session.get('app_guid');

    if (Session.get('cash_check_reviewing_sms')) {
      IW.call('notifyCustomer', {
        type: 'reviewing',
        support_number: IW.getUISettings().support_number
      }, () => {
        IW.sessComplete(sessionGuid, appGuid);
      });
      return;
    }

    const insertAmount = N(IW.appGetVal('amount'));
    const requestedAmount = N(IW.appGetVal('requested_amount'));
    const lastAmount = N(Session.get('last_amount'));
    if (!IW.appGetVal('paid') &&
      insertAmount > lastAmount &&
      requestedAmount > 0 &&
      insertAmount < requestedAmount) {
      IW.appSetVal('print_receipt_automatically', true);
      IW.call('storeSession', null, function () {
        IW.sessComplete(sessionGuid, appGuid);
      });
    } else {
      IW.sessComplete(sessionGuid, appGuid);
    }
    if (Session.get('card_inserting')) {
      Session.set('card_inserting', false);
      if (!(CONFIG.CARD_READER_ROUTINE && CONFIG.ATM_ENABLED)) IW.callCardReader('close', null, sessionGuid);
      IW.callEPP('close', null, sessionGuid);
    }
  },

  // Save session
  saveSession: function (data) {
    const socket = getSocket();
    const DEVICE = getDevice();
    if (!data) return;

    socketEmit('/s/save', {
      session_guid: Session.get('session_guid'),
      session_data: data,
      device_guid: DEVICE.DEVICE_GUID
    });
  },

  initPayment: function (amount, original_amount, x_data) {
    const socket = getSocket()
    x_data.locale = getLocale();

    var data = {
      session_guid: Session.get('session_guid'),
      customer_id: IW.appGetVal('customer_id') || 0,
      payment_guid: 'p_' + Random.id(),
      amount: amount || 0,
      original_amount: original_amount || amount || 0,
      product_name: Session.get('title'), // KILLME
      info: x_data,
      app_guid: Session.get('app_guid'),
    };

    IW.appSetVal('pay', data.payment_guid)

    socketEmit('/p/create', data);
  },
  paymentDo: function () {
    const socket = getSocket()
    console.log("[Debug] Payment DO object:", Session.get('session_guid'));
    socketEmit('/p/do', {
      app_guid: Session.get('app_guid'),
      session_guid: Session.get('session_guid')
    });
  },

  goCoinCreateInvoice: function (amount, currency) {
    const socket = getSocket()
    currency = currency || 'BTC';

    IW.appSetVal('gocoin_' + currency + '_token', Random.secret())

    var post = {
      session_guid: Session.get('session_guid'),
      payment_guid: IW.appGetVal('pay'),  // comes from IW.initPayment
      amount: amount,
      currency: currency,
      security_token: IW.appGetVal('gocoin_' + currency + '_token'),
    };

    socketEmit('/p/gocoin/invoice', post);
  },

  openCat: function (categoryId) {
    // if (!categoryId || categoryId === Session.get('last_category')) return;

    // TODO: Replace timeout
    Meteor.setTimeout(function () {
      FlowRouter.go("Category", {language: getLocale(), categoryName: categoryId});
    }, 50);

    IW.appClearVals(); // just in case...
    Session.set('last_category', categoryId);
  },

  mainMenu: function (lang) {
    const lastCategory = Session.get('last_category') || IW.getPriorityCategory();
    const entryPointPath = Session.get('entry_point_path');
    const isProfilePath = /app_profile_common/.test(entryPointPath);

    IW.appClearVals(isProfilePath);
    const language = getLocale(lang);

    if (entryPointPath) {

      if (isProfilePath) {
        return setTimeout(() => IW.profileLaunch(), 1);
      }

      return FlowRouter.go(entryPointPath, {language});
    }
    return FlowRouter.go("Categories", {language});
  },

  openTranslationTool: function () {
    IW.appClearVals(true);
    return setTimeout(() => IW.translationLaunch(), 1);
  },

  goToError: function (lang) {
    const entryPointPath = Session.get('entry_point_path');
    const isProfilePath = /app_profile_common/.test(entryPointPath);

    const language = getLocale(lang);

    FlowRouter.go("Error", {language});
    IW.appClearVals(isProfilePath)
  },

  gotoIndex: function () {
    if (FlowRouter.getRouteName() === 'Index') return;
    IW.appClearVals('index');
    Session.set('last_category', 0);
    //Session.set('layout', '');
    FlowRouter.go('Index');
  },
  gotoProfile: function () {
    const user = getUser();
    if (!user || !user.customer_id) throw Error('User is not valid:' + user);
    IW.appClearVals();
    FlowRouter.go("Profile", {
      language: getLocale(user.language),
      categoryName: Session.get('last_category') || IW.getPriorityCategory()
    });
  },
  log: function (message) {
    // TODO: Meteor.call cause infinite loop in v6.10
    //Meteor.call('log', message);
  },
  err: function (message) {
    // TODO: Meteor.call cause infinite loop in v6.10
    //Meteor.call('err', message);
  },
  // XXX get rid of this:
  validEmail: function (email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email)) return false;

    const possibleDomainEndings = ['.com', '.net', '.cl', '.co.uk', '.it', '.am', '.nu', '.zzn.com', '.or.cr', '.fr', '.be',
      '.pt', '.dk', '.hu', '.kg', '.cc', '.cz', '.se', '.hm', '.co.za', '.lv', '.de', '.bg', '.org', '.com.ar', '.com.au',
      '.net ', '.check.com', '.ru', '.rr.com', '.edu', '.ca', '.us', '.net.au', '.ch', '.com.br', '.az', '.ro', '.garfield.com',
      '.sk', '.es', '.nl', '.net.vg', '.biz', '.br', '.terra.com.gt', '.theboys.com', '.ie', '.to', '.te.pt', '.ee', '.si',
      '.women.com', '.st', '.com.kh', '.co.jp', '.egine.com', '.fm', '.at', '.gr', '.com.pe', '.usa.com', '.c3.hu', '.com.pk',
      '.et', '.lt', '.org.mk', '.uk.net', '.pl', '.li', '.com.py', '.uk.com', '.no.net', '.com.sg', '.co.il', '.com.mx',
      '.kz', '.tou.com', '.or.jp', '.fi', '.ne.jp', '.austria.com', '.bulgaria.com', '.byte.it', '.entrepeneurmag.com',
      '.freetown.com', '.hitthebeach.com', '.kmsp.com', '.md', '.org.uk', '.pf', '.pharmacy.com', '.r-o-o-t.com', '.salu.net',
      '.sisna.com', '.spaceports.com', '.vasarhely.hu', '.commail2molly.com', '.as', '.co', '.ml.org', '.lu', '.student.com',
      '.lk', '.com.uy', '.net.mx', '.ro.org', '.cn', '.comsmithemail.net', '.ney', '.no', '.sn', '.dynip.com', '.com.er',
      '.co.in', '.ac', '.com.co', '.com.ve', '.vn', '.bellsouth.net', '.co.yu', '.att.net', '.supernews.com', '.co.id',
      '.co.kr', '.co.nz', '.com.cn', '.com.hk', '.com.is', '.com.ph', '.com.ru', '.in', '.jp', '.com.lb', '.ddns.org',
      '.freeservers.com', '.findhere.com'];

    const [account, domain] = email.split('@');

    if (/(\..+)(|\..*)\1/.test(domain)) return false;
    if (domain.split('.').length >= 4) return false;

    for (let i = 0, l = possibleDomainEndings.length; i < l; i++) {
      const current = possibleDomainEndings[i]
      if (domain.length < current) continue
      const sliced = domain.slice(0, -current.length);
      if (domain.slice(-current.length) === current) {
        for (let j = 0; j < l; j++) {
          const current2 = possibleDomainEndings[j];
          if (domain.length >= (current.length + current2.length) && (sliced + '.').includes(current2 + '.') && !_.contains(possibleDomainEndings, domain.slice(-current.length - current2.length))) {
            return false;
          }
        }
      }
    }

    return true
  },

  takePhoto: function () {
    const socket = getSocket();
    var data = {
      session_guid: Session.get('session_guid')
    };

    socketEmit('/s/take_photo', data);
  },

  verifyNumber: function () {
    Session.set('wp_result', null);
    socketEmit('/wp/get', {phone_number: IW.appGetVal('phone_number')}, (_, result) => {
      Session.set('wp_result', !result ?
        {error: true} :
        result);
    });
  },

  verifyNumberSync: async function (number) {
    return await promisifiedSocketEmit('/wp/get', {phone_number: number})
  },

  sendMobileAppLinkSms: async function (number) {
    return await promisifiedSocketEmit('/u/sms/app', {phone_number: number})
  },

  /*********************************************
   How the form validation works:
   1. We get the html5 input regexp (from the server via product or hardcoded);
   2. Then using Randexp, I generate a 'dummy' string;
   3. We set maxlength="" property according to the length of that 'dummy';
   4. We generate a {{9999}}-{{999}} mask for the masking plugin
   5. We apply the mask to the input field
   6. Using the 'dummy' and the maxlength, we check the possible keys to press
   (via cloning an input with that value and running the .isValid() method on it);
   *********************************************/

  // Converts pattern="" attribute from <input> into a /regexp/
  html5patternToRegex: function (pattern) {
    pattern = pattern || '\\.*';

    // Replace all \ with \\, prepend ^ and append $
    return new RegExp('^' + pattern.replace(/\\/g, '\\\\') + '$');
  },

  // Extracts the float value from the given input
  parseNum: function (input) {
    if (!input) return 0;

    input = ("" + input).replace(/[^\d|.]/g, '').trim();

    return parseFloat(input) || 0;
  },

  // Return a valid beginning based on the provided simpexp_ch
  beginningByPattern: function (simpexp_ch, ch) {
    return simpexp_ch.split(ch)[0];
  },

  getUISettings: function () {
    return _.extend({}, CONFIG.PARTNER_SETTINGS, Session.get('ui_settings'));
  },

  getPath: function () {
    return (FlowRouter.current() || {}).path;
  },

  launchShoppingCart: function () {
    const user = getUser();
    if (!user || !user.customer_id) throw Error('User is not logged');
    // TODO: replace app_shoppingcart_dTres GUID
    IW.appLaunch('app_shoppingcart_dTres', {
      app_guid: "app_shoppingcart_dTres",
      icon: "icon",
      title: "Shopping Cart", // in the header
      name: "Shopping Cart", // in the list
      start_page: "shopping_cart",
      feed: 0,
      customer_id: user.customer_id,
    });

    goToPageManager('shopping_cart', 'app_shoppingcart_dTres', '0', getLocale(user.language));
  },

  launchReferrerDispense: function () {
    const user = getUser();
    if (!user || !user.customer_id) throw Error('User is not logged');
    // TODO: replace app_shoppingcart_dTres GUID
    IW.appLaunch('app_referrerdispense_rEfdi', {
      app_guid: "app_referrerdispense_rEfdi",
      icon: "icon",
      title: "Dispense", // in the header
      name: "Dispense", // in the list
      start_page: "index",
      feed: 0,
      customer_id: user.customer_id,
    });

    goToPageManager('index', 'app_referrerdispense_rEfdi', '0', getLocale(user.language));
  },

  launchRefund: function (data) {
    const sessionResumed = IW.appGetVal('session_resumed');
    IW.appLaunch('app_refund_RefNd', {
      app_guid: "app_refund_RefNd",
      icon: "icon",
      title: "Refund", // in the header
      name: "Refund", // in the list
      start_page: "index",
      feed: 0,
      refund_payment_guid: data.refund_payment_guid,
      customer_id: data.customer_id
    });

    IW.appSetLocalVal('session_resumed', sessionResumed);
    goToPageManager(data.start_page || 'index',
      'app_refund_RefNd', '0', getLocale(data.refund_session_locale));
  },

  launchPreviousSessions: function () {
    const appGuid = Session.get('app_guid');
    const data = {
      app_guid: appGuid,
      icon: Session.get('icon'),
      title: Session.get('title'),
      product_id: IW.appGetVal('product_id'), // product_id
      feed: IW.appGetVal('feed') || 0,
      session_source_guid: Session.get('session_guid')
    };
    IW.appLaunch(appGuid, data);
  },

  launchAppAfterLogin: function (o = {}, forceStartPage) {
    if (!(o.app_guid && o.product_id)) console.error(Error('Invalid arguments: ' + JSON.stringify(o)));

    Session.set('entry_point_path', FlowRouter.current().path);

    const options = {
      app_guid: o.app_guid,
      icon: o.icon,
      title: o.title,
      product_id: o.product_id,
      feed: IW.loginGetVal('feed') || 0,
      logged: true
    };

    function canOpenForceStartPage() {
      return appWorkflow[o.app_guid] && appWorkflow[o.app_guid][forceStartPage]
    }

    const startPage = canOpenForceStartPage() ? forceStartPage : 'on_login';

    if (o.session_guid) {
      options.session_source_guid = o.session_guid;
    }

    if (_.isNumber(o.customer_id)) {
      options.customer_id = o.customer_id;
    }

    IW.appLaunch(o.app_guid, options);

    IW.appSetLocalVal('customer_next_page', startPage);

    goToPageManager('redirect_customer', o.app_guid, o.product_id, getLocale());
  },

  launchATM: function (lang, startPage) {
    const page = startPage || 'insert_card';
    const appGuid = 'app_atm_dGer';

    const data = {
      app_guid: appGuid,
      name: 'ATM',
      title: 'ATM',
      start_page: page,
      feed: 0,
      icon: '',
      product_id: 0, // product_id
      page: page,
    };

    IW.appLaunch(appGuid, data);

    goToPageManager(page, appGuid, '0', getLocale(lang));
  },

  launchSweepstakes: function (data) {
    IW.appLaunch('app_sweepstakes_SwEta', {
      app_guid: "app_sweepstakes_SwEta",
      icon: "icon",
      title: "Sweepstakes", // in the header
      name: "Sweepstakes", // in the list
      start_page: "index",
      feed: 0,
      customer_id: data.customer_id
    });

    goToPageManager('index', 'app_sweepstakes_SwEta', '0', getLocale());
  },

  launchProduct: function (productId, startPage) {
    const link = Links.findOne({product_id: productId});
    if (!link) return false;

    startPage = startPage || link.start_page;

    setTimeout(function () {
      IW.appLaunch(link.app_guid, {
        icon: link.icon,
        title: link.title,
        product_id: link.product_id,
        page: startPage
      });
      goToPageManager(startPage, link.app_guid, S(productId), getLocale());
    });
    return true;
  },

  launchDebitDispense: function () {
    const user = getUser();
    if (!user || !user.customer_id) throw Error('User is not logged');
    IW.appLaunch('app_debitdispense_dEBdi', {
      app_guid: "app_debitdispense_dEBdi",
      icon: "icon",
      title: "Dispense", // in the header
      name: "Dispense", // in the list
      start_page: "index",
      feed: 0,
      customer_id: user.customer_id,
    });

    goToPageManager('index', 'app_debitdispense_dEBdi', '0',
      getLocale(user.language));
  },

  // TODO: ATM TEST
  checkPinATM: function () {
    socketEmit('/atm/check_pin');
  },

  loopback: function (action, data) {
    socketEmit('/s/loopback', Session.get('session_guid'), action, data); // => .on('/i/app/' + action, (data) => {})
  },

  handleError: function (message) {
    console.error(message)
    socketEmit('/d/error', message, Session.all());
    IW.goToError();
  },

  callCustomer: function (action, ...data) {
    const sessionGuid = Session.get('session_guid');

    if (action && sessionGuid) {
      console.log('IW.callCustomer:', action, ...data, IW.__debug_createStackTraceObj());
      socketEmit('/c/' + action, sessionGuid, ...data);
    }
  },

  initCustomer: (callback) => IW.callCustomer('create', IW.appGetVal('sender_number') || IW.appGetVal('number'), callback),
  checkCustomer: (number, callback) => IW.callCustomer('exists', number, callback),
  loadCustomerData: (callback) => IW.callCustomer('get_data', (err, data) => {
    if (err) return IW.mainMenu();
    _.each(data, (v, k) => v === null ?
      data[k] = '' :
      null);
    IW.appSetLocalVal('customer', data);
  }),
  sendCustomerPin: (callback) => IW.callCustomer('send_pin', callback),
  updateCustomer: (data, callback) => IW.callCustomer('update', _.extend({}, data, {
    // We do not support customers from other countries
    address_country: 'USA'
  }), callback),
  getCustomerStoredSessions: (callback) => IW.callCustomer('get_stored_sessions', callback),
  selectCustomerStoredSession: (itemId, callback) => IW.callCustomer('select_stored_session', itemId, callback),
  updateCustomerDiscount: (callback) => IW.callCustomer('update_discount', callback),
  checkCustomerReferralCode: (code, callback) => IW.callCustomer('check_referral_code', code, callback),
  applyCustomerReferralCode: (code) => IW.callCustomer('apply_referral_code', code),

  /**
   * @param {string} address
   * @param {string} state
   * @param {string} country
   * @param callback
   */
  getStreets: function (address, state, country, device_guid, callback) {
    socketEmit('/streets/get', {
      request: address,
      value: address,
      country,
      state,
      device_guid
    }, callback);
  },

  /**
   *
   * @param {string} address
   * @param callback
   */
  getZip: function (address, callback) {
    socketEmit('/zip/get', {request: address}, callback);
  },

  getLocationByAddress: function (address, callback) {
    socketEmit('/geocode/location', {request: address}, callback);
  },

  getAddressByLocation: function (locaction, callback) {
    socketEmit('/geocode/address', {request: locaction}, callback);
  },

  getPriorityCategory: function () {
    const categoriesTree = Session.get('categories_tree');
    if (!categoriesTree) return Session.get('priority_category') || 0;

    const category = _.first(_.sortBy(categoriesTree.categories, (c) => -c.selection_priority));
    if (category) {
      Session.set('priority_category', category.id);
      return category.id;
    }
    return Session.get('priority_category');
  },

  setServerSession(updateObject) {
    if (!Session.get('session_guid')) return;
    socketEmit('/s/set', Session.get('session_guid'), updateObject);
  },

  sendVoicePage: createLazyExecution((page, data) => {
    const DEVICE = getDevice();
    if (!(DEVICE && DEVICE.DEVICE_GUID)) return;

    socketEmit('/d/voice/page', {
      device_guid: DEVICE.DEVICE_GUID,
      page,
      data,
      lang: getLocale()
    });
  }, 100),

  setVoicePage(page, data) {
    Session.get('voice_page_update');
    voicePageState.overlay = false;
    if (Session.get('app_guid') && Session.get('app_guid') !== 'app_atm_dGer') {
      return IW.sendVoicePage('silence', data);
    }
    IW.sendVoicePage(page, data);
  },

  updateMenuVoicePage() {
    setTimeout(() => {
      const options = {press_1: i18n('ATM')}
      _.each($('.app_icon'), function (icon, i) {
        if (i > 7) return;
        i += 2;
        options['press_' + i] = $(icon).text();
      });
      IW.setVoicePage('menu', options);
    }, 10); // After rerender
  },

  setOverlayVoicePage(page, data) {
    voicePageState.overlay = true;
    IW.sendVoicePage(page, data);
  },

  resetVoicePage() {
    if (voicePageState.overlay) Session.set('voice_page_update', Math.random());
  },

  callSSO: async function (path, params, sessionScope = 'login') {
    const result = await promisifiedSocketEmit(`/sso/${path}`,
      _.extend({}, params, {session_guid: Session.get('session_guid')}));

    const SM = IW.createSessionMethods(sessionScope);
    SM.setVal(`${path}_result`, result);

    return result;
  },

  callSSOCustomerData: async function (params, sessionScope = 'login') {
    const result = await promisifiedSocketEmit(`/sso/customer_data`,
      _.extend({}, params, {session_guid: Session.get('session_guid')}));

    const SM = IW.createSessionMethods(sessionScope);
    const payments = result.payments;
    result.payments = null;
    SM.setVal(`customer_data_result`, result);
    Session.set('customer_data_payments', payments);

    return result;
  },

  isVirtual() {
    return CONFIG.VIRTUAL_ENABLED;
  },

  isTestMode: function () {
    return Session.get('TEST_MODE')
  },

  isClerk() {
    return false;
  },

  isClerkLogged() {
    return false;
  },

  canRefund() {
    return CONFIG.REFUND_ENABLED && (!IW.appGetVal('payment_refunded') || IW.appGetVal('refund_allowed'))
  },

  printReceipt() {
    if (!IW.isVirtual()) {
      return IW.call('printReceipt');
    }

    IW.call('getReceiptUrl', (_, url) => {
      IW.openReceipt(url)
    })
  },

  printFromWeb(url) {
    const options = {
      printable: `${url}/png`,
      type: 'image',
      onLoadingStart: () => {
        retrievingReceiptTimer = setTimeout(() => {
          Session.set('retrieving_receipt', false);
          Session.set('retrieving_receipt_timeout', true)
        }, 25000);
        Session.set('retrieving_receipt', true);
      },
      onLoadingEnd: () => {
        clearTimeout(retrievingReceiptTimer);
        Session.set('retrieving_receipt', false);
      }
    };

    printJS(options);
  },

  openReceipt(url) {
    IW.downloadLink(`receipt_${url.match(/\/r\/(\w+)/)[1]}.pdf`, `${url}/pdf`)
  },

  downloadLink(name, url) {
    var element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', name);
    element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  },

  createSessionMethods(scope) {
    if (!scope) {
      return console.error(Error(`Not valid arguments ${scope}`));
    }

    return createSessionMethods(scope)
  },

  async initProduct(product_id) {
    if (!u.isNumber(product_id)) return console.error(Error(`Not valid arguments ${product_id}`));

    let result = Prods.findOne(product_id);

    if (!result) {
      console.log('Product not found in current product set.');
      console.log(`Initializing product ${product_id} from server ...`);

      result = await promisifiedSocketEmit('/u/product/get', {product_id});
      if (!result) {
        console.error('Product not found on server', product_id);
        return IW.mainMenu();
      }

      result = removeDot(result);

      result._id = N(result.product_id);

      Prods.upsert(result._id, result);
    }

    console.log('Initialized product =>', result);
    return result;
  },

  logoutClerk() {
  },

  takeScreenShot(sessionGuid) {
    if (CONFIG.SCREEN_CAPTURE !== true) return;
    if (IW.isVirtual()) return;
    sessionGuid = sessionGuid || Session.get('session_guid');
    if (!/^s_/.test(sessionGuid)) return;
    if (!canCreateScreenshot(sessionGuid)) return;
    iwbrowserApi.saveScreenshot(`/opt/iw/tmpfs/screenshot_${sessionGuid}_${Date.now()}.jpg`, (res) => {
    });
  },

  isSessionActive() {
    return !!Session.get('session_guid');
  },

  getSocket() {
    return getSocket();
  }
};

export default IW;
window.IW = IW;
window.CONFIG = CONFIG;

const serverSession = {};
const voicePageState = {};
const cacheableCallState = {};

function socketEmit(action, ...args) {
  function emitSessionUpdate() {
    const sessionGuid = serverSession.sessionGuid;
    const sessionData = _.extend({}, serverSession.updateObject);
    asyncRun((done) => {
      console.log('Emitting /s/set', sessionGuid, sessionData);
      socket.emit('/s/set', sessionGuid, sessionData, done);
    });
    serverSession.updateObject = null;
    serverSession.lastEmit = Date.now();
  }

  const socket = getSocket();
  if (!socket) return;

  if (action === '/s/set') {
    serverSession.sessionGuid = args[0];
    serverSession.updateObject = _.extend({}, serverSession.updateObject, args[1]);
    clearTimeout(serverSession.timeout);
    // do not send immediately
    if (Date.now() - N(serverSession.lastEmit) > 500) {
      emitSessionUpdate();
      return;
    }
    serverSession.timeout = setTimeout(emitSessionUpdate, 100);
    return;
  }

  if (!_.isEmpty(serverSession.updateObject)) {
    clearTimeout(serverSession.timeout);
    emitSessionUpdate();
  }

  let callback = null
  if (_.isFunction(_.last(args))) {
    callback = args.pop();
    asyncRun((done) => {
      console.log('Emitting', action, ...args);
      socket.emit(action, ...args, (...res) => {
        callback(...res);
        done();
      });
    });
  } else {
    asyncRun((done) => {
      console.log('Emitting', action, ...args);
      socket.emit(action, ...args);
      done();
    });
  }
}

function promisifiedSocketEmit(action, ...args) {
  return new Promise(function (resolve, reject) {
    socketEmit(action, ...args, (_, result) => {
      // if(err) reject(err);
      resolve(result)
    })
  })
}

const asyncRunQueue = createQueue();

function asyncRun(callback) {
  function end(done) {
    if (endCalled) return;
    endCalled = true;
    clearTimeout(timeout);
    done();
  }

  let endCalled = false;
  let timeout = null;
  asyncRunQueue((done) => {
    callback(() => end(done));
    timeout = setTimeout(() => end(done), 3000); //ignore too long callback
  })();
}

const screenshotCounters = {};

function canCreateScreenshot(sessionGuid) {
  const maxCount = Math.floor(80000 / 130) // 130kb images up to ~80Mb on disk
  const sessionScreenshotsLifetime = 60 * 60 * 1000;
  _.each(screenshotCounters, (c, k) => {
    if (c.lastScreenshot + sessionScreenshotsLifetime > Date.now()) return;
    delete screenshotCounters[k]
  });
  const currentCount = _.reduce(screenshotCounters, (s, c) => s + c.count, 0);
  if (currentCount > maxCount) return false;
  const c = screenshotCounters[sessionGuid] = screenshotCounters[sessionGuid] || {count: 0};
  c.count++;
  c.lastScreenshot = Date.now();
  return true;
}

function getLocale(lang) {
  return lang || FlowRouter.getParam("language") || Session.get('locale') || CONFIG.DEFAULT_LANGUAGE
}