import {createContainer} from 'imports/lib/react_createContainer';
import ConfirmPhoto from './СonfirmPhoto'
import getPageManager from 'imports/lib/page_manager'
import IW from 'imports/iw_api';

export default createContainer((params) => {
  function formatUrl(url) {
    return url + '?' + Date.now()
  }

  function getType(type) {
    if (type === 'bill') return {
      imagePaths: [IW.appGetVal('image_path')],
      message: 'Do you see a biller name and an account number in this photo?'
    };

    if (type === 'customer') return {
      imagePaths: [IW.appGetVal('image_path')],
      message: 'This is your photo. Please confirm.'
    };

    if (type === 'id_scan') return {
      imagePaths: [formatUrl(IW.appGetVal('image_path_front'))],
      message: 'Make sure ID is visible and right side up.',
      onRotate: () => IW.callCheckReader('rotate_image', update),
      onFlip: () => IW.callCheckReader('flip_image', update),
    };

    if (type === 'check_scan') return {
      imagePaths: [formatUrl(IW.appGetVal('image_path_front'))],
      message: 'Make sure check info is visible and right side up.',
      onRotate: () => IW.callCheckReader('rotate_image', update),
      onFlip: () => IW.callCheckReader('flip_image', update),
    };

    if (type === 'bill_scan') return {
      imagePaths: [formatUrl(IW.appGetVal('image_path_front'))],
      message: 'Make sure bill info is visible and right side up.',
      onRotate: () => IW.callCheckReader('rotate_image', update),
      onFlip: () => IW.callCheckReader('flip_image', update),
    };
  }

  function update(){
    IW.appSetLocalVal('__update', Math.random());
  }

  IW.appGetVal('__update');

  return {
    onBack: () => getPageManager().back(),
    onNext: () => getPageManager().next(),
    imagePaths: [],
    message: '',
    ...getType(params.type)
  };

}, ConfirmPhoto);
