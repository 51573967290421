import {createContainer} from 'imports/lib/react_createContainer';
import {getUser, C} from 'imports/lib/helpers';
import ProfileSelect from './ProfileSelect';
import IW from 'imports/iw_api';
import getPageManager, {goTo} from 'imports/lib/page_manager';

export default createContainer((params) => {

  const loginData = IW.loginGetVal('customer_data_result') || {};

  const onNext = (type) => {
    IW.loginSetVal('profile_type', type);

    return getPageManager().next()
  };

  const onSkip = () => {
    return getPageManager().skip()
  };

  return {
    role: loginData.role,
    firstName: loginData.first_name,
    onNext,
    onSkip
  }
}, ProfileSelect);