import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import IW from 'imports/iw_api';
import {C, N} from 'imports/lib/helpers'
import CONFIG from 'imports/config'
import BillAmountContainer from './BillAmountContainer';

export default createContainer((params) => {
  return {
    isValid: (v) => N(v) > 0 && N(v) <= CONFIG.CASH_CHECK_MAX_DISPENSE,
    valid: CONFIG.CASH_CHECK_MAX_DISPENSE >= IW.appGetVal('original_amount') &&
      IW.appGetVal('original_amount') >= CONFIG.CASH_CHECK_MIN_DISPENSE,
    maxAmount: C(CONFIG.CASH_CHECK_MAX_DISPENSE, '$'),
    title: 'Amount on the Check'
  };

}, BillAmountContainer);
