import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import Quick from './Quick';


export default createContainer((params) => {

  return {};

}, Quick);
