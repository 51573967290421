import React from 'react';

const imageIsAbsent = {}
const maxTime = 5*60*1000 // 5 min

export default class Image extends React.Component {
  constructor(arg){
    super(arg);
    this.state = {error: false}
  }

  render() {
    if (this.state.error || (+new Date() - (imageIsAbsent[this.props.src] || 0) < maxTime)) {
      // key={1} forces to update full <img> (bug of iwbrowser to render borders if 404)
      return <img key={1} {...this.props} src={this.props.default}/>
    }

    return (
      // Store image path as absent, to prevent additional checks
      <img key={0} {...this.props} onError={() => (imageIsAbsent[this.props.src] = +new Date(), this.setState({error: true}))}/>
    )
  }
}

Image.propTypes = {
  src: React.PropTypes.string,
  default: React.PropTypes.string,
};

Image.defaultProps = {
  src: "",
  default: "",
};