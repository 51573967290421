import {Session} from 'core/session';
import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import RefundInfo from './RefundInfo';
import IW from 'imports/iw_api';
import getPageManager from 'imports/lib/page_manager';
import {_ as i18n, C} from 'imports/lib/helpers';
import {sprintf} from 'imports/lib/external/sprintf';

export default createContainer((params) => {

  let reason = '';
  if (IW.appGetVal('dispense_status') === 'no_money') {
    reason = 'There is no enough money'
  }

  if (IW.appGetVal('dispense_status') === 'not_allowed') {
    reason = 'The refund is not allowed'
  }

  if (IW.appGetVal('dispense_status') === 'not_found') {
    reason = 'Your payment was not found'
  }

  if (IW.appGetVal('dispense_status') === 'invalid_amount') {
    reason = 'No cash left for refund'
  }

  if (IW.appGetVal('dispense_status') === 'not_ready') {
    reason = 'Device is not ready'
  }

  if (IW.appGetVal('dispense_status') === 'cannot_reverse') {
    reason = 'The transaction cannot be reversed'
  }

  let message = '';
  if (IW.appGetVal('refund_request_amount') === IW.appGetVal('refund_amount')) {
    message = i18n('Your transaction has been cancelled, please collect your refund');
  }

  if (IW.appGetVal('refund_request_amount') > IW.appGetVal('refund_amount') && IW.appGetVal('refund_amount') > 0) {
    if (IW.appGetVal('customer_new_balance') - IW.appGetVal('customer_balance') > 0){
      message = sprintf([
          i18n('Your transaction has been cancelled but there is not enough money to dispense complete refund.'),
          i18n('We will dispense %s and the remainder %s will be added to your credit balance.'),
          i18n('Sign in to check your account.'),
          '\n',
          '\n',
          i18n('Your current credit balance: %s')
        ].join(' '),
        C(IW.appGetVal('refund_amount'), '$'),
        C(IW.appGetVal('customer_new_balance') - IW.appGetVal('customer_balance'), '$'),
        C(IW.appGetVal('customer_balance'), '$'));
    } else {
      const fromBalance = IW.appGetVal('customer_balance') - IW.appGetVal('customer_new_balance');
      message = sprintf([
          i18n('Your transaction has been cancelled but there is not enough money to dispense complete refund.'),
          i18n('We will dispense %s (%s for your transaction and %s from the balance).'),
          i18n('Sign in to check your account.'),
          '\n',
          '\n',
          i18n('Your current credit balance: %s')
        ].join(' '),
        C(IW.appGetVal('refund_amount'), '$'),
        C( IW.appGetVal('refund_amount') - fromBalance, '$'),
        C( fromBalance, '$'),
        C(IW.appGetVal('customer_balance'), '$'));
    }
  }

  if (IW.appGetVal('refund_amount') === 0) {
    message = sprintf([
        i18n('Your transaction has been cancelled but there is not enough money to dispense complete refund.'),
        i18n('We will add %s to your credit balance.'),
        i18n('Sign in to check your account.'),
        '\n',
        '\n',
        i18n('Your current credit balance: %s')
      ].join(' '),
      C(IW.appGetVal('customer_new_balance') - IW.appGetVal('customer_balance'), '$'),
      C(IW.appGetVal('customer_balance'), '$'));
  }

  return {
    message: message,
    canNotRefundReason_: reason,
    onRefund: () => getPageManager().next(),
    onMenu: () => getPageManager().cancel(),
    onSupport: () => getPageManager().support()
  };

}, RefundInfo);