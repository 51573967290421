import React from 'react';
import Button from 'imports/ui/shared/button/Button';

export default class MainHeader extends React.Component {
  render() {
    return (
      <tr className="main">
        <td>
          <Button id="menu" className="button button-header button-danger" onMouseDown={this.props.onIndexSelected} text_="Menu"/>
        </td>
        <td>
          <div className="logo-container">
            <a className="partner logo"/>
          </div>
        </td>
        <td>
          <Button id="search" className="button button-header button-confirm" text_="Search" onMouseDown={this.props.onSearchSelected}/>
        </td>
      </tr>
    )
  }
}

MainHeader.propTypes = {
  onIndexSelected: React.PropTypes.func.isRequired,
  onSearchSelected: React.PropTypes.func.isRequired
};
