import {createContainer} from 'imports/lib/react_createContainer';
import Choice from './Choice';
import {SLIDE_ITEM_TYPES} from 'imports/lib/constants';
import Countries from 'imports/collections/countries';

import getPageManager from 'imports/lib/page_manager'
import {countInDomBillSearch, N} from '../../../lib/helpers';
import IW from '../../../iw_api';

export default createContainer(() => {
  function convertToItem(account, i) {
    if (N(account.original_amount) > N(IW.appGetVal('customer_limit_amount'))) {
      return null;
    }

    const country = Countries.findOne({iso2: account.recipient_country_iso});
    return {
      account_id: account.account_id,
      name: account.recipient_number,
      iconPerson: '/resources/images/login/image_holder_transparent.png',
      iconCountry: country ? '/resources/icons/mt/' + country.iso3 + '.png' : null,
      amount: account.original_amount,
      icon: account.icon,
      prod: String(account.product).replace('.', '@'),
    }
  }

  const ROWS_COUNT = countInDomBillSearch();
  const ITEM_COUNT = 3;

  const accounts = Session.get("accounts") || [];

  console.log(accounts);
  return {
    title: 'Select account',
    skipButton: 'Another Account',
    sliderItemType: SLIDE_ITEM_TYPES.CARD,
    onBack: () => getPageManager().back(),
    onSkip: () => getPageManager().skip(),
    items: _.map(accounts, convertToItem)
      .chunk(ROWS_COUNT * ITEM_COUNT)
      .map((item) => item.complete(ROWS_COUNT * ITEM_COUNT, {}).chunk(ITEM_COUNT))
  };
}, Choice);
