import {createContainer} from 'imports/lib/react_createContainer';
import ListSlider from './ListSlider';
import getPageManager from 'imports/lib/page_manager';
import {capitalize, countInDomAmounts} from 'imports/lib/helpers';
import IW from '../../../iw_api';

export default createContainer(function () {
  const ROWS_COUNT = countInDomAmounts();
  const ITEM_COUNT = 3;

  const products = IW.appGetVal('product_list');

  const items = _.map(products, function (item) {
    return {
      _id: item,
      product_name: item,
      enabled: true,
      product_type: 'digital_mint_product',
      app_guid: 'app_cryptocoin_DgtMt',
      icon: '/resources/images/atm/new/ic_amount.svg',
      iconDefault: '/resources/images/atm/new/ic_amount.svg',
      name: item,
    }
  });

  const choices = items.chunk(ROWS_COUNT * ITEM_COUNT).map((item) => item.complete(ROWS_COUNT * ITEM_COUNT, {}).chunk(ITEM_COUNT));

  return {
    choices,
    title: 'Select product to purchase:',
    onBack: () => getPageManager().back(),
  }
}, ListSlider);