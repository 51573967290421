import {createContainer} from 'imports/lib/react_createContainer';
import Choice from './Choice';
import {Session} from 'core/session';
import {MONEY_TRANSFER_PAYMENT_MODES as MODES, SLIDE_ITEM_TYPES} from 'imports/lib/constants';
import {capitalize, countInDomRecipientChoice, S} from 'imports/lib/helpers';
import getPageManager from 'imports/lib/page_manager'
import _ from 'lodash';

export default createContainer(() => {
  function convertToItem(receipient, i) {
    const createRecipientName = _.compact([
      receipient.recipient_first_name,
      receipient.recipient_middle_name,
      receipient.recipient_last_name,
      receipient.recipient_mother_name,
    ]).join(' ').replace(/\s+/g, ' ');

    const paymentMode = MODES[receipient.recipient_payment_mode_type] || {};
    const paymentModeName = S(paymentMode.name);
    const country = S(receipient.recipient_address_country).toLowerCase().replace(/\s/g, '_');
    const payerNameField = S(receipient.payer_name).toLowerCase().replace(/\s/g, '');

    const payerIcon = '/resources/icons/payout/' + [paymentModeName, country, payerNameField].join('/') + '.png';
    const defaultPayerIcon = `/resources/icons/payout/${paymentModeName}/default.png`;

    return {
      id: i,
      name: createRecipientName,
      subname: capitalize(receipient.recipient_city_name || ""),
      icon: '/resources/images/login/image_holder_transparent.png',
      subicon: payerIcon,
      defaultPayerIcon,
      productIcon: Session.get('icon')
    }
  }

  const ROWS_COUNT = countInDomRecipientChoice();
  const ITEM_COUNT = 4;

  const recipients = Session.get("recipients") || [];

  return {
    title: 'Select recipient',
    skipButton: 'Another Recipient',
    sliderItemType: SLIDE_ITEM_TYPES.CUSTOMER,
    onBack: () => getPageManager().back(),
    onSkip: () => getPageManager().skip(),
    items: _.map(recipients, convertToItem).chunk(ROWS_COUNT*ITEM_COUNT).map((item) => item.complete(ROWS_COUNT*ITEM_COUNT, {}).chunk(ITEM_COUNT))
  };
}, Choice);
