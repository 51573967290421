import {Session} from 'core/session';
import IW from 'imports/iw_api';
import app_common from './app_common';
import createNumber from "./common/number";
import PageWatcher from './common/PageWatcher';
import CONFIG from 'imports/config';
import __ from 'lodash';

const checkReaderWatcher = new PageWatcher(
  (sessionGuid, options) => {
    if (options.scanId) {
      IW.callCheckReader('receive_id', sessionGuid)
    } else {
      IW.callCheckReader('receive_check', sessionGuid)
    }
  },
  (sessionGuid) => IW.callCheckReader('close', sessionGuid),
  ['cash_check_insert_id', 'loading', 'cash_check_insert_check', 'confirm_photo_check_scan', 'cash_check_review',
    'cash_check_wait_review', 'cash_check_amount_info', 'redirect1', 'extract_money', 'cash_check_success',
    'cash_check_return_check', 'redirect4']
);

const cameraWatcher = new PageWatcher(
  (sessionGuid) => IW.callCamera('preview_face', sessionGuid),
  (sessionGuid) => {
    IW.callCamera('stop', sessionGuid);
    IW.callCamera('scan', sessionGuid);
  },
  ['start_take_photo', 'loading']
);

export default _.extend({}, app_common, {
  index: () => 'number_login',

  // index: function*(){
  //   IW.call('debug_copySession', 's_gMJvw');
  //   IW.appSetVal('create_new_customer', true);
  //   yield 'sleep 1000';
  //   return 'cash_check_confirm_data'
  // },

  number_login: createNumber('exit', 'redirect2', {allowLandline: true}),

  on_login: function () {
    const phoneNumber = IW.appGetVal('phone_number');

    if (!phoneNumber) {
      const customerData = IW.loginGetVal('customer_data_result');
      const customerPhoneNumber = customerData.mobile_number;

      IW.appSetVal('phone_number', customerPhoneNumber);
      IW.appSetVal('number', customerPhoneNumber)
    }

    IW.appSetLocalVal('logged', true);
    return 'redirect2';
  },

  // redirect: function({lastAction}){
  //   if (lastAction === 'back') return 'number_login';
  //   return 'on_launch_login';
  // },

  redirect2: function* () {
    IW.appSetDefaults((k) => (/check_/).test(k) || (/image_/.test(k)) || (/^s_customer_/).test(k));
    IW.appSetLocalVal('s_customer_id_found', false);
    IW.appSetVal('s_customer_cell_phone', IW.appGetVal('phone_number').replace(/[^\d]/g, ''));

    IW.call('doCustomerSearch', {
      query: IW.appGetVal('phone_number').replace(/[^\d]/g, ''),
      offset: 0,
      limit: 1,
      all: true
    }, (__, r) => {
      IW.appSetVal('s_customer_id', (r && !_.isEmpty(r.s_customer_list) && r.s_customer_list[0].id) || '');
      IW.appSetLocalVal('s_customer_id_found', true);
    });
    yield 'loading app s_customer_id_found';

    // if (!IW.appGetVal('logged') && IW.appGetVal('s_customer_id')) {
    //   return 'redirect';
    // }

    IW.appSetLocalVal('logged', false);

    if (!IW.appGetVal('s_customer_id')) {
      IW.appSetLocalVal('create_new_customer', true);
      IW.appSetVal('s_customer_id', '');
      IW.appSetVal('s_customer_edit_mode', false);

      const loginScope = Session.get('login');
      const customerData = __.get(loginScope, 'customer_data_result', {});

      IW.appSetVal('s_customer_address', __.get(customerData, 'address', ''));
      IW.appSetVal('s_customer_address1', __.get(customerData, 'address1', ''));
      IW.appSetVal('s_customer_address_city', __.get(customerData, 'address_city', ''));
      IW.appSetVal('s_customer_address_state', __.get(customerData, 'address_state', ''));
      IW.appSetVal('s_customer_address_zip', __.get(customerData, 'address_zip', ''));
      IW.appSetVal('s_customer_cell_phone', __.get(loginScope, 'phone_number', '').replace(/[^\d]/g, ''));
      IW.appSetVal('s_customer_dob', __.get(customerData, 'date_of_birth', ''));
      IW.appSetVal('s_customer_first_name', __.get(customerData, 'first_name', ''));
      IW.appSetVal('s_customer_last_name', __.get(customerData, 'last_name', ''));
      IW.appSetVal('s_customer_middle_name', __.get(customerData, 'middle_name', ''));

      return 'cash_check_customer_create'
    }

    IW.appSetLocalVal('create_new_customer', false);
    IW.appSetLocalVal('get_customer_done', false);
    IW.call('getCustomer');
    yield 'loading app get_customer_done';
    return 'cash_check_confirm_data';
  },

  cash_check_customer_create: {
    back: 'number_login',
    next: 'start_take_photo'
  },

  ////////////////////////////////////////
  start_take_photo: function* () {
    IW.appSetLocalVal('camera_preview_face_done', false);
    cameraWatcher.start();
    yield 'loading app camera_preview_face_done';

    return {
      back: function () {
        return 'number_login'
      },
      scan: function* () {
        IW.appSetVal('image_path', '');
        IW.callCamera('frame');
        yield 'loading';
        cameraWatcher.stop();
        if (!IW.appGetVal('image_path')) return 'start_take_photo';
        IW.appSetVal('s_customer_photo_path', IW.appGetVal('image_file_path'));
        return 'confirm_photo_customer';
      }
    }
  },

  confirm_photo_customer: {
    back: 'start_take_photo',
    next: 'cash_check_insert_id'
  },
  //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

  ///////////////////////////////////////////
  cash_check_insert_id: function* () {
    checkReaderWatcher.start({scanId: true});
    yield {
      back: function* () {
        if (!IW.appGetVal('s_customer_edit_mode')) return 'confirm_photo_customer';
        IW.appSetLocalVal('s_customer_edit_mode', false);
        IW.appSetLocalVal('create_new_customer', false);
        IW.appSetLocalVal('get_customer_done', false);
        IW.call('getCustomer');
        yield 'loading app get_customer_done';
        return 'cash_check_confirm_data';
      },
      next: 'exit'
    };
    IW.appSetLocalVal('check_scan_arrived', false);
    IW.appSetVal('image_path_front', '');
    IW.appSetVal('image_path_back', '');
    IW.appSetLocalVal('check_scan_front', '');
    IW.appSetLocalVal('check_scan_back', '');
    IW.appSetLocalVal('check_removed', false);
    yield 'sleep app check_scan_front';
    yield 'sleep app check_scan_back';
    checkReaderWatcher.stop();
    return 'confirm_photo_id_scan'
  },

  cash_check_return_id: function* () {
    yield 'sleep app check_removed';
    return 'confirm_photo_id_scan'
  },

  confirm_photo_id_scan: {
    back: 'cash_check_insert_id',
    next: function* () {
      IW.appSetVal('image_file_path_front', '');
      IW.callCheckReader('send_image');
      IW.appSetLocalVal('loading_message', 'Uploading scans...');
      yield 'loading app image_file_path_front';
      IW.appSetLocalVal('loading_message', '');

      IW.appSetVal('s_customer_id_path_front', IW.appGetVal('image_file_path_front'));
      IW.appSetVal('s_customer_id_path_back', IW.appGetVal('image_file_path_back'));

      IW.appSetLocalVal('do_id_ocr_done', false);
      IW.call('doIdOCR');
      yield 'loading app do_id_ocr_done';

      return 'cash_check_create_customer'
    }
  },
  //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

  cash_check_create_customer: function () {
    Session.set('prev_current_active_field', 0);
    Session.set('current_active_field', 0);
    return {
      back: 'confirm_photo_id_scan',
      next: 'cash_check_id_list'
    }
  },

  cash_check_id_list: {
    back: 'cash_check_create_customer',
    next: 'cash_check_create_customer_id'
  },

  cash_check_create_customer_id: function () {
    Session.set('prev_current_active_field', 0);
    Session.set('current_active_field', 0);

    IW.appSetVal('s_customer_id_issuer', IW.appGetVal('s_customer_address_state'));
    return {
      back: 'cash_check_id_list',
      next: 'cash_check_confirm_data'
    }
  },

  cash_check_confirm_data: {
    back: function () {
      if (IW.appGetVal('create_new_customer')) return 'cash_check_insert_id';
      return 'number_login';
    },
    edit: function () {
      IW.appSetVal('s_customer_edit_mode', true);
      IW.appSetLocalVal('create_new_customer', true);
      return 'cash_check_insert_id'
    },
    next: function* () {
      if (IW.appGetVal('create_new_customer')) {
        IW.appSetLocalVal('create_customer_done', '');
        IW.call('createCustomer');
        yield 'loading app create_customer_done';
        if (IW.appGetVal('s_customer_duplicated')) return 'cash_check_customer_duplicated';
        if (!IW.appGetVal('create_customer_success')) return 'cash_check_insert_id';
      }

      const customer = {}

      if (IW.appGetVal('s_customer_first_name')) customer.first_name = IW.appGetVal('s_customer_first_name');
      if (IW.appGetVal('s_customer_middle_name')) customer.middle_name = IW.appGetVal('s_customer_middle_name');
      if (IW.appGetVal('s_customer_last_name')) customer.last_name = IW.appGetVal('s_customer_last_name');
      if (IW.appGetVal('s_customer_email')) customer.email = IW.appGetVal('s_customer_email');
      if (IW.appGetVal('s_customer_address')) customer.address = IW.appGetVal('s_customer_address');
      if (IW.appGetVal('s_customer_address1')) customer.address1 = IW.appGetVal('s_customer_address1');
      if (IW.appGetVal('s_customer_address2')) customer.address2 = IW.appGetVal('s_customer_address2');
      if (IW.appGetVal('s_customer_address_city')) customer.address_city = IW.appGetVal('s_customer_address_city');
      if (IW.appGetVal('s_customer_address_state')) customer.address_state = IW.appGetVal('s_customer_address_state');
      if (IW.appGetVal('s_customer_address_zip')) customer.address_zip = IW.appGetVal('s_customer_address_zip');
      if (IW.appGetVal('s_customer_dob')) customer.date_of_birth = IW.appGetVal('s_customer_dob');

      IW.appSetLocalVal('s_customer_update_done', false)
      IW.updateCustomer(customer, () => IW.appSetLocalVal('s_customer_update_done', true));
      yield 'loading app s_customer_update_done';

      IW.appSetLocalVal('get_customer_approved_check_done', false)
      IW.call('getCustomerApprovedCheck');
      yield 'loading app get_customer_approved_check_done';

      return 'cash_check_continue'
    }
  },

  /////////////////////////////////////////////////////////////////////////

  redirect3: function () {
    return 'cash_check_continue'; // Ignoring customer id verification
    //
    // IW.appSetLocalVal('verify_customer_done', false)
    // IW.appSetLocalVal('verify_customer_challenge', null)
    // IW.appSetLocalVal('verify_customer_status', '')
    // IW.call('getCustomerVerification', {
    //   s_customer_id: IW.appGetVal('s_customer_id'),
    // }, (__, data) => {
    //   IW.appSetLocalVal('verify_customer_status', data.verify_customer_status);
    //   IW.appSetLocalVal('verify_customer_challenge', data.verify_customer_challenge);
    //   IW.appSetLocalVal('verify_customer_done', true);
    // });
    // yield 'loading app verify_customer_done';
    //
    // if (!_.isEmpty(IW.appGetVal('verify_customer_challenge'))) {
    //   IW.appSetLocalVal('verification_challenge_id', 0);
    //   return 'cash_check_challenge';
    // }
    // if (IW.appGetVal('verify_customer_status') === 'passed') {
    //   IW.appSetLocalVal('create_new_customer', false);
    //   IW.appSetVal('s_customer_edit_mode', false);
    //   return 'cash_check_continue';
    // }
    //
    // return 'cash_check_customer_verification_failed'; //TODO: Create message
  },


  cash_check_challenge: function* ({lastAction}) {
    const lastChallengeId = IW.appGetVal('verification_challenge_id') || 0;

    if (lastChallengeId < 0) {
      return 'cash_check_confirm_data'
    }

    const currentQuestion = _.map(IW.appGetVal('verify_customer_challenge'), (i) => i)[lastChallengeId];
    if (!currentQuestion) {
      IW.appSetLocalVal('verify_customer_done', false)
      IW.call('setCustomerVerification', {
        s_customer_id: IW.appGetVal('s_customer_id'),
        verify_customer_challenge: IW.appGetVal('verify_customer_challenge')
      }, () => {
        IW.appSetLocalVal('verify_customer_done', true);
      })
      yield 'loading app verify_customer_done';
      return 'redirect3';
    }

    IW.appSetLocalVal('verify_customer_prompt', currentQuestion.prompt);
    IW.appSetLocalVal('verify_customer_answers', currentQuestion.answers);

    return {
      back: function () {
        IW.appSetLocalVal('verification_challenge_id', lastChallengeId - 1);
        return 'cash_check_challenge';
      },
      next: function ({params}) {
        const challenge = IW.appGetVal('verify_customer_challenge');
        challenge[lastChallengeId].selected_answer = params[0];
        IW.appSetLocalVal('verify_customer_challenge', challenge);
        IW.appSetLocalVal('verification_challenge_id', lastChallengeId + 1);
        return 'cash_check_challenge';
      }
    }
  },

  cash_check_customer_verification_failed: {
    back: 'cash_check_create_customer_id',
    next: 'cash_check_continue',
  },

  /////////////////////////////////////////////////////////////////////////

  cash_check_continue: function() {
    if (!IW.appGetVal('previous_check_approved')) {
      return 'cash_check_amount'
    }

    IW.appSetVal('original_amount', 0);
    return {
      cancel: 'cash_check_amount',
      next: () => {
        IW.appSetVal('original_amount', IW.appGetVal('review_check_amount'));
        return 'cash_check_amount'
      }
    };
  },

  cash_check_amount: {
    back: 'cash_check_confirm_data',
    next: 'cash_check_amount_info2'
  },

  cash_check_amount_info2: function* () {
    IW.appSetVal('check_amount', IW.appGetVal('original_amount'));
    IW.appSetVal('customer_check_amount', IW.appGetVal('original_amount'));
    IW.appSetVal('selected_amount', CONFIG.CASH_CHECK_MAX_DISPENSE);
    IW.appSetVal('dispensable_amount', CONFIG.CASH_CHECK_MAX_DISPENSE);
    IW.appSetVal('fee', 0);
    IW.appSetVal('debit_amount', 0);
    yield* calculateDispensableCash();

    if (IW.appGetVal('customer_balance_amount') + IW.appGetVal('customer_amount') < 0) {
      return 'cash_check_insert_check';
    }

    return {
      back: 'cash_check_amount',
      next: 'cash_check_insert_check'
    }
  },

  cash_check_insert_check: function* () {
    checkReaderWatcher.start();
    yield {
      back: 'cash_check_amount',
      next: 'exit'
    };
    IW.appSetLocalVal('check_scan_arrived', false);
    IW.appSetVal('image_path_front', '');
    IW.appSetVal('image_path_back', '');
    IW.appSetLocalVal('check_scan_front', '');
    IW.appSetLocalVal('check_scan_back', '');
    yield 'sleep app check_scan_front';
    yield 'sleep app check_scan_back';
    return 'confirm_photo_check_scan'
  },
  //
  // _debug: {
  //   back: 'confirm_photo_check_scan'
  // },

  confirm_photo_check_scan: {
    back: function () {
      IW.appSetLocalVal('return_check_message', 'Scan you check again');
      IW.appSetLocalVal('return_check_next_page', 'cash_check_insert_check');
      return 'cash_check_return_check'
    },
    next: function* () {
      IW.appSetVal('config_auto_approve', !!CONFIG.CASH_CHECK_AUTO_APPROVE);
      IW.appSetVal('config_clerk_review', !!CONFIG.CASH_CHECK_CLERK_REVIEW);

      IW.appSetVal('image_file_path_front', '');
      IW.callCheckReader('send_image');
      IW.appSetLocalVal('loading_message', 'Uploading scans...');
      yield 'loading app image_file_path_front';
      IW.appSetLocalVal('loading_message', '');

      IW.appSetVal('s_customer_check_path_front', IW.appGetVal('image_file_path_front'));
      IW.appSetVal('s_customer_check_path_back', IW.appGetVal('image_file_path_back'));

      IW.appSetLocalVal('check_processing_done', false);
      IW.call('doCheckProcessing');
      yield 'loading app check_processing_done';

      const checkProcessingTransactionStatus = IW.appGetVal('transaction_status');

      IW.appSetLocalVal('transaction_should_be_canceled', false);

      if (IW.appGetVal('decision_recommendation') || IW.appGetVal('check_processing_success')) {
        IW.appSetLocalVal('check_clerk_review_done', false);
        IW.appSetLocalVal('check_clerk_review_success', false);
        IW.call('checkClerkReview');
        yield 'loading app check_clerk_review_done';

        if (!IW.appGetVal('check_clerk_review_success')) {
          IW.appSetLocalVal('create_transaction_done', false);
          IW.call('createTransaction');
          yield 'loading app create_transaction_done';

          if (!IW.appGetVal('create_transaction_success')) {
            IW.appSetLocalVal('return_check_message', 'We cannot create transaction for your input');
            IW.appSetLocalVal('return_check_next_page', 'cash_check_amount');
            return 'cash_check_return_check'
          }
        }
      }

      if (IW.appGetVal('transaction_status') === 'canceled') {
        IW.appSetLocalVal('return_check_message', 'This check is not accepted by reviewer.');
        IW.appSetLocalVal('return_check_next_page', 'cash_check_amount');
        return 'cash_check_return_check';
      }

      if (IW.appGetVal('transaction_status') === 'review' && IW.appGetVal('review_check_transaction_id')) {
        return 'cash_check_wait_review';
      }

      IW.appSetLocalVal('transaction_should_be_canceled', !!IW.appGetVal('check_transaction_id'));

      if (IW.appGetVal('transaction_status') === 'review') {
        IW.appSetVal('check_review', false);

        IW.appSetVal('check_amount', IW.appGetVal('customer_check_amount'));
        IW.appSetVal('selected_amount', CONFIG.CASH_CHECK_MAX_DISPENSE);
        IW.appSetVal('dispensable_amount', CONFIG.CASH_CHECK_MAX_DISPENSE);
        IW.appSetVal('fee', 0);
        IW.appSetVal('debit_amount', 0);

        IW.appSetLocalVal('update_amounts_done', false);
        IW.call('updateAmounts', null, () => IW.appSetLocalVal('update_amounts_done', true));
        yield 'loading app update_amounts_done';

        IW.appSetLocalVal('return_check_message', '');
        IW.appSetLocalVal('return_check_next_page', 'cash_check_amount');
        IW.appSetVal('check_review', true);

        if (IW.appGetVal('check_clerk_review_success')) {
          IW.appSetLocalVal('create_transaction_done', false);
          IW.call('createClerkTransaction');
          yield 'loading app create_transaction_done';

          if (!IW.appGetVal('create_transaction_success')) {
            IW.appSetLocalVal('return_check_message', 'We cannot create transaction for your input');
            IW.appSetLocalVal('return_check_next_page', 'cash_check_amount');
            return 'cash_check_return_check';
          }
        }

        return 'cash_check_wait_review';
      }

      if (IW.appGetVal('transaction_status') === 'accepted' || IW.appGetVal('transaction_status') === 'approved') {
        return 'cash_check_amount_info';
      }

      if (!IW.appGetVal('check_processing_success')) {
        const possibleCases = {
          bad_image: 'We cannot find check number or account on the check',
          not_parsed: 'You have incorrect account information on the check',
          bad_maker: 'The check maker is not allowed in our system',
          bad_decision: 'We have some problems trying process your check',
          decline_decision: 'Your check is declined according to our decision rules',
        };

        IW.appSetLocalVal('transaction_should_be_canceled',
          IW.appGetVal('transaction_should_be_canceled') &&
          checkProcessingTransactionStatus !== 'decline_decision');
        IW.appSetLocalVal('return_check_message', possibleCases[checkProcessingTransactionStatus] || '');
        IW.appSetLocalVal('return_check_next_page', 'cash_check_amount');
        return 'cash_check_return_check';
      }

      IW.appSetLocalVal('return_check_message', 'We have some problems trying process your check');
      IW.appSetLocalVal('return_check_next_page', 'cash_check_amount');
      return 'cash_check_return_check';
    }
  },

  // cash_check_review: function () {
  //   IW.appSetVal('check_review', false);
  //   return {
  //     cancel: function () {
  //       IW.appSetLocalVal('return_check_message', 'Review is canceled');
  //       IW.appSetLocalVal('return_check_next_page', 'cash_check_amount');
  //       return 'cash_check_return_check'
  //     },
  //     next: function* () {
  //       IW.appSetVal('check_amount', IW.appGetVal('original_amount'));
  //       IW.appSetVal('selected_amount', CONFIG.CASH_CHECK_MAX_DISPENSE);
  //       IW.appSetVal('dispensable_amount', CONFIG.CASH_CHECK_MAX_DISPENSE);
  //       IW.appSetVal('fee', 0);
  //       IW.appSetVal('debit_amount', 0);
  //
  //       IW.appSetLocalVal('update_amounts_done', false);
  //       IW.call('updateAmounts', null, () => IW.appSetLocalVal('update_amounts_done', true));
  //       yield 'loading app update_amounts_done';
  //
  //       IW.appSetLocalVal('return_check_message', '');
  //       IW.appSetLocalVal('return_check_next_page', 'cash_check_amount');
  //       IW.appSetVal('check_review', true);
  //
  //       if (IW.appGetVal('check_clerk_review_success')) {
  //         IW.appSetLocalVal('create_transaction_done', false);
  //         IW.call('createClerkTransaction');
  //         yield 'loading app create_transaction_done';
  //
  //         if (!IW.appGetVal('create_transaction_success')) {
  //           IW.appSetLocalVal('return_check_message', 'We cannot create transaction for your input');
  //           IW.appSetLocalVal('return_check_next_page', 'cash_check_amount');
  //           return 'cash_check_return_check';
  //         }
  //       }
  //
  //       return 'cash_check_wait_review';
  //     }
  //   }
  // },

  cash_check_wait_review: function* () {
    IW.appSetVal('send_review_status_sms', false);
    IW.appSetVal('decline_transaction_reason', '');
    Session.set('cash_check_reviewing_sms', true);
    yield {
      next: function () {
        IW.call('notifyCustomer', {
          type: 'reviewing',
          support_number: IW.getUISettings().support_number
        });
        Session.set('cash_check_reviewing_sms', false);
        IW.appSetVal('send_review_status_sms', true);
        IW.appSetLocalVal('transaction_should_be_canceled', false);
        IW.appSetLocalVal('return_check_message', 'Come back when review will be finished');
        IW.appSetLocalVal('return_check_next_page', 'exit');
        return 'cash_check_return_check'
      }
    };

    IW.appSetLocalVal('check_review_too_long', false);
    IW.appSetLocalVal('transaction_status', '');
    IW.call('updateReviewStatus');
    let startTime = Date.now();
    while (!IW.appGetVal('transaction_status') || IW.appGetVal('transaction_status') === 'review') {
      if (Date.now() - startTime > 4 * 60 * 1000) {
        startTime += Date.now()
        IW.appSetLocalVal('check_review_too_long', true);
      }
      yield 'sleep 1000';
    }

    Session.set('cash_check_reviewing_sms', false);

    if (IW.appGetVal('transaction_status') === 'approved') {
      IW.appSetLocalVal('check_review', false);
      IW.appSetVal('check_amount', IW.appGetVal('review_check_amount'));
      return 'cash_check_amount_info';
    }

    if (IW.appGetVal('transaction_status') === 'canceled') {
      IW.appSetLocalVal('transaction_should_be_canceled', false);
      IW.appSetLocalVal('return_check_message', 'This check is not accepted by reviewer.');
      IW.appSetLocalVal('return_check_next_page', 'cash_check_amount');
      return 'cash_check_return_check';
    }

    IW.appSetLocalVal('return_check_message', 'There is some problem on the our side.');
    IW.appSetLocalVal('return_check_next_page', 'cash_check_amount');
    return 'cash_check_return_check';
  },

  cash_check_amount_info: function* () {
    var res = yield* calculateDispensableCash();
    if (!res) {
      return {
        cancel: 'cash_check_return_check'
      }
    }

    IW.appSetVal('customer_referrals_enabled', CONFIG.REFERRALS_ENABLED);
    IW.appSetLocalVal('customer_update_discount_done', false);
    IW.updateCustomerDiscount(() => IW.appSetLocalVal('customer_update_discount_done', true));
    yield 'loading app customer_update_discount_done';

    return {
      cancel: 'cash_check_return_check',
      next: 'extract_money'
    }
  },

  // cash_check_clerk_login: function () {
  //   function restore() {
  //     IW.appSetVal('clerk_number', IW.appGetVal('number'));
  //     IW.appSetVal('clerk_phone_number', IW.appGetVal('phone_number'));
  //     IW.appSetVal('number', IW.appGetVal('customer_number'));
  //     IW.appSetVal('phone_number', IW.appGetVal('customer_phone_number'));
  //   }
  //
  //   IW.appSetVal('customer_number', IW.appGetVal('number'));
  //   IW.appSetVal('customer_phone_number', IW.appGetVal('phone_number'));
  //   IW.appSetVal('number', IW.appGetVal('clerk_number'));
  //   IW.appSetVal('phone_number', IW.appGetVal('clerk_phone_number'));
  //
  //   return {
  //     back: function () {
  //       restore();
  //       return 'back'
  //     },
  //     next: function () {
  //       restore();
  //       return 'cash_check_clerk_wait_approve'
  //     }
  //   }
  // },

  // cash_check_clerk_wait_approve: function* () {
  //   IW.appSetLocalVal('notify_clerk_done', false);
  //   IW.appSetLocalVal('notify_clerk_success', false);
  //   IW.appGetVal('clerk_login_token', '');
  //   IW.call('notifyClerk');
  //   yield 'loading app notify_clerk_done';
  //
  //   yield {
  //     back: 'cash_check_clerk_login',
  //   };
  //
  //   while (true) {
  //     IW.appSetLocalVal('clerk_qr_scanned', false);
  //     yield 'sleep app clerk_qr_scanned';
  //     if (!IW.appGetVal('clerk_qr_correct')) {
  //       yield 'sleep 3000';
  //       continue;
  //     }
  //
  //     IW.appSetLocalVal('check_review', false);
  //     IW.appSetVal('check_amount', IW.appGetVal('review_check_amount'));
  //     return 'cash_check_amount_info';
  //   }
  // },

  // redirect1: function* () {
  //   // IW.appSetLocalVal('create_transaction_done', false);
  //   // IW.call('createTransaction');
  //   // yield 'loading app create_transaction_done';
  //   //
  //   // if (!IW.appGetVal('create_transaction_success')) {
  //   //   IW.appSetLocalVal('return_check_message', 'We cannot create transaction for your input');
  //   //   IW.appSetLocalVal('return_check_next_page', 'cash_check_insert_check');
  //   //   return 'cash_check_return_check'
  //   // }
  //   //
  //   // if (IW.appGetVal('transaction_status') !== 'approved' && IW.appGetVal('transaction_status') !== 'review') {
  //   //   IW.appSetLocalVal('cancel_transaction_done', false);
  //   //   IW.call('cancelTransaction');
  //   //   yield 'loading app cancel_transaction_done';
  //   //
  //   //   IW.appSetLocalVal('return_check_message', 'Your check was not accepted');
  //   //   IW.appSetLocalVal('return_check_next_page', 'cash_check_insert_check');
  //   //   return 'cash_check_return_check'
  //   // }
  //
  //   // if (IW.appGetVal('check_review')) {
  //   //   return 'cash_check_wait_review';
  //   // }
  //
  //   // IW.appSetLocalVal('extract_cash_done', false);
  //   // IW.call('extractCash');
  //   // yield 'loading app extract_cash_success';
  //   //
  //   // if (!IW.appGetVal('extract_cash_success')) {
  //   //   IW.appSetLocalVal('return_check_message', 'We cannot dispense the amount now');
  //   //   IW.appSetLocalVal('return_check_next_page', 'exit');
  //   //   return 'cash_check_return_check'
  //   // }
  //
  //   return 'extract_money';
  // },

  extract_money: function* () {
    IW.appSetLocalVal('extract_cash_done', false);
    IW.call('extractCash');
    yield 'loading app extract_cash_done';

    if (!IW.appGetVal('extract_cash_success')) {
      IW.appSetLocalVal('return_check_message', 'We cannot dispense the amount now');
      IW.appSetLocalVal('return_check_next_page', 'exit');
      return 'cash_check_return_check'
    }

    IW.appSetLocalVal('selected_account', 6);
    Session.set('menu_disabled', true);

    IW.callBillAcceptor('dispense');
    IW.callCashDispenser('dispense');
    yield 'sleep app dispensing_done';

    Session.set('menu_disabled', false);

    yield 'loading app cash_dispensed_done';

    if (!IW.appGetVal('cash_dispensed_success')) {
      IW.appSetLocalVal('return_check_message', 'Dispensing was failed');
      IW.appSetLocalVal('return_check_next_page', 'exit');
      return 'cash_check_return_check'
    }

    IW.callCheckReader('swallow_check');
    yield 'loading app check_reader_swallow_check_done';
    return 'redirect4';
  },

  redirect4: function () {
    checkReaderWatcher.stop();
    IW.printReceipt();

    if (CONFIG.SWEEPSTAKES_ENABLED) {
      IW.appSetLocalVal('customer_next_page', 'cash_check_success');
      return 'sweepstakes_start_question'
    }

    return 'cash_check_success'
  },

  cash_check_success: function* () {
    if (CONFIG.SHOW_POPULAR_PRODUCTS_ADVERTISEMENT) {
      IW.call('getPopularProductsId');
      yield 'loading app popular_products_id';
    }

    return {
      cancel: 'exit',
      next: 'exit'
    }
  },

  cash_check_return_check: function* () {
    if (IW.appGetVal('transaction_should_be_canceled')) {
      IW.call('cancelTransaction');
    }
    IW.appSetLocalVal('transaction_should_be_canceled', false);

    IW.appSetLocalVal('check_removed', false);
    checkReaderWatcher.stop();
    const elapsedTime = Date.now() + 10000;
    const timer = setTimeout(() => IW.appSetLocalVal('check_removed', true), 3000);
    yield 'sleep app check_removed';
    clearTimeout(timer);
    yield {
      next: IW.appGetVal('return_check_next_page'),
    };
    yield 'sleep ' + Math.max(elapsedTime - Date.now(), 0);
    return IW.appGetVal('return_check_next_page');
  },

  // cash_check_customer_exists: {
  //   back: 'number_login',
  //   next: 'on_launch_login',
  // },

  cash_check_customer_duplicated: function () {
    if (IW.appGetVal('s_customer_ssn_duplicated')) {
      return {back: 'cash_check_create_customer'}
    }

    if (IW.appGetVal('s_customer_id_duplicated')) {
      return {back: 'cash_check_create_customer_id'}
    }

    return {
      back: 'number_login'
    }
  }
});


function* calculateDispensableCash() {
  IW.appSetLocalVal('cash_dispenser_status_done', false);
  IW.appSetLocalVal('coin_acceptor_status_done', false);
  IW.callCashDispenser('status');
  IW.callBillAcceptor('status');
  yield 'loading app cash_dispenser_status_done';
  yield 'loading app coin_acceptor_status_done';

  IW.appSetVal('selected_amount', CONFIG.CASH_CHECK_MAX_DISPENSE);
  IW.appSetVal('fee', 0);
  IW.appSetVal('debit_amount', 0);
  IW.appSetLocalVal('return_check_message', '');
  IW.appSetLocalVal('return_check_next_page', 'cash_check_amount');

  do {
    IW.appSetLocalVal('last_selected_amount', IW.appGetVal('selected_amount'));
    IW.appSetLocalVal('load_dispense_done', false);
    IW.call('loadDispense');
    yield 'loading app load_dispense_done';

    IW.appSetLocalVal('update_amounts_done', false);
    IW.call('updateAmounts', null, (_, data) => {
      IW.appSetLocalVal('selected_amount', data.selected_amount)
      IW.appSetLocalVal('update_amounts_done', true)
    });
    yield 'loading app update_amounts_done';

    if (IW.appGetVal('selected_amount') <= 0 && IW.appGetVal('debit_amount') <= 0) {
      return false
    }
  } while (IW.appGetVal('selected_amount') < IW.appGetVal('last_selected_amount'));

  return true
}
