import {createContainer} from 'imports/lib/react_createContainer';
import ConfirmBillScanning from './ConfirmBillScanning'
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';

export default createContainer((params) => {

  return {
    items: [{
      title: 'Biller Name',
      value: IW.appGetVal('biller_name')
    }],
    onBack: () => getPageManager().back(),
    onNext: () => getPageManager().next(),
  };
}, ConfirmBillScanning)