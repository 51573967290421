import IW from 'imports/iw_api/index';
import {LOGIN_FIELDS} from 'imports/lib/constants';
import {S} from 'imports/lib/helpers';
import createNumber from './common/number';
import createPayDevice from './common/payDevice';
import createEnterSenderData from './common/enterSenderData';
import createPayout from './common/payout';
import createRecipientCity from './common/recipientCity';
import createRecipientTown from './common/recipientTown';
import createListSlider from './common/listSlider';
import createEnterBankData from './common/createEnterBankData';
import createEmail from './common/createEmail';
import createEnterRecipientData from './common/enterRecipientData';
import createConfirmPayout from './common/confirmPayout';
import createPay from './common/pay';
import createPaymentStatus from './common/payment_status';
import createConfirmSenderData from './common/confirmSenderData';
import createRecipientChoise from './common/recipientChoise';
import {next} from '../lib/page_manager';

let lastTransactionHash = '';

export default {
  //start page
  index: () => 'sender_number',

  sender_number: createNumber('exit', 'redirect_transaction_validation', {
    onCreate: function () {
      IW.appSetVal('sender_address_country', 'USA');
      IW.appSetVal('sender_country_iso', 'US');
      IW.appSetVal('number', IW.appGetVal('sender_number') || '', true);
      // IW.call('debug_copySession', 's_aV3qA');
      // return 'enter_recipient_data'
    }
  }),

  on_login_pay_again: () => 'enter_amount_transfer_again',

  redirect_transaction_validation: function* () {
    validateTransaction();
    return yield* getTransactionNextPage('enter_sender_data', 'enter_sender_data');
  },

  enter_sender_data: createEnterSenderData('sender_number', 'confirm_sender_data', 'confirm_sender_data'),

  validate_sender_data: function* () {
    let isCityStateOk = true;

    IW.appSetLocalVal('check_city_state_done', false)
    IW.callCacheable('checkCityState', {
      city: IW.appGetVal('sender_address_city'),
      state: IW.appGetVal('sender_address_state'),
      country_iso: IW.appGetVal('sender_address_country')
    }, (data) => {
      isCityStateOk = !!data;
      IW.appSetLocalVal('check_city_state_done', true)
    });

    yield 'loading app check_city_state_done';

    if (isCityStateOk) {
      const customer = _.extend({}, IW.appGetVal('customer'), {
        first_name: IW.appGetVal('sender_first_name'),
        middle_name: IW.appGetVal('sender_middle_name'),
        last_name: IW.appGetVal('sender_last_name'),
        address: IW.appGetVal('sender_address'),
        address1: IW.appGetVal('sender_address1'),
        address_city: IW.appGetVal('sender_address_city'),
        address_state: IW.appGetVal('sender_address_state'),
        address_zip: IW.appGetVal('sender_address_zip'),
      });

      IW.updateCustomer(customer, () => next());
      yield 'loading';

      IW.appClearVal('data_model');

      IW.appSetVal('sender_new_data_entered', true);

      return IW.appGetVal('validate_sender_data_next_page')
    }

    return {
      back: () => IW.appGetVal('validate_sender_data_back_page')
    }
  },

  confirm_sender_data: createConfirmSenderData('enter_sender_data', 'payout', 'recipient_choice'),

  payout: createPayout('confirm_sender_data', 'recipient_city', 'confirm_payout', 'recipient_choice'),

  recipient_city: createRecipientCity('payout', 'list_slider'),

  recipient_town: createRecipientTown('recipient_city', 'list_slider'),

  // confirm_customer_data: createConfirmCustomerData('recipient_choice'),

  list_slider: createListSlider('recipient_city', function () {
    confirmPayoutData();
    return 'recipient_notification'
  }, 'enter_bank_data', 'payout'),

  enter_bank_data: createEnterBankData('list_slider', function () {
    confirmPayoutData();

    return 'recipient_notification'
  }),

  recipient_choice: createRecipientChoise('confirm_sender_data', 'confirm_payout'),

  recipient_notification: function () {
    IW.appSetVal('recipient_email', '');
    IW.appSetVal('recipient_number', '');
    IW.appSetVal('recipient_notification_type', '');

    return {
      back: function () {
        if (IW.appGetVal('recipient_payment_mode_type') === 'bank_deposit') return 'enter_bank_data';
        return 'list_slider';
      },

      next: function () {

        const notificationType = IW.appGetVal('recipient_notification_type');

        if (!notificationType) return 'enter_recipient_data';

        switch (IW.appGetVal('recipient_notification_type')) {
          case 'email':
            return 'recipient_email';
          case 'phone':
            return 'recipient_number';
          default:
            return 'enter_recipient_data'
        }
      }

    }
  },

  recipient_number: createNumber('recipient_notification', 'enter_recipient_data', {
    onCreate: function () {
      IW.appSetVal('number', IW.appGetVal('recipient_number') || '', true);
    },
    shouldCreateCustomer: false
  }),

  recipient_email: createEmail('recipient_notification', 'enter_recipient_data'),

  enter_recipient_data: createEnterRecipientData('recipient_notification', 'list_slider_ofac'),

  // recipient_notification -> enter_recipient_data ->
  // list_slider_ofac -> ofac_required_data -> enter_amount_transfer ->
  // confirm_transfer
  list_slider_ofac: function* (data) {
    function doBack() {
      if (IW.appGetVal('logged') && IW.appGetVal('recipient_data_filled')) {
        if (IW.appGetVal('sender_data_filled')) return 'recipient_notification';
        return 'confirm_sender_data'
      }
      return 'enter_recipient_data'
    }

    const senderIdTypeRequired = !!_.find(IW.appGetVal('ofac_fields'), (f) => f.FieldName === 'Compliance.SenderTypeId');
    if (senderIdTypeRequired && _.isEmpty(Session.get('money_transferring_id_types'))) {
      Session.set('money_transferring_id_types', null);
      IW.callWithUpdate('getSenderIdTypes', 'money_transferring_id_types');
      yield 'loading session money_transferring_id_types';
    }

    if (!senderIdTypeRequired) {
      //Check sender & recipient pre-filled fields in login
      if (data.lastAction === 'back') {
        return doBack();
      }
      return 'ofac_required_data';
    }

    return {
      back: function () {
        return doBack();
      },
      next: 'ofac_required_data'
    }
  },

  ofac_required_data: function (data) {
    if (_.size(IW.appGetVal('ofac_fields')) < 1) {
      return data.lastAction === 'back' ?
        'back' :
        'enter_amount_transfer';
    }

    Session.set('prev_current_active_field', 0);
    Session.set('current_active_field', 0);

    return {
      back: 'back',
      next: function () {
        IW.appSetVal('transaction_ofac_passed', true);
        return 'enter_amount_transfer';
      }
    }
  },

  enter_amount_transfer: createAmount(function () {
    if (IW.appGetVal('logged') && !IW.appGetVal('new_recipient') && !IW.appGetVal('is_payer_edit')) {
      return 'confirm_payout';
    }
    return 'enter_recipient_data'
  }),

  enter_amount_transfer_again: createAmount(function () {
    if (IW.appGetVal('logged')) return 'exit'
    return 'back'
  }), // Not implemented (disabled)

  redirect_pincode_verification: function* ({lastAction}) {
    if (lastAction === 'back') return 'back';
    // if (IW.appGetVal('original_amount') < 500) return 'pay';
    // IW.appSetVal('customer_first_payment_enabled', CONFIG.FIRST_PAYMENT_ENABLED);
    // IW.appSetLocalVal('customer_update_discount_done', false);
    // IW.updateCustomerDiscount(() => IW.appSetLocalVal('customer_update_discount_done', true));
    // yield 'loading app customer_update_discount_done';
    // if (IW.appGetVal('customer_is_first_payment')) return 'confirm_with_pin_number';
    return 'pay';
  },

  send_disclosure_email: function () {
    IW.appSetLocalVal('disclosure_email', false);

    return {
      back: function () {
        IW.appClearVal('email');
        IW.appSetVal('email_valid', false);
        return 'confirm_transfer'
      },
      next: function () {
        IW.call('sendDisclosureOnEmail');
        return 'confirm_transfer';
      }
    }
  },

  confirm_payout: createConfirmPayout('recipient_choice', 'payout', 'enter_amount_transfer', confirmPayoutData),

  // confirm_with_pin_number: createPinNumber('back', 'pay', null),

  pay: createPay('pay_transfer', 'pay_virtual'),
  pay_transfer: createPayDevice('back', 'final', null, 'payment_status'), // TODO: Remove it with pay page

  payment_status: createPaymentStatus('sender_number', 'enter_amount_transfer_again', 'pay'),

  // confirm_recipient_data: function () {
  //   return {
  //     back: 'recipient_choice',
  //     next: 'confirm_payout'
  //   }
  // },

  confirm_transfer: function () {
    const currentTransactionHash = getTransactionHash();
    if (lastTransactionHash !== currentTransactionHash) {
      IW.appSetVal('transaction_validation_done', false);
      IW.appSetVal('transaction_ofac_passed', false);
      IW.appSetVal('transaction_business_errors', []);
      IW.call('initTransaction');
      lastTransactionHash = currentTransactionHash;
    }
    return {
      back: function* () {
        yield* getTransactionNextPage('');
        return 'back'
      },
      next: function* () {
        return yield* getTransactionNextPage('redirect_pincode_verification');
      },
      email: 'send_disclosure_email'
    }
  },

  //After login
  on_login: function* () {

    IW.loadCustomerData();
    yield 'loading app customer';

    const customerData = IW.appGetVal('customer');

    if (customerData) {
      const updateObject = {};

      _.each(LOGIN_FIELDS, (f) => {
        updateObject[`sender_${f}`] = S(customerData[f])
      });

      IW.appSetObject(updateObject);
      if (customerData.mobile_number) {
        IW.appSetVal('sender_number', customerData.mobile_number);
        IW.appSetVal('phone_number', customerData.mobile_number);
      }
    }

    IW.appSetVal('confirm_sender_data', false);

    return 'redirect_transaction_validation'
  },

  on_login_recipient_choice: function () {
    IW.appSetVal('original_amount', 0);
    return 'enter_amount_transfer_again'
  },

  transaction_error_moneytransfer: function () {
    return {
      back: 'sender_number'
    }
  }
}

function confirmPayoutData() {
  IW.appSetVal('payer_details_confirm', {
    payer_bank_id: IW.appGetVal('payer_bank_id'),
    payer_bank_name: IW.appGetVal('payer_bank_name'),
    payer_name: IW.appGetVal('payer_name'),
    payer_id: IW.appGetVal('payer_id'),
    payer_rate: IW.appGetVal('payer_rate'),
    payer_branch_id: IW.appGetVal('payer_branch_id'),
    payer_sender_limit: IW.appGetVal('payer_sender_limit'),
    original_max_amount: IW.appGetVal('original_max_amount'),
    recipient_payment_mode_id: IW.appGetVal('recipient_payment_mode_id'),
  }, true)
}

function validateTransaction() {
  IW.appSetVal('transaction_validation_done', false);
  IW.appSetVal('transaction_ofac_passed', false);
  IW.appSetVal('transaction_business_errors', []);
  IW.call('validateTransaction');
}

function* getTransactionNextPage(nextPage, ofacNotPassedPage = 'list_slider_ofac') {
  yield 'loading app transaction_validation_done';

  if (!_.isEmpty(IW.appGetVal('transaction_business_errors'))) {
    IW.appSetLocalVal('transaction_error_messages', IW.appGetVal('transaction_business_errors'));
    return 'transaction_error_moneytransfer';
  }

  if (!IW.appGetVal('transaction_ofac_passed')) {
    return ofacNotPassedPage;
  }

  return nextPage;
}

function getTransactionHash() {
  let hash = Session.get('session_guid');
  _.each(Session.get('app'), (v, k) => {
    hash += k === 'customer_id' || k === 'original_amount' ||
    (/^sender_|^recipient_|^payer_|^required_/).test(k) ?
      S(v) + '|' :
      '';
  });
  return hash;
}

function createAmount(back) {
  return function* () {
    IW.appSetLocalVal('rate', 0);
    IW.call('updateAmounts');
    yield 'loading app rate';

    return {
      back: back,
      next: function* () {
        IW.appSetLocalVal('rate', 0);
        IW.call('updateAmounts');
        yield 'loading app rate';
        return 'confirm_transfer';
      }
    }
  }
}