import IW from 'imports/iw_api';
import {VIAMERICA_INTERNAL_ERRORS} from '../../lib/constants';

export default function (pageBack, pageNext, setSenderId = null, setRecipient = null) {
  return function* ({lastAction}) {
    Session.set('recipients', null);

    IW.appSetDefaults(/^payer/);
    IW.appSetDefaults(/^recipient_(?!country|currency)/);
    IW.appSetDefaults(/^payment_agent/);
    IW.appSetDefaults(/^calculated_/);
    IW.appSetDefaults(/^agent_/);
    IW.appSetVal('original_max_amount', '');
    IW.appSetVal('recipient_payment_mode_id', '');

    IW.callWithUpdate('getRecipients', 'recipients');
    yield 'loading session recipients';

    if (setSenderId) yield* setSenderId();

    if (IW.appGetVal('validation_error')) {
      if (VIAMERICA_INTERNAL_ERRORS[IW.appGetVal('validation_error')]) {
        if (IW.appGetVal('logged')) {
          IW.call('logout');
          IW.loadCustomerData();
          yield 'loading app customer';

          return 'edit_customer_data';
        }
        return 'enter_sender_data'
      }

      IW.appSetVal('transaction_error_messages', [IW.appGetVal('validation_error')]);
      return 'transaction_error_moneytransfer'
    }

    if (!Session.get('recipients') || !Session.get('recipients').length) {
      if (lastAction === 'back' || !lastAction) return pageBack;

      IW.appSetVal('new_recipient', true);

      return pageNext;
    }

    IW.appSetObject(emptyRecipient);

    return {
      back: function () {
        // if(IW.appGetVal('logged')) IW.call('logout');
        return pageBack;
      },

      next: function* () {
        const recipientId = +IW.appGetVal('customer_item_id');
        const recipient = (Session.get('recipients') || [])[recipientId];
        if (setRecipient) {
          const page = yield* setRecipient(recipient);
          if (page) return page;
        } else {
          IW.appSetObject(recipient || {});
        }

        IW.appSetLocalVal('new_recipient', false);
        IW.appSetLocalVal('payer_details_confirm', null);
        return pageNext;
      },

      skip: function () {
        IW.appSetLocalVal('new_recipient', true);
        return pageNext;
      }
    }
  }
}

// TODO: Replace to constants
const emptyRecipient = {
  payer_bank_id: "",
  payer_bank_name: "",
  payer_branch_id: "",
  payer_id: "",
  payer_name: "",
  payer_rate: 1,
  payer_recipient_limit: 0,
  payer_sender_limit: 0,
  recipient_address_city_name: "",
  // recipient_address_country: "",
  recipient_city_id: "",
  recipient_city_name: "",
  // recipient_country_iso: "",
  // recipient_currency_iso: "",
  recipient_email: "",
  recipient_first_name: "",
  recipient_last_name: "",
  recipient_middle_name: "",
  recipient_mother_name: "",
  recipient_notification_type: "",
  recipient_number: "",
  recipient_payment_mode_id: "",
  recipient_state_id: "",
  bank_account: "",
};
