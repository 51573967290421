/**
 * Created by vitaliy on 13.03.17.
 */
import {createContainer} from 'imports/lib/react_createContainer';
import EnterUserDataContainer from './EnterUserDataContainer';
import {KEYBOARDS, TRANSFER_INPUTS_RESULT} from 'imports/lib/constants';
import moment from 'imports/lib/external/moment-with-langs'
import {Session} from "core/session";

export default createContainer((params) => {

  const fields = [
    {
      name: 'first_name',
      describe: 'First name',
      describe_output: 'First name',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      validation: {
        required: true,
        minLength: 2,
        maxLength: 50,
        pattern: /^[a-zA-Z\s]+$/
      }
    },
    {
      name: 'middle_name',
      describe: 'Middle name(optional)',
      describe_output: 'Middle name(optional)',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      validation: {
        required: false,
        maxLength: 28,
        pattern: /^[a-zA-Z\s]+$/
      }
    },
    {
      name: 'last_name',
      describe: 'Last name',
      describe_output: 'Last name',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      validation: {
        required: true,
        minLength: 2,
        maxLength: 28,
        pattern: /^[a-zA-Z\s]+$/
      }
    },
    // {
    //   name: 'ssn',
    //   describe: 'SSN',
    //   describe_output: 'SSN',
    //   type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    //   type_keyboard: KEYBOARDS.NUMBER,
    //   validation: {
    //     required: false,
    //     minLength: 9,
    //     maxLength: 11,
    //     pattern: /^[0-9]+$/
    //   }
    // },
    {
      name: 'home_phone',
      describe: 'Home Phone',
      describe_output: 'Home Phone',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.NUMBER,
      validation: {
        required: false,
        minLength: 10,
        maxLength: 11,
        pattern: /^[0-9]+$/
      }
    },
    {
      name: 'dob',
      describe: 'Date of Birth',
      describe_output: 'Date of Birth',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.DATEPICKER,
      default_value: +moment().add(-20, 'year'),
      disable_clear: true,
      validation: {
        required: false
      }
    },
    {
      name: 'gender',
      describe: 'Gender',
      describe_output: 'Gender',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SCROLL_LIST,
      type_keyboard: KEYBOARDS.NONE,
      validation: {
        required: true,
        pattern: /^Male$|^Female$/
      }
    },
    {
      name: 'address',
      describe: 'Street Address',
      describe_output: 'Address',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SEARCH_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      hideFromList: true,
      output_scheme: [
        ['address1', 'address2'],
        ['address_city', ['address_state', 'address_zip']],
        ['address_country']
      ],
      validation: {
        required: true,
        pattern: ''
      }
    },
    {
      name: 'address1',
      describe: 'Street Address',
      describe_output: 'Address',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        required: true,
        pattern: '',
        maxLength: 60
      },
      part_of: 'address'
    },
    {
      name: 'address2',
      describe: 'Unit or Suite #',
      describe_output: 'Unit or Suite #',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        required: false,
        pattern: '',
        maxLength: 100
      },
      part_of: 'address'
    },
    {
      name: 'address_city',
      describe: 'City',
      describe_output: 'City',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      validation: {
        required: true,
        minLength: 2,
        maxLength: 20
      },
      part_of: 'address'
    },
    {
      name: 'address_state',
      describe: 'State',
      describe_output: 'State',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      validation: {
        required: true,
        minLength: 2,
        maxLength: 20
      },
      part_of: 'address'
    },
    {
      name: 'address_zip',
      describe: 'ZIP',
      describe_output: 'ZIP',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        required: true,
        minLength: 2,
        maxLength: 10
      },
      part_of: 'address'
    }];

  const props = {
    fields,
    prefix: 's_customer'
  }

  if (fields[Session.get('current_active_field') || 0].name === 'gender') {
    props.scrollListResult = [{key: 'male', value: 'Male'}, {key: 'female', value: 'Female'}]
  }

  return props
}, EnterUserDataContainer);