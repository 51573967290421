import app_common from './app_common';
import IW from '../iw_api';

export default _.extend({}, app_common, {
  loans: {
    back: 'exit',
    next: 'loans_phone_number'
  },

  loans_phone_number: {
    back: 'loans',
    next: function*() {
      IW.call('send_sms', {phone_number: IW.appGetVal('number')});
      yield 'loading app_exists send_sms';
      return 'loans_final'
    }
  },
  loans_final: {
    next: 'exit'
  }
});
