import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import List from './List';
import Links from 'imports/collections/links';

export default createContainer((params) => {

  let data = {
    query: {
      app_guid: params.appGuid,
      app_id: null,
      keywords: {$not: null}
    },
    sort: {
      sort_priority: 1,
      name: 1
    }
  };


  function getLinks() {
    var links = Links.find(data.query,
      {
        sort: data.sort,
        hidden: false
      }).fetch();

    return links.chunk(12).map((item) => item.complete(12, {}).chunk(4));
  }


  return {
    choices: getLinks()
  };

}, List);
