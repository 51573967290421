import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import ScanInfo from './ScanInfo';
import getPageManager from 'imports/lib/page_manager';


export default createContainer((params) => {
  return {
    goNext: () => getPageManager().next(),
    goBack: () => getPageManager().back()
  };

}, ScanInfo);
