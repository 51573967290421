import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';
import PaymentStatus from './PaymentStatus';
import {Session} from 'core/session';
import moment from 'imports/lib/external/moment-with-langs';
import CONFIG from 'imports/config';
import {usd} from '../../../lib/helpers';

let lastTimeout = 0;

export default createContainer((params) => {

  console.log('PaymentStatusContainer | last_category ', Session.get('last_category'));

  function dateFormat(timestamp) {
    moment.lang(Session.get('locale'));
    const res = moment(timestamp).format('Do of MMMM, YYYY, h:mma');
    moment.lang('en_US');
    return res
  }

  function doCancelExpiration(){
    const time = +IW.appGetVal('payment_cancel_expiration') - new Date() - 1;
    Meteor.clearTimeout(lastTimeout);
    if (time <= 0) return;

    const nonce = IW.appGetVal('payment_cancel_expiration');
    lastTimeout = Meteor.setTimeout(function(){
      // Session changed
      if (IW.appGetVal('payment_cancel_expiration') !== nonce) return;
      // Expired
      IW.appSetVal('payment_cancel_expiration', 0)
    }, time)
  }

  function getSigueTransactionStatus(){
    if (IW.appGetVal('transaction_status') === 'CRE') return 'Processing';
    if (IW.appGetVal('transaction_status') === 'AUT') return 'Processing';
    if (IW.appGetVal('transaction_status') === 'HLD') return 'Processing';
    if (IW.appGetVal('transaction_status') === 'TRN') return 'Available';
    if (IW.appGetVal('transaction_status') === 'PAY') return 'Paid';
    if (IW.appGetVal('transaction_status') === 'CAN') return 'Cancelled';
    if (IW.appGetVal('transaction_status') === 'VOI') return 'Cancelled';
    return 'PAID';
  }

  doCancelExpiration();

  const isVirtualDevice = IW.isVirtual();
  const isSigue = IW.appGetVal('service_name') === 'sigue';

  let paymentStatus = _.contains(['paid', 'cancelled'], IW.appGetVal('payment_status')) ?
    IW.appGetVal('payment_status').toUpperCase() :
    'FAILED';

  if (paymentStatus === 'PAID' && isSigue) {
    paymentStatus = getSigueTransactionStatus()
  }

  const canCancel = (IW.appGetVal('payment_status') === 'paid' || IW.appGetVal('payment_status') === 'received') &&
    IW.appGetVal('payment_cancel_expiration') > Date.now();

  return {
    ...IW.appGetAccount(),
    transactionId: IW.appGetVal('transaction_id'),
    amount: usd(IW.appGetVal('paid_amount')),

    cancelAllowed: IW.appGetVal('cancel_allowed') !== false,

    showSigueCancelNotAllowed: isSigue && !canCancel,

    canCancel : canCancel,

    canEdit : IW.appGetVal('payment_status') !== 'paid' && !IW.appGetVal('payment_readonly') && // payment_readonly deprecated
      !IW.appGetVal('payment_edit_disabled') && !IW.appGetVal('payment_refunded'),

    canPayAgain : IW.appGetVal('payment_status') === 'paid' &&
      !(/app_billpay_anyBl/).test(Session.get('app_guid')) && !IW.appGetVal('payment_pay_again_disabled'),

    canRefund : IW.canRefund() && IW.appGetVal('payment_status') !== 'paid' && !IW.appGetVal('payment_refund_disabled'),

    isPaid: IW.appGetVal('payment_status') === 'paid',

    paymentStatus : paymentStatus,

    paymentLocation : IW.appGetVal('payment_location_name') || '--',
    paymentCity : IW.appGetVal('payment_location_city') || '--',
    paymentState : IW.appGetVal('payment_location_state') || '',
    paymentAddress : IW.appGetVal('payment_location_address') || '',
    paymentZip : IW.appGetVal('payment_location_zip') || '',
    paymentTime : dateFormat(IW.appGetVal('payment_time')),
    onCancel: () => getPageManager().cancel(),
    onEdit: () => getPageManager().edit(),
    onPayAgain: () => getPageManager().payAgain(),
    onRefund: () => getPageManager().refund(),
    onGetSupport: () => getPageManager().support(),
    onPrintReceipt: () => IW.printReceipt(),
    isVirtualDevice
  };

}, PaymentStatus);
