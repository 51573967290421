import React from 'react';
import KeyboardKey from '../keyboard/KeyboardKey';
import keyboardOnPress from '../../decorators/keyboardOnPress';

class Numpad extends React.Component {
  render() {
    const {keys, onPress, className, style} = this.props;

    return (
      <table id="numpad" className={className} style={style}>
        <tbody>
        {keys.map((row, r) => (
          <tr key={r}>
            {row.map((key, k) => (
              <td key={k}><KeyboardKey key={k} keyName={key} onPress={onPress}/></td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>
    )
  }
}

Numpad.propTypes = {
  keys: React.PropTypes.arrayOf(React.PropTypes.arrayOf(React.PropTypes.any)),
  onPress: React.PropTypes.func,
  className : React.PropTypes.string,
  style: React.PropTypes.object
};

Numpad.defaultProps = {
  className: ""
};

export default keyboardOnPress(Numpad)
