/**
 * Created by vitaliy on 13.03.17.
 */
import {createContainer} from 'imports/lib/react_createContainer';
import EnterUserDataContainer from './EnterUserDataContainer';
import {KEYBOARDS, TRANSFER_INPUTS_RESULT} from 'imports/lib/constants';
import IW from 'imports/iw_api';
import {Session} from 'core/session';

export default createContainer((params) => {
  const requiredFields = IW.appGetVal('ofac_fields');

  const fields = _.map(_.filter(requiredFields, (field) => field.FieldName !== 'Compliance.SenderTypeId'), (field) => {

    switch (field.FieldName) {
      case "Compliance.SenderIdNumber":
        return {
          name: 'sender_id_number',
          describe: 'Sender ID number',
          describe_output: 'Sender ID number',
          type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
          type_keyboard: KEYBOARDS.TEXT_NUMBER,
          upper_case: true,
          validation: {
            required: true,
            minLength: 1,
            maxLength: 15
          }
        };
      case "Compliance.Ssn":
        return {
          name: 'sender_ssn',
          describe: 'Sender SSN',
          describe_output: 'Sender SSN',
          type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
          type_keyboard: KEYBOARDS.TEXT_NUMBER,
          validation: {
            required: true,
            minLength: 9,
            maxLength: 9
          }
        };
      case "Compliance.CountryIssueIsoCode":
        return {
          name: 'sender_id_country_iso',
          describe: 'Country ISO of the ID',
          describe_output: 'Country ISO of the ID',
          type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
          type_keyboard: KEYBOARDS.TEXT_NUMBER,
          validation: {
            required: true,
            minLength: 2,
            maxLength: 2
          }
        };
      case "Compliance.StateIssueId":
        return {
          name: 'sender_id_state_iso',
          describe: 'State ISO of the ID',
          describe_output: 'State ISO of the ID',
          type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
          type_keyboard: KEYBOARDS.TEXT_NUMBER,
          validation: {
            required: true,
            minLength: 2,
            maxLength: 5
          }
        };
      case "Compliance.SourceOfFunds":
        return {
          name: 'sender_source_of_funds',
          describe: 'Sender Source Of Funds',
          describe_output: 'Sender Source Of Funds',
          type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
          type_keyboard: KEYBOARDS.TEXT_NUMBER,
          validation: {
            required: true,
            minLength: 1,
            maxLength: 60
          }
        };
      case "Compliance.SenderOccupation":
        return {
          name: 'sender_occupation',
          describe: 'Sender Occupation',
          describe_output: 'Sender Occupation',
          type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
          type_keyboard: KEYBOARDS.TEXT_NUMBER,
          validation: {
            required: true,
            minLength: 1,
            maxLength: 30
          }
        };
      case "Compliance.SenderEmployerName":
        return {
          name: 'sender_employer_name',
          describe: 'Sender Employer',
          describe_output: 'Sender Employer',
          type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
          type_keyboard: KEYBOARDS.TEXT_NUMBER,
          validation: {
            required: true,
            minLength: 1,
            maxLength: 40
          }
        };
      case "Compliance.SenderEmployerAddress":
        return {
          name: 'sender_employer_address',
          describe: 'Sender Employer Address',
          describe_output: 'Sender Employer Address',
          type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
          type_keyboard: KEYBOARDS.TEXT_NUMBER,
          validation: {
            required: true,
            minLength: 1,
            maxLength: 60
          }
        };
      case "Compliance.SenderEmployerPhone":
        return {
          name: 'sender_employer_phone',
          describe: 'Sender Employer Phone',
          describe_output: 'Sender Employer Phone',
          type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
          type_keyboard: KEYBOARDS.TEXT_NUMBER,
          validation: {
            required: true,
            minLength: 1,
            maxLength: 15
          }
        };
      case "SenderData.IdExpiryDate":
      case "Compliance.IdExpiryDate":
        return {
          name: 'expiry_date',
          describe: 'Expiration date of the ID',
          describe_output: 'Expiration date of the ID',
          type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
          type_keyboard: KEYBOARDS.DATEPICKER,
          default_value: +new Date(),
          disable_clear: true,
          validation: {
            required: true
          }
        };
      case "Compliance.SenderDateOfBirth":
      case "SenderData.DateOfBirth":
      case "Sender.DateOfBirth":
        return {
          name: 'sender_birth_day',
          describe: 'Sender Date Of Birth',
          describe_output: 'Sender Date Of Birth',
          type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
          type_keyboard: KEYBOARDS.DATEPICKER,
          disable_clear: true,
          default_value: +new Date(new Date().getFullYear() - 30, 0, 1),
          validation: {
            required: true
          }
        };
      case "Compliance.ReceiverDateOfBirth":
        return {
          name: 'recipient_birth_day',
          describe: 'Recipient Date Of Birth',
          describe_output: 'Recipient Date Of Birth',
          type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
          type_keyboard: KEYBOARDS.DATEPICKER,
          disable_clear: true,
          default_value: +new Date(new Date().getFullYear() - 30, 0, 1),
          validation: {
            required: true
          }
        };
      case "Compliance.ReceiverFullName":
        return {
          name: 'recipient_full_name',
          describe: 'Recipient Full Name',
          describe_output: 'Recipient Full Name',
          type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
          type_keyboard: KEYBOARDS.TEXT_NUMBER,
          validation: {
            required: true,
            minLength: 1,
            maxLength: 80
          }
        };
      case "Compliance.ReceiverRelationship":
        return {
          name: 'recipient_relationship',
          describe: 'Recipient Relationship',
          describe_output: 'Recipient Relationship',
          type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
          type_keyboard: KEYBOARDS.TEXT_NUMBER,
          validation: {
            required: true,
            minLength: 1,
            maxLength: 20
          }
        };
      case "TransactionInfo.Account":
        return {
          name: 'bank_account',
          describe: 'Bank Account',
          describe_output: 'Bank Account',
          type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
          type_keyboard: KEYBOARDS.TEXT_NUMBER,
          validation: {
            required: true,
            minLength: 1,
            maxLength: 40
          }
        };
      default: {
        const fieldName = 'unknown_' + field.FieldName;
        const description = field.FieldName.replace(/^(.+\.)+/, '').match(/[A-Z]*[^A-Z]+/g).join(' ');
        const typeObj = (/Date/).test(field.FieldName) ?
          {
            type_keyboard: KEYBOARDS.DATEPICKER,
            disable_clear: true,
            default_value: +new Date(new Date().getFullYear() - 30, 0, 1),
            validation: {
              required: true
            }
          } :
          {
            type_keyboard: KEYBOARDS.TEXT_NUMBER,
            validation: {
              required: true,
              minLength: 1,
              maxLength: 40
            }
          };
        return _.extend({
          name: fieldName,
          describe: description,
          describe_output: description,
          type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
          // error_message: field.Message,
        }, typeObj);
      }
    }
  });

  if (!fields || fields.length === 0) {
    return {}
  }


  return {
    fields,
    prefix: 'required',
    onInput: (value) => {
      const currentActiveField = fields[Session.get('current_active_field') || 0];
      IW.appSetVal(`required_${currentActiveField.name}`, value.toString().toUpperCase())
    }
  }
}, EnterUserDataContainer);