/**
 * Created by vitaliy on 22.03.17.
 */
import React, {Component} from 'react';
import Numpad from 'imports/ui/shared/numpad/Numpad';
import IW from 'imports/iw_api';
import {Session} from 'core/session';
import Button from 'imports/ui/shared/button/Button';
import ClearedInput from 'imports/ui/shared/cleared_input/ClearedInput';
import DropdownIOSliderList from 'imports/ui/shared/dropdown/DropdownIOSliderListContainer';
import InputStateDescribe from 'imports/ui/shared/input/InputStateDescribe';
import TransferDescription from 'imports/ui/shared/transfer_description/TransferDescriptionSessionContainer';
import InputLabel from 'imports/ui/shared/input/InputLabel';
import {capitalize, _} from 'imports/lib/helpers';
import InputLabelCustomer from 'imports/ui/shared/input/InputLabelCustomer';

export default class PhoneNumber extends Component {

  componentDidMount() {
    const {sessionSet} = this.props;

    Session.set('number', Session.get('number') ? Session.get('number') : "");

    Session.set('focus', 'number');

    const field_to_focus = Session.get('focus');
    Session.set('focus', '');
    Session.set('focus', field_to_focus);

    sessionSet('focus', field_to_focus);
  }

  getHeader() {
    const {isHeaderShowed} = this.props;

    if (!isHeaderShowed) return null;

    return <TransferDescription/>;
  }

  render() {
    const {
      isNumberOk, isCustomer, isHeaderShowed, number, numberPattern, numpadLayout, onPrintNumber, goNext, goBack, goLogin, countryCodes,
      setPatternByCountryId, canModifyPattern, clearInput, transfer, abovePhoneText, isUnderPhoneTextError, underPhoneText, showDisclosure
    } = this.props;

    return (
      <table className={"phone-number " + (transfer ? "transfer" : "")}>
        <tbody>
        <tr className="thin">
          <td className="phone-number__header-container" colSpan="2">
            {this.getHeader()}
          </td>
        </tr>
        <tr>
          <td className="phone-number__input-container">
            <InputLabel text={abovePhoneText}/>
            <br/><br/>
            <ClearedInput id="number" pattern={numberPattern} className="focus" value={number} readOnly={true}
                          clearInput={clearInput} focus="number"/>
            <br/>
            {countryCodes.length > 0 ? <DropdownIOSliderList id="dropdown" onChecked={setPatternByCountryId}
                                                             className="drop-down" items={countryCodes}/> : ''
            }


            <InputStateDescribe
              className="phone-number__under-input"
              isError={isUnderPhoneTextError}
              text={underPhoneText}/>

            {showDisclosure &&
            <div className="phone-number__important_information" _={[capitalize(IW.appGetVal('service_name'))]}>
              *IMPORTANT: Money transfer services are provided exclusively
              by %s Corporation or a licensed affiliate.
              Maya is not the provider of any of the money transfer services provided here.
            </div>}
            {showDisclosure && <div className="phone-number__important_information" style={{marginTop: 0}}>
              {IW.appGetVal('service_name') === 'sigue' ? _('**Data and message rates may apply') : ''}
            </div>}

          </td>
          <td className="phone-number__numpad-container">
            <Numpad keys={numpadLayout} pattern={numberPattern} value={number} onValueChange={onPrintNumber}
                    canModifyPattern={canModifyPattern} style={{margin: 0}}/>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2">
            <table>
              <tr>
                <td style={{padding: 0}}>
                  <Button id="back" className="left button" text_="Back" onMouseDown={goBack}/></td>
                <td style={{padding: 0}}>
                  {isNumberOk ?
                    <Button id="next" className="right button"
                            onMouseDown={goNext} text_="Next"/> :
                    null}
                </td>
              </tr>
            </table>
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

PhoneNumber.propTypes = {
  appGuid: React.PropTypes.string,
  pages: React.PropTypes.object,
  numpadLayout: React.PropTypes.arrayOf(React.PropTypes.array),
  numberPattern: React.PropTypes.string,
  number: React.PropTypes.string,
  onPrintNumber: React.PropTypes.func,
  goNext: React.PropTypes.func,
  goBack: React.PropTypes.func,
  goLogin: React.PropTypes.func,
  inputWidth: React.PropTypes.number,
  countryCodes: React.PropTypes.array,
  setPatternByCountryId: React.PropTypes.func,
  clearInput: React.PropTypes.func,
  canModifyPattern: React.PropTypes.bool,
  abovePhoneText: React.PropTypes.string,
  underPhoneText: React.PropTypes.string,
  isNumberOk: React.PropTypes.bool,
  isUnderPhoneTextError: React.PropTypes.bool,
  isCustomer: React.PropTypes.bool
};


