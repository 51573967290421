import React from 'react';
import Button from 'imports/ui/shared/button/Button';

export default class Maya5Description extends React.Component {

  render() {

    const {onBack} = this.props;

    return (
      <table className="maya5-description-container">
        <tbody>
          <tr>
            <td style={{textAlign: 'center'}}>
              <img width={700} src="/resources/images/maya5/maya5_full_steps.svg" />
              <h2 className="maya5-description-title" _>EVERY <span>5th</span> TRANSACTION IS FREE!</h2>
              <p  className="maya5-description-text" _>{"After having made the 4th payment, a free payment will be activated on your account"}</p>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td style={{"display": "block", "height": "132px", "position": "relative"}}>
              <Button id="back" onMouseDown={onBack} className="left button" text_="Back"/>
            </td>
          </tr>
        </tfoot>
      </table>
    )
  }

}

Maya5Description.propTypes = {
  onBack: React.PropTypes.func,
};
