import {Session} from 'core/session';
import io from 'socket.io-client';
import CONFIG from '../config';
import IW from './index';
import {N, parseUrl, removeDot, getUser} from 'imports/lib/helpers';
import {next as goNextPage} from 'imports/lib/page_manager';
import {FlowRouter} from 'core/Router';
import * as Timer from 'imports/api/timer';

import {getDevice} from '../api/device';
import FEEDS from '../collections/feeds';
import Apps from '../collections/apps';
import I18n from '../collections/i18n';
import Links from '../collections/links';
import Products from '../collections/products';
import Quicklaunch from '../collections/quicklaunch_apps';
import Languages from '../collections/languages';
import Countries from '../collections/countries';
import createSessionLaunch from 'imports/lib/createSessionLaunch';
import runCardReaderInterval from 'imports/lib/runCardReader'

let socket = null;
let escrowInterval = 0;
let cardReaderInterval = 0;

export function getSocket() {
  try {
    return socket || null;
  }catch (err){
    return null;
  }
}

// Socket.io initialization
const sockedUrl = parseUrl(CONFIG.SOCKETIO_SERVER);
console.log('Socket.io connecting to', sockedUrl.origin, sockedUrl.pathname);

socket = io.connect(sockedUrl.origin, {
  path: sockedUrl.pathname,
  transports: ['websocket'],
  timeout: 5000, // 5 seconds should be enough
  reconnection: true,
  reconnectionDelay: 500,
  reconnectionDelayMax: 5000,
  randomizationFactor: 0,
  autoConnect: true
});
const socketEmit = socket.emit.bind(socket);
socket.emit = (...args) => {
  console.log('<=', ...args)
  socketEmit(...args);
};
Session.set('io.connected', false);

socket.on('connect_failed', function () {
  console.error("[Socket.io] connect_failed");
});

// TODO compare the incoming socket_id and device_guid/session_guid for
// all incoming events from the server to avoid event injection via broadcast

socket.on('/s/create/success', function (response, nonce) {
  console.log('=> /s/create/success', response.session_guid);
  if (Session.get('session_create_nonce') === nonce) {
    console.timeEnd('CREATE SESSION');
    Session.set('session_guid', response.session_guid);

    console.log('Update data', response.data);
    _.each(response.data, (v, k) => {
      IW.appSetLocalVal(k, v);
    });
  }
});
socket.on('/s/create/failure', function (data) {
  console.log('=> /s/create/failure', data);
  return IW.goToError();
});
// socket.on('/s/update/success', function() {
//   console.log('successfully updated the session')
// });

// Payments callbacks
socket.on('/p/create/success', function (payment_guid) {
  console.log('=> /p/create/success', payment_guid);
  // TODO compare the 'pay' session value to payment_guid
  // and cancel the last initPayment (or re-run) in case
  // if they don't match

  // IW.appSetVal('pay', payment_guid)
});

// Interface update
socket.on('/i/app/amount', function (amount) {
  console.log('=> /i/app/amount', amount);
  IW.appSetVal('amount', amount)
});

socket.on('/i/app/next', function (data) {
  console.log('=> /i/app/next');
  goNextPage(data);
});

socket.on('/i/app/error', function (data) {
  console.log('=> /i/app/error');
  IW.goToError();
});

socket.on('/i/app/exit', function () {
  console.log('=> /i/app/exit');
  IW.mainMenu();
});

socket.on('/i/app/open_maya/keepalive', function (data) {
  console.log('=> /i/app/open_maya/keepalive');
  const frame = $('#open-maya-frame')[0];
  if (_.isEmpty(frame) || _.isEmpty(frame.contentWindow)) return;
  frame.contentWindow.postMessage(data, location.origin);
  IW.takeScreenShot();
});

socket.on('/i/app/activity', function () {
  console.log('=> /i/app/activity');
  IW.takeScreenShot();
  Timer.reset();
});

socket.on('/i/app/result', function (data) {
  console.log('=> /i/app/result', data);
  for (var k in data) {
    IW.appSetLocalVal(k, data[k]);
  }
});

socket.on('/i/app/products/update', function () {
  console.log('=> /i/app/products/update');
  const DEVICE = getDevice();
  socket.emit('/data/products/update', DEVICE.DEVICE_GUID);
});

// Feed
//
socket.on('/data/feed', function (feeds) {
  console.log('=> /data/feed');

  if (!feeds || !feeds.length)
    return console.log('WARN: empty feed object');

  for (var i = 0; i < feeds.length; i++) {
    var data = feeds[i];
    if (data && data.feed) {
      data.local_timestamp = +new Date();
      data._id = data.feed;
      FEEDS.upsert(data._id, data);
    }
  }

  // Clean the fields older than one hour from minimongo
  FEEDS.remove({local_timestamp: {$lt: (+new Date()) - 3600000}})
});

socket.on('/data/countries', function (countries) {
  console.log('=> /data/countries');

  if (!countries || !countries.length)
    return console.log('WARN: empty country object');

  for (var i = 0; i < countries.length; i++) {
    var country = countries[i];
    if (country && country.pattern && country.code) {
      Countries.upsert(country.country_id, country);
    }
  }
});

socket.on('/data/categories', function (tree) {
  console.log('=> /data/categories');
  Session.set('categories_tree', tree);
});

socket.on('/data/options', function (...args) {
  console.log('=> /data/options', ...args);
});

socket.on('/p/gocoin/invoice/success', function (invoice_data) {
  console.log('Incoming /p/gocoin/invoice/success socket.io event')

  var sessions_match = !!(Session.get('session_guid') === invoice_data.session_guid)
  var sec_hash_valid = !!(IW.appGetVal('gocoin_' + invoice_data.crypto_currency + '_token') === invoice_data.security_token)

  if (sec_hash_valid && sessions_match) {
    console.log('received new ' + invoice_data.crypto_currency + ' address...')
    IW.appSetVal('crypto_addr_' + invoice_data.crypto_currency, invoice_data.crypto_addr)
  }
});

// Internationalization
socket.on('/i18n/get/file', function (list) {
  console.log('=> /i18n/get/file:', list);

  for (var i = 0, llen = list.length; i < llen; i++) {
    var term = list[i];

    term._id = term.i18n_id;
    delete term.i18n_id;

    I18n.upsert(term._id, term);
  }
  Session.set('i18n_version', Math.random());
});

socket.on('/data/apps/update', function (data) {
  console.log('=> /data/apps/update', data);

  // convert app_guid to _id, do upsert
  for (var i = 0; i < data.length; i++) {
    var app = data[i];

    app._id = app.app_guid;

    Apps.upsert(app._id, app);

    // Create links based on app records which contain either categories[] or keywords[]
    if (app.categories || app.keywords) {
      IW.appLink(app);
    }
  }
});

socket.on('/data/product', function (prod) {
  console.log('=> /data/product', prod);

  // Mongo does not support dot in property names
  prod = removeDot(prod);

  prod._id = N(prod.product_id);

  Products.upsert(prod._id, prod);

  IW.appLink(prod);
});

socket.on('/data/product/remove', function (prod) {
  console.log('=> /data/product/remove', prod);
  Products.remove(prod.product_id);
  IW.appLinkRemove(prod.product_id);
});

socket.on('/data/product/ids', function (productIds) {
  const productsCount = _.values(productIds).length;
  console.log('=> /data/product/ids', productsCount);
  setTimeout(() => {
    const currentCount = productsCount > 0 ?
      Products.find({$or: _.map(productIds, (id) => ({product_id: id}))}).count() :
      0;
    if (currentCount < productIds.length) {
      const DEVICE = getDevice();
      socket.emit('/data/apps/update', DEVICE.DEVICE_GUID);
    }
  }, 10000);
});

socket.on('/data/languages', function (data) {
  console.log('=> /data/languages', data);

  Languages.remove({});
  for (let i = 0; i < data.length; i++) {
    const _id = N(data[i].language_id);
    data[i].order = i;
    Languages.upsert(_id, data[i])
  }

});

socket.on('/data/ui', function (data) {
  console.log('=> /data/ui', data);

  if (data.style) {
    Session.set('ui_css', data.style);
  }

  if (data.settings) {
    Session.set('ui_settings', data.settings);
  }
});

socket.on('/data/quicklaunch', function (apps) {
  console.log('=> /data/quicklaunch:', apps);
  const insertApps = [];

  for (let i = 0, alen = apps.length; i < alen; i++) {
    const app = apps[i];

    app._id = app.app_guid;

    const link = Links.findOne({app_guid: app._id, name: app.product_name});

    if (link) {
      app.icon = link.icon;
      app.product_id = link.product_id;
      app.start_page = link.start_page;
      app.title = link.title;

      if (app.icon && !_.contains(insertApps, app.app_guid)) {
        insertApps.push(app.app_guid);
        Quicklaunch.upsert(app._id, app);
        if (Quicklaunch.find().count() >= 6) return;
      }
    }
  }

});

socket.on('/data/settings', function (settings) {
  console.log('=> /data/settings', settings);
  _.extend(CONFIG, settings);
  Session.set('i18n_version', Math.random());
  Session.set('settings_version', JSON.stringify(settings));
});

socket.on('/i/app/logs/sigue', function (data) {
  console.log('=================SIGUE==============', data)
});


// Session value updates
socket.on('/s/data', function (data) {
  console.log('=> /s/data', data);
  if (!data) return
  const actual_data = data[Session.get('session_guid')];
  if (!actual_data) return

  for (let i in actual_data) {
    IW.appSetVal(i, actual_data[i], 'no_point_to_send_back_what_we_just_received');
  }
});

socket.on('/sso/login', function (data) {
  console.log('=> /sso/login ', data);
  // throw Error('Login is disabled because it does not work properly on server side');
  const user = getUser();
  if (user && user.customer_id) {
    console.log('Previous user is logged now.');
    return;
  }

  if (typeof data.customer_id !== 'undefined') {
    IW.appClearVals();

    Session.set('user', _.extend({}, data));
    IW.launchShoppingCart();
  }
});

socket.on('/qr/referrer/dispense', function (data) {
  console.log('=> /qr/referrer/dispense', data);
  // throw Error('Login is disabled because it does not work properly on server side');
  const user = getUser();
  if (user && user.customer_id) {
    console.log('Previous user is logged now.');
    return;
  }
  if (typeof data.customer_id !== 'undefined') {
    IW.appClearVals();

    Session.set('user', _.extend({}, data));
    IW.launchReferrerDispense();
  }
});

socket.on('/qr/payment/refund', function (data) {
  console.log('=> /qr/payment/refund', data);
  // throw Error('Login is disabled because it does not work properly on server side');
  const user = getUser();
  if (user && user.customer_id) {
    console.log('Previous user is logged now.');
    return;
  }
  if (typeof data.refund_payment_guid !== 'string' || typeof data.customer_id !== 'number') return;
  IW.appClearVals();
  Session.set('user', _.extend({}, data));
  IW.launchRefund(data);
});

//Resume session
socket.on('/s/resume/get/status', function (callback) {
  console.log('=> /s/resume/get/status');
  callback(null, {
    restoration_locked: Session.get('restoration_locked')
  })
});

socket.on('/s/resume/success', function (data) {
  console.log('=> /s/resume/success ', data);
  IW.appClearVals();

  // if (Session.get('restoration_locked')) return;

  createSessionLaunch().resume_session(data);
});

socket.on('/s/resume/failed', function (data) {
  console.log('=> /s/resume/failed ', data);
});

socket.on('/streets/send', function (data) {
  console.log('=> /streets/send', data);
  if (data && data.length) {
    console.log('Getting places =>', data);
    Session.set("money_transferring_places", data);
  }
});

socket.on('/d/test_mode', function (testMode) {
  console.log('=> /d/test_mode', testMode);
  Session.set('TEST_MODE', testMode);
});

socket.on('/h/epp/key', function (key) {
  console.log('=> /h/epp/key', key);
  Timer.reset();
  if (!Session.get('session_guid')) {
    if (!CONFIG.ATM_ENABLED) return;
    const params = FlowRouter.current().params;
    console.log(params);
    if (params.layoutName === 'index') {
      if (key === '1') return IW.mainMenu('en_US');
      if (key === '2' && Languages.findOne({lang: 'es_MX'})) return IW.mainMenu('es_MX');
    }
    if (params.layoutName === 'categories' || params.layoutName === 'category') {
      if (key === '1') return IW.launchATM();
      if (/^[2-9]$/.test(key)) {
        triggerMouseEvent($('.app_icon').eq(key - 2)[0], 'mousedown');
      }
      if (key === 'CANCEL') return IW.gotoIndex();
    }
    return;
  }
  IW.appSetLocalVal('pad_key', key);
});

socket.on('/d/acceptor/escrow', function (data, callback) {
  console.log('=> /d/acceptor/escrow', data);
  clearInterval(escrowInterval);
  const timeoutEnd = Date.now() + 10000;
  let lastTimeout = -1;

  IW.appSetLocalVal('payout_amount', data.payout_amount);
  IW.appSetLocalVal('debit_variance', data.variance);
  IW.appSetLocalVal('add_cash_to_balance_timeout_result', '');
  IW.appSetLocalVal('add_cash_to_balance_timeout_show', true);
  escrowInterval = setInterval(() => {
    if (IW.appGetVal('add_cash_to_balance_timeout_result')) {
      clearInterval(escrowInterval);
      IW.appSetLocalVal('add_cash_to_balance_timeout', 0);
      IW.appSetLocalVal('add_cash_to_balance_timeout_show', false);
      return callback(null, {accept: IW.appGetVal('add_cash_to_balance_timeout_result') === 'accept'});
    }

    if (timeoutEnd < Date.now()) {
      IW.appSetLocalVal('add_cash_to_balance_timeout', 0);
      return;
    }

    if (Math.ceil((timeoutEnd - Date.now()) / 1000) !== lastTimeout) {
      lastTimeout = Math.ceil((timeoutEnd - Date.now()) / 1000);
      IW.appSetLocalVal('add_cash_to_balance_timeout', lastTimeout);
    }
  }, 100);
});

socket.on('/d/maintenance_message', function (message) {
  console.log('=> /d/maintenance_message', message);
  Session.set('maintenance_message', message);
});

socket.on('/d/virtual', function (device) {
  console.log('=> /d/virtual', device);
  if (!(device && device.device_guid && device.device_auth_token)) {
    return console.error('Incorrect virtual device', device);
  }

  Session.set('DEVICE', {
    DEVICE_GUID: device.device_guid,
    AUTH_TOKEN: device.device_auth_token,
    VIRTUAL: true
  });

  socket.emit('/d/virtual/alive', device.device_guid, device.device_auth_token);
});

socket.on('/h/card_reader/inside', function (data) {
  console.log('=> [/h/card_reader/inside] Card inside CRW');
  Session.set('card_reader_last_activity', Date.now());

  if (IW.isSessionActive()) {
    return IW.appSetObject(data);
  }

  //If application going to launch - prevent atm launching
  if (FlowRouter.getParam('appName')) {
    return;
  }

  if (!CONFIG.ATM_ENABLED) return;
  IW.launchATM();
  IW.sessionSetObject('app', data);
});

socket.on('/h/card_reader/read', function (data) {
  console.log('=> [/h/card_reader/read] Card has been read');
  Session.set('card_reader_last_activity', Date.now());
  Session.set('card_reader_card_inside', true);
  return IW.appSetObject(data);
});

socket.on('/h/card_reader/removed', function (data) {
  console.log('=> [/h/card_reader/removed] Card has been removed');
  return IW.appSetObject(data);
});

socket.on('connect', function () {
  console.log('Socket.io connected to ' + CONFIG.SOCKETIO_SERVER, 'engine.transport.name', socket.io.engine.transport.name);
  Session.set('io.connected', true);

  if (CONFIG.CARD_READER_ROUTINE && CONFIG.ATM_ENABLED) {
    clearInterval(cardReaderInterval);
    cardReaderInterval = runCardReaderInterval();
  }
});

socket.on('disconnect', function () {
  console.log('Socket.io disconnected from ' + CONFIG.SOCKETIO_SERVER);
  Session.set('io.connected', false);
});

socket.on('close', function () {
  console.log('Socket.io closed connection to ' + CONFIG.SOCKETIO_SERVER);
  Session.set('io.connected', false);
});

socket.on('reconnect', function () {
  console.log('Attempting to reconnect to ' + CONFIG.SOCKETIO_SERVER);
});

// TODO : ATM TEST
socket.on('/atm/do_something', function (data) {
  console.log('=> /atm/do_something ', data);
  // appATMGoToPage(data.status)
});
