import createNumber from './common/number';

// ["loading", "number", "confirm", "tabbar"]
export default {
  number: createNumber('number', 'tabbar'),
  confirm: {
    next: 'tabbar',
    cancel: 'exit'
  },
  tabbar: {
    cancel: 'exit'
  },
};


