import React from 'react';
import {_ as i18n} from './helpers';
import IW from 'imports/iw_api';

const allTranslations = window.ALL_TRANSLATIONS; // FIXME

const createElement = React.createElement.bind(React);
React.createElement = function (tag, props, ...children) {
  let original = '';
  let translation = '';
  if (props && children) {
    if (props._ && typeof children[0] === 'string') {
      if (typeof props._ === 'object' && props._.length > 0) {
        original = children.splice(0, 1)[0];
        translation = i18n(original);
        const newChildren = stringToArgs(translation, props._);
        children = newChildren.concat(children);
      } else {
        original = children[0];
        translation = i18n(original);
        children[0] = translation;
      }
      allTranslations[children[0]] = {original, translation, time: Date.now()};
    }

    if (props.hasOwnProperty('_')) {
      props['data-i18n'] = props._;
      delete props._;
    }

    const keys = Object.keys(props)
    for (let i = 0, l = keys.length; i < l; i++) {
      if (keys[i].slice(-1) === '_') {
        original = props[keys[i]];
        translation = i18n(original);
        props[keys[i].slice(0, -1)] = translation
        props['data-i18n_' + keys[i].slice(0, -1)] = original;
        allTranslations[translation] = {original, translation, time: Date.now()};
        delete props[keys[i]]
      }
    }
  }
  return createElement(tag, props, ...children);
}


function stringToArgs(str, args) {
  const split = str.replace(/%%/g, 'ёё').split('%s');
  const result = [split[0].replace(/ёё/g, '%')];
  for (let i = 1; i < split.length; i++) {
    result.push(args[i - 1]);
    result.push(split[i].replace(/ёё/g, '%'));
  }
  return result;
}

document.addEventListener("keydown", function (event) {
  if (!event.ctrlKey || !event.shiftKey) return;
  const target = _.last(document.querySelectorAll(':hover'));
  if (!target) return;
  const firstNode = _.find(target.childNodes, (node) => node.nodeName === '#text');
  const value = (firstNode && firstNode.textContent) || target.text || target.textContent || target.innerText;
  if (!allTranslations[value]) return;
  const newTranslation = window.prompt(allTranslations[value].original, allTranslations[value].translation);
  if (newTranslation) {
    IW.setTranslation(
      allTranslations[value].original,
      newTranslation,
      allTranslations[value].type);
  }

}, true);
