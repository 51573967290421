import IW from 'imports/iw_api';

export default function (scope) {
    if(!(scope && typeof scope === 'string')) return {};

    const methods = {
      currentScope: scope,
      scopeExists: () => {
        const scope = Session.get(methods.currentScope);
        return !!(scope && _.isObject(scope) && !_.isEmpty(scope));
      },
      setVal: (field, value) => {
        IW.sessionSetVal(scope, field, value);
      },
      setObject: (inputObject) => {
        IW.sessionSetObject(scope, inputObject);
      },
      setObjectVal: (objectField, inputObject) => {
        methods.setVal(objectField, _.extend(methods.getVal(objectField), inputObject));
      },
      getVal: (field) => {
        return IW.sessionGetVal(scope, field);
      },
      clearVal: (field) => {
        let removeObj = IW.sessionClearVal(scope, field);
        methods.setObject(removeObj);
      }
    };

    return methods
}