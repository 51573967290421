import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import Prods from 'imports/collections/products';
import Amount from './Amount';
import getPageManager from 'imports/lib/page_manager';
import {N, countInDomAmounts} from 'imports/lib/helpers';
import CONFIG from 'imports/config';
import IW from 'imports/iw_api';

export default createContainer((params) => {
  const ROWS_COUNT = countInDomAmounts();
  const ITEM_COUNT = 3;

  const product = Prods.findOne(N(FlowRouter.getParam("productId")));

  function getChoices() {
    const choices = {};
    let ordered_choices = [];
    const cards = [];

    // Flatten the product map into [{prod: id, amount: price}, ...]
    if (product && product.product_denom_mapping) {
      _.each(product.product_denom_mapping, (product_choices, gw_product_id) => {
        const amountChoices = _.compact(_.map(product_choices, (c) => {
          if (N(c) > N(IW.appGetVal('customer_limit_amount'))) return;
          return {
            prod: gw_product_id,
            amount: N(c),
            name: product.product_name_mapping && product.product_name_mapping[gw_product_id],
          }
        }));

        _.each(amountChoices, (c) => {
          choices[c.amount] = c;
        })
      });
    }

    // Normalize the indexes (0,1,2 instead of 10,15,20)
    for (let c_id in choices) {
      if (!_.contains(CONFIG.IGNORE_PRODUCT_AMOUNTS, choices[c_id].amount)) {
        ordered_choices.push(choices[c_id]);
      }
    }

    // Split the array of all possible product values
    // in groups of 6
    ordered_choices = _.sortBy(ordered_choices, 'amount');
    ordered_choices = ordered_choices.chunk(ITEM_COUNT * ROWS_COUNT);

    $.each(ordered_choices, function (i, link) {
      cards.push(link.complete(ITEM_COUNT * ROWS_COUNT, {}).chunk(ITEM_COUNT));
    });

    return cards;
  }

  return {
    choices: getChoices(),
    pages: params.pages,
    onBack: () => getPageManager().back()
  };

}, Amount);
