import {Session} from './session'
import {store} from "./store";

export const Tracker = {
  autorun: function (handler) {
    Session.__getKeys = [];
    try {
      handler();
      const keys = Session.__getKeys;
      Session.__getKeys = null;
      scopes.push({
        handler,
        keys,
        lastHash: getHash(keys)
      })
    } catch (err) {
      console.error('Tracker:', err)
    }

    Session.__getKeys = null;
    return Tracker;
  },
  Dependency: function () {

  }
}

const scopes = [];

store.subscribe(() => {
  _.each(scopes, (scope) => {
    const currentHash = getHash(scope.keys);
    if (currentHash === scope.lastHash) return;
    scope.lastHash = currentHash;
    try {
      scope.handler();
    } catch (err) {
      console.error('Tracker routine:', err);
      scope.lastHash = '';
      scope.keys = []
    }
  });
});

function getHash(keys) {
  return _.reduce(keys, (res, key) => res + ':' + key + ':' + String(getHashValue(Session._get(key))), '')
}

function getHashValue(v) {
  return _.isObject(v) && v.hashCode() || v;
}
