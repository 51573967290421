import {createContainer} from 'imports/lib/react_createContainer';
import ListSlider from './ListSlider';
import getPageManager from 'imports/lib/page_manager';
import {capitalize, countInDomAmounts} from 'imports/lib/helpers';
import _ from 'lodash';

export default createContainer(function () {
  const ROWS_COUNT = countInDomAmounts();
  const ITEM_COUNT = 3;

  const items = _.map([
    {
      "Id": "Passport",
      "Name": "Passport",
    },
    {
      "Id": "IdCard",
      "Name": "State ID Card",
    },
    {
      "Id": "Driver's License",
      "Name": "Driver License",
    },
    {
      "Id": "Military",
      "Name": "Military ID",
    },
    {
      "Id": "MatriculaConsular",
      "Name": "Matricula Consular Card",
    },
    {
      "Id": "National ID",
      "Name": "National ID",
    },
    {
      "Id": "Alien Registration",
      "Name": "Residence Card",
    },
    {
      "Id": "Foreign DL",
      "Name": "Foreign Driver License",
    }], function (item) {
    return {
      _id: item.Id,
      s_customer_id_type: item.Id,
      product_name: capitalize(item.Name),
      enabled: true,
      app_guid: 'app_cashcheck_allTR',
      icon: '/resources/icons/mt/id_types/' + item.Name.replace(/[\s/]/g, '_').toLowerCase() + '.png',
      iconDefault: '/resources/icons/payout/identification/default.png',
      name: capitalize(item.Name),
    }
  });

  const choices = items.chunk(ITEM_COUNT * ROWS_COUNT).map((item) => item.complete(ITEM_COUNT * ROWS_COUNT, {}).chunk(ITEM_COUNT));

  return {
    choices,
    title: 'Select your identification document:',
    onBack: () => getPageManager().back(),
  }
}, ListSlider);