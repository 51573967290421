import {createContainer} from 'imports/lib/react_createContainer';
import BillerSearch from './BillerSearch'
import getPageManager from 'imports/lib/page_manager'
import IW from 'imports/iw_api';
import {Session} from "core/session";
import {createLazyExecution, countInDomBillSearch} from "../../../lib/helpers";
import {getIcon} from 'imports/lib/getIcon';

let initSearchingTimer = 0;

let lastValue = '';
let lastSearchingValue = '';
let searchInProgress = 0;

const getBillers = createLazyExecution((FIELD, v) => {
  if (!IW.appGetVal('getBillers_init')) {
    IW.appSetLocalVal('getBillers_init', true);
    searchInProgress = 0;
  }

  lastValue = v;
  if (Date.now() - searchInProgress < 10000) {
    return;
  }
  searchInProgress = Date.now();
  lastSearchingValue = v;
  IW.callCacheable('getBillers', {[FIELD]: v}, function (result) {
    searchInProgress = 0;
    if (lastSearchingValue !== lastValue) {
      getBillers(FIELD, lastValue);
      return;
    }
    IW.appSetLocalVal('in_searching', false);
    Session.set('founded_billers', result || []);
  });
}, 555);

const initSearching = () => {
  IW.appSetLocalVal('initial_search', false);
  clearTimeout(initSearchingTimer);
  initSearchingTimer = setTimeout(() => {
    IW.appSetLocalVal('initial_search', true);
  }, 5000)
};

export default createContainer((params) => {
  const LINE_COUNT = countInDomBillSearch();
  const ITEMS_COUNT = 4;
  const FIELD = params.field || 'biller_po_box';

  const doSearch = (v) => {
    initSearching();
    IW.appSetVal(FIELD, v);
    if (!v) return;
    IW.appSetLocalVal('started_searching', true);
    IW.appSetLocalVal('in_searching', true);

    getBillers(FIELD, v)
  };

  const onClearInput = () => {
    Session.set('founded_billers', []);
    IW.appSetLocalVal('started_searching', false);
    IW.appSetVal(FIELD, '')
  };

  const getSearchResults = () => {
    let res = Session.get('founded_billers') || [];

    res = _.map(res, (fb) => {
      return {
        biller_id: fb.biller_id,
        name: fb.biller_name,
        title: fb.biller_name,
        icon: fb.icon || getIcon(fb.biller_name) || '/resources/icons/other/ic_common_billpay.png',
        iconDefault: Session.get('icon') || '/resources/icons/other/ic_common_billpay.png',
        biller: fb
      }
    });

    return res.chunk(ITEMS_COUNT * LINE_COUNT).map((item) => item.complete(ITEMS_COUNT * LINE_COUNT, {}).chunk(ITEMS_COUNT));
  };

  return {
    label: params.searchType === 'name' ?
      'NAME' :
      'P.O.Box',
    tipImage: params.searchType === 'name' ?
      '/resources/images/bill_scanning/biller_name_tip.png' :
      '/resources/images/bill_scanning/po_box_tip.png',
    skipMessage: params.searchType === 'name' ?
      'If you still cannot find the biller, call support number %s' :
      'If you can not find right biller, please, tap on "Skip" button',
    supportNumber: IW.getUISettings().support_number,
    onBack: () => getPageManager().back(),
    onSkip: () => getPageManager().skip(),
    value: IW.appGetVal(FIELD),
    searchResult: getSearchResults() || [],
    startedSearching: IW.appGetVal('started_searching'),
    inSearching: IW.appGetVal('in_searching'),
    showSkipButton: !params.noSkip && IW.appGetVal('initial_search') && !IW.appGetVal('in_searching'),
    doSearch,
    onClearInput
  };

}, BillerSearch);
