import React, {Component} from 'react';
import {_} from 'imports/lib/helpers';
import Button from 'imports/ui/shared/button/Button';

export default class Account extends Component {
  render() {

    var res = null;
    const {a} = this.props;
    res = (
      <table>
        <tbody>
        <tr>
          <td style={{padding: "40px", textAlign: "center"}}>
            <table>
              <tr>
                {/*<!-- The black box on the left -->*/}
                <td rowSpan="2" style={{background: "#000"}}>
                  <h4>{_("Account Balance & Details")}</h4>
                </td>
                {/*<!-- Six buttons on the right: -->*/}
                <td>edit profile</td>
                <td>payment history</td>
                <td>invite friends!</td>
              </tr>
              <tr>
                <td>calling rates</td>
                <td>call history</td>
                <td>access numbers</td>
              </tr>
            </table>

          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2" className="center">
            <Button id="back" className="left button upper" onMouseDown={() => {}} style={{fontSize: "120%"}}
                    text_="Print Instructions"/>
            <Button id="next" className="right button upper" onMouseDown={() => {}} text_="Add Money"/>
          </td>
        </tr>
        </tfoot>
      </table>
    );
    return res;
  }
}

Account.propTypes = {};
