import {Session} from 'core/session'
import IW from "../../iw_api";

export default function (pageBack, pageBalance, pageAmount) {
  return function*() {
    Session.set('menu_disabled', false);

    return {
      back: function(){
        return pageBack
      },
      next: function() {
        let type = IW.appGetVal('selected_action');
        if (type === 'balance') return pageBalance;
        if (type === 'reload') return pageAmount;
        return 'exit';
      }
    }
  }
}

function ejectCard() {
  IW.callCardReader('close');
  Session.set('card_inserting', false);
}
