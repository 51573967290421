import React, {Component} from 'react';
import SlideItem from 'imports/ui/shared/slider/SlideItem';
import PaginationArrows from 'imports/ui/shared/pagination/PaginationArrows';
import {initSlider} from 'imports/lib/helpers';
import {PAGINATOR_SLIDER_MODE, SLIDE_ITEM_TYPES} from 'imports/lib/constants';
//TODO remove unused
export default class SliderLanguage extends Component {
  constructor(props) {
    super(props);
    this.id = 'SliderLanguage-' + Math.random().toFixed(5).slice(2);
    this.linkToSliderObj = null;
  }

  componentDidMount() {
    const slider = initSlider('#' + this.id, {navSlideSelector: null}, PAGINATOR_SLIDER_MODE.LEFT_RIGHT_ARROWS);
    this.linkToSliderObj = slider && slider[0] && $(slider[0]);
  }

  componentDidUpdate() {
    const slider = initSlider('#' + this.id, {navSlideSelector: null}, PAGINATOR_SLIDER_MODE.LEFT_RIGHT_ARROWS);
    this.linkToSliderObj = slider && slider[0] && $(slider[0]);
  }

  render() {
    const {items} = this.props;
    if (!items.length) return null;

    return (
      <div id={this.id} className="sliderContainer" style={{overflow: 'visible'}}>
        <div className="iosSlider">
          <div className="slider sliderLanguages">
            {_.map(items, function (slides, i) {
              return <SlideItem key={i} type={SLIDE_ITEM_TYPES.LANGUAGE} slides={slides}/>
            })}
          </div>
        </div>
        <PaginationArrows items={items}/>
      </div>
    );
  }
}

SliderLanguage.propTypes = {
  items: React.PropTypes.arrayOf(React.PropTypes.arrayOf(React.PropTypes.array))
};
