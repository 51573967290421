import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import {Session} from 'core/session';
import Tabbar from './Tabbar';
import Apps from 'imports/collections/apps';
import Account from 'imports/ui/apps/tabbar/wrappers/account/AccountContainer';
import Speed from 'imports/ui/apps/tabbar/wrappers/speed/SpeedContainer';
import Quick from 'imports/ui/apps/tabbar/wrappers/quick/QuickContainer';
import Family from 'imports/ui/apps/tabbar/wrappers/family/FamilyContainer';

export default createContainer((params) => {

  //Set Application style
  const curr_app_guid = FlowRouter.getParam('appName');
  let css = "",
    activeTab = "";

  console.log('APP_GUID changed to', curr_app_guid);

  if (curr_app_guid && Apps.findOne(curr_app_guid)) { // launched an app
    css = Apps.findOne(curr_app_guid).style;
  }

  function getActiveTab() {
    return Session.get('activeTab') || 'tab_account';
  }

  function setActiveTab(tab) {
    if (Session.get('activeTab') != tab) {
      Session.set('activeTab', tab);
    }
  }

  const Components = {
    tab_account: Account,
    tab_speed: Speed,
    tab_quick: Quick,
    tab_family: Family
  };

  return {
    appGuid: params.appGuid,
    pages: params.pages,
    css,
    activeTab: getActiveTab(),
    setActiveTab,
    components: Components
  };

}, Tabbar);
