import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';

export default class Error extends Component {
  render() {
    const {errorText, buttonBackText, buttonNextText, buttonExitText,
      onBack, onNext, onExit, supportNumber, errorTitle} = this.props;
    return (
      <table>
        <tbody>
        <tr>
          <td style={{padding: "40px", textAlign: "center", verticalAlign: "middle"}}>
            {errorTitle ?  <h1 _>{errorTitle}</h1> : null}
            <h1 _>{errorText}</h1>
            {buttonExitText ? (
              <h3>For support call {supportNumber}</h3>) :
              null}
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          {!buttonExitText ? (
            <td>
              <Button id="back" className="left button goto-main" text_={buttonBackText} onMouseDown={onBack}/>
              <Button id="next" className="right button goto-main" text_={buttonNextText} onMouseDown={onNext}/>
            </td>
          ) : (
            <td style={{textAlign: 'center'}}>
              <Button id="exit" className="button goto-main" text_={buttonExitText} onMouseDown={onExit}/>
            </td>
          )}

        </tr>
        </tfoot>
      </table>
    );
  }
}

Error.propTypes = {
  errorText: React.PropTypes.string,
  buttonBackText: React.PropTypes.string,
  buttonNextText: React.PropTypes.string,
  buttonExitText: React.PropTypes.string,
  onBack: React.PropTypes.func,
  onNext: React.PropTypes.func
};

Error.defaultProps = {
  errorText: 'Internal server error',
  buttonBackText: 'Back',
  buttonNextText: 'Next',
  onBack: () => {},
  onNext: () => {}
};
