import {createContainer} from 'imports/lib/react_createContainer';
import SelectAmount from './SelectAmount';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';
import CONFIG from 'imports/config';
import {hasVariant, number as N} from 'imports/lib/helpers';

const AMOUNTS = _.compact(_.map(CONFIG.ATM_AMOUNTS, (v) => {
  if (N(v) < 1) return null;
  return N(v);
})).slice(0, 8);

export default createContainer((params) => {
  const currentKey = IW.appGetVal('pad_key');
  IW.appSetLocalVal('pad_key', '');
  const account = _.find(IW.appGetVal('accounts'), (a) => a.id === IW.appGetVal('selected_account'));
  if (!account) throw Error('Account not found');
  const maxAmount = params.extract ?
    CONFIG.ATM_MAX_DISPENSE :
    CONFIG.ATM_MAX_TRANSFER;
  const minAmount = params.extract ?
    CONFIG.ATM_MIN_DISPENSE :
    CONFIG.ATM_MIN_TRANSFER;
  const customerMaxAmount = Math.min(N(account.amount || maxAmount), maxAmount);

  const cashMovement = CONFIG.ATM_IGNORE_COUNTERS ?
    {1: maxAmount} :
    (params.extract ?
      IW.appGetVal('dispenser_cash') :
      {1: customerMaxAmount});

  const amountInfo = _.map(AMOUNTS, (a) => ({
    amount: N(a),
    canDispense: minAmount <= N(a) && N(a) <= customerMaxAmount && hasVariant(N(a),
      _.keys(cashMovement),
      _.values(cashMovement))
  }));

  function handlePadKey() {
    if (currentKey === '9') return getPageManager().other();
    if (currentKey >= 1 && currentKey <= 8) return handleClickNext(amountInfo[currentKey - 1]);
    if (currentKey === '#') return getPageManager().back();
    if (currentKey === 'CANCEL') return getPageManager().cancel();
  }

  function handleClickNext(item) {
    if (!item.canDispense) return;
    IW.appSetVal('selected_amount', item.amount);
    getPageManager().next();
  }

  handlePadKey();
  IW.setVoicePage('atm_select_amount', _.compact(_.map(amountInfo,
    (item, i) => (item.canDispense ?
      {key: i + 1, amount: item.amount} :
      null))));
  return {
    nominals: amountInfo,
    onNext: handleClickNext,
    onOther: () => getPageManager().other(),
    onBack: () => getPageManager().back(),
  };

}, SelectAmount);
