import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import {Session} from 'core/session';
import Plus from './Plus';
import IW from 'imports/iw_api';
import getPageManager from 'imports/lib/page_manager';

const FIELDS = [
  {name: 'house', minLength: 5, maxLength: 10, description: 'House number'},
  {name: 'zip', minLength: 5, maxLength: 10, description: 'Zip code'},
]

const NUMPAD_LAYOUT = [
  [1, 2, 3],
  [4, 5, 6],
  [7, 8, 9],
  ['clear', 0, 'delete']
];


export default createContainer((params) => {
  const prefix = (params.prefix && params.prefix + '_') || '';
  const fields = params.fields || FIELDS
  _.each(fields, (f, i) => (f.index = i));

  function isValid(f) {
    const v = getValue(f.name);
    return v.length >= f.minLength && v.length <= f.maxLength && (/^\d+$/).test(v)
  }

  function clearInput() {
    IW.appSetVal(Session.get('focus'), '');
  }

  function getValue(name) {
    return IW.appGetVal(prefix + name);
  }

  function setValue(name, value) {
    return IW.appSetVal(prefix + name, value);
  }

  function getFocusedField() {
    return _.find(fields, (f) => f.name === Session.get('focus')) || {};
  }

  return {
    fields,
    values: _.map(fields, (f) => getValue(f.name)),
    numPadLayout: NUMPAD_LAYOUT,
    valid: isValid(getFocusedField()),
    value: getValue(getFocusedField().name),
    keyboardMaxLength: getFocusedField().maxLength,
    onValueChange: (v) => {
      setValue(getFocusedField().name, v);
    },
    goToNextStep: () => {
      const currentField = getFocusedField();
      if (!isValid(currentField))
        return;

      const invalidField = _.find(fields, (f) => !isValid(f))
      if (!invalidField && currentField === _.last(fields)){
        return getPageManager().next();
      }

      if (invalidField){
        return Session.set('focus', invalidField.name);
      }

      Session.set('focus', fields[currentField.index + 1].name);
    },
    goToPrevStep: () => {
      const currentField = getFocusedField();
      if (currentField.index > 0){
        return Session.set('focus', fields[currentField.index - 1].name);
      }
      getPageManager().back()
    },
    inputsCount: fields.length,
    clearInput,
    focus : getFocusedField().name
  };

}, Plus);
