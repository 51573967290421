
export function getIcon(name) {
  const modName = name.replace(/[^\w]/g, '').toLowerCase();
  let res = '';
  _.find(ICONS, (word, icon) => {
    if (!word) return false;
    if (word instanceof RegExp) {
      if (word.test(modName)) res = icon;
      return word.test(modName);
    }
    const current = modName.includes(word);
    if (current) res = icon;
    return current
  });
  return res;
}

const ICONS = {
  '/resources/icons/mtu/BoostMobile.png': 'Boost',
  '/resources/icons/mtu/GoSmartMobile.png': 'GoSmart',
  '/resources/icons/mtu/MetroPCS.png': 'MetroPCS',
  '/resources/icons/mtu/T-Mobile.png': 'Mobile',
  '/resources/icons/mtu/Verizon.png': 'Verizon',
  '/resources/icons/old/PayPal.png': 'PayPal',
  '/resources/icons/utilities/AcceptanceInsurance.png': 'Acceptance',
  '/resources/icons/utilities/ADT.png': 'ADT',
  '/resources/icons/utilities/AEP.png': 'AEP',
  '/resources/icons/utilities/AkronWaterSupply.png': 'Akron',
  '/resources/icons/utilities/AlabamaPower.png': 'Alabama Power',
  '/resources/icons/utilities/AlfaInsurance.png': 'AlfaInsurance',
  '/resources/icons/utilities/AllstateInsurance.png': 'AllstateInsurance',
  '/resources/icons/utilities/AmbitEnergy.png': 'Ambit Energy',
  '/resources/icons/utilities/AmerenMissouri.png': 'Ameren Missouri',
  '/resources/icons/utilities/AMEX.png': 'AMEX',
  '/resources/icons/utilities/AmigoEnergy.png': 'Amigo Energy',
  '/resources/icons/utilities/AnnistonWater.png': 'Anniston Water',
  '/resources/icons/utilities/AnthemBlueCross.png': 'Anthem Blue Cross',
  '/resources/icons/utilities/APGasElectric.png': 'AP Gas',
  '/resources/icons/utilities/AquaAmerica.png': 'Aqua America',
  '/resources/icons/utilities/AthensUtilities.png': 'Athens Utilities',
  '/resources/icons/utilities/AtmosEnergy.png': 'Atmos Energy',
  '/resources/icons/utilities/ATTHome.png': /ATT|AT&T/i,
  '/resources/icons/utilities/ATTMobility.png': /(ATT|AT&T).+obility/i,
  '/resources/icons/utilities/ATTUniversalCard.png': /(ATT|AT&T).+niversal/i,
  '/resources/icons/utilities/ATTUverse.png': /(ATT|AT&T).+verse/i,
  '/resources/icons/utilities/AzusaLightWater.png': 'Azusa Light',
  '/resources/icons/utilities/BCBSFloridaBlue.png': 'BCBS Florida',
  '/resources/icons/utilities/BirminghamWater.png': 'Birmingham Water',
  '/resources/icons/utilities/BlackHillsEnergy.png': 'Hills Energy',
  '/resources/icons/utilities/BooneElectricCooperativeMissouri.png': 'Boone Electric',
  '/resources/icons/utilities/BrowardCounty.png': 'Broward County',
  '/resources/icons/utilities/BurrtecWaste.png': 'Burrtec Waste',
  '/resources/icons/utilities/CableOne.png': 'Cable One',
  '/resources/icons/utilities/CentralAlabamaElectricCoop.png': 'Alabama Electric',
  '/resources/icons/utilities/CentralMissouriElectricCooperative.png': 'Missouri Electric',
  '/resources/icons/utilities/CenturyLink.png': 'CenturyLink',
  '/resources/icons/utilities/CharterSpectrum.png': 'Charter Spectrum',
  '/resources/icons/utilities/CirroEnergy.png': 'Cirro Energy',
  '/resources/icons/utilities/CitizensWater.png': 'Citizens Water',
  '/resources/icons/utilities/CityOfAlliance.png': /City.+Alliance/i,
  '/resources/icons/utilities/CityOfChinoHills.png': /City.+Chino.+Hills/i,
  '/resources/icons/utilities/CityOfChino.png': /City.+Chino/i,
  '/resources/icons/utilities/CityOfClevelandWater.png': /City.+Cleveland/i,
  '/resources/icons/utilities/CityOfLibertyMissouri.png': /City.+Liberty/i,
  '/resources/icons/utilities/CityOfRoma.png': /City.+Roma/i,
  '/resources/icons/utilities/CityOfSanBernardino.png': /City.+Bernardino/i,
  '/resources/icons/utilities/CityOfSanDiego.png': /City.+Diego/i,
  '/resources/icons/utilities/CityOfSantaAna.png': /City.+Santa/i,
  '/resources/icons/utilities/CityOfSunrise.png': /City.+Sunrise/i,
  '/resources/icons/utilities/CityOfTuscaloosa.png': /City.+Tuscaloosa/i,
  '/resources/icons/utilities/ClevelandHeightsWater.png': /Cleveland.+Heights/i,
  '/resources/icons/utilities/ClevelandPublicPower.png': /Cleveland.+Public/i,
  '/resources/icons/utilities/ColumbiaGasOhio.png': 'Columbia Gas',
  '/resources/icons/utilities/Comcast.png': 'Comcast',
  '/resources/icons/utilities/conEdison.png': 'con Edison',
  '/resources/icons/utilities/COX.png': 'COX',
  '/resources/icons/utilities/CPLRetailEnergy.png': 'CPL Retail',
  '/resources/icons/utilities/CucamongaValleyWaterDistrict.png': 'Cucamonga Valley',
  '/resources/icons/utilities/CuyahogaCounty.png': 'Cuyahoga',
  '/resources/icons/utilities/DirectEnergy.png': 'Direct Energy',
  '/resources/icons/utilities/DirecTV.png': 'Direc TV',
  '/resources/icons/utilities/DishNetwork.png': 'Dish Network',
  '/resources/icons/utilities/DominionGas.png': 'Dominion Gas',
  '/resources/icons/utilities/DukeEnergyProgress.png': 'Duke Energy',
  '/resources/icons/utilities/ECentralMissouriWaterSewer.png': 'entral Missouri Water',
  '/resources/icons/utilities/ElPasoElectric.png': 'El Paso Electric',
  '/resources/icons/utilities/EntrustEnergy.png': 'Entrust Energy',
  '/resources/icons/utilities/FirstChoicePower.png': 'First Choice',
  '/resources/icons/utilities/FloridaSunPass.png': 'Florida Sun',
  '/resources/icons/utilities/FontanaWater.png': 'Fontana Water',
  '/resources/icons/utilities/FortLauderdale.png': 'Fort Lauderdale',
  '/resources/icons/utilities/FPL.png': 'FPL',
  '/resources/icons/utilities/Frontier.png': 'Frontier',
  '/resources/icons/utilities/GeicoInsurance.png': 'Geico Insurance',
  '/resources/icons/utilities/GreenMountainEnergy.png': 'Green Mountain',
  '/resources/icons/utilities/GSWC.png': 'GSWC',
  '/resources/icons/utilities/HughesNet.png': 'Hughes Net',
  '/resources/icons/utilities/IlluminatingCompany.png': 'Illuminating',
  '/resources/icons/utilities/JustEnergy.png': 'Just Energy',
  '/resources/icons/utilities/KaiserPermanente.png': 'Kaiser Permanente',
  '/resources/icons/utilities/KCPL.png': 'KCPL',
  '/resources/icons/utilities/LACWD.png': 'LACWD',
  '/resources/icons/utilities/LADWP.png': 'LADWP',
  '/resources/icons/utilities/LAFCU.png': 'LAFCU',
  '/resources/icons/utilities/LakeCountyTreasurer.png': 'Lake County Treasurer',
  '/resources/icons/utilities/LibertyMutualInsurance.png': 'Liberty Mutual',
  '/resources/icons/utilities/LumbeeRiverElectric.png': 'Lumbee River',
  '/resources/icons/utilities/Mercury.png': 'Mercury',
  '/resources/icons/utilities/Met-Ed.png': 'Met',
  '/resources/icons/utilities/MetropolitanUtilitiesDistrict.png': 'Metropolitan',
  '/resources/icons/utilities/MiamiDadeWaterSewer.png': 'Dade Water',
  '/resources/icons/utilities/MidAmericanEnergy.png': 'MidAmerican',
  '/resources/icons/utilities/MissouriAmericanWater.png': 'Missouri American',
  '/resources/icons/utilities/MSD.png': 'MSD',
  '/resources/icons/utilities/MVWD.png': 'MVWD',
  '/resources/icons/utilities/NAGD.png': 'NAGD',
  '/resources/icons/utilities/NationalGrid.png': 'National Grid',
  '/resources/icons/utilities/NEORSD.png': 'NEORSD',
  '/resources/icons/utilities/NorthWestMissouriCellular.png': 'NorthWest Missouri',
  '/resources/icons/utilities/OhioEdison.png': 'Ohio Edison',
  '/resources/icons/utilities/OPPD.png': 'OPPD',
  '/resources/icons/utilities/Optimum.png': 'Optimum',
  '/resources/icons/utilities/PiedmontGas.png': 'Piedmont Gas',
  '/resources/icons/utilities/PortageCountyWater.png': 'Portage County',
  '/resources/icons/utilities/PotentiaEnergy.png': 'Potentia Energy',
  '/resources/icons/utilities/ProgressiveDirect.png': 'Progressive Direct',
  '/resources/icons/utilities/PWC.png': 'PWC',
  '/resources/icons/utilities/ReliantEnergy.png': 'Reliant Energy',
  '/resources/icons/utilities/RepublicServices.png': 'Republic Services',
  '/resources/icons/utilities/SafeAutoInsurance.png': 'Safe Auto',
  '/resources/icons/utilities/SDCC.png': 'San Diego C',
  '/resources/icons/utilities/SDGE.png': 'SDG',
  '/resources/icons/utilities/SEAGD.png': 'Alabama Gas',
  '/resources/icons/utilities/SoCalEdison.png': 'SoCalEdison',
  '/resources/icons/utilities/SoCalGas.png': 'SoCalGas',
  '/resources/icons/utilities/SouthRiverElectric.png': 'South River',
  '/resources/icons/utilities/SpectrumTWC.png': 'Spectrum T',
  '/resources/icons/utilities/SpireEnergy.png': 'Spire Energy',
  '/resources/icons/utilities/SprintWireless.png': 'Sprint Wireless',
  '/resources/icons/utilities/StarkCountyTreasurer.png': 'Stark County',
  '/resources/icons/utilities/StateFarmInsurance.png': 'State Farm',
  '/resources/icons/utilities/StreamEnergy.png': 'Stream Energy',
  '/resources/icons/utilities/SummitNaturalGasMissouri.png': 'Summit Natural',
  '/resources/icons/utilities/TECO.png': 'TECO',
  '/resources/icons/utilities/TelMex.png': 'TelMex',
  '/resources/icons/utilities/TexasGas.png': 'TexasGas',
  '/resources/icons/utilities/TrumbullCounty.png': 'TrumbullCounty',
  '/resources/icons/utilities/TWC.png': 'TWC',
  '/resources/icons/utilities/TXUEnergy.png': 'TXUEnergy',
  '/resources/icons/utilities/Utilities.png': 'Utilities',
  '/resources/icons/utilities/Verizon.png': 'Verizon',
  '/resources/icons/utilities/VisaMasterCard.png': 'Visa Master',
  '/resources/icons/utilities/Windstream.png': 'Windstream',
  '/resources/icons/visamc/CapitalOne.png': 'CapitalOne',
};

_.each(ICONS, (w, k) => {
  if (_.isString(w)) ICONS[k] = w.replace(/[^\w]/g, '').toLowerCase();
});