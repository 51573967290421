import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import {_} from 'imports/lib/helpers'

export default class ConfirmBillScanning extends Component {

  render() {
    const {items, onBack, onNext} = this.props;

    return (
      <table className="confirm-entered-data-container">
        <tbody>
        <tr>
          <td className="enter-user-data__user-info">
            <span className="transfer-description__accented" >{`${_('Confirm your biller info')}:`}</span>
          </td>
        </tr>
        <tr>
          <td>
            <table className="field-list">
              <tbody>
              {items.map((item, i) => {
                if (item) return (
                  <tr key={i}>
                    <td className="field-list__description" _>{item.title}</td>
                    <td className="field-list__data"><span>{item.value}</span></td>
                  </tr>
                );
                return '';
              })}
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2">
            <Button id="back" className="left button" text_="Back" onMouseDown={ () => onBack()}/>
            <Button id="next" className="right button" text_="Confirm" onMouseDown={ () => onNext() } />
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}