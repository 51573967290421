/**
 * Created by vitaliy on 13.03.17.
 */
import {createContainer} from 'imports/lib/react_createContainer';
import EnterUserDataContainer from './EnterUserDataContainer';
import {KEYBOARDS, TRANSFER_INPUTS_RESULT} from 'imports/lib/constants';
import IW from 'imports/iw_api';
import {countInDomSearch, validateText} from 'imports/lib/helpers';
import {Session} from 'core/session';
import moment from 'imports/lib/external/moment-with-langs';

const FIELDS_TEMPLATES = {
  text: {
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT_NUMBER,
    disable_clear: false
  },
  digits: {
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.NUMBER,
    disable_clear: false
  },
  amount: {
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.AMOUNT,
    disable_clear: false,
    validation: {
      required: true,
      pattern: /^(0|[1-9]\d{0,3})(\.\d{0,2})?$/
    },
    maxLength: 7
  },
  date: {
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.DATEPICKER,
    disable_clear: true
  },
  choice: {
    type_inputs_result: TRANSFER_INPUTS_RESULT.SCROLL_LIST,
    type_keyboard: KEYBOARDS.NONE,
    disable_clear: false
  },
};

export default createContainer((params) => {
  const requiredFields = IW.appGetVal('required_fields'); // [{name, description, format:{length,charSet}}]

  const choices = {};

  const fields = _.compact(_.map(requiredFields, (field) => {
    if (field.constantValue) {
      IW.appSetVal('required_field_' + field.name, field.constantValue);
      return;
    }

    if (!_.isEmpty(field.choices)) {
      choices[field.name] = field.choices;
    }
    return _.extend({
      name: field.name,
      describe: field.description,
      describe_output: field.description,
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      disable_clear: false,
      validation: {
        required: true,
        minLength: field.min,
        maxLength: field.max,
      },
      maxLength: field.max
    }, FIELDS_TEMPLATES[field.type])
  }));

  function onInput(v) {
    const {active, value} = getCurrentActiveField()
    IW.appSetVal(value, v);
    if (active.type_keyboard === KEYBOARDS.DATEPICKER) {
      IW.appSetVal(value + '_date', moment(v).format('YYYYMMDD'));
    }
  }

  function showNext() {
    const {active: currentActiveField, value: currentValueField} = getCurrentActiveField();
    if (!validateText(IW.appGetVal(currentValueField), currentActiveField.validation, 'required')) {
      return false
    }

    return !(searchingChoices && !IW.appGetVal(currentChoiceField));
  }

  function getCurrentActiveField() {
    const currentActiveField = fields[Session.get('current_active_field') || 0];
    return {
      active: currentActiveField,
      value: 'required_field_' + currentActiveField.name
    };
  }

  const currentField = getCurrentActiveField();
  const currentChoiceField = currentField.value + '_choice';
  const searchingChoices = choices[currentField.active.name];

  return {
    fields,
    prefix: 'required_field',
    onInput,
    onSelectItem: function (v, i) {
      IW.appSetVal(currentField.value, v);
      IW.appSetVal(currentChoiceField, searchingChoices[i].key);
    },
    scrollListResult: searchingChoices,
    showNext: showNext()
  };
}, EnterUserDataContainer);