import React from 'react';

export default class Map extends React.PureComponent {

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return false;
  }

  render() {
    const {onGoogleApiLoaded, language, ...rest} = this.props;

    return null;
    // return (
    //   <GoogleMapReact
    //     bootstrapURLKeys={{key: GOOGLE_API_KEY, language: language || 'en'}}
    //     defaultCenter={this.props.center}
    //     defaultZoom={this.props.zoom}
    //     {...rest}
    //
    //     options={(maps) => {
    //       return {
    //         zoomControl: false,
    //         mapTypeControl: false,
    //         scaleControl: false,
    //         streetViewControl: false,
    //         rotateControl: false,
    //         fullscreenControl: false
    //       }
    //     }}
    //
    //     onGoogleApiLoaded={onGoogleApiLoaded}
    //   />
    // )
  }
}