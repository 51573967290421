import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import Card from 'imports/ui/shared/card/CardContainer';
import safeRender from 'imports/ui/decorators/safeRender';

class SelectAmount extends Component {
  render() {
    const {nominals, onNext, onOther, onBack} = this.props;

    return (
      <table>
        <tbody>
        <tr>
          <td >
            <div className="atm-nominals">
              { nominals.map((item, i) => {
                return <Card key={i} className={ "atm-nominals__card " + (!item.canDispense ? " disabled" : "") }
                             text={"$" + item.amount} additional={i + 1} onCardClick={() => onNext(item)}/>
              })}
              {onOther ?
                <Card className="atm-nominals__card atm-nominals__card__next"
                      iconUrl="/themes/mayafs/images/icons/ic_btn_next.png" text_="Other amount" direction="rev_row"
                      additional="9"
                      onCardClick={() => onOther()}/> :
                null
              }
            </div>
          </td>
        </tr>
        </tbody>
        <tfoot className="atm-foot">
        <tr>
          <td colSpan="2">
            <Button id="back" className="left button" text_="Back" onMouseDown={onBack}/>
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

SelectAmount.propTypes = {
  minAmount: React.PropTypes.number,
  maxAmount: React.PropTypes.number,
  nominals: React.PropTypes.arrayOf(React.PropTypes.shape({
    amount: React.PropTypes.number,
    canDispense: React.PropTypes.bool
  })),
  onBack: React.PropTypes.func,
  onOther: React.PropTypes.func,
  onNext: React.PropTypes.func,
};

SelectAmount.defaultProps = {};

export default SelectAmount;
