/**
 * Created by vitaliy on 09.03.17.
 */
import {createContainer} from 'imports/lib/react_createContainer';
import PayoutCheckFree from './PayoutCheckFree';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';

function getTypeOfPaymentMethod(type) {
  if (type === 'Cash') return 'cash_pickup';
  if (type === 'Debit' || type === 'Credit' || type === 'ACH') return 'bank_deposit';
  return 'delivery'
}

function getPaymentMethod(type) {
  return {
    id: type,
    name: type.replace(/([a-z])([A-Z])/g, '$1 $2'),
    type: getTypeOfPaymentMethod(type)
  }
}

export default createContainer(() => {
  const paymentModes = _.map(IW.appGetVal('payment_methods'), getPaymentMethod) || [];

  return {
    paymentModes: paymentModes.length > 4 ?
      _.sortBy(paymentModes, 'displayIndex').slice(0, 8).chunk(2).complete(2) :
      _.sortBy(paymentModes, 'displayIndex').chunk(1),
    onBack: () => getPageManager().back(),
    onNext: (id, type) => {
      IW.appSetVal('selected_payment_method', id);
      getPageManager().next()
    },
  };
}, PayoutCheckFree);
