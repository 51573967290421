import {Session} from 'core/session';
import IW from 'imports/iw_api';
import {FlowRouter} from 'core/Router';
import PageWatcher from './PageWatcher';
import {nextWhen} from 'imports/lib/page_manager';

export default function (pageBack, pageNext, pageError) {
  const checkReaderWatcher = new PageWatcher(
    (sessionGuid) => IW.callCheckReader('receive_check', sessionGuid),
    (sessionGuid) => IW.callCheckReader('close', sessionGuid),
    ['bill_scanning_insert_bill']
  );

  return {
    bill_scanning_insert_bill: function*() {
      checkReaderWatcher.start();
      yield {
        back: pageBack,
        next: 'exit'
      };
      IW.appSetLocalVal('check_scan_arrived', false);
      IW.appSetVal('image_path_front', '');
      IW.appSetVal('image_path_back', '');
      IW.appSetLocalVal('check_scan_front', '');
      IW.appSetLocalVal('check_scan_back', '');
      yield 'sleep app check_scan_front';
      yield 'sleep app check_scan_back';
      checkReaderWatcher.stop();
      return 'bill_scanning_confirm_bill';
    },

    bill_scanning_confirm_bill: {
      back: 'back',
      next: function*() {
        IW.appSetVal('image_file_path_front', '');
        IW.callCheckReader('send_image');
        yield 'loading app image_file_path_front';

        IW.appSetLocalVal('check_reader_process_image_done', '');
        IW.callCheckReader('process_image');
        yield 'loading app check_reader_process_image_done';
        if (!IW.appGetVal('image_name')) return pageError;
        return pageNext;
      }
    }
  }
}
