import createNumber from './common/number';
import app_common from './app_common';
import createPaymentStatus from './common/payment_status';
import createAmount from './common/amount';
import IW from 'imports/iw_api';
import {Session} from 'core/session';
import CONFIG from 'imports/config';
import createBillerSearching from './common/createBillerSearching';
import createStartScanningInsertBill from './common/start_scanning_insert_bill';
import createStartScanning from './common/start_scanning';
import PageWatcher from './common/PageWatcher';
import {nextWhen} from '../lib/page_manager';

const DEFAULT_ENTERGY_AMOUNT = 800; // probably $950 trigger additional fields
const startScanningPage = CONFIG.BILL_SCANNING_GENMEGA ?
  'bill_scanning_insert_bill' :
  'start_scanning';

const checkReaderWatcher = new PageWatcher(
  (sessionGuid) => IW.callCheckReader('receive_check', sessionGuid),
  (sessionGuid) => IW.callCheckReader('close', sessionGuid),
  ['loading', 'adv_loading', 'cash_check_insert_check', 'confirm_photo_check_scan', 'cash_check_return_check']
);

// ["info", "number", "bill_scanning", "scan_info", "start_scanning", "inputs_billpayment", "bill_amount", "plus", "pay", "final", "email", "sent"]
export default _.extend({}, app_common, {
  index: () => 'number_login',

  redirect: function () {
    IW.appSetLocalVal('biller_predefined', true);
    return 'number_login'
  },

  check_free_without_customer: function* () {
    IW.appSetVal('customer_limit_amount', CONFIG.BILL_MAX_AMOUNT);
    yield* downloadBiller(IW.appGetVal('name'));
    backPages.info = 'exit';
    return 'info';
  },

  number_login: createNumber('exit', 'bill_scanning', {allowLandline: true}),

  // After Login
  on_login: function* () {
    const phoneNumber = IW.appGetVal('phone_number');

    if (!phoneNumber) {
      const customerData = IW.loginGetVal('customer_data_result');
      const customerPhoneNumber = customerData.mobile_number;

      IW.appSetVal('phone_number', customerPhoneNumber);
      IW.appSetVal('number', customerPhoneNumber)
    }

    return "bill_scanning"
  },

  on_login_pay_again: () => 'check_free_bill_amount_again',

  bill_scanning: function* () {
    disablePages();
    let account = IW.appGetVal('current_account') || {};
    IW.appSetLocalVal('current_account', _.extend({
      product_id: Session.get('product_id'),
      name: Session.get('title'),
      icon: Session.get('icon'),
      account: '',
      account2: '',
      gw_prod_id: IW.appGetVal('gw_prod_id'),
      location_id: IW.appGetVal('location_id'),
      product: IW.appGetVal('product'),
      biller_id: IW.appGetVal('biller_id'),
      biller_name: IW.appGetVal('biller_name'),
      biller_is_rent: IW.appGetVal('biller_is_rent'),
    }, account));
    account = IW.appGetVal('current_account');

    IW.appSetDefaults(/^required_|^payment_|^fee/);
    IW.appSetObject(account);
    Session.set('title', account.name);
    Session.set('icon', account.icon);

    const getAccountsWaiter = doCall('getAccounts', null, (data) => Session.set('accounts', data));

    if (!IW.appGetVal('biller_predefined')) {
      IW.appSetLocalVal('show_favorites', true);
      const getFavoritesWaiter = doCall('getFavorites', null, (data) => IW.appSetLocalVal('current_favorites', data));

      yield* getFavoritesWaiter();
      yield* getAccountsWaiter();

      if (_.isEmpty(Session.get('accounts')) && _.isEmpty(IW.appGetVal('current_favorites'))) {
        // bill_scanning:manual
        backPages.biller_search_by_name = 'number_login';
        backPages.info = 'biller_search_by_name';
        return 'biller_search_by_name';
      }
    }

    yield* getAccountsWaiter();

    IW.appSetLocalVal('bill_scanning_disabled', true);
    return {
      back: function () {
        return 'number_login'
      },
      next: function* ({params}) {
        const icon = params[0].icon || {};
        if (icon.type === 'account') {
          const accountId = +IW.appGetVal('account_id');
          account = _.find(Session.get('accounts'), (a) => a.account_id === accountId);
          if (!account) return 'exit';
          IW.appSetObject(account || {});

          yield* downloadBiller(account.name);

          Session.set('title', account.name);
          Session.set('icon', account.icon);
          backPages.bill_amount = 'bill_scanning';
          backPages.inputs_billpayment = 'bill_scanning';
          return 'check_free_bill_amount';
        }

        if (icon.type === 'favorite') {
          yield* downloadBiller(icon.name);

          Session.set('title', icon.name);
          Session.set('icon', icon.icon);
          IW.appSetVal('name', icon.name);
          IW.appSetVal('icon', icon.icon);

          backPages.info = 'bill_scanning';
          return 'info';
        }

        throw Error('Bad flow');
      },
      scan: function () {
        return 'none';
      },

      select: function () {
        backPages.biller_search_by_name = 'bill_scanning';
        backPages.info = 'biller_search_by_name';
        return 'biller_search_by_name';
      },

      manual: function* () {
        yield* downloadBiller(IW.appGetVal('biller_name'))
        backPages.info = 'bill_scanning';
        return 'info';
      }
    }
  },

  biller_search_by_name: createBillerSearching(() => backPages.biller_search_by_name, 'info'),

  info: function () {
    disablePages()

    IW.appSetLocalVal('payment_methods', null);
    IW.appSetDefaults(/fee|^required/);

    if (_.isEmpty(_.compact(IW.appGetVal('payment_options')))) {
      backPages.inputs_billpayment = backPages.info;
      return 'inputs_billpayment'
    }

    setTimeout(() => {
      _.each(_.compact(IW.appGetVal('payment_options')), (c) =>
        console.log('OPTION:', c.original.ProductDescription, c.original.Data || '', c.original.BillTypeName || '', c.original.BillerInstructions || '', c.original));
    }, 100)

    backPages.inputs_billpayment = 'info';
    return {
      back: function () {
        clearBiller();
        return backPages.info
      },
      next: function* () {
        IW.appSetLocalVal('biller_instruction', '')
        yield* doCall('setPaymentOption', null, IW.appSetObject)();
        return 'check_free_instruction';
      },
    }
  },

  check_free_instruction: function () {
    if (!IW.appGetVal('biller_instruction')) return 'inputs_billpayment';
    return {
      back: 'info',
      next: 'inputs_billpayment'
    }
  },

  inputs_billpayment: {
    back: () => backPages.inputs_billpayment,
    next: () => {
      if (IW.appGetVal('biller_name') === 'Entergy') {
        backPages.bill_amount = 'inputs_billpayment';
        backPages.to_bill_amount = 'inputs_billpayment';
        IW.appSetVal('bill_original_amount', DEFAULT_ENTERGY_AMOUNT);
        nextPages.redirect3 = 'check_free_bill_amount_entergy';
        enabledPages.check_free_bill_amount_entergy = true;
        return 'check_free_bill_required_data';
      }

      backPages.bill_amount = 'inputs_billpayment';
      return 'check_free_bill_amount'
    },
  },

  check_free_bill_amount: createAmount(() => backPages.bill_amount, () => {
    backPages.to_bill_amount = 'check_free_bill_amount';
    backPages.check_free_overpay = 'check_free_bill_amount';
    nextPages.check_free_overpay = 'check_free_bill_required_data';
    return 'check_free_overpay'
  }),

  check_free_bill_amount_again: createAmount('exit', () => {
    backPages.to_bill_amount = 'check_free_bill_amount_again';
    IW.appSetDefaults(/transaction_ref/);
    IW.appSetDefaults(/required_field/);
    backPages.check_free_overpay = 'check_free_bill_amount_again';
    nextPages.check_free_overpay = 'redirect2';
    return 'check_free_overpay'
  }),

  check_free_bill_required_data: function ({lastStep}) {
    if (!enabledPages.check_free_bill_required_data) return 'info2';

    Session.set('current_active_field', 0);
    Session.set('prev_current_active_field', 0);

    const requiredFields = IW.appGetVal('required_fields_first') || IW.appGetVal('required_fields');
    if (_.isEmpty(requiredFields)) return 'info2';

    IW.appSetLocalVal('required_fields', requiredFields);

    return {
      back: getBack('check_free_bill_required_data'),
      next: 'info2',
    }
  },

  info2: function () {
    backPages.check_free_overpay = getBack('info2');
    nextPages.check_free_overpay = 'check_free_payout';
    if (!enabledPages.info2) return 'check_free_overpay';

    setTimeout(() => {
      _.each(_.compact(IW.appGetVal('payment_options')), (c) =>
        console.log('OPTION:', c.original.ProductDescription, c.original.Data || '', c.original.BillTypeName || '', c.original.BillerInstructions || '', c.original));
    }, 100)

    IW.appSetVal('fee_id', '');
    backPages.check_free_overpay = 'info2';

    return {
      back: getBack('info2'),
      next: function* () {
        IW.appSetLocalVal('biller_instruction', '')
        IW.appSetVal('payment_options', IW.appGetVal('payment_options')) // send to server
        yield* doCall('setPaymentOption', null, IW.appSetObject)();
        return 'check_free_instruction2'
      },
    }
  },

  check_free_instruction2: function () {
    if (!IW.appGetVal('biller_instruction')) return 'check_free_overpay';
    return {
      back: 'info2',
      next: 'check_free_overpay'
    }
  },

  check_free_overpay: function* ({lastAction}) {
    if (lastAction === 'back') return backPages.check_free_overpay;
    if (!IW.appGetVal('fee_id')) return nextPages.check_free_overpay;

    const oldAmount = IW.appGetVal('bill_original_amount');
    yield* doCall('updateAmounts')();
    if (oldAmount === IW.appGetVal('bill_original_amount')) {
      return nextPages.check_free_overpay;
    }

    IW.appSetLocalVal('bill_original_amount_overpay',
      IW.appGetVal('bill_original_amount') - oldAmount);

    if (IW.appGetVal('biller_is_rent')) {
      IW.appSetLocalVal('bill_original_amount_new', IW.appGetVal('bill_original_amount'));
      IW.appSetVal('bill_original_amount', oldAmount);
    }

    return {
      back: backPages.check_free_overpay,
      next: nextPages.check_free_overpay,
    }
  },

  check_free_payout: function ({lastAction}) {
    function next() {
      IW.appSetLocalVal('check_free_payout_passed', true);
      enabledPages.check_free_bill_required_data_id_type = false;
      enabledPages.check_free_bill_required_data2 = false;
      enabledPages.redirect1 = false;
      return 'redirect1'
    }

    IW.appSetLocalVal('check_free_payout_passed', false);
    IW.appSetVal('is_pop_authorized', false);
    if (_.isEmpty(IW.appGetVal('payment_methods'))) {
      return 'check_free_bill_required_data_id_type';
    }

    // if (IW.appGetVal('payment_methods').length <=1){
    //   IW.appSetVal('selected_payment_method', IW.appGetVal('payment_methods')[0]);
    //   return next();
    // }

    IW.appSetDefaults(/required_field_TypeOfID/);
    _.each(IW.appGetVal('required_fields_second'), (f) => {
      IW.appSetDefaults(new RegExp('required_field_' + f.name));
    });
    IW.appSetLocalVal('required_fields_second', null);
    IW.appSetVal('required_fields', IW.appGetVal('required_fields_first') || null);

    const availableMethods = CONFIG.BILL_SCANNING_GENMEGA ?
      IW.appGetVal('payment_methods') :
      _.filter(IW.appGetVal('payment_methods'), (m) => !(/Check/.test(m)));
    IW.appSetVal('payment_methods', availableMethods);

    if (IW.appGetVal('payment_methods').length > 1) {
      return {
        back: getBack('check_free_payout'),
        next: next
      };
    }

    if (lastAction === 'back') return getBack('check_free_payout');

    IW.appSetVal('selected_payment_method', IW.appGetVal('payment_methods')[0]);
    return next();
  },

  check_free_pop_authorized: {
    cancel: 'check_free_payout',
    next: () => {
      IW.appSetVal('is_pop_authorized', true);
      return 'redirect1'
    }
  },

  redirect1: () => {
    if (!enabledPages.redirect1) return 'check_free_bill_required_data_id_type';
    return startScanningPage
  },

  ...createStartScanningInsertBill(() => getBack('redirect1'), 'check_free_bill_required_data_id_type', 'scanning_error'),
  start_scanning: createStartScanning(() => getBack('redirect1'), 'check_free_bill_required_data_id_type', 'scanning_error'),

  scanning_error: () => ({
    back: getBack('redirect1'),
    next: 'check_free_bill_required_data_id_type'
  }),

  check_free_bill_required_data_id_type: function ({lastAction}) {
    if (lastAction === 'back' && !enabledPages.check_free_bill_required_data_id_type) {
      return getBack('check_free_bill_required_data_id_type');
    }
    if (!enabledPages.check_free_bill_required_data_id_type) return 'redirect2';

    Session.set('current_active_field', 0);
    Session.set('prev_current_active_field', 0);

    IW.appSetDefaults(/required_field_TypeOfID/);
    _.each(IW.appGetVal('required_fields_second'), (f) => {
      IW.appSetDefaults(new RegExp('required_field_' + f.name));
    });

    IW.appSetLocalVal('required_fields_before_id_type', IW.appGetVal('required_fields'));
    IW.appSetLocalVal('required_fields', [{
      type: 'choice',
      name: 'TypeOfID',
      description: 'Type Of ID',
      max: 0,
      min: 0,
      choices: [
        {key: 'D', value: 'Driver’s license'},
        {key: 'N', value: 'State ID'},
        {key: 'P', value: 'Passport'},
        {key: 'M', value: 'Military ID'},
        {key: 'A', value: 'Alien registration card'},
      ]
    }]);

    return {
      back: () => {
        IW.appSetLocalVal('required_fields', IW.appGetVal('required_fields_before_id_type'));
        return getBack('check_free_bill_required_data_id_type')
      },
      next: () => {
        IW.appSetLocalVal('required_fields', IW.appGetVal('required_fields_before_id_type'));
        return 'redirect2'
      },
    }
  },

  // check_free_deposit_amount: function(){
  //   if (!enabledPages.check_free_deposit_amount) return 'check_free_bill_required_data2';
  //   return {
  //     back: getBack('check_free_deposit_amount'),
  //     next: 'check_free_bill_required_data2'
  //   }
  // },

  check_free_bill_required_data2: function ({lastAction}) {
    if (lastAction === 'back' && !enabledPages.check_free_bill_required_data2) {
      return getBack('check_free_bill_required_data2');
    }
    if (!enabledPages.check_free_bill_required_data2) return 'redirect2';

    Session.set('current_active_field', 0);
    Session.set('prev_current_active_field', 0);

    const requiredFields = IW.appGetVal('required_fields_second');
    if (_.isEmpty(requiredFields)) return getBack('check_free_bill_required_data2');

    IW.appSetLocalVal('required_fields', requiredFields);

    return {
      back: getBack('check_free_bill_required_data2'),
      next: 'redirect2',
    }
  },

  redirect2: function* ({lastAction}) {
    if (lastAction === 'back') return 'back';

    IW.appSetLocalVal('validate_transaction_message', '');
    IW.appSetVal('validate_transaction_delivery_message', '');
    IW.appSetVal('validate_transaction_consumer_message', '');
    yield* doCall('validateTransaction', null, (data) => _.each(data, (v, k) => IW.appSetLocalVal(k, v)))();

    const hasStatus = (status) => _.contains(IW.appGetVal('validate_transaction_status'), status);

    if (hasStatus('success')) {
      if (IW.appGetVal('validate_transaction_delivery_message')) {
        IW.appSetVal('validate_transaction_delivery_message', IW.appGetVal('validate_transaction_delivery_message'))
        return 'check_free_delivery';
      }
      if (IW.appGetVal('validate_transaction_consumer_message')) {
        IW.appSetVal('validate_transaction_consumer_message', IW.appGetVal('validate_transaction_consumer_message'))
        return 'check_free_consumer';
      }
      return 'redirect3'
    }

    if (!IW.appGetVal('validate_transaction_success')) {
      if (IW.appGetVal('validate_transaction_consumer_message')) {
        IW.appSetVal('validate_transaction_consumer_message', IW.appGetVal('validate_transaction_consumer_message'))
        return 'check_free_consumer';
      }
      return 'check_free_error'
    }

    if (hasStatus('cannot_process')) {
      return 'check_free_error'
    }

    if (hasStatus('invalid_account')) {
      IW.appSetVal('account_is_invalid', true);
      return 'inputs_billpayment'
    }

    if (hasStatus('bad_account')) {
      IW.appSetLocalVal('validate_transaction_message', 'Your account cannot be accepted for payment');
      return 'check_free_error'
    }

    if (hasStatus('pop_authorization')) {
      return 'check_free_pop_authorized'
    }

    if (hasStatus('required_fields_invalid')) {
      if (IW.appGetVal('check_free_payout_passed')) enabledPages.check_free_bill_required_data2 = true;
      else enabledPages.check_free_bill_required_data = true;
      return 'check_free_required_fields_invalid'
    }

    if (hasStatus('required_field_type_of_id')) {
      enabledPages.check_free_bill_required_data_id_type = true;
      return 'check_free_bill_required_data_id_type'
    }

    if (hasStatus('required_fields')) {
      if (!IW.appGetVal('check_free_payout_passed')) {
        enabledPages.check_free_bill_required_data = true;
        IW.appSetLocalVal('required_fields_first', IW.appGetVal('required_fields'));
      }

      if (IW.appGetVal('check_free_payout_passed')) {
        enabledPages.check_free_bill_required_data2 = true;
        const requiredFieldsFirst = _.map(IW.appGetVal('required_fields_first'), (f) => f.name);
        const requiredFieldsSecond = _.filter(IW.appGetVal('required_fields'), (f) => !_.contains(requiredFieldsFirst, f.name));
        IW.appSetLocalVal('required_fields_second', requiredFieldsSecond);
      }

      if (_.find(IW.appGetVal('required_fields'), (f) => f.name === 'CheckAccountNumber')) {
        enabledPages.redirect1 = true;
        return startScanningPage
      }

      return IW.appGetVal('check_free_payout_passed') ?
        'check_free_bill_required_data2' :
        'check_free_bill_required_data'
    }

    if (hasStatus('payment_options')) {
      enabledPages.info2 = true;
      return 'info2'
    }

    return 'check_free_error'
  },

  check_free_error: {
    back: () => getBack('check_free_error')
  },

  check_free_required_fields_invalid: {
    back: () => getBack('check_free_error')
  },

  check_free_delivery: {
    back: 'back',
    next: 'redirect3'
  },

  check_free_consumer: {
    back: 'back',
    next: 'redirect3'
  },

  redirect3: function ({lastAction}) {
    if (lastAction === 'back') return 'back';
    if (nextPages.redirect3) return nextPages.redirect3;
    if (IW.appGetVal('selected_payment_method') !== 'Check') return 'pay';
    return 'cash_check_insert_check'
  },

  // TODO: Copy from check cashing. Move it into ./common
  cash_check_insert_check: function* () {
    checkReaderWatcher.start();
    yield {
      back: 'check_free_payout',
      next: 'exit'
    };
    IW.appSetLocalVal('check_scan_arrived', false);
    IW.appSetVal('image_path_front', '');
    IW.appSetVal('image_path_back', '');
    IW.appSetLocalVal('check_scan_front', '');
    IW.appSetLocalVal('check_scan_back', '');
    yield 'sleep app check_scan_front';
    yield 'sleep app check_scan_back';
    return 'confirm_photo_check_scan'
  },


  confirm_photo_check_scan: {
    back: function () {
      IW.appSetLocalVal('return_check_message', 'Scan you check again');
      IW.appSetLocalVal('return_check_next_page', 'cash_check_insert_check');
      return 'cash_check_return_check'
    },
    next: function* () {
      IW.appSetVal('config_auto_approve', !!CONFIG.CASH_CHECK_AUTO_APPROVE);
      IW.appSetVal('config_clerk_review', !!CONFIG.CASH_CHECK_CLERK_REVIEW);

      IW.appSetVal('image_file_path_front', '');
      IW.callCheckReader('send_image');
      IW.appSetLocalVal('loading_message', 'Uploading scans...');
      yield 'loading app image_file_path_front';
      IW.appSetLocalVal('loading_message', '');

      IW.appSetVal('s_customer_check_path_front', IW.appGetVal('image_file_path_front'));
      IW.appSetVal('s_customer_check_path_back', IW.appGetVal('image_file_path_back'));

      yield* doCall('doCheckPayment');

      IW.appSetVal('session_stored', false);
      IW.paymentDo();

      IW.appSetLocalVal('payment_status', '');

      if (CONFIG.SHOW_POPULAR_PRODUCTS_ADVERTISEMENT) {
        IW.appSetLocalVal('popular_products_id', null)
        IW.call('getPopularProductsId');
      }

      const startTime = Date.now();
      yield nextWhen(() => Date.now() - startTime > 5000);
      yield 'adv_loading';

      yield 'loading app payment_status';

      const isPaid = IW.appGetVal('payment_status') === 'paid';
      if (isPaid) {
        IW.callCheckReader('swallow_check');
        yield 'loading app check_reader_swallow_check_done';
      }

      checkReaderWatcher.stop();

      return isPaid ?
        'final' :
        'payment_status'
    }
  },

  cash_check_return_check: function* () {
    IW.appSetLocalVal('check_removed', false);
    checkReaderWatcher.stop();
    const elapsedTime = Date.now() + 10000;
    const timer = setTimeout(() => IW.appSetLocalVal('check_removed', true), 3000);
    yield 'sleep app check_removed';
    clearTimeout(timer);
    yield {
      next: 'cash_check_insert_check',
    };
    yield 'sleep ' + Math.max(elapsedTime - Date.now(), 0);
    return 'cash_check_insert_check';
  },

  check_free_bill_amount_entergy: createAmount(() => {
    IW.appSetVal('bill_original_amount', DEFAULT_ENTERGY_AMOUNT)
    return getBack('check_free_bill_amount_entergy')
  }, function* () {
    backPages.check_free_overpay = 'check_free_bill_amount_entergy';
    nextPages.check_free_overpay = 'pay';

    IW.appSetLocalVal('validate_transaction_message', '');
    IW.appSetVal('validate_transaction_delivery_message', '');
    IW.appSetVal('validate_transaction_consumer_message', '');
    yield* doCall('validateTransaction', null, (data) => _.each(data, (v, k) => IW.appSetLocalVal(k, v)))();

    if (!_.contains(IW.appGetVal('validate_transaction_status'), 'success')) {
      return 'check_free_error'
    }

    return 'check_free_overpay'
  }, () => IW.appSetVal('bill_original_amount', 0)),


  payment_status: createPaymentStatus('number_login', 'check_free_bill_amount_again', 'pay'),
});


function clearBiller() {
  IW.appSetVal('biller_name', '');
  IW.appSetVal('biller_id', -1);
  IW.appSetVal('biller_is_rent', false);
  IW.appSetVal('address_sequence', -1);
  IW.appSetVal('biller_city_state_zip', '');
  IW.appSetVal('payment_options', null);
}

function doCall(method, requestData, onData) {
  IW.appSetLocalVal(method + '_done', false);
  IW.call(method, requestData, (__, data) => {
    _.isFunction(onData) && onData(data);
    IW.appSetLocalVal(method + '_done', true);
  })
  return function* () {
    yield 'loading app ' + method + '_done';
  }
}

function* downloadBiller(billerName) {
  IW.appSetLocalVal('getBillers_result', null);
  IW.callCacheable('getBillers', {biller_name: billerName}, IW.appSetLocalVal.bind(IW, 'getBillers_result'));
  yield 'loading app getBillers_result';

  const billers = _.filter(IW.appGetVal('getBillers_result'), (b) => b.biller_name === billerName);

  if (_.isEmpty(billers)) throw Error('Biller not found');
  if (billers.length > 1) throw Error('Billers too many');

  const b = _.first(billers);

  IW.appSetVal('biller_id', b.biller_id || -1);
  IW.appSetVal('biller_name', b.biller_name || '');
  IW.appSetVal('biller_is_rent', b.biller_is_rent || false);
  IW.appSetVal('address_sequence', b.address_sequence || -1);
  IW.appSetVal('biller_city_state_zip', b.biller_city_state_zip || '');
  IW.appSetVal('payment_options', b.payment_options || null);
  IW.appSetVal('payment_methods', b.payment_methods || null);
  IW.appSetVal('biller_unknown', false);
}

function getBack(currentPage) {
  const order = [
    'check_free_bill_required_data',
    'info2',
    'check_free_payout',
    'redirect1',
    'check_free_bill_required_data_id_type',
    'check_free_bill_required_data2',
    'check_free_bill_amount_entergy',
    'check_free_error',
  ];


  for (let i = 0; i < order.length; i++) {
    const orderElement = order[i];
    if (orderElement === currentPage) {
      for (let j = i - 1; j >= 0; j--) {
        if (enabledPages[order[j]] || order[j] === 'check_free_payout') return order[j];
      }
    }
  }

  return backPages.to_bill_amount;
}

function disablePages() {
  enabledPages.check_free_bill_required_data = false;
  enabledPages.info2 = false;
  enabledPages.check_free_payout = false;
  enabledPages.redirect1 = false;
  enabledPages.check_free_bill_required_data_id_type = false;
  enabledPages.check_free_bill_required_data2 = false;
  enabledPages.check_free_error = false;
  enabledPages.check_free_bill_amount_entergy = false;
}


const backPages = createSessionProxy('_back_');
const nextPages = createSessionProxy('_next_');
const enabledPages = createSessionProxy('_enabled_');

function createSessionProxy(prefix) {
  return new Proxy({}, {
    get(__, key) {
      return IW.appGetVal(prefix + key)
    },
    set(__, key, value) {
      IW.appSetLocalVal(prefix + key, value);
      return true
    }
  })
}
