import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import RecipientNotification from './RecipientNotification';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';


export default createContainer((params) => {
  return {
    goNext: (type) => {
      if(type) IW.appSetVal("recipient_notification_type", type);
      getPageManager().next()
    },
    goBack: () => getPageManager().back(),
    sendingFromNumber: IW.appGetVal('sender_number'),
    sendingFromCountry: IW.appGetVal('sender_address_country')
  };

}, RecipientNotification);
