import {createContainer} from 'imports/lib/react_createContainer';
import CardReloadActions from './CardReloadActions';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';
import CONFIG from 'imports/config';
import {getName} from 'imports/lib/helpers';

export default createContainer((params) => {
  const currentKey = IW.appGetVal('pad_key');
  IW.appSetLocalVal('pad_key', '');

  function handlePadKey() {
    if (currentKey === '1') return handleClickNext('balance');
    if (currentKey === '2') return handleClickNext('reload');
    if (currentKey === 'CANCEL') return getPageManager().cancel();
  }

  function handleClickNext(type) {
    IW.appSetVal('selected_action', type);
    getPageManager().next();
  }

  handlePadKey();

  return {
    fullName: getName(IW.appGetVal('card_name')),
    onNext: handleClickNext,
    onBack: () => getPageManager().back()
  };

}, CardReloadActions);