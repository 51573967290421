import React, {Component} from 'react';
import SmallLoading from 'imports/ui/shared/loading/SmallLoading';
import {initVerticalSlider} from 'imports/lib/helpers';

export default class ShoppingCartLoading extends Component {

  componentDidMount() {
    const { optionsSlider } = this.props;
    if (this.refs.slider){
      initVerticalSlider(Object.assign(optionsSlider));
    }
  }

  componentDidUpdate(prevProps) {
    const { optionsSlider } = this.props;
    if (this.refs.slider){
      initVerticalSlider(Object.assign(optionsSlider));
    }
  }

  render() {
    const {shoppingCart, shoppingCartPayedCount, shoppingCartCount, userName} = this.props;
    const items = _.map(shoppingCart, (item) => {
      let loading = <SmallLoading />;
      if (item.paymentStatus === 'success') loading = <div className="payed-product"/>;
      if (item.paymentStatus === 'init') loading = <div className="payed-product" data-disabled={true}/>;

      return (
        <div className="vertical-slider-row" key={item.id}>
          <div className="vertical-slider-row__checkbox">
            { loading }
          </div>
          <div className="vertical-slider-row__icon">
            <img src={item.icon} className="product-icon"/>
          </div>
          <div>
            {item.name}
          </div>
        </div>);
    });
    return (
      <table className="shopping-cart-loading-page">
        <tbody>
        <tr>
          <td>
            {userName ?
              <div className="center user-greeting" _={[<span>{userName}</span>]}>Hi there, %s!</div>:
              <div className="center user-greeting">Hi there!</div>}
            <table className="shopping_table m-t-40">
              <thead>
              <tr>
                <td className="upper center">
                  You payment progress for shopping cart
                </td>
              </tr>
              </thead>
            </table>
            <div className="scroll-area">
              <div ref="slider" className="iosVerticalSlider">
                <div className="slider">
                  {items}
                  <br/>
                </div>
              </div>
            </div>
            <table className="shopping_table">
              <tfoot>
              <tr>
                <td>
                  <div className="center">{shoppingCartPayedCount} / {shoppingCartCount}</div>
                </td>
              </tr>
              </tfoot>
            </table>
          </td>
        </tr>
        </tbody>
      </table>
    );
  }
}

ShoppingCartLoading.propTypes = {
  shoppingCart: React.PropTypes.arrayOf(React.PropTypes.shape({
    paymentStatus: React.PropTypes.string,
    name: React.PropTypes.string,
    icon: React.PropTypes.string,
    id: React.PropTypes.string
  })),
  shoppingCartPayedCount: React.PropTypes.number,
  shoppingCartCount: React.PropTypes.number,
  optionsSlider : React.PropTypes.object,
  userName : React.PropTypes.string
};
ShoppingCartLoading.defaultProps = {
  userName : ''
};