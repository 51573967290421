import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import getPageManager from 'imports/lib/page_manager'

export default class Notice extends Component {

  // componentWillMount() {
  //   if (IW.appGetVal('showedNotice') && IW.appGetVal('showedNotice') == this.props.pages.next) {
  //
  //     IW.appSetVal('showedNotice', this.props.pages.prev);
  //     goToAppPage(this.props.pages.prev);
  //
  //   } else if ((IW.appGetVal('showedNotice') && IW.appGetVal('showedNotice') == this.props.pages.prev)) {
  //
  //     IW.appSetVal('showedNotice', this.props.pages.next);
  //     goToAppPage(this.props.pages.next);
  //
  //   }
  // }

  render() {
    return (
      <table>
        <tbody>
        <tr>
          <td className="yellow" style={{textAlign : "center"}}>
            <h1 className="upper" _>Notice</h1>
            <h2 className="upper" _>This kiosk does not give change</h2>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td>
            <Button id="back" className="left button goto-main" onMouseDown={() => getPageManager().cancel()} text_="Exit"/>
            <Button id="next" className="right button" onMouseDown={() => getPageManager().next()} text_="OK"/>
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}
