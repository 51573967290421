import _ from 'lodash'
import {Session} from './session'

class Collection {
  constructor(dbSelector) {
    this.dbSelector = dbSelector || (Math.random().toString(36).slice(2));
    this._db = {}
  };

  upsert(id, obj) {
    Session.set('collection_' + this.dbSelector, Math.random())
    this._db[id] = _.extend({_id: id}, obj);
    return this;
  };

  findOne(selector) {
    return _.first(this.find(selector).fetch());
  }

  find(selector, processor) {
    Session.get('collection_' + this.dbSelector);
    if (selector === 'DEVICE') {
      this.found = [{DEVICE_GUID: 'atmcm', AUTH_TOKEN: 'atmcm'}];
      return this;
    }

    if (_.isNumber(selector)) {
      this.found = _.filter(this._db, (item) => {
        return _.values(item).some((el) => el === selector);
      });
      return this;
    }

    if (_.isEmpty(selector) && !_.isNumber(selector)) {
      this.found = _.values(this._db);
      return this;
    }

    if (_.isPlainObject(selector)) {
      const isValueMatchIn = (inSelector, value) => {
        const innerIn = !_.isArray(inSelector) ? [inSelector] : inSelector;
        const innerValue = !_.isArray(value) ? [value] : value;
        for (let i = 0; i < innerIn.length; i++) {
          if (_.isRegExp(innerIn[i])) {
            for (let j = 0; j < innerValue.length; j++) {
              if (innerIn[i].test(innerValue[j])) return true;
            }
          } else {
            for (let j = 0; j < innerValue.length; j++) {
              if (innerIn[i] === innerValue[j]) return true;
            }
          }
        }
        return false
      };
      const isValueMatch = (selector, value) => {
        if (_.has(selector, '$not')) return selector.$not !== value;
        if (_.has(selector, '$in')) return isValueMatchIn(selector.$in, value);
        return selector === value;
      };
      const isMatch = (selector, obj) => {
        const notMatchValue = _.find(selector, (selector, key) => {
          if (key === '$or') {
            const orMatch = _.find(selector, (selector) => isMatch(selector, obj));
            return !orMatch;
          }
          return !isValueMatch(selector, obj[key])
        });
        return !notMatchValue
      };

      this.found = _.filter(this._db, (obj) => isMatch(selector, obj));

      const getSorted = (order, key) => {
        return (a, b) => {
          if (order === 1) {
            if (_.isNumber(a[key])) return a[key] - b[key];
            return a[key] > b[key] ? 1 : -1;
          }
          if (order === -1) {
            if (_.isNumber(a[key])) return b[key] - a[key];
            return a[key] > b[key] ? -1 : 1;
          }
        };
      };

      if (_.has(processor, 'sort')) {
        _.forInRight(processor.sort, (value, key) => {
          this.found.sort(getSorted(value, key));
        });
      }

      return this;
    }

    this.found = [this._db[this.selector] || null];
    return this;
  };

  fetch() {
    return this.found
  };

  count() {
    return _.size(this.found)
  };

  forEach(cb) {
    return _.map(this.found, cb)
  }

  remove(selector) {
    Session.set('collection_' + this.dbSelector, Math.random());
    const data = this.find(selector).fetch();
    _.each(data, (obj) => {
      delete this._db[obj._id];
    });
    return this;
  }
}

export const Meteor = {
  Collection: Collection,
  subscribe: function () {
    return Meteor;
  },
  settings: {
    public: {}
  },
  startup: function (fn) {
    fn();
  },
  status() {
    return {
      connected: true
    }
  },
  current() {
    return 1;
  },
  setInterval: (...args) => setInterval(...args),
  clearInterval: (...args) => clearInterval(...args),
  setTimeout: (...args) => setTimeout(...args),
  clearTimeout: (...args) => clearInterval(...args),
};
