import {createContainer} from 'imports/lib/react_createContainer';
import EnterUserData from './EnterUserData';
import {
  KEYBOARD_AMOUNT_3ROW,
  KEYBOARD_EMAIL,
  KEYBOARD_EMAIL_SUFFIX_SECOND,
  KEYBOARD_NUMBER_3ROW,
  KEYBOARD_TEXT,
  KEYBOARDS,
  MONEYTRANSFER_USER_AVAILABLE_FIELDS,
  TRANSFER_INPUTS_RESULT,
  VIAMERICA_INTERNAL_ERRORS
} from 'imports/lib/constants';
import IW from 'imports/iw_api';
import {Session} from 'core/session';
import {
  countInDomSearch,
  createLazyExecution,
  getValueFromSession,
  S,
  setValueToSession,
  triggerMouseEvent,
  validateText
} from 'imports/lib/helpers';
import getPageManager from 'imports/lib/page_manager';
import moment from 'imports/lib/external/moment-with-langs';
import {getDevice} from '../../../api/device';
import __ from 'lodash'

function getCities(v) {
  IW.appSetLocalVal('enter_user_data_loading', true);

  IW.callCacheable('getCities', v, function (cities) {
    Session.set('money_transferring_cities', _.map(cities, function (city) {
      IW.appSetLocalVal('enter_user_data_loading', false);

      return _.extend(city, {'key': city.id_city, 'value': `${city.city_name}, ${city.state_name}`})
    }));
  });
}

function getTowns(v) {
  IW.appSetLocalVal('enter_user_data_loading', true);

  IW.callCacheable('getTowns', v, function (cities) {
    IW.appSetLocalVal('enter_user_data_loading', false);

    Session.set('money_transferring_towns', _.map(cities, function (town) {
      return _.extend(town, {'key': town.Id, 'value': `${town.Name}`})
    }));
  });
}

const getLocation = createLazyExecution((v, type) => {
  IW.appSetLocalVal('enter_user_data_loading', true);

  IW.callCacheable('getLocation', {
    value: v,
    type: type,
    _forCache: IW.appGetVal('recipient_payment_mode_type')
  }, function (cities) {
    IW.appSetLocalVal('enter_user_data_loading', false);

    Session.set('money_transferring_locations', _.map(cities, function (city) {
      return _.extend(city, {
        'key': city.id_city,
        'value': `${city.city_name}, ${city.state_name}, ${city.country_name}`
      })
    }));
  });
}, 500);

const getStreets = createLazyExecution(function (obj) {
  IW.callCacheable('getStreets', obj, (data) => Session.set('money_transferring_places', data || []));
}, 1);

const getStreetsProfile = createLazyExecution(function (val, state, country, device_guid) {
  IW.getStreets(val, state, country, device_guid, (err, places) => {
    console.log('getStreetsProfile', err, places);
    Session.set('money_transferring_places', places);
  })
}, 500);

let lastSearchingValue = ''

export default createContainer((params) => {
  const LINE_COUNT = countInDomSearch();

  const prefixType = params.prefix || ''; //params.isSender ? 'sender' : (params.isRecipient ? 'recipient' : (params.isBank ? 'bank' : ''));
  const prefixFields = params.prefixFields || params.prefix;
  const fields = [...(params.fields || MONEYTRANSFER_USER_AVAILABLE_FIELDS[prefixFields] || [])]; //need make deep copy
  const inObject = params.inObject;
  const sessionType = params.sessionType;
  const device = getDevice();
  let currentFields = [];

  _.each(fields, (field) => currentFields.push(_.extend({}, field))); //nested objects in array copy by reference

  if (prefixType === 'bank' && !IW.appGetVal('recipient_bank_routing')) {
    currentFields = currentFields.filter((field) => field.name !== 'routing_number');
  }

  // TODO: Fix this stopgup
  const currentActiveField = currentFields[Session.get('current_active_field') || 0] || {
    name: 'first_name',
    describe: '',
    describe_output: '',
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: KEYBOARDS.TEXT,
    validation: {
      required: false,
      minLength: 0,
      maxLength: 9999
    }
  };
  console.log('EnterUserDataContainer | currentFields ', currentFields);
  console.log('EnterUserDataContainer | currentActiveField ', currentActiveField);

  function clearValidation() {
    // TODO: This code is for helpers.validateText() to prevent go next without validation. Replace it
    Session.set('city_exists', null);
    Session.set('state_exists', null);
  }

  function clearErrors() {
    if (Session.get('app').internal_data_error) {
      IW.appSetVal('internal_data_error', '');
    }

    if (Session.get('app').validation_error) {
      IW.appSetVal('validation_error', '');
    }
  }

  // Handlers
  function handleClickNext() {
    clearValidation();
    clearErrors();
    setCurrentActiveFieldIndex('next');
  }

  function handleClickBack() {
    clearValidation();
    clearErrors();
    Session.set('money_transferring_selected_place', '');
    setCurrentActiveFieldIndex('back');
  }

  function setCurrentActiveFieldIndex(type) {
    const index = getCurrentActiveFieldIndex(currentFields, currentActiveField);
    console.log('EnterUserDataContainer | getCurrentActiveFieldIndex ', index);

    // Session.set('prev_current_active_field', index);
    setPrevSliderIndex();
    let newIndex = index;

    if (type === 'next') {
      if (currentActiveField.name === 'address' && getCurrentActualValue() && Session.get('address_portal_value') > 0) {
        const nextIndex = currentFields.slice(index + 1).findIndex((field, index) => {
          return field.name !== 'address2' && (field.part_of !== 'address' || !getValue(field.name))
        });
        if (nextIndex < 0) return Session.set('current_active_field', currentFields.length - 1)
        return Session.set('current_active_field', index + nextIndex + 1);
      }

      if (__.get(currentFields, [index + 1, 'name']) === 'currency' && _.size(IW.appGetVal('recipient_currency_select')) <= 1) {
        const currency = _.first(IW.appGetVal('recipient_currency_select'));
        // Fixme: Duplicated code from handleCheckItem
        IW.appSetVal(`${prefixType}_currency_id`, currency.id);
        IW.appSetLocalVal(`${prefixType}_currency_selected`, currency.value);
        IW.appSetLocalVal(`${prefixType}_currency`, currency.value);
        newIndex += 2;
        return Session.set('current_active_field', newIndex);
      }

      if (index < currentFields.length - 1) {
        newIndex += 1;
        return Session.set('current_active_field', newIndex);
      }

      getPageManager().next();
    } else if (type === 'back') {
      if (index > 0) {
        newIndex -= 1;
        return Session.set('current_active_field', newIndex);
      }

      getPageManager().back();
    }
  }

  function handleInput(v) {
    clearValidation();
    clearErrors();
    const name = currentActiveField.name;

    const excludeCapitalizeAll = ['address', 'address_state', 'address_city_name', 'address1', 'address2',
      'location', 'currency', 'bank_search', 'payment_reason_select', 'id_type', 'cpf', 'id_number',
      'local_bank_code', 'occupation_type_select', 'relationship_to_beneficiary_select', 'sender_birth_date',
      'sender_pace_of_birth', 'sender_nationality', 'sender_birth_country_select', 'birth_country_select', 'receiver_birth_date',
      'receiver_pace_of_birth', 'receiver_nationality', 'receiver_birth_country_select', 'id_type', 'id_issuer_country'
    ];
    const capitalizeField = ['address1', 'address2', 'address_city', 'first_name', 'second_last_name',
      'middle_name', 'last_name', 'mother_name', 'gender', 'id_issuer'];

    if (!_.contains(excludeCapitalizeAll, name) && v && typeof v === 'string') {
      v = v[0].toUpperCase() + v.slice(1).toLowerCase()
    }

    if (_.contains(capitalizeField, name)) {
      let words = v.split(' ');
      words = words.map((word) => {
        if (word) return word[0].toUpperCase() + word.slice(1).toLowerCase();
        return word;
      });
      v = words.join(' ');
    }

    if (name === 'email' || currentActiveField.lower_case) {
      v = v.toLowerCase();
    }

    if (currentActiveField.upper_case) {
      v = v.toUpperCase();
    }

    if (name === 'address_city_name' && v.length > 1) {
      getCities({
        input: v,
        country_iso: getValueFromSession(prefixType ?
          `${prefixType}_country_iso` :
          'country_iso', inObject, sessionType)
      });
    }

    if (name === 'location' && v.length > 2) {
      getLocation(v, prefixType === 'payment_agent' ? 'pm' : 'common');

      if (Session.get(`${prefixType}_location_selected`) === v) {
        const [cityName, stateName, countryName] = _.map(S(v).split(','), s => S(s).trim());

        const addressCityField = prefixType ? `${prefixType}_address_city` : 'address_city';
        const addressStateField = prefixType ? `${prefixType}_address_state` : 'address_state';
        const addressCountryField = prefixType ? `${prefixType}_address_country` : 'address_country';

        setValueToSession(addressCityField, cityName || '', inObject, sessionType);
        setValueToSession(addressStateField, stateName || '', inObject, sessionType);
        setValueToSession(addressCountryField, countryName || '', inObject, sessionType);
      }
    }

    if (name === 'address_town' && v.length > 1) {
      getTowns({
        input: v
      })
    }

    if (name === 'address') {
      Session.set('address_portal_value', +Session.get('address_portal_value') - 1);
    }
    if (name === 'address' && v.length > 1) {
      if (sessionType === 'login') {
        const state = getValueFromSession('address_state', inObject, sessionType);
        const country = getValueFromSession('address_country', inObject, sessionType);
        getStreetsProfile(v, state, country, device.DEVICE_GUID)
      } else {
        getStreets({value: v});
      }

      if (!Session.get('money_transferring_selected_place')) {
        const addressMatch = v.match(/(([^,]+),\s+)?([^,]+),\s+(\w{3,20}),\s+(\w{2})(,\s+?\w+)?/);
        if (addressMatch) {
          const address1 = prefixType ? `${prefixType}_address1` : 'address1';
          const address2 = prefixType ? `${prefixType}_address2` : 'address2';
          const addressCityField = prefixType ? `${prefixType}_address_city` : 'address_city';
          const addressStateField = prefixType ? `${prefixType}_address_state` : 'address_state';
          const addressCountryField = prefixType ? `${prefixType}_address_country` : 'address_country';

          setValueToSession(address1, addressMatch[3] || '', inObject, sessionType);
          setValueToSession(address2, addressMatch[2] || '', inObject, sessionType);
          setValueToSession(addressCityField, addressMatch[4] || '', inObject, sessionType);
          setValueToSession(addressStateField, addressMatch[5] || '', inObject, sessionType);
          setValueToSession(addressCountryField, addressMatch[6] || '', inObject, sessionType);
        }
      }
    }

    if ((name === 'search_biller_input' || name === 'po_box') && v.length > 1) {
      v = v.toUpperCase();
    }

    if (currentActiveField.type_keyboard === KEYBOARDS.EMAIL) {
      const previousEmail = getCurrentActualValue();
      if (v.toLowerCase().length - previousEmail.length >= 4) {
        v = v.toLowerCase().replace(/([^o]|.{2,})\1/, '$1');
      }
    }

    const field = prefixType ? `${prefixType}_${name}` : name;
    setValueToSession(field, v, inObject, sessionType);

    if (params.onAfterInput) params.onAfterInput(name, v);
  }

  function handleClearInput() {
    clearErrors();

    if (currentActiveField.name === 'address') {
      Session.set('address_portal_value', +Session.get('address_portal_value') - 1);
    }

    const field = prefixType ? `${prefixType}_${currentActiveField.name}` : currentActiveField.name;
    setValueToSession(field, '', inObject, sessionType);

    if (params.onAfterInput) params.onAfterInput(currentActiveField.name, '');
  }

  function handleCheckItem(v, key) {
    if (v === '') return;

    if (currentActiveField.name === 'location') {
      Session.set(`${prefixType}_location_selected`, v);
    }

    if (currentActiveField.name === 'address') {
      Session.set('address_portal_value', 2);

      const addressField = prefixType ? `${prefixType}_address` : 'address';
      const address1Field = prefixType ? `${prefixType}_address1` : 'address1';
      const address2Field = prefixType ? `${prefixType}_address2` : 'address2';
      const addressCityField = prefixType ? `${prefixType}_address_city` : 'address_city';
      const addressStateField = prefixType ? `${prefixType}_address_state` : 'address_state';
      const addressCountryField = prefixType ? `${prefixType}_address_country` : 'address_country';
      const addressZip = prefixType ? `${prefixType}_address_zip` : 'address_zip';

      if (key === 'MAP') {
        IW.appSetVal(addressField, v.address);
        IW.appSetVal(address1Field, v.components.street_number + ' ' + v.components.route);
        IW.appSetVal(address2Field, '');
        IW.appSetVal(addressCityField, v.components.locality);
        IW.appSetVal(addressStateField, v.components.short_administrative_area_level_1 || v.components.administrative_area_level_1);
        IW.appSetVal(addressCountryField, v.components.short_country || v.components.country);
        IW.appSetVal(addressZip, v.components.postal_code);
        return;
      }
      //Places
      const place = Session.get('money_transferring_places') ?
        Session.get('money_transferring_places')[key] :
        {};

      if (!_.isEmpty(place)) {
        Session.set('money_transferring_selected_place', place);

        setValueToSession(addressField, _.compact([place.number || '', place.address || '']).join(' '), inObject, sessionType);
        setValueToSession(address1Field, _.compact([place.number || '', place.address || '']).join(' '), inObject, sessionType);
        setValueToSession(address2Field, '', inObject, sessionType);
        setValueToSession(addressCityField, place.city || '', inObject, sessionType);
        setValueToSession(addressStateField, place.state || '', inObject, sessionType);
        setValueToSession(addressCountryField, place.country || '', inObject, sessionType);

        //Getting geocode
        IW.getZip(place.value, (_, zip) => {
          setValueToSession(addressZip, zip || '', inObject, sessionType);
        });
      }
    }

    if (currentActiveField.name === 'address_city_name') {
      //Places
      const place = Session.get('money_transferring_cities') ?
        Session.get('money_transferring_cities')[key] :
        {};

      if (!_.isEmpty(place)) {
        const cityNameField = prefixType ? `${prefixType}_city_name` : 'city_name';
        const cityIdField = prefixType ? `${prefixType}_city_id` : 'city_id';
        const stateIdField = prefixType ? `${prefixType}_state_id` : 'state_id';

        setValueToSession(cityNameField, place.city_name || '', inObject, sessionType);
        setValueToSession(cityIdField, place.id_city || '', inObject, sessionType);
        setValueToSession(stateIdField, place.id_state || '', inObject, sessionType);
      }
    }

    if (currentActiveField.name === 'address_town') {
      setValueToSession('recipient_town_id', Session.get('money_transferring_towns')[key].Id || {});
      setValueToSession('recipient_town_name', Session.get('money_transferring_towns')[key].Name || {});
    }

    if (currentActiveField.name === 'po_box') {
      const biller = Session.get('founded_billers') ?
        Session.get('founded_billers')[key] :
        {};

      setValueToSession('biller_search_biller_input', biller.biller_name || '', inObject, sessionType);

      return handleClickNext();
    }

    if (currentActiveField.name === 'currency') {
      const currencyList = IW.appGetVal('recipient_currency_select');
      const selectedCurrency = _.find(currencyList, (c) => c.value === v);

      IW.appSetVal(`${prefixType}_currency_id`, selectedCurrency.id);
      IW.appSetLocalVal(`${prefixType}_currency_selected`, selectedCurrency.value);
    }

    if (currentActiveField.name === 'payment_reason_select') {
      const currencyList = Session.get('bank_payment_reason_list')
      const selectedReason = _.find(currencyList, (c) => c.value === v);

      IW.appSetVal('bank_sending_reason_id', selectedReason.sending_reason_id);
      IW.appSetVal('bank_sending_reason_name', selectedReason.sending_reason_name);
      IW.appSetLocalVal('bank_payment_reason_selected', selectedReason.value);
    }

    if (currentActiveField.name === 'occupation_type_select') {
      const occupationList = Session.get('occupation_type_list')
      const selectedOccupation = _.find(occupationList, (c) => c.value === v);

      IW.appSetVal('compliance_occupation_type_id', selectedOccupation.occupation_type_id);
      IW.appSetVal('compliance_occupation_type_name', selectedOccupation.occupation_type_name);
      IW.appSetLocalVal('occupation_type_selected', selectedOccupation.value);
    }

    if (currentActiveField.name === 'relationship_to_beneficiary_select') {
      const relationshipList = Session.get('relationship_to_beneficiary_list')
      const selectedRelationship = _.find(relationshipList, (c) => c.value === v);

      IW.appSetVal('compliance_relationship_to_beneficiary_id', selectedRelationship.relationship_id);
      IW.appSetVal('compliance_relationship_to_beneficiary_name', selectedRelationship.relationship_name);
      IW.appSetLocalVal('relationship_to_beneficiary_selected', selectedRelationship.value);
    }

    if (currentActiveField.name === 'sender_birth_country_select' ||
      currentActiveField.name === 'birth_country_select') {
      const countriesList = Session.get('countries_list');
      const selectedCountry = _.find(countriesList, (c) => c.value === v);

      IW.appSetVal('compliance_sender_birth_country_id', selectedCountry.country_id);
      IW.appSetVal('compliance_sender_birth_country_name', selectedCountry.country_name);
      IW.appSetVal(`${prefixType}_birth_country_id`, selectedCountry.country_id);
      IW.appSetVal(`${prefixType}_birth_country_name`, selectedCountry.country_name);
    }

    if (currentActiveField.name === 'id_issuer_country') {
      const countriesList = Session.get('countries_list');
      const selectedCountry = _.find(countriesList, (c) => c.value === v);
      IW.appSetVal(`${prefixType}_id_issuer_country_id`, selectedCountry.country_id);
      IW.appSetVal(`${prefixType}_id_issuer_country`, selectedCountry.country_name);
      IW.appSetLocalVal('id_issuer_country_id', selectedCountry.country_id);
    }

    if (currentActiveField.name === 'receiver_birth_country_select') {
      const countriesList = Session.get('countries_list');
      const selectedCountry = _.find(countriesList, (c) => c.value === v);

      IW.appSetVal('compliance_receiver_birth_country_id', selectedCountry.country_id);
      IW.appSetVal('compliance_receiver_birth_country_name', selectedCountry.country_name);
      IW.appSetLocalVal('receiver_birth_country_selected', selectedCountry.value);
    }

    if (currentActiveField.name === 'receiver_nationality_select') {
      const countriesList = Session.get('countries_list');
      const selectedCountry = _.find(countriesList, (c) => c.value === v);

      IW.appSetVal('compliance_receiver_nationality_id', selectedCountry.country_id);
      IW.appSetVal('compliance_receiver_nationality_name', selectedCountry.country_name);
      IW.appSetLocalVal('receiver_nationality_selected', selectedCountry.value);
    }

    if (currentActiveField.name === 'sender_nationality_select') {
      const countriesList = Session.get('countries_list');
      const selectedCountry = _.find(countriesList, (c) => c.value === v);

      IW.appSetVal('compliance_sender_nationality_id', selectedCountry.country_id);
      IW.appSetVal('compliance_sender_nationality_name', selectedCountry.country_name);
      IW.appSetLocalVal('sender_nationality_selected', selectedCountry.value);
    }

    if (currentActiveField.name === 'sender_money_origin_select') {
      const moneyOriginList = Session.get('money_origin_list');
      const selectedMoneyOrigin = _.find(moneyOriginList, (c) => c.value === v);

      IW.appSetVal('compliance_sender_money_origin_id', selectedMoneyOrigin.origin_id);
      IW.appSetVal('compliance_sender_money_origin_name', selectedMoneyOrigin.origin_name);
      IW.appSetLocalVal('sender_money_origin_selected', selectedMoneyOrigin.value);
    }

    if (currentActiveField.name === 'bank_search') {
      const bankList = Session.get('bank_search_list');
      const selectedBank = _.find(bankList, (c) => c.value === v);

      IW.appSetVal('bank_id', selectedBank.bank_id);
      IW.appSetVal('bank_required_local_bank_code', selectedBank.bank_code);
      IW.appSetVal('bank_required_bank_iban', selectedBank.bank_iban);
      IW.appSetVal('bank_required_bank_name', selectedBank.bank_name);
      IW.appSetVal('bank_swift', selectedBank.bank_swift);
      IW.appSetLocalVal('bank_bank_search_selected', selectedBank.value);
    }

    if (currentActiveField.name === 'bank_account_type') {
      const typesList = Session.get('bank_account_type_list');
      const selectedType = _.find(typesList, (c) => c.value === v);

      IW.appSetLocalVal('bank_account_type_selected', selectedType.value);
      IW.appSetVal('bank_account_type_id', selectedType.id);
      IW.appSetVal('bank_account_type_code', selectedType.code);
    }

    if (currentActiveField.name === 'id_type') {
      const typesList = Session.get('id_type_list');
      const selectedType = _.find(typesList, (c) => c.value === v);

      IW.appSetVal(`${prefixType}_id_type_id`, selectedType.id);
      IW.appSetVal(`${prefixType}_id_type_code`, selectedType.code);
      IW.appSetLocalVal(`${prefixType}_type_regexp`, selectedType.regexp);
    }

    handleInput(v);
  }

  function handleSliderClick({pageY}) {
    function clickOn(selector, time) {
      setTimeout(() => triggerMouseEvent($(selector)[0], 'mousedown'), time || 1);
    }

    let way = 1;
    if (pageY < 344) way = 0;
    if (pageY > 448) way = 2;

    const fields = getFilteredDisplayedFields();
    const index = getCurrentActiveFieldIndex(currentFields, currentActiveField);
    const nextAvaliable = typeof params.showNext === 'undefined' ?
      validateInput() :
      params.showNext;
    const isLast = _.last(fields).name === currentActiveField.name;
    if (way === 0 && index >= 1) {
      clickOn('#back')
    }
    if (way === 1 && nextAvaliable && index === 0) {
      clickOn('#next')
    }
    if (way === 2 && nextAvaliable && !isLast) {
      clickOn('#next')
      if (index === 0) {
        clickOn('#next', 30)
      }
    }
  }

  function getValue(name) {
    const field = prefixType ? `${prefixType}_${name}` : name;
    const v = getValueFromSession(field, inObject, sessionType);
    return S(v);
  }

  function getFormattedValue(field, v) {
    if (field.type_keyboard === KEYBOARDS.DATEPICKER) {
      moment.lang(Session.get('locale'));
      v = moment(+v).format('MMMM D, YYYY');
      v = v[0].toUpperCase() + v.slice(1).toLowerCase();
      moment.lang('en_US');
    }

    return v;
  }

  function getCurrentActualValue() {
    const v = getValue(currentActiveField.name)
    if (!v && currentActiveField.default_value) {
      handleInput(currentActiveField.default_value)
    }

    return getValue(currentActiveField.name);
  }

  function getCurrentInputValue() {
    return getFormattedValue(currentActiveField, getCurrentActualValue());
  }

  function getCurrentActiveFieldIndex(availableFields, active) {
    return availableFields.findIndex((el) => el.name === active.name);
  }

  function getSearchItems() {
    if (currentActiveField.type_inputs_result !== TRANSFER_INPUTS_RESULT.SEARCH_LIST) return;
    const searchQ = getCurrentActualValue();
    let results = [];

    if (currentActiveField.name === 'address') {
      if (searchQ && searchQ.trim().length >= 2) {
        if (!Session.get('money_transferring_places')) {
          console.log('_DEBUG searchQ', searchQ);
          if (sessionType === 'login') {
            const state = getValueFromSession('address_state', inObject, sessionType);
            const country = getValueFromSession('address_country', inObject, sessionType);
            getStreetsProfile(searchQ.trim(), state, country, device.DEVICE_GUID);
          } else {
            getStreets({value: searchQ.trim()});
          }
        }
        results = (Session.get('money_transferring_places') || []);
      }
    } else if (currentActiveField.name === 'location') {
      if (searchQ) {
        results = (Session.get('money_transferring_locations') || []);
      }
    } else if (currentActiveField.name === 'address_city_name') {
      if (searchQ) {
        results = (Session.get('money_transferring_cities') || []);
      }
    } else if (currentActiveField.name === 'address_town') {
      if (searchQ) {
        results = (Session.get('money_transferring_towns') || []);
      }
    } else if (currentActiveField.name === 'sender_type_id') {
      if (searchQ) {
        results = (Session.get('money_transferring_id_types') || []);
      }
    } else if (currentActiveField.name === 'po_box') {
      if (searchQ) {
        results = (Session.get("founded_billers") || []);
      }
    } else if (currentActiveField.name === 'currency') {
      let regExp = new RegExp(searchQ, 'i');
      let list = _.filter((IW.appGetVal('recipient_currency_select') || []), function (val) {
        return regExp.test(S(val.value));
      });
      results = list;
    } else {
      const fieldToFieldMap = {
        bank_search: 'bank_search_list',
        payment_reason_select: 'bank_payment_reason_list',
        occupation_type_select: 'occupation_type_list',
        relationship_to_beneficiary_select: 'relationship_to_beneficiary_list',
        sender_money_origin_select: 'money_origin_list',
        sender_birth_country_select: 'countries_list',
        receiver_birth_country_select: 'countries_list',
        birth_country_select: 'countries_list',
        id_issuer_country: 'countries_list',
        sender_nationality_select: 'countries_list',
        receiver_nationality_select: 'countries_list',
        bank_account_type: 'bank_account_type_list',
        id_type: 'id_type_list'
      }
      results = getMostRelevant(
        Session.get(fieldToFieldMap[currentActiveField.name]) || [],
        searchQ,
        ['value'],
        [[1, 5, 41]],
        5);
    }

    if (_.compact(results).length > 0) {
      if (results.length === 1 && results[0].value.toLowerCase() === searchQ.toLowerCase() && !(currentActiveField.name === 'currency')) {
        results = []
      }
    }

    return results.slice(0, LINE_COUNT);
  }

  function getScrollListResult() {
    if (currentActiveField.type_inputs_result !== TRANSFER_INPUTS_RESULT.SCROLL_LIST) return;
    let results = [];

    if (currentActiveField.name === 'currency') {
      results = IW.appGetVal('recipient_currency_select') || [];
    }

    return results;
  }

  function validateInput() {
    const input = getCurrentActualValue();
    return validateText(input, currentActiveField.validation, prefixType);
  }

  // calculated slider list
  function getCurrentSliderIndex(fields, indexODataObject) {
    // index of element shown before input
    const offsetSlides = 0; // in slider first shown the before element
    const currentIndexDataObject = indexODataObject;
    const currentIndexSlider = currentIndexDataObject +
      1 + // slider index starts from 1
      offsetSlides -
      getHiddenElements(fields, indexODataObject); // data object elements from field set, which not shown into slider list

    return currentIndexSlider;
  }

  function getHiddenElements(fields, indexODataObject) {
    const currentIndexDataObject = indexODataObject;
    const count = fields.slice(0, currentIndexDataObject + 1) // selected all fields before checked field
      .reduce((v, item) => {
        return item.hideFromList ? v + 1 : v;
      }, 0);

    return count;
  }

  function getFilteredDisplayedFields() {
    return [...currentFields].filter((item) => !item.hideFromList)
      .map((item) => {
        const where = prefixType ? prefixType + '_' + item.name : item.name;
        return {...item, data: getFormattedValue(item, getValueFromSession(where, inObject, sessionType))};
      });
  }

  function setPrevSliderIndex() {
    const currentSliderIndex = getCurrentSliderIndex(currentFields, getCurrentActiveFieldIndex(currentFields, currentActiveField));
    Session.set('prev_current_active_field', currentSliderIndex);
  }

  function getPrevSliderIndex() {
    return Session.get('prev_current_active_field') || 0;
  }

  function getValidationError() {
    if (IW.appGetVal('validation_error') && VIAMERICA_INTERNAL_ERRORS[IW.appGetVal('validation_error')]) {
      return VIAMERICA_INTERNAL_ERRORS[IW.appGetVal('validation_error')].message || IW.appGetVal('validation_error');
    }
    if (IW.appGetVal('validation_error')) return IW.appGetVal('validation_error');
  }

  function autoCheckSearchValue() {
    if (currentActiveField.type_inputs_result !== TRANSFER_INPUTS_RESULT.SEARCH_LIST) return;
    if (!validationResult) return;
    const value = getCurrentInputValue();
    const index = __.findIndex(searchResult, (r) => r.value === value);
    if (index === -1) return;
    if (lastSearchingValue === value) return;
    lastSearchingValue = value;
    handleCheckItem(value, index);
  }

  const validationResult = validateInput();
  const searchResult = getSearchItems();
  const scrollListResult = getScrollListResult();

  autoCheckSearchValue();
  return {
    value: getCurrentActualValue(),
    inputValue: getCurrentInputValue(),
    onClearInput: paramsOrValue(params.onClearInput, handleClearInput),
    onClickNext: handleClickNext,
    onClickBack: handleClickBack,
    onSliderClick: handleSliderClick,
    onInput: __.get(params, 'onInput', handleInput),
    onSelectItem: __.get(params, 'onSelectItem', handleCheckItem),
    userType: prefixType,
    emailKeyboardLayout: KEYBOARD_EMAIL,
    numberKeyboardLayout: KEYBOARD_NUMBER_3ROW,
    amountKeyboardLayout: KEYBOARD_AMOUNT_3ROW,
    textKeyboardLayout: KEYBOARD_TEXT,
    emailSuffixKeyboard: KEYBOARD_EMAIL_SUFFIX_SECOND,
    availableFields: currentFields,
    displayedFields: getFilteredDisplayedFields(), // getDisplayedFields(),
    currentActiveField,
    allFieldsReady: false,
    searchResult: __.get(params, 'searchResult', searchResult),
    scrollListResult: __.get(params, 'scrollListResult', scrollListResult),
    inSearching: Session.get('in_data_searching'),
    showNext: __.get(params, 'showNext', validationResult),
    slideNum: getCurrentSliderIndex(currentFields, getCurrentActiveFieldIndex(currentFields, currentActiveField)),
    prevSlideNum: getPrevSliderIndex(),
    optionsSlider: {
      desktopClickDrag: false,
      scrollbar: false,
    },
    maxInputLength: currentActiveField.maxLength || 28, // 28 - optimal length
    internalError: IW.appGetVal('internal_data_error') || '',
    validationError: getValidationError(),
    showLoading: __.get(params, 'showLoading', IW.appGetVal('enter_user_data_loading')),
    sliderSlideNum: IW.appGetVal(`${currentActiveField.name}_selected_item`)
  };

}, EnterUserData);

function paramsOrValue(params, value) {
  return typeof params === 'undefined' ? value : params
}

function getMostRelevant(inputList, query, fields, mod, depth) {
  let callDepth = 0;
  const list = __.map(inputList, el => ({...el, sortRate: 0}));

  function calculateValues(params) {
    callDepth++
    let isContinue = 0;

    __.each(params, function (q, j) {
      if (q.length < 1) return;

      const regExpPart = new RegExp(q, 'i');
      const regExpStart = new RegExp('(^| )' + q, 'i');
      const regExpFull = new RegExp('(^| )' + q + '($| )', 'i');
      const modificator = Math.pow(3, q.length);

      __.each(list, function (el) {
        __.each(fields, function (f, i) {

          if (regExpPart.test(el[f])) el.sortRate += mod[i][0] * modificator;
          if (regExpStart.test(el[f])) el.sortRate += mod[i][1] * modificator;
          if (regExpFull.test(el[f])) el.sortRate += mod[i][2] * modificator;

          if (q.length > 1) isContinue++;
        });
      });
    });
    if (isContinue && (depth && callDepth < depth)) {
      const newParams = __.compact(params.map(function (p) {
        if (p.length > 1) return p.slice(0, -1);
        return '';
      }));

      calculateValues(newParams);
    }
  }

  const params = S(query).replace(/,/g, '').split(/\s/);

  calculateValues(params);

  return __.sortBy(list, function (el) {
    const rate = el.sortRate;
    delete el.sortRate;
    return -rate;
  });
}