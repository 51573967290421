import IW from 'imports/iw_api'
import {next} from 'imports/lib/page_manager'
import {VIAMERICA_INTERNAL_ERRORS} from '../../lib/constants';

export default function (backPage) {
  return function() {

    Session.set('prev_current_active_field', 0);
    Session.set('current_active_field', 0);

    const error = VIAMERICA_INTERNAL_ERRORS[IW.appGetVal('validation_error')];
    if (error) {
      IW.appSetVal(error.field, '');
      Session.set('current_active_field', error.index);
    }

    return {
      back: backPage,
      next: function*() {
        const customer = IW.appGetVal('customer');

        if (!_.isEmpty(customer)){
          IW.updateCustomer(customer, () => next());
          yield 'loading';
        }

        return 'confirm_customer_data'
      }
    }
  }
}
