/**
 * Created by vitaliy on 14.03.17.
 */
import React from 'react';
import { initVerticalSlider } from 'imports/lib/helpers';

export default class PickerIOSlider extends React.Component {
  componentDidMount() {
    const { optionsSlider, sliderId } = this.props;
    // console.log('PickerIOSlider | componentDidMount | sliderId ', sliderId);
    if (this.refs.slider){
      initVerticalSlider({...optionsSlider}, sliderId.toString());
    }
  }

  shouldComponentUpdate(nextProps) {
    const { items, optionsSlider } = this.props;
    const { items : nItems, optionsSlider : nOptionsSlider } = nextProps;

    if (nextProps.freezeUpdate) return false;

    return JSON.stringify(this.props) !== JSON.stringify(nextProps);
  }

  componentDidUpdate() {
    const { optionsSlider, sliderId } = this.props;
    console.log('PickerIOSlider | componentDidUpdate | sliderId ', sliderId);
    initVerticalSlider({...optionsSlider}, sliderId.toString());
  }

  renderItems() {
    let { items } = this.props;

    return items.map(function (item, index) {
      return <a key={index} className="slide slide-vertical-item" data-value={item.value}>
        <span>{item.text}</span></a>
    });
  }

  render() {
    const { sliderId, handleSlide } = this.props;
    //if (this.props.sliderId === 'day') console.log('PickerIOSlider | render');

    return (<div className="picker-ios-slider">
      <div className={'picker-slider ' + sliderId.toString()}>
        <div className="slide-button slide-button__up" onMouseDown={handleSlide}/>
        <div className="iosVerticalSlider" ref="slider">
          <div className="slider">
            {this.renderItems()}
          </div>
        </div>
        <div className="slide-button slide-button__down" onMouseDown={handleSlide}/>
      </div>
    </div>);
  }
}


PickerIOSlider.propTypes = {
  items : React.PropTypes.array,
  optionsSlider : React.PropTypes.object,
  handleSlide : React.PropTypes.func,
  sliderId : React.PropTypes.string,
  freezeUpdate : React.PropTypes.bool,
};