import React, {Component} from 'react';

const DIRECTION = {
  ROW : 'row',
  COL : 'col',
  REVERSE_ROW : 'rev_row',
  REVERSE_COL : 'rev_col',
};

export default class Card extends Component {

  columnsView(iconUrl, text, additional){
    return (
      <tbody>
        <tr>
          { this.cardIconContent(iconUrl, additional)}
        </tr>
        <tr>
          { this.cardTextContent(text)}
        </tr>
      </tbody>
    );
  }

  rowsView(iconUrl, text, additional){
    return (
      <tbody>
      <tr>
        { this.cardIconContent(iconUrl, additional)}
        { this.cardTextContent(text)}
      </tr>
      </tbody>
    );
  }

  columnsReverseView(iconUrl, text, additional){
    return (
      <tbody>
      <tr>
        { this.cardTextContent(text)}
      </tr>
      <tr>
        { this.cardIconContent(iconUrl, additional)}
      </tr>
      </tbody>
    );
  }

  rowsReverseView(iconUrl, text, additional){
    return (
      <tbody>
      <tr>
        { this.cardTextContent(text)}
        { this.cardIconContent(iconUrl, additional)}
      </tr>
      </tbody>
    );
  }

  cardTextContent(text) {
    return <td className="card__text">{text}</td>;
  }

  cardIconContent(iconUrl, additional) {
    return (<td className="card__icon">
      <img src={iconUrl} alt=""/>
      { additional ? <span className="card__icon__additional-text">[ {additional} ]</span> : null}
    </td>);
  }

  checkView(direction, iconUrl, text, additional){
    switch(direction){
      case DIRECTION.COL:
        return this.columnsView(iconUrl, text, additional);
      case DIRECTION.REVERSE_COL:
        return this.columnsReverseView(iconUrl, text, additional);
      case DIRECTION.REVERSE_ROW:
        return this.rowsReverseView(iconUrl, text, additional);
      case DIRECTION.ROW:
      default:
        return this.rowsView(iconUrl, text, additional);
    }
  }

  render() {
    const {direction, iconUrl, text, additional, className, onCardClick, style} = this.props;

    return (
      <div className={'card ' + className} style={style}>
        <a onClick={onCardClick}>
          <table>
            { this.checkView(direction, iconUrl, text, additional)}
          </table>
        </a>
      </div>
    )
  }
}

Card.propTypes = {
  onCardClick: React.PropTypes.func,
  iconUrl: React.PropTypes.string,
  direction: React.PropTypes.string,
  text : React.PropTypes.string,
  additional : React.PropTypes.string,
  style: React.PropTypes.object
};

Card.defaultProps = {
  onCardClick: () => '',
  iconUrl: '',
  direction: 'row',
  text: 'Card text',
  additional : null,
  style: {}
};