import React, {Component} from 'react';
import Image from 'imports/ui/shared/image/Image';
import {_, _feed} from 'imports/lib/helpers';

export default class BillerIcon extends Component {
  render() {
    const {icon, onTapIcon} = this.props;

    if (!icon.biller_id) {
      return null;
    }

    return (
      <a className="biller_icon" onMouseDown={onTapIcon} onClick={onTapIcon}>
        <div><Image src={icon.icon} default={icon.iconDefault}/></div>
        {icon.hasOwnProperty('name') ? <span>{icon.name}</span> : null}
      </a>
    )
  }
}