import React, {Component} from 'react';
import IW from 'imports/iw_api';
import Button from 'imports/ui/shared/button/Button';
import CONFIG from '../../config';
import Time from '../shared/time/TimeContainer';
import getPageManager from 'imports/lib/page_manager';
import {Session} from "core/session";
import {FlowRouter} from 'core/Router';
import {gaEvent} from "imports/lib/ga";

export default class CategoriesHeader extends Component {

  changeLanguage(locale) {
    const language = locale === 'en_US' ? 'es_MX' : 'en_US';
    Session.set('locale', language);
    FlowRouter.withReplaceState(() => {
      FlowRouter.setParams({language})
    })
  }

  render() {
    const {backButton, isSearchBack, onSearchBack, locale, gaCategory} = this.props;

    const language = locale === 'en_US' ? 'Español' : 'English';

    let renderBack = <Button id="menu" className="button button-pink" onMouseDown={() => {
      if (gaCategory) gaEvent(gaCategory, 'Back', 'Menu');
      IW.gotoIndex()
    }} text_="Menu"/>;

    if (backButton) {
      renderBack = <Button id="back" className="button button-categories button-pink" onMouseDown={() => {
        if (gaCategory) gaEvent(gaCategory, 'Back');
        history.back()
      }} text_="Back"/>
    }

    if(isSearchBack){
      renderBack = <Button id="back" className="button button-categories button-pink" onMouseDown={onSearchBack} text_="Back"/>
    }

    return (
      <tr className="main">
        <td>
          {renderBack}
        </td>
        <td>
          <div className="logo-container">
            <a className="partner logo"/>
          </div>
        </td>
        <td>
          {CONFIG.ATM_ENABLED && CONFIG.ATM_ENABLED_IN_HEADER ?
            <Button id="next" className="button button-atm button-blue" text_="ATM" onMouseDown={() => {
              if (gaCategory) gaEvent(gaCategory, 'ATM');
              IW.launchATM()
            }}/> : <Button id="next" className="button button-atm button-blue" text_={language}
                           onMouseDown={() => this.changeLanguage(locale)}/>
          }
        </td>
      </tr>
    )
  }
}
