import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';

export default class Loans extends Component {
  render() {
    const {onBack, onNext, qr_url} = this.props;

    return (
      <div className="wrapper-index">
        <div className="body-container">
          <div className="get-app_wrapper">

            <img className="get-app_logo" src="/resources/img/logos/fig-loans.png" />

            <p className="get-app_title" _>{'Loans up to $500 that can help build your credit'}</p>

            <div className="get-app_link_container">
              <img width={300} src={qr_url} />
              <p className="get-app_link">Scan this code</p>
            </div>

            <p className="get-app_title" _>{'Apply in minutes'}</p>
          </div>
        </div>
        <div className="footer-container">
          <div className="footer-container_col index-footer_col_left">
            <Button id="back" className="button button-pink" onMouseDown={onBack} text_="Exit"/>
          </div>
          <div className="footer-container_col index-footer_col_right ">
            <Button id="next" className="button button-blue" onMouseDown={onNext} text_="Send me a link"/>
          </div>
        </div>
      </div>
    )
  }
}

Loans.propTypes = {
   onBack: React.PropTypes.func,
}
