import {createContainer} from 'imports/lib/react_createContainer';
import ProfileProducts from './ProfileProducts';
import Links from 'imports/collections/links';
import Products from 'imports/collections/products';
import * as u from 'underscore';

const ALLOWED_APPS = ['billpay', 'giftcards', 'phonereload', 'pinproducts']
  .map(function (e) {
    return new RegExp(e, "i");
  });

export default createContainer((params) => {

  const getCats = function () {
    const result = [];
    const categories = [];

    const categoriesTree = Session.get('categories_tree');
    if (!categoriesTree) return result;

    const availableCategories = [];

    Products.find({app_guid: {'$in': ALLOWED_APPS}}).forEach((p) => {
      u.each(p.path, (c) => availableCategories.push(c));
    });

    u.each(categoriesTree.categories, (c, i) => {
      if (u.contains(availableCategories, c.id)) {
        result.push({
          id: c.id,
          nm: c.name,
          index: i,
          selection_priority: c.selection_priority,
          icon: c.icon,
          type: 'category'
        })
      }
    });

    return result;
  };

  const getApps = function (currentCatId, prevCatId) {
    const links = [];

    if (!currentCatId) {
      console.log('Category id not passed');
      return links;
    }

    const categoriesTree = Session.get('categories_tree');
    if (!categoriesTree) return links;

    const currentCategory =
      u.find(categoriesTree.categories, (c) => c.id === currentCatId) ||
      u.find((u.find(categoriesTree.categories, (c1) => c1.id === prevCatId)).categories, (c2) => {
        return c2.id === currentCatId;
      });

    if (!currentCategory) {
      return links;
    }

    u.each(currentCategory.categories, (c) => {
      links.push({
        icon: c.icon,
        app_guid: 'category',
        name: c.name,
        title: c.name,
        categoryLevel: 1,
        id: c.id,
      })
    });

    Links.find({
      product_id: {$in: currentCategory.products},
      app_guid: {'$in': ALLOWED_APPS},
      hidden: false
    }, {sort: {priority: -1, name: 1}})
      .forEach((l) => links.push(l));

    return links;
  };

  return {
    getApps,
    categories: getCats() || []
  }

}, ProfileProducts);
