import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';

//var Tiff = require('imports/lib/external/tiff.min.js');

export default class ConfirmPhoto extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // this.renderPhoto();
  }

  // renderPhoto() {
  //   let {imagePath} = this.props;
  //
  //   //imagePath = 'http://localhost:3200/resources/images/bill_scanning/data.tiff';
  //
  //   const xhr = new XMLHttpRequest();
  //   xhr.responseType = 'arraybuffer';
  //   xhr.open('GET', imagePath, true);
  //
  //   Tiff.initialize({TOTAL_MEMORY: 16777216 * 10});
  //
  //   xhr.onload = function (e) {
  //     const tiff = new Tiff({buffer: xhr.response});
  //     let canvas = tiff.toCanvas();
  //
  //     document.getElementById("bill-photo").appendChild(canvas);
  //   };
  //
  //   xhr.onerror = function (e) {
  //     console.log('File getting error', e);
  //   };
  //
  //   xhr.send();
  // }

  render() {
    const {onBack, onNext, onRotate, onFlip, imagePaths, message} = this.props;

    return (
      <table>
        <tbody>
        <tr>
          <td colSpan="4" style={{padding: "20px", textAlign: "center", verticalAlign: "middle"}}>
            {imagePaths.length >= 2 && _.map(imagePaths.slice(0, 2),
              (path) => <img id="bill-photo" src={path} style={{maxHeight: 288}}/>)}
            {imagePaths.length === 1 && <img id="bill-photo" src={imagePaths[0]}/>}
            {/*<div id='bill-photo'/>*/}
            <h1 style={{margin: 0}} _>{message}</h1>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td>
            <Button id="back" className="left button goto-main" text_={'Try Again'} onMouseDown={onBack}/>
          </td>
          {onRotate && (
            <td>
              <Button id="edit" className="center button confirm-photo-symbol-button" text={'↻'} onMouseDown={onRotate}/>
            </td>
          )}
          {onFlip && (
            <td>
              <Button id="edit" className="center button confirm-photo-symbol-button" text={'⇅'} onMouseDown={onFlip}/>
            </td>
          )}
          <td>
            <Button id="next" className="right button goto-main" text_={'Confirm'} onMouseDown={onNext}/>
          </td>
        </tr>
        </tfoot>
      </table>
    );
  }
}