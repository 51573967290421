import {createContainer} from 'imports/lib/react_createContainer';
import AmountClerk from './AmountClerk';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';
import {N, S, createLazyExecution} from 'imports/lib/helpers'

const MAX_AMOUNT = 10000;

export default createContainer((params) => {
  const currencySymbol = '$';

  const amount = N(IW.appGetVal('clerk_received_amount')) || 0;
  const displayAmount = IW.appGetVal('clerk_received_display_amount') || amount || 0;

  const numpadLayout = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9],
    ['.', 0, 'delete']
  ];

  function onPrintNumber(key) {
    const printedAmount = N(key);

    if(printedAmount > MAX_AMOUNT) return;

    IW.appSetLocalVal('clerk_received_amount', printedAmount);
    IW.appSetLocalVal('clerk_received_display_amount', key);
  }

  function clearInput() {
    IW.appSetLocalVal('clerk_received_amount', 0);
    IW.appSetLocalVal('clerk_received_display_amount', 0);
  }

  return {
    keyboardMaxLength: 9,
    keyboardPattern: '^\\d{1,4}(\\.\\d{1,2}|\\.)?$',
    currency: currencySymbol,
    amount: displayAmount,
    numpadLayout,
    confirm : N(IW.appGetVal('requested_amount')) <= amount && amount <= MAX_AMOUNT,
    onPrintNumber,
    clearInput,
    isFlexible: true,
    onNext: () => {
      IW.appSetLocalVal('clerk_received_amount', displayAmount);
      getPageManager().next()
    },
    onBack: () => getPageManager().back(),
  };

}, AmountClerk);
