import React, {Component} from 'react';
import {FlowRouter} from 'core/Router';
import Image from 'imports/ui/shared/image/Image'
import Button from 'imports/ui/shared/button/Button';
import IW from 'imports/iw_api';
import Payment from './Payment'

export default class PaymentVirtual extends Payment {
  componentDidMount() {
    IW.appSetVal('virtual_cash_access_denied', false)
  }

  buildPayMethods() {
    const {qrCodeUrl, virtualPayAccessDenied, isClerkEnabled} = this.props;
    if (isClerkEnabled) return;

    return <Image className={`payment-qr_code ${virtualPayAccessDenied ? 'blurred' : ''}`} src={qrCodeUrl}/>;
  }

  buildTipNotes() {
    const {virtualPayAccessDenied, onTryPayerLoginAgain} = this.props;

    if (virtualPayAccessDenied) {
      return (
        <div>
          <h4 className="payment-qr_title" _>Access Denied</h4>
          <Button id="optional" className="center" onMouseDown={onTryPayerLoginAgain} text_="Try again"/>
        </div>
      )
    }

    return (
      <div>
        <h4 className="payment-qr_title" _>Scan QR to pay</h4>
      </div>
    );
  }
}
