import React from 'react';

export default class IFrame extends React.Component {
  shouldComponentUpdate(){
    return false;
  }

  render() {
    const {startUrl, style, id, className} = this.props;
    return (
      <iframe id={id} src="/themes/mayafs/open_maya.html"
              className={className}
              name={startUrl}
              scrolling="no"
              style={style}/>
    )
  }
}


IFrame.propTypes = {
  id: React.PropTypes.string,
  className: React.PropTypes.string,
  style: React.PropTypes.object,
  startUrl: React.PropTypes.string,
};
