import _ from "lodash";
import {combineReducers} from 'redux';

const INITIAL_STATE = {};

const session = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET":
    case "SET_DEFAULT": {
      // debugger
      // if (_.isEqual(JSON.parse(JSON.stringify(action.payload.obj)), _.get(state, action.payload.selector))) return state;
      const newState = _.extend({}, state);
      _.set(newState, action.payload.selector, action.payload.obj);
      return newState;
    }
    case 'SET_SESSION': {
      return action.payload
    }
    default:
      return state;
  }
}

export default combineReducers({session});
