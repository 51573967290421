import IW from 'imports/iw_api';
import {Session} from "core/session";

export default function () {
  return function*() {
    IW.callCardReader('data');
    yield 'loading app card_data_received';

    IW.appSetLocalVal('withdrawal_alert', 'take_your_card');
    IW.callCardReader('close');

    yield 'sleep app card_removed';

    IW.appSetVal('card_insert', false);
    IW.appSetVal('card_data_received', false);
    IW.appSetVal('card_removed', false);
    IW.appSetVal('pin_wrong', false);

    yield {
      back: 'back',
      next: 'back'
    };

    if (!IW.appGetVal('card_processed')) {
      yield* finishPaymentPin('card_insert_error');

      return 'back';
    }


    if (!(yield* initPage())) {
      yield* finishPaymentPin('return_card_internal_error');

      return 'back';
    }

    return {
      back: function*() {
        yield* finishPaymentPin();
        return 'back'
      },
      cancel: function*() {
        IW.appSetVal('pad_canceled', false);
        yield* finishPaymentPin();
        return 'back'
      },
      next: function*() {
        IW.appSetLocalVal('epp_set_pin_mode_success', false);
        IW.appSetLocalVal('withdrawal_alert', '');
        IW.appSetLocalVal('withdrawal_not_enough', false);
        IW.appSetLocalVal('transaction_error', false);

        if (!IW.appGetVal('pad_entered')) {
          IW.callEPP('get_pin');
        }
        IW.appSetVal('pad_entered', false);
        yield 'loading app_exists pin_valid';
        if (!IW.appGetVal('pin_valid')) {
          if (!(yield* initPage())) {
            yield* finishPaymentPin('return_card_internal_error');
            return 'back';
          }
          return 'none';
        }

        IW.call('doWithdrawal');
        IW.appSetLocalVal('withdrawal_done', false);
        yield 'loading app withdrawal_done';

        if (IW.appGetVal('pin_wrong')) {
          if (!(yield* initPage())) {
            yield* finishPaymentPin('return_card_internal_error');
            return 'back';
          }
          return 'none';
        }

        let alert = '';
        if (IW.appGetVal('withdrawal_not_enough')) {
          alert = 'withdrawal_not_enough';
        }

        if (IW.appGetVal('transaction_error')) {
          alert = 'transaction_error';
        }

        if (!IW.appGetVal('withdrawal_success') && !alert) {
          alert = 'return_card_internal_error';
        }

        if (IW.appGetVal('withdrawal_success')) {
          alert = 'withdrawal_success';
        }

        IW.appSetLocalVal('update_payment_done', false);
        IW.call('updatePayment');
        yield 'loading app update_payment_done';

        yield* finishPaymentPin(alert);
        return 'back'
      }
    }
  }
}

function*finishPaymentPin(alert) {
  IW.appSetLocalVal('withdrawal_alert', alert || '');
  IW.appSetLocalVal('epp_set_pin_mode_success', false);
  IW.appSetVal('card_insert', false);
  IW.appSetVal('card_data_received', false);
  IW.appSetVal('card_removed', false);
  IW.appSetVal('pin_wrong', false);
  IW.appSetLocalVal('epp_close_done ', false);
  IW.callEPP('close');
  yield 'loading app epp_close_done'
  if (alert){
    yield 'sleep 5000';
  }
}

function*initPage() {
  IW.call('updatePinDebitFee');
  IW.callEPP('set_pin_mode');
  IW.appSetVal('pad_pin', '');
  IW.appSetVal('pad_entered', false);
  IW.appSetVal('pad_canceled', false);
  IW.appSetLocalVal('pin_valid', undefined);
  IW.appSetLocalVal('epp_set_pin_mode_done', false);
  IW.appSetLocalVal('epp_set_pin_mode_success', false);

  IW.appSetLocalVal('terminal_init_success', false);
  IW.appSetLocalVal('withdrawal_alert', '');
  IW.appSetLocalVal('withdrawal_not_enough', false);
  IW.appSetLocalVal('transaction_error', false);

  yield 'loading app epp_set_pin_mode_done';
  return !!IW.appGetVal('epp_set_pin_mode_success');
}
