import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import Image from 'imports/ui/shared/image/Image';
import safeRender from 'imports/ui/decorators/safeRender';

class ConfirmCustomerData extends Component {

  printData(data) {
    if (!_.isArray(data)) return data;

    return _.map(data, (item, i) => {
      if (_.isArray(item)) item = _.compact(_.map(item, (item2) => {
        if (!_.isArray(item2)) return item2.trim();
        return _.compact(_.map(item2, (item3) => item3.trim())).join(' ');
      })).join(", ");
      return <p key={i}>{item}</p>
    });
  }

  render() {
    const {onBack, onEdit, onNext, onNoImage, title, titleItems, items} = this.props;

    return (<table className="confirm-entered-data-container">
      <tbody>
      <tr>
        <td className="enter-user-data__user-info">
          <span _={_.map(titleItems, (item) => (
            <span className="transfer-description__accented" _>{item}</span>
          ))}>{title}</span>
        </td>
      </tr>
      <tr>
        <td>
          <table className="field-list">
            <tbody>
            {items.map((data, index) => {
              if (data.key && data.value) {
                return (
                  <tr key={index}>
                    <td className="field-list__description"><span _>{data.key}</span>:</td>
                    <td className="field-list__data"><span>{this.printData(data.value)}</span></td>
                  </tr>);
              }

              if (data.type === 'icon' && data.value) {
                return (
                  <tr key={index}>
                    <td colSpan={2} style={{textAlign: 'center'}}>
                      <Image src={data.value} style={{height: 260}} default={data.default}/>
                    </td>
                  </tr>
                )
              }
              return null
            })}
            </tbody>
          </table>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td style={{textAlign: 'center'}}>
          {onBack && <Button id="back" className="left button" text_="Back" onMouseDown={onBack}/>}
          {onEdit && <Button id="edit" className="center button goto-index" text_="Edit" onMouseDown={onEdit}/>}
          <Button id="next" className="right button" text_="Confirm" onMouseDown={onNext}/>
        </td>
      </tr>
      </tfoot>
    </table>);
  }
}

ConfirmCustomerData.propTypes = {
  onNext: React.PropTypes.func,
  onEdit: React.PropTypes.func,
  onBack: React.PropTypes.func,
  onNoImage: React.PropTypes.func,
  title: React.PropTypes.string,
  titleItems: React.PropTypes.array,
  items: React.PropTypes.array
};

export default ConfirmCustomerData;
