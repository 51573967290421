import {Session} from 'core/session'
import IW from 'imports/iw_api'

export default function (pageBack, pageEdit, pageNext, confirmPayoutData = () => {}){
  return function*({lastAction}) {
    IW.appSetLocalVal('is_payer_edit', false);
    if (!IW.appGetVal('payer_details_confirm')) confirmPayoutData();
      else IW.appSetObject(IW.appGetVal('payer_details_confirm'));

    if (!IW.appGetVal('recipient_payment_mode_id')){
      if (lastAction === 'back') return pageBack;
      IW.appSetLocalVal('is_payer_edit', true);
      return pageEdit;
    }

    const recipientId = +IW.appGetVal('customer_item_id');
    const recipient = (Session.get('recipients') || [])[recipientId];
    IW.appSetObject(recipient || {});

    Session.set('money_transferring_payers', null);
    IW.callWithUpdate('getPayers', 'money_transferring_payers', {payer_id: IW.appGetVal('payer_id')});
    yield 'loading session money_transferring_payers';
    const payer = _.first(Session.get('money_transferring_payers'));

    if (!payer) {
      if (lastAction === 'back') return pageBack;

      clearRecipientCity();
      IW.appSetLocalVal('is_payer_edit', true);
      return pageEdit;
    }

    IW.appSetVal('recipient_currency_test_id', payer.recipient_currency_test_id); // TODO: Replace from here
    IW.appSetVal('payer_rate', payer.rate || 0);
    IW.appSetVal('payer_sender_limit', payer.max_send || 0);
    IW.appSetVal('original_max_amount', 0);
    IW.call('getPayerLimit');
    yield 'loading app payer_sender_limit';
    IW.call('updateAmounts');
    yield 'loading app original_max_amount';

    return {
      back: pageBack,
      edit: () => {
        IW.appSetLocalVal('is_payer_edit', true);
        return pageEdit;
      },
      next: pageNext
    }
  }
}

function clearRecipientCity() {
  IW.appSetVal('recipient_city_id', '');
  IW.appSetVal('recipient_city_name', '');
  IW.appSetVal('recipient_address_city_name', '');
}
