/**
 * Created by vitaliy on 28.02.17.
 */
import React from 'react';
import Input from '../input/Input';

export default class ClearedInput extends React.Component {
  render() {
    const { clearInput, id, focus, showClearButton } = this.props;

    //console.log('ClearedInput | id, focus ', id, focus);

    return (
      <div className="cleared-input">
        <Input className="focus" {...this.props}/>
        {id === focus || showClearButton ?
          <div className="cleared-input__clear-button" onMouseDown={ clearInput } /> :
          ''}
      </div>
    )
  }
}


ClearedInput.propTypes = {
  clearInput : React.PropTypes.func,
  showClearButton: React.PropTypes.bool
};

ClearedInput.defaultProps = {
  clearInput : () => {},
  showClearButton: false
};