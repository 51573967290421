import {createContainer} from 'imports/lib/react_createContainer';
import BillScanningError from './BillScanningError'
import getPageManager from 'imports/lib/page_manager'
import IW from 'imports/iw_api';

export default createContainer(() => {

  return {
    onBack: () => getPageManager().back(),
    onNext: () => getPageManager().next(),
    imagePath: IW.appGetVal('image_path'),
    showAccountNumber: !!IW.appGetVal('biller_id')
  };

}, BillScanningError);
