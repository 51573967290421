import React from 'react';

export default class Time extends React.Component {
  render() {
    return (
      <span id="now">
        {this.props.time}
      </span>
    )
  }
}

Time.propTypes = {
  time: React.PropTypes.string
}
