/**
 * Created by vitaliy on 10.03.17.
 */
import React, {Component} from 'react';
import {_ as i18n, N} from 'imports/lib/helpers'
import safeRender from 'imports/ui/decorators/safeRender';

class TransferDescription extends Component {

  getCommonTemplate() {
    const {sendingFromNumber, sendingFromCountry, sendingToNumber, sendingToEmail, sendingToCountry, sendingToWallet, amount, via, currency} = this.props;

    if(sendingToWallet) return this.getCryptoTemplate();

    let sendingFromMessage = 'Sending from : %s %s';
    const sendingFromArgs = [
      <span className="transfer-description__number">{sendingFromNumber}</span>,
      <span className="transfer-description__country">{sendingFromCountry ? `(${i18n(sendingFromCountry)})` : ''}</span>
    ];

    if (amount) {
      sendingFromArgs.unshift(<span className="transfer-description__accented">{currency}{N(amount)}</span>);
      sendingFromMessage = 'Sending %s from : %s %s';
    }

    const sendingFrom = <span _={sendingFromArgs}>{sendingFromMessage}</span>

    let sendingTo = null
    if ((sendingToNumber || sendingToEmail) && sendingToCountry){
      const sendingToArgs = [
        <span className="transfer-description__number">{sendingToNumber ? sendingToNumber : sendingToEmail}</span>,
        <span className="transfer-description__country">{sendingToCountry ? `(${i18n(sendingToCountry)})` : ''}</span>
      ]
      if (via) sendingToArgs.push(<span className="transfer-description__accented">{via}</span>)
      const sendingToMessage = via ? 'To : %s %s via %s' : 'To : %s %s'
      sendingTo = <span _={sendingToArgs}>{sendingToMessage}</span>
    }

    return (
      <div className="transfer-description__container">
        {sendingFrom}<br/>
        {sendingTo}
      </div>);
  }

  getSendingToIn() {
    const {sendingFromNumber, sendingFromCountry, sendingToNumber, sendingToEmail, sendingToCountry, amount, via, currency} = this.props;

    let sendingMessage = 'Sending to %s to %s'
    const sendingArgs = [
      <span className="transfer-description__country">{i18n(sendingToCountry)}</span>
    ];

    if(sendingToNumber || sendingToEmail) {
      sendingArgs.unshift(<span className="transfer-description__number">{sendingToNumber ? sendingToNumber : sendingToEmail}</span>)
    }
    if (amount) {
      sendingArgs.unshift(<span className="transfer-description__accented">{currency}{N(amount)}</span>);
    }

    switch (sendingArgs.length){
      case 3:
        sendingMessage = 'Sending %s to %s in %s'; // "Sending $20 to +380634767005 in Mexico"
        break;
      case 2:
        sendingMessage = 'Sending %s to %s'; // "Sending $20 to Mexico"
        break;
      default: throw Error('Unexpected case')
    }

    return (
      <div className="transfer-description__header_container">
        <div className="transfer-description__info">
          <span _={sendingArgs}>{sendingMessage}</span>
        </div>
        <div className="money-transfer-logo">Transfer powered by <div className="db-css-transfer-logo-img"></div></div>
      </div>
    );
  }

  getCryptoTemplate() {
    const sendingMessage = 'This %s purchase will be sent to: %s';
    const sendingMessageArgs = [this.props.cryptoCurrency, this.props.sendingToWallet]

    return (
      <div className="transfer-description__container">
        <span _={sendingMessageArgs}>{sendingMessage}</span>
      </div>);
  }

  getContentByType() {
    const {type} = this.props;

    switch (type){
      case 'simple': return this.getSendingToIn();
      case 'common': return this.getCommonTemplate();
      default: return null;
    }
  }

  render() {
    return this.getContentByType();
  }
}

TransferDescription.propTypes = {
  sendingFromNumber: React.PropTypes.string,
  sendingFromCountry: React.PropTypes.string,
  sendingToNumber: React.PropTypes.string,
  sendingToEmail: React.PropTypes.string,
  sendingToCountry: React.PropTypes.string,
  amount: React.PropTypes.oneOfType([
    React.PropTypes.string,
    React.PropTypes.number
  ]),
  via: React.PropTypes.string,
  currency: React.PropTypes.string,
  transferType: React.PropTypes.string,
  type: React.PropTypes.string
};

export default safeRender(TransferDescription);
