/**
 * Created by vitaliy on 14.03.17.
 */
import React, {Component} from 'react';
import PickerIOSlider from '../picker_ios/PickerIOSliderContainer';

export default class DatePickerIOSlider extends Component {
  render() {
    const {items, onValueChange} = this.props;

    // console.log('DatePickerIOSlider | render | items : ', items);

    return (
      <table className="datepicker-ioslider-container">
        <tbody>
        <tr>
          { items.map((item, i) => {
            return (<td key={i} className="datepicker-picker-container">
              <PickerIOSlider items={item.items} sliderId={item.type} selected={item.selected}
                              onValueChange={(it) => onValueChange(it, item)}
              />
            </td>);
          })}
        </tr>
        </tbody>
      </table>);
  }
}

DatePickerIOSlider.propTypes = {
  items: React.PropTypes.arrayOf(React.PropTypes.shape({
    items: React.PropTypes.arrayOf(React.PropTypes.shape({
      text: React.PropTypes.string,
      value: React.PropTypes.number,
    })),
    type: React.PropTypes.string,
    selected: React.PropTypes.number,
  })),
  onValueChange: React.PropTypes.func,
  onMount: React.PropTypes.func,
};
