import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import {Session} from 'core/session';
import MainMenu from './MainMenu';
import Links from 'imports/collections/links';
import IW from 'imports/iw_api';
import {countInDomMenu} from "../../lib/helpers";

export default createContainer(({gaCategory}) => {
  const ROWS_COUNT = countInDomMenu();

  function getApps() {
    const apps = [];
    const links = [];
    let currentCategoryGuid = '';
    let itemsInRow = 5;
    let itemsInSlide = ROWS_COUNT * itemsInRow;

    const currentCategoryId = ~~FlowRouter.getParam('categoryName');
    const categoriesTree = Session.get('categories_tree');
    if (!categoriesTree) return apps;

    const currentCategory = _.find(categoriesTree.categories, (c) => c.id === currentCategoryId);
    if (!currentCategory) return apps;

    _.each(currentCategory.categories, (c) => {
      links.push({
        icon: c.icon,
        app_guid: 'category',
        name: c.name,
        title: c.name,
        categoryLevel: 1,
        id: c.id,
        priority: (c.priority * 1000) + 1000
      })
    });

    Links.find({product_id: {$in: currentCategory.products}, hidden: false}, {sort: {priority: -1, name: 1}})
      .fetch()
      .forEach((l) => {
        if (!currentCategoryGuid) currentCategoryGuid = l.app_guid;
        links.push({
          ...l,
          priority: currentCategory.priorities[l.product_id] || l.priority
        })
      });

    links.sort((a, b) => b.priority - a.priority);

    const anyBillIndex = links.findIndex((item) => {
      return item && item.prod_type && item.prod_type === 'any_bill'
    });

    if (/app_giftcards_ddGtr/.test(currentCategoryGuid)) {
      itemsInRow = 4;
      itemsInSlide = ROWS_COUNT * itemsInRow;
    }

    if (anyBillIndex !== -1) {
      const anyBillProduct = links.slice(anyBillIndex, anyBillIndex + 1)[0]; //get any bill product
      anyBillProduct.isBillPay = true;
      links.splice(anyBillIndex, 1);
      links.unshift(anyBillProduct); //any bill always first

      //dummy elements to fill space for 2x2 anyBillProduct icon
      [1, itemsInRow, itemsInRow + 1].forEach((dummyIndex) => links.splice(dummyIndex, 0, null));
    }

    _.each(links.complete(itemsInSlide, {}).chunk(itemsInSlide), (link) => {
      apps.push(link.chunk(itemsInRow));
    });

    return apps;
  }

  IW.updateMenuVoicePage();
  return {
    apps: getApps(),
    gaCategory,
  };

}, MainMenu);
