import {Session} from 'core/session'
import {createContainer} from 'imports/lib/react_createContainer';
import ProfileHeader from './ProfileHeader';
import {FlowRouter} from 'core/Router';
import {_} from 'imports/lib/helpers'
import IW from 'imports/iw_api';

export default createContainer((props) => {

  const onSearchBack = () => {
    Session.set('search_panel', false);
    Session.set('search_string', '')
  };

  return {
    ...props,
    backButton: FlowRouter.getParam('step') === 'edit_customer_data_profile'
    // isSearchBack: Session.get('search_panel'),
    // onSearchBack
  }
}, ProfileHeader)
