import { createContainer } from 'imports/lib/react_createContainer';
import TransferComponent from './TransferCash';
import getPageManager from 'imports/lib/page_manager'
import IW from 'imports/iw_api';

const TRANSFER_TYPES = [
  {
    text: "Transfer from primary checking account to savings account",
    iconUrl: "/resources/images/atm/ic_extract_cash.png",
    from: 1,
    to: 2
  },
  {
    text: "Transfer from primary savings account to checking account",
    iconUrl: "/resources/images/atm/ic_extract_cash.png",
    from: 2,
    to: 1
  },
  {
    text: "Transfer from primary credit card account to primary checking account",
    iconUrl: "/resources/images/atm/ic_extract_cash.png",
    from: 3,
    to: 1
  }
];

export default createContainer((params) => {
  const currentKey = IW.appGetVal('pad_key');
  IW.appSetLocalVal('pad_key', '');

  function handlePadKey(){
    if (currentKey >= 1 && currentKey <= 3){
      return onCardClick(TRANSFER_TYPES[currentKey - 1].from, TRANSFER_TYPES[currentKey - 1].to);
    }
    if (currentKey === '#') return getPageManager().back();
    if (currentKey === 'CANCEL') return getPageManager().cancel();
  }

  function onCardClick(from, to){
    IW.appSetObject({
      'selected_account': from,
      'destination_account': to
    });
    getPageManager().next();
  }

  handlePadKey();
  IW.setVoicePage('atm_select_transfer');
  return {
    transferTypes: TRANSFER_TYPES,
    onCardClick,
    onBack: () => getPageManager().back(),
    onNext: () => getPageManager().next()
  };

}, TransferComponent);