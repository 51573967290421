import IW from 'imports/iw_api';
import app_common from './app_common';
import moneyTransfer from './app_moneytransfer_common';
import createRecipientChoise from './common/recipientChoise';
import createConfirmSenderData from './common/confirmSenderData';
import createEnterRecipientData from './common/enterRecipientData';
import createPayout from './common/payout';
import createEnterSenderData from './common/enterSenderData';
import createConfirmCustomerData from './common/confirm_customer_data';
import createNumber from "./common/number";

export default _.extend({}, app_common, moneyTransfer, {
  sender_number: createNumber('exit', 'enter_sender_data_viamericas', {
    onCreate: function () {
      IW.appSetVal('sender_address_country', 'USA');
      IW.appSetVal('sender_country_iso', 'US');
      IW.appSetVal('number', IW.appGetVal('sender_number') || '', true);
    }
  }),
  enter_sender_data_viamericas: createEnterSenderData('sender_number', 'confirm_sender_data', 'confirm_sender_data'),

  confirm_sender_data: createConfirmSenderData('enter_sender_data_viamericas', 'payout', 'recipient_choice', function* () {
      IW.appSetVal('sender_init_done', false);
      IW.call('createSender');
      yield 'loading app sender_init_done';
  }),

  recipient_choice: createRecipientChoise('confirm_sender_data', 'confirm_payout', function* () {
    IW.appSetVal('sender_init_done', false);
    IW.call('createSender');
    yield 'loading app sender_init_done';
  }),

  enter_recipient_data: createEnterRecipientData('recipient_notification', 'list_slider_ofac', function* () {
    if (!IW.appGetVal('recipient_id')) {
      IW.call('createRecepient');
      yield 'loading app recipient_id';
    }
  })
});

