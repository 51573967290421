import {actions, register, state, assert} from '../../lib/app_flow_testing'
import IW from 'imports/iw_api';

register({
  app_pinproducts_EX5LS: {
    number: {
      should_continue_phone_2042121111: function* () {
        assert.page('number')
        yield actions.go('common.flow.should_continue_phone_2042121111');
      },
    },


    amount: {
      should_able_select_10: function* () {
        assert.page('amount');
        assert.regexp($('.button-buy').eq(0), /\$10/)
        actions.click($('.button-buy').eq(0), 'No amount')
        yield actions.loading()
      },
      should_able_select_60: function* () {
        assert.page('amount');
        assert.regexp($('.button-buy').eq(23), /\$60/)
        actions.click($('.button-buy').eq(23), 'No amount')
        yield actions.loading()
      }
    },
  }
})

register({
  simple_cases: {
    app_pinproducts_EX5LS: {
      launch_login_flow: function* () {
        IW.launchProduct(1782);
        yield actions.waitLoading();
        yield actions.go('app_pinproducts_EX5LS | number                 | should_continue_phone_2042121111');
        yield actions.go('common                | stored_sessions        | should_skip');
      },

      general_flow: function* () {
        yield actions.go('simple_cases          | app_pinproducts_EX5LS | launch_login_flow');

        yield actions.go('app_pinproducts_EX5LS | amount | should_able_select_10');
        yield actions.go('common                | simple | should_able_back');
        yield actions.go('app_pinproducts_EX5LS | amount | should_able_select_60');
        yield actions.go('common                | simple | should_able_back');
        yield actions.go('app_pinproducts_EX5LS | amount | should_able_select_10');

        yield actions.go('common                | flow   | should_pay_and_finish');

      },
    }
  }
})
