import {Tracker} from 'core/tracker';
import {Session} from 'core/session';
import {getSocket} from 'imports/iw_api/sockets';

Tracker.autorun(function () {
  const socket = getSocket();
  if (Session.get('io.connected') && socket && Session.get('session_guid')) {
    console.log('EMITTING /s/get_all');
    socket.emit('/s/get_all', Session.get('session_guid'));
  }
});
