import {createContainer} from 'imports/lib/react_createContainer';
import SelectItem from './SelectItem';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';

export default createContainer((params) => {
  const items = IW.appGetVal('selection_items');
  const currentKey = IW.appGetVal('pad_key');
  IW.appSetLocalVal('pad_key', '');

  function handlePadKey() {
    if (currentKey >= 1 && currentKey <= 9) return handleClickNext(currentKey - 1);
    if (currentKey === '#') return getPageManager().back();
    if (currentKey === 'CANCEL') return getPageManager().cancel();
  }

  function handleClickNext(i = 0) {
    IW.appSetVal('selected_item', items[i]);
    IW.appSetVal('selected_item_id', i);
    getPageManager().next();
  }

  handlePadKey();
  IW.setVoicePage('atm_select_item', items);
  return {
    items: items,
    onNext: handleClickNext,
    onBack: () => getPageManager().back(),
  };

}, SelectItem);