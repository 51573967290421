import login_common from './app_login_common'
import createProfile from './common/createProfile'
import createEditCustomerData from './common/edit_customer_data_profile';
import IW from '../iw_api';
import {Session} from "core/session";

export default _.extend({}, login_common, {
  index: () => 'number_sign_in',
  number_sign_in: function () {
    const SM = IW.createSessionMethods('login');

    SM.setVal('phone_is_not_valid', false);

    return {
      next: function* () {
        const phone = SM.getVal('phone_number');
        Session.set("customer_exists", undefined);
        IW.checkCustomer(phone, function (_, result) {
          Session.set("customer_exists", result);
        });
        yield `loading session_exists customer_exists`;

        if (!Session.get("customer_exists")) {
          SM.setVal('phone_is_not_valid', true);
          return 'none';
        }

        return 'pin_redirect'
      },
      back: 'exit'
    }
  },

  on_login: () => 'profile',

  profile_select: () => {
    return {
      next: () => {
        const profileType = IW.loginGetVal('profile_type');
        return profileType ? profileType : 'none';
      },
      skip: 'profile'
    }
  },

  profile: createProfile(),
  edit_customer_data_profile: createEditCustomerData('profile'),
})
