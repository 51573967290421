import {createContainer} from 'imports/lib/react_createContainer';
import ClerkLoginError from './ClerkLoginError'
import getPageManager from 'imports/lib/page_manager'
import IW from 'imports/iw_api';

export default createContainer(() => {

  const createMessage = () => {
    const SM = IW.createSessionMethods('clerk');
    const errorCode = SM.getVal('clerk_login_error');

    let message = 'Unexpected error';

    if(errorCode === 'access_token_not_found' || errorCode === 'access_denied'){
      message = 'Access denied'
    }

    if(errorCode === 'user_not_found'){
      message = 'User not found'
    }

    if(errorCode === 'device_not_found'){
      message = 'Clerk device not found'
    }

    return message;
  };

  return {
    onNext: () => getPageManager().next(),
    message: createMessage()
  };

}, ClerkLoginError);
