import React, {Component} from 'react';
import {C} from 'imports/lib/helpers';
import NumpadDisabledKey from 'imports/ui/shared/numpad/NumpadDisabledKey';
import Button from 'imports/ui/shared/button/Button';
import Input from 'imports/ui/shared/input/Input';
import safeRender from 'imports/ui/decorators/safeRender';

class EnterAmount extends Component {

  render() {
    const {amount, amountEnterTip, currency, numpadLayout, lockedKeys, showConfirm, onValueChange, onNext, onBack} = this.props;

    console.log('EnterAmount | props ', lockedKeys);

    return (
      <table className="atm-enter-amount">
        <tbody>
        <tr>
          <td>
            <div className="atm-enter-amount__input">
              <Input id="amount" className="focus" value={C(amount, currency)}
                     readOnly={true}
              />
              <br />
              <br />
              <span className="text-md" _>Enter the Amount</span>
              <br />
              <span className="text-md">{amountEnterTip}</span>
            </div>
          </td>
          <td>
            <NumpadDisabledKey keys={numpadLayout} lockedKeys={lockedKeys} value={amount}
                               onValueChange={onValueChange}/>
          </td>
        </tr>
        </tbody>
        <tfoot className="atm-foot">
        <tr>
          <td colSpan="2">
            <Button id="back" className="left button" text_="Back" onMouseDown={onBack}/>
            { showConfirm ? <Button id="next" className="right button" onMouseDown={onNext} text_="Confirm"/> :
              <Button id="next" className="right button hidden" onMouseDown={onNext} text_="Confirm"/> }
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

EnterAmount.propTypes = {
  amount: React.PropTypes.string,
  currency: React.PropTypes.string,
  numpadLayout: React.PropTypes.arrayOf(React.PropTypes.array),
  lockedKeys: React.PropTypes.array,
  onValueChange: React.PropTypes.func,
  showConfirm: React.PropTypes.bool,
  onBack: React.PropTypes.func,
  amountEnterTip: React.PropTypes.string,
  // TODO : remove this!
  onNext: React.PropTypes.func
};

export default EnterAmount;
