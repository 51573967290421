import loadJson from 'core/loadJson';

const settings = loadJson('/config/settings.json');

export default _.extend({
  DEBUG: false,
  VERSION: "1.0.0.23",
  PARTNER_NAME: 'Maya',
  TITLE: 'Maya Kiosk',
  // Timeouts (in minutes)
  IDLE_TIMEOUT: 5,
  SEARCH_TIMEOUT: 1,
  POPUP_TIMEOUT: 0.75,
  // Timeouts (in seconds)
  TIMEOUT: 15,
  TIMEOUT_APP: 60,
  TIMEOUT_INDEX: Number.MAX_SAFE_INTEGER,
  TIMEOUT_PAYMENT: 300,
  TIMEOUT_LOADING: 300,
  TIMEOUT_WAITING: 300,
  TIMEOUT_SUCCESS_PAYMENT: 10,
  TIMEOUT_CHANGE_LANGUAGE: 300,
  INTERNAL_TIMEOUT: 30,
  KEEP_ALIVE_TIMEOUT: 60,
  LANGUAGES_PER_PAGE: 4,
  SOCKETIO_SERVER: "https://api-staging.maya.tech/daemon",
  STREAM_SERVER: "ws://localhost:8084",
  PREVIEW_SERVER: "http://local.atm.cm:8090",
  CLOCK_FORMAT: "dddd h:mma M/D/YY",
  CLOCK_FULL_FORMAT: "h:mma, D of MMMM YYYY",
  DEFAULT_LANGUAGE: "en_US",
  NUMBER_PATTERN: "\\+1 [2-9|0]\\d{2} \\d{3} \\d{4}",
  SEARCH_MINLEN: 2,

  PARTNER_SETTINGS: {
    support_number: '...',
    print_receipt_automaticaly: true,
  },

  ATM_PAGES_WITHOUT_MENU: ["return_card", "extract_money", "transaction_canceled", "bad_card"],
  ATM_ENABLED: true,
  ATM_ENABLED_IN_HEADER: true,
  ATM_ENABLED_IN_CATEGORY: true,
  ATM_ENABLED_IN_LANGUAGE_PAGE: true,
  ATM_COMING_SOON: true,
  ATM_ALLOW_INPUT_ANY_AMOUNT: false,
  ATM_MIN_DISPENSE: 20,
  ATM_MIN_TRANSFER: 1,
  ATM_MAX_DISPENSE: 500,
  ATM_MAX_TRANSFER: 100000,
  ATM_AMOUNTS: [20, 40, 60, 80, 100, 200, 500, 1000],
  ATM_IGNORE_COUNTERS: false,
  ATM_DISABLE_TRANSFER: false,
  CATEGORIES_SPEC_LINKS: ['gift_cards'],
  PARTIALS_REGEX: {
    PARTIAL_MATCHING: {
      CAN: {
        control_length: 4,
        number: /^1(204|236|249|250|289|306|343|365|387|403|416|418|431|437|438|450|506|514|519|548|579|581|587|604|613|639|647|672|705|709|742|778|780|782|807|819|825|867|873|902|905).*/,
      },
      DOM: {
        control_length: 4,
        number: /^1(809|829|849).*/,
      },
      USA: {
        control_length: 4,
        number: /^1((?!((1\d{2})|204|236|249|250|289|306|343|365|387|403|416|418|431|437|438|450|506|514|519|548|579|581|587|604|613|639|647|672|705|709|742|778|780|782|807|819|825|867|873|902|905|809|829|849)).){3}.*/,
      }
    },
    BAD_PARTIAL_MATCHING: {
      '1': {
        control_length: 2,
        number: /^11.*/,
        code: '+1 1',
        pattern: '\\+1 1\\d{2} \\d{3} \\d{4}'
      }
    }
  },
  SLIDER: {
    slideStartVelocityThreshold: 7,
    snapVelocityThreshold: 5,
  },
  APPS_PRIORITY: {
    app_phonereloadmx_catEg: 1000
  },
  SHOW_NOTICE: false,
  SHOW_NO_COINS_NOTICE: false,
  SHOW_NO_COINS_NOTICE_HARD: false,
  LOGIN_ENABLED: false,
  BILL_SCANNING_ENABLED: false,
  BILL_SCANNING_GENMEGA: false,
  LIVE_IMAGES_GENMEGA: false,
  PAY_METHODS: ['cash', 'card'],
  PAY_NOMINALS: [[1, 2, 5, 10, 20, 50, 100], [1, 5, 10, 25, 100]],
  BILL_MIN_AMOUNT: 3,
  BILL_MAX_AMOUNT: 1500,
  MONEYTRANSFER_MIN_AMOUNT: 10,
  MONEYTRANSFER_MAX_AMOUNT: 1000,
  EZPAYBILLS_MAX_AMOUNT: 199,
  MT_PAYERS_PRIORITY: {
    sigue: 2000,
    electra: 1000,
    elecktra: 1000
  },
  BINDED_PRODUCTS:[
    {plan: 58, prepaid: 1783},
    {plan: 45, prepaid: 1791},
    {plan: 12, prepaid: 1757},
    {simple: 893, amigo: 1967, internet: 1968}, // telcel
    {simple: 888, internet: 1969}, // movistar
    {online: 1963, membership: 1965}, // playstation
  ],
  MAYA5_ENABLED: true,
  REFERRALS_ENABLED: true,
  FIRST_PAYMENT_ENABLED: true,
  WELCOME_BACK_ENABLED: true,
  FREE_TRANSACTION_ENABLED: true,
  IGNORE_PRODUCT_AMOUNTS: [],
  REFUND_ENABLED: true,
  SWEEPSTAKES_ENABLED: true,
  SWEEPSTAKES_PRIZES: {
    10: 0.7,
    50: 0.1,
    100: 0.05
  },
  DISPLAY_CONTINUE_LATER: true,
  MAIN_SCREEN_LOGIN: true,
  MAIN_SCREEN_GET_APP: true,
  VIRTUAL_ENABLED: true,
  CLERK_ENABLED: false,
  CLERK_AMOUNT_ENABLED: true,
  SCREEN_CAPTURE: false,

  CASH_CHECK_MAX_DISPENSE: 1000,
  CASH_CHECK_MIN_DISPENSE: 5,
  CASH_CHECK_AUTO_APPROVE: false,
  CASH_CHECK_CLERK_REVIEW: false,

  CARD_READER_ROUTINE: false,

  INDEX_PAGE_BUTTON_NAME: 'Bill Pay & More',

  SHOW_POPULAR_PRODUCTS_ADVERTISEMENT: false,
}, settings.public.clamshell_configuration)
