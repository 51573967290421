import React, {Component} from 'react';
import Numpad from 'imports/ui/shared/numpad/Numpad';
import Button from 'imports/ui/shared/button/Button';
import ClearedInput from 'imports/ui/shared/cleared_input/ClearedInput';
import TransferDescription from 'imports/ui/shared/transfer_description/TransferDescriptionSessionContainer';
import IW from 'imports/iw_api';
import {Session} from 'core/session';
import AmountStatistic from 'imports/ui/shared/amount_statistic/AmountStatisticContainer';
import {C, S, capitalize, _} from 'imports/lib/helpers'

export default class EnterAmount extends Component {
  componentDidMount() {
    Session.set('focus', 'amount');

    const field_to_focus = Session.get('focus');
    Session.set('focus', '');
    Session.set('focus', field_to_focus);

    IW.appSetVal('focus', field_to_focus);
    IW.call('updateAmounts');
  }

  /*
  *
   <br/>
   <span _>(maximum amount is $900)</span>
  * */

  render() {
    const {amount, currency, numpadLayout, maxAmount, confirm, onPrintNumber, onNext, onBack, clearInput,
      disableTransferDescription, title} = this.props;

    return (
      <table className="enter-amount">
        <tbody>
        <tr className="thin">
          <td colSpan="2">
            {disableTransferDescription ? null : <TransferDescription/>}
            <div className="money-transfer-logo">Transfer powered by <div className="db-css-transfer-logo-img"></div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className="enter-amount__input">
              <div className="label" _>{title || 'Amount to send'}</div>
              <div className="label" style={{fontSize: 30}} _={[currency + C(maxAmount)]}>(Daily transfer limit: %s excluding fees)
              </div>
              <ClearedInput id="amount" className="focus" value={currency + C(amount)} readOnly={true} focus="amount"
                            clearInput={clearInput}/>
              <br/>
            </div>
            <AmountStatistic className="amount-statistic"/>
            <div className="enter-amount__information_block">
              <div className="enter-amount__addition_text">
                {Session.get('app_guid') === 'app_cryptocoin_DgtMt'
                  ? _('*rate includes a Digital Mint service fee.')
                  : capitalize(IW.appGetVal('service_name')) + ' ' + _(`may make money when it changes your US Dollars into a different currency.`)}
                <br/>
                {(IW.appGetVal('service_name') === 'sigue' || Session.get('app_guid') === 'app_cryptocoin_DgtMt')
                  ? '' : _('Recipient may receive less due to fees charged by recipient’s bank and applicable taxes.')}
              </div>
            </div>
          </td>
          <td>
            <Numpad keys={numpadLayout} value={S(amount)} onValueChange={onPrintNumber}/>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2">
            <Button id="back" className="left button" text_="Back" onMouseDown={onBack}/>
            {confirm ? <Button id="next" className="right button" onMouseDown={onNext} text_="Confirm"/> : null}
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

EnterAmount.propTypes = {
  amount: React.PropTypes.number,
  currency: React.PropTypes.string,
  maxAmount: React.PropTypes.number,
  numpadLayout: React.PropTypes.arrayOf(React.PropTypes.array),
  onPrintNumber: React.PropTypes.func,
  confirm: React.PropTypes.bool,
  onNext: React.PropTypes.func,
  onBack: React.PropTypes.func,
  clearInput: React.PropTypes.func
};
