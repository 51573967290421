import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import _ from 'lodash';
import {Meteor} from '../core/meteor';
import {Session} from '../core/session';

window.$ = $;
window.jQuery = $;
window._ = _;
window._.contains = _.includes;
window.Meteor = Meteor;
window.Session = Session;

React.PropTypes = PropTypes
