import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import safeRender from 'imports/ui/decorators/safeRender';

class CardBalance extends Component {
  render() {
    const {
      smallText, bigText, srcImg, isSpinner, onCancel, onBack, onPrint, onImageClick
    } = this.props;

    return (
      <table>
        <tbody>
        <tr>
          <td>
            <div className="atm-top-tr">
              <div>
                <div className="atm__temp-page atm__temp-page__text">
                  <span>{smallText}</span>
                </div>
                <div className="atm__temp-page atm__display__text">
                  <span>{bigText}</span>
                </div>
              </div>
              <div className="atm__temp-page atm__display__img">
                <img src={srcImg} alt=""/>
              </div>
            </div>
          </td>
        </tr>
        {isSpinner ? (
            <tr>
              <td>
                <div className="atm-down-tr">
                  <div className="spinner-dots">
                    <div className="spinner-dots__item spinner-dots__item-1"></div>
                    <div className="spinner-dots__item spinner-dots__item-2"></div>
                    <div className="spinner-dots__item spinner-dots__item-3"></div>
                    <div className="spinner-dots__item spinner-dots__item-4"></div>
                  </div>
                </div>
              </td>
            </tr>) :
          null }

        </tbody>
        <tfoot>
        <tr>
          <td style={{textAlign: 'center'}}>
            { onBack ?
              <Button id="back" className="left button" text_="Back" onMouseDown={onBack}/> :
              null }
            { onCancel ?
              <Button className="left button button-danger" onMouseDown={onCancel} text_="Cancel"/> :
              null }
            { onPrint ?
              <Button id="next" className="right button" onMouseDown={onPrint} text_="Print"/> :
              null }
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

CardBalance.propTypes = {
  smallText: React.PropTypes.string,
  bigText: React.PropTypes.string,
  srcImg: React.PropTypes.string,
  isSpinner: React.PropTypes.bool,
  onCancel: React.PropTypes.func,
  onPrint: React.PropTypes.func,
  onBack: React.PropTypes.func,
};

CardBalance.defaultProps = {
  smallText: '',
  bigText: '',
  srcImg: '',
  isSpinner: true
};

export default CardBalance;
