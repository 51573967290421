import {createContainer} from 'imports/lib/react_createContainer';
import ConfirmCustomerData from './ConfirmCustomerData';
import {capitalize} from 'imports/lib/helpers'
import IW from 'imports/iw_api';

import getPageManager from 'imports/lib/page_manager'

export default createContainer(() => {
  const getPayoutModeName = () => {
    let payoutMode = null;

    if (customerData.recipient_payment_mode_type === 'cash_pickup') {
      payoutMode = capitalize('CASH PICK UP');
    }

    if (customerData.recipient_payment_mode_type === 'bank_deposit') {
      payoutMode = capitalize('BANK DEPOSIT');
    }

    if (customerData.recipient_payment_mode_type === 'delivery') {
      payoutMode = capitalize('DELIVERY');
    }

    return payoutMode;
  };

  const getPayoutModeIcon = () => {
    let payoutMode = null;

    if (customerData.recipient_payment_mode_type === 'cash_pickup') {
      payoutMode = 'cash';
    }

    if (customerData.recipient_payment_mode_type === 'bank_deposit') {
      payoutMode = 'deposit';
    }

    if (customerData.recipient_payment_mode_type === 'delivery') {
      payoutMode = 'delivery';
    }

    const country = customerData.recipient_address_country;
    const payerName = customerData.payer_name || customerData.payer_bank_name;

    return {
      value: '/resources/icons/payout/' + payoutMode + '/' + country.toLowerCase().replace(/ /g, '_') + '/' + payerName.toLowerCase().replace(/ /g, '') + '.png',
      default: '/resources/icons/payout/' + payoutMode + '/default.png',
    }
  };

  const customerData = {
    recipient_payment_mode_type: IW.appGetVal('recipient_payment_mode_type'),
    payer_bank_name: IW.appGetVal('payer_bank_name'),
    bank_account: IW.appGetVal('bank_account'),
    payer_name: IW.appGetVal('payer_name'),
    payer_rate: +IW.appGetVal('payer_rate'),
    recipient_address_country: IW.appGetVal('recipient_address_country'),
  };
  const customerViewData = [];

  if (!_.isEmpty(customerData)) {
    customerViewData.push({
      key: "Payout Mode",
      value: getPayoutModeName()
    });

    if (customerData.payer_bank_name) {
      customerViewData.push({
        key: "Bank Name",
        value: capitalize(customerData.payer_bank_name)
      });

      customerViewData.push({
        key: "Bank Account",
        value: _.map(_.range(customerData.bank_account.length - 4), () => '#').join('') + customerData.bank_account.slice(-4)
      });
    }

    if (customerData.payer_name) {
      customerViewData.push({
        key: "Network",
        value: capitalize(customerData.payer_name)
      });
    }

    customerViewData.push({
      key: "Current Exchange Rate",
      value: '$1 = $' + customerData.payer_rate,
    });

    customerViewData.push({
      type: "icon",
      ...getPayoutModeIcon(),
    });
  }

  return {
    title: customerData.recipient_address_country ?
      'Confirm %s in %s below:' :
      'Confirm %s below:',
    titleItems: customerData.recipient_address_country ?
      ['Payout Network', customerData.recipient_address_country] :
      ['Payout Network'],
    items: customerViewData,
    onBack: () => getPageManager().back(),
    onEdit: () => getPageManager().edit(),
    onNext: () => getPageManager().next(),
  };
}, ConfirmCustomerData);
