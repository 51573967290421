import {createContainer} from 'imports/lib/react_createContainer';
import EnterUserDataContainer from './EnterUserDataContainer';
import {KEYBOARDS, TRANSFER_INPUTS_RESULT} from 'imports/lib/constants';
import IW from 'imports/iw_api';
import {
  createLazyExecution,
  validateText,
  getValueFromSession,
  S} from 'imports/lib/helpers';

export default createContainer((params) => {
  const updateCustomer = createLazyExecution(() => {
    let customer = IW.appGetVal("customer");
    if (!_.isEmpty(customer)) IW.updateCustomer(customer);
  }, 500);

  const fields = [
    {
      name: 'first_name',
      describe: 'First name',
      describe_output: 'First name',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      validation: {
        required: true,
        minLength: 2,
        maxLength: 28
      }
    },
    {
      name: 'middle_name',
      describe: 'Middle name',
      describe_output: 'Middle name',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      validation: {
        required: false,
        minLength: 2,
        maxLength: 28
      }
    },
    {
      name: 'last_name',
      describe: 'Last name',
      describe_output: 'Last name',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      validation: {
        required: true,
        minLength: 2,
        maxLength: 28
      }
    },
    {
      name: 'address',
      describe: 'Address',
      describe_output: 'Address',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SEARCH_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      hideFromList: true,
      output_scheme: [
        ['address1', 'address2'],
        ['address_city', 'address_state'],
        ['address_zip'],
        ['address_country']
      ],
      validation: {
        required: true,
        pattern: ''
      }
    },
    {
      name: 'address1',
      describe: 'Street Address',
      describe_output: 'Address',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        required: true,
        pattern: '',
        maxLength: 60
      },
      part_of: 'address'
    },
    {
      name: 'address2',
      describe: 'Unit or Suite #',
      describe_output: 'Unit or Suite #',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        required: false,
        pattern: '',
        maxLength: 60
      },
      part_of: 'address'
    },
    {
      name: 'address_city',
      describe: 'City',
      describe_output: 'City',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      validation: {
        exists: 'cities',
        required: true
      },
      part_of: 'address'
    },
    {
      name: 'address_state',
      describe: 'State',
      describe_output: 'State',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      validation: {
        required: true,
        exists: 'states'
      },
      part_of: 'address'
    },
    {
      name: 'address_zip',
      describe: 'ZIP',
      describe_output: 'ZIP',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      validation: {
        required: true,
        minLength: 2,
        maxLength: 10
      },
      part_of: 'address'
    }
  ];

  const currentActiveField = fields[Session.get('current_active_field') || 0]

  function getValue(name) {
    const v = getValueFromSession(name, 'customer', 'app');
    return S(v);
  }

  function getCurrentActualValue() {
    return getValue(currentActiveField.name);
  }

  function validateInput() {
    const input = getCurrentActualValue();
    return validateText(input, currentActiveField.validation);
  }

  return {
    fields,
    inObject: "customer",
    onAfterInput(){
      updateCustomer()
    },
    showNext: validateInput()
  }
}, EnterUserDataContainer);
