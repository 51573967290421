import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import IW from 'imports/iw_api';
import ShoppingCartLoading from './ShoppingCartLoading';
import {S, getName, getUser} from 'imports/lib/helpers'

export default createContainer((params) => {
  function getShoppingCartItems(){
    return _.map(IW.appGetVal('shopping_cart') || [], (item, key) => {
      return _.extend(item, {id: key, paymentStatus: item.payment_status});
    }).filter(i => i.selected && i.enabled);
  }

  // console.log('IW ', IW);

  const shoppingCart = getShoppingCartItems();
  const successItems = _.filter(shoppingCart, i => i.paymentStatus === 'success');
  if (successItems.length === shoppingCart.length){
    Session.set('shopping_cart_length', 0);
  }

  const payingItems = _.filter(shoppingCart, i => i.paymentStatus === 'paying');
  const pendingItems = _.filter(shoppingCart, i => i.paymentStatus === 'paying');

  return {
    userName: getName(S(getUser().last_name) + ' ' + S(getUser().first_name)),
    shoppingCart: shoppingCart,
    shoppingCartPayedCount: successItems.length,
    shoppingCartCount: shoppingCart.length,
    optionsSlider : {
      scrollbarBackground : '#ff972b',
      scrollbarWidth: '8px',
      scrollbarMargin: '0px',
      scrollbarOpacity: '1',
      scrollbarBorderRadius : '0px'
    },
  };

}, ShoppingCartLoading);