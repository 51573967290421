/* eslint-disable */
+function(){
  let lastWidth = -1;
  setInterval(function () {
    const scaleWidth = window.innerWidth / 1280;
    const scaleHeight = window.innerHeight / 1024;
    const globalScale = Math.min(scaleWidth, scaleHeight);
    const newWidth = 1280 * globalScale;
    if (lastWidth === newWidth) return;
    lastWidth = newWidth;
    document.documentElement.style
      .setProperty('--screen-width', newWidth);
  }, 300);
}()