/**
 * Created by vitaliy on 13.03.17.
 */
import {createContainer} from 'imports/lib/react_createContainer';
import EnterUserDataContainer from './EnterUserDataContainer';
import {TRANSFER_INPUTS_RESULT, KEYBOARDS, TRNASPYMENT_DEFAULT_FIELDS as mustSetByDefault} from 'imports/lib/constants';
import IW from 'imports/iw_api';
import {capitalize, validateText} from 'imports/lib/helpers';
import {Session} from 'core/session';

export default createContainer((params) => {
  const requiredFields = IW.appGetVal('required_fields'); // [{name, description, format:{length,charSet}}]

  const fields = _.map(requiredFields, (field) => ({
    name: field.name,
    describe:  capitalize(field.description),
    describe_output: capitalize(field.description),
    type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
    type_keyboard: (field.format.charSet === 'A' && KEYBOARDS.TEXT_NUMBER) ||
                  (field.format.charSet === 'N' && KEYBOARDS.NUMBER) ||
                  KEYBOARDS.TEXT_NUMBER,
    validation: {
      required: true,
      minLength: Math.min(4, field.format.length),
      maxLength: field.format.length,
    },
    maxLength: field.format.length
  }));

  if (!fields || fields.length === 0){
    return {}
  }

  // Set fields by default
  function setDefaults() {
    if (requiredFields !== null) {
      requiredFields.forEach((requiredField) => {
        const resultFromDefault = mustSetByDefault.find((v) => v.keyFromRequired === requiredField.name);

        if(resultFromDefault) {
          const keyForIW = `required_${resultFromDefault.keyFromRequired}`;
          const valueForIW = resultFromDefault.formatterFromIW(IW.appGetVal(resultFromDefault.keyFromIW), requiredField.format);
          // we don't want rewrite setted value (exmpl. when touch back, and again returned on this page)
          if (!IW.appGetVal(keyForIW)) {
            IW.appSetVal(keyForIW, valueForIW);
            const result = getValuesForCheckDefaults(resultFromDefault.keyFromIW, keyForIW, IW);

            Session.set(result.keyHashSession, result.calculatedValuesHash);
          } else if (resultFromDefault.listenIW) {
            // Also, we check this value is a listener.
            // If 'yes' - we must observe at changes resultFromDefault.keyFromIW
            // Check last 'hash' of observes value and current
            const result = getValuesForCheckDefaults(resultFromDefault.keyFromIW, keyForIW, IW);
            
            const valuesHashSession = Session.get(result.keyHashSession);

            if (valuesHashSession !== result.calculatedValuesHash) {
              IW.appSetVal(keyForIW, valueForIW);
              Session.set(result.keyHashSession, result.calculatedValuesHash);
            }
          }
        }
      });
    }
  }

  // indicate first load page (maybe use componentDidMount?)
  if (Session.get('use_page_preload_cb')) {
    setDefaults();
    Session.set('use_page_preload_cb', false);
    // return first 'bad' index, that means, first not filled value
    let index = fields.findIndex((item) => {
      const val = IW.appGetVal(`required_${item.name}`);

      if (!val) return true;

      if (validateText(val, item.validation, 'required_')) return false;

      return true;
    });

    if (index < 0) index = fields.length - 1;

    Session.set('current_active_field', index);
  }

  function onInput(value) {
    const keyIW = `required_${currentActiveField.name}`;

    IW.appSetVal(keyIW, value.toUpperCase());

    // Detect, if this field is a listener
    const defaultField = mustSetByDefault.find((v) => v.keyFromRequired === currentActiveField.name);

    if (defaultField && defaultField.listenIW) {
      /*
       * For example: two session values are related (a and b).
       * If a changed, b must changed too. But if b - changed, a not must be changed
       */
      const result = getValuesForCheckDefaults(defaultField.keyFromIW, keyIW, IW);
      
      Session.set(result.keyHashSession, result.calculatedValuesHash);
    }
  }

  /**
   * Prepare main params for check at conformity observable and listener values.
   * @param keyObservable
   * @param keyListener
   * @param store
   * @param method
   * @return {*}
   */
  function getValuesForCheckDefaults(keyObservable, keyListener, store, method = 'appGetVal') {
    try {
      const keyHashSession = `${keyObservable}_${keyListener}`;
      const valueCurrentObservable = store[method](keyObservable);
      const valueCurrentListener = store[method](keyListener);
      const calculatedValuesHash = valueCurrentObservable.toString().concat(valueCurrentListener.toString());

      return {
        keyHashSession,
        valueCurrentObservable,
        valueCurrentListener,
        calculatedValuesHash
      }
    } catch (e) {
      return {
        keyHashSession : null,
        valueCurrentObservable : null,
        valueCurrentListener : null,
        calculatedValuesHash : null
      };
    }

  }
  
  const currentActiveField = fields[Session.get('current_active_field') || 0];

  return {
    fields,
    prefix: 'required',
    onInput
  }
}, EnterUserDataContainer);