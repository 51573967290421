import React from 'react'
import {store} from "./store";
import _ from 'lodash';
import _castPath from 'lodash/_castPath';
import I from 'immutable';

let currentSession = I.fromJS({});
const setToStoreDebounce = _.debounce(() => {
  store.dispatch({type: 'SET_SESSION', payload: currentSession});
}, 10);

export const Session = {
  _get(selector) {
    if (Session.__getKeys) Session.__getKeys.push(selector);
    const path = _castPath(selector, currentSession);
    return currentSession.getIn(path);
  },
  get(selector) {
    const value = Session._get(selector);
    return _.isObject(value) && value.toJS ? value.toJS() : value;
  },
  set(selector, obj) {
    const res = _.set({}, selector, obj);
    const newSessions = currentSession.merge(I.fromJS(res));
    if (newSessions !== currentSession) setToStoreDebounce();
    currentSession = newSessions;
  },
  setDefault(selector, obj) {
    Session.set(selector, obj)
  },
  all() {
    return currentSession.toJS();
  }
}

if (process.env.NODE_ENV !== 'production') {
  window.onbeforeunload = function (event) {
    if (window.location.href.includes('/app/')) {
      Session.set('last_location', window.location.toString())
      window.sessionStorage.setItem('session', JSON.stringify(Session.all()));
    } else {
      window.sessionStorage.setItem('session', '');
    }
  };

  setTimeout(() => {
    const sessionJSON = window.sessionStorage.getItem('session');
    if (!sessionJSON) return;
    try {
      const sessionJS = JSON.parse(sessionJSON);
      if (sessionJS.last_location !== window.location.toString()) return;
      currentSession = I.fromJS(JSON.parse(sessionJSON));
      setToStoreDebounce();
    } catch (err) {
      console.error('Session restore:', err)
    }
  }, 1);
}