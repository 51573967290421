import IW from 'imports/iw_api';
import {LOGIN_FIELDS} from '../../lib/constants';
import {S} from '../../lib/helpers';

export default function (pageNext) {
  return function*() {
    //Get customer data
    const customerData = IW.appGetVal('customer');
    const updateObject = {};

    _.each(LOGIN_FIELDS, (f) => {
      updateObject[`sender_${f}`] = S(customerData[f])
    });

    IW.appSetObject(updateObject);

    return {
      edit: 'edit_customer_data',
      next: function*() {
        IW.appSetVal('confirm_customer_data', true);
        return pageNext
      }
    }
  }
}
