/**
 * Created by vitaliy on 22.03.17.
 */
import React from 'react';

const InputLabel = (params) => {
  const { text, style } = params;

  return (<span className="text-md padding-l-md " style={style} _>{text}</span>);
};

export default InputLabel;