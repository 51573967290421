import {Session} from 'core/session'
import IW from 'imports/iw_api'
import CONFIG from 'imports/config'

export default function(pageBack, pageNext){
  return function({lastAction}){
    if (!CONFIG.SHOW_NOTICE || IW.appGetVal('notice_showed')){
      if(lastAction === 'next'){
        return pageNext;
      }

      return pageBack;
    }

    return {
      next: function() {
        IW.appSetVal('notice_showed', true);
        return pageNext
      },
      cancel: 'exit',
    }
  }
}
