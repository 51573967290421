import app_common from './app_common';
import createPaymentStatus from './common/payment_status';
import IW from 'imports/iw_api';
import createPayDevice from './common/payDevice';
import createPayVirtual from './common/payVirtual';

// ["shopping_cart", "pay", "shopping_cart_loading", "final", "email", "sent"]
export default _.extend({}, app_common, {
  index: 'shopping_cart',
  shopping_cart: function*() {
    Session.set('transaction_limits', null)
    IW.callWithUpdate('getShoppingCartLimits', 'transaction_limits');
    yield 'loading session transaction_limits';
    IW.appSetLocalVal('shopping_cart_limits', Session.get('transaction_limits').shopping_cart_limits);
    return {
      cancel: 'exit',
      next: function () {
        IW.appSetLocalVal('maya5_referral_hidden', true);
        IW.appSetLocalVal('customer_next_page', 'pay');
        return 'redirect_customer';
      },
    }
  },

  pay_device: createPayDevice('back', null, 'shopping_cart_loading'),
  pay_virtual: createPayVirtual('back', null, 'shopping_cart_loading', 'amount_clerk'),

  shopping_cart_loading: function*() {
    if (IW.appGetVal('session_stored')) return 'final';
    IW.appSetLocalVal('payment_status', '');
    yield 'sleep app payment_status';
    return 'final';
  },

  payment_status: createPaymentStatus(null, 'shopping_cart', 'pay'),
});
