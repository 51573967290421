import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import AppIcon from './AppIcon';
import IW from 'imports/iw_api';
import getPageManager, {goTo} from 'imports/lib/page_manager';
import appWorkflow from 'imports/apps_workflow'
import {gaEvent} from "imports/lib/ga";

export default createContainer((params) => {

  function onTapIcon(event) {
    if(params.icon.product_type === 'digital_mint_product') {
      getPageManager().next(params.icon)
      return event.preventDefault();
    }

    const icon = params.icon;
    const app_guid = params.icon.app_guid;


    // Ignore mousedown for slider items
    if ((event.type == 'mousedown') && $('.iosSlider').data('iosslider')) {
      gaEvent(params.gaCategory, 'Slider swipe');
      return;
    }

    if (icon.categoryLevel > 0){
      const route = {}
      route['subCategory' + icon.categoryLevel] = String(icon.id);
      gaEvent(params.gaCategory, 'Category select', String(icon.id));

      FlowRouter.go('SubCategory' + icon.categoryLevel, _.extend(FlowRouter.current().params, route));
      return event.preventDefault();
    }

    const startPage = appWorkflow[app_guid] && appWorkflow[app_guid][icon.start_page] ?
      icon.start_page :
      null;

    if (FlowRouter.getParam('appName') && !icon.force) {
      // console.log('There's an app currently running, update the appVals');

      IW.appSetVal('feed', icon.feed || null, '_private');

      if(icon.product_id) IW.appSetVal('product_id', icon.product_id); // product_id
      if(_.has(icon, 'network_id')) IW.appSetVal('network_id', icon.network_id); // network_id
      if(_.has(icon, 'required_sender_id_type')) IW.appSetVal('required_sender_id_type', icon.required_sender_id_type); // network_id
      if(_.has(icon, 's_customer_id_type')) IW.appSetVal('s_customer_id_type', icon.s_customer_id_type); // network_id

      if(_.has(icon, 'customer_id')) IW.appSetVal('customer_id', icon.customer_id || null); // user id in session
      if(_.has(icon, 'user')) IW.appSetVal('user', icon.user || null); // user id in session

      // For sigue payment locations
      if(_.has(icon, 'agent_loc_agent_id')) IW.appSetVal('agent_loc_agent_id', icon.agent_loc_agent_id);
      if(_.has(icon, 'agent_loc_phone')) IW.appSetVal('agent_loc_phone', icon.agent_loc_phone);
      if(_.has(icon, 'agent_loc_address')) IW.appSetVal('agent_loc_address', icon.agent_loc_address);
      if(_.has(icon, 'agent_loc_name')) IW.appSetVal('agent_loc_name', icon.agent_loc_name);
      if(_.has(icon, 'agent_loc_city_id')) IW.appSetVal('agent_loc_city_id', icon.agent_loc_city_id);
      if(_.has(icon, 'agent_loc_internal_code')) IW.appSetVal('agent_loc_internal_code', icon.agent_loc_internal_code);

      // Don't replace title if we in transfer money check payout network

      if (icon.title) {
        Session.set('icon', icon.icon);
        Session.set('title', icon.title);
      }

      if (startPage){
        IW.appSetVal('page', startPage);
        goTo(startPage, app_guid, icon.product_id ?
            String(icon.product_id) :
            null);
      } else {
        getPageManager().next(params);
      }

    } else {
      // console.log("There's no app currently active, launching a new app session");
      const data = {
        icon: icon.icon,
        app_icon: icon.app_icon,
        title: icon.title,
        product_id: icon.product_id || 0, // product_id
        page: startPage,
        feed: icon.feed,
        customer_id: icon.customer_id,
        user: icon.user
      };


      gaEvent(params.gaCategory, 'App select', String(app_guid));
      IW.appLaunch(app_guid, data);

      goTo(startPage || 'exit', app_guid, String(icon.product_id || '0'));
    }

    return event.preventDefault();
  }

  return {
    icon: params.icon,
    onTapIcon: onTapIcon
  };

}, AppIcon);
