import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import safeRender from 'imports/ui/decorators/safeRender';
import Disclaimer from 'imports/ui/apps/sweepstakes/DisclaimerContainer'
import IW from 'imports/iw_api';
import VerticalSlider from 'imports/ui/shared/vertical_slider/VerticalSliderContainer';
import {FlowRouter} from "core/Router";
import {setTime} from "../../../lib/helpers";

class DisclaimerMain extends Component {

  changeLanguage(language){
    Session.set('locale', language);
    setTime();
    FlowRouter.go('Disclaimer', {
      language: language
    })
  }

  render() {
    return (
      <table>
        <tbody>
          <tr>
            <td colSpan={3}>
              <div className="sweepstakes_switch-language">
                <div className="sweepstakes_switch-language_row">
                  <Button className={`button button-primary disclaimer_change-lang_button
                  ${Session.get('locale') === 'en_US' ? 'active' : null}`}
                          text_={'English version'}
                          onMouseDown={()=> this.changeLanguage('en_US')}/>
                </div>
                <div className="sweepstakes_switch-language_row">
                  <Button className={`button button-primary disclaimer_change-lang_button
                  ${Session.get('locale') === 'es_MX' ? 'active' : null}`}
                          text_={'Español versión'}
                          onMouseDown={()=> this.changeLanguage('es_MX')}/>
                </div>
              </div>

              <div className="sweepstakes-slider">
                <VerticalSlider sliderOptions={{
                  slideStartVelocityThreshold: 0,
                  snapVelocityThreshold: 0
                }} minEls={1} showScrollBar={true} items={<Disclaimer />}/>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td style={{textAlign: 'center'}}>
              <Button id="back" className="left button" text_={'Back'} onMouseDown={() => IW.gotoIndex()}/>
            </td>
          </tr>
        </tfoot>
      </table>
    )
  }
}

export default safeRender(DisclaimerMain);
