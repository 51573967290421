import {Session} from 'core/session'
import {createContainer} from 'imports/lib/react_createContainer';
import CategoriesHeader from './CategoriesHeader';
import {FlowRouter} from 'core/Router';
import {_} from 'imports/lib/helpers'
import IW from "../../iw_api";
import {gaEvent} from "imports/lib/ga";

export default createContainer((props) => {


  IW.setVoicePage('language', Session.get('locale')); //TODO need fix voice agent here

  const onSearchBack = () => {
    Session.set('search_panel', false);
    Session.set('search_string', '')
    gaEvent('Search', 'Back');
  };

  const locale = Session.get('locale');

  return {
    ...props,
    isSearchBack: Session.get('search_panel'),
    onSearchBack,
    locale,
  }
}, CategoriesHeader)
