import {createContainer} from 'imports/lib/react_createContainer';
import ListSlider from './ListSlider';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';
import {capitalize, N, C, countInDomAmounts} from 'imports/lib/helpers';
import CONFIG from 'imports/config'

export default createContainer(function () {
  const ROWS_COUNT = countInDomAmounts();
  const ITEM_COUNT = 3;

  const paymentMode = IW.appGetVal('recipient_payment_mode_type');
  const country = IW.appGetVal('recipient_address_country');
  const recipientCurrency = IW.appGetVal('recipient_currency_iso');

  let payerNameField = null;
  let paymentModeName = null;

  if (paymentMode === 'delivery') {
    paymentModeName = 'delivery';
    payerNameField = 'payer_name';
  }

  if (paymentMode === 'cash_pickup') {
    paymentModeName = 'cash';
    payerNameField = 'payer_name';
  }

  if (paymentMode === 'bank_deposit') {
    paymentModeName = 'deposit';
    payerNameField = 'bank_name';
  }

  const payers = _.map(Session.get('money_transferring_payers'), (payer) => ({
    _id: payer.network_id,
    network_id: payer.network_id,
    product_name: capitalize(payer[payerNameField] || payer.payer_name || ''),
    enabled: true,
    app_guid: 'app_moneytransfer_erTSg',
    icon: '/resources/icons/payout/' +
    paymentModeName + '/' +
    country.toLowerCase().replace(/\s/g, '_') + '/' +
    (payer[payerNameField] || payer.payer_name || '').toLowerCase().replace(/\s/g, '') + '.png',
    iconDefault: '/resources/icons/payout/' + paymentModeName + '/default.png',
    name: capitalize(payer[payerNameField] || payer.payer_name || ''),
    additional: '$1 = ' + C(payer.rate) + ' ' + recipientCurrency,
    rate: N(payer.rate)
  }))

  _.each(payers, (p) => {
    p.priority = p.rate;
    _.each(CONFIG.MT_PAYERS_PRIORITY, (v, regex) => {
      const nameReg = new RegExp(regex.toLowerCase());
      if (nameReg.test(p.product_name.toLowerCase())) p.priority = v + p.rate;
    })
  })

  payers.sort((a, b) => b.priority - a.priority);

  const choices = payers.chunk(ITEM_COUNT*ROWS_COUNT).map(function (item) {
    return item.complete(ITEM_COUNT*ROWS_COUNT, {}).chunk(ITEM_COUNT)
  });

  return {
    choices,
    title: "Select recipient's payout network:",
    onBack: () => getPageManager().back(),
    error: IW.appGetVal('list_slider_error'),
  }
}, ListSlider);
