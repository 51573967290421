import {createContainer} from 'imports/lib/react_createContainer';
import Overlay from './Overlay';
import {Meteor} from 'core/meteor';
import {Session} from 'core/session';
import * as Timer from 'imports/api/timer';
import CONFIG from 'imports/config'
import IW from 'imports/iw_api'
import {usd} from 'imports/lib/helpers'

export default createContainer(() => {
  if (!Meteor.status().connected) {
    console.log("No meteorJS backend running at the moment");
    IW.setOverlayVoicePage('overlay_connecting');
    return {
      messageText_: 'Initialization...',
      show: true,
    }
  }
  if (!Session.get('online')) {
    console.log("No connection to the main server");
    IW.setOverlayVoicePage('overlay_connecting');
    return {
      messageText_: 'Establishing connection to the server...',
      show: true,
    }
  }
  if (!Session.get('io.connected')) {
    console.log("No api backend running at the moment");
    IW.setOverlayVoicePage('overlay_connecting');
    return {
      messageText_: 'Reconnecting...',
      show: true,
    }
  }
  if (!Session.get('server.connected')) {
    console.log("The api backend does not respond");
    IW.setOverlayVoicePage('overlay_connecting');
    return {
      messageText_: 'Trying to reconnect...',
      show: true,
    }
  }

  if (Session.get('maintenance_message')) {
    console.log("Terminal is out of service");
    IW.gotoIndex();
    IW.setOverlayVoicePage('overlay_maintenance', {message: Session.get('maintenance_message')});
    return {
      messageText: Session.get('maintenance_message'),
      show: true,
    }
  }

  if(Session.get('retrieving_receipt')) {
    console.log("Retrieving receipt");
    const retrievingReceiptMessage = 'Retrieving receipt...';

    IW.setOverlayVoicePage('overlay_retrieving_receipt', {message: retrievingReceiptMessage});

    return {
      messageText: retrievingReceiptMessage,
      show: true,
    }
  }

  if(Session.get('retrieving_receipt_timeout')){
    console.log("Retrieving receipt timeout");
    const retrievingReceiptTimeoutMessage = 'Retrieving receipt error. Please call to support.';

    IW.setOverlayVoicePage('overlay_retrieving_receipt_timeout', {message: retrievingReceiptTimeoutMessage});

    return {
      messageText: retrievingReceiptTimeoutMessage,
      show: true,
      onClick: () => Session.set('retrieving_receipt_timeout', false),
      buttonText: 'OK'
    }
  }

  if (Timer.isTimeout()) {
    IW.setOverlayVoicePage('overlay_timeout');
    return {
      time: Timer.getCountdown(),
      onClick: Timer.reset,
      onClick2: () => {
        Session.set('overlay_state', '');
        Timer.reset();
        return IW.gotoIndex();
      },
      show: true,
      type: 'overlay_timeout',
    }
  }

  if (IW.appGetVal('add_cash_to_balance_timeout_show')) {
    IW.setOverlayVoicePage('overlay_timeout');
    return {
      debitVariance: IW.appGetVal('debit_variance'),
      payoutAmount: IW.appGetVal('payout_amount'),
      isTimeout: IW.appGetVal('add_cash_to_balance_timeout') <= 0,
      time: IW.appGetVal('add_cash_to_balance_timeout'),
      onClick: () => IW.appSetLocalVal('add_cash_to_balance_timeout_result', 'accept'),
      onClick2: () => IW.appSetLocalVal('add_cash_to_balance_timeout_result', 'decline'),
      show: true,
      type: 'add_cash_to_balance_timeout'
    }
  }

  if (IW.appGetVal('maintenance_qr_sent_show')) {
    return {
      messageText: 'We have sent an authorization QR  code to your mobile phone. Please Scan it to access the ' +
        'maintenance menu. If you are having issues please call Maya Support (855) 781-7277',
      show: true,
      onClick: () => IW.appSetVal('maintenance_qr_sent_show', false),
      buttonText: 'OK',
    };
  }

  IW.resetVoicePage();
  return {
    show: false,
  }
}, Overlay)
