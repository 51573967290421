import React, {Component} from 'react';
import Card from 'imports/ui/shared/card/CardContainer';
import safeRender from 'imports/ui/decorators/safeRender';

class ProfileSelect extends Component {
  constructor(props) {
    super(props);
  }

  renderAdditionalProfiles(){
    const {role} = this.props;

    if(role === 'store_clerk'){
      return <Card className="profile-select_card" iconUrl="/resources/images/atm/ic_check_balance.png" text_="Clerk"
                    direction="col" onCardClick={() => onNext('profile_clerk')}/>
    }

    return null;
  }


  render(){

    const {onNext, onSkip, firstName} = this.props;

    return (
      <table className="atm-actions">
        <tbody>
          <tr>
            <td style={{height: 350}} className="atm-actions__top">
              {firstName ? <div _={[<span className="atm-actions__top__user-name">{firstName}</span>]}>Welcome back, %s</div> : null}
              <div style={{paddingTop: 30}} _>Select necessary profile for continue</div>
            </td>
          </tr>
          <tr>
            <td className="atm-actions__down">
              <div>
                {this.renderAdditionalProfiles()}
                <Card className="profile-select_card" iconUrl="/resources/images/atm/ic_extract_cash.png" text_="Customer"
                      direction="col" onCardClick={onSkip}/>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

}

export default ProfileSelect;