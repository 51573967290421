import React, {Component} from 'react';
import Search from "./SearchContainer";
import Input from "../../shared/input/Input";
import Keyboard from "../../shared/keyboard/Keyboard";
import {KEYBOARD_TEXT} from "../../../lib/constants";

const KEYS_MAX_LENGTH = 18;

export default class SearchLayout extends Component {

  renderKeyboard() {
    const {searchString, onQuery} = this.props;

    return (
      <div className="search-keyboard_container">
        <Keyboard keys={KEYBOARD_TEXT} value={searchString} maxLength={KEYS_MAX_LENGTH}
                  onValueChange={onQuery}/>
      </div>
    )
  }

  /**
   * !IMPORTANT
   * props.pageRender - need to be pure function
   *
   */
  render() {

    const {isSearchOpened, searchString, onClear, onOpenSearch, children, errorText, gaCategory} = this.props;

    return (
      <div className="flex-container">
        <div style={{marginBottom: isSearchOpened ? 390 : 0}} className="flex-container_main">
          {isSearchOpened ?
            <Search errorText={errorText} gaCategory={gaCategory}/> :
            children
          }
        </div>
        <div className="search-block" style={{bottom: isSearchOpened ? '0' : '-270px'}}>
          <div id="search-bar">
              <span id="q-wrap" style={searchString ? {backgroundImage: 'none'} : {}} onMouseDown={onOpenSearch}>
                  <Input id="q" className="upper" value={this.props.searchString} readOnly={true}
                         placeholder_="Search"/>
                {searchString ? <span id="q-x" onMouseDown={onClear}/> : null}
              </span>
          </div>
          {this.renderKeyboard()}
        </div>
      </div>
    )
  }
}

SearchLayout.propTypes = {
  searchString: React.PropTypes.string,
  errorText: React.PropTypes.string,
  onQuery: React.PropTypes.func,
  onClear: React.PropTypes.func,
  onOpenSearch: React.PropTypes.func,
  isSearchOpened: React.PropTypes.bool
};
