import {createContainer} from 'imports/lib/react_createContainer';
import NewPinNumber from './NewPinNumber';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';
import {createLazyExecution} from 'imports/lib/helpers';
import createSessionMethods from 'imports/lib/createSessionMethods'

const MAX_LENGTH = 4;
const INPUTS = [
  {focus: 'pinNumber', name: 'pin_number_1'},
  {focus: 'pinNumberConfirm', name: 'pin_number_2'},
];

export default createContainer((params) => {
  const sessionScope = params.sessionScope;
  const SM = createSessionMethods(sessionScope);

  function getCurrentInput() {
    return _.find(INPUTS, (i) => i.focus === Session.get('focus')) || {};
  }

  function getCurrentValue() {
    return SM.getVal(getCurrentInput().name);
  }

  function getInputValue(id) {
    return SM.getVal(INPUTS[id].name);
  }

  function setCurrentValue(value) {
    if (!getCurrentInput().name) return;
    return SM.setVal(getCurrentInput().name, value);
  }

  function showNextButton() {
    return (getCurrentInput().focus === 'pinNumber' && getInputValue(0).length === 4) ||
      (getCurrentInput().focus === 'pinNumberConfirm' &&
      getInputValue(0) === getInputValue(1))
  }

  function onBack() {
    if (getCurrentInput().focus === 'pinNumber') {
      return getPageManager().back();
    }
    Session.set('focus', 'pinNumber');
  }

  function onNext() {
    if (getCurrentInput().focus === 'pinNumberConfirm') {
      return getPageManager().next();
    }
    Session.set('focus', 'pinNumberConfirm');
  }


  return {
    onBack,
    onNext,
    pinNumber: getInputValue(0),
    pinNumberConfirm: getInputValue(1),
    pinNumberCurrent: getCurrentValue(),
    showNextButton: showNextButton(),
    onClearInput: () => setCurrentValue(''),
    onValueChange: (v) => setCurrentValue(v.slice(0, MAX_LENGTH).replace(/\D/g, '')),
    focus: Session.get('focus'),
    errorMessage: SM.getVal('error_message_code'),
    headerScope: params.headerScope,
  };

}, NewPinNumber);