import React, {Component} from 'react';
import InputLabel from 'imports/ui/shared/input/InputLabel';
import ClearedInput from 'imports/ui/shared/cleared_input/ClearedInput';
import InputStateDescribe from 'imports/ui/shared/input/InputStateDescribe';
import Numpad from 'imports/ui/shared/numpad/Numpad';
import Button from 'imports/ui/shared/button/Button';

export default class LoansPhoneNumber extends Component {

  render() {
    const {
      isNumberOk, number, numberPattern, numpadLayout, onPrintNumber, goNext, goBack, goExit, canModifyPattern,
      clearInput, abovePhoneText, isUnderPhoneTextError, underPhoneText, isAppLinkWasSend, isLoading
    } = this.props;

    return (
      <table className={"phone-number app"}>
        <tbody>
        <tr>
          <td className="phone-number__input-container">
            <InputLabel text="Your mobile phone number"/>
            <br /><br />
            <ClearedInput id="number" pattern={numberPattern} className="focus" value={number} readOnly={true}
                          clearInput={clearInput} focus="number"/>

            <br />
            <InputStateDescribe
              className="phone-number__under-input"
              isError={isUnderPhoneTextError}
              text={underPhoneText}/>

          </td>
          <td className="phone-number__numpad-container">
            <Numpad keys={numpadLayout} pattern={numberPattern} value={number} onValueChange={onPrintNumber}
                    canModifyPattern={canModifyPattern} style={{margin: 0}}/>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2">
            <table>
              <tr>
                <td style={{padding: 0}}>
                  <Button id="back" className="left button" text_="Back" onMouseDown={goBack}/></td>
                <td style={{padding: 0}}>
                  {isNumberOk ?
                    <Button id="next" className="right button" onMouseDown={async() => {
                      Session.set('loading', true);
                      await goNext();
                      Session.set('loading', false);
                    }} text_="Send"/> :
                    null }
                </td>
              </tr>
            </table>
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}
