import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import {SLIDE_ITEM_TYPES} from 'imports/lib/constants';
import SliderContainer from 'imports/ui/shared/slider/SliderContainer';
import Loading from 'imports/ui/shared/loading/Loading';

export default class EnterAmount extends Component {

  render() {
    const {onBack, choices, title, error} = this.props;

    if (!(choices && choices.length)) return <Loading/>;

    return (
      <table className="list-slider-page">
        <tbody>
          <tr>
            <td className="list-slider-title">
              {!!error ?
                <span className="list-slider__error-message">{error}</span> :
                <span _>{title}</span>}
            </td>
          </tr>
          <tr>
            <td id="choices" className="list-slider-items">
              <SliderContainer type={SLIDE_ITEM_TYPES.ICON} apps={choices}/>
            </td>
          </tr>
        </tbody>
        <tfoot>
        <tr>
          <td>
            <Button id="back" className="left button" text_="Back" onMouseDown={onBack}/>
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

EnterAmount.propTypes = {
  onBack: React.PropTypes.func,
  choices: React.PropTypes.array,
  title: React.PropTypes.string,
  error: React.PropTypes.string
};