import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import {C, initVerticalSlider} from 'imports/lib/helpers';

export default class ShoppingCart extends Component {

  componentDidMount() {
    const {optionsSlider} = this.props;
    if (this.refs.slider) {
      initVerticalSlider(Object.assign(optionsSlider));
    }
  }

  componentDidUpdate(prevProps) {
    const {optionsSlider} = this.props;
    if (this.refs.slider && (this.props.shoppingCart.length !== prevProps.shoppingCart.length)) {
      initVerticalSlider(Object.assign(optionsSlider));
    }
  }

  render() {
    const {shoppingCart, requestedAmount, canGoNext, messageText, goNext, goProfile, userName, onTapItem} = this.props;
    const items = _.map(shoppingCart, (item) => {
      const key = item.id;

      return (
        <div className="vertical-slider-row" key={key} data-disabled={!item.enabled} data-limit={item.limit_reached}
             onMouseDown={(event) => onTapItem(key, item.selected, event)}
             onMouseUp={(event) => onTapItem(key, item.selected, event)}>
          <div className="vertical-slider-row__checkbox">
            <div className="checkboxDashed">
              <input id={'shopping_cart_' + key} type="checkbox" checked={!!item.selected} name="shopping"/>
              <label htmlFor={'shopping_cart_' + key}></label>
            </div>
          </div>
          <div className="vertical-slider-row__icon">
            <img src={item.icon} className="product-icon"/>
          </div>
          <div className="vertical-slider-row__name">
            {item.name}
          </div>
          <div className="vertical-slider-row__price" style={{width: 350}}>
            {C(item.original_amount, '$')}
          </div>
          <div className="vertical-slider-row__price" style={{width: 160}}>
            {C(item.requested_amount - item.original_amount, '$')}
          </div>
        </div>);
    });

    return (
      <table className="shopping-cart-page">
        <tbody>
        <tr>
          <td>
            {userName ?
              <div className="center user-greeting" _={[<span>{userName}</span>]}>Hi there, %s!</div> :
              <div className="center user-greeting">Hi there!</div>}
            <table className="shopping_table m-t-40">
              <thead>
              <tr>
                <td className="upper center">{messageText}</td>
              </tr>
              </thead>
            </table>
            <div className="scroll-area">
              <div ref="slider" className="iosVerticalSlider">
                <div className="slider">
                  {items}
                  <br/>
                </div>
              </div>
            </div>
            <table className="shopping_table">
              <tfoot>
              <tr>
                <td>
                  <span className="add_remove-text" _>Add / Remove</span>
                  <span className="total-text right" _>Total:</span>
                </td>
                <td className="totalPrice_td">
                  {C(requestedAmount, '$')}
                </td>
              </tr>
              </tfoot>
            </table>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td className="center">
            <Button id="back" className="button button-danger" onMouseDown={goProfile} text_="Pay Later"/>
            {canGoNext ?
              <Button id="next" className="button" text_="Pay Now" onMouseDown={goNext}/> :
              null}
          </td>
        </tr>
        </tfoot>
      </table>
    );
  }
}

ShoppingCart.propTypes = {
  shoppingCart: React.PropTypes.array,
  requestedAmount: React.PropTypes.number,
  canGoNext: React.PropTypes.bool,
  messageText: React.PropTypes.string,
  goNext: React.PropTypes.func,
  goProfile: React.PropTypes.func,
  onTapItem: React.PropTypes.func,
  userName: React.PropTypes.string
};
ShoppingCart.defaultProps = {
  userName: ''
};