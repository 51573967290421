import app_common from './app_common';
import createNumber from './common/number';
import IW from '../iw_api';
import createAmount from './common/amount';
import CONFIG from '../config';
import createPaymentStatus from './common/payment_status';

export default _.extend({}, app_common, {
  index: () => {
    return 'number'
  },
  number: createNumber('exit', 'redirect1', () => ({
      onCreate() {
        IW.appSetVal('required_fields', [{
          description: 'PIN Code',
          format: {length: 4, charSet: 'N'},
          name: 'PIN'
        }]);
      },
      withValidation: !IW.isTestMode(),
      withServerValidation: true
    })),
  redirect1: ({lastAction}) => {
    if (lastAction === 'back') {
      return 'back';
    }

    return IW.appGetVal('require_pin') ? 'bill_required_data' : 'bill_amount'
  },
  bill_required_data: {
    back: 'number',
    next: () => {
      const limit = IW.appGetVal('customer_limit_amount')

      if (CONFIG.EZPAYBILLS_MAX_AMOUNT < limit ) {
        IW.appSetVal('customer_limit_amount', CONFIG.EZPAYBILLS_MAX_AMOUNT)
      }

      return 'bill_amount'
    }
  },
  bill_amount: createAmount('back', 'pay'),
  payment_status: createPaymentStatus('number', 'bill_amount_again', 'pay'),
  bill_amount_again: createAmount('exit', 'pay'),
})
