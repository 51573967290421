import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import Card from 'imports/ui/shared/card/CardContainer';
import safeRender from 'imports/ui/decorators/safeRender';

class SelectItem extends Component {
  render() {
    const {items, onNext, onBack} = this.props;

    return (
      <table>
        <tbody>
        <tr>
          <td >
            <div className="atm-actions__down" style={{textAlign: 'center'}}>
              { _.map(items, (item, i) => {
                return <Card key={i} className={ "atm-application__down__card " }
                  text={item} additional={i + 1} onCardClick={() => onNext(i)}/>
              })}
            </div>
          </td>
        </tr>
        </tbody>
        <tfoot className="atm-foot">
        <tr>
          <td colSpan="2" >
            <Button id="back" className="left button" text_="Back" onMouseDown={onBack}/>
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

SelectItem.propTypes = {
  items : React.PropTypes.array,
  onBack : React.PropTypes.func,
  onNext: React.PropTypes.func,
};

SelectItem.defaultProps = {};

export default SelectItem;
