'use strict';

/* eslint-disable */
import moment from 'imports/lib/external/moment-with-langs';
import {Session} from 'core/session';

moment.fn.isHoliday = function () {
  return HOLIDAYS.indexOf(this.format('MM-DD-YYYY')) >= 0;
};

moment.fn.isBusinessDay = function () {
  var defaultWorkingWeekdays = [1, 2, 3, 4, 5];
  var workingWeekdays = defaultWorkingWeekdays;

  if (this.isHoliday()) return false;
  if (workingWeekdays.indexOf(this.day()) >= 0) return true;

  return false;
};

moment.fn.businessDaysIntoMonth = function () {
  var businessDay = this.isBusinessDay() ? this : this.prevBusinessDay();
  var monthBusinessDays = businessDay.monthBusinessDays();
  var businessDaysIntoMonth;
  monthBusinessDays.map(function (day, index) {
    if (day.format('M/DD/YY') === businessDay.format('M/DD/YY')) {
      businessDaysIntoMonth = index + 1;
    }
  })
  return businessDaysIntoMonth;
};

moment.fn.businessDiff = function (param) {
  var d1 = this.clone();
  var d2 = param.clone();
  var start = d1 < d2 ? d1 : d2;
  var end = d2 > d1 ? d2 : d1;

  var daysBetween = 0;

  if (start === end) {
    return daysBetween;
  }

  while (start < end) {
    if (start.isBusinessDay()) {
      daysBetween++;
    }
    start.add(1, 'd')
  }

  return daysBetween;
};

moment.fn.businessAdd = function (number, period) {
  var day = this.clone();
  var signal = number < 0 ? -1 : 1;
  var remaining = Math.abs(number);
  period = (typeof period !== 'undefined') ? period : 'days';

  while (remaining) {
    day.add(signal, period);

    if (day.isBusinessDay()) {
      remaining--;
    }
  }

  return day;
};

moment.fn.businessSubtract = function (number, period) {
  return this.businessAdd(-number, period);
};


moment.fn.nextBusinessDay = function () {
  var loop = 1;
  var limit = 7;
  while (loop < limit) {
    if (this.add(1, 'd').isBusinessDay()) {
      break;
    }
    ;
    loop++;
  }
  ;
  return this;
};

moment.fn.prevBusinessDay = function () {
  var loop = 1;
  var limit = 7;
  while (loop < limit) {
    if (this.subtract(1, 'd').isBusinessDay()) {
      break;
    }
    ;
    loop++;
  }
  ;
  return this;
};

moment.fn.monthBusinessDays = function (partialEndDate) {
  var me = this.clone();
  var day = me.clone().startOf('month');
  var end = partialEndDate ? partialEndDate : me.clone().endOf('month');
  var daysArr = [];
  var done = false;
  while (!done) {
    if (day.isBusinessDay()) {
      daysArr.push(day.clone());
    }
    ;
    if (end.diff(day.add(1, 'd')) < 0) {
      done = true;
    }
    ;
  }
  ;
  return daysArr;
};

moment.fn.monthNaturalDays = function (fromToday) {
  var me = this.clone();
  var day = fromToday ? me.clone() : me.clone().startOf('month');
  var end = me.clone().endOf('month');
  var daysArr = [];
  var done = false;
  while (!done) {
    daysArr.push(day.clone());
    if (end.diff(day.add(1, 'd')) < 0) {
      done = true;
    }
    ;
  }
  ;
  return daysArr;
};

moment.fn.monthBusinessWeeks = function (fromToday) {
  var me = this.clone();
  var day = fromToday ? me.clone() : me.clone().startOf('month');
  var end = me.clone().endOf('month');
  var weeksArr = [];
  var daysArr = [];
  var done = false;

  while (!done) {
    if (day.day() >= 1 && day.day() < 6) {
      daysArr.push(day.clone());
    }
    ;
    if (day.day() === 5) {
      weeksArr.push(daysArr);
      daysArr = [];
    }
    ;
    if (end.diff(day.add(1, 'd')) < 0) {
      if (daysArr.length < 5) {
        weeksArr.push(daysArr);
      }
      ;
      done = true;
    }
    ;
  }
  ;
  return weeksArr;
};

moment.fn.monthNaturalWeeks = function (fromToday) {
  var me = this.clone();
  var day = fromToday ? me.clone() : me.clone().startOf('month');
  var end = me.clone().endOf('month');
  var weeksArr = [];
  var daysArr = [];
  var done = false;

  while (!done) {
    daysArr.push(day.clone());
    if (day.day() === 6) {
      weeksArr.push(daysArr);
      daysArr = [];
    }

    if (end.diff(day.add(1, 'd')) < 0) {
      if (daysArr.length < 7) {
        weeksArr.push(daysArr);
      }

      done = true;
    }

  }

  return weeksArr;
};

const HOLIDAYS = [
    "01-01-2018",
    "01-15-2018",
    "02-14-2018",
    "02-19-2018",
    "03-17-2018",
    "03-30-2018",
    "04-01-2018",
    "05-28-2018",
    "05-13-2018",
    "06-17-2018",
    "07-04-2018",
    "09-03-2018",
    "10-08-2018",
    "10-31-2018",
    "11-11-2018",
    "11-22-2018",
    "11-23-2018",
    "12-24-2018",
    "12-25-2018",
    "12-31-2018",
    "01-01-2019",
    "01-21-2019",
    "02-14-2019",
    "02-18-2019",
    "03-17-2019",
    "04-19-2019",
    "04-21-2019",
    "05-27-2019",
    "05-12-2019",
    "06-16-2019",
    "07-04-2019",
    "09-02-2019",
    "10-14-2019",
    "10-31-2019",
    "11-11-2019",
    "11-28-2019",
    "11-22-2019",
    "12-24-2019",
    "12-25-2019",
    "12-31-2019",
    "01-01-2020",
    "01-20-2020",
    "02-14-2020",
    "02-17-2020",
    "03-17-2020",
    "04-10-2020",
    "04-12-2020",
    "05-25-2020",
    "05-10-2020",
    "06-21-2020",
    "07-04-2020",
    "09-07-2020",
    "10-12-2020",
    "10-31-2020",
    "11-11-2020",
    "11-26-2020",
    "11-27-2020",
    "12-24-2020",
    "12-25-2020",
    "12-31-2020",
    "01-01-2021",
    "01-18-2021",
    "02-14-2021",
    "02-15-2021",
    "03-17-2021",
    "04-02-2021",
    "04-04-2021",
    "05-31-2021",
    "05-09-2021",
    "06-20-2021",
    "07-04-2021",
    "09-06-2021",
    "10-11-2021",
    "10-31-2021",
    "11-11-2021",
    "11-25-2021",
    "11-26-2021",
    "12-24-2021",
    "12-25-2021",
    "12-31-2021",
    "01-01-2022",
    "01-17-2022",
    "02-14-2022",
    "02-21-2022",
    "03-17-2022",
    "04-15-2022",
    "04-17-2022",
    "05-30-2022",
    "05-08-2022",
    "06-19-2022",
    "07-04-2022",
    "09-05-2022",
    "10-10-2022",
    "10-31-2022",
    "11-11-2022",
    "11-24-2022",
    "11-25-2022",
    "12-24-2022",
    "12-25-2022",
    "12-31-2022",
    "01-01-2023",
    "01-16-2023",
    "02-14-2023",
    "02-20-2023",
    "03-17-2023",
    "04-07-2023",
    "04-09-2023",
    "05-29-2023",
    "05-14-2023",
    "06-18-2023",
    "07-04-2023",
    "09-04-2023",
    "10-09-2023",
    "10-31-2023",
    "11-11-2023",
    "11-23-2023",
    "11-24-2023",
    "12-24-2023",
    "12-25-2023",
    "12-31-2023",
    "01-01-2024",
    "01-15-2024",
    "02-14-2024",
    "02-19-2024",
    "03-17-2024",
    "03-29-2024",
    "03-31-2024",
    "05-27-2024",
    "05-12-2024",
    "06-16-2024",
    "07-04-2024",
    "09-02-2024",
    "10-14-2024",
    "10-31-2024",
    "11-11-2024",
    "11-28-2024",
    "11-22-2024",
    "12-24-2024",
    "12-25-2024",
    "12-31-2024",
    "01-01-2025",
    "01-20-2025",
    "02-14-2025",
    "02-17-2025",
    "03-17-2025",
    "04-18-2025",
    "04-20-2025",
    "05-26-2025",
    "05-11-2025",
    "06-15-2025",
    "07-04-2025",
    "09-01-2025",
    "10-13-2025",
    "10-31-2025",
    "11-11-2025",
    "11-27-2025",
    "11-28-2025",
    "12-24-2025",
    "12-25-2025",
    "12-31-2025",
    "01-01-2026",
    "01-19-2026",
    "02-14-2026",
    "02-16-2026",
    "03-17-2026",
    "04-03-2026",
    "04-05-2026",
    "05-25-2026",
    "05-10-2026",
    "06-21-2026",
    "07-04-2026",
    "09-07-2026",
    "10-12-2026",
    "10-31-2026",
    "11-11-2026",
    "11-26-2026",
    "11-27-2026",
    "12-24-2026",
    "12-25-2026",
    "12-31-2026",
    "01-01-2027",
    "01-18-2027",
    "02-14-2027",
    "02-15-2027",
    "03-17-2027",
    "03-26-2027",
    "03-28-2027",
    "05-31-2027",
    "05-09-2027",
    "06-20-2027",
    "07-04-2027",
    "09-06-2027",
    "10-11-2027",
    "10-31-2027",
    "11-11-2027",
    "11-25-2027",
    "11-26-2027",
    "12-24-2027",
    "12-25-2027",
    "12-31-2027",
    "01-01-2028",
    "01-17-2028",
    "02-14-2028",
    "02-21-2028",
    "03-17-2028",
    "04-14-2028",
    "04-16-2028",
    "05-29-2028",
    "05-14-2028",
    "06-18-2028",
    "07-04-2028",
    "09-04-2028",
    "10-09-2028",
    "10-31-2028",
    "11-11-2028",
    "11-23-2028",
    "11-24-2028",
    "12-24-2028",
    "12-25-2028",
    "12-31-2028",
    "01-01-2029",
    "01-15-2029",
    "02-14-2029",
    "02-19-2029",
    "03-17-2029",
    "03-30-2029",
    "04-01-2029",
    "05-28-2029",
    "05-13-2029",
    "06-17-2029",
    "07-04-2029",
    "09-03-2029",
    "10-08-2029",
    "10-31-2029",
    "11-11-2029",
    "11-22-2029",
    "11-23-2029",
    "12-24-2029",
    "12-25-2029",
    "12-31-2029",
    "01-01-2030",
    "01-21-2030",
    "02-14-2030",
    "02-18-2030",
    "03-17-2030",
    "04-19-2030",
    "04-21-2030",
    "05-27-2030",
    "05-12-2030",
    "06-16-2030",
    "07-04-2030",
    "09-02-2030",
    "10-14-2030",
    "10-31-2030",
    "11-11-2030",
    "11-28-2030",
    "11-22-2030",
    "12-24-2030",
    "12-25-2030",
    "12-31-2030",
    "01-01-2031",
    "01-20-2031",
    "02-14-2031",
    "02-17-2031",
    "03-17-2031",
    "04-11-2031",
    "04-13-2031",
    "05-26-2031",
    "05-11-2031",
    "06-15-2031",
    "07-04-2031",
    "09-01-2031",
    "10-13-2031",
    "10-31-2031",
    "11-11-2031",
    "11-27-2031",
    "11-28-2031",
    "12-24-2031",
    "12-25-2031",
    "12-31-2031",
    "01-01-2032",
    "01-19-2032",
    "02-14-2032",
    "02-16-2032",
    "03-17-2032",
    "03-26-2032",
    "03-28-2032",
    "05-31-2032",
    "05-09-2032",
    "06-20-2032",
    "07-04-2032",
    "09-06-2032",
    "10-11-2032",
    "10-31-2032",
    "11-11-2032",
    "11-25-2032",
    "11-26-2032",
    "12-24-2032",
    "12-25-2032",
    "12-31-2032",
    "01-01-2033",
    "01-17-2033",
    "02-14-2033",
    "02-21-2033",
    "03-17-2033",
    "04-15-2033",
    "04-17-2033",
    "05-30-2033",
    "05-08-2033",
    "06-19-2033",
    "07-04-2033",
    "09-05-2033",
    "10-10-2033",
    "10-31-2033",
    "11-11-2033",
    "11-24-2033",
    "11-25-2033",
    "12-24-2033",
    "12-25-2033",
    "12-31-2033",
    "01-01-2034",
    "01-16-2034",
    "02-14-2034",
    "02-20-2034",
    "03-17-2034",
    "04-07-2034",
    "04-09-2034",
    "05-29-2034",
    "05-14-2034",
    "06-18-2034",
    "07-04-2034",
    "09-04-2034",
    "10-09-2034",
    "10-31-2034",
    "11-11-2034",
    "11-23-2034",
    "11-24-2034",
    "12-24-2034",
    "12-25-2034",
    "12-31-2034",
    "01-01-2035",
    "01-15-2035",
    "02-14-2035",
    "02-19-2035",
    "03-17-2035",
    "03-23-2035",
    "03-25-2035",
    "05-28-2035",
    "05-13-2035",
    "06-17-2035",
    "07-04-2035",
    "09-03-2035",
    "10-08-2035",
    "10-31-2035",
    "11-11-2035",
    "11-22-2035",
    "11-23-2035",
    "12-24-2035",
    "12-25-2035",
    "12-31-2035",
    "01-01-2036",
    "01-21-2036",
    "02-14-2036",
    "02-18-2036",
    "03-17-2036",
    "04-11-2036",
    "04-13-2036",
    "05-26-2036",
    "05-11-2036",
    "06-15-2036",
    "07-04-2036",
    "09-01-2036",
    "10-13-2036",
    "10-31-2036",
    "11-11-2036",
    "11-27-2036",
    "11-28-2036",
    "12-24-2036",
    "12-25-2036",
    "12-31-2036",
    "01-01-2037",
    "01-19-2037",
    "02-14-2037",
    "02-16-2037",
    "03-17-2037",
    "04-03-2037",
    "04-05-2037",
    "05-25-2037",
    "05-10-2037",
    "06-21-2037",
    "07-04-2037",
    "09-07-2037",
    "10-12-2037",
    "10-31-2037",
    "11-11-2037",
    "11-26-2037",
    "11-27-2037",
    "12-24-2037",
    "12-25-2037",
    "12-31-2037",
    "01-01-2038",
    "01-18-2038",
    "02-14-2038",
    "02-15-2038",
    "03-17-2038",
    "04-23-2038",
    "04-25-2038",
    "05-31-2038",
    "05-09-2038",
    "06-20-2038",
    "07-04-2038",
    "09-06-2038",
    "10-11-2038",
    "10-31-2038",
    "11-11-2038",
    "11-25-2038",
    "11-26-2038",
    "12-24-2038",
    "12-25-2038",
    "12-31-2038",
    "01-01-2039",
    "01-17-2039",
    "02-14-2039",
    "02-21-2039",
    "03-17-2039",
    "04-08-2039",
    "04-10-2039",
    "05-30-2039",
    "05-08-2039",
    "06-19-2039",
    "07-04-2039",
    "09-05-2039",
    "10-10-2039",
    "10-31-2039",
    "11-11-2039",
    "11-24-2039",
    "11-25-2039",
    "12-24-2039",
    "12-25-2039",
    "12-31-2039",
    "01-01-2040",
    "01-16-2040",
    "02-14-2040",
    "02-20-2040",
    "03-17-2040",
    "03-30-2040",
    "04-01-2040",
    "05-28-2040",
    "05-13-2040",
    "06-17-2040",
    "07-04-2040",
    "09-03-2040",
    "10-08-2040",
    "10-31-2040",
    "11-11-2040",
    "11-22-2040",
    "11-23-2040",
    "12-24-2040",
    "12-25-2040",
    "12-31-2040",
    "01-01-2041",
    "01-21-2041",
    "02-14-2041",
    "02-18-2041",
    "03-17-2041",
    "04-19-2041",
    "04-21-2041",
    "05-27-2041",
    "05-12-2041",
    "06-16-2041",
    "07-04-2041",
    "09-02-2041",
    "10-14-2041",
    "10-31-2041",
    "11-11-2041",
    "11-28-2041",
    "11-22-2041",
    "12-24-2041",
    "12-25-2041",
    "12-31-2041",
    "01-01-2042",
    "01-20-2042",
    "02-14-2042",
    "02-17-2042",
    "03-17-2042",
    "04-04-2042",
    "04-06-2042",
    "05-26-2042",
    "05-11-2042",
    "06-15-2042",
    "07-04-2042",
    "09-01-2042",
    "10-13-2042",
    "10-31-2042",
    "11-11-2042",
    "11-27-2042",
    "11-28-2042",
    "12-24-2042",
    "12-25-2042",
    "12-31-2042",
    "01-01-2043",
    "01-19-2043",
    "02-14-2043",
    "02-16-2043",
    "03-17-2043",
    "03-27-2043",
    "03-29-2043",
    "05-25-2043",
    "05-10-2043",
    "06-21-2043",
    "07-04-2043",
    "09-07-2043",
    "10-12-2043",
    "10-31-2043",
    "11-11-2043",
    "11-26-2043",
    "11-27-2043",
    "12-24-2043",
    "12-25-2043",
    "12-31-2043",
    "01-01-2044",
    "01-18-2044",
    "02-14-2044",
    "02-15-2044",
    "03-17-2044",
    "04-15-2044",
    "04-17-2044",
    "05-30-2044",
    "05-08-2044",
    "06-19-2044",
    "07-04-2044",
    "09-05-2044",
    "10-10-2044",
    "10-31-2044",
    "11-11-2044",
    "11-24-2044",
    "11-25-2044",
    "12-24-2044",
    "12-25-2044",
    "12-31-2044",
    "01-01-2045",
    "01-16-2045",
    "02-14-2045",
    "02-20-2045",
    "03-17-2045",
    "04-07-2045",
    "04-09-2045",
    "05-29-2045",
    "05-14-2045",
    "06-18-2045",
    "07-04-2045",
    "09-04-2045",
    "10-09-2045",
    "10-31-2045",
    "11-11-2045",
    "11-23-2045",
    "11-24-2045",
    "12-24-2045",
    "12-25-2045",
    "12-31-2045",
    "01-01-2046",
    "01-15-2046",
    "02-14-2046",
    "02-19-2046",
    "03-17-2046",
    "03-23-2046",
    "03-25-2046",
    "05-28-2046",
    "05-13-2046",
    "06-17-2046",
    "07-04-2046",
    "09-03-2046",
    "10-08-2046",
    "10-31-2046",
    "11-11-2046",
    "11-22-2046",
    "11-23-2046",
    "12-24-2046",
    "12-25-2046",
    "12-31-2046",
    "01-01-2047",
    "01-21-2047",
    "02-14-2047",
    "02-18-2047",
    "03-17-2047",
    "04-12-2047",
    "04-14-2047",
    "05-27-2047",
    "05-12-2047",
    "06-16-2047",
    "07-04-2047",
    "09-02-2047",
    "10-14-2047",
    "10-31-2047",
    "11-11-2047",
    "11-28-2047",
    "11-22-2047",
    "12-24-2047",
    "12-25-2047",
    "12-31-2047",
    "01-01-2048",
    "01-20-2048",
    "02-14-2048",
    "02-17-2048",
    "03-17-2048",
    "04-03-2048",
    "04-05-2048",
    "05-25-2048",
    "05-10-2048",
    "06-21-2048",
    "07-04-2048",
    "09-07-2048",
    "10-12-2048",
    "10-31-2048",
    "11-11-2048",
    "11-26-2048",
    "11-27-2048",
    "12-24-2048",
    "12-25-2048",
    "12-31-2048"
  ];

export function getMoment(){
  const currentLocale = Session.get('locale');
  moment.lang(currentLocale);
  setTimeout(() => moment.lang('en_US'));
  return moment;
}
