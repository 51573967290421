import {createContainer} from 'imports/lib/react_createContainer';
import Slider from './Slider';

export default createContainer((params) => {

  return {
    apps: params.apps,
    type: params.type,
    pages: params.pages,
    height: params.height,
    marginBottom:  params.marginBottom,
    itemHeight: params.itemHeight,

    gaCategory: params.gaCategory,
  }

}, Slider)
