import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import Sent from './Sent';
import IW from 'imports/iw_api';
import {SENT_PAGE_TEXTS} from 'imports/lib/constants';
import getPageManager from 'imports/lib/page_manager';

export default createContainer((params) => {
  const data = SENT_PAGE_TEXTS[params.type + '_' + IW.appGetVal('service_name')] || SENT_PAGE_TEXTS[params.type]
  let value = null;
  if (data.type === 'email') value = IW.appGetVal('email');
  if (data.type === 'support') value = IW.getUISettings().support_number;

  function onNext() {
    getPageManager().next();
  }

  return {
    ...data,
    value,
    onNext
  };

}, Sent);
