import {createContainer} from 'imports/lib/react_createContainer';
import CONFIG from "imports/config";
import Index from "./IndexHeader";

export default createContainer(({params}) => {
  const locale = Session.get('locale') || CONFIG.DEFAULT_LANGUAGE;
  return {
    locale,
  };

}, Index);
