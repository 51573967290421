import {Session} from 'core/session'
import IW from 'imports/iw_api'
import {nextWhen} from 'imports/lib/page_manager'

/**
 *
 * @param pageEdit
 * @param pageAgain
 * @param pagePay
 * @return {Function}
 */
export default function (pageEdit, pageAgain, pagePay) {
  return function*({lastAction}) {
    if (Session.get('payment_status_app')){
      Session.set('app', Session.get('payment_status_app'));
    }

    IW.appSetObject({
      logged: false,
      current_account: null,
      customer: null,
      login_disabled: true
    });

    if (IW.appGetVal('payment_status_pay_again')) {
      IW.appSetLocalVal('payment_status_pay_again', false);
      if (lastAction !== 'back') {
        Session.set('payment_status_app', Session.get('app'));
        if (typeof pageAgain === 'function') pageAgain = pageAgain();
        IW.launchPreviousSessions();
        Session.set('last_amount', 0);
        IW.appSetVal('print_disclosure', false);
        return pageAgain;
      }
    }

    if (!IW.appGetVal('payment_status')) {
      return pagePay;
    }

    Session.set('payment_status_app', null);
    let restored = false;
    IW.call('onBeforePaymentRestore', null, () => (restored = true));
    yield nextWhen(() => restored);
    yield 'loading';

    return {
      cancel: 'payment_status_cancel',
      edit: pageEdit,
      support: 'support_number',
      payAgain: function () {
        IW.appSetLocalVal('payment_status_pay_again', true);
        // Fixme: This handler depends on stored_sessions page
        IW.appSetLocalVal('customer_next_page', 'payment_status');
        return 'redirect_customer'
      },
      refund: function () {
        IW.launchRefund({
          refund_payment_guid: IW.appGetVal('payment_guid'),
          customer_id: IW.appGetVal('customer_id'),
          start_page: 'refund_payment_status',
        });
        return 'loading';
      }
    }
  }
}
