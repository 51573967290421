import React from 'react';
import VideoOverlay from '../../shared/video_overlay/VideoOverlay';

export default class Maya5Congratulation extends React.Component {

  constructor () {
    super()
    this.state = {
      display: true,
    }
  }

  onVideoFinish () {
    this.setState({ display: false });
  }


  render() {
    const {videoUrl, styles, onFinish} = this.props;

    return (
      <div>
        <VideoOverlay src={videoUrl} styles={styles}
                      onFinish={onFinish}
                      onClick={onFinish}
        />
      </div>
    )
  }

}
