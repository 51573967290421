import QWebChannel from 'imports/lib/external/qwebchannel';

let maya = null;
const transport = (window.qt && window.qt.webChannelTransport) || null;
if (!window.IWBrowserAPI && _.isObject(transport)) {
  const c = new QWebChannel(transport, (channel) => {
    if (!_.isObject(channel) || !_.isObject(channel.objects) || !_.isObject(channel.objects.maya)) return;
    maya = channel.objects.maya;
    if (!_.isObject(maya)) return;
    window.addEventListener('message', (message) => {
      if (!_.has(message.data, 'GXS64Sy5zW1Fl4IQ9DA1zUli5M')) return;
      if (!message.data.call) return;
      if (!_.has(maya, message.data.call)) return window.postMessage({GXS64Sy5zW1Fl4IQ9DA1zUli5M: true, result: true, args: [], id: message.data.id}, '*')
      const args = message.data.args;
      args.push((...args2) => {
        window.postMessage({GXS64Sy5zW1Fl4IQ9DA1zUli5M: true, result: true, args: args2, id: message.data.id}, '*')
      });
      maya[message.data.call](...args);
    });
  });
}

export default window.IWBrowserAPI || {
  pageKeepAliveSetTimeout: wrapSafe('pageKeepAliveSetTimeout'),
  pageKeepAlive: wrapSafe('pageKeepAlive'),
  saveScreenshot: wrapSafe('saveScreenshot'),
}

function wrapSafe(func) {
  return (...args) => {
    const callback = typeof args[args.length - 1] === 'function' ?
      args.pop() :
      () => {
      };
    const id = Date.now() + Math.random();
    const handler = (message) => {
      if (!_.has(message.data, 'GXS64Sy5zW1Fl4IQ9DA1zUli5M')) return;
      if (!message.data.result) return;
      if (message.data.id !== id) return;
      window.removeEventListener('message', handler);
      callback(...message.data.args);
    }
    window.parent.postMessage({GXS64Sy5zW1Fl4IQ9DA1zUli5M: true, call: func, args: args, id: id}, '*')
    window.addEventListener('message', handler);
    setTimeout(() => window.removeEventListener('message', handler), 5000);
  }
}