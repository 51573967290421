/**
 * Created by vitaliy on 09.03.17.
 */
import React, {Component} from 'react';
import {FlowRouter} from 'core/Router';
import TransferDescription from 'imports/ui/shared/transfer_description/TransferDescriptionSessionContainer';
import Button from 'imports/ui/shared/button/Button';
import Loading from 'imports/ui/shared/loading/Loading';

export default class Payout extends Component {


  render() {
    const {onNext, onBack, paymentModes, selectLabelHeader, selectLabel, disableTransferDescription} = this.props;

    if(!paymentModes.length) return <Loading />;

    const res = (
        <table className="payout-page">
          <tbody>
          {disableTransferDescription ? null : (
          <tr className="payout__description">
            <td colSpan="2">
              <TransferDescription { ...this.props }/>
            </td>
          </tr>
          )}
          <tr className="payout__status">
            <td>
              {selectLabelHeader && <span>{selectLabelHeader}<br/></span>}
              <span>{selectLabel}</span>
            </td>
          </tr>
          <tr className="payout__buttons">
            <td>
              <table className="buttons-table">
                <tbody>
                {paymentModes.map((row, i) => {
                  return (
                      <tr key={i}>
                        <td>
                          <Button className="button button-primary" text_={row.name} onMouseDown={() => onNext(row.id, row.type) }
                                  icon={<img className="button-icon" src={`/resources/images/money_transferring/ic_${row.type}.png`} />}/>
                        </td>
                      </tr>
                  )
                })}
                </tbody>
              </table>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <td colSpan="2">
              <Button id="back" onMouseDown={onBack} className="left button" text_="Back"/>
            </td>
          </tr>
          </tfoot>
        </table>
    );
    return res;
  }
}

Payout.propTypes = {
  paymentModes: React.PropTypes.array,
  onNext: React.PropTypes.func,
  onBack: React.PropTypes.func,
};
