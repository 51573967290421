import createNumber from './common/number';
import createAmount from './common/amount';
import app_common from './app_common';

// ["gifts", "amount", "number", "emailInner", "pay", "loading", "final", "email", "sent"]
export default _.extend({}, app_common, {
  gifts: {},
  number: createNumber('exit', 'email_inner'),
  email_inner: {
    back: 'number',
    next: 'amount',
  },
  amount: createAmount('email_inner', 'pay'),
});


