/**
 * Created by vitaliy on 13.03.17.
 */
import {createContainer} from 'imports/lib/react_createContainer';
import EnterUserDataContainer from './EnterUserDataContainer';
import {KEYBOARDS, TRANSFER_INPUTS_RESULT} from 'imports/lib/constants';
import moment from 'imports/lib/external/moment-with-langs'
import {Session} from "core/session";

export default createContainer((params) => {

  const fields = [
    {
      name: 'id_number',
      describe: 'ID Number',
      describe_output: 'ID Number',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT_NUMBER,
      upper_case: true,
      validation: {
        required: true,
        minLength: 6,
        maxLength: 11,
        pattern: /^[A-Z0-9]+$/
      },
      maxLength: 11
    },
    {
      name: 'id_issuer',
      describe: 'Issuer State',
      describe_output: 'Issuer',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.TEXT,
      upper_case: true,
      validation: {
        required: true,
        minLength: 2,
        maxLength: 3
      },
      maxLength: 3
    },
    {
      name: 'id_expiration_date',
      describe: 'Expiration Date',
      describe_output: 'Expiration Date',
      type_inputs_result: TRANSFER_INPUTS_RESULT.SIMPLE_LIST,
      type_keyboard: KEYBOARDS.DATEPICKER,
      default_value: +moment(),
      disable_clear: true,
      validation: {
        required: false
      }
    }];

  return {
    fields,
    prefix: 's_customer'
  }
}, EnterUserDataContainer);