import IW from 'imports/iw_api';
import {N} from 'imports/lib/helpers';
import CONFIG from 'imports/config';

export default function (pageBack, pageNext, pageLoading, pageError = 'payment_status', pageAmountClerk) {
  return function* ({lastStep}) {
    console.error('This page is deprecated');
    return 'loading';
    IW.appSetLocalVal('customer_update_discount_done', false);
    IW.appSetVal('customer_maya5_enabled', CONFIG.MAYA5_ENABLED);
    IW.appSetVal('customer_referrals_enabled', CONFIG.REFERRALS_ENABLED);
    IW.appSetVal('customer_first_payment_enabled', CONFIG.FIRST_PAYMENT_ENABLED);
    IW.appSetVal('customer_welcome_back_enabled', CONFIG.WELCOME_BACK_ENABLED);
    IW.appSetVal('customer_free_transaction_enabled', CONFIG.FREE_TRANSACTION_ENABLED);

    IW.call('updateCustomerBalance');
    IW.updateCustomerDiscount(() => IW.appSetLocalVal('customer_update_discount_done', true));
    yield 'loading app customer_update_discount_done';

    IW.appSetVal('payment_guid', '');
    IW.call('initPayment');
    yield 'loading app payment_guid';

    yield* createPaymentQR();

    IW.appSetLocalVal('paid', false);

    if (!IW.appGetVal('congratulation_showed') && (
      IW.appGetVal('discount_type') === 'first_payment' ||
      IW.appGetVal('discount_type') === 'welcome_back' ||
      IW.appGetVal('discount_type') === 'maya5') &&
      IW.appGetVal('discount_amount') < 0 &&
      IW.appGetVal('amount') <= 0) {
      IW.appSetLocalVal('congratulation_showed', true);
      yield 'sleep 4500 maya5_congratulation';
    }

    function* createPaymentQR() {
      IW.appSetVal('payment_qr_token_url', '');
      IW.call('createPaymentQR');

      yield 'loading app payment_qr_token_url';
    }

    if (!IW.appGetVal('payment_last_step')) {
      IW.appSetLocalVal('payment_last_step', lastStep);
    }
    return {
      back: function () {
        const paymentBack = IW.appGetVal('payment_last_step') || pageBack;
        IW.appSetVal('payment_guid', '');
        IW.appSetLocalVal('payment_last_step', '')
        return paymentBack;
      },

      tryPay: function* () {
        const totalAmountInserted = N(IW.appGetVal('amount'));
        const amountToLoad = N(IW.appGetVal('requested_amount'));

        if (IW.appGetVal('virtual_cash_inserted')) {
          console.log('Payment updating');
          IW.call('updatePayment');
          IW.appSetLocalVal('virtual_cash_inserted', undefined);
        }

        if (IW.appGetVal('paid') || totalAmountInserted < amountToLoad || amountToLoad === 0) {
          return 'none'
        }

        IW.appSetLocalVal('paid', true);

        yield 'sleep 333';
        IW.appSetVal('session_stored', false);
        IW.paymentDo();

        if (pageLoading) return pageLoading;

        IW.appSetLocalVal('payment_status', '');
        yield 'loading app payment_status';

        return IW.appGetVal('payment_status') === 'paid' ?
          pageNext :
          pageError
      },

      referral: () => 'referral_code',
      maya5: () => 'maya5_description',
      generateQR: function* () {
        yield* createPaymentQR();
        return 'none'
      },
      clerkAmount: () => pageAmountClerk
    }
  }
}
