import {createContainer} from 'imports/lib/react_createContainer';
import ConfirmEnteredData from './ConfirmEnteredData';
import IW from 'imports/iw_api';
import getPageManager from 'imports/lib/page_manager';
import {complete} from 'imports/lib/array_extension';
import moment from 'imports/lib/external/moment-with-langs';
import _ from 'lodash';

export default createContainer((params) => {

  const fullName = _.compact([IW.appGetVal('s_customer_first_name'), IW.appGetVal('s_customer_middle_name'), IW.appGetVal('s_customer_last_name')]).join(' ');
  const fullAddress = [_.compact([IW.appGetVal('s_customer_address1'), IW.appGetVal('s_customer_address2')]).join(' '),
    _.compact([IW.appGetVal('s_customer_address_city'), _.compact([IW.appGetVal('s_customer_address_state'), IW.appGetVal('s_customer_address_zip')]).join(' ')]).join(', ')
  ]

  return {
    userType: 'customer',
    items: [
      {describe_output: 'Name', data: fullName},
      {describe_output: 'Cell Phone', data: IW.appGetVal('s_customer_cell_phone')},
      {describe_output: 'Last SSN digits', data: IW.appGetVal('s_customer_ssn').slice(-4)},
      {
        describe_output: 'Date of Birth', data: IW.appGetVal('s_customer_dob') ?
          moment(IW.appGetVal('s_customer_dob')).format('MM/DD/YYYY') :
          null
      },
      {describe_output: 'Address', data: fullAddress},
      {
        describe_output: 'ID', data: IW.appGetVal('s_customer_id_number') ?
          [IW.appGetVal('s_customer_id_type') + ' ' + IW.appGetVal('s_customer_id_number'),
            IW.appGetVal('s_customer_id_issuer'),
            moment(IW.appGetVal('s_customer_id_expiration_date')).format('MM/DD/YYYY')
          ] :
          null
      },
    ],
    onBack: () => getPageManager().back(),
    onNext: () => getPageManager().next(),
    onEdit: () => getPageManager().edit()
  };
}, ConfirmEnteredData);
