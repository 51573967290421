import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import {Session} from 'core/session';
import Categories from './Categories';
import Links from 'imports/collections/links';
import Products from 'imports/collections/products';
import {countInDomMenu} from 'imports/lib/helpers';
import CONFIG from 'imports/config';
import Apps from 'imports/collections/apps';

export default createContainer(({gaCategory}) => {
  const ROWS_COUNT = countInDomMenu();
  const ITEM_COUNT = 5;

  function getCats() {
    const result = [];
    const categories = [];

    const categoriesTree = Session.get('categories_tree');
    if (!categoriesTree) return result;

    const availableCategories = [];

    Products.find({hidden: false}).fetch().forEach((p) => {
      _.each(p.path, (c) => availableCategories.push(c));
    });

    _.each(categoriesTree.categories, (c, i) => {
      if (_.contains(availableCategories, c.id)) {
        result.push({
          id: c.id,
          nm: c.name,
          index: i,
          selection_priority: c.selection_priority,
          apps: getApps(c.id),
          icon: c.icon,
          type: 'category',
          priority: (c.priority * 1000) + 1000
        })
      }
    });

    Links.find({product_id: {$in: categoriesTree.products}, enabled: true, hidden: false}).fetch().forEach((product, i) => {
      const currentApp = Apps.findOne({app_guid: product.app_guid});
      result.push({
        app_guid: product.app_guid,
        product_id: product.product_id,
        index: i,
        selection_priority: 0,
        type: 'application',
        ...currentApp,
        icon: product.icon,
        nm: product.name,
        name: product.name,
        title: product.title,
        start_page: product.start_page,
        priority: categoriesTree.priorities[product.product_id] || product.priority,
      })
    });

    if (CONFIG.ATM_ENABLED && CONFIG.ATM_ENABLED_IN_CATEGORY) {
      const startPage = 'insert_card';
      const appGuid = 'app_atm_dGer';

      result.push({
        app_guid: appGuid,
        name: 'GET CASH',
        title: 'ATM',
        start_page: startPage,
        feed: 0,
        icon: '/resources/icons/redesigned/ic_atm_red.svg',
        product_id: 0, // product_id
        page: startPage,
        type: 'application',
        priority: -1
      });
    }

    result.sort((a, b) => b.priority - a.priority);

    _.each(result.chunk(ITEM_COUNT * ROWS_COUNT), (cat) => {
      categories.push(cat.chunk(ITEM_COUNT));
    });

    return categories;
  }

  function getApps(category_id) {
    if (!category_id) {
      return [];
    }

    const links = [];
    const categoriesTree = Session.get('categories_tree');
    if (!categoriesTree) return links;

    const currentCategory = _.find(categoriesTree.categories, (c) => c.id === category_id);
    if (!currentCategory) return links;

    _.each(currentCategory.categories, (c) => {
      links.push({
        icon: c.icon,
        app_guid: 'category',
        name: c.name,
        title: c.name,
        categoryLevel: 1,
        id: c.id,
      })
    });

    Links.find({product_id: {$in: currentCategory.products}, hidden: false}, {sort: {priority: -1, name: 1}})
      .fetch()
      .forEach((l) => links.push(l));

    return links.complete(8, {});
  }

  return {
    categories: getCats(),
    gaCategory
  };

}, Categories);
