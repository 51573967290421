import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';

export default class ErrorMoneyTransfer extends Component {
  render() {
    const {errorMessages, onMenu,
      onBack, onNext, onExit, supportNumber, errorTitle} = this.props;
    const messages = [...errorMessages, `For support call ${supportNumber}`];
    return (
      <table>
        <tbody>
        <tr>
          <td style={{padding: "40px", textAlign: "center", verticalAlign: "middle"}}>
            <h1 _>{errorTitle}</h1>
            {_.map(messages, (m, k) => (<h3>{m}</h3>))}
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td style={{textAlign: 'center'}}>
            { onBack ?
              <Button id="back" className="left button" text_="Back" onMouseDown={onBack}/> :
              null }
            { onMenu ?
              <Button id="next" className="right button goto-index" onMouseDown={onMenu} text_="Main Menu"/> :
              null }
            { onExit ?
              <Button id="next" className="right button button-danger" onMouseDown={onExit} text_="Exit"/> :
              null }
            { onNext ?
              <Button id="next" className="right button" onMouseDown={onNext} text_="Next"/> :
              null }
          </td>
        </tr>
        </tfoot>
      </table>
    );
  }
}

ErrorMoneyTransfer.propTypes = {
  errorTitle: React.PropTypes.string,
  errorMessages: React.PropTypes.arrayOf(React.PropTypes.string),
  supportNumber: React.PropTypes.string,
  onBack: React.PropTypes.func,
  onNext: React.PropTypes.func,
  onExit: React.PropTypes.func,
  onMenu: React.PropTypes.func,
};

ErrorMoneyTransfer.defaultProps = {
  errorMessages: [],
};
