import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import Card from 'imports/ui/shared/card/CardContainer';
import safeRender from 'imports/ui/decorators/safeRender';

class TakeActions extends Component {
  render() {

    const {fullName, canExtract, canTransfer, isEMV, onNext, onCancel, onTransfer} = this.props;

    let welcomeElement = <div _>Welcome back</div>
    if (fullName){
      welcomeElement = <div _={[<span className="atm-actions__top__user-name">{fullName}</span>]}>Welcome back, %s</div>
    }

    return (
      <table className="atm-actions">
        <tbody>
        <tr>
          <td className="atm-actions__top">
            {welcomeElement}
          </td>
        </tr>
        <tr>
          <td className="atm-actions__down">
            <div >
              <Card className="atm-actions__down__card" iconUrl="/resources/images/atm/ic_check_balance.png" text_="Check balance"
                    direction="col" additional="1" onCardClick={() => onNext('balance')}/>
              {canExtract ?
                <Card className="atm-actions__down__card" iconUrl="/resources/images/atm/ic_extract_cash.png" text_="Get cash"
                    direction="col" additional="2" onCardClick={() => onNext('withdraw')}/> :
                null}
              {canTransfer ?
                <Card className="atm-actions__down__card" iconUrl="/resources/images/atm/ic_extract_cash.png" text_="Transfer cash"
                      direction="col" additional="3" onCardClick={() => onNext('transfer')}/> :
               null}
            </div>
          </td>
        </tr>
        </tbody>
        <tfoot style={{ backgroundColor : '#1A2343' }}>
        <tr>
          <td colSpan="2" >
            <Button id="cancel" className="left button button-danger" text_="Cancel" onMouseDown={onCancel}/>
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

TakeActions.propTypes = {
  fullName : React.PropTypes.string,
  canExtract: React.PropTypes.bool,
  canTransfer: React.PropTypes.bool,
  onNext : React.PropTypes.func,
  onCancel : React.PropTypes.func,
  onTransfer: React.PropTypes.func
};

TakeActions.defaultProps = {
  fullName : '',
  canExtract: false,
  canTransfer: true
};

export default TakeActions;
