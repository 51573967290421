/**
 * Created by vitaliy on 13.03.17.
 */
import React, {Component} from 'react';

export default class UserTransferDetails extends Component {
  render() {
    const {number, country, user, email, error_message, userType, currentActiveField} = this.props;
    if (!user) return null;
    if(error_message) return (<span className="transfer-error_message">{error_message}</span>);

    let message = 'Enter %s details in %s:';

    if (userType === 'recipient' &&
      (currentActiveField.name === 'first_name' || currentActiveField.name === 'last_name')
      && currentActiveField.validation &&
      currentActiveField.validation.required) {
      message = 'Be sure to enter Recipient’s information exactly as it appears on the ID';
      // const args = [currentActiveField.describe_output];

      return (<span _>{message}</span>);
    }

    if (userType === 'required' && currentActiveField.name === 'PIN' &&
      (Session.get('app').current_account && Session.get('app').biller_id === 8835)) {
      message = 'Enter the 4 digit PIN associated to your Boost Mobile account.\n ' +
        '(If you do not know your 4 digit PIN, please contact Boost Mobile)';

      return (<span style={{whiteSpace: 'pre-wrap'}} _>{message}</span>)
    }

    const args = [
      <span className="transfer-description__accented">{user}</span>,
      <span className="transfer-description__country" _>{country}</span>,
    ]

    if (number || email) {
      message = 'Enter %s details (for %s) in %s:'
      args.splice(1, 0, <span className="transfer-description__number">{number ? number : email}</span>)
    }

    return (<span _={args}>{message}</span>);
  }
}

UserTransferDetails.propTypes = {
  number: React.PropTypes.string,
  email: React.PropTypes.string,
  country: React.PropTypes.string,
  user: React.PropTypes.string,
  error_message: React.PropTypes.string
};

UserTransferDetails.defaultProps = {
  number: '',
  email: '',
  country: '',
  user: '',
  error_message: ''
};
