import {createContainer} from 'imports/lib/react_createContainer';
import EnterAmount from './EnterAmount';
import getPageManager from 'imports/lib/page_manager';
import IW from 'imports/iw_api';
import {N} from 'imports/lib/helpers'


export default createContainer((params) => {
  const currencySymbol = '$';
  const amount = N(IW.appGetVal('original_amount')) || 0;
  const maxAmount = 120000;

  const numpadLayout = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9],
    ['clear', 0, 'delete']
  ];

  function updateLocalAmounts() {
    const paid = N(IW.appGetVal('original_amount'));
    const rate = N(IW.appGetVal('rate'));
    const purchaseAmount = N(paid / rate);
    IW.appSetLocalVal('total_purchase', purchaseAmount);
  }

  function onPrintNumber(key) {
    if ( N(key) <= maxAmount) {
      IW.appSetVal('original_amount', N(key));
      updateLocalAmounts();
    }
  }

  function clearInput() {
    // console.log('clear');
    IW.appSetVal('original_amount', 0);
    updateLocalAmounts();
  }

  return {
    currency: currencySymbol,
    amount: amount,
    numpadLayout,
    maxAmount: 3000,
    confirm : 1 <= amount && amount <= 3000,
    onPrintNumber,
    clearInput,
    onNext: () => getPageManager().next(),
    onBack: () => getPageManager().back(),
    title: 'Amount to pay'
  };

}, EnterAmount);
