import React, {Component} from 'react';
import {_} from 'imports/lib/helpers';

export default class Speed extends Component {
  render() {

    var res = null;
    const {a} = this.props;
    res = (
      <table>
        <tbody>
        <tr>
          <td style={{padding: "40px", textAlign: "center"}}>
            <h4 className="shadow">{_('Click a number to change it, click the Star to set as preferred')}</h4>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2" className="center">
            <p
              className="star">{_('Pay less to talk to your preferred numbers!\nNote: You can change this once a month. Preferred numbers are\nlocked automatically for a month and can not be removed.')}</p>
          </td>
        </tr>
        </tfoot>
      </table>
    );
    return res;
  }
}

Speed.propTypes = {};
