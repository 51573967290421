import React, {Component} from 'react';
import {
  countInDomEditScrollListSlide,
  countInDomEditSlide,
  initVerticalSlider,
  makeVerticalSlide,
} from 'imports/lib/helpers';
import Button from 'imports/ui/shared/button/Button';
import InputRTLDirection from 'imports/ui/shared/input_rtl_direction/InputRTLDirection';
import Input from 'imports/ui/shared/input/Input';
import IW from 'imports/iw_api';
import {Session} from 'core/session';
import UserTransferData from 'imports/ui/shared/user_transfer/UserTransferSessionContainer';
import Keyboard from 'imports/ui/shared/keyboard/Keyboard';
import Clear from 'imports/ui/shared/clear/Clear';
import MapContainer from 'imports/ui/apps/map_dev/MapContainer';
import DatePickerIOSliderContainer from 'imports/ui/shared/datepicker/DatePickerIOSliderContainer';
import {KEYBOARDS, TRANSFER_INPUTS_RESULT} from 'imports/lib/constants';
import safeRender from 'imports/ui/decorators/safeRender'
import VerticalSlider from "imports/ui/shared/vertical_slider/VerticalSliderContainer";


const lastPoint = {x: 0, y: 0, id: null};

class EnterUserData extends Component {
  constructor() {
    super();
    this.LINE_COUNT = countInDomEditSlide();
    this.SLIDER_LINE_COUNT = countInDomEditScrollListSlide();
  }

  componentWillMount() {
    IW.appSetLocalVal('scroll_selected_item', ''); //FIXME
    // Session.set('prev_current_active_field', 0);
    // Session.set('current_active_field', 0);
  }

  componentDidMount() {
    Session.set('address_portal_value', 1);

    const {slideNum} = this.props;
    Session.set('focus', 'data');

    const field_to_focus = Session.get('focus');
    Session.set('focus', '');
    Session.set('focus', field_to_focus);

    IW.appSetVal('focus', field_to_focus);

    initVerticalSlider(Object.assign(this.props.optionsSlider), 'slider-container', this.LINE_COUNT);
    makeVerticalSlide(slideNum, 'slider-container');
  }

  componentDidUpdate(prevProps) {
    const slideNum = Math.min(Math.max(this.props.slideNum - (this.LINE_COUNT - 2), 1), this.props.displayedFields.length - this.LINE_COUNT + 1);
    const prevSlideNum = Math.min(Math.max(this.props.prevSlideNum - (this.LINE_COUNT - 2), 1), this.props.displayedFields.length - this.LINE_COUNT + 1);

    if (prevProps.currentActiveField.type_inputs_result !== this.props.currentActiveField.type_inputs_result &&
      this.props.currentActiveField.type_inputs_result === TRANSFER_INPUTS_RESULT.SCROLL_LIST) {
      IW.appSetLocalVal('scroll_selected_item', ''); //FIXME
    }

    if (prevProps.currentActiveField.type_inputs_result === this.props.currentActiveField.type_inputs_result) {
      // console.log('EnterUserData | componentDidUpdate | makeVerticalSlide ', this.props.slideNum, slideNum, prevSlideNum, this.LINE_COUNT);
      makeVerticalSlide(slideNum || prevSlideNum, 'slider-container');
      return;
    }
    if (prevProps.prevSlideNum !== this.props.prevSlideNum || prevProps.slideNum !== this.props.slideNum) {
      initVerticalSlider(Object.assign(this.props.optionsSlider, {startAtSlide: prevSlideNum}),
        'slider-container');
      makeVerticalSlide(slideNum || prevSlideNum, 'slider-container');
      // console.log('EnterUserData | componentDidUpdate | initVerticalSlider + makeVerticalSlide ', this.props.slideNum, slideNum, prevSlideNum, this.LINE_COUNT);
    } else {
      initVerticalSlider(Object.assign(this.props.optionsSlider, {startAtSlide: slideNum}),
        'slider-container', this.LINE_COUNT);
      // console.log('EnterUserData | componentDidUpdate | initVerticalSlider ', this.props.slideNum, slideNum, prevSlideNum, this.LINE_COUNT);
    }
  }

  //Inputs result content
  getInputResult() {
    const {currentActiveField} = this.props;
    console.log('EnterUserData | getInputResult | currentActiveField ', currentActiveField);

    switch (currentActiveField.type_inputs_result) {
      case TRANSFER_INPUTS_RESULT.SEARCH_LIST:
        return this.getSearchResultList();
      case TRANSFER_INPUTS_RESULT.MAP_SELECTOR:
        return null;
      case TRANSFER_INPUTS_RESULT.SCROLL_LIST:
        return null;
      case TRANSFER_INPUTS_RESULT.SIMPLE_LIST:
      default:
        return this.getSimpleListContent();
    }
  }

  getSimpleListContent() {
    const {displayedFields, onSliderClick} = this.props;
    // item.describe, item.data
    const renderResult = displayedFields.map((item, i) => {
      return (<div className="slide" key={i}>
        <div className="left-side">
          <div _>{item.describe}{_.isObject(item.validation) && item.validation.required && <span style={{marginLeft: 10}}>*</span>}</div>
        </div>
        <div className="right-side">
          <Input className="" value={item.data} readOnly={true} disabled={true}/>
        </div>
      </div>);
    });

    let additionalClass = '';

    // Add two empty objects for fix slide effect
    if (renderResult.length >= this.LINE_COUNT - 1) {
      additionalClass = 'middle-input'; // TODO: middle-input is dependent
    }

    for (let i = this.LINE_COUNT - renderResult.length; i > 0; i--) {
      renderResult.unshift((<div key={-i - 1} className="slide"/>));
    }
    renderResult.push((<div key={-10} className="slide"/>));

    return (
      <tr>
        <td colSpan="2" className="enter-user-data__dynamic-user-info"
            onMouseDown={({pageY}) => onSliderClick({
              pageY: pageY,
              index: Math.min(Math.floor((pageY - 245) / 120), this.LINE_COUNT),
            })}>
          <div className={'slider-container middle-input'}>
            <div className="iosVerticalSlider">
              <div className="slider">
                {renderResult}
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  }

  getSearchResultList() {
    const {searchResult, onSelectItem, inSearching, showLoading} = this.props;

    const searchBox = (
      <td colSpan="2" className="search-result-container">
        <span className="searching-label" _>Searching...</span>
      </td>
    );

    // if (searchResult.length === 0) {
    //   return (
    //     <tr className="enter-user-data__dynamic-item">
    //       {inSearching ? searchBox : <td colSpan="2" className="search-result-container">Empty</td>}
    //     </tr>
    //   )
    // }

    const items = _.compact(_.map(searchResult, (item, i) => {
      if (item && (item.value || item.value === '')) return (
        <tr key={i}>
          <td onMouseDown={() => onSelectItem(item.value, i)}>
            <div className="ellipsis-container">
              <span>{item.value}</span>
            </div>
          </td>
        </tr>
      );
    }));

    const searchingResult = (
      <td colSpan="2" className="search-result-container">
        <span _>{showLoading ?
          'Loading...' :
          items.length > 0 ? 'Do you mean (click to select):' : 'No results'
        }</span>
        <table className="search-result-table">
          <tbody>
          {items}
          <tr key={100} className="last">
            <td>
            </td>
          </tr>
          </tbody>
        </table>
      </td>
    );

    return (
      <tr className="enter-user-data__dynamic-item">
        {inSearching ? searchBox : searchingResult}
      </tr>);
  }


  showNextIfScrollList() {
    return this.props.currentActiveField.type_inputs_result !== TRANSFER_INPUTS_RESULT.SCROLL_LIST || IW.appGetVal('scroll_selected_item');
  }

  getScrollListInput() {
    const {currentActiveField, scrollListResult, showLoading, value, inputValue} = this.props;

    if (!IW.appGetVal('scroll_selected_item')) {
      let defaultItem = scrollListResult.findIndex((el) => el.value === value || el.value === inputValue);
      if (defaultItem <= -1) {
        defaultItem = 0
      }
      if (scrollListResult[defaultItem]) {
        IW.appSetVal('scroll_selected_item', `#${defaultItem}`);
        this.props.onSelectItem && this.props.onSelectItem(scrollListResult[defaultItem].value, defaultItem);
      }
    }

    // FIXME
    let originalSlideNumber = String(IW.appGetVal('scroll_selected_item') || '').match(/#(\d+)/) || [null, -1];
    originalSlideNumber = +originalSlideNumber[1];

    const items = _.map(scrollListResult, (item, i) => {
      if (_.isEmpty(item)) {
        return <div className="search-list__item"></div>;
      }

      return (
        <div key={i} onMouseUp={(event) => this.handleScrollSearchClick(event, item, i)}
             onMouseDown={(event) => this.handleScrollSearchClick(event, item, i)}
             className="search-list__item">
          <div className={originalSlideNumber === i ? 'search-list__item--active' : ''}>
            {item.value}
          </div>
        </div>
      );
    });

    return (
      <div className="search-list__container">
        {showLoading && (<span _>Loading...</span>)}
        {!showLoading && items.length > 0 && (
          <span _={[<span style={{color: 'white'}} _>{currentActiveField.describe}</span>]}>Choose your %s (click to select):</span>
        )}
        {!showLoading && items.length <= 0 && (<span _>No results</span>)}
        <div className="search-list__wrap">
          <VerticalSlider minEls={this.SLIDER_LINE_COUNT} withArrows={true} slideNumber={originalSlideNumber}
                          items={items}/>
        </div>
      </div>
    );
  }

  handleScrollSearchClick(event, item, i) {
    const {currentActiveField} = this.props;
    // Ignore mousedown for slider items
    if (event.type === 'mousedown') {
      lastPoint.x = event.pageX;
      lastPoint.y = event.pageY;
      lastPoint.id = i;
      return;
    }

    if (lastPoint.id !== i ||
      Math.max(Math.abs(lastPoint.x - event.pageX), Math.abs(lastPoint.y - event.pageY)) > 20) return;

    IW.appSetLocalVal('scroll_selected_item', `#${i}`); // FIXME
    this.props.onSelectItem && this.props.onSelectItem(item.value, i);
  }


  // Keyboards content
  getKeyboard() {
    const {currentActiveField} = this.props;

    switch (currentActiveField.type_keyboard) {
      case KEYBOARDS.DATEPICKER:
        return this.getDatePicker();
      case KEYBOARDS.EMAIL:
        return this.getEmailKeyboard();
      case KEYBOARDS.TEXT_NUMBER:
        return this.getTextNumberKeyboard();
      case KEYBOARDS.NUMBER:
        return this.getNumberKeyBoard();
      case KEYBOARDS.AMOUNT:
        return this.getAmountKeyBoard();
      case KEYBOARDS.NONE:
        return null;
      case KEYBOARDS.TEXT:
      default:
        return this.getTextKeyboard();
    }
  }

  getEmailKeyboard() {
    const {value, emailKeyboardLayout, emailSuffixKeyboard, onInput, maxInputLength} = this.props;

    return (<tr className="enter-user-data__keyboard-container">
      <td className="email-keyboard__email-keyboard">
        <Keyboard keys={emailKeyboardLayout} value={value} maxLength={maxInputLength || 150} onValueChange={onInput}/>
        <Clear/>
      </td>
      <td className="email-keyboard__email-keyboard-second">
        <Keyboard keys={emailSuffixKeyboard} value={value} maxLength={maxInputLength || 150} onValueChange={onInput}/>
        <Clear/>
      </td>
    </tr>);
  }

  getTextKeyboard() {
    const {value, textKeyboardLayout, onInput, maxInputLength} = this.props;

    return (<tr className="enter-user-data__keyboard-container">
      <td colSpan="2">
        <Keyboard keys={textKeyboardLayout} value={value} maxLength={maxInputLength || 150} onValueChange={onInput}
                  style={{margin: 'auto', width: 900}}/>
        <Clear/>
      </td>
    </tr>);
  }

  getTextNumberKeyboard() {
    const {value, textKeyboardLayout, numberKeyboardLayout, onInput, maxInputLength} = this.props;

    return (<tr className="enter-user-data__keyboard-container">
      <td>
        <Keyboard keys={textKeyboardLayout} value={value} maxLength={maxInputLength || 150} onValueChange={onInput}/>
        <Clear/>
      </td>
      <td>
        <Keyboard keys={numberKeyboardLayout} value={value} maxLength={maxInputLength || 150} onValueChange={onInput}/>
        <Clear/>
      </td>
    </tr>);
  }

  getNumberKeyBoard() {
    const {value, numberKeyboardLayout, onInput, maxInputLength} = this.props;

    return (<tr className="enter-user-data__keyboard-container">
      <td colSpan="2">
        <Keyboard keys={numberKeyboardLayout} value={value} maxLength={maxInputLength || 150}
                  onValueChange={onInput} style={{margin: 'auto', width: 300}}/>
        <Clear/>
      </td>
    </tr>);
  }

  getAmountKeyBoard() {
    const {value, amountKeyboardLayout, onInput, maxInputLength} = this.props;

    return (<tr className="enter-user-data__keyboard-container">
      <td colSpan="2">
        <Keyboard keys={amountKeyboardLayout} value={value} maxLength={maxInputLength || 150}
                  onValueChange={onInput} style={{margin: 'auto', width: '300px'}}/>
        <Clear/>
      </td>
    </tr>);
  }

  getDatePicker() {
    const {onInput, value} = this.props;

    return (<tr className="enter-user-data__keyboard-container">
      <td>
        <DatePickerIOSliderContainer date={value} onDateChange={onInput} startYear={1930}/>
      </td>
    </tr>);
  }

  getInputField(currentActiveField, inputValue, onClearInput, length, slideNum, onInput, onSelectItem) {
    let additionalClass = 'bottom';
    const style = {};
    // console.log('getInputField : ', currentActiveField, inputValue, length);

    switch (currentActiveField.type_inputs_result) {
      case TRANSFER_INPUTS_RESULT.SIMPLE_LIST:
        if (slideNum < length || (slideNum === length && length > 1)) {
          additionalClass = 'flying';
          const offset = (slideNum < this.LINE_COUNT - 1) ?
            slideNum - 1 + Math.max(0, this.LINE_COUNT - length) :
            (slideNum === length ?
              this.LINE_COUNT - 1 :
              this.LINE_COUNT - 2);
          style.top = 100 + (offset * 117);
        }
        if (slideNum === length && length === 1) {
          additionalClass = 'flying';
          style.top = 100 + ((this.LINE_COUNT - 1) * 117);
        }
        break;
      case TRANSFER_INPUTS_RESULT.MAP_SELECTOR:
        return <MapContainer address={inputValue} onAddressSelect={onSelectItem}/>;
      case TRANSFER_INPUTS_RESULT.SCROLL_LIST:
        return this.getScrollListInput();
      case TRANSFER_INPUTS_RESULT.SEARCH_LIST:
      default:
        additionalClass = '';
    }


    return (<table className={`enter-user-data__input-container ${additionalClass}`} style={style}>
      <tbody>
      <tr style={{height: 0}}>
        <td>
          <div
            className="enter-user-data__input-describe"
            data-input-wide={length <= 1}
          >
            <span _>
              {currentActiveField.describe}{currentActiveField.validation && currentActiveField.validation.required && <span style={{marginLeft: 10}}>*</span>}
            </span>
          </div>
        </td>
        <td>
          <div className="bordered-input">
            <InputRTLDirection id="data" className="focus" value={inputValue}
                               focus={currentActiveField.disable_clear ? '' : 'data'}
                               clearInput={onClearInput}/>
            <span className="enter-user-data__error-message" _>{currentActiveField.error_message}</span>
          </div>
        </td>
      </tr>
      </tbody>
    </table>);
  }

  renderUserInfo() {
    const {userType, currentActiveField, validationError} = this.props;

    if (validationError) {
      return (
        <td colSpan="2" className="enter-user-data__user-info">
          <span className="transfer-error_message">{validationError}</span>
        </td>
      );
    }

    if (userType) {
      return (
        <td colSpan="2" className="enter-user-data__user-info-transfer">
          <UserTransferData currentActiveField={currentActiveField} userType={userType}/>
        </td>
      );
    }


    return (
      <td colSpan="2" className="enter-user-data__user-info">
        <span className="transfer-description__accented">Edit your profile:</span>
      </td>
    );
  }

  renderAdditionalUserInfo() {
    const {currentActiveField} = this.props;
    if (!currentActiveField.additionalUserInfo) return null;
    return (
      <div className="enter-user-data__user-info-transfer" data-bottom>
        {currentActiveField.additionalUserInfo}
      </div>
    );
  }

  render() {
    const {
      onClickNext, onClickBack, onClearInput, inputValue, userType, allFieldsReady,
      currentActiveField, showNext, displayedFields, slideNum, onInput, onSelectItem,
    } = this.props;

    console.log('Props : ', this.props);

    return (
      <table className="enter-user-data">
        <tbody>
        <tr>
          {this.renderUserInfo()}
          {this.renderAdditionalUserInfo()}
        </tr>
        {this.getInputResult()}
        <tr>
          <td colSpan="2">
            {this.getInputField(currentActiveField, inputValue, onClearInput, displayedFields.length, slideNum, onInput, onSelectItem)}
          </td>
        </tr>
        {this.getKeyboard()}
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2">
            <Button id="back" className="left button" text_="Back" onMouseDown={() => onClickBack()}/>
            {showNext && this.showNextIfScrollList() && (
              <Button id="next" className="right button" onMouseDown={() => onClickNext()}
                      text_={allFieldsReady ? 'Confirm' : 'Next'}/>)}
          </td>
        </tr>
        </tfoot>
      </table>
    );
  }
}

EnterUserData.propTypes = {
  onClickNext: React.PropTypes.func,
  onClickBack: React.PropTypes.func,
  onSliderClick: React.PropTypes.func,
  onInput: React.PropTypes.func,
  onClearInput: React.PropTypes.func,
  value: React.PropTypes.string,
  inputValue: React.PropTypes.string,
  emailKeyboardLayout: React.PropTypes.array,
  numberKeyboardLayout: React.PropTypes.array,
  textKeyboardLayout: React.PropTypes.array,
  cssFixKeyboard: React.PropTypes.object,
  country: React.PropTypes.string,
  number: React.PropTypes.string,
  userType: React.PropTypes.string,
  availableFields: React.PropTypes.array,
  currentActiveField: React.PropTypes.object,
  displayedFields: React.PropTypes.array,
  allFieldsReady: React.PropTypes.bool,
  searchResult: React.PropTypes.array,
  onSelectItem: React.PropTypes.func,
  showNext: React.PropTypes.bool,
  slideNum: React.PropTypes.number,
  prevSlideNum: React.PropTypes.number,
  optionsSlider: React.PropTypes.object,
  emailSuffixKeyboard: React.PropTypes.array,
  date: React.PropTypes.object,
  onDateChange: React.PropTypes.func,
  maxInputLength: React.PropTypes.number,
};

export default EnterUserData;
