import { FlowRouter } from 'core/Router';
import IW from '../iw_api';
import {Session} from 'core/session'

export function isCurrentPage(page) {
  return FlowRouter.getRouteName() == page;
}

export function isIndex() {
  return FlowRouter.getRouteName() == "Index";
}

export function isCategory() {
  return FlowRouter.getRouteName() == "Category";
}

export function isSearch() {
  return FlowRouter.getRouteName() == "Search";
}

export function isApplication() {
  return FlowRouter.getRouteName() == "Application";
}

export function isAppReady() {
  return !!Session.get('session_guid');
}

export function getIconStyle() {
  var style_string = '';

  if( Session.get('icon') )
    style_string += 'background-image:url(' + Session.get('icon')+');';

  return style_string;
}

export function getAppId() {
  return FlowRouter.getParam("appName");
}

export function getLanguage() {
  return FlowRouter.getParam("language");
}

export function getAppName() {
  return IW.appGetVal('name');
}

export function getAppDesc() {
  return IW.appGetVal('desc');
}

export function getAppStep() {
  var html = '';
  var step = IW.appGetStep( IW.appGetVal('page') || 'index' );

  if( step )
    for( var i = 1; i <= IW.appGetStep(false, true); i++ )
      html += (step == i) ? '<b>'+i+'</b>' : '<a>'+i+'</a>';

  return html;
}

export function getCategoryName() {
  return Session.get('cats')[Session.get('cat')];
}
