import createNumber from './common/number';
import createStartScanning from './common/start_scanning';
import createStartScanningInsertBill from './common/start_scanning_insert_bill';
import app_common from './app_common';
import createPaymentStatus from './common/payment_status';
import createAmount from './common/amount';
import createChangeProduct from './common/change_product';
import IW from 'imports/iw_api';
import {Session} from 'core/session';
import CONFIG from 'imports/config';

const startScanningPage = CONFIG.BILL_SCANNING_GENMEGA ?
  'bill_scanning_insert_bill' :
  'start_scanning';

// ["info", "number", "bill_scanning", "scan_info", "start_scanning", "inputs_billpayment", "bill_amount", "plus", "pay", "final", "email", "sent"]
export default _.extend({}, app_common, {
  change_product: createChangeProduct('redirect1', {plan: 'redirect1', prepaid: 'number'}),

  redirect1: () => {
    if (IW.appGetVal('transpayment_does_not_work')) return 'return_card_internal_error';
    let numberPage = 'number_login';
    if (IW.appGetVal('bill_type') === 'phone') {
      numberPage = 'number';
    }
    return numberPage;
  },

  number_login: createNumber('exit', 'info', {allowLandline: true}),
  number: createNumber('exit', 'info', {allowLandline: true}),

  info: function* () {
    function getBack() {
      if (IW.appGetVal('logged')) return 'exit';
      return 'back'
    }
    if (IW.appGetVal('transpayment_does_not_work')) return 'return_card_internal_error';

    IW.appClearVal('required_fields');

    yield 'loading app product';
    IW.appSetLocalVal('current_account', {
      product_id: Session.get('product_id'),
      name: Session.get('title'),
      icon: Session.get('icon'),
      account: '',
      account2: '',
      gw_prod_id: IW.appGetVal('gw_prod_id'),
      location_id: IW.appGetVal('location_id'),
      product: IW.appGetVal('product'),
      biller_id: IW.appGetVal('product'),
    });

    return {
      back: getBack(),
      next: function* () {
        IW.call('setPaymentOption');

        yield 'loading app_exists required_fields';

        const billType = IW.appGetVal('bill_type');
        if (billType === 'phone') {
          const phone = IW.appGetVal('phone_number').slice(-10);
          IW.appSetVal('account', phone);

          return 'bill_required_data_phone'
        }

        return 'bill_scanning'
      }
    }
  },

  bill_scanning: function* () {
    let account = IW.appGetVal('current_account') || {};
    IW.appClearVal(/required_([A-Z0-9]{1,10})/);
    IW.appSetObject(account);
    Session.set('icon', account.icon);
    Session.set('title', account.name);

    if (!CONFIG.BILL_SCANNING_ENABLED && _.map(Session.get('accounts'), (a) => a).length === 0) {
      // bill_scanning:manual
      IW.appSetVal('selected_payment_option', +IW.appGetVal('payment_option_type'));
      IW.call('setPaymentOption');
      IW.appSetLocalVal('payment_options', null);
      yield 'loading app payment_options';
      IW.appSetLocalVal('new_recipient', true);
      return 'inputs_billpayment';
    }

    return {
      back: function () {
        return 'info'
      },
      next: function* () {
        const accountId = +IW.appGetVal('account_id');
        account = _.find(Session.get('accounts'), (a) => a.account_id === accountId);
        if (!account) return 'exit';
        IW.appSetObject(account || {});

        // This code removes required fields if they are entered
        // IW.call('validateRequiredFields');
        // IW.appSetVal('validate_account_done', false);
        // yield 'loading app validate_account_done';

        IW.appSetVal('selected_payment_option', +IW.appGetVal('payment_option_type'));
        IW.call('setPaymentOption');
        IW.appSetLocalVal('payment_options', null);
        yield 'loading app payment_options';

        Session.set('icon', account.icon);
        Session.set('title', account.name);
        IW.appSetLocalVal('new_recipient', false);
        return 'bill_required_data';
      },
      scan: function () {
        IW.appSetLocalVal('new_recipient', true);
        return startScanningPage;
      },
      manual: function* () {
        IW.appSetVal('selected_payment_option', +IW.appGetVal('payment_option_type'));
        IW.call('setPaymentOption');
        IW.appSetLocalVal('payment_options', null);
        yield 'loading app payment_options';
        IW.appSetLocalVal('new_recipient', true);
        return 'inputs_billpayment';
      }
    }
  },

  ...createStartScanningInsertBill('bill_scanning', 'bill_required_data', 'scanning_error'),

  // __bill_scanning: {
  //   scan: 'scan_info',
  //   manual: 'inputs_billpayment',
  // },
  // scan_info: {
  //   back: 'bill_scanning',
  //   next: 'start_scanning',
  // },
  start_scanning: createStartScanning('bill_scanning', 'bill_required_data', 'scanning_error'),
  scanning_error: {
    back: startScanningPage,
    next: 'inputs_billpayment'
  },
  inputs_billpayment: {
    back: CONFIG.BILL_SCANNING_ENABLED ?
      'bill_scanning' :
      'info',
    next: 'bill_required_data',
  },
  bill_required_data: function ({lastAction}) {
    function getBack() {
      if (!IW.appGetVal('logged')) return 'back';
      if (IW.appGetVal('new_recipient')) return 'bill_scanning';
      return 'bill_scanning'
    }

    Session.set('current_active_field', 0);
    Session.set('prev_current_active_field', 0);

    const requiredFields = IW.appGetVal('required_fields');

    if (!(requiredFields && requiredFields.length)) {
      if (lastAction !== 'back') return 'bill_amount';
      return getBack()
    }

    Session.set('use_page_preload_cb', true);

    return {
      back: getBack(),
      next: 'bill_amount',
    }
  },

  bill_required_data_phone: function ({lastAction}) {
    Session.set('current_active_field', 0);
    Session.set('prev_current_active_field', 0);

    const requiredFields = IW.appGetVal('required_fields');

    if (!(requiredFields && requiredFields.length)) {
      if (lastAction !== 'back') return 'bill_amount';
      return 'info'
    }

    Session.set('use_page_preload_cb', true);

    return {
      back: 'info',
      next: 'bill_amount',
    }
  },

  bill_amount: createAmount('back', 'pay'),
  bill_amount_again: createAmount('exit', 'pay'),
  payment_status: createPaymentStatus('number_login', 'bill_amount_again', 'pay'),

  // After Login
  on_login: function* () {
    const phoneNumber = IW.appGetVal('phone_number');

    if (!phoneNumber) {
      const customerData = IW.loginGetVal('customer_data_result');
      const customerPhoneNumber = customerData.mobile_number;

      IW.appSetVal('phone_number', customerPhoneNumber);
      IW.appSetVal('number', customerPhoneNumber)
    }

    IW.callWithUpdate('getAccounts', 'accounts');
    yield 'loading session accounts';

    return "info"
  },

  on_login_pay_again: () => 'bill_amount_again'

  // __bill_scanning_loginned: createAccountChoise("confirm_customer_data", "bill_required_data", "inputs_billpayment"),
});
