import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import BillProcessingType from './BillProcessingType';
import getPageManager from 'imports/lib/page_manager';
import CONFIG from 'imports/config';

export default createContainer((params) => {

  return {
    billScanningEnabled: CONFIG.BILL_SCANNING_ENABLED,
    goToScan: () => getPageManager().scan(),
    goToSearch: () => getPageManager().search(),
    onBack: () => getPageManager().back()
  };

}, BillProcessingType);