import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import Error from './Error'
import getPageManager from 'imports/lib/page_manager'
import IW from '../../../iw_api';

export default createContainer(() => {
  const supportNumber = IW.getUISettings().support_number;

  return {
    onExit: () => getPageManager().cancel(),
    onBack: () => getPageManager().back(),
    onNext: () => getPageManager().next(),
    supportNumber
  };

}, Error);


