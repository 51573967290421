import {createContainer} from 'imports/lib/react_createContainer';
import Loans from './Loans'
import getPageManager from 'imports/lib/page_manager'

export default createContainer(function(params) {
  return {
    onBack: () => getPageManager().back(),
    onNext: () => getPageManager().next(),
    qr_url: Session.get('app').qr_url
  }
}, Loans);
