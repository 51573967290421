import React, {Component} from 'react';
import {FlowRouter} from 'core/Router';
import Button from 'imports/ui/shared/button/Button';

const Row = ({children}) => (
  <tr>
    <td style={{textAlign: 'right'}}>{children[0]}:</td>
    <td style={{textAlign: 'left', paddingLeft: 20}}>{children[1]}</td>
  </tr>)

export default class PaymentStatus extends Component {

  getDescriptonText() {
    const {paymentLocation, paymentCity, paymentState, paymentAddress, paymentZip, paymentTime} = this.props;
    const address = _.compact([paymentAddress, paymentCity, paymentState]).join(', ')
    return (
      <span _={[paymentLocation, _.compact([address, paymentZip]).join(' '), paymentTime]}>
        You made this transaction at %s in %s on the %s
      </span>);
  }

  getStatusText() {
    const {account, accountFieldName, transactionId, amount, isPaid, paymentStatus} = this.props;
    return (
      <table>
        <tbody>
        {transactionId && (
          <Row>
            <span _>Reference Number</span>
            {transactionId}
          </Row>
        )}
        {accountFieldName && (
          <Row>
            <span _>{accountFieldName}</span>
            {account}
          </Row>
        )}
        <Row>
          <span _>Amount</span>
          {amount}
        </Row>
        <Row>
          <span _>Payment Status</span>
          <b className={(isPaid && 'success') || 'failed'} _>{paymentStatus}</b>
        </Row>
        </tbody>
      </table>)
  }

  getActionButtons() {
    const {
      canCancel, canEdit, canPayAgain, canRefund, onCancel, onPrintReceipt, onEdit, onPayAgain, onRefund, onGetSupport,
      isVirtualDevice
    } = this.props;

    const buttons = [];

    if (canCancel) buttons.push(
      <Button className="button button-danger" text_="Cancel this transaction" onMouseDown={onCancel}/>
    )

    if (canEdit) buttons.push(
      <Button className="button button-danger" text_="Edit transaction info" onMouseDown={onEdit}/>
    )

    if (canPayAgain) buttons.push(
      <Button className="button button-primary" text_="Pay this bill again" onMouseDown={onPayAgain}/>
    )

    if (canRefund && !isVirtualDevice) buttons.push(
      <Button className="button button-primary" text_="Withdraw Cash Refund" onMouseDown={onRefund}/>
    )

    if (isVirtualDevice) buttons.push(
      <Button className="button button-primary" text_="Print Receipt" onMouseDown={onPrintReceipt}/>
    );

    buttons.push(
      <Button className="button button-primary" text_="Get Support" onMouseDown={onGetSupport}/>
    )

    return (<table className="buttons-table">
      <tbody>
      {buttons.map((button, k) => (
        <tr key={k}>
          <td>
            {button}
          </td>
        </tr>
      ))}
      </tbody>
    </table>);
  }

  render() {
    const res = (
      <table className="pay-status">
        <tbody>
        <tr className="pay-status__description">
          <td>
            {this.getDescriptonText()}
          </td>
        </tr>
        <tr className="pay-status__status">
          <td>
            {this.getStatusText()}
          </td>
        </tr>
        {!this.props.cancelAllowed && <tr className="pay-status__status failed">
          <td>
            <span _>You cannot cancel this transaction</span>
          </td>
        </tr>}
        {this.props.showSigueCancelNotAllowed && <tr className="pay-status__status failed">
          <td>
            <span _>Transaction cancellation not available. Contact Sigue Customer Service at 1(800)385-3316</span>
          </td>
        </tr>}
        <tr className="pay-status__buttons">
          <td>
            {this.getActionButtons()}
          </td>
        </tr>
        </tbody>
      </table>
    );
    return res;
  }
}

PaymentStatus.propTypes = {
  canCancel: React.PropTypes.bool,
  canEdit: React.PropTypes.bool,
  canPayAgain: React.PropTypes.bool,
  canRefund: React.PropTypes.bool,
  isPaid: React.PropTypes.bool,
  paymentStatus: React.PropTypes.string,
  paymentLocation: React.PropTypes.string,
  paymentCity: React.PropTypes.string,
  paymentTime: React.PropTypes.string,
  onCancel: React.PropTypes.func,
  onEdit: React.PropTypes.func,
  onPayAgain: React.PropTypes.func,
  onRefund: React.PropTypes.func,
  onGetSupport: React.PropTypes.func
};
