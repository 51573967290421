export default function (pageBack, pageNext) {
  return function() {
    return {
      back: function () {
        return pageBack
      },
      next: function() {
        return pageNext;
      }
    }
  }
}