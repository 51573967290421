import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import CardSimple from 'imports/ui/shared/card/CardContainer';
import safeRender from 'imports/ui/decorators/safeRender';

class Accounts extends Component {
  render() {

    const {accounts, onCardClick, onBack, onPrint} = this.props;
    return (
      <table>
        <tbody>
        <tr>
          <td >
            <div className="atm-accounts">
              {accounts.map((item, i) => {
                return <CardSimple key={i} className="atm-accounts__card" iconUrl={item.iconUrl}
                             text_={item.text} direction="col"
                             onCardClick={onCardClick.bind(this, item.id)}
                             additional={item.id}
                             style={item.id === 1 ?
                               {width: 930} :
                               null}
                />
              })}
            </div>
          </td>
        </tr>
        </tbody>
        <tfoot className="atm-foot">
        <tr>
          <td colSpan="2">
            <Button id="back" className="left button" text_="Back" onMouseDown={ onBack }/>
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

Accounts.propTypes = {
  accounts: React.PropTypes.array,
  onBack: React.PropTypes.func,
  onPrint: React.PropTypes.func,
  onCardClick: React.PropTypes.func,
};

Accounts.defaultProps = {
  accounts: [],
};

export default Accounts;
