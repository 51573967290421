import React, {Component} from 'react';
import UserTransferData from 'imports/ui/shared/user_transfer/UserTransferSessionContainer';
import Button from 'imports/ui/shared/button/Button';
import safeRender from 'imports/ui/decorators/safeRender';

class ConfirmEnteredData extends Component {
  printData(data) {
    if (_.isArray(data)) {
      return data.map((item, i) => <p key={i}>{item}</p>)
    }
    return data;
  }

  render() {
    const {userType, onBack, onNext, onEdit, items} = this.props;

    return (<table className="confirm-entered-data-container">
      <tbody>
      <tr>
        <td className="enter-user-data__user-info">
          <UserTransferData userType={userType}/>
        </td>
      </tr>
      <tr>
        <td>
          <table className="field-list">
            <tbody>
            {items.map((item, i) => {
              if (item.data) return (<tr key={i}>
                <td className="field-list__description">{item.describe_output ?
                  <span><span _>{item.describe_output}</span>:</span> :
                  null}
                </td>
                <td className="field-list__data"><span
                  className={item.accented ? "accented" : ""}>{this.printData(item.data)}</span></td>
              </tr>);
              return '';
            })}
            </tbody>
          </table>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td style={{textAlign: 'center'}} colSpan="2">
          {onBack && <Button id="back" className="left button" text_="Back" onMouseDown={onBack}/>}
          <Button id="edit" className="center button goto-index" text_="Edit" onMouseDown={onEdit}/>
          <Button id="next" className="right button" text_="Confirm" onMouseDown={onNext}/>
        </td>
      </tr>
      </tfoot>
    </table>);
  }
}

ConfirmEnteredData.propTypes = {
  userType: React.PropTypes.string,
  onNext: React.PropTypes.func,
  onBack: React.PropTypes.func,
  onEdit: React.PropTypes.func,
  items: React.PropTypes.array
};

export default ConfirmEnteredData;