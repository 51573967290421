import createSessionMethods from 'imports/lib/createSessionMethods'
import IW from 'imports/iw_api';
import {Session} from "core/session";
import {nextWhen} from '../lib/page_manager';

const SM = createSessionMethods('login');
const SMP = createSessionProxy(SM);
const sessionScope = 'login';

export default {
  on_launch_login: function ({lastAction}) {
    if (lastAction === 'back') return 'back';

    IW.loginLaunchInApplication();
    return 'pin_redirect'
  },

  pin_redirect: function* ({lastAction}) {
    if (lastAction === 'back') return 'back';

    const phone = SMP.phone_number;

    // no_payment:no_sso  payment:no_sso  payment:sso:no_pin payment:sso
    SMP.customer_type = '';

    if (IW.appGetVal('login_just_verify_and_next')) {
      SMP.customer_type = 'no_payment:no_sso';
    }

    yield* doSSOCall('check_user_exists', {phone_number: phone});
    if (SMP.check_user_exists_result) {
      yield* doSSOCall('check_pin_exists', {phone_number: phone});
    }

    if (!SMP.customer_type && !SMP.check_user_exists_result && !Session.get('customer_exists')) {
      SMP.customer_type = 'no_payment:no_sso';
    }

    if (!SMP.customer_type && !SMP.check_user_exists_result && Session.get('customer_exists')) {
      SMP.customer_type = 'payment:no_sso';
    }

    if (!SMP.customer_type && SMP.check_user_exists_result) {
      SMP.customer_type = 'payment:sso';
    }

    if (SMP.customer_type === 'payment:sso' && !SMP.check_pin_exists_result) {
      SMP.customer_type = 'payment:sso:no_pin';
    }

    const ct = SMP.customer_type;
    if (/:no_sso/.test(ct)) return 'pin_code_sing_in';
    if (/:no_pin/.test(ct)) return 'pin_code_sing_in';
    return 'pin_number_sing_in';
  },

  pin_code_sing_in: function* () {
    SMP.error_message_code = '';
    SMP.pin_number = '';
    const phone = SMP.phone_number;

    yield* doSSOCall('get_pin_code', {phone_number: phone});

    yield {
      back: 'back',
      change: () => {
        IW.callSSO('get_pin_code', {phone_number: phone}, sessionScope);
        return 'none';
      }
    };

    while (true) {
      yield nextWhen(() => SMP.pin_number.length === 4);
      yield 'none';

      const pinNumber = SMP.pin_number;
      SMP.pin_number = '';

      yield* doSSOCall('verify_user', {phone_number: phone, pin: pinNumber});

      const verifyResult = SMP.verify_user_result;

      if (!verifyResult) {
        callError('server_error');
        continue;
      }

      if (!verifyResult.verify) {
        callError('invalid_pin');
        continue;
      }

      if (SMP.customer_type === 'no_payment:no_sso') {
        if (!IW.appGetVal('login_just_verify_and_next')) {
          callError('internal_error');
          continue;
        }

        return IW.appGetVal('login_just_verify_and_next');
      }

      SMP.pin_code = pinNumber;
      return 'new_pin_number_sing_in';
    }
  },

  pin_number_sing_in: function* () {
    SMP.error_message_code = '';
    SMP.pin_number = '';
    yield {
      back: 'back',
      change: () => {
        SMP.customer_type = 'payment:sso:no_pin';
        return 'pin_code_sing_in'
      }
    };

    while (true) {
      yield nextWhen(() => SMP.pin_number.length === 4);
      yield 'none';

      const nextPage = yield* signIn();

      SMP.pin_number = '';

      if (nextPage === 'none') continue;

      return nextPage;
    }
  },

  new_pin_number_sing_in: function () {
    SMP.pin_number_1 = '';
    SMP.pin_number_2 = '';

    return {
      back: 'back',
      next: function* () {

        const phone = SMP.phone_number;
        const pin = SMP.pin_number_1;

        if (/:no_sso/.test(SMP.customer_type)) {
          yield* doSSOCall('do_registration', {phone_number: phone, code: SMP.pin_code});

          if (!SMP.do_registration_result) {
            return callError('server_error');
          }

          if(SMP.do_registration_result.error) {
            return callError('invalid_pin');
          }

          if(!SMP.do_registration_result.login_code){
            return callError('server_error');
          }

          SMP.pin_code = SMP.do_registration_result.login_code;
          SMP.customer_type = 'payment:sso:no_pin';
        }

        yield* doSSOCall('reset_pin', {phone_number: phone, pin, code: SMP.pin_code});

        const result = SMP.reset_pin_result;

        if (!(result && result.success)) {
          return callError('server_error')
        }

        SMP.pin_number = pin;
        SMP.customer_type = 'payment:sso';
        return yield* signIn();
      }
    }
  },
};

function* signIn() {
  console.log('|||.... Sign In ....|||');
  cleanUser(SM);

  const pinNumber = SMP.pin_number;
  const phone = SMP.phone_number;

  yield* doSSOCall('sign_in', {phone_number: phone, pin: pinNumber});

  const signInResult = SMP.sign_in_result;

  if (!signInResult) {
    return callError('server_error');
  }

  if (signInResult.error) {
    if (signInResult.error === 'invalid_grant') {
      return callError('invalid_pin');
    }

    return callError('server_error');
  }

  if (!signInResult.access_token) {
    return callError('server_error');
  }

  SMP.access_token = signInResult.access_token;
  SMP.refresh_token = signInResult.refresh_token;

  yield `loading ${sessionScope} access_token`;

  SMP.customer_data_result = null;
  IW.callSSOCustomerData({phone_number: phone, access_token: signInResult.access_token}, sessionScope);
  yield `loading ${sessionScope} customer_data_result`;

  return 'on_login';
}

function* doSSOCall(call, data) {
  const key = `${call}_result`;
  SM.setVal(key, undefined);
  IW.callSSO(call, data, sessionScope);
  yield `loading ${sessionScope}_exists ${key}`;
}
//
// function* doAwait(promise) {
//   let done = false;
//   let res = undefined;
//   promise.catch(() => 0).then((r) => (done = true, res = r));
//   yield nextWhen(() => done);
//   yield 'none';
//   return res;
// }

function callError(key) {
  SMP.error_message_code = key;
  return 'none'
}

function cleanUser() {
  SMP.do_registration_result = undefined;
  SMP.sign_in_result = undefined;
  SMP.customer_data_result = undefined;
  SMP.access_token = '';
  SMP.refresh_token = '';
  SMP.customer_data = '';
}

function createSessionProxy(sessionMethods) {
  return new Proxy({}, {
    get(__, key) {
      return sessionMethods.getVal(key)
    },
    set(__, key, value) {
      sessionMethods.setVal(key, value);
      return true
    }
  })
}
