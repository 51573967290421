import {Session} from 'core/session';
import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import App from './App';
import Apps from 'imports/collections/apps';
import {steps} from 'imports/lib/helpers';

export default createContainer((params) => {

  //Set Application style
  const appGuid = FlowRouter.getParam('appName');
  let css = '';

  console.log('APP_GUID changed to', appGuid);

  if (appGuid && Apps.findOne(appGuid)) { // launched an app
    css = Apps.findOne(appGuid).style;
  }

  return {
    appGuid: FlowRouter.getParam('appName'),
    step: FlowRouter.getParam('step'),
    css
  };

}, App);
