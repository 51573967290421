import React, {Component} from 'react';
import appWorkflow from 'imports/apps_workflow';
import IW from 'imports/iw_api';
import Image from 'imports/ui/shared/image/Image';
import VerticalSlider from '../../shared/vertical_slider/VerticalSliderContainer';
import {goTo} from 'imports/lib/page_manager';
import {gaEvent} from "imports/lib/ga";

export default class Search extends Component {
  constructor(props) {
    super(props);

    this.onTapIcon = this.onTapIcon.bind(this);
  }

  onTapIcon(event, app) {
    const icon = app.icon;
    const app_guid = app.app_guid;

    // Ignore mousedown for slider items
    if ((event.type == 'mousedown') && $('.iosVerticalSlider').data('iosSliderVertical')) {
      gaEvent(this.props.gaCategory, 'Slide');
      return;
    }

    const startPage = appWorkflow[app_guid] && appWorkflow[app_guid][app.start_page] ?
      app.start_page :
      null;

    const data = {
      icon: app.icon,
      app_icon: app.app_icon,
      title: app.title,
      product_id: app.product_id || 0, // product_id
      page: startPage,
      feed: app.feed,
      customer_id: app.customer_id,
      user: app.user
    };


    gaEvent(this.props.gaCategory, 'Select item', app_guid);
    IW.appLaunch(app_guid, data);

    goTo(startPage || 'exit', app_guid, String(app.product_id || '0'));
  }

  renderAppsItem() {
    return this.props.results.map((result, i) => {
      return (
        <div key={i}>
            {result.map((item, j) => {
              if (!item) return;

              return (
                <span className="app_icon" key={j}>
                  <a onMouseDown={(event) => this.onTapIcon(event, item)}
                     onClick={(event) => this.onTapIcon(event, item)}>
                    <div><Image src={item.icon} default={item.iconDefault}/></div>
                    {item.hasOwnProperty('name') ? <span>{item.name}</span> : null}
                  </a>
                </span>
              )
            })}
          </div>
      )
    })
  }

  render() {
    const {errorText, results, gaCategory} = this.props;

    return (
      <table>
        <tbody>
        <tr>
          <td className="search" id="apps">
            {
              results && results.length ? (
                <VerticalSlider withArrows={true} scrollBarStyle={{width: 85}} showScrollBar={results.length > 2} minEls={2} sliderOptions={
                  {
                    slideStartVelocityThreshold: 0,
                    snapVelocityThreshold: 0
                  }} items={this.renderAppsItem()} gaCategory={gaCategory}/>
              ) : <div className="search-error_text">{errorText}</div>
            }
          </td>
        </tr>
        </tbody>
      </table>
    )
  }
}

Search.propTypes = {
  results: React.PropTypes.arrayOf(React.PropTypes.array),
  errorText: React.PropTypes.string.isRequired,
};
