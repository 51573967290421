import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import {countInDomAmounts, countInDomEditSlide, getUser} from 'imports/lib/helpers';
import Favorites from './Favorites';
import Prods from 'imports/collections/products';

export default createContainer((params) => {
  const ROWS_COUNT = countInDomEditSlide();
  const ITEM_COUNT = 3;

  function getApps() {
    var apps = [];
    let favorites_products = (getUser().favorite_products && getUser().favorite_products.length) ? getUser().favorite_products : [];

    var prods = Prods.find({product_id: {$in: favorites_products}}, {
      sort: {priority: 1, name: 1}
    }).fetch();

    // It's 9 apps per slide
    prods = prods.chunk(ITEM_COUNT * ROWS_COUNT);

    $.each(prods, function (i, link) {
      apps.push(link.complete(ITEM_COUNT, {}).chunk(ITEM_COUNT));
    });

    return apps;
  }

  return {
    favorites: getApps()
  };

}, Favorites);