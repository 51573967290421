import {Meteor} from 'core/meteor';
import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import {Session} from 'core/session';
import Confirm from './Confirm';
import IW from 'imports/iw_api';
import getPageManager from 'imports/lib/page_manager';

export default createContainer((params) => {

  function getCode() {
    return IW.appGetVal('code');
  }

  function getResendButton() {
    return Session.get('hide_resend_button') || false;
  }

  const numpad_layout = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9],
    ['clear', 0, 'delete']
  ];

  function isPincodeValid() {
    return IW.appGetVal('pin') && (IW.appGetVal('code') == IW.appGetVal('pin'))
  }

  if (isPincodeValid()) {
    getPageManager().next();
  }

  return {
    appGuid: params.appGuid,
    pages: params.pages,
    numpadLayout: numpad_layout,
    code: getCode(),
    pattern: "\\d{5}",
    isPincodeOk: isPincodeValid(),
    hideResendButton: getResendButton(),
    onTypeCode: (v) => {
      IW.appSetVal('code', v);
    },
    onResendCode: () => {},
    onNext: () => getPageManager().next(),
    onExit: () => getPageManager().cancel(),
  };

}, Confirm);