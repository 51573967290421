import {createContainer} from 'imports/lib/react_createContainer';
import NameValueDataDisplay from '../namevalue_display/NameValueDataDisplay';
import IW from 'imports/iw_api';
import {C, N} from 'imports/lib/helpers';

export default createContainer((params) => {
  const currency = '$';
  let items = [];

  const requestedAmount = N(IW.appGetVal('requested_amount'));
  const receivedAmount = N(IW.appGetVal('clerk_received_amount'));
  const changeAmount = N(requestedAmount - receivedAmount);

  items = [
    {description: 'Requested amount: ', data: C(requestedAmount, currency)},
    {description: 'Received amount from customer: ', data: C(receivedAmount, currency)},
    {description: 'Change is: ', data: C(changeAmount, currency)},
  ];

  if(receivedAmount < requestedAmount){
    items = [
      {description: 'Should be received from customer: ', data: C(requestedAmount, currency)},
    ]
  }

  return {
    items
  }
}, NameValueDataDisplay);
