import React from 'react';
import Button from 'imports/ui/shared/button/Button';

export default class Pagination extends React.Component {

  renderDots(count, handlerClickDot){
    let dots = [];
    for( let i = 0; i < count; i++ ) {
      dots.push(<div key={i} className="item" onClick={() => handlerClickDot(i + 1)}></div>);
    }

    return dots;
  }

  render() {
    let {apps, onClickDot} = this.props;

    return ( apps != 0 ? (<div className="slideSelectors">
        <Button className="left-arrow button upper prev button-slider" text="&lt;"/>
        {this.renderDots(apps, onClickDot)}
        <Button className="right-arrow button upper next button-slider" text="&gt;"/>
      </div>) : null);
  }
}

Pagination.propTypes = {
  apps: React.PropTypes.number,
  onClickDot : React.PropTypes.func
};