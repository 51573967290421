import IW from 'imports/iw_api'
import {next} from 'imports/lib/page_manager'

export default function (backPage) {
  return function() {

    Session.set('prev_current_active_field', 0);
    Session.set('current_active_field', 0);

    return {
      back: backPage,
      next: function*() {
        const customer = IW.appGetVal("customer");
        if (!_.isEmpty(customer)){
          IW.updateCustomer(customer, () => next());
          yield 'loading';
        }

        return backPage
      }
    }
  }
}
