import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';

export default class ConfirmCheckCash extends Component {

  render() {
    const {items, canAccept, canDispense, dispensableAmount, requestedAmount, onCancel, onNext} = this.props;

    return (
      <table className="confirm-entered-data-container">
        <tbody>
        <tr>
          {canDispense ?
            (<td className="confirm_check_cash__text" _={[dispensableAmount]}>
              Press Accept to Dispense %s
            </td>) :
            canAccept ?
              (<td className="confirm_check_cash__text">
                <span _={[requestedAmount]}>Now we can just increase your balance.</span>
              </td>) :
              (<td className="confirm_check_cash__text">
                <span _={[requestedAmount]}>Now we cannot dispense %s.</span>
                <br/>
                <span _>Please, try again later</span>
              </td>)
          }
        </tr>
        <tr>
          <td>
            <table className="field-list confirm_check_cash__table">
              <tbody>
              {_.compact(_.map(items, (data, index) => data.key && data.value && ((
                <tr key={index}>
                  <td className="field-list__description" _>{data.key}:</td>
                  <td className="field-list__data" style={{padding: '0px 50px'}}><span>{data.value}</span></td>
                </tr>) || (
                <tr key={index}>
                  <td colSpan={2}/>
                </tr>
              ))))}
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td>
            <Button id="back" className="left button" text_="Cancel" onMouseDown={() => onCancel()}/>
            {canAccept && (
              <Button id="next" className="right button" text_="Accept" onMouseDown={() => onNext()}/>
            )}
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}