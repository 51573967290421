import React, {Component} from 'react';
import { FlowRouter } from 'core/Router';
import Overlay from '../shared/overlay/OverlayContainer';
import Alert from '../shared/alert/AlertContainer';
import * as Timer from 'imports/api/timer';
import {Session} from 'core/session';
import IW from 'imports/iw_api';
import 'public/css/layout.css';
import safeRender from 'imports/ui/decorators/safeRender';
import ReactGA from 'react-ga';

//css

const SigueLocations = (props) => {
  const onChange = (event) => {
    const socket = IW.getSocket();
    socket.emit('/u/sigue/set_location', {id: event.target.value}, () =>
      socket.emit('/u/sigue/get_locations', (e, ls) => {
        Session.set('sigue_locations', ls);
        window.confirm('Location changed')
      }))
  }
  return <select style={{
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 176,
    height: 12,
    zIndex: 9999,
    fontSize: 10
  }} onChange={onChange}>
    <option disabled selected={!_.find(props.locations, (l)=> l.selected)} value="--"> -- select location -- </option>
    {_.map(props.locations, (l, i) => <option key={i} selected={l.selected} value={l.id}>{l.name}</option>)}
  </select>
}


// App component - represents the whole app
class Layout extends Component {
  render() {
    return (
      <div>
        <link rel="shortcut icon" href="favicon.ico"/>
        <meta name="viewport" content="width=device-width, minimum-scale=1, maximum-scale=1"/>
        <title id="title">{this.props.title}</title>
        <style id="ui">{this.props.styles.ui}</style>
        <style id="css">{this.props.styles.css}</style>
        <Overlay />
        <Alert />
        <table id="body" onMouseMove={ Timer.tryReset } onKeyPress={ Timer.tryReset } onMouseDown={ Timer.tryReset }
               onDragStart={ notMoveImage } onFocus={ focusInput }>
          <thead id="header" className={this.props.params.layoutName}>
          {this.props.header(this.props)}
          </thead>
          <tbody>
          <tr>
            <td id="wrapper" className={this.props.params._formattedNames}>
              {this.props.content(this.props)}
            </td>
          </tr>
          </tbody>
        </table>
        {this.props.testMode? <div style={{position:'absolute', top: 0, left: 0, width: 3, height: 3,  zIndex: 9999, background:'#0f0'}}></div> : null}
        {!_.isEmpty(this.props.sigue_locations) ?
          <SigueLocations locations={this.props.sigue_locations}/>:
          null}
      </div>
    )

  }

  componentDidMount() {
    this.onKeyboardPressed = (e) => this.props.onKeyboardPressed(e, this.props);
    window.addEventListener('keydown', this.onKeyboardPressed)

    ReactGA.initialize('UA-154893561-1', {
      'cookieDomain': 'auto',
      // 'debug': true
    });
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyboardPressed)
  }
}

Layout.propTypes = {
  title: React.PropTypes.string,
  testMode: React.PropTypes.bool,
  header: React.PropTypes.func,
  content: React.PropTypes.func,
  onKeyboardPressed: React.PropTypes.func,
  params: React.PropTypes.object.isRequired,
  styles: React.PropTypes.object
};

Layout.defaultProps = {
  content: () => {
  },
  header: () => {
  }
}

export default safeRender(Layout);

/* Prevent image dragging */
function notMoveImage(ev) {
  if (ev.target.tagName === "IMG") {
    ev.preventDefault();
  }
}

/* We do not let focusing inputs */
function focusInput(ev) {
  if (ev.target.tagName === "INPUT") {
    const $field = $(ev.target);
    Session.set('focus', $field.attr('id')/* || $field.attr('name')*/);
    IW.appSetVal('focus', $field.attr('id'));
    $field.blur();
  }
}
