import React, {Component} from 'react';
import Favorites from 'imports/ui/shared/favorites/FavoritesContainer';
import AppIcon from 'imports/ui/shared/app_icon/AppIconContainer';
import Loading from 'imports/ui/shared/loading/Loading';
import safeRender from 'imports/ui/decorators/safeRender';

class Profile extends Component {
  render() {
    const {icon, user} = this.props;
    return <Loading/>; // do nothing and wait redirection to shopping cart
    return (
      <table className="v-top">
        <tbody>
          <tr>
            <td id="profile_favorites">
              <div className="center user-greeting smaller">Welcome back, <span>{user.firstName}</span></div>
              <Favorites/>
            </td>
            <td id="profile_cats">
              <table className="striped auto">
                <tbody>
                  <tr>
                    <td>
                      <a>Scan a bill</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a>Pay a new bill</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a>Send money</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a>Payment history</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a>Invite friends</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a>Preferences</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <AppIcon icon={icon} isProfileItem={true}/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

Profile.propTypes = {
  icon: React.PropTypes.object,
  user: React.PropTypes.shape({
    firstName: React.PropTypes.string,
    lastName: React.PropTypes.string,
  })
};

Profile.defaultProps = {
  icon: React.PropTypes.object,
  user: React.PropTypes.shape({
    firstName: React.PropTypes.string,
    lastName: React.PropTypes.string,
  })
};

export default safeRender(Profile);