import React, {Component} from 'react';
import {Session} from 'core/session';
import IW from 'imports/iw_api';
import Numpad from 'imports/ui/shared/numpad/Numpad';
import Button from 'imports/ui/shared/button/Button';
import ClearedInput from 'imports/ui/shared/cleared_input/ClearedInput';
import Clear from 'imports/ui/shared/clear/Clear';

const COUNT_PADDING_BOTTOM = [180, 180, 160, 90, 60, 60];

export default class Plus extends Component {

  componentDidMount() {
    // Focusing the field upon (re)draw
    Session.set('focus', this.props.fields[0] && this.props.fields[0].name);
    // triggers the change in Deps.autorun

    const field_to_focus = Session.get('focus');
    Session.set('focus', '');
    Session.set('focus', field_to_focus);
    IW.appSetVal('focus', field_to_focus);
  }

  buildInput(field, value){
    const {focus, clearInput } = this.props;
    return (
      <tr key={field.name}>
        <td>
          <div className="plus-page__post-describe">
            <span className="text-md padding-l-md" _>{field.description}</span>
          </div>
          <ClearedInput id={field.name} readOnly={true} required={true} maxLength={6} value={value}
                        clearInput={clearInput} focus={focus}
          />
        </td>
      </tr>
    )
  }

  render() {
    var res = null;
    const {
      fields, values, numPadLayout, valid, onValueChange, value,
      goToNextStep, goToPrevStep, keyboardMaxLength, inputsCount, onFocusChange
    } = this.props;

    if (!fields || fields.length === 0) return null;

    res = (
      <table className="plus-page">
        <tbody>
        <tr>
          <td className="plus-page__post-address" style={{paddingBottom: COUNT_PADDING_BOTTOM[inputsCount]}}>
            <table>
              {_.map(fields, (f, i) => this.buildInput(f, values[i]))}
            </table>
          </td>
          <td className="plus-page__keyboard">
            <Numpad keys={numPadLayout} value={value} onValueChange={onValueChange} maxLength={keyboardMaxLength}
              inputsCount={inputsCount} onFocusChange={onFocusChange}/>
            <Clear />
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2">
            { valid ? <Button id="next" className="button right" onMouseDown={goToNextStep} text_="Next"/> : null }
            <Button id="back" className="left button" onMouseDown={goToPrevStep} text_="Back"/>
          </td>
        </tr>
        </tfoot>
      </table>
    );
    return res;
  }
}

Plus.propTypes = {
  fields: React.PropTypes.arrayOf(React.PropTypes.shape({
    name: React.PropTypes.string,
    description: React.PropTypes.string,
  })),
  values: React.PropTypes.arrayOf(React.PropTypes.string),
  numPadLayout: React.PropTypes.arrayOf(React.PropTypes.arrayOf(React.PropTypes.any)),
  valid: React.PropTypes.bool,
  value: React.PropTypes.string,
  keyboardMaxLength: React.PropTypes.number,
  onValueChange: React.PropTypes.func,
  goToNextStep: React.PropTypes.func,
  goToPrevStep: React.PropTypes.func,
  inputsCount: React.PropTypes.number,
  onFocusChange: React.PropTypes.func,
  clearInput : React.PropTypes.func,
  focus : React.PropTypes.string
};
