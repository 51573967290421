import React from 'react';

export default function (Component) {
  if (!/^class/.test(String(Component))) return Component;
  return class _safeRender extends Component {
    render(...args) {
      try {
        return super.render(...args);
      } catch (err) {
        console.error(err);
        return null;
      }
    }
  }
}
