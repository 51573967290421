import React from 'react';
import Map from './Map';
import IW from 'imports/iw_api'

const includedTypes = ['street_number', 'route', 'locality', 'administrative_area_level_1', 'country', 'postal_code'];


class MapSelector extends React.Component {
  static defaultProps = {
    zoom: 16
  };

  state = {
    center: null,
  };

  isCreatingMarker = false;
  currentMarker = null;

  zoomIn = () => {
    const zoomLevel = this.map.getZoom();
    const newZoomLevel = _.min([zoomLevel + 1, 20]);
    this.map.setZoom(newZoomLevel);
  };

  zoomOut = () => {
    const zoomLevel = this.map.getZoom();
    const newZoomLevel = _.max([zoomLevel - 1, 5]);
    this.map.setZoom(newZoomLevel);
  };


  handleAddressSelect = (value, key) => {
    const {onAddressSelect} = this.props;
    onAddressSelect && onAddressSelect(value, key);
  };


  getLocation = async address => {
    const place = await new Promise((resolve) => IW.getLocationByAddress(address, (err, res) => resolve(res) || err));
    if (!place) return null;
    return place.geometry.location;
  };

  getDeviceLocation = async () => {
    return await new Promise((resolve) => IW.call('getKioskLocation', null, (err, res) => resolve(res) || err));
  };

  initMap = async address => {
    const location = !!address ? await this.getLocation(address) : await this.getDeviceLocation();
    if (!location) throw Error('Cannont get initial location');
    this.setState({center: {lat: +location.lat, lng: +location.lng}});
  };

  handleMapAddressSelect = async location => {
    if (this.isCreatingMarker) return;
    this.isCreatingMarker = true;
    if (!!this.currentMarker) this.currentMarker.setMap(null);

    const address = await new Promise((resolve) => IW.getAddressByLocation(location, (err, res) => resolve(res) || err));

    if (!address) {
      this.isCreatingMarker = false;
      return;
    }

    const components = _.reduce(address.address_components, (acc, c) => {
      const key = _.intersection(c.types, includedTypes)[0];
      if (!!key) {
        acc[key] = c.long_name;
        acc['short_' + key] = c.short_name;
      }
      return acc;
    }, {});

    this.currentMarker = new this.maps.Marker({
      position: location,
      map: this.map,
      title: address.formatted_address
    });
    this.map.panTo(location);

    this.handleAddressSelect({address: address.formatted_address, components}, 'MAP');
    this.isCreatingMarker = false;
  };


  onGoogleApiLoaded = ({map, maps}) => {
    this.map = map;
    this.maps = maps;

    this.handleMapAddressSelect(this.state.center);
    map.addListener('click', (e) => this.handleMapAddressSelect(e.latLng))
  };


  componentDidMount() {
    this.initMap(this.props.address);
  }

  render() {
    const {address, language} = this.props;
    const {center} = this.state;

    return (
      <div className="map-wrapper">
        {center && (
          <Map
            defaultCenter={center}
            defaultZoom={this.props.zoom}
            onGoogleApiLoaded={this.onGoogleApiLoaded}
            language={language}
          />
        )}
        <div className="map-result-wrap">
          {address && <div className="map-result">{address}</div>}
        </div>
        <div className="map-zoom-control map-zoom-control-in" onClick={this.zoomIn}>+</div>
        <div className="map-zoom-control map-zoom-control-out" onClick={this.zoomOut}>-</div>
      </div>
    )
  }
}

export default MapSelector;