import React from 'react';
import SmallLoading from '../../shared/loading/SmallLoading';

export default class BillScanningLoading extends React.Component {

  componentDidMount() {
    this.props.onMount();
  }

  render() {
    const {items} = this.props

    const itemComponents = _.map(items, (item, i) => {
      let loading = (<div className="payed-product" data-disabled="true"></div>);
      if (item.status === 'loading'){
        // TODO: Replace it to css
        loading = (<SmallLoading style={{margin: 7, display: 'inline-block', left: -8}}/>)
      }
      if (item.status === 'done'){
        loading = (<div className="payed-product"></div>)
      }

      return (
        <tr key={i}>
          <td className="bill-scanning-loading__status" data-status={item.status}>
            {loading}
          </td>
          <td _>{item.description}</td>
        </tr>)
    })

    return (
      <div className="loading">
        <table className="bill-scanning-loading__table">
          <tbody>
          {itemComponents}
          </tbody>
        </table>
      </div>
    )
  }
}
