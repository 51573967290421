import React, {Component} from 'react';
import Numpad from 'imports/ui/shared/numpad/Numpad';
import Button from 'imports/ui/shared/button/Button';
import ClearedInput from 'imports/ui/shared/cleared_input/ClearedInput';
import IW from 'imports/iw_api';
import {Session} from 'core/session';
import AmountStatistic from 'imports/ui/shared/amount_statistic/AmountStatisticClerk';
import {C, S} from 'imports/lib/helpers'

export default class AmountClerk extends Component {
  componentDidMount() {
    Session.set('focus', 'amount');

    const field_to_focus = Session.get('focus');
    Session.set('focus', '');
    Session.set('focus', field_to_focus);

    IW.appSetVal('focus', field_to_focus);
  }

  render() {
    const { amount, numpadLayout, confirm, onPrintNumber, onNext, onBack, clearInput, isFlexible,
      keyboardPattern, keyboardMaxLength} = this.props;

    return (
      <table className="enter-amount">
        <tbody>
        <tr>
          <td>
            <div className="enter-amount__input">
              <div className="label clerk_received-amount-title" _>Received from customer: </div>
              <ClearedInput id="amount" placeholder="$0.00" required={true} className="focus"
                            maxLength={keyboardMaxLength} pattern={keyboardPattern} value={S(amount)}
                            readOnly={true} focus="amount"
                            clearInput={clearInput} />
              <br />
            </div>
            <AmountStatistic className="amount-statistic amount-statistic-clerk" />
          </td>
          <td>
            <Numpad keys={numpadLayout} pattern={keyboardPattern} isFlexible={isFlexible} value={S(amount)}
                    maxLength={keyboardMaxLength} onValueChange={onPrintNumber} />
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2" >
            <Button id="back" className="left button" text_="Back" onMouseDown={onBack}/>
            { confirm ? <Button id="next" className="right button" onMouseDown={onNext} text_="Confirm"/> : null }
          </td>
        </tr>
        </tfoot>
      </table>
    )
  }
}

AmountClerk.propTypes = {
  amount: React.PropTypes.number,
  currency: React.PropTypes.string,
  numpadLayout: React.PropTypes.arrayOf(React.PropTypes.array),
  onPrintNumber : React.PropTypes.func,
  confirm : React.PropTypes.bool,
  onNext: React.PropTypes.func,
  onBack: React.PropTypes.func,
  clearInput : React.PropTypes.func
};
