import React, {Component} from 'react';
import {Session} from 'core/session';
import Keyboard from '../shared/keyboard/Keyboard';
import AppIcon from 'imports/ui/shared/app_icon/AppIconContainer';
import Input from 'imports/ui/shared/input/Input';
import safeRender from 'imports/ui/decorators/safeRender';

const KEYS_MAX_LENGTH = 18;

class Search extends Component {

  componentDidMount() {
    Session.set('focus', '');
    Session.set('focus', 'q');
  }

  render() {
    return (
      <table>
        <tbody>
        <tr>
          <td id="apps">
            {
              this.props.results.length ? (<table>
                  <tbody>
                  {this.props.results.map(function (result, i) {
                    return (
                      <tr key={i}>
                        {result.map(function (item, j) {
                          return <td key={j}
                                     style={{width: "20%", height: "50%"}}><AppIcon key={j} icon={item}/>
                          </td>;
                        })}
                      </tr>
                    )
                  })}
                  </tbody>
                </table>) : <div>{this.props.errorText()}</div>
            }
          </td>
        </tr>
        <tr>
          <td id="search-input_countainer">
            <div id="search-bar">
              <span id="q-wrap">
                  <Input id="q" className="upper" value={this.props.searchString} readOnly={true} placeholder_="Product name"/>
                  {this.props.query ? <span id="q-x" onMouseDown={this.props.onClear}/> : null}
              </span>
            </div>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr className="text-keyboard">
          <td>
            <Keyboard keys={this.props.keyboardLayout} value={this.props.searchString} maxLength={KEYS_MAX_LENGTH}
                      onValueChange={this.props.onQuery}/>

          </td>
        </tr>
        </tfoot>
      </table>

    );
  }
}

Search.propTypes = {
  searchString: React.PropTypes.string,
  results: React.PropTypes.arrayOf(React.PropTypes.array),
  keyboardLayout: React.PropTypes.arrayOf(React.PropTypes.array),
  links: React.PropTypes.objectOf(React.PropTypes.string),
  errorText: React.PropTypes.func.isRequired,
  onQuery: React.PropTypes.func,
  onClear: React.PropTypes.func
};

export default safeRender(Search);
