import React, {Component} from 'react';
import Loading from 'imports/ui/shared/loading/Loading';
import Button from 'imports/ui/shared/button/Button';
import PaymentHistory from 'imports/ui/shared/payment_history/PaymentHistoryContainer'
import SendMoney from 'imports/ui/shared/send_money/SendMoneyContainer'
import ProfileProducts from 'imports/ui/shared/profile_products/ProfileProductsContainer'
import IW from 'imports/iw_api';
import safeRender from 'imports/ui/decorators/safeRender';
import __ from 'lodash';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {ready: false};
  }

  componentDidMount() {
    const {last_payments} = this.props;
    let currentAction = IW.loginGetVal('profile_action');
    if (!currentAction) {
      currentAction = 'payment_history';
      IW.loginSetVal('profile_action', currentAction);
    }

    this.setState({
      action: currentAction,
      lastPayments: [],
      ready: false,
    });
    setTimeout(() => {
      this.setState({
        ready: true,
        lastPayments: last_payments,
      });
    }, 50);
  }

  componentWillReceiveProps(nextProps) {
    if (__.get(nextProps.last_payments, '0.payment_id', -1) !== -1 || _.isEmpty(nextProps.last_payments)) {
      this.setState({
        ready: true,
        lastPayments: nextProps.last_payments,
      });
    }
  }

  renderContainer() {
    const {action, lastPayments} = this.state;

    switch (action) {
      case 'payment_history':
        return <PaymentHistory onNext={this.next.bind(this)} items={lastPayments}/>;
      case 'pay_new_bill':
        return <ProfileProducts/>;
      case 'send_money':
        return <SendMoney items={lastPayments}/>;
      default:
        this.setState({action: 'payment_history'});
        return <PaymentHistory items={lastPayments}/>;
    }
  }

  next(a) {
    const {onNext} = this.props;

    this.setState({action: a});
    return onNext(a);
  }

  render() {
    const {first_name, time, referral_amount, debit_balance, canWithdrawReferral, canWithdrawDebit, referral_code, allowMaintenance, launchMaintenance} = this.props;
    const {ready, action} = this.state;

    return (
      <div className="profile_container">
        <div className="profile_loading" data-not-ready={!ready}>
          <Loading/>
        </div>
        <div className="profile_bar profile_left-bar_container">
          <div className="profile_menu_container">

            <div className="profile_referral_balance">
              <div className="profile_referral_balance-item">
                <span className="profile_referral_balance-title">Referral Code</span>
                <span className="profile_referral_balance-amount">{referral_code}</span>
              </div>
              <div className="profile_referral_balance-item">
                <span className="profile_referral_balance-title">Referral Bal.</span>
                <span className="profile_referral_balance-amount">{referral_amount}</span>
              </div>
              <div className="profile_referral_balance-item">
                <span className="profile_referral_balance-title">Debit Bal.</span>
                <span className="profile_referral_balance-amount">{debit_balance}</span></div>
            </div>

            <Button id="next" className="menu-button menu-button_pink menu-button_withdraw"
                    onMouseDown={canWithdrawReferral ? () => this.next('withdraw_referral_cash') : null}
                    text_={'Withdraw Referral'}/>

            <Button id="next" className="menu-button menu-button_pink menu-button_withdraw"
                    onMouseDown={canWithdrawDebit ? () => this.next('withdraw_debit_cash') : null}
                    text_={'Withdraw Credit'}/>

            <Button id="next" className="menu-button" onMouseDown={() => this.next('edit_customer_data_profile')}
                    text_={'Edit profile'}/>
            <Button id="next" className={'menu-button' + (action === 'payment_history' ? ' menu-button-active' : '')}
                    onMouseDown={() => this.next('payment_history')}
                    text_={'Payment history'}/>
            <Button id="next" className={'menu-button' + (action === 'pay_new_bill' ? ' menu-button-active' : '')}
                    onMouseDown={() => this.next('pay_new_bill')}
                    text_={'Pay a new bill'}/>
            <Button id="next" className={'menu-button' + (action === 'send_money' ? ' menu-button-active' : '')}
                    onMouseDown={() => this.next('send_money')} text_={'Money transfer'}/>
            {allowMaintenance && <Button id="next" className={'menu-button'}
                                         onMouseDown={launchMaintenance} text_={'Maintenance'}/>}
          </div>
        </div>
        <div className="profile_bar profile_right-bar_container">
          <div className="profile_welcome_container">
            <div className="profile_welcome-name">
              <span className="profile_welcome-name_text" _>
                Welcome back
              </span>
              <span className="profile_welcome-name_title">{first_name}</span>
            </div>
            <div className="profile_welcome_time">
              <span className="profile_welcome-name_text">
                {time}
              </span>
            </div>
          </div>
          {this.renderContainer()}
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  first_name: React.PropTypes.string,
  referral_amount: React.PropTypes.string,
  last_payments: React.PropTypes.arrayOf(
    React.PropTypes.shape({
      payment_id: React.PropTypes.number,
      product_name: React.PropTypes.string,
      icon: React.PropTypes.string,
      actual_amount: React.PropTypes.string,
      time_end: React.PropTypes.string,
    }),
  ),
};

Profile.defaultProps = {
  first_name: 'Chuck',
  referral_amount: '$777.00',
  last_payments: [
    {
      payment_id: 1,
      product_name: 'Test Product',
      icon: '/resources/icons/utilities/FortLauderdale.png',
      actual_amount: '$777',
      time_end: '5/23/2018 | 2:08 PM',
    },
    {
      payment_id: 2,
      product_name: 'Product with very very long name',
      icon: '/resources/icons/utilities/FortLauderdale.png',
      actual_amount: '$1.5',
      time_end: '5/23/2018 | 2:08 PM',
    },
    {
      payment_id: 1,
      product_name: 'Test Product',
      icon: '/resources/icons/utilities/FortLauderdale.png',
      actual_amount: '$1',
      time_end: '5/23/2018 | 2:08 PM',
    },
    {
      payment_id: 2,
      product_name: 'Product with very very long name',
      icon: '/resources/icons/utilities/FortLauderdale.png',
      actual_amount: '$1514',
      time_end: '5/23/2018 | 2:08 PM',
    },
    {
      payment_id: 1,
      product_name: 'Test Product',
      icon: '/resources/icons/utilities/FortLauderdale.png',
      actual_amount: '$425',
      time_end: '5/23/2018 | 2:08 PM',
    },
    {
      payment_id: 2,
      product_name: 'Product with very very long name',
      icon: '/resources/icons/utilities/FortLauderdale.png',
      actual_amount: '$777',
      time_end: '5/23/2018 | 2:08 PM',
    },
    {
      payment_id: 1,
      product_name: 'Test Product',
      icon: '/resources/icons/utilities/FortLauderdale.png',
      actual_amount: '$17',
      time_end: '5/23/2018 | 2:08 PM',
    },
    {
      payment_id: 2,
      product_name: 'Product with very very long name',
      icon: '/resources/icons/utilities/FortLauderdale.png',
      actual_amount: '$2577',
      time_end: '5/23/2018 | 2:08 PM',
    },
  ],
};

export default Profile;
