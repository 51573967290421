import React, {Component} from 'react';
import SliderPageTmp from 'imports/ui/shared/slider_page_tmp/SliderPageTmpl';
import { SLIDE_ITEM_TYPES } from 'imports/lib/constants';

export default class Amount extends Component {
  shouldComponentUpdate(newProps){
    return !_.isEqual(newProps.choices, this.props.choices)
  }

  render() {
    const {choices, pages, onBack} = this.props;

    return <SliderPageTmp className="amount__container" type={SLIDE_ITEM_TYPES.CARD} choices={choices} pages={pages}
      onBack={onBack} />;
  }
}

Amount.propTypes = {
  choices: React.PropTypes.array,
  pages: React.PropTypes.object,
  onBack: React.PropTypes.func,
};

