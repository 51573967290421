import {createContainer} from 'imports/lib/react_createContainer';
import {FlowRouter} from 'core/Router';
import {_, N} from 'imports/lib/helpers';
import OpenMaya from './OpenMaya';
import Prods from 'imports/collections/products';
import IW from 'imports/iw_api';
import getPageManager from 'imports/lib/page_manager';


export default createContainer((params) => {
  const product = Prods.findOne({"product_id": N(FlowRouter.getParam("productId"))});
  return {
    visible: IW.appGetVal('initialized') && Session.get('frame_loaded'),
    startUrl: IW.appGetVal('start_url'),
  };

}, OpenMaya);
