import {Session} from 'core/session';
import IW from 'imports/iw_api/index';

export default function (pageBack, pageNext) {
  return function() {

    Session.set('prev_current_active_field', 0);
    Session.set('current_active_field', 0);

    IW.appSetVal('bank_account', IW.appGetVal('required_bank_account') || IW.appGetVal('bank_account'));
    return {
      back: () => {
        IW.appSetVal('required_bank_account', IW.appGetVal('bank_account'));
        return pageBack
      },
      next: () => {
        IW.appSetVal('required_bank_account', IW.appGetVal('bank_account'));
        return pageNext
      }
    }
  }
}
