import React, {Component} from 'react';
import AppIcon from 'imports/ui/shared/app_icon/AppIconContainer';

export default class QuickLaunch extends Component {
  render() {
    var res = null;
    let {quickLaunchItems, quickLaunchText} = this.props;
    if (quickLaunchItems.length == 0)
      return null;

    return (
      <tr>
        <td id="quicklaunch" colSpan="2">
          <div className="pre-wrap quicklaunch-text">{quickLaunchText}</div>
          { _.map(quickLaunchItems, function (item, i) {
            return <AppIcon key={i} icon={item}/>
          }) }
        </td>
      </tr>
    );
  }
}

QuickLaunch.propTypes = {
  quickLaunchItems: React.PropTypes.arrayOf(React.PropTypes.object),
  quickLaunchText: React.PropTypes.string
};