import {FlowRouter} from 'core/Router';
import {Session} from "core/session";
import IW from "../iw_api";
import {Meteor} from "core/meteor";

export default function () {
  let lastCardReaderStart = 0;
  return setInterval(function () {
    if ((IW.isSessionActive() && !isHoldLocation()) ||
      Session.get('maintenance_message') ||
      !Session.get('server.connected') ||
      !Session.get('io.connected') ||
      !Session.get('online') ||
      !Meteor.status().connected) {
      if (!Session.get('card_reader_already_active') && !Session.get('card_reader_card_inside')) return;
      return stop();
    }

    if (!IW.isSessionActive() && Session.get('card_reader_card_inside')){
      Session.set('card_reader_already_active', false);
    }

    if (!IW.isSessionActive()) {
      if (Session.get('card_reader_already_active') && lastCardReaderStart + 41000 > Date.now()) return;
      if (Session.get('card_reader_last_activity') + 5000 > Date.now()) return;
      return start();
    }

    function start() {
      IW.callCardReaderForce('receive_card', {});
      Session.set('card_reader_already_active', true);
      Session.set('card_reader_card_inside', false);
      Session.set('card_reader_last_activity', Date.now());
      lastCardReaderStart = Date.now();
    }

    function stop() {
      IW.appSetVal('card_insert', false);
      Session.set('card_reader_already_active', false);
      Session.set('card_reader_card_inside', false);
      IW.callCardReaderForce('close', {});
    }

  }, 222);

  function isHoldLocation() {
    const locations = [/app_atm/];

    const params = FlowRouter.current().params;
    if (!params.appName) return false;

    return _.some(locations, function (regex) {
      return regex.test(params.appName);
    })
  }
}