import SlideItem from './SlideItem';
import {createContainer} from 'imports/lib/react_createContainer';

import React from "react";

export default createContainer((params) => {

  return {
    slides: params.slides,
    type: params.type,
    pages: params.pages,
    itemHeight: params.itemHeight,

    gaCategory: params.gaCategory
  }

}, SlideItem)


