import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import Input from 'imports/ui/shared/input/Input';
import PopularProducts from "imports/ui/shared/popular_products/PopularProductsContainer";

class FinalAdvertisement extends Component {
  render() {
    let res = null;
    const {
      canPrint, usdAmount, onPrintReceipt, fee, account, originalAmount, accountFieldName, onEmailPage, onMainMenu,
      showSweepstakes, onSweepstakesStart
    } = this.props;
    // TODO: Add phone number
    // TODO: Replace _() with react _ attribute

    let leftButton = <Button className="left button" text_="Main menu" onMouseDown={onMainMenu}/>
    if (canPrint) {
      leftButton = <Button className="left button" text_="Print receipt" onMouseDown={onPrintReceipt}/>
    }

    res = (
      <table className="final-page">
        <tbody>
        <tr>
          <td className="td-container">
            <table style={{height: 0}}>
              <tbody className="finalInputs">
              {account && accountFieldName ?
                <tr>
                  <td colSpan="3">
                    <div style={{"display": "flex", "justifyContent": "space-between"}}>
                      <span style={{"whiteSpace": "nowrap"}}>{accountFieldName}</span>
                      <Input className="center" disabled="true" value={account}/>
                    </div>
                  </td>
                </tr> : null}

              <tr>
                <td colSpan="3">
                  <div style={{"display": "flex", "justifyContent": "space-between"}}>
                    <span className="pre-wrap" _>Your Bill Amount</span>
                    {fee ?
                      <span className="pre-wrap" _>Fee</span> :
                      null}
                    <span style={{"fontWeight": "600"}} className="pre-wrap" _>Total paid today</span>
                  </div>
                </td>
              </tr>

              <tr>
                <td colSpan="3">
                  <div style={{"display": "flex", "justifyContent": "space-between"}}>
                    <Input style={{"width": "350px"}} className="center" disabled="true" value={originalAmount}/>
                    {fee ? <Input style={{"width": "350px"}} className="center" disabled="true" value={fee}/> : null}
                    <Input style={{"fontWeight": "600", "width": "350px"}} className="center" disabled="true"
                           value={usdAmount}/>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <PopularProducts/>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr className="finalTfoot">
          <td colSpan="2">
            {leftButton}
            {showSweepstakes ?
              <Button className={`right button button-warning pulse-button-sweepstakes`} onMouseDown={onSweepstakesStart} text_="Sweepstakes"/> :
              <Button className={`right button`} onMouseDown={onEmailPage} text_="Email receipt"/>}
          </td>
        </tr>
        </tfoot>
      </table>
    );
    return res;
  }
}

FinalAdvertisement.propTypes = {
  canPrint: React.PropTypes.bool,
  formatPhoneNumber: React.PropTypes.string,
  usdAmount: React.PropTypes.string,
  fee: React.PropTypes.string,
  account: React.PropTypes.string,
  accountFieldName: React.PropTypes.string,
  originalAmount: React.PropTypes.string,
  onEmailPage: React.PropTypes.func,
  onPrintReceipt: React.PropTypes.func,
  onMount: React.PropTypes.func,
  onMainMenu: React.PropTypes.func,
};


export default FinalAdvertisement;
