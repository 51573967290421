import React, {Component} from 'react';
import {Session} from 'core/session';
import Keyboard from 'imports/ui/shared/keyboard/Keyboard';
import Clear from 'imports/ui/shared/clear/Clear';
import Button from 'imports/ui/shared/button/Button';
import ClearedInput from 'imports/ui/shared/cleared_input/ClearedInput';

export default class Inputs extends Component {

  componentDidMount() {
    Session.set('focus', 'account1');

    var focus = Session.get('focus');
    Session.set('focus', '');
    Session.set('focus', focus);
  }

  buildLongInput() {
    const {
      accnumInstructions, accnumPattern, account1, account2, placeholder, showAlert, clearInput, focus
    } = this.props;
    return (
      <table className="inputs-page__table-inputs">
        <tbody>
        <tr>
          <td className="inputs-page__account-text" style={{width: 980, 'padding-bottom': 0, 'padding-left': 45}}>
            <div>
              <label htmlFor="account1" className="inputs-page__label" _>
                Account #
                {accnumInstructions ?
                  <div><br/><span style={{fontSize: "80%"}} _>{accnumInstructions}</span></div> : null}
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <td className="inputs-page__account-input" style={{padding: 0}}>
            <div>
              <ClearedInput id="account1" required={true} pattern={accnumPattern} className="mask inputs-page__input"
                            value={account1} placeholder={placeholder} clearInput={clearInput} focus={focus}/>
            </div>
          </td>
        </tr>
        <tr>
          <td className="inputs-page__reenter-account-text" style={{'padding-left': 45}}>
            <label htmlFor="account2" className="inputs-page__label" _>
              Re-enter account #
            </label>
          </td>
        </tr>
        <tr>
          <td className="inputs-page__reenter-account-input">
            <ClearedInput id="account2" required={true} pattern={accnumPattern} className="mask inputs-page__input"
                          value={account2} placeholder={placeholder} clearInput={clearInput} focus={focus}/>
          </td>
        </tr>
        <tr>
          <td colSpan={2} style={{height: 80}}>
            {showAlert ? <div className="inputs-page__alert" style={{padding: 0}} _>{showAlert}</div> : null}
          </td>
        </tr>
        </tbody>
      </table>
    )
  }

  buildNormalInput() {
    const {
      accnumInstructions, accnumPattern, account1, account2,
      placeholder, showAlert, clearInput, focus
    } = this.props;
    return (
      <table className="inputs-page__table-inputs">
        <tbody>
        <tr>
          <td className="inputs-page__account-text">
            <div>
              <label htmlFor="account1" className="inputs-page__label" _>
                Account #
                {accnumInstructions ?
                  <div><br/><span style={{fontSize: "80%"}} _>{accnumInstructions}</span></div> : null}
              </label>
            </div>
          </td>
          <td className="inputs-page__account-input">
            <div>
              <ClearedInput id="account1" required={true} pattern={accnumPattern} className="mask inputs-page__input"
                            value={account1} placeholder={placeholder} clearInput={clearInput} focus={focus}/>
            </div>
          </td>
        </tr>
        <tr>
          <td className="inputs-page__reenter-account-text">
            <label htmlFor="account2" className="inputs-page__label" _>
              Re-enter account #
            </label>
          </td>
          <td className="inputs-page__reenter-account-input">
            <ClearedInput id="account2" required={true} pattern={accnumPattern} className="mask inputs-page__input"
                          value={account2} placeholder={placeholder} clearInput={clearInput} focus={focus}/>
          </td>
        </tr>
        <tr>
          <td colSpan={2} style={{height: 120}}>
            {showAlert ? <div className="inputs-page__alert" _>The Account Numbers do not match</div> : null}
          </td>
        </tr>
        </tbody>
      </table>
    )
  }

  render() {
    let res = null;
    const {
      value, keyboardMaxLength, keyboardPattern, keyboardLayout, numPadLayout, showNextButton, onClickNext, onClickBack,
      onChangeValue, focus, onFocusChange, isInputLong
    } = this.props;

    res = (
      <table>
        <tbody className="inputs-page">
        <tr>
          <td className="inputs-page__inputs" colSpan="2">
            {isInputLong ? this.buildLongInput() : this.buildNormalInput()}
          </td>
        </tr>
        <tr className="inputs-page__keyboards">
          <td className="inputs-page__text-keyboard">
            <Keyboard keys={keyboardLayout} onValueChange={onChangeValue} value={value}
                      pattern={keyboardPattern} maxLength={keyboardMaxLength}
                      inputsCount={2} onFocusChange={onFocusChange} focus={focus == 'account2' ? 1 : 0}/>
            <Clear/>
          </td>
          <td className="inputs-page__number-keyboard">
            <Keyboard keys={numPadLayout} value={value} pattern={keyboardPattern} maxLength={keyboardMaxLength}
                      onValueChange={onChangeValue}/>
            <Clear/>
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2">
            {showNextButton ? <Button id="next" onMouseDown={(event) => onClickNext(event)} className="button right"
                                      text_="Next"/> : null}
            <Button id="back" onMouseDown={(event) => onClickBack(event)} className="left button" text_="Back"/>
          </td>
        </tr>
        </tfoot>
      </table>
    );
    return res;
  }
}

Inputs.propTypes = {
  accnumInstructions: React.PropTypes.string,
  accnumPattern: React.PropTypes.string,
  pricePattern: React.PropTypes.string,
  account1: React.PropTypes.string,
  account2: React.PropTypes.string,
  placeholder: React.PropTypes.string,
  originalAmount: React.PropTypes.string,
  keyboardLayout: React.PropTypes.arrayOf(React.PropTypes.arrayOf(React.PropTypes.string)),
  numPadLayout: React.PropTypes.arrayOf(React.PropTypes.arrayOf(React.PropTypes.any)),
  showNextButton: React.PropTypes.bool,
  showAlert: React.PropTypes.string,
  value: React.PropTypes.string,
  keyboardMaxLength: React.PropTypes.number,
  onClickNext: React.PropTypes.func,
  onClickBack: React.PropTypes.func,
  onChangeValue: React.PropTypes.func,
  keyboardPattern: React.PropTypes.string,
  isFlexible: React.PropTypes.bool,
  isInputLong: React.PropTypes.bool,
  clearInput: React.PropTypes.func,
  focus: React.PropTypes.string,
  onFocusChange: React.PropTypes.func,
};