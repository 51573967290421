import React, {Component} from 'react';
import Button from 'imports/ui/shared/button/Button';
import {_, capitalize} from 'imports/lib/helpers';
import VerticalSlider from 'imports/ui/shared/vertical_slider/VerticalSliderContainer';
import Image from 'imports/ui/shared/image/Image';
import IW from 'imports/iw_api'
import * as u from 'underscore'

const RENDER_CATEGORIES = 'cat';
const RENDER_APPS = 'app';

export default class ProfileProducts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeState: [{
        [RENDER_CATEGORIES]: null
      }]
    };

    this.lastPoint = {x: 0, y: 0, id: null};
    this.onTapIcon = this.onTapIcon.bind(this);
  }

  onTapIcon(event, next, key) {
    if (event.type === 'mousedown') {
      this.lastPoint.id = key;
      this.lastPoint.x = event.pageX;
      this.lastPoint.y = event.pageY;
      return;
    }

    if (this.lastPoint.id !== key ||
      Math.max(Math.abs(this.lastPoint.x - event.pageX), Math.abs(this.lastPoint.y - event.pageY)) > 20) return;

    setTimeout(next, 1);
  }

  getCurrentState(){
    const statePull = this.state.activeState;
    return Object.keys(statePull[statePull.length-1])[0]
  }

  getCurrentCategoryId(){
    const statePull = this.state.activeState;
    return statePull[statePull.length-1][this.getCurrentState()]
  }

  getPrevCategoryId(){
    const statePull = this.state.activeState;
    return statePull[statePull.length-2][this.getCurrentState()]
  }

  doStepBack(){
    const {activeState} = this.state;
    activeState.pop();

    this.setState({activeState})
  }

  doStepNext(id){
    const {activeState} = this.state;

    activeState.push({[RENDER_APPS]:id});

    this.setState({
      activeState
    })
  }

  renderAppsItems(apps) {
    function onAppSelect(app) {
      if (app.categoryLevel > 0){
        this.doStepNext(app.id);
        return;
      }

      const customerData = IW.loginGetVal('customer_data_result') || {};

      const options = {
        app_guid: app.app_guid,
        icon: app.icon,
        title: app.product_name,
        product_id: app.product_id,
        customer_id: customerData.customer_id
      };

      return IW.launchAppAfterLogin(options)
    }

    return apps.map((item, key) => {

      const action = onAppSelect.bind(this, item);

      return (
        <div key={key}
             onMouseDown={(event) => this.onTapIcon(event, action, `${item.name}${key}`)}
             onMouseUp={(event) => this.onTapIcon(event, action, `${item.name}${key}`)}
             className="payment-item_container">
          <div className="payment-item_product">
            {item.icon ?
              <Image className="payment-item_product-image_image" src={item.icon}/> :
              <Image className="payment-item_product-image_image" src={'/themes/mayafs/images/icons/ic_bill.png'}/>
            }
            <div className="payment-item_product-desc">
              <span className="payment-item_product-desc_name_full">{item.name}</span>
            </div>
          </div>
        </div>
      )
    });
  }

  renderCategoriesItems() {
    const {categories} = this.props;

    return categories.map((item, key) => {

      const action = this.doStepNext.bind(this, item.id);

      return (
        <div key={key}
             onMouseDown={(event) => this.onTapIcon(event, action, `${item.nm}${key}`)}
             onMouseUp={(event) => this.onTapIcon(event, action, `${item.nm}${key}`)}
             className="payment-item_container">
          <div className="payment-item_product">
            {item.icon ?
              <Image className="payment-item_product-image_image" src={item.icon}/> :
              <Image className="payment-item_product-image_image" src={'/themes/mayafs/images/icons/ic_bill.png'}/>
            }
            <div className="payment-item_product-desc">
              <span className="payment-item_product-desc_name_full">{item.nm}</span>
            </div>
          </div>
        </div>
      )
    });
  }

  renderApps() {
    const {getApps} = this.props;

    const categoryId = this.getCurrentCategoryId();
    const prevCategoryId = this.getPrevCategoryId();

    if (this.getCurrentState() !== RENDER_APPS && categoryId) return;

    const apps = getApps(categoryId, prevCategoryId);

    let renderAppsList = (
      <div className="payment-item_container">
        <span className="payment-item_product-not" _>No products yet</span>
      </div>
    );

    if (apps.length) {
      renderAppsList =
        <div className="payment_slider">
          <VerticalSlider minEls={7} withArrows={true} items={this.renderAppsItems(apps)}/>
        </div>
    }

    return renderAppsList;
  }

  renderCategories() {
    const {categories} = this.props;

    if (this.getCurrentState() !== RENDER_CATEGORIES) return;

    let renderCategoriesList = (
      <div className="payment-item_container">
        <span className="payment-item_product-not" _>No categories yet</span>
      </div>
    );

    if (categories.length) {
      renderCategoriesList =
        <div className="payment_slider">
          <VerticalSlider minEls={7} withArrows={true} items={this.renderCategoriesItems()}/>
        </div>
    }

    return renderCategoriesList;
  }


  render() {
    const {activeState} = this.state;

    return (
      <div className="profile_payments_container">
        {activeState.length <= 1 ?
          <div className="profile_list-header_container">
            <img className="profile_list-header_icon" src="/themes/mayafs/images/icons/ic_bill.png"/>
            <span className="profile_list-header_title" _>Pay a new bill</span>
          </div>
          :
          <div onMouseDown={this.doStepBack.bind(this)} className="profile_list-header_container">
            <img className="profile_list-header_icon_back" src="/themes/mayafs/images/icons/ic_btn_back.png"/>
            <span className="profile_list-header_title" _>Back</span>
          </div>
        }
        <div id="payment-list" className="profile_payments-list_container">
          {this.getCurrentState() === RENDER_CATEGORIES ? this.renderCategories() : null}
          {this.getCurrentState() === RENDER_APPS ? this.renderApps() : null}
        </div>
      </div>
    );
  }
}
