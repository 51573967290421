import {createContainer} from 'imports/lib/react_createContainer';
import {Session} from 'core/session';
import Gifts from './Gifts';
import Links from 'imports/collections/links';

const MAX_ITEMS = 64;

export default createContainer((params) => {
  const data = {
    query: {
      app_guid: params.appGuid,
      // enabled: true,
      keywords: {$not: undefined}
    },
    sort: {
      sort_priority: 1,
      name: 1
    }
  };

  const links = Links.find(data.query, { sort: data.sort }).fetch();
  const selectedId = isNaN(Session.get('gifts_selected_id')) ? -1 : +Session.get('gifts_selected_id');
  const filterItems = _.uniq(_.map(links, (l) => l.name[0].toUpperCase()))
    .filter((i) => i >= 'A' && i<= 'Z')
    .sort((a, b) => a > b ? 1 : a < b ? -1 : 0);
  filterItems.push('#');

  let filteredLinks = links.filter((l) =>
    selectedId === -1 ||
    l.name[0].toUpperCase() === filterItems[selectedId] ||
    (filterItems[selectedId] === '#' && !isNaN(+l.name[0])));

  filteredLinks = filteredLinks.slice(0, MAX_ITEMS) // get only first MAX_ITEMS
  filteredLinks = filteredLinks
    .complete(Math.ceil(filteredLinks.length / 8) * 8, {})
    .chunk(4)
    .chunk(2)

  return {
    links: filteredLinks,
    selectedId,
    filterItems,
    onFilterChange: (k) => Session.set('gifts_selected_id', k)
  };

}, Gifts);
