import React, {Component} from 'react';
import IW from 'imports/iw_api';
import Button from 'imports/ui/shared/button/Button';
import Input from 'imports/ui/shared/input/Input';

export default class Sent extends Component {
  render() {
    var res = null;
    const {thankYouText, message, message2, value, value2, onNext} = this.props;

    res = (
      <table className="sent-page">
        <tbody>
        <tr>
          <td className="sent-page__content">
            <h1 className="yellow">{thankYouText}</h1>
            <span style={{whiteSpace: 'pre-line', lineHeight: 1.4}}>{message}</span>
            <br />
            {value && <Input className="input center lower" disabled="disabled" maxLength={150} value={value} readOnly={true}/>}
            <br />
            {message2 && <span style={{whiteSpace: 'pre-line', lineHeight: 1.4}}>{message2}</span>}
            <br />
            {value2 && <Input className="input center lower" disabled="disabled" maxLength={150} value={value2} readOnly={true}/>}
          </td>
        </tr>
        </tbody>

        <tfoot>
        <tr>
          <td className="sent-page__footer">
            <Button className="center button goto-index" onMouseDown={onNext} text_="Main Menu"/>
          </td>
        </tr>
        </tfoot>
      </table>
    );
    return res;
  }
}

Sent.propTypes = {
  thankYouText: React.PropTypes.string,
  message: React.PropTypes.string,
  value: React.PropTypes.string,
  onNext : React.PropTypes.func
};